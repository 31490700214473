import { type ClassValue } from "clsx";
import { forwardRef, Fragment, isValidElement, type ReactElement, type ReactNode } from "react";
import { cn } from "../../utils";
import { Button, type ButtonProps } from "../Buttons/Button";
import { LinkButton, type LinkButtonProps } from "../Buttons/LinkButton";
import { SearchBar, type SearchBarProps } from "../Search/SearchBar";

export type Breadcrumb = { label: string; href: string };

const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  return breadcrumbs.map(({ label, href }, i) => (
    <Fragment key={i}>
      <LinkButton
        variant="primary"
        outline={false}
        asLink
        href={href}
        className="p-0 text-xl font-semibold text-secondary print:hidden"
        title={`Back to ${label}`}
      >
        {label}
      </LinkButton>
      <span className="text-xl text-tertiary print:hidden">/</span>
    </Fragment>
  ));
};

const TitleSegments = ({ segments, className }: { segments: ReactNode[]; className?: string }) => (
  <div
    className={cn(
      `grid w-fit auto-cols-auto grid-flow-col items-baseline gap-1 text-base font-normal
      text-secondary`,
      className
    )}
  >
    {segments
      .filter((s) => !!s)
      .map((segment, i, all) => (
        <Fragment key={i}>
          {segment}
          {i < all.length - 1 && <span className="text-tertiary">|</span>}
        </Fragment>
      ))}
  </div>
);

/**
 * A titlebar action can be a button, link button, or a custom element. These will
 * be rendered on the right side of the titlebar.
 */
export type TitlebarAction =
  | ({ href?: never; label?: string } & ButtonProps)
  | ({ href: string; label?: string } & LinkButtonProps)
  | ReactElement<typeof Button | typeof LinkButton>
  | false
  | undefined
  | null;

const TitlebarActions = ({ actions }: { actions: TitlebarAction[] }) => {
  return (
    <>
      {actions.map((action, i) =>
        !action ? null : isValidElement(action) ? (
          <Fragment key={i}>{action}</Fragment>
        ) : action.href !== undefined ? (
          <LinkButton key={i} {...action}>
            {action.label}
          </LinkButton>
        ) : (
          <Button key={i} {...action}>
            {action.label}
          </Button>
        )
      )}
    </>
  );
};

export type TitlebarProps = {
  title: string | null | undefined;
  breadcrumbs?: Breadcrumb[] | null | undefined;
  titleSegments?: (string | ReactNode)[] | null | undefined;
  subtitleSegments?: (string | ReactNode)[] | null | undefined;
  actions?: TitlebarAction[] | null | undefined;
  enableSearch?: SearchBarProps | null | undefined;
  className?: ClassValue;
  children?: ReactNode;
};

/**
 * A titlebar is a header component that displays a title, breadcrumbs, title segments, and action buttons
 * @param title - The title of the titlebar
 * @param [breadcrumbs] - An optional array of breadcrumb objects to render to the left of the title
 * @param [titleSegments] - An optional array of segments to render to the right of the title (separated by a pipe character)
 * @param [subtitleSegments] - An optional array of segments to render below the title (separated by a pipe character)
 * @param [actions] - An optional array of action buttons to render, aligned to the right side of the titlebar
 * @param [enableSearch] - When provided, a search bar will be rendered to the left of the actions
 * @param [children] - Additional elements to render in between the title and actions
 */
export const Titlebar = forwardRef<HTMLElement, TitlebarProps>(
  (
    {
      title,
      breadcrumbs,
      titleSegments,
      subtitleSegments,
      actions,
      enableSearch,
      className,
      children,
    },
    ref
  ) => {
    return (
      <header
        ref={ref}
        className={cn("z-10 bg-primary px-4 py-3 text-base font-normal text-secondary", className)}
      >
        <div className="flex items-center gap-1">
          <div className="flex items-baseline gap-1">
            {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            <h1 className="whitespace-nowrap px-0.5 text-xl font-semibold text-primary">{title}</h1>
            {titleSegments && <TitleSegments segments={titleSegments} />}
          </div>
          {children && (
            <div className="flex flex-1 items-center justify-center gap-1">{children}</div>
          )}
          <div
            className={cn(
              "-my-1 flex items-center justify-end gap-1 print:hidden",
              !children && "flex-1"
            )}
          >
            {/* TODO: Remove enableSearch from the titlebar itself and allow a SearchBar component to be passed in via actions instead */}
            {enableSearch && <SearchBar {...enableSearch} />}
            {actions && <TitlebarActions actions={actions} />}
          </div>
        </div>
        {subtitleSegments && <TitleSegments segments={subtitleSegments} />}
      </header>
    );
  }
);
Titlebar.displayName = "Titlebar";

export type Titlebar = typeof Titlebar;
