"use client";

import { useState, type SyntheticEvent } from "react";

/**
 * AsyncEventHandler is a helper type to represent an async or sync even handler to
 * be used with useAsyncEvent hook.
 */
export type AsyncEventHandler<E = SyntheticEvent> =
  | ((event: E) => Promise<unknown>)
  | ((event: E) => unknown);

/**
 * useAsyncEvent is a hook that wraps an event handler with loading state. It is
 * mainly useful for showing a loading state on a button click.
 * @param onEvent - The event handler to wrap with loading state.
 */
export function useAsyncEvent<E = SyntheticEvent>(onEvent?: (event: E) => unknown) {
  const [loading, setLoading] = useState(false);

  if (!onEvent) return [false, undefined] as const;

  const handleEvent = async (args: E) => {
    try {
      setLoading(true);
      await onEvent(args);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return [loading, handleEvent] as const;
}
