import { z } from 'zod';
import { ConsentFormTypeSchema } from '../inputTypeSchemas/ConsentFormTypeSchema'

/////////////////////////////////////////
// CONSENT FORM SCHEMA
/////////////////////////////////////////

export const ConsentFormSchema = z.object({
  formType: ConsentFormTypeSchema,
  id: z.string(),
  name: z.string(),
  organizationId: z.string(),
  /**
   * This ought be a table eventually
   */
  consentType: z.string(),
  url: z.string().nullish(),
  active: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type ConsentForm = z.infer<typeof ConsentFormSchema>

export default ConsentFormSchema;
