import { z } from 'zod';

/////////////////////////////////////////
// MEDICATION ADMINISTRATION RECORD SCHEMA
/////////////////////////////////////////

export const MedicationAdministrationRecordSchema = z.object({
  id: z.string(),
  orderId: z.string(),
  medication: z.string(),
  registrarId: z.string(),
  frequency: z.string().nullish(),
  dose: z.string(),
  unitsId: z.string(),
  route: z.string().nullish(),
  site: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type MedicationAdministrationRecord = z.infer<typeof MedicationAdministrationRecordSchema>

export default MedicationAdministrationRecordSchema;
