import { Prisma, type PrismaClient } from "@prisma/client";
import { PermissionDeniedError, type AppAbility } from "@procision-software/auth";
import {
  BillingChargeMasterSchema,
  type BillingChargeMaster,
  type Organization,
} from "@procision-software/database-zod";
import { z } from "zod";
import { type PaginatedResult } from "~/types/paginated-result";

export const NamedBillingChargeMasterSchema = BillingChargeMasterSchema.extend({
  name: z.string(),
});

export type NamedBillingChargeMaster = z.infer<typeof NamedBillingChargeMasterSchema>;
export class MultipleChargeMasterForSameCodeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "MultipleChargeMasterForSameCode";
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
export class NoChargeMasterForCodeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NoChargeMasterForCode";
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
export async function getChargeMasterForCode(
  prisma: Pick<PrismaClient, "billingChargeMaster" | "cptCode" | "hcpcsCode">,
  ability: AppAbility,
  code: string
): Promise<NamedBillingChargeMaster> {
  const where: Prisma.BillingChargeMasterWhereInput = {
    active: true,
    OR: [
      {
        cptCode: code,
        hcpcsCode: null,
      },
      {
        hcpcsCode: code,
        cptCode: null,
      },
    ],
  };

  const chargeMasters = await prisma.billingChargeMaster.findMany({ where });
  const chargeMaster = chargeMasters[0];
  if (chargeMasters.length > 1) {
    throw new MultipleChargeMasterForSameCodeError(`Multiple charge masters found for code`);
  } else if (chargeMaster) {
    const name = chargeMaster.cptCode
      ? await nameForCptCode(prisma, chargeMaster.cptCode)
      : await nameForHcpcsCode(prisma, chargeMaster.hcpcsCode!);
    return { ...chargeMaster, name };
  } else {
    throw new NoChargeMasterForCodeError(`No charge master found for code`);
  }
}

export async function duplicateChargeMasters(
  prisma: PrismaClient,
  ability: AppAbility,
  input: { sort: string; order: "asc" | "desc"; page: number; perPage: number; search?: string }
): Promise<
  PaginatedResult<
    NamedBillingChargeMaster & { organization: Organization & { billingOrganizationId: string } }
  >
> {
  const orderBy: Prisma.BillingChargeMasterOrderByWithRelationInput[] = [
    { active: "desc" },
    { [input.sort]: input.order },
  ];
  if (input.sort === "cptCode") {
    orderBy.push({ hcpcsCode: input.order });
  }
  const where: Prisma.BillingChargeMasterWhereInput = {
    ...(input.search && {
      OR: [
        {
          cptCode: {
            contains: input.search.toUpperCase(),
          },
        },
        {
          hcpcsCode: {
            contains: input.search.toUpperCase(),
          },
        },
      ],
    }),
  };
  const duplicates = await prisma.$queryRaw<
    { cptCode: string; hcpcsCode: string; billingOrganizationId: string }[]
  >(
    Prisma.sql`select "cptCode", "hcpcsCode", "billingOrganizationId" from "BillingChargeMaster" where "active"=true group by "cptCode", "hcpcsCode", "billingOrganizationId" having count(*) > 1`
  );
  if (duplicates.length === 0)
    return {
      rows: [],
      pagination: {
        page: input.page,
        perPage: input.perPage,
        all: 0,
      },
    };
  where.AND = [
    {
      OR: duplicates.map(({ cptCode, hcpcsCode, billingOrganizationId }) => ({
        cptCode,
        hcpcsCode,
        billingOrganizationId,
      })),
    },
  ];
  const rows = await prisma.billingChargeMaster.findMany({
    where,
    include: {
      billingOrganization: {
        include: {
          organization: true,
        },
      },
    },
    orderBy,
    skip: (input.page - 1) * input.perPage,
    take: input.perPage,
  });
  return {
    rows: (await augmentChargeMasterWithProcedureName(prisma, ability, rows)).map(
      ({ billingOrganization, ...row }) => {
        return {
          ...row,
          organization: {
            ...billingOrganization.organization,
            billingOrganizationId: billingOrganization.id,
          },
        };
      }
    ),
    pagination: {
      page: input.page,
      perPage: input.perPage,
      all: await prisma.billingChargeMaster.count({
        where,
      }),
    },
  };
}

export async function listChargeMaster(
  prisma: PrismaClient,
  ability: AppAbility,
  input: { sort: string; order: "asc" | "desc"; page: number; perPage: number; search?: string }
): Promise<
  PaginatedResult<
    NamedBillingChargeMaster & { organization: Organization & { billingOrganizationId: string } }
  >
> {
  const orderBy: Prisma.BillingChargeMasterOrderByWithRelationInput[] = [
    { active: "desc" },
    { [input.sort]: input.order },
  ];
  if (input.sort === "cptCode") {
    orderBy.push({ hcpcsCode: input.order });
  }
  const where = {
    ...(input.search && {
      OR: [
        {
          cptCode: {
            contains: input.search.toUpperCase(),
          },
        },
        {
          hcpcsCode: {
            contains: input.search.toUpperCase(),
          },
        },
      ],
    }),
  };
  const rows = await prisma.billingChargeMaster.findMany({
    where,
    include: {
      billingOrganization: {
        include: {
          organization: true,
        },
      },
    },
    orderBy,
    skip: (input.page - 1) * input.perPage,
    take: input.perPage,
  });
  return {
    rows: (await augmentChargeMasterWithProcedureName(prisma, ability, rows)).map(
      ({ billingOrganization, ...row }) => {
        return {
          ...row,
          organization: {
            ...billingOrganization.organization,
            billingOrganizationId: billingOrganization.id,
          },
        };
      }
    ),
    pagination: {
      page: input.page,
      perPage: input.perPage,
      all: await prisma.billingChargeMaster.count({
        where,
      }),
    },
  };
}

export async function augmentChargeMasterWithProcedureName<T extends BillingChargeMaster>(
  prisma: PrismaClient,
  ability: AppAbility,
  rows: T[]
): Promise<(T & { name: string })[]> {
  const [cptCodes, hcpcsCodes] = await Promise.all([
    prisma.cptCode.findMany({
      where: {
        code: {
          in: rows.filter((cpt) => cpt.cptCode !== null).map((row) => row.cptCode!),
        },
      },
    }),
    prisma.hcpcsCode.findMany({
      where: {
        code: {
          in: rows.filter((cpt) => cpt.hcpcsCode !== null).map((row) => row.hcpcsCode!),
        },
      },
    }),
  ]);
  return rows.map((row) => ({
    ...row,
    name:
      (row.cptCode
        ? cptCodes.find((cpt) => cpt.code === row.cptCode)?.name
        : hcpcsCodes.find((hcpcs) => hcpcs.code === row.hcpcsCode)?.shortName) ?? "Unknown Code",
  }));
}

async function nameForCptCode(
  prisma: Pick<PrismaClient, "cptCode">,
  code: string
): Promise<string> {
  return (
    await prisma.cptCode.findFirstOrThrow({
      where: {
        code,
      },
    })
  ).name;
}

async function nameForHcpcsCode(
  prisma: Pick<PrismaClient, "hcpcsCode">,
  code: string
): Promise<string> {
  return (
    await prisma.hcpcsCode.findFirstOrThrow({
      where: {
        code,
      },
    })
  ).shortName;
}

export async function getChargeMasterForId(
  prisma: PrismaClient,
  id: string
): Promise<NamedBillingChargeMaster> {
  const cm = await prisma.billingChargeMaster.findFirstOrThrow({
    where: {
      id,
    },
  });
  const name = cm.cptCode
    ? await nameForCptCode(prisma, cm.cptCode)
    : await nameForHcpcsCode(prisma, cm.hcpcsCode!);
  return { ...cm, name };
}

export function createChargeMaster(
  prisma: PrismaClient,
  ability: AppAbility,
  input: Prisma.BillingChargeMasterCreateInput
) {
  const chargeMaster =
    ability.can("create", "BillingChargeMaster") &&
    prisma.billingChargeMaster.create({
      data: {
        ...input,
        active: true,
      },
    });
  if (!chargeMaster) throw new PermissionDeniedError();
  return chargeMaster;
}

// Update ChargeMaster
export async function updateChargeMaster(
  prisma: PrismaClient,
  ability: AppAbility,
  id: string,
  data: Prisma.BillingChargeMasterUpdateInput
) {
  if (!ability.can("update", "BillingChargeMaster")) {
    throw new PermissionDeniedError();
  }
  if (data.cptCode && data.hcpcsCode) {
    throw new Error("Cannot have both cptCode and hcpcsCode");
  }
  await prisma.billingChargeMaster.updateMany({
    where: {
      id,
    },
    data,
  });
  return await prisma.billingChargeMaster.findFirstOrThrow({
    where: {
      id,
    },
  });
}

// Delete ChargeMaster
export async function deleteChargeMaster(prisma: PrismaClient, ability: AppAbility, id: string) {
  if (!ability.can("delete", "BillingChargeMaster")) {
    throw new PermissionDeniedError();
  }
  const deleted = await prisma.billingChargeMaster.updateMany({
    where: {
      id,
    },
    data: {
      active: false,
    },
  });
  return deleted.count === 1;
  // Prisma Documentation: https://www.prisma.io/docs/reference/api-reference/prisma-client-reference#delete
}

export async function typeOfCode(
  prisma: Pick<PrismaClient, "cptCode" | "hcpcsCode">,
  code: string
): Promise<"hcpcs" | "cpt" | null> {
  const [hcpcs, cpt] = await Promise.all([
    prisma.hcpcsCode.count({ where: { code } }),
    prisma.cptCode.count({ where: { code } }),
  ]);
  if (hcpcs === 1 && cpt === 0) return "hcpcs";
  if (cpt === 1 && hcpcs === 0) return "cpt";
  return null;
}
