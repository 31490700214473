import { useClerk, useOrganization } from "@clerk/nextjs";
import { OrganizationType } from "@procision-software/database-zod";
import type { PropsWithChildren } from "react";
import { trpc } from "~/utils/trpc";

export const WithOrganizationalUnit = ({ children }: PropsWithChildren<unknown>) => {
  const { user: clerkUser } = useClerk();
  const { organization } = useOrganization();

  // because of how the <With> components are nested, we can take a lack of organization as lack of org having loaded... yet
  if (!clerkUser || !organization) {
    return null;
  }

  const { data, isLoading } = trpc.user.get.useQuery({ id: clerkUser.id });
  const { data: org, isLoading: isOrgLoading } = trpc.organization.get.useQuery({
    clerkId: organization.id,
  });

  if (!data || isLoading || isOrgLoading || !org) {
    return null; // TODO: How should we handle this invalid state instead of a blank screen
  }

  const { activeFacilityId, activePracticeId } = data;
  if (activeFacilityId) {
    if (org.type === OrganizationType.PRACTICE && activePracticeId) {
      return <>{children}</>;
    } else {
      return <>{children}</>;
    }
  }

  return null;
};
