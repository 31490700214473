"use client";

import { cva } from "class-variance-authority";
import { EyeIcon, EyeOffIcon, type LucideIcon } from "lucide-react";
import type { ChangeEvent, ForwardedRef, InputHTMLAttributes } from "react";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import type { FieldValues, Path } from "react-hook-form";
import { mergeRefs } from "react-merge-refs";
import { useDebouncedCallback } from "use-debounce";
import { cn } from "../../utils";
import { Icon, type IconProps } from "../Icons";
import { BoundField, useFilteredFieldProps, type BoundFieldWithLegacyProps } from "./BoundField";

type Ref = HTMLInputElement;

type ElementProps = Omit<InputHTMLAttributes<Ref>, "className" | "size" | "type" | "value">;

export type InputValue = string | number | readonly string[] | null | undefined;
export type InputVariant = "default" | "error" | "success" | "warning";
export type InputSize = "sm" | "md" | "table";
export type InputWidth = "xs" | "sm" | "md" | "lg" | "full" | "auto";
export type InputType =
  | "text"
  | "hidden"
  | "password"
  | "email"
  | "tel"
  | "url"
  | "search"
  | "number"
  | "date"
  | "week"
  | "month"
  | "time"
  | "color"
  | "file"
  | "image"
  | "range";

export const inputVariants = cva(
  `group/input relative box-border flex flex-auto items-center border focus-within:outline-none
  focus-within:ring-1 focus-within:ring-offset-primary disabled:opacity-70
  data-[disabled="true"]:opacity-70 print:border-0 print:placeholder-transparent`,
  {
    variants: {
      variant: {
        default: `placeholder-priamry/50 border-primary bg-primary text-primary
        focus-within:ring-brand [&>input]:placeholder-primary/50`,
        error: `border-error bg-error text-error placeholder-error/50 focus-within:ring-error
        [&>input]:placeholder-error/50`,
        success: `border-success bg-success text-success placeholder-success/50
        focus-within:ring-success [&>input]:placeholder-success/50`,
        warning: `border-warning bg-warning text-warning placeholder-warning/50
        focus-within:ring-warning [&>input]:placeholder-warning/50`,
      } satisfies Record<InputVariant, string>,
      size: {
        sm: "min-h-[1.875rem] gap-1 rounded-md px-1.5 py-0.5 text-sm print:p-0",
        md: "min-h-[2.375rem] gap-1.5 rounded-lg px-2.5 py-1.5 text-base print:p-0",
        table: "min-h-6 gap-1 rounded-md px-1.5 py-0.5 text-sm print:p-0", // TODO: can this size be deprecated?
      } satisfies Record<InputSize, string>,
      width: {
        xs: "min-w-20 max-w-24 sm:min-w-20 sm:max-w-36",
        sm: "min-w-32 max-w-32 sm:min-w-36 sm:max-w-52",
        md: "min-w-44 max-w-44 sm:min-w-44 sm:max-w-60",
        lg: "min-w-72 max-w-72 sm:min-w-[18rem] sm:max-w-[25rem]",
        full: "min-w-32 max-w-full",
        auto: "", // don't apply any min or max
      } satisfies Record<InputWidth, string>,
      type: {
        text: "",
        hidden: "",
        password: "",
        email: "",
        tel: "",
        url: "",
        search: "",
        number: "", //"[&>input]:text-right",
        date: "",
        week: "",
        month: "",
        time: "",
        color: "",
        file: "",
        image: "",
        range: "",
      } satisfies Record<InputType, string>,
      leftIcon: {
        true: "",
        false: "",
      },
      rightIcon: {
        true: "",
        false: "",
      },
    },
    compoundVariants: [
      { size: "sm", leftIcon: false, rightIcon: false, className: "" },
      { size: "sm", leftIcon: true, rightIcon: false, className: "" },
      { size: "sm", leftIcon: false, rightIcon: true, className: "" },
      { size: "sm", leftIcon: true, rightIcon: true, className: "" },

      { size: "md", leftIcon: false, rightIcon: false, className: "" },
      { size: "md", leftIcon: true, rightIcon: false, className: "pl-2" },
      { size: "md", leftIcon: false, rightIcon: true, className: "pr-2" },
      { size: "md", leftIcon: true, rightIcon: true, className: "px-2" },

      { size: "table", leftIcon: false, rightIcon: false, className: "" },
      { size: "table", leftIcon: true, rightIcon: false, className: "gap-0.5 pl-1" },
      { size: "table", leftIcon: false, rightIcon: true, className: "gap-0.5 pr-1" },
      { size: "table", leftIcon: true, rightIcon: true, className: "gap-0.5 px-1" },

      // override widths for date related inputs to better fit content
      {
        size: "sm",
        type: "date",
        className: "min-w-36 max-w-36 flex-none sm:min-w-36 sm:max-w-36",
      },
      {
        size: "md",
        type: "date",
        className: "min-w-40 max-w-40 flex-none pr-2 sm:min-w-40 sm:max-w-40",
      },
      {
        size: "sm",
        type: "time",
        className: "min-w-28 max-w-28 flex-none sm:min-w-28 sm:max-w-28",
      },
      {
        size: "md",
        type: "time",
        className: "min-w-32 max-w-32 flex-none pr-2 sm:min-w-32 sm:max-w-32",
      },
      {
        size: "sm",
        type: "week",
        className: "min-w-36 max-w-36 flex-none sm:min-w-36 sm:max-w-36",
      },
      {
        size: "md",
        type: "week",
        className: "min-w-40 max-w-40 flex-none pr-2 sm:min-w-40 sm:max-w-40",
      },
      {
        size: "sm",
        type: "month",
        className: "min-w-40 max-w-40 flex-none sm:min-w-40 sm:max-w-40",
      },
      {
        size: "md",
        type: "month",
        className: "min-w-44 max-w-44 flex-none pr-2 sm:min-w-44 sm:max-w-44",
      },

      // override styling for color inputs
      {
        size: "sm",
        type: "color",
        className: `min-w-fit max-w-fit flex-none py-0.5 sm:min-w-fit sm:max-w-fit [&>input]:h-full
        [&>input]:w-8`,
      },
      {
        size: "md",
        type: "color",
        className: `min-w-fit max-w-fit flex-none py-0.5 sm:min-w-fit sm:max-w-fit [&>input]:h-full
        [&>input]:w-12`,
      },
    ],
    defaultVariants: {
      variant: "default",
      size: "md",
      width: "full",
      type: "text",
      leftIcon: false,
      rightIcon: false,
    },
  }
);

export type InputVariantProps = {
  size?: InputSize;
  variant?: InputVariant;
  width?: InputWidth;
  type?: InputType;
  leftIcon?: LucideIcon;
  leftIconProps?: IconProps;
  rightIcon?: LucideIcon;
  rightIconProps?: IconProps;
  className?: string;
};

export type InputProps = ElementProps &
  InputVariantProps & {
    value?: InputValue;
    error?: boolean;
    hideContent?: boolean;
    buffered?: boolean | number; // buffers for the given number of ms, or use the default if true
    selectOnFocus?: boolean;
    loading?: boolean;
  };

/**
 * Input component for form field input
 * @param [size] - Size of the input (default: "md")
 * @param [variant] - Variant of the input (default: "default")
 * @param [error] - Whether or not the input is in an error state
 * @param [leftIcon] - Icon to display on the left side of the button
 * @param [leftIconProps] - Icon props to override the button defaults
 * @param [rightIcon] - Icon to display on the right side of the button
 * @param [rightIconProps] - Icon props to override the button defaults
 * @param [hideContent] - When enabled, the input content will be hidden with a button to toggle visibility
 * @param [buffered] - Enables buffering the input onChange event as the user types
 * @param [selectOnFocus] - Whether to select the input content on focus
 * @param [loading] - Sets the input into a loading state (when true, the input is disabled and shows a loading spinner)
 */
export const Input = forwardRef<Ref, InputProps>(
  (
    {
      variant: _variant,
      size,
      width,
      error,
      leftIcon,
      leftIconProps,
      rightIcon,
      rightIconProps,
      hideContent,
      buffered: _buffered,
      selectOnFocus,
      loading,
      className,
      type,
      value,
      onChange,
      onBlur,
      ...props
    },
    ref
  ) => {
    const variant = error ? "error" : _variant;
    const disabled =
      loading ||
      props.disabled ||
      (props.readOnly && ["radio", "checkbox", "color"].includes(type ?? ""));
    const buffered =
      _buffered === false || _buffered === undefined ? 0 : _buffered === true ? 3000 : _buffered;

    const [contentVisible, setContentVisible] = useState(!hideContent);
    const [isDirty, setIsDirty] = useState(false);
    const [internalValue, setInternalValue] = useState(value);
    useEffect(() => {
      if (isDirty) return; // don't update the internal value if the user has already changed it
      setInternalValue(value);
    }, [value, isDirty]);

    const bufferedOnChange = useDebouncedCallback((e: ChangeEvent<Ref>) => {
      if (e.target.value !== value) onChange?.(e);
    }, buffered);
    useEffect(() => () => bufferedOnChange.flush(), [bufferedOnChange]);

    const handleChange = useCallback(
      (e: ChangeEvent<Ref>) => {
        const val = e.target.value as typeof internalValue;
        setInternalValue(val);
        if (buffered > 0) {
          setIsDirty(val !== value);
          return bufferedOnChange(e);
        }
        return onChange?.(e);
      },
      [buffered, bufferedOnChange, onChange, value]
    );

    const focusingRef = useRef(false);
    const enableSelectOnFocus = !disabled && (selectOnFocus || type === "number");

    return (
      <div
        className={cn(
          inputVariants({
            variant,
            size,
            width,
            type,
            leftIcon: !!leftIcon || !!loading,
            rightIcon: !!rightIcon || !!hideContent,
          }),
          className
        )}
        {...(error ? { "data-error": true } : {})}
        {...(disabled ? { "data-disabled": true, "aria-disabled": true } : {})}
      >
        <Icon
          icon={leftIcon}
          size={size === "table" ? "sm" : size}
          {...leftIconProps}
          className={cn("opacity-70", leftIconProps?.className)}
          loading={loading}
        />
        <input
          {...props}
          ref={ref}
          value={internalValue ?? ""}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              buffered > 0 && bufferedOnChange.flush();
            }
            props.onKeyDown?.(e);
          }}
          onBlur={(e) => {
            buffered > 0 && bufferedOnChange.flush();
            onBlur?.(e);
          }}
          onFocus={(e) => {
            if (enableSelectOnFocus) {
              // auto-select field content
              focusingRef.current = true;
              e.target.select();
              setTimeout(() => (focusingRef.current = false), 1);
            }
            props.onFocus?.(e);
          }}
          onContextMenu={(e) => {
            // disable the context menu while the input is being focused due to
            // e.target.select() opening the context menu on touch devices
            if (focusingRef.current) return e.preventDefault();
            props.onContextMenu?.(e);
          }}
          type={type ?? "text"}
          // size={5} // Override the browser's default size of 20
          // min={props.min ?? 0} // leaving these two min/max lines commented out as a reminder
          // max={props.max ?? 9999} // browsers auto-size number input width to fit the needed min/max value
          className={cn(
            `h-full w-full truncate bg-transparent text-inherit focus-visible:outline-none
            print:overflow-visible print:whitespace-normal`,
            hideContent && !contentVisible && "text-security"
          )}
          autoComplete={props.autoComplete ?? "off"}
          {...(error ? { "aria-invalid": error, "data-error": true } : {})}
          {...(disabled ? { disabled: true, "data-disabled": true, "aria-disabled": true } : {})}
        />
        {hideContent ? (
          <Icon
            icon={contentVisible ? EyeOffIcon : EyeIcon}
            size={size === "table" ? "sm" : size}
            onClick={() => setContentVisible((prev) => !prev)}
            className={cn(
              "cursor-pointer opacity-70 print:hidden",
              disabled && "pointer-events-none"
            )}
          />
        ) : (
          <Icon
            icon={rightIcon}
            size={size === "table" ? "sm" : (size ?? undefined)}
            {...rightIconProps}
            className={cn("opacity-70", rightIconProps?.className)}
          />
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

type InputFieldProps<
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
> = Omit<InputProps, "name"> & BoundFieldWithLegacyProps<TFieldValues, TFieldPath>;

const InputFieldInner = <TFieldValues extends FieldValues, TFieldPath extends Path<TFieldValues>>(
  props: InputFieldProps<TFieldValues, TFieldPath>,
  ref?: ForwardedRef<Ref>
) => {
  const { fieldProps, inputProps } = useFilteredFieldProps<
    TFieldValues,
    TFieldPath,
    InputFieldProps<TFieldValues, TFieldPath>
  >(props);

  return (
    <BoundField
      {...fieldProps}
      labelProps={{ ...fieldProps.labelProps, size: inputProps.size }}
      className={cn(
        inputProps.type && ["number", "date", "time"].includes(inputProps.type) && "flex-none",
        fieldProps.className
      )}
      render={({ field }) => (
        <Input
          {...inputProps}
          {...field}
          onChange={(e) =>
            field.onChange({
              ...e,
              target: {
                ...e.target,
                value:
                  inputProps.type === "number"
                    ? e.target.value
                      ? e.target.valueAsNumber
                      : null
                    : e.target.value,
              },
            })
          }
          ref={mergeRefs([ref, field.ref])}
        />
      )}
    />
  );
};

export const InputField = forwardRef(InputFieldInner) as <
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
>(
  props: InputFieldProps<TFieldValues, TFieldPath> & {
    ref?: ForwardedRef<Ref>;
  }
) => ReturnType<typeof InputFieldInner>;
