import React, { forwardRef } from "react";
import { trpc } from "~/utils/trpc";
import { Select, SelectMultiple, type SelectMultipleProps, type SelectProps } from "../base/fields";
import { type JobRoleType } from "@procision-software/database-zod";
import { type StaffFilterContext } from "~/models/schema/Staff";
import { staffFullName } from "@procision-software/mason";

export const StaffSelect = forwardRef<
  HTMLButtonElement,
  Omit<SelectProps, "options"> & {
    roleType?: JobRoleType;
    context?: StaffFilterContext;
    sort?: "lastName" | undefined;
  }
>(({ roleType, context, loading, sort, disabled, ...props }, ref) => {
  const partialContext = context
    ? {
        practiceId: context.practiceId,
        facilityId: context.facilityId,
        activeOnly: context.activeOnly ?? true,
      }
    : undefined;
  const staff = trpc.staff.list.useQuery({ roleType, sort, context: partialContext });
  return (
    <Select
      {...props}
      ref={ref}
      loading={loading || staff.isLoading}
      disabled={disabled || staff.isLoading}
      options={staff.data?.map((staff) => ({ id: staff.id, label: staffFullName(staff) })) ?? []}
    />
  );
});
StaffSelect.displayName = "StaffSelect";

export const StaffSelectMultiple = forwardRef<
  HTMLButtonElement,
  Omit<SelectMultipleProps, "options"> & {
    roleType?: JobRoleType;
    context?: StaffFilterContext;
  }
>(({ roleType, context, loading, disabled, ...props }, ref) => {
  const partialContext = context
    ? {
        practiceId: context.practiceId,
        facilityId: context.facilityId,
        activeOnly: context.activeOnly ?? true,
      }
    : undefined;
  const staff = trpc.staff.list.useQuery({ roleType, context: partialContext });
  return (
    <SelectMultiple
      {...props}
      ref={ref}
      loading={loading || staff.isLoading}
      disabled={disabled || staff.isLoading}
      options={staff.data?.map((staff) => ({ id: staff.id, label: staffFullName(staff) })) ?? []}
    />
  );
});
StaffSelectMultiple.displayName = "StaffSelectMultiple";
