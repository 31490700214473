import { z } from 'zod';
import { OrganizationTypeSchema } from '../inputTypeSchemas/OrganizationTypeSchema'

/////////////////////////////////////////
// ORGANIZATION SCHEMA
/////////////////////////////////////////

export const OrganizationSchema = z.object({
  type: OrganizationTypeSchema,
  id: z.string(),
  /**
   * company name
   */
  name: z.string(),
  /**
   * appears in top banner
   */
  displayName: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  clerkId: z.string().nullish(),
})

export type Organization = z.infer<typeof OrganizationSchema>

export default OrganizationSchema;
