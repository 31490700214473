import type { KnownLDFlags } from "~/hooks/feature-flags";

export type NavItem = {
  key?: string;
  name: string;
  href: string;
  roles: string[];
  icon?: React.FC<React.ComponentProps<"svg">>;
  disabled?: boolean | undefined;
  subnav?: NavItem[];
  flag?: keyof KnownLDFlags;
};

export function isAllowed(roles: string | string[], allowedRoles: string[]): boolean {
  return (Array.isArray(roles) ? roles : [roles]).some((role) => allowedRoles.includes(role));
}

export function canAccess(navItem: NavItem, allowedRoles: string[]): boolean {
  return isAllowed(navItem.roles, allowedRoles);
}
