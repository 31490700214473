import { z } from 'zod';

/////////////////////////////////////////
// WAYSTAR PAYER GROUP SCHEMA
/////////////////////////////////////////

export const WaystarPayerGroupSchema = z.object({
  id: z.string(),
  canonicalName: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type WaystarPayerGroup = z.infer<typeof WaystarPayerGroupSchema>

export default WaystarPayerGroupSchema;
