"use client";

import type { ReactNode } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useSyncExternalStore,
} from "react";

type SetStoreAction<TState> = Partial<TState> | ((prevState: TState) => TState);

/**
 * Creates a fast context provider and hook for a data store that is optimized to avoid
 * unnecessary re-renders. This is accomplished by using a ref to store the data and
 * a ref to store the subscribers. The subscribers are called when the data is updated.
 * @param {initialState} initialState - The initial state of the store
 *
 * @returns {Provider} Provider - The React Context Provider for the store
 * @returns {typeof useStore} useStore - A hook to access the store data and update the store
 *
 * @see {@link https://youtu.be/ZKlXqrcBx88 Making React Context FAST!}
 * @see {@link  https://github.com/jherr/fast-react-context/tree/main/fast-context-generic Fast Context Example}
 *
 * @example
 * ```typescript
 * const {Provider, useStore} = createFastContext({firstName: "", lastName: ""})
 * ```
 */
export function createFastContext<TState>(initialState?: TState) {
  function useStoreData(overrides?: Partial<TState>): {
    get: () => TState;
    set: (value: SetStoreAction<TState>) => void;
    subscribe: (callback: () => void) => () => void;
  } {
    if (!initialState && !overrides) throw new Error("Cannot initialize context without state");

    const store = useRef({ ...initialState, ...overrides } as TState);

    const get = useCallback(() => store.current, []);

    const subscribers = useRef(new Set<() => void>());

    const set = useCallback((value: SetStoreAction<TState>) => {
      store.current =
        typeof value === "function" ? value(store.current) : { ...store.current, ...value };
      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback: () => void) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    // Update the store if the overrides change
    useEffect(() => overrides && set(overrides), [overrides, set]);

    return {
      get,
      set,
      subscribe,
    };
  }

  type UseStoreDataReturnType = ReturnType<typeof useStoreData>;

  const StoreContext = createContext<UseStoreDataReturnType | null>(null);

  function Provider({ value, children }: { value?: Partial<TState>; children: ReactNode }) {
    return <StoreContext.Provider value={useStoreData(value)}>{children}</StoreContext.Provider>;
  }

  function useStore(): [void, (value: SetStoreAction<TState>) => void];
  function useStore<TOutput>(
    selector?: (store: TState) => TOutput
  ): [TOutput, (value: SetStoreAction<TState>) => void];
  function useStore<TOutput>(
    selector?: (store: TState) => TOutput
  ): [TOutput | void, (value: SetStoreAction<TState>) => void] {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error("Store not found");
    }

    const state = useSyncExternalStore(
      store.subscribe,
      () => (selector ? selector(store.get()) : undefined),
      () => (selector ? selector(store.get()) : undefined) // Used when rendering on the server
    );

    return [state, store.set];
  }

  return {
    Provider,
    useStore,
  };
}
