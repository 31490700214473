import { z } from 'zod';

/////////////////////////////////////////
// PROVIDER ENCOUNTER DOCUMENT SCHEMA
/////////////////////////////////////////

export const ProviderEncounterDocumentSchema = z.object({
  id: z.string().cuid(),
  encounterDocumentId: z.string(),
  staffId: z.string(),
})

export type ProviderEncounterDocument = z.infer<typeof ProviderEncounterDocumentSchema>

export default ProviderEncounterDocumentSchema;
