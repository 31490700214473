import type { Organization as ClerkOrganization } from "@clerk/backend";
import { logger } from "@procision-software/asimov";
import type { BillingOrganization, PrismaClient } from "@procision-software/database";
import { prisma } from "@procision-software/database";
import { OrganizationType, type Organization } from "@procision-software/database-zod";
import { typeFromString } from "./organization_kind";

declare const OrganizationId: unique symbol;
export type OrganizationId = string & { readonly tag: typeof OrganizationId };
export function toOrganizationId(id: string): OrganizationId {
  return id as OrganizationId;
}
export function organizationIdToString(id: OrganizationId): string {
  return String(id);
}

export async function upsertFromClerk(
  organization: Pick<ClerkOrganization, "id" | "name" | "slug" | "publicMetadata">
): Promise<Organization> {
  const { id, name, slug, publicMetadata } = organization;
  if (!prisma) throw new Error("Invalid prisma context");

  if (name === null || slug === null || publicMetadata === null) {
    throw new Error(
      `Could not load organization ${id} from Clerk, missing slug or publicMetadata.`
    );
  }

  if (publicMetadata.type === null || publicMetadata.type === undefined) {
    throw new Error(`Could not load organization ${id} from Clerk, missing type.`);
  }

  const type = typeFromString(publicMetadata.type as string);

  let existing = await prisma.organization.findUnique({
    where: { clerkId: id },
  });

  // If no match is found with the clerk org id, then try matching by the slug.
  // Once a slug has been matched the first time, we will store the clerk org id
  // in clerkId to prevent future slug collisions.
  if (!existing && slug.length > 0) {
    existing = await prisma.organization.findFirst({
      where: { clerkId: null, name: slug },
    });
  }

  const org = existing
    ? await prisma.organization.update({
        where: { id: existing.id },
        data: {
          clerkId: id,
          name: slug,
          displayName: name,
          type: type,
        },
      })
    : await prisma.organization.create({
        data: {
          clerkId: id,
          name: slug,
          displayName: name,
          type: type,
        },
      });

  if (type === OrganizationType.FACILITY) {
    // upsert this facility
    try {
      const facility = await prisma.facility.findFirst({
        where: {
          organizationId: org.id,
        },
      });

      if (facility) {
        await prisma.facility.update({
          where: { id: facility.id },
          data: {
            name: org.displayName,
          },
        });
      } else {
        await prisma.facility.create({
          data: {
            organizationId: org.id,
            name: org.displayName,
            rooms: {
              create: [
                {
                  name: "OR1",
                },
              ],
            },
          },
        });
      }
    } catch (error) {
      logger.error(error);
      throw error;
    }
  } else {
    try {
      const practice = await prisma.practice.findFirst({
        where: {
          organizationId: org.id,
        },
      });

      if (practice) {
        await prisma.practice.update({
          where: { id: practice.id },
          data: {
            name: org.displayName,
          },
        });
      } else {
        await prisma.practice.create({
          data: {
            organizationId: org.id,
            name: org.displayName,
          },
        });
      }
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  return org;
}

export async function billingOrganizationFor(
  prisma: PrismaClient,
  { id }: { id: string }
): Promise<BillingOrganization> {
  const existing = await prisma.billingOrganization.findFirst({
    where: {
      organizationId: id,
    },
  });
  if (!existing) {
    const billingOrganization = await prisma.billingOrganization.create({
      data: {
        organization: {
          connect: {
            id: id,
          },
        },
      },
    });
    return billingOrganization;
  } else {
    return existing;
  }
}

export async function facilityOrganizationForCase(
  prisma: PrismaClient,
  subject: { caseId: string } | { billingCaseId: string }
): Promise<Organization> {
  if ("caseId" in subject) {
    const c = await prisma.case.findFirstOrThrow({
      where: {
        id: subject.caseId,
      },
      include: {
        facility: {
          include: {
            organization: true,
          },
        },
      },
    });
    return c.facility.organization;
  } else {
    const c = await prisma.billingCase.findFirstOrThrow({
      where: {
        id: subject.billingCaseId,
      },
      include: {
        case: {
          include: {
            facility: {
              include: {
                organization: true,
              },
            },
          },
        },
      },
    });
    return c.case.facility.organization;
  }
}
