import type { Patient } from "@procision-software/database-zod";
import type { ReactNode } from "react";
import { trpc } from "~/utils/trpc";

type PatientDataProps = {
  children: (data: Patient) => ReactNode;
  id: string;
};

export default function PatientData({ children, id }: PatientDataProps) {
  const { data } = trpc.patient.get.useQuery({ id });

  if (data) {
    return children(data as unknown as Patient);
  }

  return null;
}
