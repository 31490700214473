import { z } from 'zod';

/////////////////////////////////////////
// ASSET SCHEMA
/////////////////////////////////////////

export const AssetSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  assetTypeId: z.string(),
  encounterId: z.string(),
  patientId: z.string(),
  storageBlobId: z.string(),
  name: z.string(),
})

export type Asset = z.infer<typeof AssetSchema>

export default AssetSchema;
