import logger from "@procision-software/asimov/logger";
import { assertUpdate, type AppAbility } from "@procision-software/auth";
import type { PrismaClient } from "@procision-software/database";
import axios from "axios";
import { createX12837I } from "./create-837i";
import { createX12837P } from "./create-837p";
import {
  getElectronicPaymentFormat,
  getStediPartnershipId,
  mapDateToCCYYMMDD,
  type X12837I,
  type X12837P,
} from "./shared";

type StediResponse = {
  edi: string;
  artifactId: string;
  fileExecutionId: string;
};

function generateUrl(stediPartnershipId: string) {
  return `https://core.us.stedi.com/2023-08-01/x12/partnerships/${stediPartnershipId}/generate-edi`;
}

/**
 * Submits a claim to Waystar via Stedi.
 *
 * interchangeUsageIndicatorOverride sets the EDI payload mode to either production (P) or test (T).
 */
export async function push(
  prisma: PrismaClient,
  ability: AppAbility,
  type: "837I" | "837P",
  billingClaimId: string,
  stediPartnershipId: string,
  transaction: X12837I | X12837P,
  interchangeUsageIndicatorOverride: "P" | "T"
) {
  assertUpdate(ability, "BillingClaim");

  /**
   * The `transactionSettingId` is different for 837I and 837P. These are strings that are managed
   * inside of Stedi partnership configs. Changing them will break the integration.
   */
  const transactionSettingId = type === "837I" ? "X12-837I" : "X12-837P";

  /**
   * The `filename` is different for 837I and 837P. Waystar requires that for 837I we use the `CLI`
   * suffix, and the `CLP` for 837P.
   */
  const filename = `${billingClaimId}_${mapDateToCCYYMMDD(new Date(), "UTC")}.${
    type === "837I" ? "CLI" : "CLP"
  }`;

  try {
    const request = await axios.post(
      generateUrl(stediPartnershipId),
      {
        filename,
        interchangeAuthorization: {
          authorizationInformationQualifier: "00",
          securityInformationQualifier: "00",
        },
        /**
         * The `interchangeUsageIndicatorOverride` is different for prod and non-prod. This is a
         * Stedi-specific setting that tells Stedi to use the production or test endpoint for Waystar.
         */
        interchangeUsageIndicatorOverride,
        transactionGroups: [
          {
            transactionSettingId,
            transactions: [transaction],
          },
        ],
      },
      {
        headers: {
          Authorization: `Key ${process.env.STEDI_API_KEY}`,
        },
      }
    );

    const response = request.data as unknown as StediResponse;

    ability.can("update", "BillingClaim") &&
      (await prisma.billingClaim.update({
        where: { id: billingClaimId },
        data: {
          lastSubmittedAt: new Date(),
        },
      }));

    return response;
  } catch (error) {
    logger.error("Error submitting claim to Stedi", error);

    throw error;
  }
}

export class X12837PaymentFormatError extends Error {
  constructor() {
    super("The electronic payment format must be 837I or 837P");
  }
}

async function handleBillingClaim(
  prisma: PrismaClient,
  ability: AppAbility,
  billingClaimId: string,
  interchangeUsageIndicatorOverride: "P" | "T"
): Promise<StediResponse> {
  switch (await getElectronicPaymentFormat(prisma, ability, billingClaimId)) {
    case "837I":
      return await push(
        prisma,
        ability,
        "837I",
        billingClaimId,
        await getStediPartnershipId(prisma, ability, billingClaimId),
        await createX12837I(prisma, ability, { id: billingClaimId }),
        interchangeUsageIndicatorOverride
      );
    case "837P":
      return await push(
        prisma,
        ability,
        "837P",
        billingClaimId,
        await getStediPartnershipId(prisma, ability, billingClaimId),
        await createX12837P(prisma, ability, { id: billingClaimId }),
        interchangeUsageIndicatorOverride
      );
    default:
      throw new X12837PaymentFormatError();
  }
}

export async function processBillingClaim(
  prisma: PrismaClient,
  ability: AppAbility,
  billingClaimId: string
): Promise<StediResponse> {
  const interchangeUsageIndicatorOverride = process.env.INFRA_ENV === "prod" ? "P" : "T";

  return await handleBillingClaim(
    prisma,
    ability,
    billingClaimId,
    interchangeUsageIndicatorOverride
  );
}

export async function testBillingClaim(
  prisma: PrismaClient,
  ability: AppAbility,
  billingClaimId: string
) {
  const interchangeUsageIndicatorOverride = "T";

  return await handleBillingClaim(
    prisma,
    ability,
    billingClaimId,
    interchangeUsageIndicatorOverride
  );
}

export async function validateBillingClaim(
  prisma: PrismaClient,
  ability: AppAbility,
  billingClaimId: string
) {
  switch (await getElectronicPaymentFormat(prisma, ability, billingClaimId)) {
    case "837I":
      return [
        "837I",
        billingClaimId,
        await getStediPartnershipId(prisma, ability, billingClaimId),
        await createX12837I(prisma, ability, { id: billingClaimId }),
      ];
    case "837P":
      return [
        "837P",
        billingClaimId,
        await getStediPartnershipId(prisma, ability, billingClaimId),
        await createX12837P(prisma, ability, { id: billingClaimId }),
      ];
    default:
      throw new X12837PaymentFormatError();
  }
}
