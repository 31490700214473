import type { AppAbility, AbilitySubjects } from "./ability/permissions";

export class AbilityError extends Error {
  constructor(
    public readonly subject: string,
    public readonly action: string,
    public readonly message: string,
    public readonly cause?: unknown
  ) {
    super(message, { cause });
  }
}

function throwAbilityError(action: string, subject: string, field?: string) {
  return new AbilityError(
    subject,
    action,
    `Not authorized to ${action} a ${subject} record${field ? " with ${field}." : "."}`
  );
}

export function assertAbility(
  ability: AppAbility,
  action: string,
  subject: AbilitySubjects,
  field?: string
) {
  if (!ability.can(action, subject, field)) {
    throw throwAbilityError(action, subject as string);
  }
}

export function assertCreate(ability: AppAbility, subject: AbilitySubjects, field?: string) {
  assertAbility(ability, "create", subject, field);
}

export function assertRead(ability: AppAbility, subject: AbilitySubjects, field?: string) {
  assertAbility(ability, "read", subject, field);
}

export function assertUpdate(ability: AppAbility, subject: AbilitySubjects, field?: string) {
  assertAbility(ability, "update", subject, field);
}

export function assertDelete(ability: AppAbility, subject: AbilitySubjects, field?: string) {
  assertAbility(ability, "delete", subject, field);
}
