import { z } from 'zod';

/////////////////////////////////////////
// BILLING MODIFIER SCHEMA
/////////////////////////////////////////

export const BillingModifierSchema = z.object({
  id: z.string(),
  modifierCode: z.string(),
  name: z.string(),
  description: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type BillingModifier = z.infer<typeof BillingModifierSchema>

export default BillingModifierSchema;
