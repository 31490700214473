import { z } from 'zod';
import { BillingPayerTypeSchema } from '../inputTypeSchemas/BillingPayerTypeSchema'

/////////////////////////////////////////
// BILLING PAYER SCHEMA
/////////////////////////////////////////

export const BillingPayerSchema = z.object({
  paymentType: BillingPayerTypeSchema,
  id: z.string(),
  sequenceNumber: z.number().int(),
  billingCaseId: z.string(),
  paymentInsuranceId: z.string().nullish(),
  paymentWorkersCompId: z.string().nullish(),
  paymentSelfPayId: z.string().nullish(),
  paymentLetterOfProtectionId: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  notes: z.string(),
  waystarInsuranceProviderId: z.string().nullish(),
})

export type BillingPayer = z.infer<typeof BillingPayerSchema>

export default BillingPayerSchema;
