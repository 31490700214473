import { assertRead, type AppAbility } from "@procision-software/auth";
import type { PrismaClient } from "@procision-software/database";
import type { BillingPayer, Facility } from "@procision-software/database-zod";
import { formatDate } from "@procision-software/ui/src/utils/dates";
import { sortBy, uniqBy } from "lodash";
import {
  getBillingCasePayers,
  getBillingCaseSummary,
  toBillingCaseId,
} from "~/billing/models/case";
import { getFacilityWithBillingOrganization } from "~/models/facility";
import { AMERICAN_DATE_4Y } from "~/utils/dates";
import isBlank from "~/utils/is-blank";
import {
  augmentClaimWithBillingAmounts,
  getBillingClaim,
  toBillingClaimId,
} from "../../models/claim";
import {
  InternalBillingPayerSchema,
  mapBillingOrganization,
  mapFrequencyCode,
  mapPatient,
  mapSurgeon,
  type InternalBillingClaimFilter,
  type InternalBillingPayer,
  type InternalPatient,
} from "./shared";

export async function mapBillingPayer(
  prisma: PrismaClient,
  _facility: Facility,
  patient: InternalPatient,
  {
    id: billingPayerId,
    paymentInsuranceId,
    paymentLetterOfProtectionId,
    paymentType,
    paymentSelfPayId,
    paymentWorkersCompId,
    waystarInsuranceProviderId,
  }: BillingPayer
) {
  switch (paymentType) {
    case "Insurance": {
      const {
        externalId: paymentInsuranceWaystarInsuranceProviderExternalId,
        name: paymentInsuranceWaystarInsuranceProviderName,
        waystarProviderGroupId: paymentInsuranceWaystarInsuranceProviderWaystarProviderGroupId,
      } = await prisma.waystarInsuranceProvider.findFirstOrThrow({
        select: {
          externalId: true,
          name: true,
          waystarProviderGroupId: true,
          waystarProviderGroup: {
            select: {
              canonicalName: true,
            },
          },
        },
        where: {
          id: waystarInsuranceProviderId!,
        },
      });

      const { canonicalName: paymentInsuranceWaystarPayerGroupCanonicalName } =
        await prisma.waystarPayerGroup.findFirstOrThrow({
          select: {
            canonicalName: true,
          },
          where: {
            id: paymentInsuranceWaystarInsuranceProviderWaystarProviderGroupId!,
          },
        });

      const {
        authorizationNumber: paymentInsuranceAuthorizationNumber,
        firstName: paymentInsuranceFirstName,
        groupNumber: paymentInsuranceGroupNumber,
        lastName: paymentInsuranceLastName,
        middleName: paymentInsuranceMiddleName,
        policyNumber: paymentInsurancePolicyNumber,
        provider: paymentInsuranceProvider,
      } = await prisma.paymentInsurance.findFirstOrThrow({
        select: {
          authorizationNumber: true,
          firstName: true,
          groupNumber: true,
          lastName: true,
          middleName: true,
          policyNumber: true,
          provider: {
            select: {
              name: true,
              claimAddress: {
                select: {
                  address1: true,
                  address2: true,
                  city: true,
                  state: true,
                  zip: true,
                },
              },
            },
          },
        },
        where: {
          id: paymentInsuranceId!,
        },
      });

      const paymentInsurancePayerName = paymentInsuranceProvider?.name;
      const paymentInsuranceAddress1 = paymentInsuranceProvider?.claimAddress?.address1;
      const paymentInsuranceAddress2 = paymentInsuranceProvider?.claimAddress?.address2;
      const paymentInsuranceCity = paymentInsuranceProvider?.claimAddress?.city;
      const paymentInsuranceState = paymentInsuranceProvider?.claimAddress?.state;
      const paymentInsuranceZip = paymentInsuranceProvider?.claimAddress?.zip;

      return InternalBillingPayerSchema.parse(
        {
          id: billingPayerId,
          occurrence: null,
          occurrenceCode: "11", // this will be thrown away later as the occurence (date) is not set
          paymentType,
          address: {
            id: "",
            organizationId: "",
            createdAt: new Date(),
            updatedAt: new Date(),
            address1: paymentInsuranceAddress1 ?? "",
            address2: paymentInsuranceAddress2,
            city: paymentInsuranceCity ?? "",
            state: paymentInsuranceState ?? "",
            zip: paymentInsuranceZip ?? "",
          },
          payment: {
            authorizationNumber: paymentInsuranceAuthorizationNumber,
            groupName: isBlank(paymentInsuranceWaystarPayerGroupCanonicalName)
              ? paymentInsuranceWaystarInsuranceProviderName
              : paymentInsuranceWaystarPayerGroupCanonicalName,
            groupNumber: paymentInsuranceGroupNumber,
            payerId: paymentInsuranceWaystarInsuranceProviderExternalId,
            payerName: paymentInsurancePayerName
              ? paymentInsurancePayerName
              : isBlank(paymentInsuranceWaystarPayerGroupCanonicalName)
                ? paymentInsuranceWaystarInsuranceProviderName
                : paymentInsuranceWaystarPayerGroupCanonicalName,
            policyNumber: paymentInsurancePolicyNumber,
          },
          subscriber: {
            ...patient,
            firstName: paymentInsuranceFirstName,
            lastName: paymentInsuranceLastName,
            middleName: paymentInsuranceMiddleName ?? "",
          },
        } satisfies InternalBillingPayer,
        { path: ["billingPayer", billingPayerId, "paymentInsurance", paymentInsuranceId!] }
      );
    }
    case "Letter_Of_Protection": {
      const {
        accidentDate: paymentLetterProtectionAccidentDate,
        address1: paymentLetterProtectionAddress1,
        address2: paymentLetterProtectionAddress2,
        attorney: paymentLetterProtectionAttorney,
        city: paymentLetterProtectionCity,
        state: paymentLetterProtectionState,
        zip: paymentLetterProtectionZip,
      } = await prisma.paymentLetterProtection.findFirstOrThrow({
        select: {
          accidentDate: true,
          address1: true,
          address2: true,
          attorney: true,
          city: true,
          state: true,
          zip: true,
        },
        where: {
          id: paymentLetterOfProtectionId!,
        },
      });

      /**
       * This looks jank, but it's desired behavior in the EDI payload.
       */
      const paymentLetterProtectionPolicyNumber = paymentLetterProtectionAccidentDate
        ? `DOI ${formatDate(paymentLetterProtectionAccidentDate, "UTC", AMERICAN_DATE_4Y)}` // accident could have been in any tz we save as UTC
        : "";
      // the billing case needs an occurenceCode field
      // if occurenceCode is blank, leave occurrence & occurenceCode off
      // if it's set, include it and the date
      const { occurrenceCode: paymentLOPOccurrenceCode } =
        await prisma.billingCase.findFirstOrThrow({
          select: {
            occurrenceCode: true,
          },
          where: {
            billingPayers: {
              some: {
                id: billingPayerId,
              },
            },
          },
        });

      return InternalBillingPayerSchema.parse(
        {
          id: billingPayerId,
          occurrence: paymentLOPOccurrenceCode ? paymentLetterProtectionAccidentDate : null, // date only shows up if user picked a code, and should not prepend with text "DOI " https://procisionworkspace.slack.com/archives/C04J7C5UYSY/p1719420733346019
          occurrenceCode: paymentLOPOccurrenceCode,
          paymentType,
          address: {
            id: "",
            organizationId: "",
            createdAt: new Date(),
            updatedAt: new Date(),
            address1: paymentLetterProtectionAddress1,
            address2: paymentLetterProtectionAddress2,
            city: paymentLetterProtectionCity,
            state: paymentLetterProtectionState,
            zip: paymentLetterProtectionZip,
          },
          payment: {
            authorizationNumber: "",
            groupName: "",
            groupNumber: paymentLetterProtectionPolicyNumber,
            payerId: "98999",
            payerName: paymentLetterProtectionAttorney,
            policyNumber: paymentLetterProtectionPolicyNumber,
          },
          subscriber: {
            ...patient,
          },
        } satisfies InternalBillingPayer,
        {
          path: [
            "billingPayer",
            billingPayerId,
            "paymentLetterProtection",
            paymentLetterOfProtectionId!,
          ],
        }
      );
    }
    case "Self_Pay": {
      return InternalBillingPayerSchema.parse(
        {
          id: billingPayerId,
          occurrence: null,
          occurrenceCode: "11",
          paymentType,
          address: {
            id: "",
            organizationId: "",
            createdAt: new Date(),
            updatedAt: new Date(),
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
          },
          payment: {
            authorizationNumber: "",
            groupName: "",
            groupNumber: "",
            payerId: "",
            payerName: "",
            policyNumber: "",
          },
          subscriber: {
            ...patient,
          },
        } satisfies InternalBillingPayer,
        { path: ["billingPayer", billingPayerId, "paymentSelfPay", paymentSelfPayId!] }
      );
    }
    case "Workers_Comp": {
      const {
        externalId: paymentWorkersCompWaystarInsuranceProviderExternalId,
        name: paymentWorkersCompWaystarInsuranceProviderName,
        waystarProviderGroupId: paymentWorkersCompWaystarInsuranceProviderWaystarProviderGroupId,
      } = await prisma.waystarInsuranceProvider.findFirstOrThrow({
        select: {
          externalId: true,
          name: true,
          waystarProviderGroupId: true,
          waystarProviderGroup: {
            select: {
              canonicalName: true,
            },
          },
        },
        where: {
          id: waystarInsuranceProviderId!,
        },
      });

      const { canonicalName: paymentWorkersCompWaystarPayerGroupCanonicalName } =
        await prisma.waystarPayerGroup.findFirstOrThrow({
          select: {
            canonicalName: true,
          },
          where: {
            id: paymentWorkersCompWaystarInsuranceProviderWaystarProviderGroupId!,
          },
        });

      const {
        authorizationNumber: paymentWorkersCompAuthorizationNumber,
        claimNumber: paymentWorkersCompClaimNumber,
        provider: paymentWorkersCompProvider,
        dateOfInjury: paymentWorkersCompDateOfInjury,
      } = await prisma.paymentWorkersComp.findFirstOrThrow({
        select: {
          authorizationNumber: true,
          claimNumber: true,
          dateOfInjury: true,
          provider: {
            select: {
              name: true,
              claimAddress: {
                select: {
                  address1: true,
                  address2: true,
                  city: true,
                  state: true,
                  zip: true,
                },
              },
            },
          },
        },
        where: {
          id: paymentWorkersCompId!,
        },
      });

      const paymentWorkersCompPayerName = paymentWorkersCompProvider?.name;
      const paymentWorkersCompAddress1 = paymentWorkersCompProvider?.claimAddress?.address1;
      const paymentWorkersCompAddress2 = paymentWorkersCompProvider?.claimAddress?.address2;
      const paymentWorkersCompCity = paymentWorkersCompProvider?.claimAddress?.city;
      const paymentWorkersCompState = paymentWorkersCompProvider?.claimAddress?.state;
      const paymentWorkersCompZip = paymentWorkersCompProvider?.claimAddress?.zip;

      /**
       * This looks jank, but it's desired behavior in the EDI payload.
       */
      const paymentWorkersCompGroupNumber = paymentWorkersCompDateOfInjury
        ? `DOI ${formatDate(paymentWorkersCompDateOfInjury, "UTC", AMERICAN_DATE_4Y)}` // date of injury is UTC as it may be different from TZ of treatment
        : "";
      // the billing case needs an occurenceCode field
      // if occurenceCode is blank, leave occurrence & occurenceCode off
      // if it's set, include it and the date
      const { occurrenceCode: paymentWorkersCompOccurrenceCode } =
        await prisma.billingCase.findFirstOrThrow({
          select: {
            occurrenceCode: true,
          },
          where: {
            billingPayers: {
              some: {
                id: billingPayerId,
              },
            },
          },
        });
      return InternalBillingPayerSchema.parse(
        {
          id: billingPayerId,
          occurrenceCode: paymentWorkersCompOccurrenceCode,
          occurrence: paymentWorkersCompOccurrenceCode ? paymentWorkersCompDateOfInjury : null,
          paymentType,
          address: {
            id: "",
            organizationId: "",
            createdAt: new Date(),
            updatedAt: new Date(),
            address1: paymentWorkersCompAddress1 ?? "",
            address2: paymentWorkersCompAddress2,
            city: paymentWorkersCompCity ?? "",
            state: paymentWorkersCompState ?? "",
            zip: paymentWorkersCompZip ?? "",
          },
          payment: {
            authorizationNumber: paymentWorkersCompAuthorizationNumber,
            groupName: isBlank(paymentWorkersCompWaystarPayerGroupCanonicalName)
              ? paymentWorkersCompWaystarInsuranceProviderName
              : paymentWorkersCompWaystarPayerGroupCanonicalName,
            groupNumber: paymentWorkersCompGroupNumber,
            payerId: paymentWorkersCompWaystarInsuranceProviderExternalId,
            payerName: paymentWorkersCompPayerName
              ? paymentWorkersCompPayerName
              : isBlank(paymentWorkersCompWaystarPayerGroupCanonicalName)
                ? paymentWorkersCompWaystarInsuranceProviderName
                : paymentWorkersCompWaystarPayerGroupCanonicalName,
            policyNumber: paymentWorkersCompClaimNumber,
          },
          subscriber: {
            ...patient,
          },
        } satisfies InternalBillingPayer,
        { path: ["billingPayer", billingPayerId, "paymentWorkersComp", paymentWorkersCompId!] }
      );
    }
  }
}

export async function mapBillingPayers(
  prisma: PrismaClient,
  facility: Facility,
  patient: InternalPatient,
  billingPayers: BillingPayer[]
): Promise<InternalBillingPayer[]> {
  const internalBillingPayers: InternalBillingPayer[] = [];

  for (const billingPayer of billingPayers) {
    internalBillingPayers.push(await mapBillingPayer(prisma, facility, patient, billingPayer));
  }

  return internalBillingPayers;
}

export async function getX12Payload(
  prisma: PrismaClient,
  ability: AppAbility,
  { id: billingClaimId }: InternalBillingClaimFilter
) {
  assertRead(ability, "BillingClaim");

  const {
    allocations: billingAllocations,
    billingPayer: unmappedBillingPayer,
    charges: unmappedBillingCharges,
    billingCase,
    billingCaseId,
    billedAmount,
    priorPayersAmount,
    expectedAmount,
    adjustmentAmount,
    paymentAmount,
    outstandingAmount,
    ...billingClaim
  } = await augmentClaimWithBillingAmounts(
    prisma,
    await getBillingClaim(prisma, ability, toBillingClaimId(billingClaimId))
  );

  if (unmappedBillingPayer.paymentType === "Self_Pay") {
    throw new Error("Self pay is not supported in X12 837.");
  }

  const previousBillingClaim = await prisma.billingClaim.findFirst({
    include: {
      billingPayer: true,
    },
    orderBy: {
      lastSubmittedAt: "asc",
    },
    where: {
      AND: [
        {
          id: {
            not: billingClaimId,
          },
          billingCaseId,
          lastSubmittedAt: {
            not: null,
          },
          status: {
            in: [
              // "New",
              // "Ready_to_Submit",
              // "Provisional",
              "Billed",
              // "Voided",
              "Denied",
              "Disputed",
              "Done",
            ],
          },
        },
      ],
    },
  });

  const isPrimaryClaim = previousBillingClaim === null;

  const rawBillingCharges = await prisma.billingCharge.findMany({
    where: {
      id: {
        in: unmappedBillingCharges.map(({ id }) => id),
      },
    },
    include: {
      allocations: {
        include: {
          billingAdjustment: true,
        },
      },
      billingChargeMaster: true,
      billingChargeModifiers: {
        include: {
          billingModifier: true,
        },
        orderBy: {
          sequenceNumber: "asc",
        },
      },
      supportingDiagnoses: {
        orderBy: {
          sequenceNumber: "asc",
        },
      },
    },
    orderBy: {
      sequenceNumber: "asc",
    },
  });

  const billingCharges = sortBy(unmappedBillingCharges, ["sequenceNumber"]).map(
    (unmappedBillingCharge) => {
      const { billingChargeMaster, billingChargeModifiers, supportingDiagnoses } =
        rawBillingCharges.find(({ id }) => id === unmappedBillingCharge.id)!;

      return {
        ...unmappedBillingCharge,
        billingChargeMaster,
        billingChargeModifiers,
        supportingDiagnoses,
      };
    }
  );

  const diagnoses = uniqBy(
    billingCharges.flatMap(({ supportingDiagnoses }) =>
      sortBy(supportingDiagnoses, ["sequenceNumber"])
    ),
    "icd10Code"
  );

  const {
    case: { facilityId, patient: unmappedPatient, surgeon: unmappedSurgeon, ...kase },
  } = await getBillingCaseSummary(prisma, ability, toBillingCaseId(billingCase.id));

  const {
    organization: {
      billingOrganizations: [unmappedBillingOrganization],
      ...organization
    },
    ...facility
  } = await getFacilityWithBillingOrganization(prisma, ability, facilityId);

  const chargeAllocations = await prisma.chargeAllocationsView.findMany({
    where: { billingCaseId },
  });

  const totalPaidPerPayer = chargeAllocations.reduce(
    (acc, { billingPayerId, paymentAmount }) => {
      return {
        ...acc,
        [billingPayerId]: (acc[billingPayerId] ?? 0) + paymentAmount,
      };
    },
    {} as Record<string, number>
  );

  const { billingOrganization, billingOrganizationContacts, billingOrganizationStaff } =
    mapBillingOrganization(unmappedBillingOrganization);
  const patient = mapPatient({ facilityId, ...kase }, unmappedPatient!);
  const surgeon = mapSurgeon(unmappedSurgeon);

  const billingPayer = await mapBillingPayer(prisma, facility, patient, unmappedBillingPayer);

  const previousBillingPayer =
    previousBillingClaim === null
      ? null
      : await mapBillingPayer(prisma, facility, patient, previousBillingClaim.billingPayer);

  const { billingPayers: unmappedBillingPayers } = await getBillingCasePayers(
    prisma,
    ability,
    toBillingCaseId(billingCase.id)
  );

  const billingPayers = (
    await mapBillingPayers(
      prisma,
      facility,
      patient,
      unmappedBillingPayers.filter(
        ({ id, paymentType }) => paymentType !== "Self_Pay" && id !== billingPayer.id
      )
    )
  ).filter(
    ({ paymentType, payment: { policyNumber } }) =>
      paymentType === "Insurance" && policyNumber !== ""
  );

  const cptCodeMap = (
    await prisma.cptCode.findMany({
      select: {
        code: true,
        name: true,
      },
      where: {
        code: {
          in: billingCharges
            .filter(({ billingChargeMaster: { cptCode } }) => !isBlank(cptCode))
            .map(({ billingChargeMaster: { cptCode } }) => `${cptCode}`),
        },
      },
    })
  ).reduce((acc, { code, name }) => ({ ...acc, [code]: name }), {});

  const hcpcsCodeMap = (
    await prisma.hcpcsCode.findMany({
      select: {
        code: true,
        shortName: true,
      },
      where: {
        code: {
          in: billingCharges
            .filter(({ billingChargeMaster: { hcpcsCode } }) => !isBlank(hcpcsCode))
            .map(({ billingChargeMaster: { hcpcsCode } }) => `${hcpcsCode}`),
        },
      },
    })
  ).reduce((acc, { code, shortName }) => ({ ...acc, [code]: shortName }), {});

  const billingType = mapFrequencyCode(billingClaim.frequencyCode);

  return {
    billingCase,
    billingClaim,
    previousBillingClaim,
    previousBillingPayer,
    billingAllocations,
    billingCharges,
    billingOrganization,
    billingOrganizationStaff,
    billingOrganizationContacts,
    billingPayer,
    billingPayers,
    billingType,
    codes: {
      ...cptCodeMap,
      ...hcpcsCodeMap,
    },
    diagnoses,
    facility,
    isPrimaryClaim,
    kase,
    organization,
    patient,
    surgeon,
    totalPaidPerPayer,
    billedAmount,
    priorPayersAmount,
    expectedAmount,
    adjustmentAmount,
    paymentAmount,
    outstandingAmount,
  };
}
