import { z } from 'zod';

/////////////////////////////////////////
// CODEABLE CONCEPT SCHEMA
/////////////////////////////////////////

export const CodeableConceptSchema = z.object({
  id: z.string(),
  code: z.string(),
  display: z.string(),
  description: z.string(),
  key: z.boolean(),
  codeableConceptId: z.string().nullish(),
  activeFrom: z.coerce.date(),
  activeTo: z.coerce.date().nullish(),
})

export type CodeableConcept = z.infer<typeof CodeableConceptSchema>

export default CodeableConceptSchema;
