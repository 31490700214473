import { z } from 'zod';
import { YesNoNotApplicableSchema } from '../inputTypeSchemas/YesNoNotApplicableSchema'

/////////////////////////////////////////
// SURGICAL TIMEOUT SCHEMA
/////////////////////////////////////////

export const SurgicalTimeoutSchema = z.object({
  antibioticPropylaxisGiven: YesNoNotApplicableSchema.nullish(),
  markingVisible: YesNoNotApplicableSchema.nullish(),
  id: z.string(),
  confirmPatientIdentity: z.boolean(),
  confirmSiteSideLaterality: z.boolean(),
  confirmProcedure: z.boolean(),
  confirmPosition: z.boolean(),
  confirmConsent: z.boolean(),
  confirmAvailabilityOfImplants: z.boolean(),
  reviewedSpecificPatientConcerns: z.boolean(),
  reviewedEquipmentConcernsSterilitySafety: z.boolean(),
  clinicalEventId: z.string(),
})

export type SurgicalTimeout = z.infer<typeof SurgicalTimeoutSchema>

export default SurgicalTimeoutSchema;
