import { z } from 'zod';

/////////////////////////////////////////
// HEALTH REVIEW REQUIREMENT SCHEMA
/////////////////////////////////////////

export const HealthReviewRequirementSchema = z.object({
  id: z.string(),
  caseId: z.string(),
  healthReviewTypeId: z.string(),
  userId: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  reviewedAt: z.coerce.date().nullish(),
  providerName: z.string().nullish(),
  providerPhone: z.string().nullish(),
})

export type HealthReviewRequirement = z.infer<typeof HealthReviewRequirementSchema>

export default HealthReviewRequirementSchema;
