import { z } from 'zod';
import { OrderStatusSchema } from '../inputTypeSchemas/OrderStatusSchema'

/////////////////////////////////////////
// ORDER SCHEMA
/////////////////////////////////////////

export const OrderSchema = z.object({
  status: OrderStatusSchema,
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  typeId: z.string(),
  orderText: z.string(),
  orderDate: z.coerce.date(),
  validFrom: z.coerce.date(),
  validUntil: z.coerce.date(),
  standingOrderId: z.string().nullish(),
  issuerId: z.string(),
  encounterId: z.string(),
  orderGroupId: z.string().nullish(),
})

export type Order = z.infer<typeof OrderSchema>

export default OrderSchema;
