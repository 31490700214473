import { z } from 'zod';

/////////////////////////////////////////
// CONTRACT LIST MEMBER SCHEMA
/////////////////////////////////////////

export const ContractListMemberSchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  listId: z.string(),
  /**
   * CPT or HCPCS code
   */
  code: z.string(),
})

export type ContractListMember = z.infer<typeof ContractListMemberSchema>

export default ContractListMemberSchema;
