import { z } from 'zod';

/////////////////////////////////////////
// WAYSTAR INSURANCE PROVIDER SCHEMA
/////////////////////////////////////////

export const WaystarInsuranceProviderSchema = z.object({
  id: z.string(),
  name: z.string(),
  externalId: z.string(),
  professionalClaimsAvailable: z.boolean(),
  professionalClaimsNeedsEnrollment: z.boolean(),
  institutionalClaimsAvailable: z.boolean(),
  institutionalClaimsNeedsEnrollment: z.boolean(),
  remitsAvailable: z.boolean(),
  remitsNeedsEnrollment: z.boolean(),
  eligibilityAvailable: z.boolean(),
  eligibilityNeedsEnrollment: z.boolean(),
  claimsMonitoringAvailable: z.boolean(),
  claimsMonitoringNeedsEnrollment: z.boolean(),
  secondaryClaimFormat: z.string(),
  dualClearinghouseFlags: z.string(),
  claimsAttachments: z.boolean(),
  estimationAvailable: z.boolean(),
  estimationNeedsEnrollment: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  waystarProviderGroupId: z.string().nullish(),
})

export type WaystarInsuranceProvider = z.infer<typeof WaystarInsuranceProviderSchema>

export default WaystarInsuranceProviderSchema;
