import { z } from 'zod';

/////////////////////////////////////////
// INSURANCE PROVIDER SCHEMA
/////////////////////////////////////////

export const InsuranceProviderSchema = z.object({
  id: z.string(),
  name: z.string(),
  organizationId: z.string(),
  active: z.boolean(),
  waystarInsuranceProviderId: z.string().nullish(),
  /**
   * 837I or 837P or null
   */
  electronicPaymentFormat: z.string().nullish(),
  claimAddressId: z.string().nullish(),
  claimPhoneNumber: z.string().nullish(),
  claimFaxNumber: z.string().nullish(),
})

export type InsuranceProvider = z.infer<typeof InsuranceProviderSchema>

export default InsuranceProviderSchema;
