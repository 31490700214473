"use client";

import { SearchIcon, XIcon } from "lucide-react";
import { forwardRef, useEffect, useState } from "react";
import { cn } from "../../utils";
import type { InputProps } from "../Forms/Input";
import { Input } from "../Forms/Input";

type Ref = HTMLInputElement;

export type SearchBarProps = Omit<InputProps, "type" | "value" | "onChange" | "leftIcon"> & {
  value?: string | null;
  clearInputAfterSearch?: boolean;
  onSearch: (query: string) => void;
  onClear?: () => void;
};

export const SearchBar = forwardRef<Ref, SearchBarProps>(
  ({ value, onSearch, onClear, clearInputAfterSearch, ...props }, ref) => {
    const [query, setQuery] = useState<string>(value ?? "");
    useEffect(() => setQuery(value ?? ""), [value]);

    return (
      <Input
        {...props}
        placeholder={props.placeholder ?? "Search"}
        ref={ref}
        leftIcon={SearchIcon}
        rightIcon={query?.length || !!value?.length ? XIcon : undefined}
        rightIconProps={{
          onClick: () => {
            onSearch("");
            onClear?.();
          },
          className: "cursor-pointer",
        }}
        name="searchText"
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onSearch(query);
            if (clearInputAfterSearch) setQuery("");
          }
        }}
        className={cn("rounded-full", props.className)}
        selectOnFocus
      />
    );
  }
);
SearchBar.displayName = "SearchBar";
