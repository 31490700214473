import { cn } from "@procision-software/ui";
import React, { forwardRef } from "react";

type RadioProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> & {
  error?: boolean;
  description?: string;
};

/**
 * @deprecated Use UI package components instead
 */
export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ className, error, description, ...props }, ref) => (
    <label
      className={cn(
        "label group flex w-full items-center gap-2 border border-transparent py-2.5 text-sm normal-case text-gray-900",
        props.disabled && "cursor-not-allowed opacity-50",
        error && "text-red-900",
        className
      )}
    >
      <input
        {...props}
        type="radio"
        ref={ref}
        className={cn(
          "input h-4 w-4 rounded-full checked:bg-blue-500 checked:text-white group-focus-within:ring-1 group-focus-within:ring-blue-600",
          props.disabled && "cursor-not-allowed opacity-50",
          error && "input-error"
        )}
      />
      <span>{description}</span>
    </label>
  )
);
Radio.displayName = "Radio";
