import type { z } from "zod";
import { HealthReviewRequirementAttachmentSchema } from "./generated";

export const HealthReviewRequirementAttachmentInputSchema =
  HealthReviewRequirementAttachmentSchema.partial({
    id: true,
    createdAt: true,
    updatedAt: true,
  });

export type HealthReviewRequirementAttachmentInput = z.infer<
  typeof HealthReviewRequirementAttachmentInputSchema
>;
