import { z } from 'zod';

/////////////////////////////////////////
// STAFF SCHEMA
/////////////////////////////////////////

export const StaffSchema = z.object({
  id: z.string(),
  userId: z.string().nullish(),
  organizationId: z.string(),
  active: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  // omitted: name: z.string().nullish(),
  firstName: z.string(),
  middleName: z.string().nullish(),
  lastName: z.string(),
  qualification: z.string().nullish(),
  email: z.string().nullish(),
  mobilePhone: z.string().nullish(),
  officePhone: z.string().nullish(),
  providerSpecialtyId: z.string().nullish(),
  npi: z.string().nullish(),
  taxonomyCode: z.string().nullish(),
  stateLicenseNumber: z.string().nullish(),
  upin: z.string().nullish(),
})

export type Staff = z.infer<typeof StaffSchema>

export default StaffSchema;
