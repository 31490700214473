"use client";

import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import * as React from "react";
import { Drawer as DrawerPrimitive } from "vaul";
import { cn } from "../../utils";
import { Titlebar } from "../Titlebar/Titlebar";
import { Button } from "../Buttons/Button";
import { ChevronsLeftIcon, ChevronsRightIcon } from "lucide-react";

export type DrawerDirection = "left" | "right" | "bottom";

export type DrawerProps = React.ComponentProps<typeof DrawerPrimitive.Root> & {
  direction?: DrawerDirection;
};

const DrawerRoot = ({
  handleOnly = true, // default to only allow dragging by the handle
  noBodyStyles = true, // do not apply body styles (false = body gets fixed position when drawer is open, breaking layouts)
  ...props
}: DrawerProps) => (
  <DrawerPrimitive.Root handleOnly={handleOnly} noBodyStyles={noBodyStyles} {...props} />
);
DrawerRoot.displayName = "Drawer";

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    className={cn("fixed inset-0 z-40 bg-black/80", className)}
    {...props}
  />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

// use `!select-text` to override the vaul default so that users can select text within the drawer
// see: https://github.com/emilkowalski/vaul/issues/346
const drawerContentVariants = cva(
  `fixed z-40 flex !select-text flex-col border border-primary bg-primary text-primary shadow-lg
  focus-visible:outline-none`,
  {
    variants: {
      direction: {
        left: `bottom-0 left-0 h-full w-full min-w-0 max-w-full overflow-y-auto rounded-l-lg
        sm:w-fit`,
        right:
          "bottom-0 right-0 h-full w-full min-w-0 max-w-full overflow-y-auto rounded-l-lg sm:w-fit",
        bottom: "inset-x-0 bottom-0 left-0 right-0 mt-24 h-auto rounded-t-lg",
      } satisfies Record<DrawerDirection, string>,
      showOverlay: {
        true: "",
        false: "",
      },
    },
    defaultVariants: {
      direction: "right",
      showOverlay: false,
    },
  }
);

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> &
    VariantProps<typeof drawerContentVariants>
>(({ direction, className, showOverlay, children, ...props }, ref) => (
  <DrawerPortal>
    {showOverlay && <DrawerOverlay />}
    <DrawerPrimitive.Content
      ref={ref}
      className={cn(drawerContentVariants({ direction, showOverlay }), className)}
      {...props}
    >
      {/* Render the handle bar when opening from the bottom */}
      {direction === "bottom" && (
        <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-secondary" />
      )}
      {children}
    </DrawerPrimitive.Content>
  </DrawerPortal>
));
DrawerContent.displayName = "DrawerContent";

const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>((props, ref) => <DrawerPrimitive.Title ref={ref} {...props} />);
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description
    ref={ref}
    className={cn("text-sm text-secondary", className)}
    {...props}
  />
));
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

const DrawerTitlebar = React.forwardRef<
  React.ElementRef<typeof Titlebar>,
  React.ComponentPropsWithoutRef<typeof Titlebar> & { direction?: DrawerDirection }
>(({ title, actions, direction, className, ...props }, ref) => (
  <>
    <DrawerPrimitive.Title className="sr-only">{title}</DrawerPrimitive.Title>
    <Titlebar
      ref={ref}
      title={title}
      actions={[
        ...(actions ?? []),
        <DrawerClose asChild>
          <Button
            variant="secondary"
            outline
            leftIcon={direction === "left" ? ChevronsLeftIcon : ChevronsRightIcon}
            title="Close Drwaer"
          />
        </DrawerClose>,
      ]}
      className={cn("sticky top-0 border-b border-primary", className)}
      {...props}
    />
  </>
));

/**
 * A popover is a non-modal dialog that floats around a trigger.
 * @param open - Whether or not the popover is open.
 * @param onOpenChange - Callback for when the popover opens or closes.
 * @see https://radix-ui.com/primitives/docs/components/popover
 */
export const Drawer = Object.assign(DrawerRoot, {
  /**
   * A fully customizable trigger that opens a drawer when clicked.
   * @param asChild - If true, the trigger will transfer its props to its child child element.
   * @see https://www.radix-ui.com/primitives/docs/components/dialog#trigger
   */
  Trigger: DrawerTrigger,
  /**
   * The content of a drawer.
   * @see https://www.radix-ui.com/primitives/docs/components/dialog#content
   */
  Content: DrawerContent,
  /**
   * A button that closes the drawer when clicked.
   * @see https://www.radix-ui.com/primitives/docs/components/dialog#close
   */
  Close: DrawerClose,
  /**
   * An accessible title to be announced when the panel is opened.
   * @see https://www.radix-ui.com/docs/primitives/components/dialog#title
   */
  Title: DrawerTitle,
  /**
   * An optional accessible description to be announced when the dialog is opened.
   * @see https://www.radix-ui.com/docs/primitives/components/dialog#description
   */
  Description: DrawerDescription,
  /**
   * A titlebar for the drawer that will stick to the top of the drawer when scrolled.
   * It includes a close button by default.
   */
  Titlebar: DrawerTitlebar,
});
