import type { z } from "zod";
import {
  PaymentWorkersCompAttachmentSchema,
  PaymentWorkersCompAttachmentTypeSchema,
} from "./generated";
import { StorageBlobUserSchema } from "./storage-blob";

export const PaymentWorkersCompAttachmentInputSchema = PaymentWorkersCompAttachmentSchema.partial({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export type PaymentWorkersCompAttachmentInput = z.infer<
  typeof PaymentWorkersCompAttachmentInputSchema
>;

export const PaymentWorkersCompAttachmentStorageBlobUserSchema =
  PaymentWorkersCompAttachmentSchema.extend({
    paymentWorkersCompAttachmentType: PaymentWorkersCompAttachmentTypeSchema,
    storageBlob: StorageBlobUserSchema,
  });

export type PaymentWorkersCompAttachmentStorageBlobUser = z.infer<
  typeof PaymentWorkersCompAttachmentStorageBlobUserSchema
>;
