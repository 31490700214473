import { z } from 'zod';

/////////////////////////////////////////
// MESSAGE SCHEMA
/////////////////////////////////////////

export const MessageSchema = z.object({
  id: z.string(),
  messageThreadId: z.string(),
  userId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  /**
   * PHI
   */
  body: z.string(),
})

export type Message = z.infer<typeof MessageSchema>

export default MessageSchema;
