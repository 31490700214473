import { z } from 'zod';

/////////////////////////////////////////
// HEALTH REVIEW TYPE SCHEMA
/////////////////////////////////////////

export const HealthReviewTypeSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  name: z.string(),
  label: z.string(),
})

export type HealthReviewType = z.infer<typeof HealthReviewTypeSchema>

export default HealthReviewTypeSchema;
