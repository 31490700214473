export function Logo({ className }: { className?: string }) {
  return (
    <svg fill="none" viewBox="0 0 620 620" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M560.279 165.805C560.279 181.521 557.136 197.237 550.064 210.596C546.134 219.24 540.634 227.883 534.347 234.956C524.132 246.743 512.345 256.172 498.2 262.459C495.843 263.245 493.486 264.03 491.128 265.602C474.626 273.46 463.625 290.748 463.625 309.607C463.625 329.252 472.998 346.356 489.5 355C476 351 467.5 348.5 454.981 348.112C452.624 348.112 449.481 348.112 447.123 348.112C436.908 348.897 427.478 351.255 418.834 354.398C412.548 341.039 408.619 325.323 408.619 309.607C408.619 293.891 411.762 278.175 418.834 264.816C422.763 256.172 428.264 247.529 434.55 240.456C443.98 228.669 456.553 220.025 469.911 213.739C473.055 212.953 475.412 211.381 477.769 210.596C493.486 201.952 504.487 185.45 504.487 165.805C504.487 138.302 481.699 115.513 454.195 115.513C434.55 115.513 418.048 126.515 409.404 143.016C412.548 131.229 415.691 119.442 415.691 106.869C415.691 104.512 415.691 101.369 415.691 99.0114C414.905 88.7959 412.548 79.3663 409.404 70.7224C422.763 64.436 438.479 60.507 454.195 60.507C512.345 59.7212 560.279 106.869 560.279 165.805Z"
        fill="#5BD1BC"
      />
      <path
        d="M348.898 173.663C349.683 183.878 352.041 193.308 355.184 201.952C341.825 208.238 326.109 212.167 310.393 212.167C294.677 212.167 278.961 209.024 265.602 201.952C256.958 198.023 248.315 192.522 241.242 186.236C229.455 176.806 220.811 164.233 214.525 150.875C213.739 148.517 212.953 146.16 211.382 143.016C201.952 124.943 181.521 113.156 159.519 116.299C137.516 119.442 120.228 137.516 117.085 158.733C113.942 181.521 125.729 201.166 143.803 210.596C132.801 206.667 120.228 204.309 107.655 204.309C105.298 204.309 102.155 204.309 99.7974 204.309C89.582 205.095 80.1523 207.452 71.5085 210.596C64.4362 195.665 61.293 178.378 62.0788 161.09C64.4362 107.655 107.655 64.436 160.304 60.507C179.164 58.9354 196.451 62.8644 212.168 69.9366C220.811 73.8657 229.455 79.3663 236.527 85.6527C248.315 95.0824 256.958 107.655 264.031 121.014C264.816 124.157 266.388 126.515 267.174 128.872C275.818 144.588 292.32 155.589 311.965 155.589C331.61 155.589 348.112 144.588 356.756 128.872C352.827 139.873 350.469 152.446 350.469 165.019C348.112 168.162 348.112 171.305 348.898 173.663Z"
        fill="#5BD1BC"
      />
      <path
        d="M265.602 201.952C254.601 231.027 231.812 254.601 201.952 265.602C193.308 268.745 183.878 271.103 173.663 271.888C171.305 271.888 168.162 271.888 165.805 271.888C153.232 271.888 140.659 269.531 128.872 265.602C126.515 264.03 124.157 263.245 121.8 262.459C117.085 260.887 111.584 260.101 106.084 260.101C78.5805 260.101 55.7921 282.89 55.7921 310.393C55.7921 337.896 78.5805 360.684 106.084 360.684C111.584 360.684 116.299 359.899 121.8 358.327C107.655 364.613 95.8682 374.043 85.6527 385.83C79.3663 392.902 73.8656 401.546 69.9366 410.19C29.0748 394.474 0 355.97 0 309.607C0 264.03 29.0748 224.74 69.9366 209.81C78.5805 206.667 88.0101 204.309 98.2256 203.523C100.583 203.523 103.726 203.523 106.084 203.523C118.657 203.523 131.229 205.881 142.231 209.81C144.588 211.381 147.731 212.167 150.089 212.953C154.804 214.525 159.518 215.311 165.019 215.311C192.522 215.311 215.311 192.522 215.311 165.019C215.311 159.518 216 155.5 214.5 151C222.358 165.144 228.999 175.57 240 185C248.644 192.072 256.172 198.023 265.602 201.952Z"
        fill="#2F4A8A"
      />
      <path
        d="M210.596 477.769C206.667 488.771 204.309 501.343 204.309 513.916C204.309 516.274 204.309 519.417 204.309 521.774C205.095 531.99 207.452 541.419 210.596 550.063C197.237 556.35 181.521 560.279 165.805 560.279C107.655 560.279 59.7212 513.131 59.7212 454.195C59.7212 438.479 62.8644 422.763 69.9367 409.404C73.8657 400.76 79.3663 392.117 85.6527 385.044C95.8682 373.257 107.655 363.828 121.8 357.541C124.157 356.755 126.515 355.97 128.872 354.398C145.374 345.754 156.375 329.252 156.375 309.607C156.375 289.962 147.002 273.858 130.5 266C130.5 266.786 152.446 271.103 165.805 271.103C168.162 271.103 171.305 271.103 173.663 271.103C183.878 270.317 193.308 267.959 201.952 264.816C208.238 278.175 212.167 293.891 212.167 309.607C212.167 325.323 209.024 341.039 201.952 354.398C198.023 363.042 192.522 371.686 186.236 378.758C176.806 390.545 164.233 399.189 150.875 406.261C147.731 407.047 145.374 408.618 143.016 409.404C127.3 418.048 116.299 434.55 116.299 454.195C116.299 482.484 139.087 504.487 166.591 504.487C185.45 504.487 201.952 493.485 210.596 477.769Z"
        fill="#5BD1BC"
      />
      <path
        d="M409.404 550.063C394.474 590.925 355.969 620 309.607 620C264.03 620 224.74 590.925 209.81 550.063C206.667 541.419 204.309 531.99 203.523 521.774C203.523 519.417 203.523 516.274 203.523 513.916C203.523 501.343 205.881 488.771 209.81 477.769C211.381 475.412 212.167 473.054 212.953 469.911C214.525 465.196 215.31 460.482 215.31 454.981C215.31 427.478 193.003 402.714 165.5 403.5C159.999 403.5 155.429 404.429 151.5 406C161 400.811 176.02 391.331 185.45 379.544C191.736 372.471 197.237 363.828 201.166 355.184C230.241 366.185 253.815 388.973 264.816 418.834C267.959 427.478 270.317 436.907 271.103 447.123C271.103 449.48 271.103 452.624 271.103 454.981C271.103 467.554 268.745 480.127 264.816 491.128C263.245 493.485 262.459 496.629 261.673 498.986C260.101 503.701 259.315 508.416 259.315 513.916C259.315 541.42 282.104 564.208 309.607 564.208C337.896 564.208 359.898 541.42 359.898 513.916C359.898 508.416 359.113 503.701 357.541 498.986C363.828 512.345 373.257 524.918 384.258 534.347C392.902 540.634 400.76 546.134 409.404 550.063Z"
        fill="#2F4A8A"
      />
      <path
        d="M559.279 454.195C559.279 512.344 512.131 560.279 453.195 560.279C437.479 560.279 421.763 557.135 408.404 550.063C399.76 546.134 391.902 540.633 384.044 534.347C372.257 524.917 363.613 512.344 357.327 498.986C356.541 496.628 354.97 493.485 354.184 491.128C345.54 475.412 329.038 464.41 309.393 464.41C289.748 464.41 273.246 475.412 264.602 491.128C268.531 480.126 270.888 467.554 270.888 454.981C270.888 452.623 270.888 449.48 270.888 447.123C270.103 436.907 267.745 427.477 264.602 418.834C277.961 412.547 293.677 408.618 309.393 408.618C325.109 408.618 340.825 411.761 354.184 418.834C362.828 422.763 371.471 428.263 378.544 434.55C390.331 444.765 399.76 456.552 406.047 470.697C406.833 473.054 407.619 475.412 409.19 477.769C417.834 494.271 434.336 505.272 453.981 505.272C481.484 505.272 504.273 482.484 504.273 454.981C504.273 435.336 491.216 418.144 475.5 409.5C487.287 412.643 500.798 415.024 513.5 415.5C515.857 415.5 515.143 415.5 517.5 415.5C527.94 413.524 540.856 412.143 549.5 409C555.001 421.573 559.279 438.479 559.279 454.195Z"
        fill="#5BD1BC"
      />
      <path
        d="M617.816 309.797C617.816 355.374 588.741 394.664 547.88 409.594C539.236 412.738 529.806 415.095 519.591 415.881C517.233 415.881 514.09 415.881 511.733 415.881C499.16 415.881 486.587 413.523 475.586 409.594C473.228 408.023 470.085 407.237 467.727 406.451C463.013 404.88 458.298 404.094 452.797 404.094C424.508 404.094 402.506 426.882 402.506 454.385C402.506 459.886 401.857 457.499 405 468.5C398 454.5 389.147 443.384 377.36 433.954C370.288 427.668 361.644 422.167 353 418.238C364.001 389.163 386.79 365.589 416.65 354.588C425.294 351.445 434.724 349.087 444.939 348.302C447.297 348.302 450.44 348.302 452.797 348.302C465.37 348.302 477.943 350.659 488.944 354.588C491.302 356.16 493.659 356.945 496.802 357.731C501.517 359.303 507.018 360.089 512.518 360.089C540.807 360.089 562.81 337.3 562.81 309.797C562.81 282.294 540.022 259.506 512.518 259.506C507.018 259.506 504.215 260.428 499.5 262C513.644 255.714 523.52 246.147 532.949 234.36C539.236 227.288 544.736 218.644 548.665 210C588.741 225.716 617.816 264.221 617.816 309.797Z"
        fill="#2F4A8A"
      />
      <path
        d="M469.5 214C455.356 220.286 443.93 229.499 434.5 240.5C428.214 247.572 422.429 256.356 418.5 265C389.425 253.999 365.399 231.027 354.398 201.166C351.255 192.522 348.898 183.093 348.112 172.877C348.112 170.52 348.112 167.376 348.112 165.019C348.112 152.446 350.469 139.873 354.398 128.872C355.97 126.515 356.756 123.371 357.541 121.014C359.113 116.299 359.899 111.584 359.899 106.084C359.899 78.5805 337.11 55.7921 309.607 55.7921C282.104 55.7921 259.5 77.9968 259.5 105.5C259.5 111.001 261.143 114.499 263.5 120C254.856 103.498 245.957 95.0824 234.17 85.6527C227.098 79.3663 218.454 73.8656 209.81 69.9366C225.526 29.0748 264.031 0 309.607 0C355.184 0 394.474 29.0748 409.404 69.9366C412.548 78.5805 414.905 88.0101 415.691 98.2256C415.691 100.583 415.691 103.726 415.691 106.084C415.691 118.657 413.333 131.229 409.404 142.231C407.833 144.588 407.047 146.945 406.261 150.089C404.69 154.804 403.904 160.304 403.904 165.019C403.904 193.308 426.692 215.311 454.195 215.311C459.696 216.096 464.785 215.572 469.5 214Z"
        fill="#2F4A8A"
      />
    </svg>
  );
}
