import { Prisma, type PrismaClient } from "@procision-software/database";
import type { PaginationInput } from "../../util/pagination";
import { codeableConceptIdForPath } from "../codeable-concept";
import { generateDocumentPreview } from "../tip-tap-editor";
import { type EncounterDocumentFormData, type EncounterDocumentRecord } from "./types";

type EncounterDocumentFindFilter = {
  encounterId: string;
  typeId?: string;
};
const RELATED_MODELS = {
  documentTemplate: {
    include: {
      organization: true,
      type: true,
    } as Prisma.DocumentTemplateInclude,
  },
  type: true,
  signatures: {
    include: {
      appliedJobRole: true,
      appliedPatientSignature: {
        include: {
          patient: true,
        },
      },
      appliedStaffSignature: {
        include: {
          staff: true,
        },
      },
    },
  },
} satisfies Prisma.EncounterDocumentInclude;

async function encounterDocumentWhereClause(
  prisma: PrismaClient,
  filters: EncounterDocumentFindFilter
): Promise<Prisma.EncounterDocumentWhereInput> {
  const where: Prisma.EncounterDocumentWhereInput = {
    encounterId: filters.encounterId,
  };
  if (filters.typeId) {
    if (filters.typeId.includes(".")) {
      where.typeId = await codeableConceptIdForPath(prisma, filters.typeId.split("."));
    } else {
      where.typeId = filters.typeId;
    }
  }
  return where;
}

export async function encounterDocumentFindMany(
  prisma: PrismaClient,
  filters: EncounterDocumentFindFilter,
  pagination: PaginationInput
): Promise<EncounterDocumentRecord[]> {
  const where = await encounterDocumentWhereClause(prisma, filters);
  const records = await prisma.encounterDocument.findMany({
    where,
    skip: (pagination.page - 1) * pagination.perPage,
    take: pagination.perPage,
    include: RELATED_MODELS,
  });
  return records as EncounterDocumentRecord[];
}

export async function encounterDocumentCount(
  prisma: PrismaClient,
  filters: EncounterDocumentFindFilter
): Promise<number> {
  const where = await encounterDocumentWhereClause(prisma, filters);
  return await prisma.encounterDocument.count({ where });
}

export async function encounterDocumentFindById(
  prisma: PrismaClient,
  id: string
): Promise<EncounterDocumentRecord> {
  return await prisma.encounterDocument.findFirstOrThrow({
    where: { id },
    include: RELATED_MODELS,
  });
}

export async function encounterDocumentNameAvailable(
  prisma: PrismaClient,
  encounterId: string,
  title: string
): Promise<boolean> {
  return (
    (await prisma.encounterDocument.count({
      where: {
        encounterId,
        title,
      },
    })) === 0
  );
}

export async function encounterDocumentCreate(
  prisma: PrismaClient,
  { typeId, encounterId, documentTemplateId, body, ...data }: EncounterDocumentFormData
): Promise<EncounterDocumentRecord> {
  const localTypeId = await (typeId.includes(".")
    ? codeableConceptIdForPath(prisma, typeId.split("."))
    : typeId);
  await prisma.encounter.findFirstOrThrow({
    where: { id: encounterId },
  });
  let validName = await encounterDocumentNameAvailable(prisma, encounterId, data.title),
    count = 1;
  while (!validName) {
    count++;
    const proposedTitle = `${data.title} (Copy ${count})`;
    validName = await encounterDocumentNameAvailable(prisma, encounterId, proposedTitle);
    if (validName) {
      data.title = proposedTitle;
    }
  }
  const newDoc = await prisma.encounterDocument.create({
    data: {
      encounter: { connect: { id: encounterId } },
      ...(documentTemplateId && { documentTemplate: { connect: { id: documentTemplateId } } }),
      type: { connect: { id: localTypeId } },
      body: body ?? Prisma.JsonNull,
      preview: generateDocumentPreview(body),
      ...data,
    },
    include: RELATED_MODELS,
  });
  return newDoc;
}

export async function encounterDocumentUpdate(
  prisma: PrismaClient,
  id: string,
  {
    typeId,
    body,
    ...data
  }: Partial<Omit<EncounterDocumentFormData, "documentTemplateId" | "encounterId">>
): Promise<EncounterDocumentRecord> {
  await prisma.encounterDocument.update({
    where: { id },
    data: {
      type: typeId ? { connect: { id: typeId } } : undefined,
      ...(body && {
        body,
        preview: generateDocumentPreview(body),
      }),
      ...data,
    },
  });
  return encounterDocumentFindById(prisma, id);
}

export async function encounterDocumentDelete(prisma: PrismaClient, id: string): Promise<boolean> {
  await prisma.encounterDocument.findFirstOrThrow({
    where: { id },
  });
  await prisma.providerEncounterDocument.deleteMany({
    where: { encounterDocumentId: id },
  });
  await prisma.encounterDocument.delete({
    where: { id },
  });

  return true;
}
