import type { DateObjectUnits } from "luxon";

/*
 * Converts a time string to a Luxon DateObjectUnits object.
 * @param {string} time - A time string in the format "HH:mm:ss:S".
 * @returns {DateObjectUnits}
 */
export const timeStringToDateUnits = (time: string): DateObjectUnits => {
  const [hour, minute, second, millisecond] =
    time?.length > 0 ? time.split(":").map((n) => parseInt(n, 10)) : [0, 0, 0, 0];
  return {
    hour: hour ?? 0,
    minute: minute ?? 0,
    second: second ?? 0,
    millisecond: millisecond ?? 0,
  };
};

/**
 *
 * @param min quantity of minutes
 * @returns string in form of "h:mm"
 */
export const minutesAsHours = (min: number): string => {
  const h = Math.floor(min / 60);
  const m = min % 60;
  return `${h}:${m < 10 ? "0" : ""}${m}`;
};
