import { z } from 'zod';

/////////////////////////////////////////
// BILLING ORGANIZATION SCHEMA
/////////////////////////////////////////

export const BillingOrganizationSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  staffId: z.string().nullish(),
  stediPartnershipId: z.string().nullish(),
})

export type BillingOrganization = z.infer<typeof BillingOrganizationSchema>

export default BillingOrganizationSchema;
