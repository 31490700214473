import Link from "next/link";
import type { AnchorHTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";
import { cn } from "../../utils";
import { Icon } from "../Icons";
import { buttonVariants, type ButtonVariantProps } from "./Button";

type Ref = HTMLAnchorElement;

type ElementProps = Omit<AnchorHTMLAttributes<Ref>, "className" | "onClick" | "children">;

export type LinkButtonProps = ElementProps &
  ButtonVariantProps & {
    className?: string;
    loading?: boolean;
    disabled?: boolean;
    children?: ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    onClick?: (event: React.MouseEvent<Ref, MouseEvent>) => Promise<unknown> | unknown;
    prefetch?: boolean;
  };

/**
 * Anchor element that looks like a button. Uses Nextjs Link component for integration with the
 * Nextjs router to utilize page transitions and prefetching.
 * @param [size] - Size of the button (default: "md")
 * @param [variant] - Variant of the button (default: "secondary")
 * @param [outline] - Whether the button should be outlined (default: false)
 * @param [leftIcon] - Icon to display on the left side of the button
 * @param [leftIconProps] - Icon props to override the button defaults
 * @param [rightIcon] - Icon to display on the right side of the button
 * @param [rightIconProps] - Icon props to override the button defaults
 * @param [asLink] - Style the button as a link (when true and outline false the button will not have minimal padding - useful to align with text)
 * @param [ghosted] - Reduce the opacity of the button until the user hovers over it for a less prominent button
 * @param [className] - Allows overriding the default button styles
 * @param [loading] - Sets the button into a loading state (when true, the button is disabled and shows a loading spinner)
 * @param [disabled] - Disables the button (when true, the button is disabled and cannot be clicked)
 * @param [href] - The URL the button should link to
 * @param [prefetch] - Whether the link should be prefetched (default: false)
 * @param [onClick] - Function to run when the button is clicked
 * @param [children] - The content of the button
 */
export const LinkButton = forwardRef<Ref, LinkButtonProps>(
  (
    {
      size,
      variant,
      outline,
      leftIcon,
      leftIconProps,
      rightIcon,
      rightIconProps,
      asLink,
      ghosted,
      className,
      loading,
      disabled: _disabled,
      children,
      href,
      ...props
    },
    ref
  ) => {
    const disabled = _disabled || loading || !href;

    return (
      <Link
        {...props}
        ref={ref}
        href={href ?? "javascript:void(0)"}
        className={cn(
          buttonVariants({
            size,
            variant,
            outline,
            asLink: !!asLink,
            ghosted: !!ghosted,
            noText: !children,
            leftIcon: !!leftIcon || !!loading,
            rightIcon: !!rightIcon,
          }),
          className
        )}
        {...(disabled ? { "data-disabled": true, "aria-disabled": true } : {})}
        prefetch={props.prefetch ?? false}
      >
        <Icon icon={leftIcon} size={size ?? undefined} {...leftIconProps} loading={loading} />
        {children ?? <span className="sr-only">{props.title}</span>}
        <Icon
          icon={rightIcon}
          size={size ?? undefined}
          className={cn(rightIconProps?.className, "ml-auto")}
          {...rightIconProps}
        />
      </Link>
    );
  }
);
LinkButton.displayName = "LinkButton";
