import type { PrismaClient } from "@procision-software/database";
import { DateTime } from "luxon";

export function unixEpoch(): Date {
  return new Date(0);
}

export function endOfTimes(): Date {
  // let's hope this isn't used past 470 years from now
  return new Date(2500, 1, 1, 0, 0, 0, 0);
}

export function isEpoch(date: Date) {
  const year = DateTime.fromJSDate(date).year; // This is tolerant of both local & UTC
  return year === 1969 || year === 1970;
}

export const DOS_FORMAT_STRING = "LLL dd yy h:mm";
export async function formatDOS(
  prisma: PrismaClient,
  facilityId: string | undefined
): Promise<(date: Date) => string> {
  if (!facilityId) {
    return (date: Date) => DateTime.fromJSDate(date).toFormat(DOS_FORMAT_STRING);
  }
  const facility = await prisma.facility.findUnique({
    where: {
      id: facilityId,
    },
    select: {
      timezone: true,
    },
  });
  return (date: Date) =>
    DateTime.fromJSDate(date, { zone: facility?.timezone }).toFormat(DOS_FORMAT_STRING);
}
