import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { HTMLAttributes, ReactElement, ReactNode } from "react";
import { Children, cloneElement, forwardRef, isValidElement } from "react";
import { cn } from "../../utils";

export const panelVariants = cva(["space-y-4 rounded-lg"], {
  variants: {
    variant: {
      default: "border-primary bg-secondary",
      info: "bg-brand",
      success: "border-success bg-success text-success",
      warning: "border-warning bg-warning text-warning",
      error: "border-error bg-error text-error",
    },
    size: {
      default: "px-6 py-4 text-sm",
      medium: "px-4 py-2 text-sm",
      small: "px-2 py-1 text-xs",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

type PanelProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof panelVariants> & {
    children: ReactNode;
  };
const PanelBase = forwardRef<HTMLElement, PanelProps>(
  ({ variant, size, className, children, ...props }, ref) => {
    return (
      <section {...props} ref={ref} className={cn(panelVariants({ variant, size }), className)}>
        {children}
      </section>
    );
  }
);
PanelBase.displayName = "Panel";

type PanelRowProps = HTMLAttributes<HTMLDivElement>;
const PanelRow = forwardRef<HTMLDivElement, PanelRowProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div {...props} ref={ref} className={cn("grid auto-cols-fr grid-flow-col gap-4", className)}>
        {children}
      </div>
    );
  }
);
PanelRow.displayName = "Panel.Row";

type PanelGroupProps = HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof panelVariants> & {
    children: ReactElement<typeof Panel>[];
  };
/**
 * Used to group multiple panels together with a shared background color and
 * diving border between them.
 */
export const PanelGroup = forwardRef<HTMLDivElement, PanelGroupProps>(
  ({ variant, size, className, children, ...props }, ref) => {
    return (
      <div {...props} ref={ref} className={cn(panelVariants({ variant, size }), className)}>
        {Children.map(children, (child) => {
          if (!isValidElement<PanelProps>(child)) return null;
          return cloneElement(child, {
            ...child.props,
            className: cn(
              panelVariants({ variant, size, className: child.props.className }),
              // Override background and borders to blend into the panel group
              `rounded-b-none border-b bg-transparent p-0 last:rounded-b-lg last:border-b-0
              last:pb-0 dark:bg-transparent`,
              size === "small" ? "pb-1" : size === "medium" ? "pb-2" : "pb-4"
            ),
          });
        })}
      </div>
    );
  }
);
PanelGroup.displayName = "Panel.Group";

export const Panel = Object.assign(PanelBase, { Row: PanelRow });
