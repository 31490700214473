import { MedicationAdministrationRecordSchema } from "@procision-software/database-zod";
import { type z } from "zod";

export const MedicationAdministrationRecordFormDataSchema =
  MedicationAdministrationRecordSchema.pick({
    medication: true,
    dose: true,
    unitsId: true,
    frequency: true,
    route: true,
    site: true,
  } satisfies Record<MedicationAdministrationRecordEditableField, true>);

export type MedicationAdministrationRecordFormData = z.infer<
  typeof MedicationAdministrationRecordFormDataSchema
> & {
  registrarId: string;
};

export type MedicationAdministrationRecordEditableField =
  | "medication"
  | "dose"
  | "unitsId"
  | "frequency"
  | "route"
  | "site";
