import { z } from "zod";
import { isEpoch } from "~/utils/dates";

export const SurgeryDateSchema = z
  .date({
    required_error: "Surgery date and time are required",
    invalid_type_error: "Surgery date and time are required",
  })
  .refine((v) => {
    // if year of v is 1970 or 1969, then it's an invalid date. Case can be in the past, but it can't be near epoch
    return !isEpoch(v);
  }, "Surgery date and time are required");
