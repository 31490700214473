import { z } from 'zod';

/////////////////////////////////////////
// PRACTICE SCHEMA
/////////////////////////////////////////

export const PracticeSchema = z.object({
  id: z.string(),
  name: z.string(),
  organizationId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  address1: z.string().nullish(),
  address2: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  zip: z.string().nullish(),
  phone: z.string().nullish(),
  fax: z.string().nullish(),
  email: z.string().nullish(),
})

export type Practice = z.infer<typeof PracticeSchema>

export default PracticeSchema;
