import type { BarePrismaClient } from "@procision-software/database";
import { PermissionDeniedError, type AppAbility } from "@procision-software/auth";

/**
 * Delete all text templates for a given topic
 * @param prisma
 * @param topic The topic to delete templates for
 * @param organizationId The organization to delete templates for
 * @returns The number of templates deleted
 */
export async function textTemplatesDeleteMany(
  prisma: BarePrismaClient,
  topic: string,
  organizationId: string | null
): Promise<number> {
  if (!topic) throw new Error("Invalid topic");
  // Validate topic format
  if (!topic.includes(".")) throw new Error("Invalid topic format");

  // access check
  const o = organizationId
    ? await prisma.organization.findFirstOrThrow({
        where: {
          id: organizationId,
        },
      })
    : null;
  const [model, field] = topic.split(".") as [string, string];
  return (
    await prisma.textTemplate.deleteMany({
      where: {
        organizationId: o ? o.id : null,
        dataModel: model,
        dataField: field,
      },
    })
  ).count;
}

/**
 *
 * @param prisma
 * @param topic the topic to create templates for
 * @param organizationId
 * @param lines
 * @returns number of templates created
 */
export async function textTemplatesCreateMany(
  prisma: BarePrismaClient,
  ability: AppAbility,
  topic: string,
  organizationId: string | null,
  lines: string[]
): Promise<number> {
  if (!topic) throw new Error("Invalid topic");
  // Validate topic format
  if (!topic.includes(".")) throw new Error("Invalid topic format");

  // access check
  const o = organizationId
    ? await prisma.organization.findFirstOrThrow({
        where: {
          id: organizationId,
        },
      })
    : null;
  const [model, field] = topic.split(".") as [string, string];

  if (!o && !ability.can("manage", "Platform")) throw new PermissionDeniedError();

  return (
    await prisma.textTemplate.createMany({
      data: lines.map((template) => ({
        template,
        dataModel: model,
        dataField: field,
        organizationId: o ? o.id : null,
      })),
    })
  ).count;
}
