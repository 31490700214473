import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { cn } from "@procision-software/ui";
import React, { forwardRef, useState } from "react";

/**
 * @deprecated Use UI package components instead
 */
export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean;
  hideContent?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

/**
 * @deprecated Use UI package components instead
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, error, icon: Icon, hideContent = false, type = "text", ...props }, ref) => {
    const disabled =
      props.disabled ?? (props.readOnly && ["radio", "checkbox", "color"].includes(type ?? ""));

    const [contentVisible, setContentVisible] = useState(!hideContent);

    return (
      <div className="relative w-full">
        <input
          {...props}
          disabled={disabled}
          ref={ref}
          type={type}
          className={cn(
            "input relative w-full",
            props.disabled && "opacity-50",
            error && "input-error",
            Icon && "pl-8",
            hideContent && "pr-7",
            hideContent && !contentVisible && "text-security",
            className
          )}
          autoComplete={props.autoComplete ?? "off"}
        />
        {Icon && (
          <span
            className={cn(
              "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2",
              props.disabled && "opacity-50"
            )}
          >
            <Icon className="h-5 w-5" />
          </span>
        )}
        {hideContent && (
          <span
            className={cn(
              "absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2 text-gray-500",
              props.disabled && "opacity-50"
            )}
            onClick={() => setContentVisible((prev) => !prev)}
            onKeyUp={() => setContentVisible((prev) => !prev)}
          >
            {contentVisible ? <EyeOffIcon className="h-4 w-4" /> : <EyeIcon className="h-4 w-4" />}
          </span>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";
