import { z } from 'zod';

/////////////////////////////////////////
// STORAGE BLOB SCHEMA
/////////////////////////////////////////

export const StorageBlobSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  organizationId: z.string(),
  userId: z.string(),
  key: z.string(),
  filename: z.string(),
  contentType: z.string().nullish(),
  byteSize: z.string(),
  checksum: z.string().nullish(),
})

export type StorageBlob = z.infer<typeof StorageBlobSchema>

export default StorageBlobSchema;
