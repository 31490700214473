import type { User as ClerkUser } from "@clerk/backend";
import type { PrismaClient } from "@procision-software/database";
import { prisma } from "@procision-software/database";
import type { User } from "@procision-software/database-zod";

export async function upsertFromClerk(
  user: Pick<ClerkUser, "id" | "firstName" | "lastName" | "primaryEmailAddressId"> & {
    emailAddresses: { id: string; emailAddress: string }[];
  }
): Promise<User> {
  const { id, firstName, lastName, emailAddresses, primaryEmailAddressId } = user;
  const name = `${firstName} ${lastName}`;
  const email = emailAddresses.find(
    (emailAddress) => emailAddress.id === primaryEmailAddressId
  )?.emailAddress;

  // TODO: Break the dependency of user.id being a clerk id
  if (!prisma) throw new Error("Invalid prisma context");
  return await prisma.user.upsert({
    where: {
      id,
    },
    update: {
      name,
      email,
    },
    create: {
      id,
      name,
      email,
    },
  });
}

export async function getUser(prisma: PrismaClient, id: string) {
  return await prisma.user.findFirstOrThrow({
    where: {
      AND: [{ id }],
    },
  });
}
