import type { BarePrismaClient } from "@procision-software/database";
import { createId } from "@paralleldrive/cuid2";
import type { QCodex, QuestionnaireResponseData } from "./types";

export async function qResponseFor(
  prisma: BarePrismaClient,
  encounterId: string,
  questionnaireId: string,
  version: number
): Promise<QuestionnaireResponseData> {
  const existing = await prisma.questionnaireResponse.findFirst({
    where: {
      encounterId,
      questionnaireId,
      questionnaireVersion: version,
    },
    include: {
      answers: true,
    },
  });
  if (existing) return existing;
  return {
    id: createId(),
    answers: [],
    encounterId,
    questionnaireId,
    questionnaireVersion: version,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
}

/**
 * if a codex already contains a questionnaireResponseId for a given questionnaireId, return it
 * @param codex
 * @param questionnaireId
 * @returns
 */
export function questionnaireResponseIdFor(
  codex: QCodex,
  questionnaireId: string
): string | undefined {
  return codex[questionnaireId]?.questionnaireResponse?.id;
}
