import { z } from 'zod';
import { ConsentStatusSchema } from '../inputTypeSchemas/ConsentStatusSchema'

/////////////////////////////////////////
// PATIENT CONSENT SCHEMA
/////////////////////////////////////////

export const PatientConsentSchema = z.object({
  status: ConsentStatusSchema,
  id: z.string(),
  caseId: z.string(),
  consentFormId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  effectiveAt: z.coerce.date().nullish(),
})

export type PatientConsent = z.infer<typeof PatientConsentSchema>

export default PatientConsentSchema;
