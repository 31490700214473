import type { CurrencyType } from "@procision-software/database-zod";

/**
 *
 * @param currencyType - The type of currency to format the value as
 * @param style - The Intl.NumberFormatOptions style to use. Defaults to "decimal"
 * @returns formatter - The Intl.NumberFormat instance to use for formatting
 * @returns unitStep - The step value for the currency (ex: 0.01 for USD, 1 for JPY)
 * @example
 * const { formatter, unitStep } = currencyFormat(CurrencyType.USD);
 *
 */
export function currencyFormat(
  currencyType: CurrencyType | string,
  style?: Intl.NumberFormatOptions["style"]
) {
  if (currencyType !== "USD") {
    throw new Error(`Only USD currency type is currently supported. (${currencyType} provided)`);
  }

  // TODO: Implement more currency types
  const locale = "en-US";
  const currency = "USD";

  return {
    formatter: Intl.NumberFormat(locale, {
      style: style ?? "decimal",
      currency: style === "currency" ? currency : undefined,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    unitStep: 0.01,
  };
}
