import { z } from 'zod';
import { EquipmentRequirementStatusSchema } from '../inputTypeSchemas/EquipmentRequirementStatusSchema'
import { EquipmentRequirementRepresentativeStatusSchema } from '../inputTypeSchemas/EquipmentRequirementRepresentativeStatusSchema'

/////////////////////////////////////////
// EQUIPMENT REQUIREMENT SCHEMA
/////////////////////////////////////////

export const EquipmentRequirementSchema = z.object({
  status: EquipmentRequirementStatusSchema,
  representativeStatus: EquipmentRequirementRepresentativeStatusSchema,
  id: z.string(),
  caseId: z.string(),
  equipmentId: z.string(),
  quantity: z.number().int().nullish(),
  requiredAtOperation: z.boolean(),
})

export type EquipmentRequirement = z.infer<typeof EquipmentRequirementSchema>

export default EquipmentRequirementSchema;
