import { z } from 'zod';
import { BillingPayerTypeSchema } from '../inputTypeSchemas/BillingPayerTypeSchema'
import { BillingClaimStatusSchema } from '../inputTypeSchemas/BillingClaimStatusSchema'

/////////////////////////////////////////
// CHARGE ALLOCATIONS VIEW SCHEMA
/////////////////////////////////////////

export const ChargeAllocationsViewSchema = z.object({
  payerType: BillingPayerTypeSchema,
  billingClaimStatus: BillingClaimStatusSchema.nullish(),
  billingChargeId: z.string(),
  billingChargeMasterId: z.string(),
  chargeCode: z.string(),
  billingCaseId: z.string(),
  organizationId: z.string().nullish(),
  billingPayerId: z.string(),
  sequenceNumber: z.number().int(),
  billingClaimId: z.string().nullish(),
  claimGeneratedAt: z.coerce.date().nullish(),
  claimSubmittedAt: z.coerce.date().nullish(),
  billedAmount: z.number().int(),
  paymentAmount: z.number().int(),
  adjustmentAmount: z.number().int(),
  patientResponsiblityAmount: z.number().int(),
  transactionDate: z.coerce.date().nullish(),
})

export type ChargeAllocationsView = z.infer<typeof ChargeAllocationsViewSchema>

export default ChargeAllocationsViewSchema;
