import { useClerk, useOrganization } from "@clerk/nextjs";
import type { PropsWithChildren } from "react";
import { trpc } from "~/utils/trpc";
import { OrganizationType } from "@procision-software/database-zod";

export const WithoutOrganizationalUnit = ({ children }: PropsWithChildren<unknown>) => {
  const { user: clerkUser } = useClerk();
  const { organization } = useOrganization();

  // because of how the <With> components are nested, we can take a lack of organization as lack of org having loaded... yet
  if (!clerkUser || !organization) {
    return null;
  }

  const { data, isLoading } = trpc.user.get.useQuery({ id: clerkUser.id });
  const { data: org, isLoading: isOrgLoading } = trpc.organization.get.useQuery({
    clerkId: organization.id,
  });

  if (!data || isLoading || isOrgLoading || !org) {
    return null;
  }

  const { activeFacilityId, activePracticeId } = data;

  if (!activeFacilityId && !activePracticeId) {
    return <>{children}</>;
  }
  if (activeFacilityId) {
    if (org.type === OrganizationType.PRACTICE && !activePracticeId) {
      return <>{children}</>;
    } else {
      return null;
    }
  } else {
    return <>{children}</>;
  }
};
