export function TabsIcon({ className }: { className?: string }) {
  return (
    <svg
      width="415"
      height="495"
      viewBox="0 0 415 495"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.26"
        d="M377.832 365.798L56.9218 476.913C36.5617 476.913 20.0137 460.384 20.0137 440.048V281.769C20.0137 261.432 36.5617 244.903 56.9218 244.903L377.832 133.788C398.192 133.788 414.74 150.317 414.74 170.653V328.932C414.74 349.355 398.192 365.798 377.832 365.798Z"
        fill="#3F83F8"
      />
      <path
        opacity="0.13"
        d="M358.078 356.451L37.1679 467.566C16.8078 467.566 0.259766 451.038 0.259766 430.701V272.422C0.259766 252.086 16.8078 235.557 37.1679 235.557L358.078 124.441C378.438 124.441 394.986 140.97 394.986 161.307V319.586C394.9 340.009 378.438 356.451 358.078 356.451Z"
        fill="#3F83F8"
      />
      <path
        opacity="0.39"
        d="M164.96 309.201L65.9317 343.471C59.6071 343.471 54.582 338.365 54.582 332.134V283.326C54.582 277.009 59.6937 271.99 65.9317 271.99L164.96 237.721C171.285 237.721 176.31 242.826 176.31 249.057V297.865C176.31 304.096 171.285 309.201 164.96 309.201Z"
        fill="white"
      />
      <path
        d="M389.44 80.9993L290.412 115.269C284.088 115.269 279.062 110.163 279.062 103.932V55.1243C279.062 48.807 284.174 43.7878 290.412 43.7878L389.44 9.51855C395.765 9.51855 400.79 14.6243 400.79 20.8551V69.6628C400.79 75.8936 395.678 80.9993 389.44 80.9993Z"
        fill="#3F83F8"
      />
      <path
        d="M351.926 68.0188L258.183 100.471C252.205 100.471 247.439 95.6245 247.439 89.7399V43.5284C247.439 37.5572 252.291 32.7976 258.183 32.7976L351.926 0.345703C357.904 0.345703 362.669 5.19186 362.669 11.0765V57.288C362.669 63.1726 357.817 68.0188 351.926 68.0188Z"
        fill="#E1EFFE"
      />
      <path
        d="M384.156 444.894L290.413 477.346C284.435 477.346 279.67 472.5 279.67 466.615V420.403C279.67 414.432 284.522 409.673 290.413 409.673L384.156 377.221C390.134 377.221 394.9 382.067 394.9 387.951V434.163C394.9 440.048 390.134 444.894 384.156 444.894Z"
        fill="#E1EFFE"
      />
      <path
        d="M228.64 460.557L129.611 494.826C123.287 494.826 118.262 489.72 118.262 483.49V434.682C118.262 428.365 123.373 423.345 129.611 423.345L228.64 389.076C234.964 389.076 239.989 394.182 239.989 400.413V449.22C239.989 455.538 234.878 460.557 228.64 460.557Z"
        fill="#3F83F8"
      />
      <path
        opacity="0.51"
        d="M164.96 398.422L65.9317 432.691C59.6071 432.691 54.582 427.586 54.582 421.355V372.547C54.582 366.23 59.6937 361.211 65.9317 361.211L164.96 326.941C171.285 326.941 176.31 332.047 176.31 338.278V387.086C176.31 393.316 171.285 398.422 164.96 398.422Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M212.524 368.134C210.098 368.134 208.105 366.143 208.105 363.72V289.99C208.105 287.567 210.098 285.576 212.524 285.576C214.95 285.576 216.943 287.567 216.943 289.99V363.72C216.943 366.143 214.95 368.134 212.524 368.134Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M236.177 223.961C235.397 221.711 236.524 219.115 238.863 218.336L355.999 176.624C358.251 175.845 360.85 176.97 361.63 179.307C362.41 181.557 361.284 184.153 358.944 184.932L241.809 226.643C239.556 227.422 237.044 226.211 236.177 223.961Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M236.177 239.884C235.397 237.634 236.524 235.037 238.863 234.259L355.999 192.547C358.251 191.768 360.85 192.893 361.63 195.23C362.41 197.48 361.284 200.076 358.944 200.855L241.809 242.566C239.556 243.345 237.044 242.134 236.177 239.884Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M236.177 255.806C235.397 253.556 236.524 250.96 238.863 250.181L355.999 208.47C358.251 207.691 360.85 208.816 361.63 211.153C362.41 213.403 361.284 215.999 358.944 216.778L241.809 258.489C239.556 259.268 237.044 258.056 236.177 255.806Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M236.177 271.73C235.397 269.48 236.524 266.884 238.863 266.105L355.999 224.394C358.251 223.615 360.85 224.74 361.63 227.076C362.41 229.326 361.284 231.923 358.944 232.701L241.809 274.413C239.556 275.192 237.044 274.067 236.177 271.73Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M236.177 287.653C235.397 285.403 236.524 282.807 238.863 282.028L355.999 240.317C358.251 239.538 360.85 240.663 361.63 242.999C362.41 245.249 361.284 247.845 358.944 248.624L241.809 290.336C239.556 291.201 237.044 289.99 236.177 287.653Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M236.177 303.663C235.397 301.413 236.524 298.817 238.863 298.038L355.999 256.326C358.251 255.547 360.85 256.672 361.63 259.009C362.41 261.259 361.284 263.855 358.944 264.634L241.809 306.346C239.556 307.124 237.044 305.913 236.177 303.663Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M236.177 319.586C235.397 317.336 236.524 314.74 238.863 313.961L355.999 272.249C358.251 271.47 360.85 272.595 361.63 274.932C362.41 277.182 361.284 279.778 358.944 280.557L241.809 322.268C239.556 323.047 237.044 321.836 236.177 319.586Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M236.177 335.509C235.397 333.259 236.524 330.662 238.863 329.884L355.999 288.172C358.251 287.393 360.85 288.518 361.63 290.855C362.41 293.105 361.284 295.701 358.944 296.48L241.809 338.191C239.556 338.97 237.044 337.759 236.177 335.509Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M236.177 351.431C235.397 349.181 236.524 346.585 238.863 345.806L355.999 304.095C358.251 303.316 360.85 304.441 361.63 306.778C362.41 309.028 361.284 311.624 358.944 312.403L241.809 354.114C239.556 354.893 237.044 353.681 236.177 351.431Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M236.177 367.355C235.397 365.105 236.524 362.509 238.863 361.73L355.999 320.019C358.251 319.24 360.85 320.365 361.63 322.701C362.41 324.951 361.284 327.548 358.944 328.326L241.809 370.038C239.556 370.817 237.044 369.605 236.177 367.355Z"
        fill="white"
      />
      <path
        d="M183.066 96.9226C184.193 96.2303 185.319 95.6245 186.445 95.1053C201.954 88.0092 220.234 94.8457 227.339 110.25C234.443 125.653 227.599 144 212.177 151.096L209.058 144.346C220.841 138.98 225.953 125.048 220.581 113.278C215.209 101.509 201.261 96.4034 189.478 101.769C188.611 102.201 187.745 102.634 186.879 103.153L183.066 96.9226Z"
        fill="#E1EFFE"
      />
      <path d="M201.521 151.961L215.21 153.172L209.492 140.711L201.521 151.961Z" fill="#E1EFFE" />
      <path
        d="M198.922 156.894C197.796 157.586 196.669 158.192 195.543 158.711C180.035 165.807 161.754 158.971 154.65 143.567C147.545 128.163 154.39 109.817 169.811 102.721L172.93 109.471C161.148 114.836 156.036 128.769 161.407 140.538C166.779 152.307 180.728 157.413 192.511 152.048C193.377 151.615 194.244 151.182 195.11 150.663L198.922 156.894Z"
        fill="#E1EFFE"
      />
      <path d="M180.468 101.855L166.779 100.644L172.497 113.105L180.468 101.855Z" fill="#E1EFFE" />
    </svg>
  );
}
