import type { ParsedUrlQueryInput } from "querystring";
import { useEffect } from "react";
import { useRouter } from "~/hooks/useRouter";
import { currentUri } from "~/utils/router";

export const RedirectToOrganizationalUnitChooser = (props: ParsedUrlQueryInput) => {
  const router = useRouter();

  useEffect(() => {
    const params = new URLSearchParams({
      redirectUrl: props.redirectUrl ? (props.redirectUrl as string) : currentUri(router),
    }).toString();

    void router.push(`/sign-in/organizational-unit-chooser${params.length ? `?${params}` : ""}`);
  }, [props, router]);

  return null;
};
