import { z } from 'zod';
import { BillingCaseStatusSchema } from '../inputTypeSchemas/BillingCaseStatusSchema'

/////////////////////////////////////////
// BILLING CASE SCHEMA
/////////////////////////////////////////

export const BillingCaseSchema = z.object({
  status: BillingCaseStatusSchema,
  id: z.string(),
  caseId: z.string(),
  nextPayerId: z.string().nullish(),
  careTypeCode: z.string(),
  facilityTypeCode: z.string(),
  dischargeCode: z.string(),
  occurrenceCode: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  missingOpReportThreadId: z.string().nullish(),
})

export type BillingCase = z.infer<typeof BillingCaseSchema>

export default BillingCaseSchema;
