import { z } from 'zod';

/////////////////////////////////////////
// PAYMENT SELF PAY SCHEMA
/////////////////////////////////////////

export const PaymentSelfPaySchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  caseId: z.string(),
  paymentMethodTypeId: z.string().nullish(),
  practiceCollectsAllPayment: z.boolean(),
  practiceCollectsProviderPayment: z.boolean(),
  notes: z.string(),
})

export type PaymentSelfPay = z.infer<typeof PaymentSelfPaySchema>

export default PaymentSelfPaySchema;
