import type { SignatureFindFilter, SignatureFormData } from "@procision-software/mason";
import { useCallback } from "react";
import { client, trpc } from "~/utils/trpc";

export function useInvalidateSignature() {
  const utils = trpc.useUtils();

  const invalidate = useCallback(
    async (_input?: SignatureFindFilter): Promise<void> => {
      // Invalidate the entire signature router due to complex cache query keys
      await utils.core.signature.get.invalidate();
      await utils.core.signature.list.invalidate();
    },
    [utils]
  );

  return [invalidate] as const;
}

export function useSignatureService() {
  const [invalidateSignature] = useInvalidateSignature();

  const create = useCallback(
    async (data: SignatureFormData) => {
      const value = await client.core.signature.create.mutate(data);

      await invalidateSignature({ id: value.id });

      return value;
    },
    [invalidateSignature]
  );

  const update = useCallback(
    async (data: Partial<SignatureFormData> & { id: string }) => {
      const value = await client.core.signature.update.mutate(data);

      await invalidateSignature({ id: value.id });

      return value;
    },
    [invalidateSignature]
  );

  return {
    create,
    update,
  } as const;
}

export function useSignature(input: SignatureFindFilter) {
  return trpc.core.signature.get.useQuery(input);
}
