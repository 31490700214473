import { z } from 'zod';

/////////////////////////////////////////
// BILLING CHARGE MODIFIER SCHEMA
/////////////////////////////////////////

export const BillingChargeModifierSchema = z.object({
  id: z.string(),
  billingChargeId: z.string(),
  billingModifierId: z.string(),
  sequenceNumber: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type BillingChargeModifier = z.infer<typeof BillingChargeModifierSchema>

export default BillingChargeModifierSchema;
