import { z } from 'zod';

/////////////////////////////////////////
// BILLING DIAGNOSIS SCHEMA
/////////////////////////////////////////

export const BillingDiagnosisSchema = z.object({
  id: z.string(),
  billingCaseId: z.string(),
  icd10Code: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  sequenceNumber: z.number().int(),
})

export type BillingDiagnosis = z.infer<typeof BillingDiagnosisSchema>

export default BillingDiagnosisSchema;
