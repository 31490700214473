import { z } from 'zod';
import { SexSchema } from '../inputTypeSchemas/SexSchema'

/////////////////////////////////////////
// PATIENT SCHEMA
/////////////////////////////////////////

export const PatientSchema = z.object({
  sex: SexSchema.nullish(),
  id: z.string(),
  /**
   * PHI
   */
  preferredName: z.string().nullish(),
  /**
   * PHI
   */
  firstName: z.string(),
  /**
   * PHI
   */
  lastName: z.string(),
  middleName: z.string().nullish(),
  /**
   * PHI
   */
  suffixName: z.string().nullish(),
  /**
   * PHI
   */
  ssn: z.string().nullish(),
  /**
   * PHI
   */
  dateOfBirth: z.coerce.date(),
  organizationId: z.string(),
  /**
   * PHI
   */
  email: z.string().nullish(),
  /**
   * PHI
   */
  primaryPhone: z.string().nullish(),
  /**
   * PHI
   */
  secondaryPhone: z.string().nullish(),
  /**
   * PHI
   */
  homeAddressId: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  /**
   * PHI
   */
  mrn: z.string(),
  /**
   * a public submission can be created w/ phantom=true. If it's not matched within 30 days it should be deleted.
   */
  phantom: z.boolean(),
  completion: z.number().int(),
})

export type Patient = z.infer<typeof PatientSchema>

export default PatientSchema;
