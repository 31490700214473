import { z } from 'zod';
import { PayerRateRuleSchema } from '../inputTypeSchemas/PayerRateRuleSchema'

/////////////////////////////////////////
// CONTRACT SCHEMA
/////////////////////////////////////////

export const ContractSchema = z.object({
  payerRateRule: PayerRateRuleSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  organizationId: z.string(),
  payerId: z.string(),
  effectiveStart: z.coerce.date(),
  effectiveEnd: z.coerce.date(),
})

export type Contract = z.infer<typeof ContractSchema>

export default ContractSchema;
