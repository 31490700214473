import { z } from 'zod';

/////////////////////////////////////////
// TEXT TEMPLATE SCHEMA
/////////////////////////////////////////

export const TextTemplateSchema = z.object({
  id: z.string(),
  organizationId: z.string().nullish(),
  dataModel: z.string(),
  dataField: z.string(),
  template: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type TextTemplate = z.infer<typeof TextTemplateSchema>

export default TextTemplateSchema;
