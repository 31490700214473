import { z } from 'zod';

/////////////////////////////////////////
// BILLING CHARGE MASTER SCHEMA
/////////////////////////////////////////

export const BillingChargeMasterSchema = z.object({
  id: z.string(),
  billingOrganizationId: z.string(),
  cptCode: z.string().nullish(),
  hcpcsCode: z.string().nullish(),
  amount: z.number().int(),
  selfPayAmount: z.number().int(),
  currency: z.string(),
  active: z.boolean(),
  allowAmountOverride: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type BillingChargeMaster = z.infer<typeof BillingChargeMasterSchema>

export default BillingChargeMasterSchema;
