import { z } from 'zod';

/////////////////////////////////////////
// CONTRACT LIST SCHEMA
/////////////////////////////////////////

export const ContractListSchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  contractId: z.string(),
  name: z.string(),
})

export type ContractList = z.infer<typeof ContractListSchema>

export default ContractListSchema;
