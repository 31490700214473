import type { z } from "zod";
import { AttachmentSchema, AttachmentTypeSchema } from "./generated";
import { StorageBlobUserSchema } from "./storage-blob";

export const AttachmentInputSchema = AttachmentSchema.partial({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export type AttachmentInput = z.infer<typeof AttachmentInputSchema>;

export const AttachmentStorageBlobUserSchema = AttachmentSchema.extend({
  attachmentType: AttachmentTypeSchema,
  storageBlob: StorageBlobUserSchema,
});

export type AttachmentStorageBlobUser = z.infer<typeof AttachmentStorageBlobUserSchema>;
