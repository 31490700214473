import CreateCaseButton from "~/components/practice/case/CreateCaseButton";
import { useFeatureFlags } from "~/hooks/feature-flags";
import { useRouter } from "~/hooks/useRouter";
import Navbar from "./NavBar";

type NavItem = {
  name: string;
  href: string;
  count?: number;
  children?: React.ReactNode;
};

export default function PracticeNavBar() {
  const router = useRouter();
  const { emrFeatures } = useFeatureFlags();

  // const statusTrackerOverview = trpc.statusTracker.overviewCount.useQuery({
  //   filter: {
  //     date: {
  //       start: DateTime.utc().startOf("day").toJSDate(),
  //       end: DateTime.utc().plus({ week: 1 }).endOf("day").toJSDate(),
  //     },
  //     status: ["incomplete"],
  //   },
  // });

  const navItems: NavItem[] = [
    {
      name: "Cases",
      href: "/practice/cases",
      children: (
        <CreateCaseButton
          variant="primary"
          outline={true}
          size="sm"
          onCreated={(id) => router.push(`/practice/cases/${id}`)}
          className="h-auto p-0"
        />
      ),
    },
    ...((emrFeatures ? [{ name: "Charts", href: "/emr/encounters" }] : []) as NavItem[]),
    { name: "Calendar", href: "/practice/calendar" },
    // { name: "Status Tracker", href: "/practice/status-tracker", count: statusTrackerOverview.data }, // TODO: disabled until permissions are fixed
    { name: "Admin", href: "/practice/admin/center-info" },
  ];

  return <Navbar navItems={navItems} />;
}
