"use client";

import { ChevronLeftIcon, HomeIcon } from "lucide-react";
import { BsExclamationOctagonFill } from "react-icons/bs";
import { Button } from "../components/Buttons/Button"; // load directly from file to bypass jest esm issues
import { LinkButton } from "../components/Buttons/LinkButton"; // load directly from file to bypass jest esm issues
import { TabsIcon } from "../components/Icons/TabsIcon";

export function ErrorScreen({
  title = "Error",
  error = "Sorry, an unknown error has occurred.",
  detail,
  onBackClick,
}: {
  title?: string;
  error?: string | Error;
  detail?: string;
  onBackClick?: () => void;
}) {
  return (
    <div className="flex h-full w-full flex-1 items-center justify-center p-16">
      <div className="flex items-center gap-12">
        <TabsIcon />
        <div className="flex flex-col justify-center gap-12">
          <h1 className="flex items-center gap-1 text-error">
            <BsExclamationOctagonFill className="h-7 w-7" />
            {title}
          </h1>
          <p className="text-error">{error instanceof Error ? error.message : error.toString()}</p>
          {detail && <p className="subtle">Details: {detail}</p>}
          <div className="flex items-center gap-4">
            {onBackClick && (
              <Button
                variant="primary"
                outline={true}
                onClick={onBackClick}
                leftIcon={ChevronLeftIcon}
              >
                Go back
              </Button>
            )}
            <LinkButton variant="secondary" outline={true} href="/" leftIcon={HomeIcon}>
              Go home
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  );
}
