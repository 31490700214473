import { z } from 'zod';
import { AuditOperationSchema } from '../inputTypeSchemas/AuditOperationSchema'
import { EquipmentRequirementStatusSchema } from '../inputTypeSchemas/EquipmentRequirementStatusSchema'
import { EquipmentRequirementRepresentativeStatusSchema } from '../inputTypeSchemas/EquipmentRequirementRepresentativeStatusSchema'

/////////////////////////////////////////
// EQUIPMENT REQUIREMENT VERSION SCHEMA
/////////////////////////////////////////

export const EquipmentRequirementVersionSchema = z.object({
  versionOperation: AuditOperationSchema,
  status: EquipmentRequirementStatusSchema.nullish(),
  representativeStatus: EquipmentRequirementRepresentativeStatusSchema.nullish(),
  versionId: z.number().int(),
  versionSourceId: z.string().nullish(),
  versionUserId: z.string().nullish(),
  versionTimestamp: z.coerce.date(),
  id: z.string().nullish(),
  caseId: z.string().nullish(),
  equipmentId: z.string().nullish(),
  quantity: z.number().int().nullish(),
  requiredAtOperation: z.boolean().nullish(),
})

export type EquipmentRequirementVersion = z.infer<typeof EquipmentRequirementVersionSchema>

export default EquipmentRequirementVersionSchema;
