import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { cn } from "@procision-software/ui";
import { HTML5_TIME_FORMAT } from "~/utils/dates";

type Props = Omit<React.ComponentProps<"input">, "value" | "defaultValue" | "type" | "onChange"> & {
  timezone?: string;
  value?: Date | null;
  onChange?: (value: Date | null) => void;
  error?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

/**
 * @deprecated Use UI package components instead
 */
export const InputTime = React.forwardRef<HTMLInputElement, Props>(
  ({ timezone, value, onChange, className, error, icon: Icon, ...props }, ref) => {
    const disabled = props.disabled ?? !timezone?.length;

    const initialValue = useMemo(
      () =>
        value && timezone ? DateTime.fromJSDate(value, { zone: "utc" }).setZone(timezone) : null,
      [value, timezone]
    );

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;
      const newValue = e.currentTarget.value
        ? DateTime.fromFormat(e.currentTarget.value, HTML5_TIME_FORMAT, { zone: timezone })
            .set({
              // Preserve the original day sent in
              day: initialValue?.day,
              month: initialValue?.month,
              year: initialValue?.year,
            })
            .toJSDate()
        : null;
      onChange?.(newValue);
    };

    return (
      <div className="relative w-fit">
        <input
          {...props}
          ref={ref}
          disabled={disabled}
          type="time"
          className={cn(
            "input",
            disabled && "cursor-not-allowed opacity-50",
            error && "input-error",
            Icon && "pl-8",
            className
          )}
          placeholder="yyyy-mm-dd"
          value={initialValue?.toFormat(HTML5_TIME_FORMAT) ?? ""}
          onChange={handleOnChange}
        />
        {Icon && (
          <span
            className={cn(
              "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2",
              disabled && "cursor-not-allowed opacity-50"
            )}
          >
            <Icon className="h-5 w-5" />
          </span>
        )}
      </div>
    );
  }
);
InputTime.displayName = "InputTime";
