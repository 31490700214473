import { z } from 'zod';
import { EmrTemplateWorkflowTypeSchema } from '../inputTypeSchemas/EmrTemplateWorkflowTypeSchema'
import { EmrTemplateStatusSchema } from '../inputTypeSchemas/EmrTemplateStatusSchema'

/////////////////////////////////////////
// EMR TEMPLATE PAGE SCHEMA
/////////////////////////////////////////

export const EmrTemplatePageSchema = z.object({
  workflowType: EmrTemplateWorkflowTypeSchema,
  status: EmrTemplateStatusSchema,
  id: z.string(),
  emrTemplateId: z.string(),
  version: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type EmrTemplatePage = z.infer<typeof EmrTemplatePageSchema>

export default EmrTemplatePageSchema;
