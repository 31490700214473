import { z } from 'zod';

/////////////////////////////////////////
// CPT CODE SCHEMA
/////////////////////////////////////////

export const CptCodeSchema = z.object({
  name: z.string(),
  code: z.string(),
  description: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type CptCode = z.infer<typeof CptCodeSchema>

export default CptCodeSchema;
