import { z } from 'zod';
import { TagTypeSchema } from '../inputTypeSchemas/TagTypeSchema'

/////////////////////////////////////////
// TAG SCHEMA
/////////////////////////////////////////

export const TagSchema = z.object({
  type: TagTypeSchema,
  id: z.string(),
  organizationId: z.string().nullish(),
  name: z.string(),
  abbreviation: z.string(),
  color: z.string(),
})

export type Tag = z.infer<typeof TagSchema>

export default TagSchema;
