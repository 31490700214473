import { z } from 'zod';
import { LateralitySchema } from '../inputTypeSchemas/LateralitySchema'

/////////////////////////////////////////
// PROCEDURE SCHEMA
/////////////////////////////////////////

/**
 * a procedure (surgery) being done to a patient
 */
export const ProcedureSchema = z.object({
  laterality: LateralitySchema,
  id: z.string(),
  cptCodeId: z.string(),
  caseId: z.string(),
  description: z.string(),
  possible: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Procedure = z.infer<typeof ProcedureSchema>

export default ProcedureSchema;
