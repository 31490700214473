import { PermissionDeniedError, type AppAbility } from "@procision-software/auth";
import {
  BillingCaseStatus,
  BillingPayerType,
  PrismaClient,
  type BillingCharge,
  type Case,
  type PaymentInsurance,
  type PaymentLetterProtection,
  type PaymentSelfPay,
  type PaymentWorkersComp,
  type Prisma,
} from "@procision-software/database";
import type { CaseStatus, PaginationInput, Patient, Staff } from "@procision-software/database-zod";
import { searchPatients } from "~/models/Patient";
import { getEntireCase, type CaseId } from "~/models/case";
import type { PaginatedResult } from "~/types/paginated-result";
import {
  augmentChargeMasterWithProcedureName,
  type NamedBillingChargeMaster,
} from "./chargemaster";
import { payerName, waystarProviderForPayer, type NamedPayer } from "./payers";

// Define a unique symbol
declare const BillingCaseIdTag: unique symbol;

// Create a tagged type
export type BillingCaseId = string & { readonly tag: typeof BillingCaseIdTag };

// Function to tag a string
export function toBillingCaseId(id: string): BillingCaseId {
  return id as BillingCaseId;
}

export function billingCaseIdToString(id: BillingCaseId): string {
  return id.toString();
}

/**
 * A prepayment lives on a case until it's applied. This will determine if it needs to be applied.
 *
 * @param kase
 * @returns boolean representing the need to call applyPrePayment
 */
export function needToApplyPrePayment(
  kase: Pick<Case, "prePaymentAmount" | "prePaymentTransferredAt">
): Promise<boolean>;
export function needToApplyPrePayment(prisma: PrismaClient, caseId: CaseId): Promise<boolean>;

export async function needToApplyPrePayment(
  prismaOrCase: PrismaClient | Pick<Case, "prePaymentAmount" | "prePaymentTransferredAt">,
  caseId?: CaseId
): Promise<boolean> {
  if (prismaOrCase instanceof PrismaClient && typeof caseId === "string") {
    // First overload: PrismaClient and caseId
    const prisma: PrismaClient = prismaOrCase;
    const kase = await prisma.case.findFirstOrThrow({
      where: {
        id: caseId,
      },
      select: {
        prePaymentAmount: true,
        prePaymentTransferredAt: true,
      },
    });
    return await needToApplyPrePayment(kase); // Recursively call the function with the case object
  } else if (typeof prismaOrCase !== "string" && !("findFirstOrThrow" in prismaOrCase)) {
    // Second overload: case object directly
    const kase: Pick<Case, "prePaymentAmount" | "prePaymentTransferredAt"> = prismaOrCase as Case;
    return (kase.prePaymentAmount ?? 0) > 0 && !kase.prePaymentTransferredAt;
  } else {
    throw new Error("Invalid arguments.");
  }
}

export async function caselist(
  prisma: PrismaClient,
  ability: AppAbility,
  input: {
    page: number;
    perPage: number;
    search?: {
      firstName?: string;
      lastName?: string;
      mrn?: string;
    };
    filter?: {
      status?: BillingCaseStatus[];
      date?: {
        start: Date;
        end: Date;
      };
    };
    sort: {
      field: "dateOfService";
      direction: "asc" | "desc";
    };
  }
) {
  // Combine search terms
  const patientSearchTerm = [input.search?.firstName, input.search?.lastName, input.search?.mrn]
    .filter(Boolean)
    .join(" ");
  const patientCandidates = patientSearchTerm
    ? (await searchPatients(prisma, patientSearchTerm, { page: 1, perPage: 20 }, false)).rows
    : [];

  // Base case where clause
  const caseWhere: Prisma.CaseWhereInput = {
    patientId: {
      not: null,
    },
  };

  // Add date filter if available
  if (input.filter?.date) {
    caseWhere.surgeryDate = {
      gte: input.filter.date.start,
      lte: input.filter.date.end,
    };
  }

  // Add patient search filter if available
  if (patientSearchTerm && patientCandidates.length) {
    caseWhere.patientId = { in: patientCandidates.map((p) => p.id) };
  }

  // Initialize where condition
  const where: Prisma.BillingCaseWhereInput = {
    case: caseWhere,
  };

  // Add status filter if available
  if (input.filter?.status) {
    where.status = { in: input.filter.status };
  }

  const orderByFor = (sort: "dateOfService", order: "asc" | "desc") => {
    switch (sort) {
      case "dateOfService":
        return { case: { surgeryDate: order } };
      default:
        throw new Error(`Unknown sort`);
    }
  };

  const cases = await prisma.billingCase.findMany({
    where,
    skip: (input.page - 1) * input.perPage,
    take: input.perPage,
    orderBy: orderByFor(input.sort.field, input.sort.direction),
    include: {
      case: {
        include: {
          patient: true,
          surgeon: true,
        },
      },
      billingPayers: {
        where: {
          sequenceNumber: 0,
        },
      },
      nextPayer: true,
    },
  });

  const all = await prisma.billingCase.count({
    where,
  });

  return {
    rows: await Promise.all(
      cases.map(async (kase) => {
        const primaryPayer = kase.billingPayers[0];
        return {
          id: kase.id,
          caseId: kase.caseId,
          financialReference: kase.case.financialReference,
          name: kase.case.name,
          patient: kase.case.patient!,
          surgeon: kase.case.surgeon,
          dateOfService: kase.case.surgeryDate,
          billingStatus: kase.status,
          caseStatus: kase.case.status,
          nextPayer: kase.nextPayer
            ? {
                ...kase.nextPayer,
                name: await payerName(prisma, ability, kase.nextPayer.id),
              }
            : undefined,
          primaryPayer: primaryPayer
            ? {
                ...primaryPayer,
                name: await payerName(prisma, ability, primaryPayer.id),
              }
            : undefined,
        };
      })
    ),
    pagination: {
      page: input.page,
      perPage: input.perPage,
      all,
    },
  };
}

export async function augmentCaseWithBillingCase<TCase extends Case>(
  prisma: PrismaClient,
  ability: AppAbility,
  kase: TCase
) {
  const {
    billingCases: [billingCase],
  } = await prisma.case.findFirstOrThrow({
    where: {
      id: kase.id,
    },
    include: {
      billingCases: true,
    },
  });
  return {
    ...kase,
    billingCase,
  };
}

export async function getChargeMasterRecords(
  prisma: PrismaClient,
  ability: AppAbility,
  charges: Pick<BillingCharge, "billingChargeMasterId">[]
): Promise<NamedBillingChargeMaster[]> {
  const bcmIds = charges.map((charge) => charge.billingChargeMasterId);
  const bcmRecords = await prisma.billingChargeMaster.findMany({
    where: {
      id: {
        in: bcmIds,
      },
    },
  });
  return await augmentChargeMasterWithProcedureName(prisma, ability, bcmRecords);
}

const billingCaseFactory =
  <T extends Prisma.BillingCaseInclude>(include: T) =>
  (prisma: PrismaClient, ability: AppAbility, id: BillingCaseId) =>
    prisma.billingCase.findFirstOrThrow({
      where: {
        id: billingCaseIdToString(id),
      },
      include,
    });

export const getBillingCaseDiagnoses = billingCaseFactory({
  diagnoses: {
    orderBy: {
      sequenceNumber: "asc",
    },
  },
});
export const getBillingCasePayers = billingCaseFactory({
  billingPayers: {
    orderBy: {
      sequenceNumber: "asc",
    },
  },
  nextPayer: true,
});

const billingCaseSummaryInclude = {
  case: {
    include: {
      patient: true,
      surgeon: true,
      encounters: {
        select: {
          id: true,
        },
      },
    },
  },
};
// Typescript wrongly thinks that the grandchildren includes are missing in the payload
export const getBillingCaseSummary = billingCaseFactory(billingCaseSummaryInclude) as unknown as (
  prisma: PrismaClient,
  ability: AppAbility,
  id: BillingCaseId
) => Promise<Prisma.BillingCaseGetPayload<{ include: typeof billingCaseSummaryInclude }>>;

export async function augmentChargeMasterToCharges<T extends BillingCharge>(
  prisma: PrismaClient,
  ability: AppAbility,
  billingCharges: T[]
): Promise<
  (T & { chargeMaster: NamedBillingChargeMaster; billingChargeMaster: NamedBillingChargeMaster })[]
> {
  const chargeMasters = await augmentChargeMasterWithProcedureName(
    prisma,
    ability,
    await getChargeMasterRecords(prisma, ability, billingCharges)
  );
  return billingCharges.map((bc) => {
    const chargeMaster = chargeMasters.find((cm) => cm.id === bc.billingChargeMasterId);
    if (!chargeMaster) {
      throw new Error(`Charge master ${bc.billingChargeMasterId} not found`);
    }
    return {
      ...bc,
      chargeMaster,
      billingChargeMaster: chargeMaster,
    };
  });
}

export async function payersFromCase(
  {
    paymentInsurance: insurance,
    paymentWorkersComp: workerscomp,
    paymentSelfPay: selfpay,
    paymentLetterProtection: lops,
  }: {
    paymentInsurance: PaymentInsurance[];
    paymentWorkersComp: PaymentWorkersComp[];
    paymentSelfPay: PaymentSelfPay[];
    paymentLetterProtection: PaymentLetterProtection[];
  },
  prisma: PrismaClient
): Promise<Omit<Prisma.BillingPayerCreateManyInput, "billingCaseId">[]> {
  const [insurancePayers, wcPayers] = await Promise.all([
    prisma.insuranceProvider.findMany({
      where: {
        id: {
          in: insurance.filter((ins) => ins.providerId).map((ins) => ins.providerId!),
        },
      },
    }),
    prisma.workersCompProvider.findMany({
      where: {
        id: {
          in: workerscomp.filter((wc) => wc.providerId).map((wc) => wc.providerId!),
        },
      },
    }),
  ]);

  const payers = ([] as Omit<Prisma.BillingPayerCreateManyInput, "billingCaseId">[])
    .concat(
      lops.map((lop, ix) => ({
        paymentLetterOfProtectionId: lop.id,
        paymentType: BillingPayerType.Letter_Of_Protection,
        sequenceNumber: ix,
      }))
    )
    .concat(
      workerscomp.map((wc, ix) => {
        return {
          paymentWorkersCompId: wc.id,
          paymentType: BillingPayerType.Workers_Comp,
          sequenceNumber: lops.length + ix,
          waystarInsuranceProviderId:
            wcPayers.find((p) => p.id === wc.providerId)?.waystarProviderId ?? null,
        };
      })
    )
    .concat(
      insurance.map((ins, ix) => {
        return {
          paymentInsuranceId: ins.id,
          paymentType: BillingPayerType.Insurance,
          sequenceNumber: lops.length + workerscomp.length + ix,
          waystarInsuranceProviderId:
            insurancePayers.find((p) => p.id === ins.providerId)?.waystarInsuranceProviderId ??
            null,
        };
      })
    )
    .concat(
      selfpay.map((selfpay, ix) => ({
        paymentSelfPayId: selfpay.id,
        paymentType: BillingPayerType.Self_Pay,
        sequenceNumber: lops.length + workerscomp.length + insurance.length + ix,
      }))
    )
    .map((payer) => ({
      paymentInsuranceId: null,
      paymentLetterOfProtectionId: null,
      paymentSelfPayId: null,
      paymentWorkersCompId: null,
      ...payer,
    }));
  if (!payers.some((p) => p.paymentType === BillingPayerType.Self_Pay)) {
    payers.push({
      paymentInsuranceId: null,
      paymentLetterOfProtectionId: null,
      paymentSelfPayId: null,
      paymentWorkersCompId: null,
      paymentType: BillingPayerType.Self_Pay,
      sequenceNumber: payers.length,
    });
  }
  return payers;
}

export async function createBillingCaseFromCase(
  prisma: PrismaClient,
  ability: AppAbility,
  id: string
) {
  const kase = await getEntireCase(prisma, id);
  const kaseWithBillingCases = await prisma.case.findFirst({
    where: {
      id,
    },
    include: {
      billingCases: true,
    },
  });
  const billingCase = kaseWithBillingCases?.billingCases?.[0] ?? null;
  if (billingCase) {
    return billingCase;
  }
  const associatedPayers = await payersFromCase(kase, prisma);
  for (const p of associatedPayers) {
    if (p.paymentType === BillingPayerType.Self_Pay && !p.paymentSelfPayId) {
      const spr =
        ability.can("create", "PaymentSelfPay") &&
        (await prisma.paymentSelfPay.create({
          data: {
            case: {
              connect: {
                id,
              },
            },
          },
        }));
      if (!spr) {
        throw new PermissionDeniedError();
      }
      p.paymentSelfPayId = spr.id;
    }
  }

  const newCase =
    ability.can("create", "BillingCase") &&
    (await prisma.billingCase.create({
      data: {
        status: BillingCaseStatus.New,
        occurrenceCode: "", // default to blank.
        billingPayers: {
          createMany: {
            data: await Promise.all(
              associatedPayers.map(
                async (
                  p
                ): Promise<
                  Omit<Prisma.BillingPayerCreateManyInput, "billingCase" | "billingCaseId">
                > => {
                  return {
                    ...p,
                    waystarInsuranceProviderId:
                      (await waystarProviderForPayer(prisma, ability, p))?.id ?? null,
                  };
                }
              )
            ),
          },
        },
        case: {
          connect: {
            id,
          },
        },
      },
    }));
  return newCase;
}

/**
 * Fetches ledger data by billing case based on provided filters and pagination.
 *
 * @param {PrismaClient} prisma - Prisma client for database access.
 * @param {AppAbility} ability - CASL ability for permissions.
 * @param {Object} filters - Filtering options.
 * @param {PaginationInput} pagination - Pagination options.
 * @param {BillingCaseStatus[]} [filters.status] - Optional array of BillingCaseStatus to filter by. Defaults to [BillingCaseStatus.New, BillingCaseStatus.Billed].
 * @returns {Promise<PaginatedResult>} - A paginated result containing the ledger data.
 *
 */
export async function ledgerByCase(
  prisma: PrismaClient,
  ability: AppAbility,
  pagination: PaginationInput,
  filters: {
    status?: BillingCaseStatus[];
    search?: string;
    date?: { start: Date; end: Date };
  } = { status: [BillingCaseStatus.New, BillingCaseStatus.Billed] }
): Promise<
  PaginatedResult<{
    dateOfService: Date;
    surgeon: Staff;
    patient: Patient;
    procedure: string;
    primaryPayer: NamedPayer | null;
    nextPayers: NamedPayer[];
    caseStatus: CaseStatus;
    billingCaseId: string;
    caseId: string;
    financialReference: number;
    billingCaseStatus: BillingCaseStatus;
  }>
> {
  const where: Prisma.BillingCaseWhereInput = {
    status: {
      in: filters.status,
    },
    case: {
      patientId: {
        not: null,
      } as Record<string, unknown>,
      ...(filters.date && {
        surgeryDate: {
          gte: filters.date.start,
          lte: filters.date.end,
        },
      }),
    },
  };

  if (filters.search) {
    // this doesn't reuse pagination because we're paginating the billingcases not the patient
    const patients = await searchPatients(prisma, filters.search, { page: 1, perPage: 100 }, false);
    // we discussed and decided if a patient search was provided, the dates & status would be discarded
    // TODO: Change this to the filter/search pattern that patientlist and case's FilterPopover uses
    delete where.status;
    delete where.billingClaims;
    where.case = { patientId: { in: patients.rows.map((p) => p.id) } }; // this replaces the earlier patientId not null condition
  }

  const [cases, count] = await Promise.all([
    prisma.billingCase.findMany({
      where: {
        ...where,
      },
      include: {
        case: {
          include: {
            patient: true,
            surgeon: true,
          },
        },
        billingPayers: {
          orderBy: {
            sequenceNumber: "asc",
          },
        },
        billingClaims: {
          include: {
            billingPayer: true,
          },
        },
      },
      orderBy: {
        case: {
          surgeryDate: "asc",
        },
      },
      skip: (pagination.page - 1) * pagination.perPage,
      take: pagination.perPage,
    }),
    prisma.billingCase.count({
      where,
    }),
  ]);
  return {
    rows: await Promise.all(
      cases.map(async (c) => ({
        dateOfService: c.case.surgeryDate,
        surgeon: c.case.surgeon,
        patient: c.case.patient!,
        procedure: c.case.name,
        primaryPayer: c.billingPayers[0]
          ? {
              ...c.billingPayers[0],
              name: await payerName(prisma, ability, c.billingPayers[0].id),
            }
          : null,
        nextPayers: await Promise.all(
          c.billingClaims.map(async (bc) => ({
            ...bc.billingPayer,
            name: bc.billingPayerId
              ? await payerName(prisma, ability, bc.billingPayerId)
              : "Unknown",
          }))
        ),
        caseStatus: c.case.status,
        billingCaseStatus: c.status,
        billingCaseId: c.id,
        caseId: c.caseId,
        financialReference: c.case.financialReference,
      }))
    ),
    pagination: {
      ...pagination,
      all: count,
    },
  };
}

export async function getBillingCase(prisma: PrismaClient, ability: AppAbility, id: BillingCaseId) {
  return await prisma.billingCase.findFirstOrThrow({
    where: {
      id: billingCaseIdToString(id),
    },
    include: {
      // billingPayer: true,
      // transactions: true,
      billingCharges: {
        include: {
          billingChargeMaster: true,
          billingChargeModifiers: {
            orderBy: {
              sequenceNumber: "asc",
            },
          },
          supportingDiagnoses: {
            orderBy: {
              sequenceNumber: "asc",
            },
          },
        },
        orderBy: {
          sequenceNumber: "asc",
        },
      },
    },
  });
}
