import { z } from 'zod';

/////////////////////////////////////////
// PAYER RATE SCHEMA
/////////////////////////////////////////

export const PayerRateSchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  payerId: z.string(),
  /**
   * when this rate becomes applicable
   */
  periodStart: z.coerce.date(),
  /**
   * when this rate is no longer applicable
   */
  periodEnd: z.coerce.date(),
  /**
   * in cents
   */
  rate: z.number().int(),
  /**
   * cpt or hcpcs code
   */
  procedure: z.string(),
  organizationId: z.string(),
})

export type PayerRate = z.infer<typeof PayerRateSchema>

export default PayerRateSchema;
