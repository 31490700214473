import { CurrencyType } from "@procision-software/database-zod";
type Money = { amount: number; currency: CurrencyType };
export function toCents(money: Money) {
  switch (money.currency) {
    case CurrencyType.USD:
      return money.amount * 100;
    default:
      throw new Error("Unsupported currency");
  }
}
