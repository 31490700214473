import { z } from 'zod';

/////////////////////////////////////////
// PAYMENT WORKERS COMP SCHEMA
/////////////////////////////////////////

/**
 * Workers Comp may be paying instead of insurance
 */
export const PaymentWorkersCompSchema = z.object({
  id: z.string(),
  caseId: z.string(),
  /**
   * deprecated in favor of provider
   */
  carrier: z.string(),
  /**
   * Workers Comp Ref Number
   */
  claimNumber: z.string(),
  address: z.string(),
  authorizationNumber: z.string(),
  adjustor: z.string(),
  adjustorPhone: z.string(),
  dateOfInjury: z.coerce.date().nullish(),
  notes: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  providerId: z.string().nullish(),
})

export type PaymentWorkersComp = z.infer<typeof PaymentWorkersCompSchema>

export default PaymentWorkersCompSchema;
