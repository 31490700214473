import { useClerk } from "@clerk/nextjs";
import type { PropsWithChildren } from "react";

export const WithOrganization = ({ children }: PropsWithChildren<unknown>) => {
  const { organization } = useClerk();

  if (organization) {
    return <>{children}</>;
  }

  return null;
};
