import { prisma, type PrismaClient } from "@procision-software/database";
import { defineAbility, type AppAbility } from "../ability/permissions";
import { forUser } from "../behaviors/revisioning";
import type { Session } from "../types/session";

export function prismaForUser(userId: string, ability: AppAbility, log = true): PrismaClient {
  if (!prisma) throw new Error("Invalid prisma context");
  return prisma.$extends(forUser(userId, ability, log)) as unknown as PrismaClient;
}

type PrismaContext = {
  ability: AppAbility;
  prisma: PrismaClient;
};

export async function getPrismaContext(session: Session): Promise<PrismaContext> {
  const { userId, organizationId, clerkUser, clerkOrganization } = session;

  if (clerkUser === null || clerkOrganization === null) {
    throw new Error("Not logged in");
  }

  const { emailAddresses, primaryEmailAddressId } = clerkUser;

  const userEmail = emailAddresses.find(
    (emailAddress) => emailAddress.id === primaryEmailAddressId
  )?.emailAddress;

  if (userEmail === undefined) {
    throw new Error("Not logged in");
  }

  if (!prisma) throw new Error("Invalid prisma context");
  const ability = await defineAbility(prisma, userId, organizationId, clerkUser, clerkOrganization);
  const userPrisma = prismaForUser(userId, ability);

  return { prisma: userPrisma, ability };
}
