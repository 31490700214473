import type {
  Questionnaire,
  QuestionnaireQuestion,
  QuestionnaireQuestionCondition,
  QuestionnaireResponse,
  QuestionnaireResponseAnswer,
} from "@procision-software/database-zod";
import type { QuestionValueTypeType } from "@procision-software/database-zod/src/generated/inputTypeSchemas/QuestionValueTypeSchema";
import { z } from "zod";

export type QConditionType = QuestionnaireQuestionCondition["type"];
export type QConditionComparatorType = QuestionnaireQuestionCondition["comparator"];
export type QFieldType = QuestionnaireQuestion["fieldType"];
export type ExpandedQuestionnaireQuestion = QuestionnaireQuestion & {
  children?: ExpandedQuestionnaireQuestion[];
  conditionsAsSource: QuestionnaireQuestionCondition[];
};
export type QEngineFieldProps = ExpandedQuestionnaireQuestion & {
  groupRow?: number;
  error?: string | boolean;
};

export const ChoiceOptionSchema = z.object({
  value: z.string(),
  label: z.string().optional(),
});
export type ChoiceOption<T = string> = { label?: string; value: T };

export type QuestionnaireData = Questionnaire & {
  questions: ExpandedQuestionnaireQuestion[];
};
export type QuestionnaireResponseData = QuestionnaireResponse & {
  answers: QuestionnaireResponseAnswer[];
};

export type QuestionnaireVersion = {
  version: number;
  questions: number;
};

// Following is a deoptimization. These union types exist to keep TS from optimizing the type alias's name away.
type QuestionnaireId = string | (string & { readonly __brand: "QuestionnaireId" });
type QuestionId = string | (string & { readonly __brand: "QuestionId" });
type GroupRow = number | (number & { readonly __brand: "GroupRow" });
type ValueOrdinal = number | (number & { readonly __brand: "ValueOrdinal" });
export type QAnswerCodex = Record<
  QuestionnaireId,
  Record<QuestionId, Record<GroupRow, Record<ValueOrdinal, QuestionnaireResponseAnswer>>>
>; // This sucks. It's better than what came before it. But it sucks.

// key is questionnaireId
export type QCodex = Record<
  QuestionnaireId,
  {
    questionnaire: QuestionnaireData;
    questionTree: ExpandedQuestionnaireQuestion[];
    questionnaireResponse: QuestionnaireResponseData;
    version: number;
  }
>;

export type QEngineProps = {
  questionnaireId: QuestionnaireId;
  version?: number;
  readOnly: boolean;
};

export const qFieldValueTypeCodex: Record<QFieldType, QuestionValueTypeType> = {
  boolean: "booleanValue",
  choice: "stringValue",
  date: "dateValue",
  email: "stringValue",
  group: "floatValue",
  header: "stringValue",
  horizontalLine: "stringValue",
  label: "stringValue",
  number: "floatValue",
  phone: "stringValue",
  radio: "stringValue",
  ssn: "stringValue",
  text: "stringValue",
  textArea: "stringValue",
  time: "dateValue",
};
