import { z } from 'zod';
import { BulkCreateSourceSchema } from '../inputTypeSchemas/BulkCreateSourceSchema'
import { BulkCreateDestinationSchema } from '../inputTypeSchemas/BulkCreateDestinationSchema'

/////////////////////////////////////////
// BULK CREATE SCHEMA
/////////////////////////////////////////

export const BulkCreateSchema = z.object({
  source: BulkCreateSourceSchema,
  destination: BulkCreateDestinationSchema,
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  userId: z.string().nullish(),
  organizationId: z.string(),
  name: z.string().nullish(),
  path: z.string().nullish(),
})

export type BulkCreate = z.infer<typeof BulkCreateSchema>

export default BulkCreateSchema;
