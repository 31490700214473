import type { QAnswerCodex, QCodex } from "./types";
import type {
  QuestionnaireResponseAnswer,
  QuestionnaireQuestionCondition,
} from "@procision-software/database-zod";
import {
  index,
  qComparatorHandlers,
  type ExpandedQuestionnaireQuestion,
} from "@procision-software/mason";

export function safelyUpdateAnswerCodex(
  mutableAnswerCodex: QAnswerCodex,
  questionnaireId: string,
  questionId: string,
  groupRow: number,
  valueOrdinal: number,
  answer: Partial<QuestionnaireResponseAnswer> // FIXME: This is a Partial<> because the value type has multiple options and the data lives in different places depending. It would be better if it was a union type that enforced rules.
) {
  mutableAnswerCodex[questionnaireId] = mutableAnswerCodex[questionnaireId] ?? {};
  mutableAnswerCodex[questionnaireId]![questionId] =
    mutableAnswerCodex[questionnaireId]![questionId] ?? {};
  mutableAnswerCodex[questionnaireId]![questionId]![groupRow] =
    mutableAnswerCodex[questionnaireId]![questionId]![groupRow] ?? {};

  // @ts-expect-error valueType is assured
  mutableAnswerCodex[questionnaireId]![questionId]![groupRow]![valueOrdinal] = {
    ...(mutableAnswerCodex[questionnaireId]![questionId]![groupRow]![valueOrdinal] ?? {
      id: crypto.randomUUID(),
    }),
    ...answer,
  };
}

/**
 * Safely get a specific answer at a specific group row and specific value ordinal
 */
export function safelyGetAnswerFromCodex({
  answerCodex,
  questionnaireId,
  questionId,
  groupRow,
  valueOrdinal,
}: {
  answerCodex: QAnswerCodex;
  questionnaireId: string;
  questionId: string;
  groupRow?: number;
  valueOrdinal?: number;
}): QuestionnaireResponseAnswer | undefined {
  return answerCodex[questionnaireId]?.[questionId]?.[groupRow ?? 0]?.[valueOrdinal ?? 0];
}

/**
 * gets all possible value ordinals of a specific answer at a specific group row
 */
export function safelyGetAllValueOrdinalsFromAnswerCodex({
  answerCodex,
  questionnaireId,
  questionId,
  groupRow,
}: {
  answerCodex: QAnswerCodex;
  questionnaireId: string;
  questionId: string;
  groupRow?: number;
}): Record<number, QuestionnaireResponseAnswer> | undefined {
  return answerCodex[questionnaireId]?.[questionId]?.[groupRow ?? 0];
}

// FIXME: This is a confusing name. Something like questionApplies would make more sense.
export function checkCondition(
  condition: QuestionnaireQuestionCondition,
  answerCodex: QAnswerCodex,
  sourceQuestionGroupRow?: number
): boolean {
  const targetAnswers = safelyGetAllValueOrdinalsFromAnswerCodex({
    answerCodex,
    questionnaireId: condition.targetQuestionnaireId,
    questionId: condition.targetQuestionId,
    groupRow: condition.useSourceGroupRow
      ? (sourceQuestionGroupRow ?? 0)
      : (condition.targetQuestionGroupRow ?? 0),
  });

  if (!targetAnswers) {
    // no target answer exists, check against undefined target value
    const comparationResult = qComparatorHandlers[condition.comparator]?.(
      undefined,
      condition?.[condition?.valueType]
    );
    return comparationResult;
  } else if (Object.keys(targetAnswers).length === 1) {
    // If a single target answer exists, check against it
    const targetAnswer = Object.values(targetAnswers)[0];
    const comparationResult = qComparatorHandlers[condition.comparator]?.(
      targetAnswer?.[targetAnswer?.valueType],
      condition?.[condition?.valueType]
    );
    return comparationResult;
  } else {
    // if multiple target answers exist, return true if ANY of them satisfy the comparator
    let shouldShow = false;
    if (targetAnswers)
      Object.values(targetAnswers).forEach((targetAnswer) => {
        const comparationResult = qComparatorHandlers[condition.comparator]?.(
          targetAnswer?.[targetAnswer?.valueType],
          condition?.[condition?.valueType]
        );

        if (comparationResult) {
          shouldShow = true;
        }
      });

    return shouldShow;
  }
}

/**
 * Takes a list of question definitions, and checks that the provided list of answers are valid.
 *
 * FIXME: Does not validate questions with display conditions that target questions in other questionnaires.
 * Fix after implementing validatorRegistry hook pattern in EMRValidator.
 * Currently with display conditions, If the source question is targeting a question in a different questionnaire,
 * it will not validate properly due to that questionnaire not currently loaded.
 *
 * Workaround: cross-questionnaire source questions should not be set to required=true.
 *
 * @param {ExpandedQuestionnaireQuestion[]} questions
 * @param answerCodex
 * @returns {{errors: Record<string, string>, isValid: boolean}}
 */
export function validateQAnswers(
  questions: ExpandedQuestionnaireQuestion[],
  answerCodex: QAnswerCodex,
  questionaireCodex?: QCodex
) {
  const errors: Record<string, string> = {};

  const questionCodex = index(questions, { by: "id" });

  // traverse question and check answers
  questions.forEach((question) => {
    const parentGroup = question.parentQuestionId ? questionCodex[question.parentQuestionId] : null;
    const context = questionaireCodex?.[question.questionnaireId];

    if (question.required) {
      // question must have a matching value

      if (parentGroup && parentGroup.repeatable) {
        // If this question has a parent, Check that all rows for this question have an answer
        const parentGroupLength =
          answerCodex[question.questionnaireId]?.[question.parentQuestionId!]?.[0]?.[0]?.floatValue;

        if (!parentGroupLength) {
          // errors[[parentGroup.id, 0].join(".")] = "This list cannot be empty";
          return;
        }

        Array.from({ length: parentGroupLength }, (_, answerRow) => {
          const answer = answerCodex[question.questionnaireId]?.[question.id]?.[answerRow]?.[0];

          // If this field is not visible, ignore its validation check
          let isVisible = true;
          question.conditionsAsSource?.forEach((condition) => {
            const isMet = checkCondition(condition, answerCodex, answerRow);
            if (!isMet) {
              isVisible = false;
            }
          });

          if (
            isVisible &&
            (!answer ||
              answer[answer.valueType] === "" ||
              answer[answer.valueType] === null ||
              answer[answer.valueType] === undefined)
          ) {
            errors[[question.questionnaireId, question.id, answerRow].join(".")] =
              "This is required";
          }
        });
      } else {
        const answer = answerCodex[question.questionnaireId]?.[question.id]?.[0]?.[0];
        let isVisible = true;
        question.conditionsAsSource?.forEach((condition) => {
          const isMet = checkCondition(condition, answerCodex, 0);
          if (!isMet) {
            isVisible = false;
          }
        });
        // If I have a parent question ID and it's not visible, then I'm not visible
        // If I don't have a parent question ID, then I'm visible
        // If I have a parent question ID and the context isn't loaded, act like I'm invisible
        const parentIsVisible =
          context && question.parentQuestionId
            ? (context?.questionTree.some((q) => q.id === question.parentQuestionId) ?? false)
            : true;
        if (
          isVisible &&
          parentIsVisible &&
          (!answer ||
            answer[answer.valueType] === "" ||
            answer[answer.valueType] === null ||
            answer[answer.valueType] === undefined)
        ) {
          errors[[question.questionnaireId, question.id, 0].join(".")] = "This is required";
        }
      }
    }
  });

  return { isValid: Object.keys(errors).length === 0, errors };
}
