import { z } from 'zod';
import { EquipmentTypeSchema } from '../inputTypeSchemas/EquipmentTypeSchema'

/////////////////////////////////////////
// EQUIPMENT SCHEMA
/////////////////////////////////////////

export const EquipmentSchema = z.object({
  type: EquipmentTypeSchema,
  id: z.string(),
  name: z.string(),
  active: z.boolean(),
  /**
   * / how the facility IDs this part
   */
  inventoryNumber: z.string(),
  /**
   * how the supplier IDs this part
   */
  supplierNumber: z.string(),
  supplierId: z.string().nullish(),
  supplierRepId: z.string().nullish(),
  organizationId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Equipment = z.infer<typeof EquipmentSchema>

export default EquipmentSchema;
