import { z } from "zod";
import { BucketSchema, OrganizationSchema } from "./generated";

export const BucketInputSchema = BucketSchema.partial({
  createdAt: true,
  updatedAt: true,
}).extend({
  id: z.string().optional(),
});

export type BucketInput = z.infer<typeof BucketInputSchema>;

export const BucketOrganizationSchema = BucketSchema.extend({
  organization: OrganizationSchema,
});

export type BucketOrganization = z.infer<typeof BucketOrganizationSchema>;
