import pino from "pino";

const logger = pino({
  formatters: {
    /**
     * Ensures that the level label is given as a string "info" instead of numeric 30
     *
     * @param label - The log level
     * @returns - The log level
     */
    level(label) {
      return { level: label };
    },
  },

  /**
   * Log level is set to "info" in production and "trace" in development
   */
  level: process.env.NODE_ENV === "production" ? "info" : "trace",

  /**
   * Datadog expects the message key to be "message" instead of "msg" (pino default).
   */
  messageKey: "message",

  /**
   * Datadog expects the time to be in the "timestamp" key instead of "time" (pino default).
   * Additionally uses the ISO-8601 format instead of the default epoch time.
   *
   * @returns - The current timestamp in ISO format
   */
  timestamp() {
    return `,"timestamp":"${new Date(Date.now()).toISOString()}"`;
  },
});

export default logger;
