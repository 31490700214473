import { z } from 'zod';

/////////////////////////////////////////
// CASE MEDICAL CONDITION SCHEMA
/////////////////////////////////////////

export const CaseMedicalConditionSchema = z.object({
  id: z.string(),
  caseId: z.string(),
  medicalConditionId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type CaseMedicalCondition = z.infer<typeof CaseMedicalConditionSchema>

export default CaseMedicalConditionSchema;
