import { z } from 'zod';

/////////////////////////////////////////
// SUPPLIER SCHEMA
/////////////////////////////////////////

export const SupplierSchema = z.object({
  id: z.string(),
  name: z.string(),
  portalUrl: z.string().nullish(),
  relationship: z.string().nullish(),
  active: z.boolean(),
  organizationId: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Supplier = z.infer<typeof SupplierSchema>

export default SupplierSchema;
