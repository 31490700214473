import { z } from 'zod';
import { ConditionStateSchema } from '../inputTypeSchemas/ConditionStateSchema'
import { AllergySeveritySchema } from '../inputTypeSchemas/AllergySeveritySchema'

/////////////////////////////////////////
// ALLERGY SCHEMA
/////////////////////////////////////////

export const AllergySchema = z.object({
  status: ConditionStateSchema,
  severity: AllergySeveritySchema,
  id: z.string(),
  patientId: z.string(),
  name: z.string(),
  notes: z.string(),
  effective: z.coerce.date(),
  invalidated: z.coerce.date().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Allergy = z.infer<typeof AllergySchema>

export default AllergySchema;
