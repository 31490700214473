import { PrismaClient, type Staff as PrismaStaff } from "@prisma/client";

export * from "@prisma/client";
export { CCRegistry, type KnownCodeableConcept } from "./models/known-concepts";
export * from "./models/in-house-tests";

export type Staff = Omit<PrismaStaff, "name">;

const globalForPrisma = globalThis as { prisma?: PrismaClient };

export const prisma =
  typeof window !== "undefined"
    ? undefined
    : globalForPrisma.prisma ??
      new PrismaClient({
        log: process.env.NODE_ENV === "development" ? ["query", "error", "warn"] : ["error"],
      });

if (process.env.NODE_ENV !== "production") {
  globalForPrisma.prisma = prisma;
}

// if you want to see parameterized queries and their params in your console, run with env SHOW_SQL=1

export type BarePrismaClient = Omit<
  PrismaClient,
  "$connect" | "$disconnect" | "$on" | "$use" | "$transaction" | "$extends"
> &
  Partial<Pick<PrismaClient, "$connect" | "$disconnect" | "$on" | "$use" | "$extends">>;
// Define a generic utility type to compose PrismaClient with various methods
export type WithPrismaMethod<K extends keyof PrismaClient> = BarePrismaClient &
  Pick<PrismaClient, K>;
