import { z } from 'zod';

/////////////////////////////////////////
// ROOM SCHEMA
/////////////////////////////////////////

/**
 * an operating/procedure room
 */
export const RoomSchema = z.object({
  id: z.string(),
  name: z.string(),
  active: z.boolean(),
  facilityId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Room = z.infer<typeof RoomSchema>

export default RoomSchema;
