import { type ReactNode } from "react";
import Header from "../branding/Header";
import { Footer } from "@procision-software/ui";

export type LayoutProps = {
  children?: ReactNode;
  subnav?: ReactNode;
};

/**
 * @deprecated Use AppLayout instead
 */
export default function MarketingLayout({ children }: LayoutProps) {
  return (
    <div className="flex min-h-screen flex-col">
      <header>
        <Header />
      </header>
      <main className="grid min-h-full grow p-6">{children}</main>
      <Footer />
    </div>
  );
}
