import { OrderSchema } from "@procision-software/database-zod";
import { type z } from "zod";

export const OrderFormDataSchema = OrderSchema.pick({
  orderText: true,
  issuerId: true,
  status: true,
  orderDate: true,
  validFrom: true,
  validUntil: true,
  orderGroupId: true,
} satisfies Record<OrderEditableField, true>);

export type OrderFormData = z.infer<typeof OrderFormDataSchema>;

export type OrderEditableField =
  | "orderText"
  | "issuerId"
  | "status"
  | "orderDate"
  | "validFrom"
  | "validUntil"
  | "orderGroupId";
