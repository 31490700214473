import { Logo } from "../components/Icons/Logo";

export function LoadScreen() {
  return (
    <div className="flex h-full w-full flex-1 items-center justify-center p-16">
      <Logo
        className="h-24 w-24 animate-spin-slow opacity-10 sm:h-32 sm:w-32 md:h-40 md:w-40 lg:h-48
          lg:w-48"
      />
    </div>
  );
}
