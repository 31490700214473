import { z } from 'zod';

/////////////////////////////////////////
// PREFERENCE CARD SCHEMA
/////////////////////////////////////////

export const PreferenceCardSchema = z.object({
  id: z.string(),
  title: z.string(),
  providerId: z.string(),
  positioning: z.string(),
  prep: z.string(),
  drapes: z.string(),
  notes: z.string(),
  gloves: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  organizationId: z.string(),
  deepUpdatedAt: z.coerce.date(),
})

export type PreferenceCard = z.infer<typeof PreferenceCardSchema>

export default PreferenceCardSchema;
