import { Popover } from "@procision-software/ui";
import { CircleHelpIcon } from "lucide-react";
import { env } from "~/env.mjs";
import { SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER } from "~/utils/constants";
import { ShowIntercomButton } from "../providers/IntercomProviderWrapper";
import { useFeatureFlags } from "~/hooks/feature-flags";

export function SupportButton() {
  // Use intercom if it is enabled and configured
  const { enableIntercom } = useFeatureFlags();
  if (enableIntercom && !!env.NEXT_PUBLIC_INTERCOM_APP_ID) {
    return <ShowIntercomButton />;
  }

  // TODO: Remove this when intercom is fully enabled
  return (
    <Popover>
      <Popover.Button
        variant="secondary"
        outline={false}
        asLink
        leftIcon={CircleHelpIcon}
        className="h-auto p-0"
        title="Contact support"
      />
      <Popover.Content align="end" sideOffset={8}>
        <div className="max-w-lg space-y-4 p-4 text-sm">
          <h2>Support</h2>
          <div>
            <div>
              Email:{" "}
              <a
                href={`mailto:${SUPPORT_EMAIL}?body=${encodeURIComponent(
                  "Please avoid submitting any PHI given email is not HIPAA compliant.  Instead ask us in the email to provide you a HIPAA compliant virtual drive to which you can upload PHI related to your support needs"
                )}`}
                className="text-brand hover:underline"
              >
                {SUPPORT_EMAIL}
              </a>
            </div>
            <div>
              Phone:{" "}
              <a href={`tel:${SUPPORT_PHONE_NUMBER}`} className="text-brand hover:underline">
                {SUPPORT_PHONE_NUMBER}
              </a>
            </div>
          </div>
          <div>
            <p>
              For urgent issues please call the number above. For everything else including issues
              and feature requests please email. We aim to respond same day or next day for all
              issues.
            </p>
            <p>
              When using our support email please avoid submitting any PHI given email is not HIPAA
              compliant. Instead ask us in the email to provide you a HIPAA compliant virtual drive
              to which you can upload PHI related to your support needs
            </p>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
}
