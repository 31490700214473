import { z } from 'zod';
import { TimePeriodTypeSchema } from '../inputTypeSchemas/TimePeriodTypeSchema'

/////////////////////////////////////////
// TIME PERIOD SCHEMA
/////////////////////////////////////////

export const TimePeriodSchema = z.object({
  type: TimePeriodTypeSchema,
  id: z.string(),
  roomId: z.string(),
  practiceId: z.string().nullish(),
  surgeonId: z.string().nullish(),
  startTime: z.coerce.date(),
  endTime: z.coerce.date(),
  color: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  recurrenceId: z.string().nullish(),
  name: z.string().nullish(),
  flipRoomId: z.string().nullish(),
  isFlip: z.boolean(),
})

export type TimePeriod = z.infer<typeof TimePeriodSchema>

export default TimePeriodSchema;
