import { z } from 'zod';
import { EmrTemplateWorkflowTypeSchema } from '../inputTypeSchemas/EmrTemplateWorkflowTypeSchema'
import { ReviewStatusSchema } from '../inputTypeSchemas/ReviewStatusSchema'

/////////////////////////////////////////
// PUBLIC SUBMISSION SCHEMA
/////////////////////////////////////////

export const PublicSubmissionSchema = z.object({
  type: EmrTemplateWorkflowTypeSchema,
  reviewStatus: ReviewStatusSchema,
  id: z.string(),
  facilityId: z.string(),
  archived: z.boolean(),
  migrated: z.boolean(),
  encounterId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  signatureId: z.string().nullish(),
})

export type PublicSubmission = z.infer<typeof PublicSubmissionSchema>

export default PublicSubmissionSchema;
