import { z } from 'zod';

/////////////////////////////////////////
// USER SCHEMA
/////////////////////////////////////////

export const UserSchema = z.object({
  id: z.string(),
  name: z.string().nullish(),
  email: z.string().nullish(),
  activeFacilityId: z.string().nullish(),
  activePracticeId: z.string().nullish(),
})

export type User = z.infer<typeof UserSchema>

export default UserSchema;
