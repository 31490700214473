import Navbar from "./NavBar";

export default function PlatformNavBar() {
  const navItems = [
    { name: "Platform Admin", href: "/platform/admin/dashboard" },
    { name: "Codeable Concepts", href: "/platform/admin/codeable-concepts" },
    { name: "Reports", href: "/platform/admin/reports/accessibleData" },
  ];

  return <Navbar navItems={navItems} />;
}
