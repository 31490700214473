"use client";

import { useEffect, useRef } from "react";

export function useFocus<T extends HTMLElement = HTMLInputElement>() {
  const ref = useRef<T>(null);
  const setFocus = () => ref?.current?.focus?.();
  return [ref, setFocus] as const;
}

export function useAutoFocus<T extends { focus: () => void } = HTMLInputElement>(
  autoFocus?: boolean
) {
  const focusRef = useRef<T>(null);
  useEffect(() => {
    !!autoFocus && focusRef.current?.focus();
  }, [autoFocus]);
  return focusRef;
}
