import { EmrTemplateWorkflowType } from "@prisma/client";
import humanizeString from "humanize-string";

export const workflowsInOrder: EmrTemplateWorkflowType[] = [
  EmrTemplateWorkflowType.PAT,
  EmrTemplateWorkflowType.Preop,
  EmrTemplateWorkflowType.Anesthesia,
  EmrTemplateWorkflowType.Operative,
  EmrTemplateWorkflowType.Postop,
  EmrTemplateWorkflowType.PacuPhase1,
  EmrTemplateWorkflowType.PacuPhase2,
  EmrTemplateWorkflowType.PostopPhoneCall,
  EmrTemplateWorkflowType.PatientPrintout,
];
export const workflowSorter = (
  a: { workflowType: EmrTemplateWorkflowType },
  b: { workflowType: EmrTemplateWorkflowType }
) => workflowsInOrder.indexOf(a.workflowType) - workflowsInOrder.indexOf(b.workflowType);

export function humanizeEmrTemplateWorkflowType(workflowType: EmrTemplateWorkflowType) {
  switch (workflowType) {
    case EmrTemplateWorkflowType.PAT:
      return "Pre-Admission";
    case EmrTemplateWorkflowType.Preop:
      return "Pre-Operative";
    case EmrTemplateWorkflowType.Operative:
      return "Operative";
    case EmrTemplateWorkflowType.Postop:
      return "Post-Operative";
    case EmrTemplateWorkflowType.Anesthesia:
      return "Anesthesia";
    case EmrTemplateWorkflowType.PacuPhase1:
      return "PACU Phase 1";
    case EmrTemplateWorkflowType.PacuPhase2:
      return "PACU Phase 2";
    case EmrTemplateWorkflowType.PostopPhoneCall:
      return "Post-Operative Phone Call";
    case EmrTemplateWorkflowType.PatientPrintout:
      return "Patient Printout";
    default:
      console.error("Unknown workflow type", workflowType);
      return humanizeString(workflowType);
  }
}

export function workflowOptions() {
  return workflowsInOrder.map((workflowType) => ({
    value: workflowType,
    label: humanizeEmrTemplateWorkflowType(workflowType),
  }));
}
