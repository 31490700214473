import type { AppAbility } from "@procision-software/auth";
import type { PrismaClient } from "@procision-software/database";
import { chunk } from "lodash";
import isBlank from "~/utils/is-blank";
import { getX12Payload } from "./read";
import {
  NON_ALPHA_NUMERIC_REGEX,
  UNALLOWED_CHARS_REGEX,
  X12837IOtherSubscriberInformationLoopSchema,
  X12837ISchema,
  X12837IServiceLineNumberLoopSchema,
  mapDateToCCYYMMDD,
  mapDateToSegments,
  unless,
  type InternalBillingClaimFilter,
  type X12837I,
  type X12837IAttendingProviderSecondaryIdentification,
  type X12837IOperatingPhysicianSecondaryIdentification,
  type X12837IOtherDiagnosisInformation,
  type X12837IOtherSubscriberInformationLoop,
  type X12837IPayerResponsibilitySequenceNumberCode,
  type X12837IServiceLineNumberLoop,
} from "./shared";

/**
 * Create a {@linkcode X12837I}.
 *
 * @param prisma {@linkcode PrismaClient}
 * @param ability {@linkcode AppAbility}
 * @param params {@linkcode BillingCaseFilter}
 * @returns {Promise<X12837I>} Promise<X12837I>
 */
export async function createX12837I(
  prisma: PrismaClient,
  ability: AppAbility,
  filter: InternalBillingClaimFilter
): Promise<X12837I> {
  const {
    billingCase,
    billingCharges,
    billingClaim,
    previousBillingClaim,
    previousBillingPayer,
    billingOrganizationContacts,
    billingPayer,
    billingPayers,
    billingType,
    codes,
    diagnoses,
    facility,
    isPrimaryClaim,
    kase,
    patient,
    surgeon,
    totalPaidPerPayer,
    billedAmount,
    expectedAmount,
  } = await getX12Payload(prisma, ability, filter);

  /**
   * How to Read: Field Naming Convention
   *
   * X12
   * Segement
   * Element Position
   *
   * -or-
   *
   * X12
   * Segment
   * Composite Element
   * Element Position
   */

  const X12_ST_03 = billingClaim.referenceNumber;

  const X12_BHT_03 = billingClaim.id;
  const [X12_BHT_04, X12_BHT_05] = mapDateToSegments(new Date());

  const X12_1000B_NM1_03 = "ZIRMED";
  const X12_1000B_NM1_09 = "ZIRMED";

  const X12_1000A_NM1_03 = facility.name;
  const X12_1000A_NM1_09 = facility.ein!;

  const X12_2010AA_NM1_03 = facility.name;
  const X12_2010AA_NM1_09 = facility.npi;
  const X12_2010AA_N3_01 = facility.address1;
  const X12_2010AA_N3_02 = facility.address2;
  const X12_2010AA_N4_01 = facility.city;
  const X12_2010AA_N4_02 = facility.state;
  const X12_2010AA_N4_03 = facility.zip.replaceAll(NON_ALPHA_NUMERIC_REGEX, "");
  const X12_2010AA_REF_02 = X12_1000A_NM1_09;

  const X12_2010BA_NM1_03 = billingPayer.subscriber.lastName;
  const X12_2010BA_NM1_04 = billingPayer.subscriber.firstName;
  const X12_2010BA_NM1_05 = billingPayer.subscriber.middleName;
  const X12_2010BA_NM1_09 = billingPayer.payment.policyNumber;
  const X12_2000B_SBR_03 = billingPayer.payment.groupNumber;
  const X12_2010BA_N3_01 = billingPayer.subscriber.address1;
  const X12_2010BA_N3_02 = billingPayer.subscriber.address2;
  const X12_2010BA_N4_01 = billingPayer.subscriber.city;
  const X12_2010BA_N4_02 = billingPayer.subscriber.state;
  const X12_2010BA_N4_03 = billingPayer.subscriber.zipCode.replaceAll(NON_ALPHA_NUMERIC_REGEX, "");
  const X12_2010BA_DMG_02 = billingPayer.subscriber.birthDate;
  const X12_2010BA_DMG_03 = billingPayer.subscriber.genderCode;
  const X12_2010BA_REF_02 = (billingPayer.subscriber.ssn ?? "").replaceAll(/([^0-9])/g, "");

  const X12_2010BB_NM1_03 = billingPayer.payment.payerName;
  const X12_2010BB_NM1_09 = billingPayer.payment.payerId;
  const X12_2010BB_N3_01 = billingPayer.address.address1;
  const X12_2010BB_N3_02 = billingPayer.address.address2 ?? "";
  const X12_2010BB_N4_01 = billingPayer.address.city;
  const X12_2010BB_N4_02 = billingPayer.address.state;
  const X12_2010BB_N4_03 = billingPayer.address.zip.replaceAll(NON_ALPHA_NUMERIC_REGEX, "");

  const X12_2300_CLM_01 = patient.mrn;
  const X12_2300_CLM_02 = billedAmount;
  const X12_2300_CLM_C023_01 = `${billingType.facilityType}${billingType.careType}`;
  const X12_2300_CLM_C023_03 = billingType.frequencyCode;
  const X12_2300_CLM_REF_G1_02 = billingPayer.payment.authorizationNumber;
  const X12_2300_CL1_03 = billingCase.dischargeCode;
  const X12_2300_CLM_HI_C022_02 = billingCase.occurrenceCode;
  const X12_2300_CLM_HI_C022_04 = billingPayer.occurrence;

  const X12_2300_DTP_435_03 = mapDateToCCYYMMDD(kase.surgeryDate, facility.timezone);
  const X12_2300_DTP_096_03 = mapDateToCCYYMMDD(kase.surgeryDate, facility.timezone);

  const X12_2310A_NM1_03 = surgeon.lastName;
  const X12_2310A_NM1_04 = surgeon.firstName;
  const X12_2310A_NM1_05 = surgeon.middleName;
  const X12_2310A_NM1_09 = surgeon.npi;
  const X12_2310A_REF_1G_09 = surgeon.upin;
  const X12_2310A_REF_0B_09 = surgeon.stateLicenseNumber;
  const X12_2310A_REF: X12837IAttendingProviderSecondaryIdentification[] = [];

  const X12_2310D_NM1_03 = surgeon.lastName;
  const X12_2310D_NM1_04 = surgeon.firstName;
  const X12_2310D_NM1_05 = surgeon.middleName;
  const X12_2310D_NM1_09 = surgeon.npi;
  const X12_2310D_REF_1G_09 = surgeon.upin;
  const X12_2310D_REF_0B_09 = surgeon.stateLicenseNumber;
  const X12_2310D_REF: X12837IOperatingPhysicianSecondaryIdentification[] = [];

  const X12_2330_SBR_01_VALUES = ["S", "T", "A", "B", "C", "D", "E", "F", "G", "H"];

  if (!isBlank(X12_2310A_REF_1G_09)) {
    X12_2310A_REF.push({
      reference_identification_qualifier_01: "1G",
      attending_provider_secondary_identifier_02: X12_2310A_REF_1G_09!,
    });
  }

  if (!isBlank(X12_2310A_REF_0B_09)) {
    X12_2310A_REF.push({
      reference_identification_qualifier_01: "0B",
      attending_provider_secondary_identifier_02: X12_2310A_REF_0B_09!,
    });
  }

  if (!isBlank(X12_2310D_REF_1G_09)) {
    X12_2310D_REF.push({
      reference_identification_qualifier_01: "1G",
      operating_physician_secondary_identifier_02: X12_2310D_REF_1G_09!,
    });
  }

  if (!isBlank(X12_2310D_REF_0B_09)) {
    X12_2310D_REF.push({
      reference_identification_qualifier_01: "0B",
      operating_physician_secondary_identifier_02: X12_2310D_REF_0B_09!,
    });
  }

  return X12837ISchema.parse({
    heading: {
      transaction_set_header_ST: {
        transaction_set_identifier_code_01: "837",
        transaction_set_control_number_02: X12_ST_03,
        version_release_or_industry_identifier_03: "005010X223A2",
      },
      beginning_of_hierarchical_transaction_BHT: {
        hierarchical_structure_code_01: "0019",
        transaction_set_purpose_code_02: "00",
        originator_application_transaction_identifier_03: X12_BHT_03,
        transaction_set_creation_date_04: X12_BHT_04,
        transaction_set_creation_time_05: X12_BHT_05,
        claim_identifier_06: "CH",
      },
      receiver_name_NM1_loop: {
        receiver_name_NM1: {
          entity_identifier_code_01: "40",
          entity_type_qualifier_02: "2",
          receiver_name_03: X12_1000B_NM1_03,
          identification_code_qualifier_08: "46",
          receiver_primary_identifier_09: X12_1000B_NM1_09,
        },
      },
      submitter_name_NM1_loop: {
        submitter_name_NM1: {
          entity_identifier_code_01: "41",
          entity_type_qualifier_02: "2",
          submitter_last_or_organization_name_03: X12_1000A_NM1_03,
          identification_code_qualifier_08: "46",
          submitter_identifier_09: X12_1000A_NM1_09,
        },
        submitter_edi_contact_information_PER: billingOrganizationContacts
          .slice(0, 2)
          .map(({ kind: X12_1000A_PER_03, name: X12_1000A_PER_02, value: X12_1000A_PER_04 }) => ({
            contact_function_code_01: "IC",
            submitter_contact_name_02: X12_1000A_PER_02,
            communication_number_qualifier_03: X12_1000A_PER_03,
            communication_number_04: X12_1000A_PER_04,
          })),
      },
    },
    detail: {
      billing_provider_hierarchical_level_HL_loop: [
        {
          billing_provider_name_NM1_loop: {
            billing_provider_name_NM1: {
              entity_identifier_code_01: "85",
              entity_type_qualifier_02: "2",
              billing_provider_organizational_name_03: X12_2010AA_NM1_03,
              identification_code_qualifier_08: "XX",
              billing_provider_identifier_09: X12_2010AA_NM1_09,
            },
            billing_provider_address_N3: {
              billing_provider_address_line_01: X12_2010AA_N3_01,
              ...unless(X12_2010AA_N3_02, (billing_provider_address_line_02) => ({
                billing_provider_address_line_02,
              })),
            },
            billing_provider_city_state_zip_code_N4: {
              billing_provider_city_name_01: X12_2010AA_N4_01,
              billing_provider_state_or_province_code_02: X12_2010AA_N4_02,
              billing_provider_postal_zone_or_zip_code_03: X12_2010AA_N4_03,
            },
            billing_provider_tax_identification_REF: {
              reference_identification_qualifier_01: "EI",
              billing_provider_tax_identification_number_02: X12_2010AA_REF_02,
            },
          },
          pay_to_address_name_NM1_loop: {
            pay_to_address_name_NM1: {
              entity_identifier_code_01: "87",
              entity_type_qualifier_02: "2",
            },
            pay_to_address_address_N3: {
              pay_to_address_line_01: X12_2010AA_N3_01,
              ...unless(X12_2010AA_N3_02, (pay_to_address_line_02) => ({
                pay_to_address_line_02,
              })),
            },
            pay_to_address_city_state_zip_code_N4: {
              pay_to_address_city_name_01: X12_2010AA_N4_01,
              pay_to_address_state_code_02: X12_2010AA_N4_02,
              pay_to_address_postal_zone_or_zip_code_03: X12_2010AA_N4_03,
            },
          },
          subscriber_hierarchical_level_HL_loop: [
            {
              subscriber_information_SBR: {
                payer_responsibility_sequence_number_code_01: "P",
                individual_relationship_code_02: "18",
                ...unless(X12_2000B_SBR_03, (subscriber_group_or_policy_number_03) => ({
                  subscriber_group_or_policy_number_03,
                })),
                claim_filing_indicator_code_09: "ZZ",
              },
              subscriber_name_NM1_loop: {
                subscriber_name_NM1: {
                  entity_identifier_code_01: "IL",
                  entity_type_qualifier_02: "1",
                  subscriber_last_name_03: X12_2010BA_NM1_03,
                  ...unless(X12_2010BA_NM1_04, (subscriber_first_name_04) => ({
                    subscriber_first_name_04,
                  })),
                  ...unless(X12_2010BA_NM1_05, (subscriber_middle_name_or_initial_05) => ({
                    subscriber_middle_name_or_initial_05,
                  })),
                  ...unless(X12_2010BA_NM1_09, (subscriber_primary_identifier_09) => ({
                    identification_code_qualifier_08: "MI",
                    subscriber_primary_identifier_09,
                  })),
                },
                subscriber_address_N3: {
                  subscriber_address_line_01: X12_2010BA_N3_01,
                  ...unless(X12_2010BA_N3_02, (subscriber_address_line_02) => ({
                    subscriber_address_line_02,
                  })),
                },
                subscriber_city_state_zip_code_N4: {
                  subscriber_city_name_01: X12_2010BA_N4_01,
                  subscriber_state_code_02: X12_2010BA_N4_02,
                  subscriber_postal_zone_or_zip_code_03: X12_2010BA_N4_03,
                },
                subscriber_demographic_information_DMG: {
                  date_time_period_format_qualifier_01: "D8",
                  subscriber_birth_date_02: X12_2010BA_DMG_02,
                  subscriber_gender_code_03: X12_2010BA_DMG_03,
                },
                ...unless(X12_2010BA_REF_02, (subscriber_supplemental_identifier_02) => ({
                  subscriber_secondary_identification_REF: {
                    reference_identification_qualifier_01: "SY",
                    subscriber_supplemental_identifier_02,
                  },
                })),
              },
              payer_name_NM1_loop: {
                payer_name_NM1: {
                  entity_identifier_code_01: "PR",
                  entity_type_qualifier_02: "2",
                  payer_name_03: X12_2010BB_NM1_03.slice(0, 60).trim(),
                  identification_code_qualifier_08: "PI",
                  payer_identifier_09: X12_2010BB_NM1_09,
                },
                ...unless(X12_2010BB_N3_01, (payer_address_line_01) => ({
                  payer_address_N3: {
                    payer_address_line_01,
                    ...unless(X12_2010BB_N3_02, (payer_address_line_02) => ({
                      payer_address_line_02,
                    })),
                  },
                })),
                ...unless(X12_2010BB_N4_01, (payer_city_name_01) => ({
                  payer_city_state_zip_code_N4: {
                    payer_city_name_01,
                    payer_state_code_02: X12_2010BB_N4_02,
                    payer_postal_zone_or_zip_code_03: X12_2010BB_N4_03,
                  },
                })),
              },
              claim_information_CLM_loop: [
                {
                  claim_information_CLM: {
                    patient_control_number_01: X12_2300_CLM_01,
                    total_claim_charge_amount_02: X12_2300_CLM_02,
                    health_care_service_location_information_05: {
                      facility_type_code_01: X12_2300_CLM_C023_01,
                      facility_code_qualifier_02: "A",
                      claim_frequency_code_03: X12_2300_CLM_C023_03,
                    },
                    assignment_or_plan_participation_code_07: "A",
                    benefits_assignment_certification_indicator_08: "Y",
                    release_of_information_code_09: "Y",
                  },
                  patient_estimated_amount_due_AMT: {
                    amount_qualifier_code_01: "F3",
                    patient_responsibility_amount_02: Number((expectedAmount * 0.01).toFixed(2)),
                  },
                  admission_date_hour_DTP: {
                    date_time_qualifier_01: "435",
                    date_time_period_format_qualifier_02: "D8",
                    admission_date_and_hour_03: X12_2300_DTP_435_03,
                  },
                  statement_dates_DTP: {
                    date_time_qualifier_01: "434",
                    date_time_period_format_qualifier_02: "RD8",
                    statement_from_and_to_date_03: `${X12_2300_DTP_435_03}-${X12_2300_DTP_096_03}`,
                  },
                  institutional_claim_code_CL1: {
                    admission_type_code_01: "3",
                    admission_source_code_02: "1",
                    patient_status_code_03: X12_2300_CL1_03,
                  },
                  ...unless(X12_2300_CLM_REF_G1_02, (prior_authorization_number_02) => ({
                    prior_authorization_REF: {
                      reference_identification_qualifier_01: "G1",
                      prior_authorization_number_02,
                    },
                  })),
                  ...unless(X12_2300_CLM_HI_C022_02, (occurrence_code_02) => ({
                    ...unless(X12_2300_CLM_HI_C022_04, (occurrence_code_04) => ({
                      occurrence_information_HI: [
                        {
                          health_care_code_information_01: {
                            code_list_qualifier_code_01: "BH",
                            occurrence_code_02: occurrence_code_02,
                            date_time_period_format_qualifier_03: "D8",
                            occurrence_code_date_04: mapDateToCCYYMMDD(occurrence_code_04, "UTC"),
                          },
                        },
                      ],
                    })),
                  })),
                  principal_diagnosis_HI: {
                    health_care_code_information_01: {
                      code_list_qualifier_code_01: "ABK",
                      principal_diagnosis_code_02:
                        diagnoses.at(0)?.icd10Code.replaceAll(/([^A-Za-z0-9])/g, "") ?? "",
                    },
                  },
                  ...unless(
                    diagnoses
                      .map(({ icd10Code }) => ({
                        code_list_qualifier_code_01: "ABF",
                        other_diagnosis_02: icd10Code.replaceAll(/([^A-Za-z0-9])/g, ""),
                      }))
                      .slice(1),
                    (otherDiagnoses) => ({
                      other_diagnosis_information_HI: chunk(otherDiagnoses, 12).map(
                        (otherDiagnosis) =>
                          otherDiagnosis
                            .map(({ code_list_qualifier_code_01, other_diagnosis_02 }, index) => ({
                              [`health_care_code_information_${(index + 1).toString().padStart(2, "0")}`]:
                                {
                                  code_list_qualifier_code_01,
                                  other_diagnosis_02,
                                },
                            }))
                            .reduce(
                              (acc, curr) => ({ ...acc, ...curr }),
                              {}
                            ) as X12837IOtherDiagnosisInformation
                      ),
                    })
                  ),
                  attending_provider_name_NM1_loop: {
                    attending_provider_name_NM1: {
                      entity_identifier_code_01: "71",
                      entity_type_qualifier_02: "1",
                      attending_provider_last_name_03: X12_2310A_NM1_03,
                      ...unless(X12_2310A_NM1_04, (attending_provider_first_name_04) => ({
                        attending_provider_first_name_04,
                      })),
                      ...unless(
                        X12_2310A_NM1_05,
                        (attending_provider_middle_name_or_initial_05) => ({
                          attending_provider_middle_name_or_initial_05,
                        })
                      ),
                      identification_code_qualifier_08: "XX",
                      attending_provider_primary_identifier_09: X12_2310A_NM1_09,
                    },
                    ...unless(X12_2310A_REF, (attending_provider_secondary_identification_REF) => ({
                      attending_provider_secondary_identification_REF,
                    })),
                  },
                  operating_physician_name_NM1_loop: {
                    operating_physician_name_NM1: {
                      entity_identifier_code_01: "72",
                      entity_type_qualifier_02: "1",
                      operating_physician_last_name_03: X12_2310D_NM1_03,
                      ...unless(X12_2310D_NM1_04, (operating_physician_first_name_04) => ({
                        operating_physician_first_name_04,
                      })),
                      ...unless(
                        X12_2310D_NM1_05,
                        (operating_physician_middle_name_or_initial_05) => ({
                          operating_physician_middle_name_or_initial_05,
                        })
                      ),
                      identification_code_qualifier_08: "XX",
                      operating_physician_primary_identifier_09: X12_2310D_NM1_09,
                    },
                    ...unless(
                      X12_2310D_REF,
                      (operating_physician_secondary_identification_REF) => ({
                        operating_physician_secondary_identification_REF,
                      })
                    ),
                  },
                  ...unless(billingPayers, (billingPayers) => ({
                    other_subscriber_information_SBR_loop: billingPayers.map(
                      (
                        {
                          id,
                          address: {
                            address1: X12_2330B_N3_01,
                            address2: X12_2330B_N3_02,
                            city: X12_2330B_N4_01,
                            state: X12_2330B_N4_02,
                            zip: X12_2330B_N4_03,
                          },
                          payment: {
                            authorizationNumber: X12_2330B_REF_G1_02,
                            payerId: X12_2330B_NM1_09,
                            payerName: X12_2330B_NM1_03,
                            policyNumber: X12_2320_SBR_03,
                          },
                          subscriber: {
                            firstName: X12_2330A_NM1_04,
                            lastName: X12_2330A_NM1_03,
                            middleName: X12_2330A_NM1_05,
                          },
                        },
                        index
                      ) => {
                        const X12_2330A_NM1_09 = X12_2320_SBR_03;
                        const X12_2320_SBR_01 = (X12_2330_SBR_01_VALUES.at(index) ??
                          "U") as unknown as X12837IPayerResponsibilitySequenceNumberCode;

                        const coordinationOfBenefitsCobPayerPaidAmountAmt = isPrimaryClaim
                          ? {}
                          : unless(totalPaidPerPayer[id], (payer_paid_amount_02) => ({
                              coordination_of_benefits_cob_payer_paid_amount_AMT: {
                                amount_qualifier_code_01: "D",
                                payer_paid_amount_02: Number(
                                  (payer_paid_amount_02 * 0.01).toFixed(2)
                                ),
                              },
                            }));

                        return X12837IOtherSubscriberInformationLoopSchema.parse({
                          ...coordinationOfBenefitsCobPayerPaidAmountAmt,
                          other_subscriber_information_SBR: {
                            payer_responsibility_sequence_number_code_01: X12_2320_SBR_01,
                            individual_relationship_code_02: "18",
                            ...unless(X12_2320_SBR_03, (insured_group_or_policy_number_03) => ({
                              insured_group_or_policy_number_03,
                            })),
                            claim_filing_indicator_code_09: "ZZ",
                          },
                          other_insurance_coverage_information_OI: {
                            benefits_assignment_certification_indicator_03: "Y",
                            release_of_information_code_06: "Y",
                          },
                          other_subscriber_name_NM1_loop: {
                            other_subscriber_name_NM1: {
                              entity_identifier_code_01: "IL",
                              entity_type_qualifier_02: "1",
                              other_insured_last_name_03: X12_2330A_NM1_03,
                              ...unless(X12_2330A_NM1_04, (other_insured_first_name_04) => ({
                                other_insured_first_name_04,
                              })),
                              ...unless(X12_2330A_NM1_05, (other_insured_middle_name_05) => ({
                                other_insured_middle_name_05,
                              })),
                              identification_code_qualifier_08: "MI",
                              other_insured_identifier_09: X12_2330A_NM1_09,
                            },
                          },
                          other_payer_name_NM1_loop: {
                            other_payer_name_NM1: {
                              entity_identifier_code_01: "PR",
                              entity_type_qualifier_02: "2",
                              other_payer_last_or_organization_name_03: X12_2330B_NM1_03.slice(
                                0,
                                60
                              ).trim(),
                              identification_code_qualifier_08: "PI",
                              other_payer_primary_identifier_09: X12_2330B_NM1_09,
                            },
                            ...unless(X12_2330B_N3_01, (other_payer_address_line_01) => ({
                              other_payer_address_N3: {
                                other_payer_address_line_01,
                                ...unless(X12_2330B_N3_02, (other_payer_address_line_02) => ({
                                  other_payer_address_line_02,
                                })),
                              },
                            })),
                            ...unless(X12_2330B_N4_01, (other_payer_city_name_01) => ({
                              other_payer_city_state_zip_code_N4: {
                                other_payer_city_name_01,
                                other_payer_state_or_province_code_02: X12_2330B_N4_02,
                                other_payer_postal_zone_or_zip_code_03: X12_2330B_N4_03.replaceAll(
                                  NON_ALPHA_NUMERIC_REGEX,
                                  ""
                                ),
                              },
                            })),
                            ...unless(
                              X12_2330B_REF_G1_02,
                              (other_payer_prior_authorization_number_02) => ({
                                other_payer_prior_authorization_number_REF: {
                                  reference_identification_qualifier_01: "G1",
                                  other_payer_prior_authorization_number_02,
                                },
                              })
                            ),
                          },
                        } as X12837IOtherSubscriberInformationLoop);
                      }
                    ) satisfies X12837IOtherSubscriberInformationLoop[],
                  })),
                  service_line_number_LX_loop: billingCharges.map(
                    (
                      {
                        billedAmount,
                        billingChargeMaster: { cptCode, hcpcsCode },
                        billingChargeModifiers,
                        revenueCode,
                        units,
                        priorPayersAmount,
                        outstandingAmount,
                      },
                      index
                    ) => {
                      const X12_2400_LX_01 = index + 1;
                      const X12_2400_SV1_C003_02 = cptCode ?? hcpcsCode ?? "";
                      const X12_2400_SV1_C003_04 = units;
                      const X12_2400_SV1_02 = Number((billedAmount * 0.01).toFixed(2));

                      const X12_2400_DTP_03 = mapDateToCCYYMMDD(
                        kase.surgeryDate,
                        facility.timezone
                      );

                      const X12_2400_SV2_01 = revenueCode;
                      const X12_2400_SV2_C003_03 =
                        billingChargeModifiers.at(0)?.billingModifier?.modifierCode;
                      const X12_2400_SV2_C003_04 =
                        billingChargeModifiers.at(1)?.billingModifier?.modifierCode;
                      const X12_2400_SV2_C003_05 =
                        billingChargeModifiers.at(2)?.billingModifier?.modifierCode;
                      const X12_2400_SV2_C003_06 =
                        billingChargeModifiers.at(3)?.billingModifier?.modifierCode;

                      return X12837IServiceLineNumberLoopSchema.parse({
                        service_line_number_LX: {
                          assigned_number_01: X12_2400_LX_01,
                        },
                        institutional_service_line_SV2: {
                          service_line_revenue_code_01: X12_2400_SV2_01,
                          composite_medical_procedure_identifier_02: {
                            product_or_service_id_qualifier_01: "HC",
                            procedure_code_02: X12_2400_SV1_C003_02,
                            procedure_modifier_03: X12_2400_SV2_C003_03,
                            procedure_modifier_04: X12_2400_SV2_C003_04,
                            procedure_modifier_05: X12_2400_SV2_C003_05,
                            procedure_modifier_06: X12_2400_SV2_C003_06,
                            ...unless(
                              Reflect.get(codes, X12_2400_SV1_C003_02) as string,
                              (description_07) => ({
                                description_07: description_07
                                  .replaceAll(UNALLOWED_CHARS_REGEX, "")
                                  .slice(0, 80), //limit is 80 chars
                              })
                            ),
                          },
                          line_item_charge_amount_03: X12_2400_SV1_02,
                          unit_or_basis_for_measurement_code_04: "UN",
                          service_unit_count_05: X12_2400_SV1_C003_04,
                        },
                        date_service_date_DTP: {
                          date_time_qualifier_01: "472",
                          date_time_period_format_qualifier_02: "RD8",
                          service_date_03: `${X12_2400_DTP_03}-${X12_2400_DTP_03}`,
                        },
                        ...unless(previousBillingClaim, ({ lastSubmittedAt }) =>
                          unless(previousBillingPayer, ({ payment: { payerId } }) =>
                            unless(lastSubmittedAt, (lastSubmittedAt) => ({
                              line_adjudication_information_SVD_loop: [
                                {
                                  line_adjudication_information_SVD: {
                                    other_payer_primary_identifier_01: payerId,
                                    service_line_paid_amount_02: Number(
                                      (priorPayersAmount * 0.01).toFixed(2)
                                    ),
                                    composite_medical_procedure_identifier_03: {
                                      product_or_service_id_qualifier_01: "HC",
                                      procedure_code_02: X12_2400_SV1_C003_02,
                                      ...billingChargeModifiers
                                        .slice(0, 4)
                                        .map(({ billingModifier: { modifierCode } }, index) => ({
                                          [`procedure_modifier_${(index + 3).toString().padStart(2, "0")}`]:
                                            modifierCode.trim(),
                                        }))
                                        .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                                      ...unless(
                                        Reflect.get(codes, X12_2400_SV1_C003_02) as string,
                                        (procedure_code_description_07) => ({
                                          procedure_code_description_07:
                                            procedure_code_description_07
                                              .replaceAll(UNALLOWED_CHARS_REGEX, "")
                                              .slice(0, 80), //limit is 80 chars
                                        })
                                      ),
                                    },
                                    service_line_revenue_code_04: X12_2400_SV2_01,
                                    paid_service_unit_count_05: X12_2400_SV1_C003_04,
                                  },
                                  line_adjustment_CAS: [
                                    {
                                      claim_adjustment_group_code_01: "PR",
                                      adjustment_reason_code_02: "2",
                                      adjustment_amount_03: Number(
                                        (outstandingAmount * 0.01).toFixed(2)
                                      ),
                                    },
                                  ],
                                  line_check_or_remittance_date_DTP: {
                                    date_time_qualifier_01: "573",
                                    date_time_period_format_qualifier_02: "D8",
                                    adjudication_or_payment_date_03:
                                      mapDateToCCYYMMDD(lastSubmittedAt),
                                  },
                                  remaining_patient_liability_AMT: {
                                    amount_qualifier_code_01: "EAF",
                                    remaining_patient_liability_02: Number(
                                      (outstandingAmount * 0.01).toFixed(2)
                                    ),
                                  },
                                },
                              ],
                            }))
                          )
                        ),
                      } satisfies X12837IServiceLineNumberLoop);
                    }
                  ) satisfies X12837IServiceLineNumberLoop[],
                },
              ],
            },
          ],
        },
      ],
    },
  } satisfies X12837I);
}
