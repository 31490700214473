import { z } from 'zod';
import { ClinicalEventResultTypeSchema } from '../inputTypeSchemas/ClinicalEventResultTypeSchema'

/////////////////////////////////////////
// CLINICAL EVENT SCHEMA
/////////////////////////////////////////

export const ClinicalEventSchema = z.object({
  resultType: ClinicalEventResultTypeSchema,
  id: z.string(),
  encounterId: z.string(),
  eventId: z.string(),
  resultText: z.string().nullish(),
  resultInt: z.number().int().nullish(),
  resultFloat: z.number().nullish(),
  resultUnitId: z.string().nullish(),
  marId: z.string().nullish(),
  eventStartTime: z.coerce.date(),
  eventEndTime: z.coerce.date(),
  performerId: z.string().nullish(),
  signedById: z.string().nullish(),
  invalidatedById: z.string().nullish(),
  invalidatedAt: z.coerce.date().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type ClinicalEvent = z.infer<typeof ClinicalEventSchema>

export default ClinicalEventSchema;
