import type { BillingCaseStatus, PrismaClient } from "@procision-software/database";
import { EmrSectionType } from "@procision-software/database";
import {
  BulkCreateSource,
  CaseActor,
  JobRoleType,
  type CaseKeyPoint,
  type CaseStatus,
} from "@procision-software/database-zod";
import type { EmrTemplateWorkflowTypeType } from "@procision-software/database-zod/src/generated/inputTypeSchemas/EmrTemplateWorkflowTypeSchema";
import { getWorkflowsForEncounter } from "../emr-template";
import { encounterParticipantFindMany } from "../encounter-participant";
import { dictionary, noteUpsert } from "../patient-note";
import type { Staff } from "../staff/types";

/**
 * Summary of the chart closure status.
 * @param workflows All workflows for the encounter and their signature status.
 * @param patientKeyPoints All patient key points that have been recorded.
 * @param surgicalTimeoutsTaken The number of surgical timeouts taken.
 * @param orTeamMembers The number of OR team members recorded.
 * @param vitalsTaken The number of vitals taken.
 * @param riskAssessmentInWorkflow The workflow type risk assessment is included in (if any).
 * @param riskAssessmentsCompleted The number of risk assessments completed.
 * @param orders All orders for the encounter and their signature status.
 * @param documents All documents for the encounter and their signature status.
 */
type CloseChartSummary = {
  workflows: {
    id: string;
    workflowType: EmrTemplateWorkflowTypeType;
    isSigned: boolean;
  }[];
  patientKeyPoints: { keyPoint: CaseKeyPoint; eventTime: Date }[];
  surgicalTimeoutsTaken: number;
  orTeamMembers: number;
  vitalsTaken: number;
  riskAssessmentInWorkflow: EmrTemplateWorkflowTypeType | null;
  riskAssessmentsCompleted: number;
  orders: { id: string; requiredJobRoleType: string; isSigned: boolean }[];
  documents: { id: string; isSignedByAll: boolean }[];
};

/**
 * Returns a summary of the chart closure status. This includes the status of all workflows,
 * patient key points, surgical timeouts, OR team members, vitals taken, risk assessments, orders,
 * and documents.
 */
export async function getCloseChartSummary(
  prisma: PrismaClient,
  encounterId: string
): Promise<CloseChartSummary> {
  // Get signature status for all encounter workflows
  const encounter = await prisma.encounter.findFirstOrThrow({
    where: { id: encounterId },
    include: {
      emrTemplate: true,
      workflowPages: {
        include: {
          emrTemplatePage: {
            include: { sections: true },
          },
          signatures: true,
        },
      },
    },
  });
  const pages = await getWorkflowsForEncounter(prisma, encounter.id);
  const workflows = pages
    .map((page) => {
      const workflow = encounter.workflowPages.find(
        (p) => p.emrTemplatePage.workflowType === page.workflowType
      );
      if (!workflow) return null;
      return {
        id: page.id,
        workflowType: page.workflowType,
        isSigned:
          !!workflow.signatures?.[0]?.appliedAt &&
          !!workflow.signatures?.[0]?.appliedStaffSignatureId,
      };
    })
    .filter((w) => w !== null) as CloseChartSummary["workflows"];

  const patientKeyPoints = (
    await encounterParticipantFindMany(prisma, { encounterId, actors: [CaseActor.PATIENT] })
  )
    .map((p) => ({ keyPoint: p.point, eventTime: p.eventTime }))
    .filter((p) => !!p.keyPoint) as {
    keyPoint: CaseKeyPoint;
    eventTime: Date;
  }[];

  const surgicalTimeoutsTaken = await prisma.surgicalTimeout.count({
    where: { clinicalEvent: { encounterId, invalidatedAt: null } },
  });

  const orTeamMembers = (
    await encounterParticipantFindMany(prisma, { encounterId, actors: [CaseActor.STAFF] })
  ).length;

  const vitalsTaken = await prisma.bulkCreate.count({
    where: {
      source: BulkCreateSource.Procision_EMR_Vitals,
      encounters: { some: { id: encounterId } },
    },
  });

  const riskAssessmentInWorkflow =
    encounter.workflowPages.find((page) =>
      page.emrTemplatePage.sections.some(
        (section) => section.type === EmrSectionType.RiskAssessment
      )
    )?.emrTemplatePage.workflowType ?? null;

  const riskAssessmentsCompleted = await prisma.bulkCreate.count({
    where: {
      source: {
        in: [
          BulkCreateSource.Procision_EMR_DVT_Risk_Assessment,
          BulkCreateSource.Procision_EMR_Fall_Risk_Assessment,
          BulkCreateSource.Procision_EMR_PONV_Risk_Assessment,
          BulkCreateSource.Procision_EMR_STOP_BANG_Risk_Assessment,
        ],
      },
      encounters: { some: { id: encounterId } },
    },
  });

  const orders = (
    await prisma.order.findMany({
      where: { encounterId, status: { not: "Invalidated" } },
      include: {
        signatures: {
          take: 1,
          orderBy: [{ createdAt: "desc" }],
        },
      },
    })
  ).map((order) => ({
    id: order.id,
    requiredJobRoleType: order.signatures?.[0]?.requiredJobRoleType ?? JobRoleType.SURGEON,
    isSigned:
      !!order.signatures?.[0]?.appliedAt && !!order.signatures?.[0]?.appliedStaffSignatureId,
  }));

  const documents = (
    await prisma.encounterDocument.findMany({
      where: { encounterId },
      include: {
        signatures: true,
      },
    })
  ).map((doc) => ({
    id: doc.id,
    isSignedByAll: doc.signatures.every(
      (s) => !!s.appliedAt && (!!s.appliedStaffSignatureId || !!s.appliedPatientSignatureId)
    ),
  }));

  return {
    workflows,
    patientKeyPoints,
    surgicalTimeoutsTaken,
    orTeamMembers,
    vitalsTaken,
    riskAssessmentInWorkflow,
    riskAssessmentsCompleted,
    orders,
    documents,
  } satisfies CloseChartSummary;
}

type EncounterClosureData = {
  closedAt: Date;
  closedBy: Pick<Staff, "id">;
  chartValid: boolean;
  forceClosed: boolean;
  isIncomplete: boolean;
  hasVariance: boolean;
  hasIncidents: boolean;
  varianceNote: string | undefined;
  incidentNote: string | undefined;
  printed: boolean;
  billable: BillingCaseStatus;
  userId: string;
  caseStatus: CaseStatus;
};

/*
Takes each of the fields from EncounterClosure, plus string values for related notes.

Writes to a record.

Writes notes.

Sets open=false on encounter.

Creates billingCase when approrpriate. (One could already exist, or, the encounter could lack an appointmentId)

Updates billingCase's status when approrpriate.
*/
/**
 * Captures details about a chart being closed.
 * @param data.closedAt The date the chart was closed.
 * @param data.closedBy.id The staff ID who closed the chart.
 * @param data.chartValid Whether the chart is valid.
 * @param data.forceClosed Whether the chart was force closed.
 * @param data.isIncomplete Whether the chart is incomplete.
 * @param data.hasVariance Whether the chart has a variance.
 * @param data.hasIncidents Whether the chart has incidents.
 * @param data.varianceNote The note about the variance.
 * @param data.incidentNote The note about the incidents.
 * @param data.printed Whether the chart was printed.
 * @param data.billable The billing case status.
 * @param data.signature.id The signature of the person who closed the chart.
 * @param data.userId The interactive user doing the close

 */

export async function closeChart(
  prisma: PrismaClient,
  encounterId: string,
  data: EncounterClosureData
): Promise<{
  id: string;
  encounterId: string;
  closedAt: Date;
  closedById: string;
  chartValid: boolean;
  forceClosed: boolean;
  isIncomplete: boolean;
  hasVariance: boolean;
  hasIncidents: boolean;
  printed: boolean;
}> {
  if (data.hasIncidents && !data.incidentNote) {
    throw new Error("Incident note is required when incidents are present");
  }
  if (data.hasVariance && !data.varianceNote) {
    throw new Error("Variance note is required when variance is present");
  }
  const closer = await prisma.staff.findFirstOrThrow({
    where: {
      id: data.closedBy.id,
    },
  });
  const encounter = await prisma.encounter.findFirstOrThrow({
    where: {
      id: encounterId,
    },
    include: {
      appointment: {
        include: {
          billingCases: true,
        },
      },
    },
  });
  if (!encounter.appointmentId) {
    throw new Error("Encounter must have an appointmentId to be closed");
  }
  const encounterClosure = prisma.encounterClosure.create({
    data: {
      encounter: {
        connect: {
          id: encounter.id,
        },
      },
      closedBy: {
        connect: {
          id: closer.id,
        },
      },
      closedAt: data.closedAt,
      chartValid: data.chartValid,
      forceClosed: data.forceClosed,
      isIncomplete: data.isIncomplete,
      hasVariance: data.hasVariance,
      hasIncidents: data.hasIncidents,
      printed: data.printed,
    },
  });
  const encounterUpdate = prisma.encounter.update({
    where: {
      id: encounter.id,
    },
    data: {
      open: false,
      // TODO: Should we be setting the encounter status as well?
    },
  });
  const appointmentUpdate = prisma.case.update({
    where: {
      id: encounter.appointmentId,
    },
    data: {
      status: data.caseStatus,
    },
  });
  const billingCaseOperation = ((
    billingCase: { id: string } | { appointmentId: string },
    billingCaseStatus: BillingCaseStatus
  ) => {
    if ("id" in billingCase) {
      return prisma.billingCase.update({
        where: {
          id: billingCase.id,
        },
        data: {
          status: billingCaseStatus,
        },
      });
    } else {
      return prisma.billingCase.create({
        data: {
          caseId: billingCase.appointmentId,
          status: billingCaseStatus,
        },
      });
    }
  })(
    encounter.appointment?.billingCases[0] ?? { appointmentId: encounter.appointmentId },
    data.billable
  );
  const varianceNote = data.hasVariance
    ? noteUpsert(
        prisma,
        encounter.id,
        dictionary.closurevariancenotes,
        data.varianceNote ?? "",
        data.userId
      )
    : Promise.resolve();
  const incidentNote = data.hasIncidents
    ? noteUpsert(
        prisma,
        encounter.id,
        dictionary.closureincidentnotes,
        data.incidentNote ?? "",
        data.userId
      )
    : Promise.resolve();
  const [closure] = await prisma.$transaction([
    encounterClosure,
    encounterUpdate,
    appointmentUpdate,
    billingCaseOperation,
  ]);
  await Promise.all([varianceNote, incidentNote]);
  return closure;
}
