import type { PrismaClient } from "@procision-software/database";
import type { Staff } from "./types";

export async function getCareTeam(prisma: PrismaClient, encounterId: string): Promise<Staff[]> {
  const encntr = await prisma.encounter.findFirstOrThrow({
    where: { id: encounterId },
    include: {
      appointment: {
        include: {
          caseRoles: {
            include: {
              staff: true,
            },
          },
          surgeon: true,
        },
      },
    },
  });
  if (!encntr.appointment) {
    return [];
  }

  return [encntr.appointment.surgeon, ...encntr.appointment.caseRoles.map(({ staff }) => staff)];
}

export const hasQualification = (staff: Pick<Partial<Staff>, "qualification">): boolean =>
  !!staff.qualification;
export const hasMiddleName = (staff: Pick<Partial<Staff>, "middleName">): boolean =>
  !!staff.middleName;

export const staffNameLastFirstTitle = (
  staff: Pick<Partial<Staff>, "firstName" | "lastName" | "qualification">
): string =>
  `${staff.lastName}, ${staff.firstName}${hasQualification(staff) ? `, ${staff.qualification}` : ""}`;

export const staffFullName = (
  staff: Pick<Partial<Staff>, "firstName" | "lastName" | "middleName">
): string =>
  `${staff.lastName}, ${staff.firstName}${hasMiddleName(staff) ? ` ${staff.middleName}` : ""}`;

export function staffForUser(prisma: Pick<PrismaClient, "staff">, userId: string): Promise<Staff> {
  return prisma.staff.findFirstOrThrow({
    where: {
      userId,
    },
  });
}
