import { z } from 'zod';
import { CaseActorSchema } from '../inputTypeSchemas/CaseActorSchema'
import { DirectionSchema } from '../inputTypeSchemas/DirectionSchema'
import { CaseKeyPointSchema } from '../inputTypeSchemas/CaseKeyPointSchema'

/////////////////////////////////////////
// ENCOUNTER PARTICIPANT SCHEMA
/////////////////////////////////////////

/**
 * A journal of when individuals involved in an encounter were present at the facility (Actuals)
 */
export const EncounterParticipantSchema = z.object({
  /**
   * who is this attendance for? the patient, the staff, or the facility?
   */
  actor: CaseActorSchema,
  /**
   * in or out?
   */
  direction: DirectionSchema,
  point: CaseKeyPointSchema.nullish(),
  id: z.string(),
  encounterId: z.string(),
  staffId: z.string().nullish(),
  /**
   * what role did this person play in the encounter?
   */
  jobRoleId: z.string().nullish(),
  roomId: z.string().nullish(),
  /**
   * when did this event occur?
   */
  eventTime: z.coerce.date(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type EncounterParticipant = z.infer<typeof EncounterParticipantSchema>

export default EncounterParticipantSchema;
