import { useFlags } from "launchdarkly-react-client-sdk";

// When a FF is added to launch darkly, indicate it here with it's corresponding type.
export type KnownLDFlags = {
  emrFeatures: boolean; /// is "EMR Module" enabled in this env
  claimValidation: boolean; /// is "Claim Validation" enabled in this env
  releaseTimeBlock: boolean; /// is "Release Time Block" enabled in this env
  billing: boolean; /// is "Billing" enabled in this env? Probably true.
  consents: boolean;
  darkMode: boolean; // is "Dark Mode" enabled in this env
  maintenanceMode: boolean; // is "Maintenance Mode" enabled in this env? It's marked OFF in all environments.
  bulkCaseImport: boolean; // is "Bulk Case Import" enabled in this env
  enableIntercom: boolean; // is "Intercom" enabled in this env? Probably.
  materialsDemo: boolean; // is "Materials Management" demo enabled in this env
  reports: boolean; // Enables reporting section in top nav. Reports is marked ON in all environments.
  scheduling: boolean; // Enables scheduling features. Scheduling is marked ON in all environments.
  generateOrderText: boolean; // Enables "Generate Order Text" feature in apps/scheduling/src/concept/standingorder/ApplicableStandingOrdersWorksheet.tsx
  userButtonSettingsLink: boolean; // Enables "Settings" link in Clerk UserButton Menu
  digitalContractsDemo: boolean; // Enables "Digital Contracts" features for demo.
};

// centralized place to manage which FF are in play
export const useFeatureFlags = (): KnownLDFlags => {
  return useFlags<KnownLDFlags>();
};
