import { z } from 'zod';

/////////////////////////////////////////
// CONDITION SCHEMA
/////////////////////////////////////////

export const ConditionSchema = z.object({
  name: z.string(),
  icd10: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Condition = z.infer<typeof ConditionSchema>

export default ConditionSchema;
