import { cn } from "@procision-software/ui";
import React, { useCallback } from "react";

type InputQuantityProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "type" | "value" | "min" | "max" | "onChange"
> & {
  value?: number;
  onChange?: (newValue: number) => void;
  min?: number;
  max?: number;
  error?: boolean;
};

/**
 * @deprecated Use UI package components instead
 */
export const InputQuantity = React.forwardRef<HTMLInputElement, InputQuantityProps>(
  (
    { className, onChange, disabled, readOnly, error, ...props },
    ref?: React.ForwardedRef<HTMLInputElement>
  ) => {
    const handleChange = useCallback(
      (value?: number | string) => {
        let val = value ? parseInt(value.toString(), 10) : 0;
        if (props.max != null) val = Math.min(val, props.max);
        if (props.min != null) val = Math.max(val, props.min);
        onChange?.(val);
      },
      [onChange, props.min, props.max]
    );

    return (
      <div
        className={cn(
          "input flex w-[6.3rem] flex-row flex-nowrap items-stretch gap-1 p-0",
          "focus-within:ring-1 focus-within:ring-blue-500",
          disabled && "cursor-not-allowed opacity-50",
          error && "input-error"
        )}
      >
        <button
          type="button"
          disabled={disabled || readOnly}
          className={cn("rounded-l-lg px-2", disabled && "cursor-not-allowed")}
          aria-label="Decrease amount"
          tabIndex={-1}
          onClick={() => handleChange((props.value ?? 0) - 1)}
        >
          -
        </button>
        <input
          {...props}
          ref={ref}
          type="text"
          disabled={disabled}
          readOnly={readOnly}
          className={cn(
            "m-0 block w-full border-0 bg-transparent p-0 py-2.5 text-center text-inherit",
            "focus:border-0 focus:outline-none focus:ring-0",
            disabled && "cursor-not-allowed",
            className
          )}
          onChange={(e) => handleChange(e.currentTarget.value)}
        />
        <button
          type="button"
          disabled={disabled || readOnly}
          className={cn("rounded-r-lg px-2", disabled && "cursor-not-allowed")}
          aria-label="Increase amount"
          tabIndex={-1}
          onClick={() => handleChange((props.value ?? 0) + 1)}
        >
          +
        </button>
      </div>
    );
  }
);
InputQuantity.displayName = "InputQuantity";
