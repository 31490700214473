import { z } from 'zod';
import { BillingTransactionStatusSchema } from '../inputTypeSchemas/BillingTransactionStatusSchema'
import { BillingPayerTypeSchema } from '../inputTypeSchemas/BillingPayerTypeSchema'
import { PaymentTypeSchema } from '../inputTypeSchemas/PaymentTypeSchema'

/////////////////////////////////////////
// BILLING TRANSACTION SCHEMA
/////////////////////////////////////////

export const BillingTransactionSchema = z.object({
  status: BillingTransactionStatusSchema,
  payerType: BillingPayerTypeSchema,
  paymentMethod: PaymentTypeSchema,
  id: z.string(),
  amount: z.number().int(),
  currency: z.string(),
  checkDate: z.coerce.date().nullish(),
  aba: z.string().nullish(),
  payerName: z.string(),
  transactionDate: z.coerce.date(),
  referenceNumber: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  organizationId: z.string(),
  providerId: z.string().nullish(),
})

export type BillingTransaction = z.infer<typeof BillingTransactionSchema>

export default BillingTransactionSchema;
