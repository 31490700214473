import type { NextRouter } from "next/router";

export function currentUri(
  router: Pick<NextRouter, "isReady" | "asPath" | "basePath" | "pathname">,
  defaultRoute: string = "/"
): string {
  // TODO: I'm not sure if basePath is only ommited from asPath or if it's also ommited from pathname
  return (
    router.isReady
      ? `${router.basePath}${router.asPath}`
      : router.pathname.includes("[")
        ? defaultRoute
        : router.pathname
  ).replace("//", "/");
}
