import { z } from 'zod';

/////////////////////////////////////////
// SURGERY PROFILE DIAGNOSIS SCHEMA
/////////////////////////////////////////

export const SurgeryProfileDiagnosisSchema = z.object({
  id: z.string(),
  surgeryProfileId: z.string(),
  icd10: z.string(),
  sequenceNumber: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type SurgeryProfileDiagnosis = z.infer<typeof SurgeryProfileDiagnosisSchema>

export default SurgeryProfileDiagnosisSchema;
