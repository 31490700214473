import { Dialog, cn, createFastContext, useAppContext } from "@procision-software/ui";
import { type ReactNode } from "react";
import MessageThreadContextProvider from "../components/Message/MessageThreadContext";
import Messages from "../components/Message/Messages";

export type ThreadSource = {
  id: string | null | undefined;
  title: string;
  onCreateMessageThread?: (id: string) => Promise<unknown> | void;
  onCreateMessage?: (id: string) => Promise<unknown> | void;
};

type MessageContext = {
  showMessagesDialog: boolean;
  dialogTitle?: string;
  threads: ThreadSource[];
  selectedThread?: ThreadSource | null | undefined;
};

const { Provider, useStore } = createFastContext<MessageContext>({
  showMessagesDialog: false,
  threads: [],
});

export function MessagesProvider({ children }: { children: ReactNode }) {
  return (
    <Provider>
      {children}
      <MessagesDialog />
    </Provider>
  );
}

export function useMessages() {
  const [state, setState] = useStore((store) => store);

  const showMessages = ({
    threads,
    dialogTitle,
  }: {
    threads: ThreadSource[];
    dialogTitle?: string;
  }) => {
    setState({ showMessagesDialog: true, dialogTitle, threads, selectedThread: threads[0] });
  };

  return { state, showMessages };
}

function MessagesDialog() {
  const { user, facility, practice } = useAppContext();
  if (!user) throw new Error("MessagesDialog requires a user");

  const [store, setStore] = useStore((store) => store);
  const { showMessagesDialog, threads, selectedThread } = store;

  if (!showMessagesDialog) return null;
  return (
    <Dialog
      show={true}
      title={store.dialogTitle ?? "Messages"}
      onClose={() => setStore({ showMessagesDialog: false, threads: [], selectedThread: null })}
      className="h-3/4 w-3/4"
    >
      <div className="flex h-full gap-4">
        {threads.length > 1 && (
          <div className="flex flex-col gap-2 border-r border-primary pr-4">
            <h2>Threads</h2>
            {threads.map((thread, i) => (
              <button
                key={String(i)}
                onClick={() => setStore({ selectedThread: thread })}
                className={cn(
                  "rounded-lg px-4 py-2 text-left hover:bg-primary",
                  selectedThread && threads.indexOf(selectedThread) === i && "bg-primary"
                )}
              >
                {thread.title}
              </button>
            ))}
          </div>
        )}
        {selectedThread && (
          <div className="flex flex-1 flex-col gap-2">
            {threads.length > 0 && selectedThread.title && <h2>{selectedThread.title}</h2>}
            <MessageThreadContextProvider
              userId={user.id}
              facilityId={facility.id}
              practiceId={practice?.id}
              messageThreadId={selectedThread.id ?? undefined}
              onCreateMessageThread={(id) => {
                const idx = threads.indexOf(selectedThread);
                const updatedThread = { ...selectedThread, id };
                setStore({
                  threads: [...threads.slice(0, idx), updatedThread, ...threads.slice(idx + 1)],
                  selectedThread: updatedThread,
                });
                void selectedThread.onCreateMessageThread?.(id);
              }}
              onCreateMessage={selectedThread.onCreateMessage}
            >
              <Messages />
            </MessageThreadContextProvider>
          </div>
        )}
      </div>
    </Dialog>
  );
}
