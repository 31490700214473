import { CodeableConceptSchema, EncounterDocumentSchema } from "@procision-software/database-zod";
import { z } from "zod";
import { DocumentTemplateRecordSchema } from "../document-template";
import { SignatureRecordSchema } from "../signature";

export type EncounterDocumentEditableField = "title" | "typeId" | "body";

// encounter is how you got here, not the data you're looking for. So it's not included in the record schema here.
export const EncounterDocumentRecordSchema = EncounterDocumentSchema.extend({
  documentTemplate: DocumentTemplateRecordSchema.nullable(),
  // TODO: Restore the codeableConceptSchemaAtPath once we can determine why it errors in production
  // type: codeableConceptSchemaAtPath("WF.DOC.TYPE"),
  type: CodeableConceptSchema,
  signatures: z.array(SignatureRecordSchema),
});
export type EncounterDocumentRecord = z.infer<typeof EncounterDocumentRecordSchema>;

export const EncounterDocumentFormDataSchema = EncounterDocumentRecordSchema.pick({
  body: true,
  documentTemplateId: true,
  encounterId: true,
  title: true,
  typeId: true,
});

export type EncounterDocumentFormData = z.infer<typeof EncounterDocumentFormDataSchema>;
