import { z } from 'zod';

/////////////////////////////////////////
// BUCKET SCHEMA
/////////////////////////////////////////

export const BucketSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  organizationId: z.string(),
  name: z.string(),
})

export type Bucket = z.infer<typeof BucketSchema>

export default BucketSchema;
