import type { Prisma, PrismaClient as PrismaClientBase } from "@prisma/client";
import type { PaginationInput } from "../../util/pagination";
import type {
  PatientSignatureFindFilter,
  PatientSignatureFormData,
  PatientSignatureRecord,
} from "./types";

type PrismaClient = Pick<PrismaClientBase, "patientSignature">;

const include = {
  patient: true,
};

function patientSignatureWhereClause({
  patientId,
}: PatientSignatureFindFilter): Prisma.PatientSignatureWhereInput {
  const where: Prisma.PatientSignatureWhereInput = {};

  if (patientId) {
    where.patientId = patientId;
  }

  return where;
}

export async function patientSignatureCount(
  prisma: PrismaClient,
  filters: PatientSignatureFindFilter
): Promise<number> {
  const where = patientSignatureWhereClause(filters);

  return await prisma.patientSignature.count({
    where,
  });
}

export async function patientSignatureCreate(
  prisma: PrismaClient,
  { patientId, blob, representativeName }: PatientSignatureFormData
): Promise<PatientSignatureRecord> {
  return await prisma.patientSignature.create({
    data: {
      blob,
      representativeName,
      patient: {
        connect: {
          id: patientId,
        },
      },
    },
    include,
  });
}

export async function patientSignatureDelete(
  prisma: PrismaClient,
  id: string
): Promise<PatientSignatureRecord> {
  return await prisma.patientSignature.delete({
    include,
    where: {
      id,
    },
  });
}

export async function patientSignatureFindById(
  prisma: PrismaClient,
  filters: PatientSignatureFindFilter
): Promise<PatientSignatureRecord | null> {
  if (!filters.patientId) {
    return null;
  }

  const where = patientSignatureWhereClause(filters);

  return await prisma.patientSignature.findFirst({
    include,
    where,
  });
}

export async function patientSignatureFindMany(
  prisma: PrismaClient,
  filters: PatientSignatureFindFilter,
  { page, perPage }: PaginationInput
): Promise<PatientSignatureRecord[]> {
  const where = patientSignatureWhereClause(filters);

  return await prisma.patientSignature.findMany({
    include,
    skip: (page - 1) * perPage,
    take: perPage,
    where,
  });
}

export function patientSignatureUpdate(
  _prisma: PrismaClient,
  _id: string,
  _data: Partial<PatientSignatureFormData>
): Promise<PatientSignatureRecord> {
  throw new Error("Not supported");
}
