import { type AppAbility, PermissionDeniedError } from "@procision-software/auth";
import { prisma, type Prisma } from "@procision-software/database";
import type {
  Case,
  CasePreferenceCard,
  CasePreferenceCardEquipment,
  CaseVersion,
  DiagnosisVersion,
  Equipment,
  EquipmentRequirementVersion,
  OrganizationType,
  PaymentInsuranceVersion,
  PaymentSelfPayVersion,
  PaymentWorkersCompVersion,
  PreferenceCard,
  PreferenceCardEquipment,
  ProcedureVersion,
  RoomVersion,
  Staff,
  StaffVersion,
} from "@procision-software/database-zod";
import {
  CaseStatus,
  CaseStatusSchema,
  DaysOfWeek,
  EquipmentRequirementRepresentativeStatus,
  EquipmentRequirementStatus,
  HealthReviewStatus,
  JobRoleType,
  PreAuthStatus,
  TimePeriodType,
} from "@procision-software/database-zod";
import { birthDateTime } from "@procision-software/ui/src/utils/dates";
import { DateTime } from "luxon";
import { z, type ZodSchema } from "zod";
import { belongsTo } from "~/utils/belongsTo";
import { isSameDay, unixEpoch } from "~/utils/dates";
import type { PrismaClient } from "~/utils/flexible-prisma-client";
import { timeStringToDateUnits } from "~/utils/time";
import { validatorForAdvancingCase, validatorForCase } from "~/validators/Case";
import { diffBetween, diffEntry, getEarlierRev } from "./audit";
import { facilityDateTimeFactory, utcDateTime } from "./facility";
import type { AuditEntry, VersionedData } from "./schema/Audit";
import { type CaseStatusOutlook } from "./schema/Case";
import { type UpdateCaseStatusTracker } from "~/server/job/update-case-status-tracker/interface";
import type { Queue } from "bullmq";

let _private_local_queue: Queue | null = null;
async function queue(): Promise<Queue> {
  if (typeof window === "undefined") {
    if (_private_local_queue) return _private_local_queue;
    // importing bullmq creates a dependency on
    // child_process which is not available in the browser
    // importing bullmq.config introduces a dependency
    // on server env vars which next disallowes in browser
    const [{ Queue }, { default: config }] = await Promise.all([
      import("bullmq"),
      import("~/server/bullmq.config"),
    ]);
    _private_local_queue = new Queue<UpdateCaseStatusTracker>(config.queueName, {
      connection: config.connection,
    });
    return _private_local_queue;
  }
  throw new Error("Queue is not available in the browser");
}

declare const CaseId: unique symbol;
export type CaseId = string & { readonly tag: typeof CaseId };
export function toCaseId(id: string): CaseId {
  return id as CaseId;
}
export function caseIdToString(id: CaseId): string {
  return String(id); // TODO: applyprepayment.spec.ts fails when this is .toString() but works as String() there's gotta be a bug in the spec but I don't see it
}

/**
 * these are states that would not have a matching encounter. They're not real cases.
 */
export const NON_ENCOUNTER_STATUSES: CaseStatus[] = [
  CaseStatus.Abandoned,
  CaseStatus.Canceled,
  CaseStatus.Denied,
  CaseStatus.Requested,
  CaseStatus.Draft,
];

const COMMON_INCLUDES = {
  case: {
    patient: true,
    room: true,
    surgeon: true,
    practice: true,
    facility: true,
    tags: true,
    caseMedicalConditions: true,
  },
  procedures: {
    procedures: {
      include: {
        cptCode: true,
      },
    },
  },
  diagnoses: {
    diagnoses: {
      include: {
        condition: true,
      },
    },
  },
  provider: {
    surgeon: true,
    additionalStaff: true,
    caseRoles: {
      include: {
        staff: true,
        jobRole: true,
      },
    },
  },
  equipment: {
    equipmentRequirements: {
      include: {
        equipment: {
          include: {
            supplier: true,
            supplierRep: true,
          },
        },
      },
    },
  },
  payments: {
    paymentInsurance: { include: { provider: true } },
    paymentSelfPay: true,
    paymentWorkersComp: true,
    paymentLetterProtection: true,
  },
  healthReviewRequirements: {
    healthReviewRequirements: {
      include: {
        healthReviewRequirementAttachments: true,
        user: true,
      },
    },
  },
};

export function touchCase(id: string, alsoAcknowledge = false) {
  // TODO: callers should determine if user is both ASC and if record was previously acknowledged
  //       if so, send autoAcknowledge = true and the case's acknowledgement will be preserved
  const updatedAt = new Date();
  const acknowledgedAt = alsoAcknowledge ? new Date(Date.now() + 2000) : undefined;
  if (prisma) {
    void prisma.case
      .update({
        where: {
          id,
        },
        data: {
          updatedAt,
          ...(alsoAcknowledge && {
            acknowledgedAt: acknowledgedAt,
          }),
        },
      })
      .then(async () => (await queue()).add("update-case-status-tracker", { caseId: id }))
      .then(() => true);
  }
}

type PrismaFunction<T> = (config: unknown) => Promise<T[]>;

export async function getCase({ caseId, prisma }: { caseId: string; prisma: PrismaClient }) {
  return await prisma.case.findFirstOrThrow({
    where: {
      id: caseId,
    },
    include: {
      ...COMMON_INCLUDES.case,
      ...COMMON_INCLUDES.procedures,
    },
  });
}

export async function createCase(
  {
    prisma,
    practiceId,
    facilityId,
    surgeonId,
    status,
    createdBy,
  }: {
    prisma: Omit<
      PrismaClient,
      "$connect" | "$disconnect" | "$on" | "$transaction" | "$use" | "$extends"
    >;
    practiceId: string;
    facilityId: string;
    surgeonId: string;
    status: CaseStatus;
    createdBy: OrganizationType;
  },
  additionalData?: Omit<
    Prisma.CaseUncheckedCreateInput,
    "requestedById" | "facilityId" | "practiceId" | "surgeonId"
  >
) {
  const facility = await prisma.facility.findFirstOrThrow({
    where: { id: facilityId },
  });

  const practice = await prisma.practice.findFirstOrThrow({
    where: { id: practiceId },
  });

  const surgeon = await prisma.staff.findFirstOrThrow({
    where: { id: surgeonId },
  });

  const { roomId, ...additionalDataWithoutRelations } = additionalData ?? { roomId: undefined };

  const caseRecordPayload: Prisma.CaseCreateInput = {
    ...additionalDataWithoutRelations,
    practice: belongsTo(practice.id),
    facility: belongsTo(facility.id),
    surgeon: belongsTo(surgeon.id),
    requestedBy: belongsTo(surgeon.id), // TODO: remove this or convert to logged in user
    status,
    createdBy,
    surgeryDate: additionalData?.surgeryDate ?? unixEpoch(),
    roomTurnOverTime: facility.defaultRoomTurnOverTimeInMinutes,
    patientFirstName: additionalData?.patientFirstName ?? "",
    patientLastName: additionalData?.patientLastName ?? "",
    ...(roomId && { room: belongsTo(roomId) }),
  };

  const kase = await prisma.case.create({
    data: caseRecordPayload,
  });
  if (!kase) throw new PermissionDeniedError();

  await createHealthReviewRequirements(prisma, kase);
  touchCase(kase.id, true);

  return kase;
}

async function createHealthReviewRequirements(
  prisma: Omit<
    PrismaClient,
    "$connect" | "$disconnect" | "$on" | "$transaction" | "$use" | "$extends"
  >,
  kase: Case
) {
  const { facilityId } = kase;

  const facility = await prisma.facility.findFirst({
    where: { id: facilityId },
  });

  if (facility) {
    const { organizationId } = facility;
    const healthReviewConditions = await prisma.healthReviewCondition.findMany({
      where: {
        AND: [{ organizationId }],
        OR: [{ isRequired: true }, { isRequiredOver50: true }],
      },
    });

    const { id: caseId, patientDateOfBirth } = kase;

    const isOver50 = patientDateOfBirth
      ? Math.abs(birthDateTime(patientDateOfBirth).diffNow("years").years) >= 50
      : false;

    for (const healthReviewCondition of healthReviewConditions) {
      const { healthReviewTypeId, isRequired, isRequiredOver50 } = healthReviewCondition;

      if (isRequired || (isRequiredOver50 && isOver50)) {
        await prisma.healthReviewRequirement.create({
          data: {
            case: belongsTo(caseId),
            healthReviewType: belongsTo(healthReviewTypeId),
          },
        });
      }
    }
  }
}

export type CaseDetailsRecord = Awaited<ReturnType<typeof getCaseDetailsRecord>>;

export async function getCaseDetailsRecord(
  prisma: PrismaClient,
  caseId: string,
  _ability: AppAbility
) {
  const caseRecord = await prisma.case.findFirstOrThrow({
    where: { id: caseId },
    include: {
      ...COMMON_INCLUDES.case,
      ...COMMON_INCLUDES.procedures,
      ...COMMON_INCLUDES.diagnoses,
      ...COMMON_INCLUDES.equipment,
      ...COMMON_INCLUDES.provider,
      ...COMMON_INCLUDES.payments,

      encounters: { select: { id: true } },
      tags: true,
    },
  });
  const orStaffRoleIds = (
    await prisma.jobRole.findMany({
      where: {
        name: "OR Staff",
      },
    })
  ).map((jr) => jr.id);
  const anesthesiologistRoleIds = (
    await prisma.jobRole.findMany({
      where: {
        type: JobRoleType.ANESTHESIOLOGIST,
      },
    })
  ).map((jr) => jr.id);
  return {
    ...caseRecord,
    anesthesiologistStaff:
      caseRecord?.caseRoles
        .filter((cr) => anesthesiologistRoleIds.indexOf(cr.jobRoleId) !== -1)
        .map((cr) => cr.staff) || [],
    anesthesiologistStaffIds:
      caseRecord?.caseRoles
        .filter((cr) => anesthesiologistRoleIds.indexOf(cr.jobRoleId) !== -1)
        .map((cr) => cr.staffId) || [],
    orStaff:
      caseRecord?.caseRoles
        .filter((cr) => orStaffRoleIds.indexOf(cr.jobRoleId) !== -1)
        .map((cr) => cr.staff) || [],
    orStaffIds:
      caseRecord?.caseRoles
        .filter((cr) => orStaffRoleIds.indexOf(cr.jobRoleId) !== -1)
        .map((cr) => cr.staffId) || [],
    medicalConditionIds: caseRecord?.caseMedicalConditions.map((cmc) => cmc.medicalConditionId),
  };
}

export type CasePreferenceCardsRecord = Awaited<ReturnType<typeof getCasePreferenceCardsRecord>>;

export async function getCasePreferenceCardsRecord(
  prisma: PrismaClient,
  caseId: string,
  _ability?: AppAbility
) {
  const caseRecord = await prisma.case.findFirstOrThrow({
    where: { id: caseId },
    include: COMMON_INCLUDES.case,
  });
  const casePreferenceCards = await Promise.all(
    (
      await prisma.casePreferenceCard.findMany({
        where: {
          caseId: caseRecord?.id,
        },
        include: {
          equipment: true,
        },
      })
    ).map(
      (cpc) =>
        new Promise((resolve, reject) => {
          void prisma.preferenceCard
            .findFirst({
              where: {
                id: cpc.preferenceCardId,
              },
              include: {
                provider: true,
                equipment: {
                  include: {
                    equipment: true,
                  },
                },
              },
            })
            .catch((e) => reject(e))
            .then((pc) => {
              if (!pc)
                return reject(
                  new Error(
                    "Preference card not found. Was seeking preference card " + cpc.preferenceCardId
                  )
                );
              resolve({
                ...cpc,
                equipment: cpc.equipment.map((cpcE) => {
                  const pcE = pc.equipment.find(
                    (pcE) => pcE.equipmentId === cpcE.equipmentId
                  )?.equipment;
                  return {
                    ...cpcE,
                    equipment: pcE,
                  };
                }),
                preferenceCard: pc,
              });
            });
        })
    )
  );
  return {
    ...caseRecord,
    casePreferenceCards: casePreferenceCards as (CasePreferenceCard & {
      equipment: (CasePreferenceCardEquipment & { equipment: Equipment })[];
      preferenceCard: PreferenceCard & {
        equipment: (PreferenceCardEquipment & {
          equipment: Equipment;
        })[];
        provider: Staff;
      };
    })[],
  };
}

export type CasePaymentRecord = Awaited<ReturnType<typeof getCasePayment>>;
export async function getCasePayment(prisma: PrismaClient, id: string) {
  const caseRecord = await prisma.case.findFirstOrThrow({
    where: { id },
    include: {
      ...COMMON_INCLUDES.case,
      ...COMMON_INCLUDES.procedures,
      ...COMMON_INCLUDES.payments,
    },
  });
  return caseRecord;
}

export type CaseHealthReviewRecord = Awaited<ReturnType<typeof getCaseHealthReview>>;
export async function getCaseHealthReview(prisma: PrismaClient, id: string) {
  const caseRecord = await prisma.case.findFirstOrThrow({
    where: { id },
    include: {
      ...COMMON_INCLUDES.case,
      ...COMMON_INCLUDES.healthReviewRequirements,
      ...COMMON_INCLUDES.payments,
      ...COMMON_INCLUDES.procedures,
    },
  });
  return caseRecord;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function atTimeOne(model: any, id: string | null | undefined, timestamp: Date) {
  if (id === null) return null;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  return model.findFirst({
    where: {
      id,
      versionTimestamp: {
        lte: timestamp, // allow for touch being called before actual records are modified
      },
    },
    orderBy: {
      versionTimestamp: "desc",
    },
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function atTimeMany(model: any, condition: Record<string, unknown>, timestamp: Date) {
  type VersionsWithId = VersionedData & { id: string };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const findMany = model.findMany as PrismaFunction<VersionsWithId>;
  const ids = await findMany({
    where: {
      versionTimestamp: {
        lte: timestamp,
      },
      ...condition,
    },
    select: {
      id: true,
      versionOperation: true,
      versionId: true,
    },
    orderBy: {
      versionId: "asc",
    },
  });
  // create a map of ids. The key should be id. The value should be the full row.
  const recentById = ids.reduce(
    (acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    },
    {} as Record<string, VersionsWithId>
  );
  // any records with a versionOperation of delete should be removed.
  const survivors = Object.entries(recentById).filter(
    ([_id, row]) => row.versionOperation !== "delete"
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return await (model.findMany as PrismaFunction<unknown>)({
    where: {
      OR: survivors.map(([rowId, row]) => ({
        id: rowId,
        versionId: row.versionId,
      })),
    },
  });
}

const attachCaseRelations = async (kase: CaseVersion, prisma: PrismaClient) => {
  // this creates a lot of queries. Load all case versions, then load this data for each of them.
  // but we don't have relationships on the version tables to do this in one query.
  // we could do this with a raw query, but that would be a lot of work.
  const [
    surgeon,
    room,
    requestedBy,
    procedures,
    diagnosis,
    equipmentRequirements,
    paymentInsurance,
    paymentSelfPay,
    paymentWorkersComp,
    ...rest
  ] = await Promise.all([
    atTimeOne(prisma.staffVersion, kase.surgeonId, kase.versionTimestamp) as Promise<StaffVersion>,
    atTimeOne(prisma.roomVersion, kase.roomId, kase.versionTimestamp) as Promise<RoomVersion>,
    atTimeOne(
      prisma.staffVersion,
      kase.requestedById,
      kase.versionTimestamp
    ) as Promise<StaffVersion>,
    atTimeMany(prisma.procedureVersion, { caseId: kase.id }, kase.versionTimestamp) as Promise<
      ProcedureVersion[]
    >,
    atTimeMany(prisma.diagnosisVersion, { caseId: kase.id }, kase.versionTimestamp) as Promise<
      DiagnosisVersion[]
    >,
    atTimeMany(
      prisma.equipmentRequirementVersion,
      { caseId: kase.id },
      kase.versionTimestamp
    ) as Promise<EquipmentRequirementVersion[]>,
    atTimeMany(
      prisma.paymentInsuranceVersion,
      { caseId: kase.id },
      kase.versionTimestamp
    ) as Promise<PaymentInsuranceVersion[]>,
    atTimeMany(prisma.paymentSelfPayVersion, { caseId: kase.id }, kase.versionTimestamp) as Promise<
      PaymentSelfPayVersion[]
    >,
    atTimeMany(
      prisma.paymentWorkersCompVersion,
      { caseId: kase.id },
      kase.versionTimestamp
    ) as Promise<PaymentWorkersCompVersion[]>,
  ]);
  if (rest.length > 0) throw new Error("Unexpected number of results");
  return {
    ...kase,
    surgeon,
    room,
    requestedBy,
    procedures,
    diagnosis,
    equipmentRequirements,
    paymentInsurance,
    paymentSelfPay,
    paymentWorkersComp,
  };
};

export async function fullCaseVersionRecord(
  revision: number | undefined,
  id: string,
  prisma: PrismaClient
) {
  if (revision === null) return null;
  // permission check
  await prisma.case.findFirstOrThrow({
    where: { id },
  });
  const kase = await prisma.caseVersion.findFirstOrThrow({
    where: {
      id,
      versionId: revision,
    },
  });
  return await attachCaseRelations(kase, prisma);
}

async function revisions(id: string, prisma: PrismaClient) {
  await prisma.case.findFirstOrThrow({
    // permission check
    where: { id },
  });
  return await Promise.all(
    (
      await prisma.caseVersion.findMany({
        where: {
          id,
        },
        orderBy: {
          versionTimestamp: "desc",
        },
      })
    ).map((kase) => attachCaseRelations(kase, prisma))
  );
}

export type CaseEntire = Awaited<ReturnType<typeof getEntireCase>>;
export async function getEntireCase(prisma: PrismaClient, id: string) {
  const caseRecord = await prisma.case.findFirstOrThrow({
    where: { id },
    include: {
      ...COMMON_INCLUDES.case,
      ...COMMON_INCLUDES.procedures,
      ...COMMON_INCLUDES.payments,
      ...COMMON_INCLUDES.diagnoses,
      ...COMMON_INCLUDES.equipment,
      ...COMMON_INCLUDES.provider,
    },
  });
  return caseRecord;
}

export function entriesForDiff(diff: object, record: CaseVersion): AuditEntry[] {
  const newDiffs: AuditEntry[] = [];
  if ("diagnosis" in diff) {
    newDiffs.push(diffEntry(record, "Diagnosis", "diagnosis", diff.diagnosis));
    delete diff.diagnosis;
  }
  if ("procedures" in diff) {
    newDiffs.push(diffEntry(record, "Procedures", "procedures", diff.procedures));
    delete diff.procedures;
  }
  if (
    "surgeon" in diff &&
    typeof diff.surgeon === "object" &&
    diff.surgeon &&
    "name" in diff.surgeon
  ) {
    diff.surgeon = diff.surgeon?.name;
    if ("surgeonId" in diff) delete diff.surgeonId;
  }
  if (
    "anesthesiologist" in diff &&
    typeof diff.anesthesiologist === "object" &&
    diff.anesthesiologist &&
    "name" in diff.anesthesiologist
  ) {
    diff.anesthesiologist = diff.anesthesiologist?.name;
    if ("anesthesiologistId" in diff) delete diff.anesthesiologistId;
  }
  if ("paymentInsurance" in diff) {
    newDiffs.push(diffEntry(record, "Insurance", "paymentInsurance", diff.paymentInsurance));
    delete diff.paymentInsurance;
  }
  if ("paymentSelfPay" in diff) {
    newDiffs.push(diffEntry(record, "Self Pay", "paymentSelfPay", diff.paymentSelfPay));
    delete diff.paymentSelfPay;
  }
  if ("paymentWorkersComp" in diff) {
    newDiffs.push(diffEntry(record, "Workers Comp", "paymentWorkersComp", diff.paymentWorkersComp));
    delete diff.paymentWorkersComp;
  }

  if (Object.keys(diff).length > 0) {
    newDiffs.unshift(diffEntry(record, "Case", null, diff));
  }
  return newDiffs;
}

export async function caseJournal(
  id: string,
  prisma: PrismaClient,
  ability: AppAbility,
  filter: Record<string, string[]>,
  page: number,
  perPage: number
) {
  const records = await revisions(id, prisma);

  const data = records.reduce((diffs, record) => {
    const earlier = getEarlierRev(record.versionId, records) ?? {};
    const diff = diffBetween(earlier, record);
    return diffs.concat(entriesForDiff(diff, record));
  }, [] as AuditEntry[]);
  const entityTypes = data
    .map((entry) => entry.entityName)
    .filter((value, index, self) => self.indexOf(value) === index);
  const window = data
    .filter((entry) => {
      if (filter.type?.length && !filter.type.includes(entry.entityName)) return false;
      if (filter.user?.length) {
        if (entry.versionUserId === null || entry.versionUserId === undefined) return false;
        if (!filter.user.includes(entry.versionUserId)) return false;
      }
      return true;
    })
    .slice((page - 1) * perPage, page * perPage);
  return {
    data: window,
    recordCount: data.length,
    entityTypes,
  };
}

type CaseConflict = {
  surgeryDate: Date;
  expectedCaseLength: number;
  roomTurnOverTime: number;
  status: CaseStatus;
};
/**
 * Convert minutes to milliseconds.
 */
const minutesToMilliseconds = (minutes: number): number => minutes * 60 * 1000;

export const caseEndTime = (kase: CaseConflict): number => {
  return kase.surgeryDate.getTime() + minutesToMilliseconds(kase.expectedCaseLength);
};

/**
 * Check if two cases have a scheduling conflict.
 */
export const hasConflict = (
  case1: CaseConflict,
  case2: CaseConflict,
  compareSurgeon: boolean // TODO: whaaaat
): boolean => {
  if (!isSameDay(case1.surgeryDate, case2.surgeryDate)) return false;
  if (case1.status === CaseStatus.Canceled || case2.status === CaseStatus.Canceled) return false;

  const case1EndTime = caseEndTime(case1),
    case2EndTime = caseEndTime(case2);

  if (compareSurgeon) {
    return case1EndTime > case2.surgeryDate.getTime() && case1EndTime < case2EndTime;
  }

  const case1RoomEndTime = case1EndTime + minutesToMilliseconds(case1.roomTurnOverTime),
    case2RoomEndTime = case2EndTime + minutesToMilliseconds(case2.roomTurnOverTime);

  return case1RoomEndTime > case2.surgeryDate.getTime() && case1RoomEndTime < case2RoomEndTime;
};

export const CaseListFilterSchema = z
  .object({
    date: z.object({ start: z.coerce.date(), end: z.coerce.date() }),
    room: z.string().array(),
    tag: z.string().array(),
    status: CaseStatusSchema.array(),
    surgeon: z.string().array(),
    anesthesiologist: z.string().array(),
    practice: z.string().array(),
    cancellationReason: z.string().array(),
    patientId: z.string(),
    facilityId: z.string(),
    bulkCreateId: z.string(),
  })
  .partial();
export type CaseListFilter = z.infer<typeof CaseListFilterSchema>;

const caseListWhere = (filter: CaseListFilter) => {
  const where: Prisma.CaseWhereInput = {};

  if (filter.date && !filter.patientId) {
    where.surgeryDate = {
      gte: filter.date.start,
      lte: filter.date.end,
    };
  }
  if (filter.room?.length) {
    where.roomId = {
      in: filter.room,
    };
  } else {
    // if the room wasn't provider, filter by facility
    // facility might be undefined as it was only added to filter
    // 2024-01-23. But room.facilityId = undefined results in noop
    // so worst case things work the way they always have
    where.room = {
      facilityId: filter.facilityId,
    };
  }

  if (filter.tag?.length) {
    where.tags = {
      some: {
        OR: filter.tag.map((tag) => ({
          id: tag,
        })),
      },
    };
  }

  if (filter.status?.length) {
    where.status = {
      in: filter.status,
    };
  }

  if (filter.surgeon?.length) {
    where.surgeonId = {
      in: filter.surgeon,
    };
  }

  if (filter.anesthesiologist?.length) {
    where.caseRoles = {
      some: {
        staffId: { in: filter.anesthesiologist },
      },
    };
  }

  if (filter?.practice?.length) {
    where.practiceId = {
      in: filter.practice,
    };
  }

  if (filter?.cancellationReason?.length) {
    where.cancellationReason = {
      in: filter.cancellationReason,
    };
  }

  if (filter?.patientId) {
    where.patientId = filter.patientId;
  }

  if (filter?.bulkCreateId) {
    where.bulkCreates = {
      some: {
        id: filter.bulkCreateId,
      },
    };
  }

  return where;
};
export async function caseList(
  prisma: PrismaClient,
  ability: AppAbility,
  filter: CaseListFilter,
  page: number,
  perPage: number,
  { sort }: { sort?: Prisma.CaseOrderByWithRelationInput[] } = {}
) {
  const where: Prisma.CaseWhereInput = caseListWhere(filter);

  const orderBy: Prisma.CaseOrderByWithRelationInput[] = sort ?? [];
  if (!sort) {
    // single day does room then time
    // multi day does date then room
    // prisma can't sort on functions so can't seperate date & time today
    if (
      filter.date &&
      utcDateTime(filter.date.end).diff(utcDateTime(filter.date.start), "hours").hours < 24
    ) {
      orderBy.push({ room: { name: "asc" } });
      orderBy.push({ surgeryDate: "asc" });
    } else {
      orderBy.push({ surgeryDate: "asc" });
      orderBy.push({ room: { name: "asc" } });
    }
    orderBy.push({ name: "asc" });
    orderBy.push({ createdAt: "asc" });
  }

  const [cases, count] = await Promise.all([
    prisma.case.findMany({
      where,
      take: perPage,
      skip: (page - 1) * perPage,
      include: {
        room: true,
        tags: true,
        surgeon: true,
        practice: true,
        facility: true,
        patient: true,
        ...COMMON_INCLUDES.procedures,
        ...COMMON_INCLUDES.payments,
        caseRoles: true,
        encounters: true,
      },
      orderBy,
    }),
    prisma.case.aggregate({
      where,
      _count: {
        id: true,
      },
    }),
  ]);

  return { count, cases };
}

export async function caseListCount(
  prisma: PrismaClient,
  ability: AppAbility,
  filter: CaseListFilter
) {
  filter;
  const where: Prisma.CaseWhereInput = {};

  if (filter.status?.length) {
    where.status = {
      in: filter.status,
    };
  }

  const count = await prisma.case.aggregate({
    _count: {
      id: true,
    },
    where,
  });

  return count;
}

export function expectedEndOfCaseTime(kase: { surgeryDate: Date; expectedCaseLength: number }) {
  return new Date(kase.surgeryDate.getTime() + minutesToMilliseconds(kase.expectedCaseLength));
}

export type CaseSummary = Awaited<ReturnType<typeof caseList>>["cases"][number];

export function casesOnDay({
  day,
  facilityId,
  include,
  prisma,
}: {
  day: DateTime;
  facilityId: string;
  include: Prisma.CaseInclude;
  prisma: PrismaClient;
}) {
  const start = day.startOf("day");
  const end = day.endOf("day");
  return prisma.case.findMany({
    where: {
      surgeryDate: {
        gte: start.toJSDate(),
        lte: end.toJSDate(),
      },
      facilityId,
    },
    include,
  });
}

export async function futureOutlook(
  startDate: DateTime,
  endDate: DateTime,
  prisma: PrismaClient,
  facilityId: string
) {
  const start = startDate.startOf("day").toJSDate();
  const end = endDate.startOf("day").toJSDate();
  type Stat = { count: bigint; date: Date }[];

  const [ready, pending, requested, denied, cancelled] = await Promise.all([
    prisma.$queryRaw<Stat>`SELECT count(*) as count, DATE("surgeryDate") as date
    FROM "Case"
    WHERE "surgeryDate" >= ${start} AND "surgeryDate" <= ${end} AND "facilityId"=${facilityId} AND "acknowledgedAt" = "updatedAt"
    GROUP BY DATE("surgeryDate")
    ORDER BY DATE("surgeryDate") ASC`,
    prisma.$queryRaw<Stat>`SELECT count(*) as count, DATE("surgeryDate") as date
    FROM "Case"
    WHERE "surgeryDate" >= ${start} AND "surgeryDate" <= ${end} AND "facilityId"=${facilityId} AND (("acknowledgedAt" < "updatedAt" or "acknowledgedAt" is null) AND "status" = 'Accepted')
    GROUP BY DATE("surgeryDate")
    ORDER BY DATE("surgeryDate") ASC`,
    prisma.$queryRaw<Stat>`SELECT count(*) as count, DATE("surgeryDate") as date
    FROM "Case"
    WHERE "surgeryDate" >= ${start} AND "surgeryDate" <= ${end} AND "facilityId"=${facilityId} AND "status" = 'Requested'
    GROUP BY DATE("surgeryDate")
    ORDER BY DATE("surgeryDate") ASC`,
    prisma.$queryRaw<Stat>`SELECT count(*) as count, DATE("surgeryDate") as date
    FROM "Case"
    WHERE "surgeryDate" >= ${start} AND "surgeryDate" <= ${end} AND "facilityId"=${facilityId} AND "status" = 'Denied'
    GROUP BY DATE("surgeryDate")
    ORDER BY DATE("surgeryDate") ASC`,
    prisma.$queryRaw<Stat>`SELECT count(*) as count, DATE("surgeryDate") as date
    FROM "Case"
    WHERE "surgeryDate" >= ${start} AND "surgeryDate" <= ${end} AND "facilityId"=${facilityId} AND "status" = 'Canceled'
    GROUP BY DATE("surgeryDate")
    ORDER BY DATE("surgeryDate") ASC`,
  ]);
  const result: CaseStatusOutlook[] = [];
  function parseResult(status: string, data: Stat, statuses: CaseStatus[]): void {
    const row: CaseStatusOutlook = {
      status,
      today: 0,
      tomorrow: 0,
      next7Days: 0,
      next14Days: 0,
      next14DaysFilter: {},
      next7DaysFilter: {},
      tomorrowFilter: {},
      todayFilter: {},
    };
    for (const item of data) {
      const date = utcDateTime(item.date);
      const diff = date.diffNow().as("days");
      const count = Number(item.count);
      if (diff < 0) {
        row.today += count;
        row.todayFilter = {
          status: statuses.map((status) => String(status)),
          startDate: String(date.toISO()),
          endDate: String(date.endOf("day").toISO()),
        };
      } else if (diff < 1) {
        row.tomorrow += count;
        row.tomorrowFilter = {
          status: statuses.map((status) => String(status)),
          startDate: String(date.plus({ days: 1 }).toISO()),
          endDate: String(date.plus({ days: 1 }).endOf("day").toISO()),
        };
      } else if (diff < 7) {
        row.next7Days += count;
        row.next7DaysFilter = {
          status: statuses.map((status) => String(status)),
          startDate: String(date.plus({ days: 1 }).endOf("day").toISO()),
          endDate: String(date.plus({ days: 7 }).endOf("day").toISO()),
        };
      } else if (diff < 14) {
        row.next14Days += count;
        row.next14DaysFilter = {
          status: statuses.map((status) => String(status)),
          startDate: String(date.plus({ days: 7 }).endOf("day").toISO()),
          endDate: String(date.plus({ days: 14 }).endOf("day").toISO()),
        };
      }
    }
    result.push(row);
  }
  parseResult("Ready", ready, ["Accepted"]); // TODO: we don't have a way to express this through API
  parseResult("Pending", pending, ["Accepted"]);
  parseResult("Requested", requested, ["Requested"]);
  parseResult("Denied", denied, ["Denied"]);
  parseResult("Canceled", cancelled, ["Canceled"]);
  return result;
}

type StatusTuple = [string, CaseStatus[]];
type CasesBySurgeon = {
  surgeonName: string;
  surgeonId: string;
  statuses: Record<string, number>;
};
export const casesBySurgeon = async (
  startRaw: DateTime,
  endRaw: DateTime,
  prisma: PrismaClient,
  statuses: StatusTuple[],
  facilityId: string
) => {
  const start = startRaw.startOf("day").toJSDate();
  const end = endRaw.startOf("day").toJSDate();
  type Stat = { surgeon: string; surgeonId: string; count: bigint; status: CaseStatus };
  // create a map of each caseStatus to it's output from the statuses array
  const statusesMap = statuses.reduce((map, [status, dbStatuses]) => {
    for (const dbStatus of dbStatuses) {
      map.set(dbStatus, status);
    }
    return map;
  }, new Map<CaseStatus, string>());

  // todo: the line `where "Case".status in ('Canceled', 'Completed', 'Discharged')` below
  // should be replaced with a dynamic where clause based on the statuses array
  // in fact it should be possible to do "Case".status IN {Prisma.join(statuses)} where statuses is an array of dbStatuses above
  // if we need additional statuses in the near term, just remove the where clause and we'll still filter out the statuses we don't want
  // just less efficently.
  // in the longer term, why doesn't Prisma.join() work?
  const queryResults = prisma.$queryRaw<Stat[]>`
    select surgeon."firstName" || ' ' || surgeon."lastName" as surgeon, "surgeonId", count(*) as count, status
    from "Case"
    inner join "Staff" as "surgeon" on "Case"."surgeonId" = surgeon.id
    where "Case".status in ('Canceled', 'Partially_Completed', 'Completed', 'Discharged')
    and "Case"."surgeryDate" >= ${start}
    and "Case"."surgeryDate" < ${end}
    and "Case"."facilityId" = ${facilityId}
    group by surgeon."firstName", surgeon."lastName", "surgeonId", status
    order by surgeon."lastName" ASC, surgeon."firstName", status ASC
  `;
  return (await queryResults).reduce((results, { surgeon, surgeonId, count, status }: Stat) => {
    const result = results.find((result) => result.surgeonId === surgeonId);
    const groupedStatus = statusesMap.get(status);
    if (!groupedStatus) return results;
    if (result) {
      result.statuses[groupedStatus] = Number(count);
    } else {
      results.push({
        surgeonName: surgeon,
        surgeonId,
        statuses: {
          [groupedStatus]: Number(count),
        },
      });
    }
    return results;
  }, [] as CasesBySurgeon[]);
};

export type StatusTrackerRow = {
  day: Date;
  preAuthActionNeeded: number;
  equipmentActionNeeded: number;
  clearanceActionNeeded: number;
  testingActionNeeded: number;
  patActionNeeded: number;
  healthReviewActionNeeded: number;
  insuranceDenominator: number;
  casesDenominator: number;
};
export async function statusTrackerRollUp(
  prisma: PrismaClient,
  ability: AppAbility,
  facilityId: string,
  startDay: Date,
  endDay: Date
): Promise<StatusTrackerRow[]> {
  // select all cases between days
  // select insurance verification status for each case. If its Pre-Auth status is anything but approved, increment by 1
  // select equipment status for each case. if its order_status!=in_inventory, increment by 1. If it's rep_status != confirmed, increment by 1
  // select overview for each case. If its clearance != ready increment by 1. If it's testing != passed, increment by 1. Pat.
  // select health review for each case. If it's review_status != ready increment by 1.
  // models
  // EquipmentRequirement
  // CaseStatusTracker
  // PaymentInsurance
  const result: StatusTrackerRow[] = [];

  const facilityTime = await facilityDateTimeFactory(prisma, ability, facilityId);
  const [start, end] = [facilityTime(startDay), facilityTime(endDay)];
  const cases = await prisma.case.findMany({
    where: {
      AND: [
        { facilityId },
        { surgeryDate: { gte: start.toJSDate() } },
        { surgeryDate: { lt: end.toJSDate() } },
      ],
    },
    include: {
      paymentInsurance: true,
      equipmentRequirements: true,
      caseStatusTracker: true,
      healthReviewRequirements: true,
    },
  });
  type ReportingCase = (typeof cases)[number];
  const preAuthActionsNeeded = (cases: ReportingCase[]) => {
    return cases.filter((c) =>
      c.paymentInsurance.some((pi) => pi.preAuthStatus !== PreAuthStatus.APPROVED)
    ).length;
  };
  const equipmentActionsNeeded = (cases: ReportingCase[]) => {
    // ok we need another order status "rep bringing".  if order status == "in inventory" or order status == "rep bringing" and rep status == "not required" or "confirmed" then we would increment the overview status by 1
    return cases.filter((c) =>
      c.equipmentRequirements.some((er) => {
        const repBringing =
          er.representativeStatus === EquipmentRequirementRepresentativeStatus.CONFIRMED &&
          er.status === EquipmentRequirementStatus.REP_BRINGING;
        const repNotComingButHaveIt =
          EquipmentRequirementRepresentativeStatus.NOT_REQUIRED &&
          er.status === EquipmentRequirementStatus.IN_INVENTORY;
        return !(repBringing || repNotComingButHaveIt);
      })
    ).length;
  };
  const clearanceActionsNeeded = (cases: ReportingCase[]) => {
    return cases.filter((c) => c.caseStatusTracker.some((cst) => !cst.overviewClearanceFlag))
      .length;
  };
  const testingActionsNeeded = (cases: ReportingCase[]) => {
    return cases.filter((c) => c.caseStatusTracker.some((cst) => !cst.overviewTestingFlag)).length;
  };
  const patActionsNeeded = (cases: ReportingCase[]) => {
    return cases.filter((c) => c.caseStatusTracker.some((cst) => !cst.overviewPatFlag)).length;
  };
  const healthReviewActionsNeeded = (cases: ReportingCase[]) => {
    return cases.filter((c) =>
      c.caseStatusTracker.some((cst) => cst.healthReviewStatus !== HealthReviewStatus.COMPLETE)
    ).length;
  };
  for (let d = start; d <= end; d = d.plus({ days: 1 })) {
    const casesOnDay = cases.filter((c) => c.surgeryDate && isSameDay(c.surgeryDate, d.toJSDate()));
    const statusTrackerForDay = {
      day: d.toJSDate(),
      preAuthActionNeeded: preAuthActionsNeeded(casesOnDay),
      equipmentActionNeeded: equipmentActionsNeeded(casesOnDay),
      clearanceActionNeeded: clearanceActionsNeeded(casesOnDay),
      testingActionNeeded: testingActionsNeeded(casesOnDay),
      patActionNeeded: patActionsNeeded(casesOnDay),
      healthReviewActionNeeded: healthReviewActionsNeeded(casesOnDay),
      insuranceDenominator: casesOnDay.filter((c) => c.paymentInsurance.length > 0).length,
      casesDenominator: casesOnDay.length,
    };
    result.push(statusTrackerForDay);
  }
  return result;
}

export const getOrWorkflowForCase = async (
  prisma: PrismaClient,
  caseId: string,
  ability: AppAbility
) => {
  const [caseDetails, caseWithPreferenceCards] = await Promise.all([
    getCaseDetailsRecord(prisma, caseId, ability),
    getCasePreferenceCardsRecord(prisma, caseId, ability),
  ]);
  return {
    ...caseDetails,
    preferenceCards: caseWithPreferenceCards.casePreferenceCards,
  };
};

export function patientName(kase: {
  patientFirstName: string;
  patientMiddleName: string;
  patientLastName: string;
  patientNameSuffix: string;
}) {
  const name = [kase.patientFirstName, kase.patientMiddleName, kase.patientLastName]
    .filter((n) => n && n !== "null")
    .join(" ");
  if (kase.patientNameSuffix && kase.patientNameSuffix !== "null")
    return `${name}, ${kase.patientNameSuffix}`;
  return name;
}
export function patientAge(kase: { patientDateOfBirth: Date }) {
  if (!kase.patientDateOfBirth) return null;
  return Math.floor(birthDateTime(kase.patientDateOfBirth).diffNow("years").years * -1);
}

export async function facilityIsOpen(
  prisma: PrismaClient,
  kase: {
    surgeryDate: Date;
    roomId: string;
    expectedCaseLength: number;
  }
) {
  // find time periods for room with a startDate <= surgeryDate and endDate >= surgeryDate
  // if there are any and they belong to the same practice, return true
  // if there are any and they don't belong to the same practice, return false
  // if there are none, check the facilityHours for the room's facility and confirm the surgeryDate is within the hours and the surgeryDate + expectedCaseLengthInMinutes is <= the end of the hours
  if (!kase.roomId || !kase.surgeryDate || !kase.expectedCaseLength) return false;
  const room = await prisma.room.findFirstOrThrow({
    where: {
      id: kase.roomId,
    },
    include: {
      facility: true,
      timePeriods: {
        where: {
          type: TimePeriodType.OPEN,
          startTime: {
            lte: kase.surgeryDate,
          },
          endTime: {
            gte: kase.surgeryDate,
          },
        },
      },
    },
  });
  const facilityDateTime = (date: Date) =>
    DateTime.fromJSDate(date, { zone: room.facility.timezone });
  const luxonSurgeryDate = facilityDateTime(kase.surgeryDate);
  const luxonSurgeryEndTime = luxonSurgeryDate.plus({ minutes: kase.expectedCaseLength });
  const days = Object.values(DaysOfWeek);
  const facilityHours = await prisma.facilityHours.findMany({
    where: {
      facilityId: room.facilityId,
      day: days[luxonSurgeryDate.weekday - 1], // luxon returns 1 for monday 7 for sunday, subtract 1 to match to daysofweek enum array
    },
  });
  const openBySchedule = facilityHours.some((fh) => {
    // fh.openTime is a time string in 24 hour format
    // fh.closeTime is a time string in 24 hour format
    const openTime = luxonSurgeryDate.set(timeStringToDateUnits(fh.openTime ?? "")),
      closeTime = luxonSurgeryDate.set(timeStringToDateUnits(fh.closeTime ?? ""));
    return luxonSurgeryDate >= openTime && luxonSurgeryEndTime <= closeTime;
  });
  if (openBySchedule) return true;
  return room.timePeriods.length > 0; // there must be a { type: OPEN } time period for the room on the surgeryDate
}

export async function caseIsClearOfOthersBlockTime(
  prisma: PrismaClient,
  kase: {
    surgeryDate: Date;
    expectedCaseLength: number;
    roomId: string;
    practiceId: string;
  }
) {
  if (!kase.surgeryDate || !kase.expectedCaseLength || !kase.roomId || !kase.practiceId)
    return false;
  // find time periods for room with a startDate <= surgeryDate and endDate >= surgeryDate
  // if there are any and they belong to the same practice, return true
  // if there are any and they don't belong to the same practice, return false
  // if there are none, check the facilityHours for the room's facility and confirm the surgeryDate is within the hours and the surgeryDate + expectedCaseLengthInMinutes is <= the end of the hours
  const room = await prisma.room.findFirstOrThrow({
    where: {
      id: kase.roomId,
    },
    include: {
      facility: true,
    },
  });
  const facilityDateTime = (date: Date) =>
    DateTime.fromJSDate(date, { zone: room.facility.timezone });

  const luxonSurgeryDate = facilityDateTime(kase.surgeryDate);
  const luxonSurgeryEndTime = luxonSurgeryDate.plus({ minutes: kase.expectedCaseLength });
  const jsSurgeryDate = luxonSurgeryDate.toJSDate(),
    jsSurgeryEndTime = luxonSurgeryEndTime.toJSDate();

  const timePeriods = await prisma.timePeriod.findMany({
    where: {
      OR: [{ roomId: kase.roomId }, { flipRoomId: kase.roomId }],
      type: TimePeriodType.BLOCKTIME,
      startTime: {
        lte: jsSurgeryDate,
      },
      endTime: {
        gte: jsSurgeryEndTime,
      },
    },
  });
  if (timePeriods.length === 0) {
    return true;
  } else {
    return timePeriods.some((tp) => tp.practiceId === kase.practiceId);
  }
}

/**
 * this verifies that the case is either inside of blocktime owned by the same practice or a time period designated as open time
 * @param prisma prisma instance
 * @param kase relevant case
 * @returns
 */
export async function caseIsInAvailableBlockTime(
  prisma: PrismaClient,
  kase: {
    surgeryDate: Date;
    roomId: string;
    expectedCaseLength: number;
    practiceId: string;
  }
) {
  // find time periods for room with a startDate <= surgeryDate and endDate >= surgeryDate
  // if there are any and they belong to the same practice, return true
  // if there are any and they don't belong to the same practice, return false
  // if there are none, check the facilityHours for the room's facility and confirm the surgeryDate is within the hours and the surgeryDate + expectedCaseLengthInMinutes is <= the end of the hours
  if (!kase.surgeryDate || !kase.roomId || !kase.expectedCaseLength) return false;
  const room = await prisma.room.findFirstOrThrow({
    where: {
      id: kase.roomId,
    },
    include: {
      facility: true,
    },
  });
  const facilityDateTime = (date: Date) =>
    DateTime.fromJSDate(date, { zone: room.facility.timezone });

  const luxonSurgeryDate = facilityDateTime(kase.surgeryDate);
  const luxonSurgeryEndTime = luxonSurgeryDate.plus({ minutes: kase.expectedCaseLength });
  const jsSurgeryDate = luxonSurgeryDate.toJSDate(),
    jsSurgeryEndTime = luxonSurgeryEndTime.toJSDate();

  const timePeriods = await prisma.timePeriod.findMany({
    where: {
      AND: [
        {
          OR: [{ roomId: kase.roomId }, { flipRoomId: kase.roomId }],
        },
        {
          OR: [
            {
              practiceId: kase.practiceId,
            },
            {
              practiceId: null,
              type: TimePeriodType.OPEN,
            },
          ],
        },
      ],
      startTime: {
        lte: jsSurgeryDate,
      },
      endTime: {
        gte: jsSurgeryEndTime,
      },
    },
  });
  return timePeriods.length > 0;
}

export async function facilityAllowSchedulingInOpenTime(
  prisma: PrismaClient,
  kase: {
    roomId: string;
  }
) {
  if (!kase.roomId) return false;
  const room = await prisma.room.findFirstOrThrow({
    where: {
      id: kase.roomId,
    },
    include: {
      facility: {
        select: {
          allowSchedulingInOpenTime: true,
        },
      },
    },
  });
  return !!room.facility.allowSchedulingInOpenTime;
}

export function caseIsRelativelyInTheFuture(kase: { surgeryDate: Date; status: CaseStatus }) {
  const statusesThatCannotBeInThePast: CaseStatus[] = [CaseStatus.Draft, CaseStatus.Requested];
  const isInFuture = DateTime.fromJSDate(kase.surgeryDate).diffNow().as("minutes") > 0; // localtime is fine here
  return isInFuture || !statusesThatCannotBeInThePast.includes(kase.status);
}

export function isDayOfStatus(status: CaseStatus) {
  // I don't believe "discharged" is a day of status as you might linger in discharged for weeks before payment arrives and case becomes complete
  return (
    [
      CaseStatus.Arrived,
      CaseStatus.Late,
      CaseStatus.Checked_In,
      CaseStatus.In_PreOp,
      CaseStatus.Ready_for_OR,
      CaseStatus.In_OR,
      CaseStatus.Recovery,
    ] as CaseStatus[]
  ).includes(status);
}

export async function caseIsNotOnAHoliday(
  prisma: PrismaClient,
  kase: {
    surgeryDate: Date;
    roomId: string;
  }
) {
  const room = await prisma.room.findFirstOrThrow({
    where: {
      id: kase.roomId,
    },
    include: {
      facility: true,
    },
  });
  const holidays = await prisma.timePeriod.count({
    where: {
      startTime: {
        lte: kase.surgeryDate,
      },
      endTime: {
        gte: kase.surgeryDate,
      },
      type: TimePeriodType.HOLIDAY,
      room: {
        facilityId: room.facilityId,
      },
    },
  });
  return holidays === 0;
}

export async function validate(
  prisma: PrismaClient,
  ability: AppAbility,
  {
    caseId,
    perspective,
    incremental,
  }: { caseId: string; perspective: "asc" | "practice"; incremental: boolean }
) {
  const kase = await getEntireCase(prisma, caseId);
  const validator: ZodSchema = incremental
    ? validatorForAdvancingCase(kase, perspective)
    : validatorForCase(kase, perspective);

  const result = await validator.safeParseAsync(kase);
  return result.success
    ? {
        success: true,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: result.data,
        error: undefined,
      }
    : {
        success: false,
        error: {
          ...result.error,
        },
      };
}
