import { z } from 'zod';
import { AnesthesiaTypeSchema } from '../inputTypeSchemas/AnesthesiaTypeSchema'

/////////////////////////////////////////
// SURGERY PROFILE SCHEMA
/////////////////////////////////////////

export const SurgeryProfileSchema = z.object({
  anesthesiaType: AnesthesiaTypeSchema.nullish(),
  id: z.string(),
  facilityId: z.string(),
  surgeryDescription: z.string(),
  surgeonId: z.string().nullish(),
  expectedCaseLength: z.number().int().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  emrTemplateId: z.string().nullish(),
})

export type SurgeryProfile = z.infer<typeof SurgeryProfileSchema>

export default SurgeryProfileSchema;
