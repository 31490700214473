import { StaffSchema, StaffSignatureSchema } from "@procision-software/database-zod";
import { z } from "zod";

export type StaffSignatureRecord = z.infer<typeof StaffSignatureRecordSchema>;
export const StaffSignatureRecordSchema = StaffSignatureSchema.extend({
  staff: StaffSchema,
});

export type StaffSignatureFindFilter = z.infer<typeof StaffSignatureFindFilterSchema>;
export const StaffSignatureFindFilterSchema = z.union([
  z.object({ id: z.string(), staffId: z.undefined() }),
  z.object({ id: z.undefined(), staffId: z.string() }),
  z.object({ id: z.undefined(), staffId: z.undefined() }),
]);

export type StaffSignatureFormData = z.infer<typeof StaffSignatureFormDataSchema>;
export const StaffSignatureFormDataSchema = StaffSignatureRecordSchema.pick({
  staffId: true,
  blob: true,
  pin: true,
})
  .extend({ pinConfirmation: z.string().length(6) })
  .refine(({ pin, pinConfirmation }) => pin === pinConfirmation, {
    message: "Pins don't match",
    path: ["pinConfirmation"],
  });

export type StaffSignatureUpdateFormData = z.infer<typeof StaffSignatureUpdateFormDataSchema>;
export const StaffSignatureUpdateFormDataSchema = StaffSignatureRecordSchema.pick({
  id: true,
  staffId: true,
  blob: true,
  pin: true,
})
  .extend({ pinConfirmation: z.string().length(6) })
  .partial()
  .required({ id: true })
  .refine(({ pin, pinConfirmation }) => pin === pinConfirmation, {
    message: "Pins don't match",
    path: ["pinConfirmation"],
  });

export type StaffSignatureAuthFormData = z.infer<typeof StaffSignatureAuthFormDataSchema>;
export const StaffSignatureAuthFormDataSchema = StaffSignatureRecordSchema.pick({
  id: true,
  pin: true,
}).partial({ pin: true });
