import {
  CodeableConceptSchema,
  DocumentTemplateSchema,
  OrganizationSchema,
} from "@procision-software/database-zod";
import type { z } from "zod";

export const DocumentTemplateRecordSchema = DocumentTemplateSchema.extend({
  organization: OrganizationSchema.nullable(),
  type: CodeableConceptSchema,
});

export type DocumentTemplateRecord = z.infer<typeof DocumentTemplateRecordSchema>;

export const DocumentTemplateFormDataSchema = DocumentTemplateSchema.pick({
  organizationId: true,
  typeId: true,
  title: true,
  body: true,
  jobRoleTypes: true,
});

export type DocumentTemplateFormData = z.infer<typeof DocumentTemplateFormDataSchema>;

export type DocumentTemplateEditableField =
  | "organizationId"
  | "title"
  | "typeId"
  | "body"
  | "jobRoleTypes";
