import { z } from 'zod';
import { DiagnosisReporterSchema } from '../inputTypeSchemas/DiagnosisReporterSchema'
import { ConditionStateSchema } from '../inputTypeSchemas/ConditionStateSchema'

/////////////////////////////////////////
// DIAGNOSIS SCHEMA
/////////////////////////////////////////

export const DiagnosisSchema = z.object({
  reporter: DiagnosisReporterSchema,
  status: ConditionStateSchema,
  id: z.string(),
  conditionId: z.string(),
  caseId: z.string(),
  icd10: z.string(),
  effective: z.coerce.date(),
  invalidated: z.coerce.date().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Diagnosis = z.infer<typeof DiagnosisSchema>

export default DiagnosisSchema;
