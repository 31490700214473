export function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer
      className="flex h-16 w-full items-center justify-center bg-secondary text-secondary
        print:hidden"
    >
      <div className="flex h-16 w-full flex-col items-center justify-center">
        <p className="text-sm">© {year} Procision Software. All rights reserved.</p>
        <p className="text-sm">
          CPT® copyright {year} American Medical Association. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
