import type { PatientSignatureFormData, PatientSignatureRecord } from "@procision-software/mason";
import { useCallback } from "react";
import { PatientSignatureCreateForm } from "./PatientSignatureCreateForm";
import { usePatientSignatureService } from "./usePatientSignature";

type PatientSignatureFormProps = {
  onCancel: () => unknown;
  onSubmit: (data: PatientSignatureRecord) => unknown;
  patientId: string;
};

export function PatientSignatureForm({ onCancel, onSubmit, patientId }: PatientSignatureFormProps) {
  const patientSignatureService = usePatientSignatureService();

  const onCreate = useCallback(
    async (input: PatientSignatureFormData) =>
      await onSubmit(await patientSignatureService.create(input)),
    [onSubmit, patientSignatureService]
  );

  return (
    <PatientSignatureCreateForm
      defaultValues={{ patientId }}
      onCancel={onCancel}
      onSubmit={onCreate}
      patientId={patientId}
    />
  );
}
