import { PermissionDeniedError, type AppAbility } from "@procision-software/auth";
import {
  BillingPayerType,
  BillingTransactionStatus,
  type BillingCharge,
  type Prisma,
  type PrismaClient,
} from "@procision-software/database";
import type { BillingChargeMaster, CurrencyType } from "@procision-software/database-zod";
import type { X12ClaimAdjustmentCodeGroupType } from "@procision-software/database-zod/src/generated/inputTypeSchemas/X12ClaimAdjustmentCodeGroupSchema";
import { billingAdjustmentIdToString, type BillingAdjustmentId } from "./adjustment";
import { getBillingCase, getChargeMasterRecords, toBillingCaseId } from "./case";
import { NoChargeMasterForCodeError } from "./chargemaster";
import { billingClaimIdToString, type BillingClaimId } from "./claim";
import {
  billingModifierIdToString,
  toBillingModifierId,
  type BillingModifierId,
} from "./modifiers";
import { billingTransactionIdToString, type BillingTransactionId } from "./payment";

// Define a unique symbol
declare const BillingChargeIdTag: unique symbol;

// Create a tagged type
export type BillingChargeId = string & { readonly tag: typeof BillingChargeIdTag };

// Function to tag a string
export function toBillingChargeId(id: string): BillingChargeId {
  return id as BillingChargeId;
}

export function billingChargeIdToString(id: BillingChargeId): string {
  return id.toString();
}

export function isSelfPayOnlyCharge(
  charge: Pick<BillingCharge, "payerTypes" | "collectFromOffice">
) {
  return (
    charge.payerTypes.length > 0 &&
    charge.payerTypes.every((t) => t === BillingPayerType.Self_Pay) &&
    !charge.collectFromOffice
  );
}
export function isOfficeOnlyCharge(
  charge: Pick<BillingCharge, "payerTypes" | "collectFromOffice">
) {
  return (
    charge.payerTypes.length > 0 &&
    charge.payerTypes.every((t) => t === BillingPayerType.Self_Pay) &&
    charge.collectFromOffice
  );
}
export function patientBillableCharges<
  TCharge extends Pick<BillingCharge, "payerTypes" | "collectFromOffice">,
>(charges: TCharge[]) {
  return charges.filter(
    (charge) =>
      charge.payerTypes.some((t) => t === BillingPayerType.Self_Pay) && !charge.collectFromOffice
  );
}

export async function reprioritize(
  prisma: PrismaClient,
  ability: AppAbility,
  billingChargeId: BillingChargeId,
  desiredPriority: number
) {
  const id = billingChargeIdToString(billingChargeId);
  const charge = await prisma.billingCharge.findFirstOrThrow({
    where: {
      id,
    },
    include: {
      billingCase: {
        include: {
          billingCharges: {
            orderBy: {
              sequenceNumber: "asc" as Prisma.SortOrder,
            },
          },
        },
      },
    },
  });
  const billingCharges = charge.billingCase.billingCharges;
  if (billingCharges.length === 0) {
    throw new Error(`No charges found for claim ${charge.billingCaseId}`);
  }

  const originalPriority = charge.sequenceNumber;
  const updates: ReturnType<typeof prisma.billingCharge.updateMany>[] = [];

  for (const p of billingCharges) {
    let newPriority: number | null = null;
    if (p.id === id) {
      newPriority = desiredPriority;
    } else if (
      p.sequenceNumber >= Math.min(originalPriority, desiredPriority) &&
      p.sequenceNumber <= Math.max(originalPriority, desiredPriority)
    ) {
      newPriority =
        p.sequenceNumber < originalPriority ? p.sequenceNumber + 1 : p.sequenceNumber - 1;
    }

    if (newPriority !== null) {
      updates.push(
        prisma.billingCharge.updateMany({
          where: { id: p.id },
          data: { sequenceNumber: newPriority },
        })
      );
    }
  }

  await prisma.$transaction(updates);

  return true;
}

export async function updateCharge(
  prisma: PrismaClient,
  ability: AppAbility,
  billingChargeId: BillingChargeId,
  input: Partial<
    Pick<BillingCharge, "units" | "unitCost" | "billingChargeMasterId" | "revenueCode"> & {
      m1?: string | null;
      m2?: string | null;
      m3?: string | null;
      m4?: string | null;
      supportingDiagnosis?: string[];
    }
  >
) {
  const id = billingChargeIdToString(billingChargeId);
  const { unitCost, billingChargeMasterId, supportingDiagnosis, m1, m2, m3, m4, ...raw } = input;
  if (supportingDiagnosis && supportingDiagnosis.length > 4) {
    throw new Error("too many supporting diagnosis");
  }
  const existing = await prisma.billingCharge.findFirstOrThrow({
    where: {
      id,
    },
    include: {
      billingChargeMaster: true,
      billingChargeModifiers: {
        orderBy: {
          sequenceNumber: "asc" as Prisma.SortOrder,
        },
        include: {
          billingModifier: true,
        },
      },
    },
  });
  const data: Prisma.BillingChargeUpdateInput = raw;
  const allowedToUpdateUnitCost = existing.billingChargeMaster.allowAmountOverride;
  let chargeMaster = existing.billingChargeMaster;
  if (billingChargeMasterId) {
    const [local] = await getChargeMasterRecords(prisma, ability, [{ billingChargeMasterId }]);
    if (!local) {
      throw new Error("Charge master not found");
    }
    chargeMaster = {
      // somehow TS thinks cptCode or hcpcsCode could be undefined. Not only can't they, they can't be edited here.
      ...local,
      cptCode: local.cptCode ?? null,
      hcpcsCode: local.hcpcsCode ?? null,
    };
    data.billingChargeMaster = {
      connect: {
        id: billingChargeMasterId,
      },
    };
  }
  if (unitCost) {
    if (!allowedToUpdateUnitCost) {
      throw new Error("Per chargemaster, this charge's unit price cannot be changed");
    }
    data.unitCost = unitCost;
  }

  // Recalculate billed amount if units or unit cost are updated
  if (!!input.units || !!input.unitCost) {
    data.billedAmount = (input.units ?? existing.units) * (unitCost ?? existing.unitCost);
  }

  const updateModifier = async (
    sequenceNumber: number,
    modifierId: BillingModifierId | null | undefined
  ) => {
    if (modifierId === undefined) return;
    if (modifierId) {
      const billingChargeModifer = await prisma.billingChargeModifier.findFirst({
        where: {
          billingChargeId: id,
          sequenceNumber,
        },
      });
      if (billingChargeModifer) {
        ability.can("manage", "BillingChargeModifier") &&
          (await prisma.billingChargeModifier.update({
            where: {
              id: billingChargeModifer.id,
            },
            data: {
              billingModifierId: billingModifierIdToString(modifierId),
            },
          }));
      } else {
        ability.can("manage", "BillingChargeModifier") &&
          (await prisma.billingChargeModifier.create({
            data: {
              billingChargeId: id,
              billingModifierId: billingModifierIdToString(modifierId),
              sequenceNumber,
            },
          }));
      }
    } else {
      await prisma.billingChargeModifier.deleteMany({
        where: {
          billingChargeId: id,
          sequenceNumber,
        },
      });
    }
  };
  const modifierIdOrNullOrUndefined = (
    m: string | null | undefined
  ): BillingModifierId | null | undefined =>
    m ? toBillingModifierId(m) : m === null ? null : undefined;
  await Promise.all([
    updateModifier(0, modifierIdOrNullOrUndefined(m1)),
    updateModifier(1, modifierIdOrNullOrUndefined(m2)),
    updateModifier(2, modifierIdOrNullOrUndefined(m3)),
    updateModifier(3, modifierIdOrNullOrUndefined(m4)),
  ]);

  if (supportingDiagnosis) {
    data.supportingDiagnoses = {
      set: supportingDiagnosis.map((id) => ({ id })),
    };
  }

  return {
    ...(await prisma.billingCharge.update({
      where: { id },
      data,
    })),
    billingChargeMaster: chargeMaster,
  };
}

/**
 * Creates a new BillingCharge record and returns it along with its associated BillingChargeMaster.
 *
 * @param {PrismaClient} prisma - Prisma client instance.
 * @param {AppAbility} ability - The ability instance for access control, defined via CASL.
 * @param {BillingCharge} input - Data to use for creating the charge.
 *
 * @returns {Promise<BillingCharge & { billingChargeMaster: BillingChargeMaster }>} The created BillingCharge record and its associated BillingChargeMaster.
 *
 * @throws {NoChargeMasterForCodeError} Throws error if the Charge Master record is not found.
 * @throws {PermissionDeniedError} Throws error if the user doesn't have the required permissions.
 *
 * @example
 * ```typescript
 * const newCharge = await createCharge(prisma, ability, { billingCaseId: '1', billingChargeMasterId: '2', units: 3 }, 'amount');
 * ```
 *
 */
export async function createCharge(
  prisma: PrismaClient,
  ability: AppAbility,
  input: Pick<BillingCharge, "billingCaseId" | "billingChargeMasterId" | "units" | "payerTypes">
): Promise<BillingCharge & { billingChargeMaster: BillingChargeMaster }> {
  const { billingCaseId, billingChargeMasterId, ...data } = input;
  const billingCase = await getBillingCase(prisma, ability, toBillingCaseId(billingCaseId));
  const [chargeMaster] = await getChargeMasterRecords(prisma, ability, [{ billingChargeMasterId }]);
  if (!chargeMaster) throw new NoChargeMasterForCodeError("Charge master not found");

  const sequenceNumber =
    1 + Math.max(...billingCase.billingCharges.map((p) => p.sequenceNumber).concat(-1));

  const unitCost = data.payerTypes.every((t) => t === BillingPayerType.Self_Pay)
    ? chargeMaster.selfPayAmount
    : chargeMaster.amount;

  const charge =
    ability.can("create", "BillingCharge") &&
    (await prisma.billingCharge.create({
      data: {
        ...data,
        unitCost,
        billedAmount: data.units * unitCost,
        billingCaseId,
        revenueCode: "0490", // https://linear.app/procision/issue/ENG-240/update-revenue-codes
        billingChargeMasterId,
        sequenceNumber,
      },
    }));

  if (!charge) {
    throw new PermissionDeniedError();
  }
  return {
    ...charge,
    billingChargeMaster: chargeMaster,
  };
}

export async function deleteCharge(
  prisma: PrismaClient,
  ability: AppAbility,
  billingChargeId: BillingChargeId
) {
  const id = billingChargeIdToString(billingChargeId);
  const existingQuery = prisma.billingCharge.findFirstOrThrow({
    where: {
      id,
    },
    include: {
      allocations: {
        include: {
          billingTransaction: true,
        },
      },
    },
  });
  const existing = await existingQuery;

  const deleteAllocations: Prisma.PrismaPromise<unknown>[] = [];

  // if there are billingTransactionAllocations, and their billingTransaction is not posted, then delete them
  // if there are billingTransactionAllocations, and their billingTransaction is posted, then the delete should fail
  if (
    existing.allocations.length > 0 &&
    existing.allocations.some((allo) => {
      return allo.status === "Posted";
    })
  ) {
    throw new Error("Cannot delete a charge with posted payments");
  } else {
    deleteAllocations.push(
      prisma.billingTransactionAllocation.deleteMany({
        where: {
          billingChargeId: id,
        },
      })
    );
  }

  const { billingCharges } = await getBillingCase(
    prisma,
    ability,
    toBillingCaseId(existing.billingCaseId)
  );
  await prisma.$transaction(
    deleteAllocations.concat(
      billingCharges.map((p) => {
        if (p.id === existing.id) {
          return prisma.billingCharge.deleteMany({
            where: { id: p.id },
          });
        } else if (p.sequenceNumber > existing.sequenceNumber) {
          return prisma.billingCharge.updateMany({
            where: { id: p.id },
            data: { sequenceNumber: p.sequenceNumber - 1 },
          });
        } else {
          return existingQuery;
        }
      })
    )
  );
  return true;
}

export class NotUpdatableError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export async function setPayment(
  prisma: PrismaClient,
  ability: AppAbility,
  input: {
    billingTransactionId: BillingTransactionId;
    billingChargeId: BillingChargeId;
    amount: number;
    currency: CurrencyType;
    billingAdjustmentId: BillingAdjustmentId | null;
    billingClaimId: BillingClaimId;
    group: X12ClaimAdjustmentCodeGroupType | null;
  }
) {
  // Both the charge and the payment need to be accessible but we don't care about the data. Billing Payment does
  // need to be upserted and if we continue past the acccess checks we're good
  const [_transaction, _charge, allocation] = await Promise.all([
    prisma.billingTransaction.findFirstOrThrow({
      where: {
        id: billingTransactionIdToString(input.billingTransactionId),
      },
    }),
    prisma.billingCharge.findFirstOrThrow({
      where: {
        id: billingChargeIdToString(input.billingChargeId),
      },
    }),
    prisma.billingTransactionAllocation.findFirst({
      where: {
        billingChargeId: billingChargeIdToString(input.billingChargeId),
        billingTransactionId: billingTransactionIdToString(input.billingTransactionId),
        billingAdjustmentId: input.billingAdjustmentId
          ? billingAdjustmentIdToString(input.billingAdjustmentId)
          : null,
      },
      include: {
        billingTransaction: true,
      },
    }),
  ]);

  if (allocation?.billingTransaction?.status === BillingTransactionStatus.Complete)
    throw new NotUpdatableError("Cannot update a completed payment");
  if (allocation) {
    // update
    const { count } = await prisma.billingTransactionAllocation.updateMany({
      where: {
        id: allocation.id,
      },
      data: {
        amount: input.amount,
        currency: input.currency,
      },
    });
    if (count === 0) {
      throw new Error("Unexpected error updating payment allocation");
    } else {
      return true;
    }
  } else {
    // create
    const allocation =
      ability.can("create", "BillingTransactionAllocation") &&
      (await prisma.billingTransactionAllocation.create({
        data: {
          billingChargeId: billingChargeIdToString(input.billingChargeId),
          billingTransactionId: billingTransactionIdToString(input.billingTransactionId),
          billingClaimId: billingClaimIdToString(input.billingClaimId),
          billingAdjustmentId: input.billingAdjustmentId
            ? billingAdjustmentIdToString(input.billingAdjustmentId)
            : null,
          amount: input.amount,
          currency: input.currency,
          adjustmentGroup: input.group,
        },
      }));
    if (allocation) {
      return true;
    } else {
      throw new Error("Unexpected error creating payment allocation");
    }
  }
}
