// do not edit; this is a generated file. See bin/codeable-concepts.ts
export type KnownCodeableConcept =
  | "CLIN"
  | "CLIN.DIM"
  | "CLIN.DIM.BULK"
  | "CLIN.DIM.SET"
  | "CLIN.DX"
  | "CLIN.DX.ICD10"
  | "CLIN.DX.ICD10.A00_B99"
  | "CLIN.DX.ICD10.C00_D49"
  | "CLIN.DX.ICD10.D50_D89"
  | "CLIN.ENCNTR"
  | "CLIN.ENCNTR.PRI"
  | "CLIN.ENCNTR.PRI.EMERG"
  | "CLIN.ENCNTR.PRI.ROUT"
  | "CLIN.ENCNTR.PRI.URG"
  | "CLIN.EV"
  | "CLIN.EV.ALDRETE"
  | "CLIN.EV.ALDRETE.BP"
  | "CLIN.EV.ALDRETE.COLOR"
  | "CLIN.EV.ALDRETE.CONSCIOUSNESS"
  | "CLIN.EV.ALDRETE.MOTOR"
  | "CLIN.EV.ALDRETE.RESPIRATORY"
  | "CLIN.EV.CHARGE"
  | "CLIN.EV.CHARGE.CPT"
  | "CLIN.EV.CHARGE.HCPCS"
  | "CLIN.EV.LAB"
  | "CLIN.EV.LAB.COCAINE"
  | "CLIN.EV.LAB.GLUCOSE"
  | "CLIN.EV.LAB.HCG"
  | "CLIN.EV.LAB.INR"
  | "CLIN.EV.LAB.OTHERDRUG"
  | "CLIN.EV.LAB.PT"
  | "CLIN.EV.LAB.PTT"
  | "CLIN.EV.LAB.TEMPSET"
  | "CLIN.EV.LAB.TEMPSET.AXIL"
  | "CLIN.EV.LAB.TEMPSET.ORAL"
  | "CLIN.EV.LAB.TEMPSET.RECTAL"
  | "CLIN.EV.LINE"
  | "CLIN.EV.LINE.DISCONTINUE"
  | "CLIN.EV.LINE.START"
  | "CLIN.EV.LINE.TYPE"
  | "CLIN.EV.LINE.TYPE.IV"
  | "CLIN.EV.MAR"
  | "CLIN.EV.OBSV"
  | "CLIN.EV.OBSV.BMI"
  | "CLIN.EV.OBSV.BP"
  | "CLIN.EV.OBSV.BP.DIA"
  | "CLIN.EV.OBSV.BP.SYS"
  | "CLIN.EV.OBSV.DVTRISK"
  | "CLIN.EV.OBSV.DVTRISK.AGE"
  | "CLIN.EV.OBSV.DVTRISK.AGE.HIGH-RISK"
  | "CLIN.EV.OBSV.DVTRISK.AGE.LOW-RISK"
  | "CLIN.EV.OBSV.DVTRISK.AGE.MID-RISK"
  | "CLIN.EV.OBSV.DVTRISK.COMORBIDITIES"
  | "CLIN.EV.OBSV.DVTRISK.COMORBIDITIES.HISTORY-OF-DVT"
  | "CLIN.EV.OBSV.DVTRISK.COMORBIDITIES.RECENT-MI"
  | "CLIN.EV.OBSV.DVTRISK.MOBILITY"
  | "CLIN.EV.OBSV.DVTRISK.MOBILITY.BEDRIDDEN"
  | "CLIN.EV.OBSV.DVTRISK.OTHERS"
  | "CLIN.EV.OBSV.DVTRISK.OTHERS.HRT"
  | "CLIN.EV.OBSV.DVTRISK.OTHERS.OBESITY"
  | "CLIN.EV.OBSV.DVTRISK.SURGERY"
  | "CLIN.EV.OBSV.DVTRISK.SURGERY.MAJOR"
  | "CLIN.EV.OBSV.DVTRISK.SURGERY.MINOR"
  | "CLIN.EV.OBSV.FALLRISK"
  | "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID"
  | "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID.FURNITURE"
  | "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID.NONE"
  | "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID.SOME"
  | "CLIN.EV.OBSV.FALLRISK.GAIT"
  | "CLIN.EV.OBSV.FALLRISK.GAIT.IMPAIRED"
  | "CLIN.EV.OBSV.FALLRISK.GAIT.MILD"
  | "CLIN.EV.OBSV.FALLRISK.GAIT.WEAK"
  | "CLIN.EV.OBSV.FALLRISK.HISTORY"
  | "CLIN.EV.OBSV.FALLRISK.HISTORY.NO"
  | "CLIN.EV.OBSV.FALLRISK.HISTORY.YES"
  | "CLIN.EV.OBSV.FALLRISK.IVHEPLOCK"
  | "CLIN.EV.OBSV.FALLRISK.IVHEPLOCK.NO"
  | "CLIN.EV.OBSV.FALLRISK.IVHEPLOCK.YES"
  | "CLIN.EV.OBSV.FALLRISK.MENTALSTATUS"
  | "CLIN.EV.OBSV.FALLRISK.MENTALSTATUS.IMPAIRED"
  | "CLIN.EV.OBSV.FALLRISK.MENTALSTATUS.ORIENTED"
  | "CLIN.EV.OBSV.FALLRISK.SECONDARYDX"
  | "CLIN.EV.OBSV.FALLRISK.SECONDARYDX.NO"
  | "CLIN.EV.OBSV.FALLRISK.SECONDARYDX.YES"
  | "CLIN.EV.OBSV.HEIGHT"
  | "CLIN.EV.OBSV.LOC"
  | "CLIN.EV.OBSV.LOC.ASLEEP"
  | "CLIN.EV.OBSV.LOC.AWAKE"
  | "CLIN.EV.OBSV.LOC.CONSCIOUS"
  | "CLIN.EV.OBSV.LOC.UNCONSCIOUS"
  | "CLIN.EV.OBSV.PAIN"
  | "CLIN.EV.OBSV.PAINLOC"
  | "CLIN.EV.OBSV.PONVRISK"
  | "CLIN.EV.OBSV.PONVRISK.HISTORY"
  | "CLIN.EV.OBSV.PONVRISK.HISTORY.MOTIONSICKNESS"
  | "CLIN.EV.OBSV.PONVRISK.POSTOPTX"
  | "CLIN.EV.OBSV.PONVRISK.POSTOPTX.OPIOIDS"
  | "CLIN.EV.OBSV.PONVRISK.SEX"
  | "CLIN.EV.OBSV.PONVRISK.SEX.FEMALE"
  | "CLIN.EV.OBSV.PONVRISK.SEX.MALE"
  | "CLIN.EV.OBSV.PONVRISK.SMOKING"
  | "CLIN.EV.OBSV.PONVRISK.SMOKING.CURRENTSMOKER"
  | "CLIN.EV.OBSV.PONVRISK.SMOKING.NONSMOKER"
  | "CLIN.EV.OBSV.PULSE"
  | "CLIN.EV.OBSV.RESPRATE"
  | "CLIN.EV.OBSV.SPO2"
  | "CLIN.EV.OBSV.STOPBANGRISK"
  | "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS"
  | "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.AGE"
  | "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.APNEA"
  | "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.BMI"
  | "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.BP"
  | "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.MALE"
  | "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.NECKCIRCUMFERENCE"
  | "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.SNORING"
  | "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.TIREDNESS"
  | "CLIN.EV.OBSV.SUPP_O2"
  | "CLIN.EV.OBSV.TEMP"
  | "CLIN.EV.OBSV.TEMPMETHOD"
  | "CLIN.EV.OBSV.WEIGHT"
  | "CLIN.EV.TIMEOUT"
  | "CLIN.OR"
  | "CLIN.OR.TYPE"
  | "CLIN.OR.TYPE.MED"
  | "CLIN.OR.TYPE.WRITTEN"
  | "MED"
  | "MED.REGISTRY"
  | "MED.REGISTRY.NDC"
  | "MED.REGISTRY.PROCISIONTT"
  | "MED.REGISTRY.RXCUI"
  | "UOM"
  | "UOM.COND"
  | "UOM.COND.ALDRETE"
  | "UOM.COND.SCORE"
  | "UOM.DIAM"
  | "UOM.DIAM.GA"
  | "UOM.DOSAGE"
  | "UOM.DOSAGE.CAPSULE"
  | "UOM.DOSAGE.CC"
  | "UOM.DOSAGE.GM"
  | "UOM.DOSAGE.MG"
  | "UOM.DOSAGE.ML"
  | "UOM.DOSAGE.PILL"
  | "UOM.DOSAGE.TABLET"
  | "UOM.DOSAGE.UNITS"
  | "UOM.LEN"
  | "UOM.LEN.CM"
  | "UOM.LEN.FT"
  | "UOM.LEN.IN"
  | "UOM.NPRS"
  | "UOM.PERCENTAGE"
  | "UOM.PRESSURE"
  | "UOM.PRESSURE.MMHG"
  | "UOM.RATE"
  | "UOM.RATE.BEPM"
  | "UOM.RATE.BRPM"
  | "UOM.RATE.LPM"
  | "UOM.TEMP"
  | "UOM.TEMP.C"
  | "UOM.TEMP.F"
  | "UOM.TIME"
  | "UOM.TIME.HR"
  | "UOM.TIME.MIN"
  | "UOM.TIME.SEC"
  | "UOM.VOL"
  | "UOM.VOL.L"
  | "UOM.VOL.MGDL"
  | "UOM.VOL.ML"
  | "UOM.WT"
  | "UOM.WT.KG"
  | "UOM.WT.LB"
  | "WF"
  | "WF.DOC"
  | "WF.DOC.TEMPLATE"
  | "WF.DOC.TYPE"
  | "WF.DOC.TYPE.CONSENT"
  | "WF.DOC.TYPE.DI"
  | "WF.DOC.TYPE.HISTORYANDPHYSICAL"
  | "WF.DOC.TYPE.IMMEDIATEPOSTOPNOTE"
  | "WF.DOC.TYPE.OPREPORT";
export const CCRegistry = {
  CLIN: {
    /**
     * Clinical Data
     */
    $path: "CLIN",
    DIM: {
      /**
       * Dimensions
       */
      $path: "CLIN.DIM",
      BULK: {
        /**
         * Bundle
         */
        $path: "CLIN.DIM.BULK",
      },
      SET: {
        /**
         * Set
         */
        $path: "CLIN.DIM.SET",
      },
    },
    DX: {
      /**
       * Diagnoses
       */
      $path: "CLIN.DX",
      ICD10: {
        /**
         * ICD-10
         */
        $path: "CLIN.DX.ICD10",
        A00_B99: {
          /**
           * A00-B99
           */
          $path: "CLIN.DX.ICD10.A00_B99",
        },
        C00_D49: {
          /**
           * C00-D49
           */
          $path: "CLIN.DX.ICD10.C00_D49",
        },
        D50_D89: {
          /**
           * D50-D89
           */
          $path: "CLIN.DX.ICD10.D50_D89",
        },
      },
    },
    ENCNTR: {
      /**
       * Encounter
       */
      $path: "CLIN.ENCNTR",
      PRI: {
        /**
         * Priorities
         */
        $path: "CLIN.ENCNTR.PRI",
        EMERG: {
          /**
           * Emergency
           */
          $path: "CLIN.ENCNTR.PRI.EMERG",
        },
        ROUT: {
          /**
           * Routine
           */
          $path: "CLIN.ENCNTR.PRI.ROUT",
        },
        URG: {
          /**
           * Urgent
           */
          $path: "CLIN.ENCNTR.PRI.URG",
        },
      },
    },
    EV: {
      /**
       * Events
       */
      $path: "CLIN.EV",
      ALDRETE: {
        /**
         * Recovery
         */
        $path: "CLIN.EV.ALDRETE",
        BP: {
          /**
           * Blood Pressure
           */
          $path: "CLIN.EV.ALDRETE.BP",
        },
        COLOR: {
          /**
           * Color
           */
          $path: "CLIN.EV.ALDRETE.COLOR",
        },
        CONSCIOUSNESS: {
          /**
           * Consciousness
           */
          $path: "CLIN.EV.ALDRETE.CONSCIOUSNESS",
        },
        MOTOR: {
          /**
           * Moto
           */
          $path: "CLIN.EV.ALDRETE.MOTOR",
        },
        RESPIRATORY: {
          /**
           * Respiratory
           */
          $path: "CLIN.EV.ALDRETE.RESPIRATORY",
        },
      },
      CHARGE: {
        /**
         * Charges
         */
        $path: "CLIN.EV.CHARGE",
        CPT: {
          /**
           * CPT
           */
          $path: "CLIN.EV.CHARGE.CPT",
        },
        HCPCS: {
          /**
           * HCPCS
           */
          $path: "CLIN.EV.CHARGE.HCPCS",
        },
      },
      LAB: {
        /**
         * Laboratory
         */
        $path: "CLIN.EV.LAB",
        COCAINE: {
          /**
           * Cocaine
           */
          $path: "CLIN.EV.LAB.COCAINE",
        },
        GLUCOSE: {
          /**
           * Glucose
           */
          $path: "CLIN.EV.LAB.GLUCOSE",
        },
        HCG: {
          /**
           * HCG
           */
          $path: "CLIN.EV.LAB.HCG",
        },
        INR: {
          /**
           * International Normalized Ratio
           */
          $path: "CLIN.EV.LAB.INR",
        },
        OTHERDRUG: {
          /**
           * Other Drug
           */
          $path: "CLIN.EV.LAB.OTHERDRUG",
        },
        PT: {
          /**
           * Prothrombin Time
           */
          $path: "CLIN.EV.LAB.PT",
        },
        PTT: {
          /**
           * Partial Thromboplastin Time
           */
          $path: "CLIN.EV.LAB.PTT",
        },
        TEMPSET: {
          /**
           * Temperature Set
           */
          $path: "CLIN.EV.LAB.TEMPSET",
          AXIL: {
            /**
             * Axillary
             */
            $path: "CLIN.EV.LAB.TEMPSET.AXIL",
          },
          ORAL: {
            /**
             * Oral
             */
            $path: "CLIN.EV.LAB.TEMPSET.ORAL",
          },
          RECTAL: {
            /**
             * Rectal
             */
            $path: "CLIN.EV.LAB.TEMPSET.RECTAL",
          },
        },
      },
      LINE: {
        /**
         * Line
         */
        $path: "CLIN.EV.LINE",
        DISCONTINUE: {
          /**
           * Line Discontinued
           */
          $path: "CLIN.EV.LINE.DISCONTINUE",
        },
        START: {
          /**
           * Line Started
           */
          $path: "CLIN.EV.LINE.START",
        },
        TYPE: {
          /**
           * types
           */
          $path: "CLIN.EV.LINE.TYPE",
          IV: {
            /**
             * IV
             */
            $path: "CLIN.EV.LINE.TYPE.IV",
          },
        },
      },
      MAR: {
        /**
         * Medication Administration
         */
        $path: "CLIN.EV.MAR",
      },
      OBSV: {
        /**
         * Observation
         */
        $path: "CLIN.EV.OBSV",
        BMI: {
          /**
           * Body Mass Index
           */
          $path: "CLIN.EV.OBSV.BMI",
        },
        BP: {
          /**
           * Blood Pressure
           */
          $path: "CLIN.EV.OBSV.BP",
          DIA: {
            /**
             * Diastolic
             */
            $path: "CLIN.EV.OBSV.BP.DIA",
          },
          SYS: {
            /**
             * Systolic
             */
            $path: "CLIN.EV.OBSV.BP.SYS",
          },
        },
        DVTRISK: {
          /**
           * DVT Risk
           */
          $path: "CLIN.EV.OBSV.DVTRISK",
          AGE: {
            /**
             * Age
             */
            $path: "CLIN.EV.OBSV.DVTRISK.AGE",
            "HIGH-RISK": {
              /**
               * >= 75 years
               */
              $path: "CLIN.EV.OBSV.DVTRISK.AGE.HIGH-RISK",
            },
            "LOW-RISK": {
              /**
               * 41-60 years
               */
              $path: "CLIN.EV.OBSV.DVTRISK.AGE.LOW-RISK",
            },
            "MID-RISK": {
              /**
               * 61-74 years
               */
              $path: "CLIN.EV.OBSV.DVTRISK.AGE.MID-RISK",
            },
          },
          COMORBIDITIES: {
            /**
             * Comorbidities
             */
            $path: "CLIN.EV.OBSV.DVTRISK.COMORBIDITIES",
            "HISTORY-OF-DVT": {
              /**
               * History of DVT
               */
              $path: "CLIN.EV.OBSV.DVTRISK.COMORBIDITIES.HISTORY-OF-DVT",
            },
            "RECENT-MI": {
              /**
               * Recent myocardial infarction
               */
              $path: "CLIN.EV.OBSV.DVTRISK.COMORBIDITIES.RECENT-MI",
            },
          },
          MOBILITY: {
            /**
             * Mobility
             */
            $path: "CLIN.EV.OBSV.DVTRISK.MOBILITY",
            BEDRIDDEN: {
              /**
               * Bedridden
               */
              $path: "CLIN.EV.OBSV.DVTRISK.MOBILITY.BEDRIDDEN",
            },
          },
          OTHERS: {
            /**
             * Others
             */
            $path: "CLIN.EV.OBSV.DVTRISK.OTHERS",
            HRT: {
              /**
               * Hormone Replacement Therapy
               */
              $path: "CLIN.EV.OBSV.DVTRISK.OTHERS.HRT",
            },
            OBESITY: {
              /**
               * Obesity
               */
              $path: "CLIN.EV.OBSV.DVTRISK.OTHERS.OBESITY",
            },
          },
          SURGERY: {
            /**
             * Surgery
             */
            $path: "CLIN.EV.OBSV.DVTRISK.SURGERY",
            MAJOR: {
              /**
               * Major
               */
              $path: "CLIN.EV.OBSV.DVTRISK.SURGERY.MAJOR",
            },
            MINOR: {
              /**
               * Minor
               */
              $path: "CLIN.EV.OBSV.DVTRISK.SURGERY.MINOR",
            },
          },
        },
        FALLRISK: {
          /**
           * Fall Risk
           */
          $path: "CLIN.EV.OBSV.FALLRISK",
          AMBULATORYAID: {
            /**
             * Ambulatory Aid
             */
            $path: "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID",
            FURNITURE: {
              /**
               * Furniture
               */
              $path: "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID.FURNITURE",
            },
            NONE: {
              /**
               * None/bedrest/nurse assist
               */
              $path: "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID.NONE",
            },
            SOME: {
              /**
               * Crutches/cane/walker
               */
              $path: "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID.SOME",
            },
          },
          GAIT: {
            /**
             * Gait
             */
            $path: "CLIN.EV.OBSV.FALLRISK.GAIT",
            IMPAIRED: {
              /**
               * Impaired
               */
              $path: "CLIN.EV.OBSV.FALLRISK.GAIT.IMPAIRED",
            },
            MILD: {
              /**
               * Normal/bedrest/wheelchair
               */
              $path: "CLIN.EV.OBSV.FALLRISK.GAIT.MILD",
            },
            WEAK: {
              /**
               * Weak
               */
              $path: "CLIN.EV.OBSV.FALLRISK.GAIT.WEAK",
            },
          },
          HISTORY: {
            /**
             * History of falling
             */
            $path: "CLIN.EV.OBSV.FALLRISK.HISTORY",
            NO: {
              /**
               * No
               */
              $path: "CLIN.EV.OBSV.FALLRISK.HISTORY.NO",
            },
            YES: {
              /**
               * Yes
               */
              $path: "CLIN.EV.OBSV.FALLRISK.HISTORY.YES",
            },
          },
          IVHEPLOCK: {
            /**
             * IV/Heparin Lock
             */
            $path: "CLIN.EV.OBSV.FALLRISK.IVHEPLOCK",
            NO: {
              /**
               * No
               */
              $path: "CLIN.EV.OBSV.FALLRISK.IVHEPLOCK.NO",
            },
            YES: {
              /**
               * Yes
               */
              $path: "CLIN.EV.OBSV.FALLRISK.IVHEPLOCK.YES",
            },
          },
          MENTALSTATUS: {
            /**
             * Mental Status
             */
            $path: "CLIN.EV.OBSV.FALLRISK.MENTALSTATUS",
            IMPAIRED: {
              /**
               * Forgets limitations
               */
              $path: "CLIN.EV.OBSV.FALLRISK.MENTALSTATUS.IMPAIRED",
            },
            ORIENTED: {
              /**
               * Oriented to own ability
               */
              $path: "CLIN.EV.OBSV.FALLRISK.MENTALSTATUS.ORIENTED",
            },
          },
          SECONDARYDX: {
            /**
             * Secondary Diagnosis
             */
            $path: "CLIN.EV.OBSV.FALLRISK.SECONDARYDX",
            NO: {
              /**
               * No
               */
              $path: "CLIN.EV.OBSV.FALLRISK.SECONDARYDX.NO",
            },
            YES: {
              /**
               * Yes
               */
              $path: "CLIN.EV.OBSV.FALLRISK.SECONDARYDX.YES",
            },
          },
        },
        HEIGHT: {
          /**
           * Patient Height
           */
          $path: "CLIN.EV.OBSV.HEIGHT",
        },
        LOC: {
          /**
           * Level of Consciousness
           */
          $path: "CLIN.EV.OBSV.LOC",
          ASLEEP: {
            /**
             * Asleep
             */
            $path: "CLIN.EV.OBSV.LOC.ASLEEP",
          },
          AWAKE: {
            /**
             * Awake
             */
            $path: "CLIN.EV.OBSV.LOC.AWAKE",
          },
          CONSCIOUS: {
            /**
             * Conscious
             */
            $path: "CLIN.EV.OBSV.LOC.CONSCIOUS",
          },
          UNCONSCIOUS: {
            /**
             * Unconscious
             */
            $path: "CLIN.EV.OBSV.LOC.UNCONSCIOUS",
          },
        },
        PAIN: {
          /**
           * Pain Level
           */
          $path: "CLIN.EV.OBSV.PAIN",
        },
        PAINLOC: {
          /**
           * Pain Location
           */
          $path: "CLIN.EV.OBSV.PAINLOC",
        },
        PONVRISK: {
          /**
           * Post Operative Nausea and Vomiting
           */
          $path: "CLIN.EV.OBSV.PONVRISK",
          HISTORY: {
            /**
             * Medical history
             */
            $path: "CLIN.EV.OBSV.PONVRISK.HISTORY",
            MOTIONSICKNESS: {
              /**
               * History of PONV or motion sickness
               */
              $path: "CLIN.EV.OBSV.PONVRISK.HISTORY.MOTIONSICKNESS",
            },
          },
          POSTOPTX: {
            /**
             * Postoperative treatment
             */
            $path: "CLIN.EV.OBSV.PONVRISK.POSTOPTX",
            OPIOIDS: {
              /**
               * Use of post-operative opioids
               */
              $path: "CLIN.EV.OBSV.PONVRISK.POSTOPTX.OPIOIDS",
            },
          },
          SEX: {
            /**
             * Sex
             */
            $path: "CLIN.EV.OBSV.PONVRISK.SEX",
            FEMALE: {
              /**
               * Female
               */
              $path: "CLIN.EV.OBSV.PONVRISK.SEX.FEMALE",
            },
            MALE: {
              /**
               * Male
               */
              $path: "CLIN.EV.OBSV.PONVRISK.SEX.MALE",
            },
          },
          SMOKING: {
            /**
             * Smoking status
             */
            $path: "CLIN.EV.OBSV.PONVRISK.SMOKING",
            CURRENTSMOKER: {
              /**
               * Current Smoker
               */
              $path: "CLIN.EV.OBSV.PONVRISK.SMOKING.CURRENTSMOKER",
            },
            NONSMOKER: {
              /**
               * Nonsmoker
               */
              $path: "CLIN.EV.OBSV.PONVRISK.SMOKING.NONSMOKER",
            },
          },
        },
        PULSE: {
          /**
           * Pulse Rate
           */
          $path: "CLIN.EV.OBSV.PULSE",
        },
        RESPRATE: {
          /**
           * Respiration Rate
           */
          $path: "CLIN.EV.OBSV.RESPRATE",
        },
        SPO2: {
          /**
           * Oxygen Saturation
           */
          $path: "CLIN.EV.OBSV.SPO2",
        },
        STOPBANGRISK: {
          /**
           * STOP-BANG Risk
           */
          $path: "CLIN.EV.OBSV.STOPBANGRISK",
          OBSERVATIONS: {
            /**
             * STOP BANG factors
             */
            $path: "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS",
            AGE: {
              /**
               * > 50 years of age
               */
              $path: "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.AGE",
            },
            APNEA: {
              /**
               * Observed stopping breathing during sleep
               */
              $path: "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.APNEA",
            },
            BMI: {
              /**
               * BMI > 35 kg/m2
               */
              $path: "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.BMI",
            },
            BP: {
              /**
               * History of high blood pressure
               */
              $path: "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.BP",
            },
            MALE: {
              /**
               * Male Gender
               */
              $path: "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.MALE",
            },
            NECKCIRCUMFERENCE: {
              /**
               * > 40 cm
               */
              $path: "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.NECKCIRCUMFERENCE",
            },
            SNORING: {
              /**
               * Loud Snoring
               */
              $path: "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.SNORING",
            },
            TIREDNESS: {
              /**
               * Feeling tired during the day
               */
              $path: "CLIN.EV.OBSV.STOPBANGRISK.OBSERVATIONS.TIREDNESS",
            },
          },
        },
        SUPP_O2: {
          /**
           * Supplemental Oxygen
           */
          $path: "CLIN.EV.OBSV.SUPP_O2",
        },
        TEMP: {
          /**
           * Temperature
           */
          $path: "CLIN.EV.OBSV.TEMP",
        },
        TEMPMETHOD: {
          /**
           * Temperature Method of Measurement
           */
          $path: "CLIN.EV.OBSV.TEMPMETHOD",
        },
        WEIGHT: {
          /**
           * Patient Weight
           */
          $path: "CLIN.EV.OBSV.WEIGHT",
        },
      },
      TIMEOUT: {
        /**
         * Time Out
         */
        $path: "CLIN.EV.TIMEOUT",
      },
    },
    OR: {
      /**
       * Orders
       */
      $path: "CLIN.OR",
      TYPE: {
        /**
         * Order Type
         */
        $path: "CLIN.OR.TYPE",
        MED: {
          /**
           * Administer Medication
           */
          $path: "CLIN.OR.TYPE.MED",
        },
        WRITTEN: {
          /**
           * Written
           */
          $path: "CLIN.OR.TYPE.WRITTEN",
        },
      },
    },
  },

  MED: {
    /**
     * Medication
     */
    $path: "MED",
    REGISTRY: {
      /**
       * Medication registry
       */
      $path: "MED.REGISTRY",
      NDC: {
        /**
         * National Drug Code
         */
        $path: "MED.REGISTRY.NDC",
      },
      PROCISIONTT: {
        /**
         * Procision Text Templates
         */
        $path: "MED.REGISTRY.PROCISIONTT",
      },
      RXCUI: {
        /**
         * RxNorm Concept Unique Identifier
         */
        $path: "MED.REGISTRY.RXCUI",
      },
    },
  },

  UOM: {
    /**
     * Units of Measure
     */
    $path: "UOM",
    COND: {
      /**
       * Condition
       */
      $path: "UOM.COND",
      ALDRETE: {
        /**
         * Aldrete
         */
        $path: "UOM.COND.ALDRETE",
      },
      SCORE: {
        /**
         * Score
         */
        $path: "UOM.COND.SCORE",
      },
    },
    DIAM: {
      /**
       * Diameter
       */
      $path: "UOM.DIAM",
      GA: {
        /**
         * Gauge
         */
        $path: "UOM.DIAM.GA",
      },
    },
    DOSAGE: {
      /**
       * Dosage
       */
      $path: "UOM.DOSAGE",
      CAPSULE: {
        /**
         * Capsule
         */
        $path: "UOM.DOSAGE.CAPSULE",
      },
      CC: {
        /**
         * Cubic Centimeter
         */
        $path: "UOM.DOSAGE.CC",
      },
      GM: {
        /**
         * Gram
         */
        $path: "UOM.DOSAGE.GM",
      },
      MG: {
        /**
         * Milligram
         */
        $path: "UOM.DOSAGE.MG",
      },
      ML: {
        /**
         * Milliliter
         */
        $path: "UOM.DOSAGE.ML",
      },
      PILL: {
        /**
         * Pill
         */
        $path: "UOM.DOSAGE.PILL",
      },
      TABLET: {
        /**
         * Tablet
         */
        $path: "UOM.DOSAGE.TABLET",
      },
      UNITS: {
        /**
         * Units
         */
        $path: "UOM.DOSAGE.UNITS",
      },
    },
    LEN: {
      /**
       * Length
       */
      $path: "UOM.LEN",
      CM: {
        /**
         * Centimeter
         */
        $path: "UOM.LEN.CM",
      },
      FT: {
        /**
         * Foot
         */
        $path: "UOM.LEN.FT",
      },
      IN: {
        /**
         * Inch
         */
        $path: "UOM.LEN.IN",
      },
    },
    NPRS: {
      /**
       * Numeric Pain Rating Scale
       */
      $path: "UOM.NPRS",
    },
    PERCENTAGE: {
      /**
       * Percentage
       */
      $path: "UOM.PERCENTAGE",
    },
    PRESSURE: {
      /**
       * Pressure
       */
      $path: "UOM.PRESSURE",
      MMHG: {
        /**
         * Millimeters of Mercury
         */
        $path: "UOM.PRESSURE.MMHG",
      },
    },
    RATE: {
      /**
       * Rate
       */
      $path: "UOM.RATE",
      BEPM: {
        /**
         * Beats per Minute
         */
        $path: "UOM.RATE.BEPM",
      },
      BRPM: {
        /**
         * Breaths per Minute
         */
        $path: "UOM.RATE.BRPM",
      },
      LPM: {
        /**
         * Liters per Minute
         */
        $path: "UOM.RATE.LPM",
      },
    },
    TEMP: {
      /**
       * Temperature
       */
      $path: "UOM.TEMP",
      C: {
        /**
         * Celsius
         */
        $path: "UOM.TEMP.C",
      },
      F: {
        /**
         * Fahrenheit
         */
        $path: "UOM.TEMP.F",
      },
    },
    TIME: {
      /**
       * Time
       */
      $path: "UOM.TIME",
      HR: {
        /**
         * Hour
         */
        $path: "UOM.TIME.HR",
      },
      MIN: {
        /**
         * Minute
         */
        $path: "UOM.TIME.MIN",
      },
      SEC: {
        /**
         * Second
         */
        $path: "UOM.TIME.SEC",
      },
    },
    VOL: {
      /**
       * Volume
       */
      $path: "UOM.VOL",
      L: {
        /**
         * Liter
         */
        $path: "UOM.VOL.L",
      },
      MGDL: {
        /**
         * Milligrams per Deciliter
         */
        $path: "UOM.VOL.MGDL",
      },
      ML: {
        /**
         * Milliliter
         */
        $path: "UOM.VOL.ML",
      },
    },
    WT: {
      /**
       * Weight
       */
      $path: "UOM.WT",
      KG: {
        /**
         * Kilogram
         */
        $path: "UOM.WT.KG",
      },
      LB: {
        /**
         * Pound
         */
        $path: "UOM.WT.LB",
      },
    },
  },

  WF: {
    /**
     * Workflow
     */
    $path: "WF",
    DOC: {
      /**
       * Documents
       */
      $path: "WF.DOC",
      TEMPLATE: {
        /**
         * Template
         */
        $path: "WF.DOC.TEMPLATE",
      },
      TYPE: {
        /**
         * Type
         */
        $path: "WF.DOC.TYPE",
        CONSENT: {
          /**
           * Consent
           */
          $path: "WF.DOC.TYPE.CONSENT",
        },
        DI: {
          /**
           * Discharge Instructions
           */
          $path: "WF.DOC.TYPE.DI",
        },
        HISTORYANDPHYSICAL: {
          /**
           * History and Physical
           */
          $path: "WF.DOC.TYPE.HISTORYANDPHYSICAL",
        },
        IMMEDIATEPOSTOPNOTE: {
          /**
           * Immediate Post Op Note
           */
          $path: "WF.DOC.TYPE.IMMEDIATEPOSTOPNOTE",
        },
        OPREPORT: {
          /**
           * Op Report
           */
          $path: "WF.DOC.TYPE.OPREPORT",
        },
      },
    },
  },
} as const;
