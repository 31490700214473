import { DateTime } from "luxon";

export const HTML_DATE_FORMAT = "yyyy-LL-dd";
export const HTML_TIME_FORMAT = "HH:mm";

export function formatDate(date: Date, timezone: string, format?: string) {
  return DateTime.fromJSDate(date, { zone: timezone }).toFormat(format ?? "LL/dd/yyyy");
}

export function birthDateTime(date: Date) {
  return DateTime.fromJSDate(date, { zone: "utc" });
}
