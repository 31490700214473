import { z } from "zod";
import { OrganizationSchema, StaffSchema, BillingOrganizationSchema } from "./generated";

export type BillingOrganizationOutput = z.infer<typeof BillingOrganizationSchema>;
export const BillingOrganizationOutputSchema = BillingOrganizationSchema.extend({
  id: z.string(),
  organization: OrganizationSchema.extend({
    id: z.string(),
  }),
  staff: StaffSchema.extend({
    id: z.string(),
  }).nullish(),
});

export type BillingOrganizationInput = z.infer<typeof BillingOrganizationInputSchema>;
export const BillingOrganizationInputSchema = BillingOrganizationSchema.extend({
  id: z.string().optional(),
});
