import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { ForwardedRef, InputHTMLAttributes } from "react";
import { forwardRef, useId } from "react";
import { type FieldValues, type Path } from "react-hook-form";
import { mergeRefs } from "react-merge-refs";
import { cn } from "../../utils";
import { BoundField, useFilteredFieldProps, type BoundFieldWithLegacyProps } from "./BoundField";

type Ref = HTMLInputElement;

export const radioVariants = cva(
  [
    `form-radio relative cursor-default rounded-full border ring-offset-primary transition-colors
    focus:outline-none focus:ring-1 focus:ring-brand focus:ring-offset-1 focus:ring-offset-primary
    focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-brand
    focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50`,
  ],
  {
    variants: {
      variant: {
        default: `border-primary bg-primary text-secondary checked:bg-brand-alt checked:text-white
        hover:checked:bg-brand-alt hover:checked:text-white focus:border-primary
        focus:checked:bg-brand-alt focus:checked:text-white`,
        warning: `border-warning bg-warning text-warning checked:bg-warning-alt
        checked:text-secondary hover:checked:bg-warning-alt hover:checked:text-secondary
        focus:border-warning focus:checked:bg-warning-alt focus:checked:text-secondary`,
        error: `border-error bg-error text-error checked:bg-error-alt checked:text-white
        hover:checked:bg-error-alt hover:checked:text-white focus:border-error
        focus:checked:bg-error-alt focus:checked:text-white`,
      },
      size: {
        sm: "h-4 w-4",
        md: "h-5 w-5",
        table: "h-4 w-4",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "md",
    },
  }
);

export type RadioValueType = boolean | string | number | readonly string[] | undefined;

export type RadioProps<TValue extends RadioValueType> = Omit<
  InputHTMLAttributes<Ref>,
  "size" | "type" | "value" | "onChange"
> &
  VariantProps<typeof radioVariants> & {
    value?: TValue;
    onChange?: (value: TValue) => void;
    error?: boolean;
    labelProps?: { className?: string };
  };

const RadioInner = <TValue extends RadioValueType>(
  {
    variant: _variant,
    size,
    error,
    className,
    children,
    value,
    onChange,
    labelProps,
    ...props
  }: RadioProps<TValue>,
  ref?: ForwardedRef<Ref>
) => {
  const id = useId();
  const variant = error ? "error" : _variant;
  const disabled = props.disabled || props.readOnly;
  const sizeClassName =
    size === "sm"
      ? "h-6 px-2 py-0.5 text-xs print:py-0"
      : size === "md" || size === "table"
        ? "h-8 px-2 py-1.5 text-sm print:py-0"
        : "h-10 px-2.5 py-2 text-sm print:py-0";
  return (
    <div className={cn("relative inline-flex items-center gap-2 align-middle", sizeClassName)}>
      <input
        {...props}
        id={props.id ?? id}
        ref={ref}
        value={typeof value === "boolean" ? (value ? "true" : "false") : value}
        onChange={(e) =>
          typeof value === "boolean"
            ? onChange?.((e.target.value === "true" ? true : false) as TValue)
            : onChange?.(e.target.value as TValue)
        }
        type="radio"
        disabled={disabled}
        className={cn(radioVariants({ variant, size }), className)}
      />
      {children && (
        <label htmlFor={props.id ?? id} className={labelProps?.className}>
          {children}
        </label>
      )}
    </div>
  );
};

export const Radio = forwardRef(RadioInner) as <TValue extends RadioValueType>(
  props: RadioProps<TValue> & {
    ref?: React.ForwardedRef<Ref>;
  }
) => ReturnType<typeof RadioInner>;

type RadioFieldProps<
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
  TValue extends RadioValueType,
> = Omit<RadioProps<TValue>, "name"> & BoundFieldWithLegacyProps<TFieldValues, TFieldPath>;

const RadioFieldInner = <
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
  TValue extends RadioValueType,
>(
  props: RadioFieldProps<TFieldValues, TFieldPath, TValue>,
  ref?: ForwardedRef<Ref>
) => {
  const { fieldProps, inputProps } = useFilteredFieldProps<
    TFieldValues,
    TFieldPath,
    RadioFieldProps<TFieldValues, TFieldPath, TValue>
  >(props);

  return (
    <BoundField
      {...fieldProps}
      labelProps={{ ...fieldProps.labelProps, size: inputProps.size }}
      className={cn("!flex-none", fieldProps.className)}
      render={({ field }) => (
        <Radio
          {...inputProps}
          {...field}
          checked={inputProps.value === field.value}
          value={inputProps.value}
          ref={mergeRefs([ref, field.ref])}
        />
      )}
    />
  );
};

export const RadioField = forwardRef(RadioFieldInner) as <
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
  TValue extends RadioValueType,
>(
  props: RadioFieldProps<TFieldValues, TFieldPath, TValue> & {
    ref?: React.ForwardedRef<Ref>;
  }
) => ReturnType<typeof RadioFieldInner>;
