import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { LabelHTMLAttributes } from "react";
import { forwardRef } from "react";
import { cn } from "../../utils";
import { type InputSize } from "./Input";

type Ref = HTMLLabelElement;

export const labelVariants = cva(["sentence-case block truncate font-medium text-primary"], {
  variants: {
    size: {
      sm: "text-sm empty:h-4",
      md: "text-sm empty:h-5",
      table: "text-sm empty:h-4",
    } satisfies Record<InputSize, string>,
  },
  defaultVariants: {
    size: "md",
  },
});

export type LabelProps = LabelHTMLAttributes<Ref> &
  VariantProps<typeof labelVariants> & {
    error?: boolean; // This does not change the appearance of the label but is here to keep a similar api to other form components
    disabled?: boolean;
    required?: boolean;
    preserveCase?: boolean;
  };

export const Label = forwardRef<Ref, LabelProps>(
  (
    {
      size,
      className,
      error: _error, // Pull out error so it isn't passed as a prop to the label element, it does not change the appearance of the label
      disabled,
      required,
      children,
      preserveCase,
      ...props
    },
    ref
  ) => {
    return (
      <label
        {...props}
        ref={ref}
        className={cn(
          labelVariants({ size }),
          disabled && "opacity-50",
          preserveCase && "normal-case",
          className
        )}
      >
        {children}
        {required && " *"}
      </label>
    );
  }
);
Label.displayName = "Label";
