import { z } from 'zod';

/////////////////////////////////////////
// CONTRACT MULTIPLE PROCEDURE REIMBURSEMENT RATE SCHEMA
/////////////////////////////////////////

export const ContractMultipleProcedureReimbursementRateSchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  contractId: z.string(),
  /**
   * exclusive
   */
  thresholdMinimum: z.number().int(),
  /**
   * inclusive
   */
  thresholdMaximum: z.number().int(),
  multiplier: z.number(),
})

export type ContractMultipleProcedureReimbursementRate = z.infer<typeof ContractMultipleProcedureReimbursementRateSchema>

export default ContractMultipleProcedureReimbursementRateSchema;
