import { z } from 'zod';

/////////////////////////////////////////
// INTERNAL MIGRATION SCHEMA
/////////////////////////////////////////

export const InternalMigrationSchema = z.object({
  id: z.string(),
  startedAt: z.coerce.date().nullish(),
  finishedAt: z.coerce.date().nullish(),
  name: z.string(),
  checksum: z.string(),
})

export type InternalMigration = z.infer<typeof InternalMigrationSchema>

export default InternalMigrationSchema;
