import { z } from 'zod';

/////////////////////////////////////////
// SPECIALTY SCHEMA
/////////////////////////////////////////

export const SpecialtySchema = z.object({
  id: z.string(),
  name: z.string(),
  color: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Specialty = z.infer<typeof SpecialtySchema>

export default SpecialtySchema;
