import type { z } from "zod";
import {
  PaymentInsuranceAttachmentSchema,
  PaymentInsuranceAttachmentTypeSchema,
} from "./generated";
import { StorageBlobUserSchema } from "./storage-blob";

export const PaymentInsuranceAttachmentInputSchema = PaymentInsuranceAttachmentSchema.partial({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export type PaymentInsuranceAttachmentInput = z.infer<typeof PaymentInsuranceAttachmentInputSchema>;

export const PaymentInsuranceAttachmentStorageBlobUserSchema =
  PaymentInsuranceAttachmentSchema.extend({
    paymentInsuranceAttachmentType: PaymentInsuranceAttachmentTypeSchema,
    storageBlob: StorageBlobUserSchema,
  });

export type PaymentInsuranceAttachmentStorageBlobUser = z.infer<
  typeof PaymentInsuranceAttachmentStorageBlobUserSchema
>;
