import Link from "next/link";
import { useRouter } from "~/hooks/useRouter";
import { cn } from "@procision-software/ui";

type NavItem = {
  name: string;
  href: string;
  count?: number;
  disabled?: boolean;
  children?: React.ReactNode;
};

export default function Navbar({ navItems }: { navItems: NavItem[] }) {
  const router = useRouter();
  return (
    <nav className="contents">
      {navItems.map((item) => (
        <div
          key={item.href}
          className={`flex items-center gap-1 rounded-lg px-3 py-1 text-gray-700 focus-within:bg-gray-200 focus-within:outline-none hover:bg-gray-200 dark:text-gray-300 dark:focus-within:bg-gray-500 dark:hover:bg-gray-500`}
        >
          <Link
            href={item.href}
            className={cn(
              `focus:bg-transparent focus:outline-none focus:ring-0`,
              router.pathname.startsWith(item.href) && "font-bold"
            )}
          >
            {item.name}
          </Link>
          {!!item.count && (
            <p className="-my-1 -mr-3 rounded-lg bg-gray-300 px-1.5 py-0.5 dark:bg-gray-700">
              {item.count}
            </p>
          )}
          {item.children}
        </div>
      ))}
    </nav>
  );
}
