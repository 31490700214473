import { type PrismaClient } from "@prisma/client";
import { Stedi } from "../../stedi";
import { toCents } from "../../util/currency";

export class PreconditionFailedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "PreconditionFailed";
  }
}
/**
 *
 * @param prisma prisma handle
 * @param paymentInsuranceId insurance to be checked
 * @returns electronically verified insurance benefits
 * @throws PreconditionFailedError if any required data is missing
 * @throws TooManyResultsError if we get two values for a single field
 * @throws VerificationFailedError if the verification fails
 */
export async function verifyAndStoreInsuranceBenefits(
  prisma: PrismaClient,
  paymentInsuranceId: string
) {
  // Load all the required related data
  const insurance = await prisma.paymentInsurance.findFirst({
    where: {
      id: paymentInsuranceId,
    },
    include: {
      provider: {
        include: {
          waystarInsuranceProvider: true,
        },
      },
      case: {
        include: {
          facility: true,
          patient: true,
        },
      },
    },
  });

  // verify that each piece of required related data is present
  if (!insurance) {
    throw new PreconditionFailedError(`Insurance record with ID ${paymentInsuranceId} not found`);
  }
  if (!insurance.case.patient?.dateOfBirth) {
    throw new PreconditionFailedError("Patient date of birth is required");
  }

  if (!insurance.case.facility?.ein) {
    throw new PreconditionFailedError("Facility EIN is required");
  }

  if (!insurance.case.facility?.npi) {
    throw new PreconditionFailedError("Facility NPI is required");
  }

  if (!insurance.provider?.waystarInsuranceProvider?.externalId) {
    throw new PreconditionFailedError("Can only verify benefits for Waystar insurance providers");
  }

  if (!insurance.networkContract) {
    throw new PreconditionFailedError("Insurance network contract is required");
  }

  if (!process.env.STEDI_API_KEY) {
    throw new PreconditionFailedError("STEDI API key is required");
  }

  if (insurance.policyNumber.length < 2) {
    throw new PreconditionFailedError("Policy number is required");
  }

  if (insurance.provider?.waystarInsuranceProvider?.externalId === "98999") {
    throw new PreconditionFailedError("Insurance provider is not supported by STEDI");
  }

  // establish a unique-ish control number.
  const controlNumber = `${insurance.case.financialReference}.${insurance.stediVerificationCount}`;

  // do the check
  const benefits = await Stedi.eligibilityCheck(
    {
      encounter: {
        dateOfService: insurance.case.surgeryDate,
        serviceTypeCodes: ["30", "13", "50"], // 30 is for anesthesia, 13 is for surgery, 50 is for facility
      },
      subscriber: {
        dateOfBirth: insurance.case.patient.dateOfBirth,
        memberId: insurance.policyNumber,
        firstName: insurance.firstName,
        lastName: insurance.lastName,
      },
      controlNumber,
      informationReceiverName: {
        federalTaxpayerIdentificationNumber: insurance.case.facility.ein,
      },
      provider: {
        npi: insurance.case.facility.npi,
        organizationName: insurance.case.facility.name,
      },
      tradingPartnerServiceId: insurance.provider?.waystarInsuranceProvider?.externalId,
    },
    insurance.networkContract,
    {
      apiKey: process.env.STEDI_API_KEY,
      dateOfBirthTZ: "GMT",
      dateOfServiceTZ: insurance.case.facility?.timezone,
    }
  );

  // we'll probably get results
  if (benefits) {
    // store it to the insurance record
    await prisma.paymentInsurance.update({
      where: {
        id: paymentInsuranceId,
      },
      data: {
        insuranceVerifiedAt: benefits.eligibilityCheckDate,
        insuranceVerified: true,
        stediVerificationCount: {
          increment: 1,
        },
        ...(benefits.coinsurance !== null && {
          coinsurancePercentage: 100 * benefits.coinsurance,
        }),
        ...(benefits.copayment !== null && {
          coPayInDollars: toCents(benefits.copayment),
        }),
        ...(benefits.deductible !== null && {
          deductibleInDollars: toCents(benefits.deductible),
        }),
        ...(benefits.deductibleRemaining !== null &&
          benefits.deductible !== null && {
            deductibleRemaining: toCents(benefits.deductibleRemaining),
          }),
        ...(benefits.outOfPocketMax !== null && {
          outOfPocketInDollars: toCents(benefits.outOfPocketMax),
        }),
        ...(benefits.outOfPocketMaxRemaining !== null &&
          benefits.outOfPocketMax !== null && {
            outOfPocketRemaining: toCents(benefits.outOfPocketMaxRemaining),
          }),
      },
    });
  }

  // return the updated(?) data so ui can reflect state
  return benefits;
}
