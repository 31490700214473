import { z } from 'zod';

/////////////////////////////////////////
// HCPCS CODE SCHEMA
/////////////////////////////////////////

export const HcpcsCodeSchema = z.object({
  id: z.string(),
  code: z.string(),
  shortName: z.string(),
  longName: z.string(),
  active: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type HcpcsCode = z.infer<typeof HcpcsCodeSchema>

export default HcpcsCodeSchema;
