import { z } from 'zod';

/////////////////////////////////////////
// PAYMENT INSURANCE ATTACHMENT SCHEMA
/////////////////////////////////////////

export const PaymentInsuranceAttachmentSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  paymentInsuranceAttachmentTypeId: z.string(),
  paymentInsuranceId: z.string(),
  storageBlobId: z.string(),
  name: z.string(),
})

export type PaymentInsuranceAttachment = z.infer<typeof PaymentInsuranceAttachmentSchema>

export default PaymentInsuranceAttachmentSchema;
