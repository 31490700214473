import type {
  PatientSignatureFindFilter,
  PatientSignatureFormData,
} from "@procision-software/mason";
import { useCallback } from "react";
import { client, trpc, type ReactQueryOptions } from "~/utils/trpc";

export function useInvalidatePatientSignature() {
  const utils = trpc.useUtils();

  const invalidate = useCallback(
    async (input?: PatientSignatureFindFilter): Promise<void> => {
      if (input === undefined) {
        await utils.core.patientSignature.get.invalidate();
        await utils.core.patientSignature.list.invalidate();
        return;
      }

      await utils.core.patientSignature.get.invalidate(input);
      await utils.core.patientSignature.list.invalidate({ filters: input });
    },
    [utils]
  );

  return [invalidate] as const;
}

export function usePatientSignatureService() {
  const [invalidatePatientSignature] = useInvalidatePatientSignature();

  const create = useCallback(
    async (data: PatientSignatureFormData) => {
      const value = await client.core.patientSignature.create.mutate(data);

      await invalidatePatientSignature({ patientId: value.patientId });

      return value;
    },
    [invalidatePatientSignature]
  );

  return {
    create,
  } as const;
}

export function usePatientSignature(
  input: PatientSignatureFindFilter,
  options?: ReactQueryOptions["core"]["patientSignature"]["get"]
) {
  return trpc.core.patientSignature.get.useQuery(input, options);
}
