import { z } from 'zod';

/////////////////////////////////////////
// ORGANIZATION RELATIONSHIP SCHEMA
/////////////////////////////////////////

export const OrganizationRelationshipSchema = z.object({
  id: z.string(),
  facilityOrgId: z.string(),
  practiceOrgId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type OrganizationRelationship = z.infer<typeof OrganizationRelationshipSchema>

export default OrganizationRelationshipSchema;
