import type { KnownCodeableConcept } from "./known-concepts";

type FilterInHouseTest<T> = T extends `CLIN.EV.LAB.${string}`
  ? T extends `CLIN.EV.LAB.TEMPSET${string}`
    ? never
    : T
  : never; // I think it's wrong that tempset wound up where it did but that's out of scope to fix right now.
export type InHouseTest = FilterInHouseTest<KnownCodeableConcept>;
export function isInHouseTest(cc: KnownCodeableConcept): cc is InHouseTest {
  return cc.startsWith("CLIN.EV.LAB.");
}
