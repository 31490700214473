import { useClerk } from "@clerk/nextjs";
import type { ParsedUrlQueryInput } from "querystring";
import { useEffect } from "react";
import { useRouter } from "~/hooks/useRouter";
import { currentUri } from "~/utils/router";

export const RedirectToOrganizationChooser = (props: ParsedUrlQueryInput) => {
  const { organization } = useClerk();
  const router = useRouter();

  useEffect(() => {
    if (!organization) {
      const params = new URLSearchParams({
        redirectUrl: props.redirectUrl ? (props.redirectUrl as string) : currentUri(router),
      }).toString();

      void router.push(`/sign-in/organization-chooser${params.length ? `?${params}` : ""}`);
    }
  }, [organization, props, router]);

  return null;
};
