import { accessibleBy, type AppAbility } from "@procision-software/auth";
import type { Prisma, PrismaClient } from "@procision-software/database";
import { staffFullName } from "@procision-software/mason";
import {
  AddressSchema,
  BillingClaimSchema,
  BillingPayerSchema,
  InsuranceProviderSchema,
  PaymentInsuranceSchema,
  PaymentWorkersCompSchema,
  WaystarInsuranceProviderSchema,
  WorkersCompProviderSchema,
  type BillingOrganization,
  type Case,
  type Patient,
  type Staff,
} from "@procision-software/database-zod";
import { formatDate } from "@procision-software/ui/src/utils/dates";
import { z } from "zod";
import isBlank from "~/utils/is-blank";

export {
  PurpleHealthCareDiagnosisCodeSchema as X12837PHealthCareDiagnosisCodeSchema,
  PurpleOtherSubscriberInformationLoopSchema as X12837POtherSubscriberInformationLoopSchema,
  X12837PSchema,
  PurpleServiceLineNumberLoopSchema as X12837PServiceLineNumberLoopSchema,
  type X12837P,
  type PurpleHealthCareDiagnosisCode as X12837PHealthCareDiagnosisCode,
  type PurpleOtherSubscriberInformationLoop as X12837POtherSubscriberInformationLoop,
  type PayerResponsibilitySequenceNumberCode as X12837PPayerResponsibilitySequenceNumberCode,
  type PurpleServiceLineNumberLoop as X12837PServiceLineNumberLoop,
} from "../../models/x12-837/x12-837p";

export {
  PurpleOtherDiagnosisInformationSchema as X12837IOtherDiagnosisInformationSchema,
  PurpleOtherSubscriberInformationLoopSchema as X12837IOtherSubscriberInformationLoopSchema,
  X12837ISchema,
  PurpleServiceLineNumberLoopSchema as X12837IServiceLineNumberLoopSchema,
  type X12837I,
  type PurpleAttendingProviderSecondaryIdentification as X12837IAttendingProviderSecondaryIdentification,
  type PurpleOperatingPhysicianSecondaryIdentification as X12837IOperatingPhysicianSecondaryIdentification,
  type PurpleOtherDiagnosisInformation as X12837IOtherDiagnosisInformation,
  type PurpleOtherSubscriberInformationLoop as X12837IOtherSubscriberInformationLoop,
  type PayerResponsibilitySequenceNumberCode as X12837IPayerResponsibilitySequenceNumberCode,
  type PurpleServiceLineNumberLoop as X12837IServiceLineNumberLoop,
} from "../../models/x12-837/x12-837i";

export const NON_ALPHA_NUMERIC_REGEX = /([^A-Za-z0-9])/g;
export const UNALLOWED_CHARS_REGEX = /([^\w\s\d])+/g;

export type InternalBillingClaimFilter = z.infer<typeof InternalBillingClaimFilterSchema>;
export const InternalBillingClaimFilterSchema = BillingClaimSchema.pick({
  id: true,
}).extend({
  id: z.string().describe("Billing Claim ID"),
});

export type InternalPayment = z.infer<typeof InternalPaymentSchema>;
export const InternalPaymentSchema = z.object({
  authorizationNumber: z.string(),
  groupName: z.string(),
  groupNumber: z.string(),
  policyNumber: z.string(),
  payerName: z.string(),
  payerId: z.string(),
});

export type InternalPatient = z.infer<typeof InternalPatientSchema>;
export const InternalPatientSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string(),
  address1: z.string(),
  address2: z.string(),
  city: z.string(),
  state: z.string(),
  zipCode: z.string(),
  birthDate: z.string(),
  ssn: z.string().nullish(),
  mrn: z.string(),
  genderCode: z.union([z.literal("F"), z.literal("M"), z.literal("U")]),
});

export type InternalAddress = z.infer<typeof InternalAddressSchema>;
export const InternalAddressSchema = AddressSchema.extend({
  id: z.string(),
});

export type InternalPaymentInsurance = z.infer<typeof InternalPaymentInsuranceSchema>;
export const InternalPaymentInsuranceSchema = PaymentInsuranceSchema.extend({
  providerId: z.string(),
  provider: InsuranceProviderSchema.extend({
    claimAddress: InternalAddressSchema.nullish(),
    waystarInsuranceProvider: WaystarInsuranceProviderSchema.nullish(),
  }),
});

export type InternalPaymentWorkersComp = z.infer<typeof InternalPaymentWorkersCompSchema>;
export const InternalPaymentWorkersCompSchema = PaymentWorkersCompSchema.extend({
  providerId: z.string(),
  provider: WorkersCompProviderSchema.extend({
    claimAddress: InternalAddressSchema.nullish(),
    waystarProvider: WaystarInsuranceProviderSchema.nullish(),
  }),
});

export type InternalMinimalBillingPayer = z.infer<typeof InternalMinimalBillingPayerSchema>;
export const InternalMinimalBillingPayerSchema = BillingPayerSchema.pick({
  id: true,
  paymentType: true,
}).extend({
  address: InternalAddressSchema,
  occurrence: z.date().nullish(),
  occurrenceCode: z.string(),
  payment: InternalPaymentSchema,
  subscriber: InternalPatientSchema,
});

export type InternalBillingPayer = z.infer<typeof InternalBillingPayerSchema>;
export const InternalBillingPayerSchema = z.discriminatedUnion("paymentType", [
  InternalMinimalBillingPayerSchema.extend({
    paymentType: z.literal("Insurance"),
  }),
  InternalMinimalBillingPayerSchema.extend({
    paymentType: z.literal("Letter_Of_Protection"),
  }),
  InternalMinimalBillingPayerSchema.extend({
    paymentType: z.literal("Self_Pay"),
  }),
  InternalMinimalBillingPayerSchema.extend({
    paymentType: z.literal("Workers_Comp"),
  }),
]);

export type InternalUnknownBillingPayer = z.infer<typeof InternalUnknownBillingPayerSchema>;
export const InternalUnknownBillingPayerSchema = BillingPayerSchema.extend({
  waystarInsuranceProvider: WaystarInsuranceProviderSchema.nullish(),
});

export type BillingOrganizationContact = { name: string; kind: "TE"; value: string };

/**
 * Unless function similar to Ruby's `unless` statement. Only runs when the condition is false.
 *
 * @param value T some value, treated as truthy via `isBlank`
 * @param cb Callback to run if `value` is not blank
 * @returns R
 */
export function unless<T, R>(
  value: T | null | undefined,
  cb: (value: NonNullable<T>) => R
): R | Record<string, never> {
  return isBlank(value) === false ? cb(value as NonNullable<T>) : {};
}

export function mapDateToSegments(value: Date): [string, string] {
  const [date, time, ..._unused] = value.toISOString().split(/[T.]/g);

  return [date!, time!];
}

export function mapDateToCCYYMMDD(value: Date, timezone = "UTC"): string {
  return formatDate(value, timezone, "yLLdd");
}

export function mapGenderToGenderCode(
  value: "Male" | "Female" | null | undefined
): "M" | "F" | "U" {
  switch (value) {
    case "Female":
      return "F";
    case "Male":
      return "M";
    default:
      return "U";
  }
}

export function mapPatient(kase: Case, patient: Patient): InternalPatient {
  return InternalPatientSchema.parse({
    ...patient,
    address1: kase.patientAddress1,
    address2: kase.patientAddress2 ?? "",
    city: kase.patientCity,
    ssn: kase.patientSsn,
    state: kase.patientState,
    zipCode: kase.patientZip,
    birthDate: mapDateToCCYYMMDD(patient?.dateOfBirth ?? kase.patientDateOfBirth),
    genderCode: mapGenderToGenderCode(kase.patientSex),
  });
}

export function mapFrequencyCode(value: string): {
  facilityType: string;
  careType: string;
  frequencyCode: string;
} {
  return {
    facilityType: value.at(1)!,
    careType: value.at(2)!,
    frequencyCode: value.at(3)!,
  };
}

export function mapSurgeon(value: Staff): Staff & {
  firstName: string;
  lastName: string;
  middleName: string;
} {
  const { firstName, lastName, middleName } = value;

  return { ...value, firstName: firstName ?? "", lastName, middleName: middleName ?? "" };
}

export function mapBillingOrganization(value?: BillingOrganization & { staff: Staff | null }): {
  billingOrganization: BillingOrganization;
  billingOrganizationStaff: Staff;
  billingOrganizationContacts: BillingOrganizationContact[];
} {
  const { staff: unmappedStaff, ...billingOrganization } = value!;
  const billingOrganizationStaff = unmappedStaff!;
  const billingOrganizationContacts = Array.from(
    generateBillingOrganizationContacts(billingOrganizationStaff)
  );

  return {
    billingOrganization,
    billingOrganizationStaff,
    billingOrganizationContacts,
  };
}

export function* generateBillingOrganizationContacts(
  value: Staff
): Generator<BillingOrganizationContact> {
  const mobilePhone = (value.mobilePhone ?? "").replaceAll(/([^0-9])/g, "").trim();

  if (mobilePhone.length > 0) {
    yield {
      name: staffFullName(value),
      kind: "TE",
      value: mobilePhone,
    };
  }

  const officePhone = (value.officePhone ?? "").replaceAll(/([^0-9])/g, "").trim();

  if (officePhone.length > 0) {
    yield {
      name: staffFullName(value),
      kind: "TE",
      value: officePhone,
    };
  }
}

/**
 * Build a where clause for {@linkcode Prisma.BillingClaimWhereInput} or
 * {@linkcode Prisma.BillingClaimWhereUniqueInput} based on the given `ability` and `filter`.
 *
 * @param ability {@linkcode AppAbility}
 * @param params {@linkcode InternalBillingClaimFilter}
 * @returns {Prisma.BillingClaimWhereInput | Prisma.BillingClaimWhereUniqueInput} {@linkcode Prisma.BillingClaimWhereInput} | {@linkcode Prisma.BillingClaimWhereUniqueInput}
 */
export function whereBillingClaim(
  ability: AppAbility,
  { id }: InternalBillingClaimFilter
): Prisma.BillingClaimWhereInput | Prisma.BillingClaimWhereUniqueInput {
  return {
    AND: [accessibleBy(ability).BillingClaim, { id }],
  };
}

export async function getStediPartnershipId(
  prisma: PrismaClient,
  ability: AppAbility,
  billingClaimId: string
) {
  const {
    billingCase: {
      case: {
        facility: {
          organization: { billingOrganizations },
        },
      },
    },
  } = await prisma.billingClaim.findFirstOrThrow({
    where: {
      id: billingClaimId,
      ...accessibleBy(ability, "read").BillingClaim,
    },
    include: {
      billingCase: {
        include: {
          case: {
            include: {
              facility: {
                include: {
                  organization: {
                    include: {
                      billingOrganizations: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  });

  const [billingOrganization] = billingOrganizations;

  if (billingOrganization === undefined) {
    throw new Error("the organization must have a billingOrganization");
  }

  const { staffId, stediPartnershipId } = billingOrganization;

  if (staffId === null) {
    throw new Error("the billingOrganization must have a staffId");
  }

  if (stediPartnershipId === null) {
    throw new Error("the billingOrganization must have a stediPartnershipId");
  }

  return stediPartnershipId;
}

export async function getElectronicPaymentFormat(
  prisma: PrismaClient,
  ability: AppAbility,
  billingClaimId: string
) {
  const {
    billingPayer: { paymentInsuranceId, paymentWorkersCompId, paymentLetterOfProtectionId },
  } = await prisma.billingClaim.findFirstOrThrow({
    where: {
      id: billingClaimId,
      ...accessibleBy(ability, "read").BillingClaim,
    },
    include: {
      billingPayer: {
        include: {
          waystarInsuranceProvider: true,
        },
      },
    },
  });

  if (
    paymentInsuranceId === null &&
    paymentWorkersCompId === null &&
    paymentLetterOfProtectionId === null
  ) {
    throw new Error(
      "the billingPayer must be either insurance, workers comp or letter of protection"
    );
  }

  if (paymentInsuranceId !== null) {
    const { provider } = await prisma.paymentInsurance.findFirstOrThrow({
      where: {
        id: paymentInsuranceId,
        ...accessibleBy(ability, "read").PaymentInsurance,
      },
      include: {
        provider: {
          include: {
            waystarInsuranceProvider: true,
          },
        },
      },
    });

    if (provider === null) {
      throw new Error("the paymentInsurance must have a provider");
    }

    return provider.electronicPaymentFormat;
  }

  if (paymentWorkersCompId !== null) {
    const { provider } = await prisma.paymentWorkersComp.findFirstOrThrow({
      where: {
        id: paymentWorkersCompId,
        ...accessibleBy(ability, "read").PaymentWorkersComp,
      },
      include: {
        provider: {
          include: {
            waystarProvider: true,
          },
        },
      },
    });

    if (provider === null) {
      throw new Error("the paymentWorkersComp must have a provider");
    }

    return provider.electronicPaymentFormat;
  }

  if (paymentLetterOfProtectionId !== null) {
    return "837I";
  }

  return null;
}
