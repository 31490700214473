import { z } from 'zod';
import { BillingPayerTypeSchema } from '../inputTypeSchemas/BillingPayerTypeSchema'
import { BillingClaimStatusSchema } from '../inputTypeSchemas/BillingClaimStatusSchema'

/////////////////////////////////////////
// CHARGE PAYER AMOUNT VIEW SCHEMA
/////////////////////////////////////////

export const ChargePayerAmountViewSchema = z.object({
  payerType: BillingPayerTypeSchema,
  billingClaimStatus: BillingClaimStatusSchema.nullish(),
  billingChargeId: z.string(),
  billingChargeMasterId: z.string(),
  chargeCode: z.string(),
  billingCaseId: z.string(),
  billingPayerId: z.string(),
  sequenceNumber: z.number().int(),
  payerName: z.string(),
  /**
   * PHI
   */
  policyNumber: z.string().nullish(),
  groupNumber: z.string().nullish(),
  billingClaimId: z.string().nullish(),
  claimGeneratedAt: z.coerce.date().nullish(),
  claimSubmittedAt: z.coerce.date().nullish(),
  patientId: z.string().nullish(),
  /**
   * PHI
   */
  patientName: z.string().nullish(),
  /**
   * PHI
   */
  patientMrn: z.string().nullish(),
  /**
   * PHI
   */
  patientDateOfBirth: z.coerce.date().nullish(),
  organizationId: z.string().nullish(),
  financialReference: z.number().int(),
  dateOfService: z.coerce.date().nullish(),
  providerId: z.string().nullish(),
  providerName: z.string().nullish(),
  billedAmount: z.number().int(),
  priorPayersAmount: z.number().int(),
  expectedAmount: z.number().int(),
  adjustmentAmount: z.number().int(),
  paymentAmount: z.number().int(),
  outstandingAmount: z.number().int(),
  transactionDate: z.coerce.date().nullish(),
})

export type ChargePayerAmountView = z.infer<typeof ChargePayerAmountViewSchema>

export default ChargePayerAmountViewSchema;
