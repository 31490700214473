import { z } from 'zod';

/////////////////////////////////////////
// BILLING CASE ATTACHMENT TYPE SCHEMA
/////////////////////////////////////////

export const BillingCaseAttachmentTypeSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  name: z.string(),
  label: z.string(),
})

export type BillingCaseAttachmentType = z.infer<typeof BillingCaseAttachmentTypeSchema>

export default BillingCaseAttachmentTypeSchema;
