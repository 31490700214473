import { z } from 'zod';
import { PatientMedicationSourceSchema } from '../inputTypeSchemas/PatientMedicationSourceSchema'
import { MedicationLocationSchema } from '../inputTypeSchemas/MedicationLocationSchema'

/////////////////////////////////////////
// PATIENT MEDICATION SCHEMA
/////////////////////////////////////////

export const PatientMedicationSchema = z.object({
  source: PatientMedicationSourceSchema,
  location: MedicationLocationSchema,
  id: z.string(),
  medication: z.string(),
  registrarId: z.string(),
  dosage: z.string().nullish(),
  uom: z.string().nullish(),
  frequency: z.string().nullish(),
  startedAt: z.coerce.date().nullish(),
  endedAt: z.coerce.date().nullish(),
  lastTakenAt: z.coerce.date().nullish(),
  patientId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type PatientMedication = z.infer<typeof PatientMedicationSchema>

export default PatientMedicationSchema;
