import { z } from 'zod';

/////////////////////////////////////////
// LINE SCHEMA
/////////////////////////////////////////

export const LineSchema = z.object({
  id: z.string(),
  clinicalEventId: z.string(),
  typeId: z.string(),
  gauge: z.number().int().nullish(),
  site: z.string(),
  /**
   * PHI
   */
  notes: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Line = z.infer<typeof LineSchema>

export default LineSchema;
