import { z } from 'zod';
import { ContractProcedureFlagSchema } from '../inputTypeSchemas/ContractProcedureFlagSchema'

/////////////////////////////////////////
// CONTRACT PROCEDURE METADATA SCHEMA
/////////////////////////////////////////

export const ContractProcedureMetadataSchema = z.object({
  /**
   * zero or more values not a relation
   */
  flags: ContractProcedureFlagSchema.array(),
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  contractId: z.string(),
  procedure: z.string(),
})

export type ContractProcedureMetadata = z.infer<typeof ContractProcedureMetadataSchema>

export default ContractProcedureMetadataSchema;
