import type { VariantProps } from "class-variance-authority";
import { DateTime } from "luxon";
import type { ForwardedRef } from "react";
import { forwardRef, useCallback, useMemo } from "react";
import type { FieldValues, Path } from "react-hook-form";
import { mergeRefs } from "react-merge-refs";
import { HTML_DATE_FORMAT, cn } from "../../utils";
import { BoundField, useFilteredFieldProps, type BoundFieldWithLegacyProps } from "./BoundField";
import type { InputProps, inputVariants } from "./Input";
import { Input } from "./Input";

type Ref = HTMLInputElement;

type DateInputProps = Omit<InputProps, "type" | "value" | "onChange" | "defaultValue"> &
  VariantProps<typeof inputVariants> & {
    value?: Date | null | undefined;
    onChange?: (value: Date | null) => void;
    timezone: string;
    error?: boolean;
  };

export const DateInput = forwardRef<Ref, DateInputProps>(
  ({ value, onChange, timezone, ...props }, ref) => {
    const valueAsString = useMemo(
      () =>
        value ? DateTime.fromJSDate(value, { zone: timezone }).toFormat(HTML_DATE_FORMAT) : "",
      [value, timezone]
    );

    const handleDateChange = useCallback(
      (data?: string) => {
        if (!onChange) return;

        const newValue = data
          ? DateTime.fromFormat(data, HTML_DATE_FORMAT, { zone: timezone }).toJSDate()
          : null;

        if (!value || value.valueOf() !== newValue?.valueOf()) {
          onChange(newValue);
        }
      },
      [value, timezone, onChange]
    );

    return (
      <Input
        {...props}
        ref={ref}
        type="date"
        value={valueAsString}
        onChange={(e) => handleDateChange(e.target.value)}
        buffered
      />
    );
  }
);
DateInput.displayName = "DateInput";

type DateInputFieldProps<
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
> = Omit<DateInputProps, "name"> & BoundFieldWithLegacyProps<TFieldValues, TFieldPath>;

const DateInputFieldInner = <
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
>(
  props: DateInputFieldProps<TFieldValues, TFieldPath>,
  ref?: ForwardedRef<Ref>
) => {
  const { fieldProps, inputProps } = useFilteredFieldProps<
    TFieldValues,
    TFieldPath,
    DateInputFieldProps<TFieldValues, TFieldPath>
  >(props);

  return (
    <BoundField
      {...fieldProps}
      labelProps={{ ...fieldProps.labelProps, size: inputProps.size }}
      className={cn("!flex-none", fieldProps.className)}
      render={({ field }) => (
        <DateInput {...inputProps} {...field} ref={mergeRefs([ref, field.ref])} />
      )}
    />
  );
};

export const DateInputField = forwardRef(DateInputFieldInner) as <
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
>(
  props: DateInputFieldProps<TFieldValues, TFieldPath> & {
    ref?: React.ForwardedRef<Ref>;
  }
) => ReturnType<typeof DateInputFieldInner>;
