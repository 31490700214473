import { z } from 'zod';
import { InsuredRelationshipSchema } from '../inputTypeSchemas/InsuredRelationshipSchema'
import { PreAuthStatusSchema } from '../inputTypeSchemas/PreAuthStatusSchema'
import { NetworkContractStatusSchema } from '../inputTypeSchemas/NetworkContractStatusSchema'

/////////////////////////////////////////
// PAYMENT INSURANCE SCHEMA
/////////////////////////////////////////

export const PaymentInsuranceSchema = z.object({
  relationship: InsuredRelationshipSchema,
  preAuthStatus: PreAuthStatusSchema,
  networkContract: NetworkContractStatusSchema.nullish(),
  id: z.string(),
  caseId: z.string(),
  /**
   * .omit([model, input]
   */
  name: z.string().nullish(),
  /**
   * PHI deprecated in favor of firstName, lastName, middleName
   */
  member: z.string(),
  /**
   * PHI Policy holder first name
   */
  firstName: z.string(),
  /**
   * PHI Policy holder last name
   */
  lastName: z.string(),
  /**
   * PHI Policy holder middle name
   */
  middleName: z.string().nullish(),
  /**
   * PHI
   */
  policyNumber: z.string(),
  groupNumber: z.string(),
  primary: z.boolean(),
  preAuthValidated: z.boolean(),
  preAuthRequired: z.boolean(),
  /**
   * PHI
   */
  authorizationNumber: z.string(),
  insuranceRepName: z.string(),
  insuranceRepPhone: z.string(),
  insuranceRepEmail: z.string(),
  /**
   * PHI
   */
  preCertNumber: z.string(),
  preCertDate: z.coerce.date().nullish(),
  preCertValidStart: z.coerce.date().nullish(),
  preCertValidEnd: z.coerce.date().nullish(),
  deductibleInDollars: z.number().nullish(),
  deductibleRemaining: z.number().nullish(),
  outOfPocketInDollars: z.number().nullish(),
  outOfPocketRemaining: z.number().nullish(),
  coinsurancePercentage: z.number().nullish(),
  facilityFeeInDollars: z.number().nullish(),
  anesthesiaFeeInDollars: z.number().nullish(),
  coPayInDollars: z.number().nullish(),
  notes: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  patientNoteId: z.string().nullish(),
  insuranceVerified: z.boolean(),
  insuranceVerifiedAt: z.coerce.date().nullish(),
  stediVerificationCount: z.number().int(),
  providerId: z.string().nullish(),
  /**
   * if an insurance provider is not listed
   */
  otherInsurerName: z.string(),
  /**
   * if an insurance provider is not listed
   */
  isInsuranceUnlisted: z.boolean(),
})

export type PaymentInsurance = z.infer<typeof PaymentInsuranceSchema>

export default PaymentInsuranceSchema;
