"use client";

import { useClerk } from "@clerk/nextjs";
import { Button } from "@procision-software/ui";
import { CircleHelpIcon } from "lucide-react";
import { useEffect, type ReactNode } from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { env } from "~/env.mjs";
import { useFeatureFlags } from "~/hooks/feature-flags";
import { trpc } from "~/utils/trpc";

export function ShowIntercomButton() {
  const { show } = useIntercom();

  return (
    <Button
      variant="primary"
      outline={false}
      asLink
      leftIcon={CircleHelpIcon}
      title="Contact support"
      onClick={show}
    />
  );
}

function IntercomSessionManager() {
  const { boot, shutdown } = useIntercom();
  const { user: clerkUser } = useClerk();

  const { data: user } = trpc.user.get.useQuery(
    { id: clerkUser?.id ?? "" },
    { enabled: !!clerkUser?.id }
  );

  const { data: intercomUserHash } = trpc.core.intercom.get.useQuery(
    {},
    { enabled: !!user?.email, onError: (err) => console.error(err) }
  );

  useEffect(() => {
    if (!user?.email || !intercomUserHash) return;
    boot({
      email: user.email,
      name: user.name ?? undefined,
      userHash: intercomUserHash,
      hideDefaultLauncher: true,
    });

    return () => {
      // Sign out of intercom when the user is no longer signed in (e.g. when this component unmounts)
      shutdown();
    };
  }, [user, intercomUserHash, boot, shutdown]);

  return <></>;
}

export function IntercomProviderWrapper({ children }: { children: ReactNode }) {
  const { enableIntercom } = useFeatureFlags();
  if (!enableIntercom) return children;

  // If intercom is not configured don't render the provider.
  if (!env.NEXT_PUBLIC_INTERCOM_APP_ID) return children;

  return (
    <IntercomProvider appId={env.NEXT_PUBLIC_INTERCOM_APP_ID}>
      {children}
      <IntercomSessionManager />
    </IntercomProvider>
  );
}
