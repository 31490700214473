"use client";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { cn } from "../../utils";
import {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  type ComponentPropsWithoutRef,
  type ElementRef,
  type ReactNode,
} from "react";

const TooltipProvider = TooltipPrimitive.Provider;

type TooltipProps = {
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  content: ReactNode;
  children: ReactNode;
} & Omit<ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>, "content" | "children">;

/**
 * A tooltip displays additional information when users hover over, focus on, or tap an element.
 * @param children - The element that triggers the tooltip.
 * @param content - The content to display in the tooltip.
 * @param side - The side of the trigger on which the tooltip should appear.
 *
 * @see https://radix-ui.com/primitives/docs/components/tooltip
 *
 * @example
 * ```
 * import { Icon, Tooltip } from "@procision-software/ui";
 * import { InfoIcon } from "lucide-react";
 * <Tooltip content={<div>Some tooltip content</div>}>
 *  <Icon icon={InfoIcon} size="md" />
 * </Tooltip>
 * ```
 */
const Tooltip = forwardRef<ElementRef<typeof TooltipPrimitive.Content>, TooltipProps>(
  (
    { defaultOpen, open: _open, onOpenChange: _onOpenChange, content, children, asChild, ...props },
    ref
  ) => {
    const [open, setOpen] = useState(defaultOpen ?? _open ?? false);
    useEffect(() => {
      if (defaultOpen !== undefined) setOpen(defaultOpen);
      if (_open !== undefined) setOpen(_open);
    }, [defaultOpen, _open]);

    const onOpenChange = useCallback(
      (open: boolean) => {
        setOpen(open);
        _onOpenChange?.(open);
      },
      [_onOpenChange]
    );

    return (
      <TooltipPrimitive.Root {...{ defaultOpen, open, onOpenChange }}>
        <TooltipPrimitive.Trigger
          onClick={asChild ? undefined : () => setOpen((prev) => !prev)}
          asChild={asChild}
        >
          {children}
        </TooltipPrimitive.Trigger>
        <TooltipContent ref={ref} {...props} sideOffset={props.sideOffset ?? 4}>
          {content}
          <TooltipPrimitive.Arrow width={12} height={6} className="fill-inverse" />
        </TooltipContent>
      </TooltipPrimitive.Root>
    );
  }
);
Tooltip.displayName = "Tooltip";

const TooltipContent = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      `animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out
      data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95
      data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2
      data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50
      overflow-hidden rounded-lg border border-inverse bg-inverse p-3 text-sm text-inverse shadow-md`,
      className
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipProvider };
