import type { z } from "zod";

/**
 * Creates a new Zod object schema containing only the specified keys from the original schema.
 *
 * @param schema - The Zod object schema to filter.
 * @param keys - An array of keys to pick from the schema.
 * @returns A new Zod object schema containing only the specified keys.
 *
 * @example
 * ```tsx
 * const schema = filterSchema(PatientFormData, ["firstName", "lastName"])
 * // schema is equivalent to z.object({ firstName: z.string(), lastName: z.string() })
 * ```
 */
export function filterSchema<
  T extends z.ZodRawShape,
  K extends readonly (keyof T)[] = readonly (keyof T)[],
>(schema: z.ZodObject<T>, keys: K): z.ZodObject<Pick<T, Extract<keyof T, K[number]>>> {
  return schema.pick(
    keys.reduce((acc, key) => ({ ...acc, [key]: true }), {} as Record<K[number], true>)
  );
}

/**
 * Helper type that creates the filterSchema return type containing only the specified keys from the original schema.
 *
 * @example
 * ```tsx
 * type NewType = FilterSchemaType<typeof PatientFormSchema, ["firstName", "lastName"]>;
 * // NewType is equivalent to { firstName: string, lastName: string }
 * ```
 */
export type FilterSchemaType<
  T extends z.ZodObject<z.ZodRawShape>,
  K extends readonly (keyof z.TypeOf<T>)[],
> = Pick<z.TypeOf<T>, K[number]>;
