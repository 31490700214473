import { OrganizationSwitcher, SignedIn, SignedOut, UserButton } from "@clerk/nextjs";
import { Button, IS_APPLE, cn, useDrawer } from "@procision-software/ui";
import { SearchIcon, UserCogIcon } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useEffect } from "react";
import logo from "~/assets/images/procision_logo_title.svg?resource";
import darkLogo from "~/assets/images/procision_logo_title_dark.svg?resource";
import { IntentShelf } from "~/concept/case-navigator/IntentShelf";
import { useFeatureFlags } from "~/hooks/feature-flags";
import { ThemeToggle } from "~/procision-core/providers/ThemeProvider";

export default function Header({
  nav: Nav,
  className,
  children,
}: {
  nav?: React.ComponentType;
  className?: string;
  children?: React.ReactNode;
}) {
  const { userButtonSettingsLink: hasUserButtonSettingsLink } = useFeatureFlags();
  const drawer = useDrawer();
  const triggerSearch = useCallback(() => {
    drawer.open(<IntentShelf search="" context="overview" />, { title: "Search" });
  }, [drawer]);
  const listener = useCallback(
    (event: KeyboardEvent) => {
      // Check if the desired key combination is pressed
      if ((event.ctrlKey || event.metaKey) && event.key === "k") {
        event.preventDefault(); // Prevent default browser behavior
        triggerSearch();
      }
    },
    [triggerSearch]
  );
  useEffect(() => {
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [listener]);

  return (
    <header
      className={cn(
        // TODO: something with the clerk.dev org/user choosers is blowing the height to 41px instead of 40px
        // set the min-height to 2.5625rem (41px) so it doesn't cause the page to bounce between first render
        // and when the clerk.dev org/user choosers are loaded
        "flex min-h-[2.5625rem] flex-wrap items-center gap-2 border-b border-primary px-2 py-1 text-sm print:hidden",
        className
      )}
    >
      <Image src={logo} alt="Procision" width={107} height={32} className="block h-5 dark:hidden" />
      <Image
        src={darkLogo}
        alt="Procision"
        width={107}
        height={32}
        className="hidden h-5 dark:block"
      />
      <SignedIn>
        {Nav && <Nav />}
        <Button
          variant="secondary"
          leftIcon={SearchIcon}
          title={`Search (${IS_APPLE ? "⌘" : "Ctrl+"}K)`}
          onClick={triggerSearch}
        />
        <div className="grow"></div> {/* spacer */}
        <div className="flex items-center gap-1">
          <OrganizationSwitcher
            // force the user to select their org unit because their `lastActive` will be from the previous org
            afterSelectOrganizationUrl={"/sign-in/organizational-unit-chooser"}
            hidePersonal={true}
            appearance={{
              elements: {
                rootBox: "flex items-center", // fixes vertical alignment with UserButton
                organizationSwitcherPopoverCard: "bg-primary text-primary border-primary",
                organizationSwitcherPreviewButton:
                  "bg-secondary hover:bg-brand-alt text-primary [&>*]:text-primary hover:text-white [&:hover>*]:text-white",
                organizationSwitcherTrigger: "max-w-[20ch]",
              },
            }}
          />
          <UserButton
            appearance={{
              elements: {
                userButtonPopoverCard: "bg-primary text-primary border-primary",
                // userPreview: "text-primary hover:text-white",
                // userPreviewSecondaryIdentifier: "text-secondary",
                userButtonPopoverActionButton:
                  "hover:bg-brand-alt bg-secondary text-primary [&>*]:text-primary hover:text-white [&:hover>*]:text-white",
                // userButtonPopoverActionButtonText: "text-secondary",
                // userButtonPopoverActionButtonIcon: "text-secondary",
              },
            }}
          >
            {hasUserButtonSettingsLink && (
              <UserButton.MenuItems>
                <UserButton.Link
                  label={`User Settings`}
                  labelIcon={<UserCogIcon />}
                  href={`/user/settings`}
                />
              </UserButton.MenuItems>
            )}
          </UserButton>
          {children}
          <ThemeToggle />
        </div>
      </SignedIn>
      <SignedOut>
        <div className="grow"></div> {/* spacer */}
        <Link href="/sign-in">Sign In</Link>
      </SignedOut>
    </header>
  );
}
