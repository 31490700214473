import { z } from "zod";
import { StorageBlobSchema, UserSchema } from "./generated";

export const StorageBlobInputSchema = StorageBlobSchema.pick({
  key: true,
  filename: true,
  contentType: true,
  byteSize: true,
  checksum: true,
}).extend({
  id: z.string().optional(),
  organizationId: z.string().optional(),
  userId: z.string().optional(),
});

export type StorageBlobInput = z.infer<typeof StorageBlobInputSchema>;

export const StorageBlobUserSchema = StorageBlobSchema.extend({
  user: UserSchema,
});

export type StorageBlobUser = z.infer<typeof StorageBlobUserSchema>;
