import { z } from 'zod';
import { AuditOperationSchema } from '../inputTypeSchemas/AuditOperationSchema'
import { LateralitySchema } from '../inputTypeSchemas/LateralitySchema'

/////////////////////////////////////////
// PROCEDURE VERSION SCHEMA
/////////////////////////////////////////

export const ProcedureVersionSchema = z.object({
  versionOperation: AuditOperationSchema,
  laterality: LateralitySchema.nullish(),
  versionId: z.number().int(),
  versionSourceId: z.string().nullish(),
  versionUserId: z.string().nullish(),
  versionTimestamp: z.coerce.date(),
  id: z.string().nullish(),
  cptCodeId: z.string().nullish(),
  caseId: z.string().nullish(),
  description: z.string().nullish(),
  possible: z.boolean().nullish(),
  createdAt: z.coerce.date().nullish(),
  updatedAt: z.coerce.date().nullish(),
})

export type ProcedureVersion = z.infer<typeof ProcedureVersionSchema>

export default ProcedureVersionSchema;
