import type { JobRoleType } from "@procision-software/database-zod";
import type {
  StaffSignatureAuthFormData,
  StaffSignatureFormData,
  StaffSignatureRecord,
  StaffSignatureUpdateFormData,
} from "@procision-software/mason";
import { useCallback, useState } from "react";
import { StaffSignatureCreateForm } from "./StaffSignatureCreateForm";
import { StaffSignatureSignForm } from "./StaffSignatureSignForm";
import { StaffSignatureUpdateForm } from "./StaffSignatureUpdateForm";
import { useStaffSignature, useStaffSignatureService } from "./useStaffSignature";

type StaffSignatureFormProps = {
  hideStaffSelect?: boolean;
  jobRoleType?: JobRoleType;
  onCancel: () => unknown;
  onSubmit: (data: StaffSignatureRecord) => unknown;
  staffId?: string;
  userId?: string;
  useUpdateForm?: boolean;
};

export function StaffSignatureForm({
  hideStaffSelect,
  jobRoleType,
  onCancel,
  onSubmit,
  staffId,
  userId,
  useUpdateForm,
}: StaffSignatureFormProps) {
  const isUpdateForm = useUpdateForm ?? false;
  const [internalStaffId, setInternalStaffId] = useState(staffId);
  const staffSignatureService = useStaffSignatureService();
  const staffSignature = useStaffSignature(
    { id: undefined, staffId: internalStaffId },
    { enabled: !!internalStaffId } // TODO: the api is leaking the first signature when both id and staffId are undefined
  ); // the type def for this is wonky, forces undefined on both inputs to keep TS happy

  const onAuth = useCallback(
    async (input: StaffSignatureAuthFormData) => {
      if (staffSignature?.data && (await staffSignatureService.auth(input))) {
        return await onSubmit(staffSignature.data);
      }
    },
    [onSubmit, staffSignature.data, staffSignatureService]
  );

  const onCreate = useCallback(
    async (input: StaffSignatureFormData) => {
      const value = await staffSignatureService.create(input);
      await staffSignature.refetch();

      if (isUpdateForm) {
        return await onSubmit(value);
      }
    },
    [isUpdateForm, onSubmit, staffSignature, staffSignatureService]
  );

  const onUpdate = useCallback(
    async (input: StaffSignatureUpdateFormData) => {
      const value = await staffSignatureService.update(input);
      await staffSignature.refetch();

      if (isUpdateForm) {
        return await onSubmit(value);
      }
    },
    [isUpdateForm, onSubmit, staffSignature, staffSignatureService]
  );

  const onChangeStaffId = useCallback(
    (id?: string | null) => setInternalStaffId(id ?? undefined), // we want to coerce null to undefined
    [setInternalStaffId]
  );

  return (
    <>
      {staffSignature.data ? (
        isUpdateForm ? (
          <StaffSignatureUpdateForm
            defaultValues={{
              id: staffSignature.data.id,
              staffId: internalStaffId,
              blob: staffSignature.data.blob,
            }}
            hideStaffSelect={hideStaffSelect}
            jobRoleType={jobRoleType}
            onCancel={onCancel}
            onChangeStaff={onChangeStaffId}
            onSubmit={onUpdate}
          />
        ) : (
          <StaffSignatureSignForm
            blob={staffSignature.data.blob}
            defaultValues={{ id: staffSignature.data.id }}
            hideStaffSelect={hideStaffSelect}
            isPinRequired={staffSignature.data.staff.userId !== userId}
            jobRoleType={jobRoleType}
            onCancel={onCancel}
            onChangeStaff={onChangeStaffId}
            onSubmit={onAuth}
            staffId={internalStaffId}
          />
        )
      ) : (
        <StaffSignatureCreateForm
          defaultValues={{ staffId: internalStaffId }}
          hideStaffSelect={hideStaffSelect}
          jobRoleType={jobRoleType}
          onCancel={onCancel}
          onChangeStaff={onChangeStaffId}
          onSubmit={onCreate}
          userId={userId}
        />
      )}
    </>
  );
}
