import { z } from 'zod';

/////////////////////////////////////////
// PAYMENT WORKERS COMP ATTACHMENT TYPE SCHEMA
/////////////////////////////////////////

export const PaymentWorkersCompAttachmentTypeSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  name: z.string(),
  label: z.string(),
})

export type PaymentWorkersCompAttachmentType = z.infer<typeof PaymentWorkersCompAttachmentTypeSchema>

export default PaymentWorkersCompAttachmentTypeSchema;
