import { z } from 'zod';

/////////////////////////////////////////
// QUESTIONNAIRE RESPONSE SCHEMA
/////////////////////////////////////////

export const QuestionnaireResponseSchema = z.object({
  id: z.string(),
  encounterId: z.string(),
  questionnaireId: z.string(),
  questionnaireVersion: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type QuestionnaireResponse = z.infer<typeof QuestionnaireResponseSchema>

export default QuestionnaireResponseSchema;
