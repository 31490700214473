import { z } from 'zod';
import { AuditOperationSchema } from '../inputTypeSchemas/AuditOperationSchema'

/////////////////////////////////////////
// PREFERENCE CARD VERSION SCHEMA
/////////////////////////////////////////

export const PreferenceCardVersionSchema = z.object({
  versionOperation: AuditOperationSchema,
  versionId: z.number().int(),
  versionSourceId: z.string().nullish(),
  versionUserId: z.string().nullish(),
  versionTimestamp: z.coerce.date(),
  id: z.string().nullish(),
  title: z.string().nullish(),
  providerId: z.string().nullish(),
  positioning: z.string().nullish(),
  prep: z.string().nullish(),
  drapes: z.string().nullish(),
  notes: z.string().nullish(),
  gloves: z.string().nullish(),
  createdAt: z.coerce.date().nullish(),
  updatedAt: z.coerce.date().nullish(),
  organizationId: z.string().nullish(),
  deepUpdatedAt: z.coerce.date().nullish(),
})

export type PreferenceCardVersion = z.infer<typeof PreferenceCardVersionSchema>

export default PreferenceCardVersionSchema;
