import { z } from 'zod';

/////////////////////////////////////////
// STAFF SIGNATURE SCHEMA
/////////////////////////////////////////

export const StaffSignatureSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  staffId: z.string(),
  blob: z.string(),
  pin: z.string().length(6),
})

export type StaffSignature = z.infer<typeof StaffSignatureSchema>

export default StaffSignatureSchema;
