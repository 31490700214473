import { z } from 'zod';

/////////////////////////////////////////
// ORDER GROUP SCHEMA
/////////////////////////////////////////

export const OrderGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  sequenceNumber: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  organizationId: z.string(),
})

export type OrderGroup = z.infer<typeof OrderGroupSchema>

export default OrderGroupSchema;
