import {
  JobRoleSchema,
  JobRoleTypeSchema,
  SignatureSchema,
} from "@procision-software/database-zod";
import { z } from "zod";
import { PatientSignatureRecordSchema } from "../patient-signature";
import { StaffSignatureRecordSchema } from "../staff-signature";

export type SignatureRecord = z.infer<typeof SignatureRecordSchema>;
export const SignatureRecordSchema = SignatureSchema.extend({
  appliedJobRole: JobRoleSchema.nullable(),
  appliedPatientSignature: PatientSignatureRecordSchema.nullable(),
  appliedStaffSignature: StaffSignatureRecordSchema.nullable(),
});

export type SignatureFindFilter = z.infer<typeof SignatureFindFilterSchema>;
export const SignatureFindFilterSchema = z.union([
  z.object({
    id: z.string(),
    clinicalEvents: z.undefined(),
    encounterDocuments: z.undefined(),
    encounters: z.undefined(),
    encounterWorkflows: z.undefined(),
    orders: z.undefined(),
    requiredJobRoleType: z.undefined(),
    encounterClosures: z.undefined(),
  }),
  z.object({
    id: z.undefined(),
    clinicalEvents: z.array(z.string()).nullish(),
    encounterDocuments: z.array(z.string()).nullish(),
    encounters: z.array(z.string()).nullish(),
    encounterWorkflows: z.array(z.string()).nullish(),
    orders: z.array(z.string()).nullish(),
    requiredJobRoleType: JobRoleTypeSchema.nullish(),
    encounterClosures: z.array(z.string()).nullish(),
  }),
]);

export type SignatureFormData = z.infer<typeof SignatureFormDataSchema>;
export const SignatureFormDataSchema = SignatureRecordSchema.pick({
  requiredJobRoleType: true,
  appliedJobRoleId: true,
  appliedPatientSignatureId: true,
  appliedStaffSignatureId: true,
}).extend({
  clinicalEvents: z.array(z.string()).nullish(),
  encounterDocuments: z.array(z.string()).nullish(),
  encounters: z.array(z.string()).nullish(),
  encounterWorkflows: z.array(z.string()).nullish(),
  orders: z.array(z.string()).nullish(),
  encounterClosures: z.array(z.string()).nullish(),
});
