import type { CCRegistry, KnownCodeableConcept } from "@procision-software/database";
import { z } from "zod";

// copy/paste out of prisma
export const CodeableConceptSchema = z.object({
  id: z.string(),
  code: z.string(),
  display: z.string(),
  description: z.string(),
  key: z.boolean(),
  codeableConceptId: z.string().nullable(),
  activeFrom: z.date(),
  activeTo: z.date().nullable(),
});

export type CodeableConcept = z.infer<typeof CodeableConceptSchema>;

export type CodeableConceptId = string;
export type CodeableConceptPath = string[];
export const CodeableConceptPathAsArray = z.array(z.string());
export const CodeableConceptPathSchema = z
  .object({
    $path: z.string(),
  })
  .transform(({ $path }) => $path.split(".") as CodeableConceptPath);

// Helper type to extract the $path value from an object
type PathOf<T> = T extends { $path: infer P } ? P : never;

// Helper type to extract the last segment of a dot-separated string
type LastSegment<P extends string> = P extends `${infer _Rest}.${infer Last}`
  ? LastSegment<Last>
  : P;

// Type to navigate to the object at the specified path
type GetAtPath<T, Path extends string> = Path extends `${infer Head}.${infer Rest}`
  ? Head extends keyof T
    ? GetAtPath<T[Head], Rest>
    : never
  : Path extends keyof T
    ? T[Path]
    : never;

// Type to extract the $path values of the immediate children, excluding the $path key
type ChildrenPaths<T> = {
  [K in keyof T]: K extends "$path" ? never : PathOf<T[K]>;
}[keyof T];

// Type to extract the last segments of a union of strings
type LastSegmentOfUnion<U> = U extends string ? LastSegment<U> : never;

// registry paths contains the full paths to children of a node, like RegistryPaths<"UOM"> to get "UOM.CC" | "UOM.ML" | ...
export type RegistryPaths<Path extends KnownCodeableConcept> = ChildrenPaths<
  GetAtPath<typeof CCRegistry, Path>
>;

// Registry values can give you the children at a path like RegistryValues<"UOM"> to get "CC" | "ML" | ...
export type RegistryValues<Path extends KnownCodeableConcept> = LastSegmentOfUnion<
  RegistryPaths<Path>
>;
