import { CaseStatus } from "@procision-software/database";
import { DateTime } from "luxon";
import { useRouter } from "~/hooks/useRouter";
import CreateCaseButton from "~/components/practice/case/CreateCaseButton";
import { trpc } from "~/utils/trpc";
import NavBar from "./NavBar";
import { useFeatureFlags } from "~/hooks/feature-flags";

type NavItem = {
  name: string;
  href: string;
  count?: number;
  children?: React.ReactNode;
};

export default function AscNavBar() {
  const { billing, scheduling, reports, emrFeatures, materialsDemo } = useFeatureFlags();
  const router = useRouter();
  const pending = trpc.case.count.useQuery({
    filters: { status: [CaseStatus.Requested] },
  });

  const statusTrackerOverview = trpc.statusTracker.overviewCount.useQuery({
    filter: {
      date: {
        start: DateTime.utc().startOf("day").toJSDate(),
        end: DateTime.utc().plus({ week: 1 }).endOf("day").toJSDate(),
      },
      status: ["incomplete"],
    },
  });

  const cancelled = trpc.scheduling.reports.case.cancellations.useQuery({
    page: 0,
    perPage: 0,
    filters: {
      dos: {
        start: DateTime.now().minus({ week: 1 }).endOf("day").toJSDate(),
        end: DateTime.now().plus({ week: 1 }).endOf("day").toJSDate(),
      },
    },
  });

  const navItems: NavItem[] = [
    ...(((scheduling && [
      { name: "Pending", href: "/facility/cases/pending", count: pending.data?._count.id },
      { name: "Canceled", href: "/facility/cases/canceled", count: cancelled.data?.pagination.all },
      {
        name: "Cases",
        href: "/facility/cases",
        children: (
          <CreateCaseButton
            variant="primary"
            outline={false}
            asLink
            size="sm"
            onCreated={(id) => router.push(`/facility/cases/${id}`)}
          />
        ),
      },
      { name: "Calendar", href: "/facility/calendar" },
      {
        name: "Status Tracker",
        href: "/facility/status-tracker",
        count: statusTrackerOverview.data,
      },
      { name: "Patients", href: "/patients" },
    ]) ||
      []) as NavItem[]),
    ...(((materialsDemo && [{ name: "Materials", href: "/mat-demo" }]) || []) as NavItem[]),
    ...(((billing && [{ name: "Billing", href: "/billing/cases" }]) || []) as NavItem[]),
    ...(((reports && [{ name: "Reports", href: "/reports" }]) || []) as NavItem[]),
    ...(((emrFeatures && [{ name: "Charts", href: "/emr/encounters" }]) || []) as NavItem[]),
    { name: "Admin", href: "/facility/admin/center-info" },
  ] as NavItem[];

  return <NavBar navItems={navItems} />;
}
