"use client";

import { usePathname, useSearchParams, useParams } from "next/navigation";
import type { Url } from "url";
import { noop } from "lodash";
import { useRouter as useNextRouter } from "next/router"; // Pages Router
import { useRouter as useAppRouter } from "next/navigation"; // App Router
import type { ParsedUrlQueryInput } from "querystring";
import { useMemo } from "react";

type RouterUrlObject =
  | {
      pathname?: string;
      query?: Record<string, string | string[] | undefined | null | number> | ParsedUrlQueryInput;
    }
  | string;
// TransitionOptions (the third parameter) can't be exported from next/router here
// so we'll dupe the Next 14.2 version of it here
type RouterTransitionOptions = {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
};
// signature of push and replace
type PortableRouterMethod = (
  url: RouterUrlObject,
  as?: Url | undefined,
  options?: RouterTransitionOptions
) => Promise<boolean>;

const fallbackPush = (url: RouterUrlObject) => {
  if (typeof url === "string") {
    window.location.href = url;
  } else {
    throw new Error("Cannot push a complex URL without a router.");
  }
};

const fallbackBack = () => window.history.back();

// Compatibility hook for using either next/router or next/navigation
export function useRouter() {
  const pagesRouter = (() => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useNextRouter();
    } catch (e) {
      return undefined;
    }
  })();
  const appRouter = (() => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useAppRouter();
    } catch (e) {
      return undefined;
    }
  })();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const params = useParams();
  const query = useMemo(() => Object.fromEntries(searchParams ?? []), [searchParams]);
  return {
    isReady: true,
    basePath: "/",
    push: (appRouter?.push.bind(appRouter) ??
      pagesRouter?.push.bind(pagesRouter) ??
      fallbackPush) as PortableRouterMethod,
    replace: (appRouter?.replace.bind(appRouter) ??
      pagesRouter?.replace.bind(pagesRouter) ??
      fallbackPush) as PortableRouterMethod,
    pathname: pagesRouter?.pathname ?? pathname ?? "",
    query: {
      ...params, // these are routing params like [encounterId]
      ...pagesRouter?.query, // these are both routing params AND query params
      ...query, // these are only query params when using app router
    },
    params: params,
    asPath:
      pagesRouter?.asPath ??
      pathname + (searchParams ? "?" + new URLSearchParams(searchParams).toString() : ""),
    back: appRouter?.back.bind(appRouter) ?? pagesRouter?.back ?? fallbackBack,
    route: pagesRouter?.route ?? pathname,
    reload: pagesRouter?.reload ?? noop,
  };
}
