import { OrganizationType } from "@procision-software/database-zod";

export function typeFromString(type: string): OrganizationType {
  switch (type) {
    case "asc":
      return OrganizationType.FACILITY;
    case "practice":
      return OrganizationType.PRACTICE;
    default:
      throw new Error(`Invalid organization type: ${type}`);
  }
}
