import ProgressBar from "@badrap/bar-of-progress";
import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  useOrganization,
  useUser,
} from "@clerk/nextjs";
import { datadogRum } from "@datadog/browser-rum";
import { getUserRoles, isAsc } from "@procision-software/auth";
import {
  AppContextProvider,
  ConfirmProvider,
  DrawerProvider,
  ErrorScreen,
  LoadScreen,
  TooltipProvider,
} from "@procision-software/ui";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { appWithTranslation } from "next-i18next";
import { type AppType } from "next/app";
import Image from "next/image";
import Router, { useRouter } from "next/router";
import { Suspense, type ReactElement, type ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tabs from "~/assets/images/tabs.svg?resource";
import { RedirectToOrganizationChooser } from "~/components/auth/RedirectToOrganizationChooser";
import { RedirectToOrganizationalUnitChooser } from "~/components/auth/RedirectToOrganizationalUnitChooser";
import { WithOrganization } from "~/components/auth/WithOrganization";
import { WithOrganizationalUnit } from "~/components/auth/WithOrganizationalUnit";
import { WithoutOrganization } from "~/components/auth/WithoutOrganization";
import { WithoutOrganizationalUnit } from "~/components/auth/WithoutOrganizationalUnit";
import { AppLayout } from "~/components/layouts/AppLayout";
import MarketingLayout from "~/components/layouts/marketing";
import { NavigatorProvider } from "~/concept/case-navigator/NavigatorProvider";
import { SignatureProvider } from "~/concept/signature/SignatureProvider";
import { env } from "~/env.mjs";
import { useFeatureFlags } from "~/hooks/feature-flags";
import { IntercomProviderWrapper } from "~/procision-core/providers/IntercomProviderWrapper";
import { MessagesProvider } from "~/procision-core/providers/MessagesProvider";
import { ThemeProvider } from "~/procision-core/providers/ThemeProvider";
import "~/styles/globals.css";
import { escapeValve } from "~/utils/redirect-escape-valve";
import { trpc } from "~/utils/trpc";

escapeValve(); // if we enter a redirect loop, this will scorched-earth the browser state in an attempt to break it

datadogRum.init({
  applicationId: env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID,
  clientToken: env.NEXT_PUBLIC_DD_RUM_CLIENT_ID,
  site: "datadoghq.com",
  service: "webapp",
  env: env.NEXT_PUBLIC_INFRA_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100, // do capture console output & page meta
  sessionReplaySampleRate: 0, // don't capture what the user is doing
  trackUserInteractions: true, // clicks
  trackResources: true, // assets
  trackLongTasks: true, // performance
  defaultPrivacyLevel: "mask", // obscures all text: form field and HTML
  allowedTracingUrls: [
    (url) => {
      // expect url to be a string on the same domain with a path prefaced with /api/ . Return true if it is, false otherwise.
      return url.startsWith(`${window.location.origin}/api/`);
    },
  ],
});

const progress = new ProgressBar({
  size: 3,
  color: "#2563eb",
  className: "z-50",
  delay: 100,
});

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish);

/**
 * List pages you want to be publicly, or leave empty if every page requires authentication.
 * Use this naming strategy:
 *  "/"              for pages/index.js
 *  "/foo"           for pages/foo/index.js
 *  "/foo/bar"       for pages/foo/bar.js
 *  "/foo/[...bar]"  for pages/foo/[...bar].js
 */
const PUBLIC_ROUTES = [
  "/forgot-password/[[...index]]",
  "/reset-password/[[...index]]",
  "/sign-in/[[...index]]",
  "/sign-in/organization-chooser",
  "/sign-in/organizational-unit-chooser",
  "/sso-callback",
  "/pat",
  "/pat/[facilitySlug]",
  "/pat/[facilitySlug]/[form]",
];

const AppContextWrapper = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { user: clerkUser } = useUser();
  const { organization: clerkOrganization } = useOrganization();
  const roles = getUserRoles(clerkUser, clerkOrganization);
  const perspective = roles.includes("platform:admin")
    ? "platform"
    : isAsc(roles)
      ? "asc"
      : "practice";

  const context = trpc.appcontext.current.useQuery(
    {},
    { enabled: !!clerkUser && !!clerkOrganization }
  );

  // // TODO: Should we move redirect logic here to remove the need for the With(out) wrappers and simplify the tree?
  // if (!clerkUser) return <RedirectToSignIn />;
  // if (!clerkOrganization) return <RedirectToOrganizationChooser />;

  if (context.isLoading) return null; // Intentionally not showing a loading screen here
  if (context.isError)
    return <ErrorScreen error={context.error?.message} onBackClick={router.back} />;
  if (!context.data?.user) {
    return (
      <ErrorScreen
        error={"Sorry, there was an error retrieving your user."}
        onBackClick={router.back}
      />
    );
  }
  if (clerkUser && !context.data.facility)
    return router.push("/sign-in/organizational-unit-chooser");

  return (
    <AppContextProvider
      context={{
        ...context.data,
        user: context.data.user,
        roles,
        perspective,
        refresh: () => void context.refetch(),
      }}
    >
      <NavigatorProvider>{children}</NavigatorProvider>
    </AppContextProvider>
  );
};

const MaintenancePage = () => (
  <div className="flex items-center justify-center">
    <div className="flex items-center gap-12">
      <Image src={tabs} width={415} height={495} alt="Page not found" />
      <div className="flex flex-col items-center justify-center gap-12">
        <h1>Maintenance Mode</h1>
        <p>Sorry, we are currently down for maintenance</p>
      </div>
    </div>
  </div>
);

const MyApp: AppType = ({ Component, pageProps: { ...pageProps } }) => {
  const { pathname, asPath, back } = useRouter();
  const isPublicPage =
    PUBLIC_ROUTES.includes(pathname) || PUBLIC_ROUTES.some((route) => pathname.startsWith(route));
  const flags = useFeatureFlags();
  const { darkMode, maintenanceMode } = flags;

  const getLayout: (page: ReactElement) => ReactElement =
    "getLayout" in Component
      ? (Component.getLayout as (page: ReactElement) => ReactElement)
      : (page: ReactElement) => <MarketingLayout>{page}</MarketingLayout>;

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ThemeProvider forcedTheme={darkMode ? undefined : "light"} defaultTheme="light">
          <TooltipProvider>
            <ClerkProvider>
              {maintenanceMode && (
                <MarketingLayout>
                  <MaintenancePage />
                </MarketingLayout>
              )}
              {!maintenanceMode && (
                <ConfirmProvider>
                  {isPublicPage ? (
                    <DrawerProvider key={`pub-drawer-${asPath}`}>
                      {getLayout(<Component {...pageProps} />)}
                    </DrawerProvider>
                  ) : (
                    <>
                      <SignedIn>
                        <IntercomProviderWrapper>
                          <WithOrganization>
                            <WithOrganizationalUnit>
                              <AppContextWrapper>
                                <ErrorBoundary
                                  key={`err-${asPath}`} // Reset the error boundary when the route changes
                                  onReset={reset}
                                  fallback={
                                    <AppLayout>
                                      <ErrorScreen
                                        error="An error occurred while rendering this page. Please try again."
                                        onBackClick={back}
                                      />
                                    </AppLayout>
                                  }
                                >
                                  <Suspense fallback={<LoadScreen />}>
                                    <SignatureProvider key={`signature-${asPath}`}>
                                      <DrawerProvider key={`drawer-${asPath}`}>
                                        <MessagesProvider>
                                          {getLayout(<Component {...pageProps} />)}
                                        </MessagesProvider>
                                      </DrawerProvider>
                                    </SignatureProvider>
                                  </Suspense>
                                </ErrorBoundary>
                              </AppContextWrapper>
                            </WithOrganizationalUnit>
                            <WithoutOrganizationalUnit>
                              <RedirectToOrganizationalUnitChooser />
                            </WithoutOrganizationalUnit>
                          </WithOrganization>
                          <WithoutOrganization>
                            <RedirectToOrganizationChooser />
                          </WithoutOrganization>
                        </IntercomProviderWrapper>
                      </SignedIn>
                      <SignedOut>
                        <ErrorBoundary
                          key={`err-${asPath}`} // Reset the error boundary when the route changes
                          onReset={reset}
                          fallback={
                            <ErrorScreen
                              error="An error occurred while rendering this page. Please try again."
                              onBackClick={back}
                            />
                          }
                        >
                          <Suspense fallback={<LoadScreen />}>
                            <DrawerProvider key={`pub-drawer-${asPath}`}>
                              {isPublicPage && <Component {...pageProps} />}
                              {!isPublicPage && <RedirectToSignIn />}
                            </DrawerProvider>
                          </Suspense>
                        </ErrorBoundary>
                      </SignedOut>
                    </>
                  )}
                  <ToastContainer />
                </ConfirmProvider>
              )}
            </ClerkProvider>
          </TooltipProvider>
        </ThemeProvider>
      )}
    </QueryErrorResetBoundary>
  );
};

export default withLDProvider({
  clientSideID: env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
})(trpc.withTRPC(appWithTranslation(MyApp)));
