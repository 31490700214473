import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon, XIcon } from "@heroicons/react/solid";
import { cn } from "@procision-software/ui";
import React, { forwardRef, useCallback, useMemo, useState, type Ref } from "react";
import { mergeRefs } from "react-merge-refs";
import { usePopper } from "react-popper";
import Spinner from "../Spinner";

/**
 * @deprecated Use UI package components instead
 */
export type SelectMultipleProps = {
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  id?: string;
  loading?: boolean;
  name?: string;
  onChange?: (values: string[]) => void;
  options: { id: string; label: string; bgColor?: string }[] | Record<string, string>;
  placeholder?: string;
  value?: string[];
};

const SelectMultipleInner = (
  {
    className,
    disabled,
    readOnly,
    error,
    icon: Icon,
    loading,
    onChange,
    options,
    placeholder,
    value,
    ...props
  }: SelectMultipleProps,
  ref?: Ref<HTMLButtonElement>
) => {
  const managedOptions = useMemo(() => {
    return Array.isArray(options)
      ? options
      : Object.entries(options).map(
          ([id, label]) => ({ id, label }) as { id: string; label: string; bgColor?: string }
        );
  }, [options]);

  const removeSelection = useCallback(
    (id: string) => {
      onChange?.(value ? value.filter((v) => v !== id) : []);
    },
    [onChange, value]
  );

  const selections = useMemo(
    () =>
      value ? managedOptions.filter((opt) => !!opt.id && value?.find((v) => v === opt.id)) : [],
    [managedOptions, value]
  );

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
  });

  return (
    <Listbox
      as="div"
      className="group relative w-full"
      value={value}
      onChange={onChange}
      disabled={disabled || readOnly}
      multiple
    >
      <Listbox.Button
        {...props}
        ref={ref ? mergeRefs([ref, setReferenceElement]) : setReferenceElement}
        className={cn(
          "input relative w-full cursor-default p-1.5 pr-8 group-focus-within:ring-1 group-focus-within:ring-blue-600",
          disabled && "cursor-not-allowed opacity-50",
          error && "input-error",
          Icon && "pl-8",
          className
        )}
      >
        {Icon && (
          <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
            <Icon className="h-5 w-5" />
          </span>
        )}
        {!selections?.length ? (
          <span className={cn("block truncate p-1", !error && "text-gray-400")}>
            {placeholder ?? "-"}
          </span>
        ) : (
          <span className="flex flex-row flex-wrap gap-1">
            {selections?.map((selection) => (
              <div
                key={selection.id}
                className={cn(
                  "flex flex-row flex-nowrap items-center whitespace-nowrap rounded-lg border border-gray-500 bg-blue-50 p-[3px] pl-1",
                  selection.bgColor
                )}
              >
                {selection.label}
                {!disabled && (
                  <div
                    className="cursor-pointer px-0.5"
                    onClick={(e) => {
                      e.preventDefault();
                      removeSelection(selection.id);
                    }}
                    onKeyUp={(e) => {
                      e.preventDefault();
                      removeSelection(selection.id);
                    }}
                  >
                    <XIcon className="h-3 w-3" />
                    <div className="sr-only">Remove {selection.label}</div>
                  </div>
                )}
              </div>
            ))}
          </span>
        )}
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1.5">
          {loading && <Spinner className="absolute left-0" />}
          <ChevronDownIcon
            className="h-6 w-6 pt-0.5 text-gray-400 dark:text-white"
            aria-hidden="true"
          />
        </span>
      </Listbox.Button>
      <Listbox.Options
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="list-popup"
      >
        {options.length === 0 ? (
          <Listbox.Option
            value={null}
            disabled={true}
            className="relative cursor-not-allowed p-2.5 pl-10 pr-4 text-sm italic text-gray-500"
          >
            None available
          </Listbox.Option>
        ) : (
          managedOptions.map((item) => (
            <Listbox.Option
              key={item.id}
              value={item.id}
              className={({ active }) =>
                cn(
                  "relative p-2.5 pl-10 pr-4 text-sm text-gray-700",
                  active && "bg-blue-600 text-white"
                )
              }
            >
              {({ selected, active }) => (
                <>
                  <span>{item.label ?? "-"}</span>
                  {selected && (
                    <span
                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                        active ? "text-white" : "font-bold text-blue-700"
                      }`}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Listbox.Option>
          ))
        )}
      </Listbox.Options>
    </Listbox>
  );
};

/**
 * @deprecated Use UI package components instead
 */
export const SelectMultiple = forwardRef(SelectMultipleInner);
