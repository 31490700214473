import { z } from 'zod';
import { IntervalUnitSchema } from '../inputTypeSchemas/IntervalUnitSchema'
import { DaysOfWeekSchema } from '../inputTypeSchemas/DaysOfWeekSchema'
import { RecurrenceMethodSchema } from '../inputTypeSchemas/RecurrenceMethodSchema'

/////////////////////////////////////////
// TIME PERIOD SCHEDULE SCHEMA
/////////////////////////////////////////

export const TimePeriodScheduleSchema = z.object({
  unit: IntervalUnitSchema.nullish(),
  dayOfWeek: DaysOfWeekSchema.nullish(),
  recurrenceMethod: RecurrenceMethodSchema,
  id: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  interval: z.number().int().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  weekOfMonth: z.number().int().nullish(),
})

export type TimePeriodSchedule = z.infer<typeof TimePeriodScheduleSchema>

export default TimePeriodScheduleSchema;
