import { z } from 'zod';
import { AuditOperationSchema } from '../inputTypeSchemas/AuditOperationSchema'
import { InsuredRelationshipSchema } from '../inputTypeSchemas/InsuredRelationshipSchema'
import { PreAuthStatusSchema } from '../inputTypeSchemas/PreAuthStatusSchema'

/////////////////////////////////////////
// PAYMENT INSURANCE VERSION SCHEMA
/////////////////////////////////////////

export const PaymentInsuranceVersionSchema = z.object({
  versionOperation: AuditOperationSchema,
  relationship: InsuredRelationshipSchema.nullish(),
  preAuthStatus: PreAuthStatusSchema.nullish(),
  versionId: z.number().int(),
  versionSourceId: z.string().nullish(),
  versionUserId: z.string().nullish(),
  versionTimestamp: z.coerce.date(),
  id: z.string().nullish(),
  caseId: z.string().nullish(),
  name: z.string().nullish(),
  member: z.string().nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  middleName: z.string().nullish(),
  policyNumber: z.string().nullish(),
  groupNumber: z.string().nullish(),
  primary: z.boolean().nullish(),
  preAuthValidated: z.boolean().nullish(),
  preAuthRequired: z.boolean().nullish(),
  authorizationNumber: z.string().nullish(),
  insuranceRepName: z.string().nullish(),
  insuranceRepPhone: z.string().nullish(),
  insuranceRepEmail: z.string().nullish(),
  preCertNumber: z.string().nullish(),
  preCertDate: z.coerce.date().nullish(),
  preCertValidStart: z.coerce.date().nullish(),
  preCertValidEnd: z.coerce.date().nullish(),
  deductibleInDollars: z.number().nullish(),
  deductibleRemaining: z.number().nullish(),
  outOfPocketInDollars: z.number().nullish(),
  outOfPocketRemaining: z.number().nullish(),
  coinsurancePercentage: z.number().nullish(),
  facilityFeeInDollars: z.number().nullish(),
  anesthesiaFeeInDollars: z.number().nullish(),
  coPayInDollars: z.number().nullish(),
  notes: z.string().nullish(),
  createdAt: z.coerce.date().nullish(),
  updatedAt: z.coerce.date().nullish(),
  patientNoteId: z.string().nullish(),
  insuranceVerified: z.boolean().nullish(),
  insuranceVerifiedAt: z.coerce.date().nullish(),
  stediVerificationCount: z.number().int().nullish(),
  networkContract: z.string().nullish(),
  providerId: z.string().nullish(),
  otherInsurerName: z.string().nullish(),
  isInsuranceUnlisted: z.boolean().nullish(),
})

export type PaymentInsuranceVersion = z.infer<typeof PaymentInsuranceVersionSchema>

export default PaymentInsuranceVersionSchema;
