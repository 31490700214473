import { z } from 'zod';

/////////////////////////////////////////
// BILLING CASE ATTACHMENT SCHEMA
/////////////////////////////////////////

export const BillingCaseAttachmentSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  billingCaseAttachmentTypeId: z.string(),
  billingCaseId: z.string(),
  storageBlobId: z.string(),
  name: z.string(),
})

export type BillingCaseAttachment = z.infer<typeof BillingCaseAttachmentSchema>

export default BillingCaseAttachmentSchema;
