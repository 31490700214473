import { z } from 'zod';

/////////////////////////////////////////
// ENCOUNTER CLOSURE SCHEMA
/////////////////////////////////////////

export const EncounterClosureSchema = z.object({
  id: z.string().cuid(),
  encounterId: z.string(),
  closedAt: z.coerce.date(),
  closedById: z.string(),
  chartValid: z.boolean(),
  forceClosed: z.boolean(),
  isIncomplete: z.boolean(),
  hasVariance: z.boolean(),
  hasIncidents: z.boolean(),
  printed: z.boolean(),
})

export type EncounterClosure = z.infer<typeof EncounterClosureSchema>

export default EncounterClosureSchema;
