import { z } from "zod";
import { PaymentLetterProtectionSchema } from "./generated";

export const PaymentLetterProtectionInputSchema = PaymentLetterProtectionSchema.partial({
  createdAt: true,
  updatedAt: true,
}).extend({
  id: z.string().optional(),
});

export type PaymentLetterProtectionInput = z.infer<typeof PaymentLetterProtectionInputSchema>;
