import { cva } from "class-variance-authority";
import { forwardRef, type FieldsetHTMLAttributes, type PropsWithChildren } from "react";
import { cn } from "../../utils";

type Ref = HTMLFieldSetElement;
type ElementProps = FieldsetHTMLAttributes<Ref>;

type FieldsetVariant = "default" | "actions";
type FieldsetDirection = "horizontal" | "vertical";

export const fieldsetVariants = cva(
  [
    "relative flex flex-wrap gap-x-4 gap-y-1.5 empty:hidden",
    "last:[&>.field]:flex-auto", // we always want the last field to take up remaining space
  ],
  {
    variants: {
      variant: {
        default: "",
        actions: "sm:justify-end",
      } satisfies Record<FieldsetVariant, string>,
      direction: {
        horizontal: "flex-col sm:flex-row sm:items-start",
        vertical: "flex-col items-start",
      } satisfies Record<FieldsetDirection, string>,
    },
    defaultVariants: {
      variant: "default",
      direction: "horizontal",
    },
  }
);

type FieldsetProps = ElementProps & {
  variant?: FieldsetVariant;
  direction?: FieldsetDirection;
};

export const Fieldset = forwardRef<Ref, PropsWithChildren<FieldsetProps>>(
  ({ variant, direction, className, children, ...props }, ref) => {
    return (
      <fieldset
        {...props}
        ref={ref}
        className={cn(fieldsetVariants({ variant, direction }), className)}
      >
        {children}
      </fieldset>
    );
  }
);
Fieldset.displayName = "FormGroup";
