import { z } from 'zod';

/////////////////////////////////////////
// PREFERENCE CARD EQUIPMENT SCHEMA
/////////////////////////////////////////

export const PreferenceCardEquipmentSchema = z.object({
  id: z.string(),
  preferenceCardId: z.string(),
  equipmentId: z.string(),
  open: z.number().int(),
  hold: z.number().int(),
  comments: z.string(),
})

export type PreferenceCardEquipment = z.infer<typeof PreferenceCardEquipmentSchema>

export default PreferenceCardEquipmentSchema;
