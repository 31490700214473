import { z } from 'zod';

/////////////////////////////////////////
// EMR TEMPLATE SCHEMA
/////////////////////////////////////////

export const EmrTemplateSchema = z.object({
  id: z.string(),
  facilityId: z.string(),
  name: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  archived: z.boolean(),
})

export type EmrTemplate = z.infer<typeof EmrTemplateSchema>

export default EmrTemplateSchema;
