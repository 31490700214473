import { z } from 'zod';

/////////////////////////////////////////
// PAYMENT LETTER PROTECTION SCHEMA
/////////////////////////////////////////

export const PaymentLetterProtectionSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  caseId: z.string(),
  accidentDate: z.coerce.date().nullish(),
  adjuster: z.string(),
  attorney: z.string(),
  attorneyPhone: z.string(),
  carInsurance: z.string(),
  carInsurancePolicyNumber: z.string(),
  claimNumber: z.string(),
  location: z.string(),
  surgeryAuthorization: z.string(),
  address1: z.string(),
  address2: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
})

export type PaymentLetterProtection = z.infer<typeof PaymentLetterProtectionSchema>

export default PaymentLetterProtectionSchema;
