"use client";

import * as PopoverPrimitive from "@radix-ui/react-popover";
import type { ComponentPropsWithoutRef, ElementRef, ReactNode } from "react";
import { forwardRef, useEffect, useState } from "react";
import { cn } from "../../utils";
import { Button } from "../Buttons/Button";

const PopoverRoot = ({
  open,
  children,
  ...props
}: Omit<ComponentPropsWithoutRef<typeof PopoverPrimitive.Root>, "children"> & {
  children:
    | ReactNode
    | (({
        isOpen,
        open,
        close,
      }: {
        isOpen: boolean;
        open: () => void;
        close: () => void;
      }) => ReactNode);
}) => {
  const [isOpen, setIsOpen] = useState(!!open);
  useEffect(() => setIsOpen(!!open), [open]);
  return (
    <PopoverPrimitive.Root
      {...props}
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        props.onOpenChange?.(open);
      }}
    >
      {typeof children === "function"
        ? children({ isOpen, open: () => setIsOpen(true), close: () => setIsOpen(false) })
        : children}
    </PopoverPrimitive.Root>
  );
};
PopoverRoot.displayName = PopoverPrimitive.Root.displayName;

const PopoverAnchor = forwardRef<
  ElementRef<typeof PopoverPrimitive.Anchor>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Anchor>
>((props, ref) => <PopoverPrimitive.Anchor ref={ref} asChild {...props} />);
PopoverAnchor.displayName = PopoverPrimitive.Anchor.displayName;

const PopoverTrigger = forwardRef<
  ElementRef<typeof PopoverPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>
>((props, ref) => <PopoverPrimitive.Trigger ref={ref} asChild {...props} />);
PopoverTrigger.displayName = PopoverPrimitive.Trigger.displayName;

const PopoverButton = forwardRef<
  ElementRef<typeof PopoverPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger> &
    ComponentPropsWithoutRef<typeof Button>
>((props, ref) => (
  <PopoverPrimitive.Trigger ref={ref} asChild>
    <Button {...props} />
  </PopoverPrimitive.Trigger>
));
PopoverButton.displayName = "Popover.Button";

const PopoverContent = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, children, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        `data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0
        data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95
        data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2
        data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 z-50 h-fit
        w-fit min-w-[var(--radix-popover-trigger-width)] rounded-lg border border-primary bg-primary
        text-primary shadow-lg outline-none`,
        className
      )}
      {...props}
    >
      {children}
      {/* <PopoverPrimitive.Arrow /> TODO: implement */}
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

/**
 * A popover is a non-modal dialog that floats around a trigger.
 * @param open - Whether or not the popover is open.
 * @param onOpenChange - Callback for when the popover opens or closes.
 * @see https://radix-ui.com/primitives/docs/components/popover
 */
export const Popover = Object.assign(PopoverRoot, {
  /**
   * Used to position the Popover.Content against. If this is not used, the content
   * will be positioned against the Popover.Trigger.
   * @see https://www.radix-ui.com/primitives/docs/components/popover#anchor
   */
  Anchor: PopoverAnchor,
  /**
   * A fully customizable trigger that opens a popover when clicked.
   * @param asChild - If true, the trigger will transfer its props to its child child element.
   * @see https://radix-ui.com/primitives/docs/components/popover#trigger
   */
  Trigger: PopoverTrigger,
  /**
   * A customizable button that opens a popover when clicked.
   * @see {@link Button} for implementation details
   */
  Button: PopoverButton,
  /**
   * The content of a popover.
   * @param side - The side of the trigger that the popover content should align to.
   * @param align - The alignment of the popover content relative to the trigger.
   * @param sideOffset - The distance between the popover content and the trigger.
   * @see https://radix-ui.com/primitives/docs/components/popover#content
   */
  Content: PopoverContent,
});
