import { z } from 'zod';
import { AuditOperationSchema } from '../inputTypeSchemas/AuditOperationSchema'

/////////////////////////////////////////
// STAFF VERSION SCHEMA
/////////////////////////////////////////

export const StaffVersionSchema = z.object({
  versionOperation: AuditOperationSchema,
  versionId: z.number().int(),
  versionSourceId: z.string().nullish(),
  versionUserId: z.string().nullish(),
  versionTimestamp: z.coerce.date(),
  id: z.string().nullish(),
  userId: z.string().nullish(),
  organizationId: z.string().nullish(),
  active: z.boolean().nullish(),
  createdAt: z.coerce.date().nullish(),
  updatedAt: z.coerce.date().nullish(),
  name: z.string().nullish(),
  firstName: z.string().nullish(),
  middleName: z.string().nullish(),
  lastName: z.string().nullish(),
  qualification: z.string().nullish(),
  email: z.string().nullish(),
  mobilePhone: z.string().nullish(),
  officePhone: z.string().nullish(),
  providerSpecialtyId: z.string().nullish(),
  npi: z.string().nullish(),
  taxonomyCode: z.string().nullish(),
  stateLicenseNumber: z.string().nullish(),
  upin: z.string().nullish(),
})

export type StaffVersion = z.infer<typeof StaffVersionSchema>

export default StaffVersionSchema;
