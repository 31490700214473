import { z } from 'zod';
import { JobRoleTypeSchema } from '../inputTypeSchemas/JobRoleTypeSchema'

/////////////////////////////////////////
// SIGNATURE SCHEMA
/////////////////////////////////////////

export const SignatureSchema = z.object({
  requiredJobRoleType: JobRoleTypeSchema.nullish(),
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  appliedAt: z.coerce.date().nullish(),
  appliedJobRoleId: z.string().nullish(),
  appliedPatientSignatureId: z.string().nullish(),
  appliedStaffSignatureId: z.string().nullish(),
})

export type Signature = z.infer<typeof SignatureSchema>

export default SignatureSchema;
