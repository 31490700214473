import { z } from 'zod';

/////////////////////////////////////////
// SUPPLIER REP SCHEMA
/////////////////////////////////////////

export const SupplierRepSchema = z.object({
  id: z.string(),
  name: z.string(),
  supplierId: z.string(),
  officePhone: z.string().nullish(),
  mobilePhone: z.string().nullish(),
  email: z.string().nullish(),
  active: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type SupplierRep = z.infer<typeof SupplierRepSchema>

export default SupplierRepSchema;
