import { ProviderEncounterDocumentSchema, StaffSchema } from "@procision-software/database-zod";
import type { z } from "zod";
import { EncounterDocumentRecordSchema } from "../encounter-document";

export const ProviderEncounterDocumentRecordSchema = ProviderEncounterDocumentSchema.extend({
  encounterDocument: EncounterDocumentRecordSchema,
  staff: StaffSchema,
});
export type ProviderEncounterDocumentRecord = z.infer<typeof ProviderEncounterDocumentRecordSchema>;

export const ProviderEncounterDocumentFormDataSchema = ProviderEncounterDocumentRecordSchema.pick({
  encounterDocumentId: true,
  staffId: true,
});

export type ProviderEncounterDocumentFormData = z.infer<
  typeof ProviderEncounterDocumentFormDataSchema
>;
