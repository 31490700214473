import * as z from "zod";

// Code identifying the type of communication number

export const CommunicationNumberQualifier03_EnumSchema = z.enum(["EM", "FX", "TE"]);
export type CommunicationNumberQualifier03_Enum = z.infer<
  typeof CommunicationNumberQualifier03_EnumSchema
>;

// Code identifying the type of communication number

export const CommunicationNumberQualifier05_EnumSchema = z.enum(["EM", "EX", "FX", "TE"]);
export type CommunicationNumberQualifier05_Enum = z.infer<
  typeof CommunicationNumberQualifier05_EnumSchema
>;

// Code identifying the major duty or responsibility of the person or group named

export const ContactFunctionCodeSchema = z.enum(["IC"]);
export type ContactFunctionCode = z.infer<typeof ContactFunctionCodeSchema>;

// Code identifying an organizational entity, a physical location, property or an individual

export const BillingProviderNameEntityIdentifierCode01Schema = z.enum(["85"]);
export type BillingProviderNameEntityIdentifierCode01 = z.infer<
  typeof BillingProviderNameEntityIdentifierCode01Schema
>;

// Code qualifying the type of entity
//
// - NM102 qualifies NM103.

export const BillingProviderNameEntityTypeQualifier02Schema = z.enum(["2"]);
export type BillingProviderNameEntityTypeQualifier02 = z.infer<
  typeof BillingProviderNameEntityTypeQualifier02Schema
>;

// Code designating the system/method of code structure used for Identification Code (67)

export const BillingProviderNameIdentificationCodeQualifier08Schema = z.enum(["XX"]);
export type BillingProviderNameIdentificationCodeQualifier08 = z.infer<
  typeof BillingProviderNameIdentificationCodeQualifier08Schema
>;

// Code qualifying the Reference Identification

export const BillingProviderTaxIdentificationRefReferenceIdentificationQualifier01Schema = z.enum([
  "EI",
]);
export type BillingProviderTaxIdentificationRefReferenceIdentificationQualifier01 = z.infer<
  typeof BillingProviderTaxIdentificationRefReferenceIdentificationQualifier01Schema
>;

// Code identifying the type of provider
//
// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const ErCodeSchema = z.enum(["BI"]);
export type ErCode = z.infer<typeof ErCodeSchema>;

// Code qualifying the Reference Identification

export const ReferenceIdentificationQualifier02_EnumSchema = z.enum(["PXC"]);
export type ReferenceIdentificationQualifier02_Enum = z.infer<
  typeof ReferenceIdentificationQualifier02_EnumSchema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const PayToAddressNameNm1EntityIdentifierCode01Schema = z.enum(["87"]);
export type PayToAddressNameNm1EntityIdentifierCode01 = z.infer<
  typeof PayToAddressNameNm1EntityIdentifierCode01Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const PayToPlanNameNm1EntityIdentifierCode01Schema = z.enum(["PE"]);
export type PayToPlanNameNm1EntityIdentifierCode01 = z.infer<
  typeof PayToPlanNameNm1EntityIdentifierCode01Schema
>;

// Code designating the system/method of code structure used for Identification Code (67)

export const PayToPlanNameNm1IdentificationCodeQualifier08Schema = z.enum(["PI", "XV"]);
export type PayToPlanNameNm1IdentificationCodeQualifier08 = z.infer<
  typeof PayToPlanNameNm1IdentificationCodeQualifier08Schema
>;

// Code qualifying the Reference Identification

export const PayToPlanSecondaryIdentificationRefReferenceIdentificationQualifier01Schema = z.enum([
  "FY",
  "NF",
  "2U",
]);
export type PayToPlanSecondaryIdentificationRefReferenceIdentificationQualifier01 = z.infer<
  typeof PayToPlanSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const AdjustedRepricedClaimNumberRefReferenceIdentificationQualifier01Schema = z.enum([
  "9C",
]);
export type AdjustedRepricedClaimNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof AdjustedRepricedClaimNumberRefReferenceIdentificationQualifier01Schema
>;

// Code indicating the date format, time format, or date and time format
//
// - DTP02 is the date or time or period format that will appear in DTP03.

export const AdmissionDateHourDtpDateTimePeriodFormatQualifier02Schema = z.enum(["D8", "DT"]);
export type AdmissionDateHourDtpDateTimePeriodFormatQualifier02 = z.infer<
  typeof AdmissionDateHourDtpDateTimePeriodFormatQualifier02Schema
>;

// Code specifying type of date or time, or both date and time

export const AdmissionDateHourDtpDateTimeQualifier01Schema = z.enum(["435"]);
export type AdmissionDateHourDtpDateTimeQualifier01 = z.infer<
  typeof AdmissionDateHourDtpDateTimeQualifier01Schema
>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const PurpleCodeListQualifierCodeSchema = z.enum(["ABJ", "BJ"]);
export type PurpleCodeListQualifierCode = z.infer<typeof PurpleCodeListQualifierCodeSchema>;

// Code identifying an organizational entity, a physical location, property or an individual

export const AttendingProviderNameEntityIdentifierCode01Schema = z.enum(["71"]);
export type AttendingProviderNameEntityIdentifierCode01 = z.infer<
  typeof AttendingProviderNameEntityIdentifierCode01Schema
>;

// Code qualifying the type of entity
//
// - NM102 qualifies NM103.

export const AttendingProviderNameEntityTypeQualifier02Schema = z.enum(["1"]);
export type AttendingProviderNameEntityTypeQualifier02 = z.infer<
  typeof AttendingProviderNameEntityTypeQualifier02Schema
>;

// Code qualifying the Reference Identification

export const AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema =
  z.enum(["G2", "LU", "0B", "1G"]);
export type AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01 = z.infer<
  typeof AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
>;

// Code identifying the type of provider

export const ProviderCodeSchema = z.enum(["AT"]);
export type ProviderCode = z.infer<typeof ProviderCodeSchema>;

// Code qualifying the Reference Identification

export const AutoAccidentStateRefReferenceIdentificationQualifier01Schema = z.enum(["LU"]);
export type AutoAccidentStateRefReferenceIdentificationQualifier01 = z.infer<
  typeof AutoAccidentStateRefReferenceIdentificationQualifier01Schema
>;

// Code identifying the functional area or purpose for which the note applies

export const BillingNoteNteNoteReferenceCode01Schema = z.enum(["ADD"]);
export type BillingNoteNteNoteReferenceCode01 = z.infer<
  typeof BillingNoteNteNoteReferenceCode01Schema
>;

// Code qualifying the Reference Identification

export const ClaimIdentifierForTransmissionIntermediariesRefReferenceIdentificationQualifier01Schema =
  z.enum(["D9"]);
export type ClaimIdentifierForTransmissionIntermediariesRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof ClaimIdentifierForTransmissionIntermediariesRefReferenceIdentificationQualifier01Schema
  >;

// Code indicating whether the provider accepts assignment

export const AssignmentOrPlanParticipationCodeSchema = z.enum(["A", "B", "C"]);
export type AssignmentOrPlanParticipationCode = z.infer<
  typeof AssignmentOrPlanParticipationCodeSchema
>;

// Code indicating a Yes or No condition or response
//
// - CLM08 is assignment of benefits indicator. A "Y" value indicates insured or authorized
// person authorizes benefits to be assigned to the provider; an "N" value indicates
// benefits have not been assigned to the provider.
//
// Code indicating a Yes or No condition or response
//
// - OI03 is the assignment of benefits indicator. A "Y" value indicates insured or
// authorized person authorizes benefits to be assigned to the provider; an "N" value
// indicates benefits have not been assigned to the provider.

export const BenefitsAssignmentCertificationIndicatorSchema = z.enum(["N", "W", "Y"]);
export type BenefitsAssignmentCertificationIndicator = z.infer<
  typeof BenefitsAssignmentCertificationIndicatorSchema
>;

// Code indicating the reason why a request was delayed

export const DelayReasonCodeSchema = z.enum([
  "1",
  "10",
  "11",
  "15",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
]);
export type DelayReasonCode = z.infer<typeof DelayReasonCodeSchema>;

// Code identifying the type of facility referenced
//
// - C023-02 qualifies C023-01 and C023-03.

export const FacilityCodeQualifierSchema = z.enum(["A"]);
export type FacilityCodeQualifier = z.infer<typeof FacilityCodeQualifierSchema>;

// Code indicating whether the provider has on file a signed statement by the patient
// authorizing the release of medical data to other organizations

export const ReleaseOfInformationCodeSchema = z.enum(["I", "Y"]);
export type ReleaseOfInformationCode = z.infer<typeof ReleaseOfInformationCodeSchema>;

// Code identifying the functional area or purpose for which the note applies

export const ClaimNoteNteNoteReferenceCode01Schema = z.enum([
  "ALG",
  "DCP",
  "DGN",
  "DME",
  "MED",
  "NTR",
  "ODT",
  "RHB",
  "RLH",
  "RNH",
  "SET",
  "SFM",
  "SPT",
  "UPI",
]);
export type ClaimNoteNteNoteReferenceCode01 = z.infer<typeof ClaimNoteNteNoteReferenceCode01Schema>;

// Code specifying the exception reason for consideration of out-of-network health care
// services
//
// - HCP15 is the exception reason generated by a third party organization.

export const ExceptionCodeSchema = z.enum(["1", "2", "3", "4", "5", "6"]);
export type ExceptionCode = z.infer<typeof ExceptionCodeSchema>;

// Code specifying policy compliance

export const PolicyComplianceCodeSchema = z.enum(["1", "2", "3", "4", "5"]);
export type PolicyComplianceCode = z.infer<typeof PolicyComplianceCodeSchema>;

// Code specifying pricing methodology at which the claim or line item has been priced or
// repriced

export const PricingMethodologySchema = z.enum([
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
]);
export type PricingMethodology = z.infer<typeof PricingMethodologySchema>;

// Code assigned by issuer to identify reason for rejection
//
// - HCP13 is the rejection message returned from the third party organization.

export const RejectReasonCodeSchema = z.enum(["T1", "T2", "T3", "T4", "T5", "T6"]);
export type RejectReasonCode = z.infer<typeof RejectReasonCodeSchema>;

// Code specifying the units in which a value is being expressed, or manner in which a
// measurement has been taken

export const UnitOrBasisForMeasurementCodeSchema = z.enum(["DA", "UN"]);
export type UnitOrBasisForMeasurementCode = z.infer<typeof UnitOrBasisForMeasurementCodeSchema>;

// Code indicating the title or contents of a document, report or supporting item

export const AttachmentReportTypeCodeSchema = z.enum([
  "A3",
  "A4",
  "AM",
  "AS",
  "B2",
  "B3",
  "B4",
  "BR",
  "BS",
  "BT",
  "CB",
  "CK",
  "CT",
  "D2",
  "DA",
  "DB",
  "DG",
  "DJ",
  "DS",
  "EB",
  "HC",
  "HR",
  "I5",
  "IR",
  "LA",
  "M1",
  "MT",
  "NN",
  "OB",
  "OC",
  "OD",
  "OE",
  "OX",
  "OZ",
  "P4",
  "P5",
  "PE",
  "PN",
  "PO",
  "PQ",
  "PY",
  "PZ",
  "RB",
  "RR",
  "RT",
  "RX",
  "SG",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "13",
  "15",
  "21",
  "V5",
  "XP",
]);
export type AttachmentReportTypeCode = z.infer<typeof AttachmentReportTypeCodeSchema>;

// Code defining timing, transmission method or format by which reports are to be sent

export const AttachmentTransmissionCodeSchema = z.enum(["AA", "BM", "EL", "EM", "FT", "FX"]);
export type AttachmentTransmissionCode = z.infer<typeof AttachmentTransmissionCodeSchema>;

// Code designating the system/method of code structure used for Identification Code (67)
//
// - PWK05 and PWK06 may be used to identify the addressee by a code number.

export const IdentificationCodeQualifier05_EnumSchema = z.enum(["AC"]);
export type IdentificationCodeQualifier05_Enum = z.infer<
  typeof IdentificationCodeQualifier05_EnumSchema
>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const FluffyCodeListQualifierCodeSchema = z.enum(["BG"]);
export type FluffyCodeListQualifierCode = z.infer<typeof FluffyCodeListQualifierCodeSchema>;

// Code identifying a contract type

export const ContractTypeCodeSchema = z.enum(["01", "02", "03", "04", "05", "06", "09"]);
export type ContractTypeCode = z.infer<typeof ContractTypeCodeSchema>;

// Code indicating the date format, time format, or date and time format
//
// - DTP02 is the date or time or period format that will appear in DTP03.
//
// Code indicating the date format, time format, or date and time format
//
// - C022-03 is the date format that will appear in C022-04.
//
// Code indicating the date format, time format, or date and time format

export const DateTimePeriodFormatQualifier01_EnumSchema = z.enum(["D8"]);
export type DateTimePeriodFormatQualifier01_Enum = z.infer<
  typeof DateTimePeriodFormatQualifier01_EnumSchema
>;

// Code specifying type of date or time, or both date and time

export const DateRepricerReceivedDateDtpDateTimeQualifier01Schema = z.enum(["050"]);
export type DateRepricerReceivedDateDtpDateTimeQualifier01 = z.infer<
  typeof DateRepricerReceivedDateDtpDateTimeQualifier01Schema
>;

// Code qualifying the Reference Identification

export const DemonstrationProjectIdentifierRefReferenceIdentificationQualifier01Schema = z.enum([
  "P4",
]);
export type DemonstrationProjectIdentifierRefReferenceIdentificationQualifier01 = z.infer<
  typeof DemonstrationProjectIdentifierRefReferenceIdentificationQualifier01Schema
>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const TentacledCodeListQualifierCodeSchema = z.enum(["DR"]);
export type TentacledCodeListQualifierCode = z.infer<typeof TentacledCodeListQualifierCodeSchema>;

// Code indicating the date format, time format, or date and time format
//
// - DTP02 is the date or time or period format that will appear in DTP03.

export const DischargeHourDtpDateTimePeriodFormatQualifier02Schema = z.enum(["TM"]);
export type DischargeHourDtpDateTimePeriodFormatQualifier02 = z.infer<
  typeof DischargeHourDtpDateTimePeriodFormatQualifier02Schema
>;

// Code specifying type of date or time, or both date and time

export const DischargeHourDtpDateTimeQualifier01Schema = z.enum(["096"]);
export type DischargeHourDtpDateTimeQualifier01 = z.infer<
  typeof DischargeHourDtpDateTimeQualifier01Schema
>;

// Code indicating a Yes or No condition or response
//
// - CRC02 is a Certification Condition Code applies indicator. A "Y" value indicates the
// condition codes in CRC03 through CRC07 apply; an "N" value indicates the condition codes
// in CRC03 through CRC07 do not apply.

export const CertificationConditionCodeAppliesIndicatorSchema = z.enum(["N", "Y"]);
export type CertificationConditionCodeAppliesIndicator = z.infer<
  typeof CertificationConditionCodeAppliesIndicatorSchema
>;

// Specifies the situation or category to which the code applies
//
// - CRC01 qualifies CRC03 through CRC07.
//
// Code identifying an organizational entity, a physical location, property or an individual

export const CodeQualifierSchema = z.enum(["ZZ"]);
export type CodeQualifier = z.infer<typeof CodeQualifierSchema>;

// Code indicating a condition

export const ConditionIndicatorSchema = z.enum(["AV", "NU", "S2", "ST"]);
export type ConditionIndicator = z.infer<typeof ConditionIndicatorSchema>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const StickyCodeListQualifierCodeSchema = z.enum(["ABN", "BN"]);
export type StickyCodeListQualifierCode = z.infer<typeof StickyCodeListQualifierCodeSchema>;

// Code indicating a Yes or No condition or response
//
// - C022-09 is used to identify the diagnosis onset as it relates to the diagnosis reported
// in C022-02. A "Y" indicates that the onset occurred prior to admission to the hospital;
// an "N" indicates that the onset did NOT occur prior to admission to the hospital; a "U"
// indicates that it is unknown whether the onset occurred prior to admission to the
// hospital or not.
// - C022-09 would only need to be reported to data collectors requiring this information
// when C022-01 is "BF" (Diagnosis Code) and range of diagnosis codes were NOT given in
// C022-08.

export const PresentOnAdmissionIndicatorSchema = z.enum(["N", "U", "W", "Y"]);
export type PresentOnAdmissionIndicator = z.infer<typeof PresentOnAdmissionIndicatorSchema>;

// Code qualifying the Reference Identification

export const InvestigationalDeviceExemptionNumberRefReferenceIdentificationQualifier01Schema =
  z.enum(["LX"]);
export type InvestigationalDeviceExemptionNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof InvestigationalDeviceExemptionNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const MedicalRecordNumberRefReferenceIdentificationQualifier01Schema = z.enum(["EA"]);
export type MedicalRecordNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof MedicalRecordNumberRefReferenceIdentificationQualifier01Schema
>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const IndigoCodeListQualifierCodeSchema = z.enum(["BH"]);
export type IndigoCodeListQualifierCode = z.infer<typeof IndigoCodeListQualifierCodeSchema>;

// Code indicating the date format, time format, or date and time format
//
// - C022-03 is the date format that will appear in C022-04.
//
// Code indicating the date format, time format, or date and time format
//
// - DTP02 is the date or time or period format that will appear in DTP03.

export const StatementDatesDtpDateTimePeriodFormatQualifier02Schema = z.enum(["RD8"]);
export type StatementDatesDtpDateTimePeriodFormatQualifier02 = z.infer<
  typeof StatementDatesDtpDateTimePeriodFormatQualifier02Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const OperatingPhysicianNameEntityIdentifierCode01Schema = z.enum(["72"]);
export type OperatingPhysicianNameEntityIdentifierCode01 = z.infer<
  typeof OperatingPhysicianNameEntityIdentifierCode01Schema
>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const IndecentCodeListQualifierCodeSchema = z.enum(["ABF", "BF"]);
export type IndecentCodeListQualifierCode = z.infer<typeof IndecentCodeListQualifierCodeSchema>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const HilariousCodeListQualifierCodeSchema = z.enum(["BBQ", "BQ"]);
export type HilariousCodeListQualifierCode = z.infer<typeof HilariousCodeListQualifierCodeSchema>;

// Code identifying the general category of payment adjustment

export const ClaimAdjustmentGroupCodeSchema = z.enum(["CO", "CR", "OA", "PI", "PR"]);
export type ClaimAdjustmentGroupCode = z.infer<typeof ClaimAdjustmentGroupCodeSchema>;

// Code to qualify amount

export const CoordinationOfBenefitsCobPayerPaidAmountAmtAmountQualifierCode01Schema = z.enum(["D"]);
export type CoordinationOfBenefitsCobPayerPaidAmountAmtAmountQualifierCode01 = z.infer<
  typeof CoordinationOfBenefitsCobPayerPaidAmountAmtAmountQualifierCode01Schema
>;

// Code to qualify amount

export const CoordinationOfBenefitsCobTotalNonCoveredAmountAmtAmountQualifierCode01Schema = z.enum([
  "A8",
]);
export type CoordinationOfBenefitsCobTotalNonCoveredAmountAmtAmountQualifierCode01 = z.infer<
  typeof CoordinationOfBenefitsCobTotalNonCoveredAmountAmtAmountQualifierCode01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema =
  z.enum(["G2", "LU"]);
export type OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
  >;

// Code specifying type of date or time, or both date and time

export const ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema = z.enum(["573"]);
export type ClaimCheckOrRemittanceDateDtpDateTimeQualifier01 = z.infer<
  typeof ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerClaimAdjustmentIndicatorRefReferenceIdentificationQualifier01Schema = z.enum(
  ["T4"]
);
export type OtherPayerClaimAdjustmentIndicatorRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherPayerClaimAdjustmentIndicatorRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema = z.enum([
  "F8",
]);
export type OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const OtherPayerNameNm1EntityIdentifierCode01Schema = z.enum(["PR"]);
export type OtherPayerNameNm1EntityIdentifierCode01 = z.infer<
  typeof OtherPayerNameNm1EntityIdentifierCode01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema = z.enum(
  ["G1"]
);
export type OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema = z.enum(["9F"]);
export type OtherPayerReferralNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01Schema = z.enum([
  "EI",
  "FY",
  "NF",
  "2U",
]);
export type OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const OtherPayerReferringProviderNm1EntityIdentifierCode01Schema = z.enum(["DN"]);
export type OtherPayerReferringProviderNm1EntityIdentifierCode01 = z.infer<
  typeof OtherPayerReferringProviderNm1EntityIdentifierCode01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema =
  z.enum(["G2", "0B", "1G"]);
export type OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
  >;

// Code identifying an organizational entity, a physical location, property or an individual

export const OtherPayerRenderingProviderNameNm1EntityIdentifierCode01Schema = z.enum(["82"]);
export type OtherPayerRenderingProviderNameNm1EntityIdentifierCode01 = z.infer<
  typeof OtherPayerRenderingProviderNameNm1EntityIdentifierCode01Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema = z.enum(["77"]);
export type OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01 = z.infer<
  typeof OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema
>;

// Code qualifying the Reference Identification

export const OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema =
  z.enum(["G2", "LU", "0B"]);
export type OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
  >;

// Code identifying type of claim

export const ClaimFilingIndicatorCodeSchema = z.enum([
  "AM",
  "BL",
  "CH",
  "CI",
  "DS",
  "FI",
  "HM",
  "LM",
  "MA",
  "MB",
  "MC",
  "OF",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "TV",
  "VA",
  "WC",
  "ZZ",
]);
export type ClaimFilingIndicatorCode = z.infer<typeof ClaimFilingIndicatorCodeSchema>;

// Code indicating the relationship between two individuals or entities
//
// - SBR02 specifies the relationship to the person insured.

export const OtherSubscriberInformationSbrIndividualRelationshipCode02Schema = z.enum([
  "G8",
  "01",
  "18",
  "19",
  "20",
  "21",
  "39",
  "40",
  "53",
]);
export type OtherSubscriberInformationSbrIndividualRelationshipCode02 = z.infer<
  typeof OtherSubscriberInformationSbrIndividualRelationshipCode02Schema
>;

// Code identifying the insurance carrier's level of responsibility for a payment of a claim

export const PayerResponsibilitySequenceNumberCodeSchema = z.enum([
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "P",
  "S",
  "T",
  "U",
]);
export type PayerResponsibilitySequenceNumberCode = z.infer<
  typeof PayerResponsibilitySequenceNumberCodeSchema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const OtherSubscriberNameNm1EntityIdentifierCode01Schema = z.enum(["IL"]);
export type OtherSubscriberNameNm1EntityIdentifierCode01 = z.infer<
  typeof OtherSubscriberNameNm1EntityIdentifierCode01Schema
>;

// Code qualifying the type of entity
//
// - NM102 qualifies NM103.

export const OtherSubscriberNameNm1EntityTypeQualifier02Schema = z.enum(["1", "2"]);
export type OtherSubscriberNameNm1EntityTypeQualifier02 = z.infer<
  typeof OtherSubscriberNameNm1EntityTypeQualifier02Schema
>;

// Code designating the system/method of code structure used for Identification Code (67)

export const OtherSubscriberNameNm1IdentificationCodeQualifier08Schema = z.enum(["II", "MI"]);
export type OtherSubscriberNameNm1IdentificationCodeQualifier08 = z.infer<
  typeof OtherSubscriberNameNm1IdentificationCodeQualifier08Schema
>;

// Code qualifying the Reference Identification

export const OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01Schema =
  z.enum(["SY"]);
export type OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01 = z.infer<
  typeof OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01Schema
>;

// Code to qualify amount

export const RemainingPatientLiabilityAmtAmountQualifierCode01Schema = z.enum(["EAF"]);
export type RemainingPatientLiabilityAmtAmountQualifierCode01 = z.infer<
  typeof RemainingPatientLiabilityAmtAmountQualifierCode01Schema
>;

// Code to qualify amount

export const PatientEstimatedAmountDueAmtAmountQualifierCode01Schema = z.enum(["F3"]);
export type PatientEstimatedAmountDueAmtAmountQualifierCode01 = z.infer<
  typeof PatientEstimatedAmountDueAmtAmountQualifierCode01Schema
>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const AmbitiousCodeListQualifierCodeSchema = z.enum(["APR", "PR"]);
export type AmbitiousCodeListQualifierCode = z.infer<typeof AmbitiousCodeListQualifierCodeSchema>;

// Code qualifying the Reference Identification

export const PeerReviewOrganizationProApprovalNumberRefReferenceIdentificationQualifier01Schema =
  z.enum(["G4"]);
export type PeerReviewOrganizationProApprovalNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof PeerReviewOrganizationProApprovalNumberRefReferenceIdentificationQualifier01Schema
>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const CunningCodeListQualifierCodeSchema = z.enum(["ABK", "BK"]);
export type CunningCodeListQualifierCode = z.infer<typeof CunningCodeListQualifierCodeSchema>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const MagentaCodeListQualifierCodeSchema = z.enum(["BBR", "BR", "CAH"]);
export type MagentaCodeListQualifierCode = z.infer<typeof MagentaCodeListQualifierCodeSchema>;

// Code qualifying the Reference Identification

export const RepricedClaimNumberRefReferenceIdentificationQualifier01Schema = z.enum(["9A"]);
export type RepricedClaimNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof RepricedClaimNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const ServiceAuthorizationExceptionCodeRefReferenceIdentificationQualifier01Schema = z.enum([
  "4N",
]);
export type ServiceAuthorizationExceptionCodeRefReferenceIdentificationQualifier01 = z.infer<
  typeof ServiceAuthorizationExceptionCodeRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const AdjustedRepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema =
  z.enum(["9D"]);
export type AdjustedRepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof AdjustedRepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema
>;

// Code indicating the date format, time format, or date and time format
//
// - DTP02 is the date or time or period format that will appear in DTP03.

export const DateServiceDateDtpDateTimePeriodFormatQualifier02Schema = z.enum(["D8", "RD8"]);
export type DateServiceDateDtpDateTimePeriodFormatQualifier02 = z.infer<
  typeof DateServiceDateDtpDateTimePeriodFormatQualifier02Schema
>;

// Code specifying type of date or time, or both date and time

export const DateServiceDateDtpDateTimeQualifier01Schema = z.enum(["472"]);
export type DateServiceDateDtpDateTimeQualifier01 = z.infer<
  typeof DateServiceDateDtpDateTimeQualifier01Schema
>;

// Code identifying the type/source of the descriptive number used in Product/Service ID
// (234)
//
// - LIN02 through LIN31 provide for fifteen different product/service IDs for each item.
// For example: Case, Color, Drawing No., U.P.C. No., ISBN No., Model No., or SKU.

export const ProductOrServiceIdQualifier02_EnumSchema = z.enum(["N4"]);
export type ProductOrServiceIdQualifier02_Enum = z.infer<
  typeof ProductOrServiceIdQualifier02_EnumSchema
>;

// Code specifying the units in which a value is being expressed, or manner in which a
// measurement has been taken

export const CompositeUnitOfMeasure05_CodeQualifier01Schema = z.enum([
  "F2",
  "GR",
  "ME",
  "ML",
  "UN",
]);
export type CompositeUnitOfMeasure05_CodeQualifier01 = z.infer<
  typeof CompositeUnitOfMeasure05_CodeQualifier01Schema
>;

// Code qualifying the Reference Identification

export const PrescriptionOrCompoundDrugAssociationNumberRefReferenceIdentificationQualifier01Schema =
  z.enum(["VY", "XZ"]);
export type PrescriptionOrCompoundDrugAssociationNumberRefReferenceIdentificationQualifier01 =
  z.infer<
    typeof PrescriptionOrCompoundDrugAssociationNumberRefReferenceIdentificationQualifier01Schema
  >;

// Code to qualify amount

export const FacilityTaxAmountAmtAmountQualifierCode01Schema = z.enum(["N8"]);
export type FacilityTaxAmountAmtAmountQualifierCode01 = z.infer<
  typeof FacilityTaxAmountAmtAmountQualifierCode01Schema
>;

// Code identifying the type/source of the descriptive number used in Product/Service ID
// (234)
//
// - C003-01 qualifies C003-02 and C003-08.
//
// Code identifying the type/source of the descriptive number used in Product/Service ID
// (234)

export const ProductOrServiceIdQualifier01_EnumSchema = z.enum(["ER", "HC", "HP", "IV", "WK"]);
export type ProductOrServiceIdQualifier01_Enum = z.infer<
  typeof ProductOrServiceIdQualifier01_EnumSchema
>;

// Code qualifying the Reference Identification

export const LineItemControlNumberRefReferenceIdentificationQualifier01Schema = z.enum(["6R"]);
export type LineItemControlNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof LineItemControlNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema = z.enum(["2U"]);
export type ReferenceIdentifier04_ReferenceIdentificationQualifier01 = z.infer<
  typeof ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const RepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema = z.enum([
  "9B",
]);
export type RepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof RepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema
>;

// Code to qualify amount

export const ServiceTaxAmountAmtAmountQualifierCode01Schema = z.enum(["GT"]);
export type ServiceTaxAmountAmtAmountQualifierCode01 = z.infer<
  typeof ServiceTaxAmountAmtAmountQualifierCode01Schema
>;

// Code identifying the functional area or purpose for which the note applies

export const ThirdPartyOrganizationNotesNteNoteReferenceCode01Schema = z.enum(["TPO"]);
export type ThirdPartyOrganizationNotesNteNoteReferenceCode01 = z.infer<
  typeof ThirdPartyOrganizationNotesNteNoteReferenceCode01Schema
>;

// Code specifying type of date or time, or both date and time

export const StatementDatesDtpDateTimeQualifier01Schema = z.enum(["434"]);
export type StatementDatesDtpDateTimeQualifier01 = z.infer<
  typeof StatementDatesDtpDateTimeQualifier01Schema
>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const FriskyCodeListQualifierCodeSchema = z.enum(["TC"]);
export type FriskyCodeListQualifierCode = z.infer<typeof FriskyCodeListQualifierCodeSchema>;

// Code identifying a specific industry code list
//
// - C022-01 qualifies C022-02, C022-04, C022-05, C022-06 and C022-08.

export const MischievousCodeListQualifierCodeSchema = z.enum(["BE"]);
export type MischievousCodeListQualifierCode = z.infer<
  typeof MischievousCodeListQualifierCodeSchema
>;

// Code indicating the relationship between two individuals or entities

export const IndividualRelationshipCode01_EnumSchema = z.enum([
  "G8",
  "01",
  "19",
  "20",
  "21",
  "39",
  "40",
  "53",
]);
export type IndividualRelationshipCode01_Enum = z.infer<
  typeof IndividualRelationshipCode01_EnumSchema
>;

// Code indicating the sex of the individual

export const GenderCodeSchema = z.enum(["F", "M", "U"]);
export type GenderCode = z.infer<typeof GenderCodeSchema>;

// Code identifying an organizational entity, a physical location, property or an individual

export const PatientNameNm1EntityIdentifierCode01Schema = z.enum(["QC"]);
export type PatientNameNm1EntityIdentifierCode01 = z.infer<
  typeof PatientNameNm1EntityIdentifierCode01Schema
>;

// Code qualifying the Reference Identification

export const PropertyAndCasualtyClaimNumberRefReferenceIdentificationQualifier01Schema = z.enum([
  "Y4",
]);
export type PropertyAndCasualtyClaimNumberRefReferenceIdentificationQualifier01 = z.infer<
  typeof PropertyAndCasualtyClaimNumberRefReferenceIdentificationQualifier01Schema
>;

// Code qualifying the Reference Identification

export const PropertyAndCasualtyPatientIdentifierRefReferenceIdentificationQualifier01Schema =
  z.enum(["SY", "1W"]);
export type PropertyAndCasualtyPatientIdentifierRefReferenceIdentificationQualifier01 = z.infer<
  typeof PropertyAndCasualtyPatientIdentifierRefReferenceIdentificationQualifier01Schema
>;

// Code indicating the relationship between two individuals or entities
//
// - SBR02 specifies the relationship to the person insured.

export const SubscriberInformationSbrIndividualRelationshipCode02Schema = z.enum(["18"]);
export type SubscriberInformationSbrIndividualRelationshipCode02 = z.infer<
  typeof SubscriberInformationSbrIndividualRelationshipCode02Schema
>;

// Code specifying the type of transaction

export const ClaimIdentifierSchema = z.enum(["CH", "RP", "31"]);
export type ClaimIdentifier = z.infer<typeof ClaimIdentifierSchema>;

// Code indicating the hierarchical application structure of a transaction set that utilizes
// the HL segment to define the structure of the transaction set

export const HierarchicalStructureCodeSchema = z.enum(["0019"]);
export type HierarchicalStructureCode = z.infer<typeof HierarchicalStructureCodeSchema>;

// Code identifying purpose of transaction set

export const TransactionSetPurposeCodeSchema = z.enum(["00", "18"]);
export type TransactionSetPurposeCode = z.infer<typeof TransactionSetPurposeCodeSchema>;

// Code identifying an organizational entity, a physical location, property or an individual

export const ReceiverNameNm1EntityIdentifierCode01Schema = z.enum(["40"]);
export type ReceiverNameNm1EntityIdentifierCode01 = z.infer<
  typeof ReceiverNameNm1EntityIdentifierCode01Schema
>;

// Code designating the system/method of code structure used for Identification Code (67)

export const ReceiverNameNm1IdentificationCodeQualifier08Schema = z.enum(["46"]);
export type ReceiverNameNm1IdentificationCodeQualifier08 = z.infer<
  typeof ReceiverNameNm1IdentificationCodeQualifier08Schema
>;

// Code identifying an organizational entity, a physical location, property or an individual

export const SubmitterNameNm1EntityIdentifierCode01Schema = z.enum(["41"]);
export type SubmitterNameNm1EntityIdentifierCode01 = z.infer<
  typeof SubmitterNameNm1EntityIdentifierCode01Schema
>;

// Code uniquely identifying a Transaction Set
//
// - The transaction set identifier (ST01) is used by the translation routines of the
// interchange partners to select the appropriate transaction set definition (e.g., 810
// selects the Invoice Transaction Set).

export const TransactionSetIdentifierCodeSchema = z.enum(["837"]);
export type TransactionSetIdentifierCode = z.infer<typeof TransactionSetIdentifierCodeSchema>;

// Reference assigned to identify Implementation Convention
//
// - The implementation convention reference (ST03) is used by the translation routines of
// the interchange partners to select the appropriate implementation convention to match the
// transaction set definition. When used, this implementation convention reference takes
// precedence over the implementation reference specified in the GS08.

export const VersionReleaseOrIndustryIdentifierSchema = z.enum(["005010X223A2"]);
export type VersionReleaseOrIndustryIdentifier = z.infer<
  typeof VersionReleaseOrIndustryIdentifierSchema
>;

export const TransactionSetHeaderSchema = z.object({
  transaction_set_control_number_02: z.number(),
  transaction_set_identifier_code_01: TransactionSetIdentifierCodeSchema,
  version_release_or_industry_identifier_03: VersionReleaseOrIndustryIdentifierSchema,
});
export type TransactionSetHeader = z.infer<typeof TransactionSetHeaderSchema>;

export const SubmitterNameSchema = z.object({
  entity_identifier_code_01: SubmitterNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherSubscriberNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: ReceiverNameNm1IdentificationCodeQualifier08Schema,
  submitter_first_name_04: z.union([z.null(), z.string()]).optional(),
  submitter_identifier_09: z.string(),
  submitter_last_or_organization_name_03: z.string(),
  submitter_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
});
export type SubmitterName = z.infer<typeof SubmitterNameSchema>;

export const SubmitterEdiContactInformationSchema = z.object({
  communication_number_04: z.string(),
  communication_number_06: z.union([z.null(), z.string()]).optional(),
  communication_number_08: z.union([z.null(), z.string()]).optional(),
  communication_number_qualifier_03: CommunicationNumberQualifier03_EnumSchema,
  communication_number_qualifier_05: z
    .union([CommunicationNumberQualifier05_EnumSchema, z.null()])
    .optional(),
  communication_number_qualifier_07: z
    .union([CommunicationNumberQualifier05_EnumSchema, z.null()])
    .optional(),
  contact_function_code_01: ContactFunctionCodeSchema,
  submitter_contact_name_02: z.union([z.null(), z.string()]).optional(),
});
export type SubmitterEdiContactInformation = z.infer<typeof SubmitterEdiContactInformationSchema>;

export const SubmitterNameLoopSchema = z.object({
  submitter_edi_contact_information_PER: z.array(SubmitterEdiContactInformationSchema),
  submitter_name_NM1: SubmitterNameSchema,
});
export type SubmitterNameLoop = z.infer<typeof SubmitterNameLoopSchema>;

export const ReceiverNameSchema = z.object({
  entity_identifier_code_01: ReceiverNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: ReceiverNameNm1IdentificationCodeQualifier08Schema,
  receiver_name_03: z.string(),
  receiver_primary_identifier_09: z.string(),
});
export type ReceiverName = z.infer<typeof ReceiverNameSchema>;

export const ReceiverNameLoopSchema = z.object({
  receiver_name_NM1: ReceiverNameSchema,
});
export type ReceiverNameLoop = z.infer<typeof ReceiverNameLoopSchema>;

export const BeginningOfHierarchicalTransactionSchema = z.object({
  claim_identifier_06: ClaimIdentifierSchema,
  hierarchical_structure_code_01: HierarchicalStructureCodeSchema,
  originator_application_transaction_identifier_03: z.string(),
  transaction_set_creation_date_04: z.string(),
  transaction_set_creation_time_05: z.string(),
  transaction_set_purpose_code_02: TransactionSetPurposeCodeSchema,
});
export type BeginningOfHierarchicalTransaction = z.infer<
  typeof BeginningOfHierarchicalTransactionSchema
>;

export const HeadingSchema = z.object({
  beginning_of_hierarchical_transaction_BHT: BeginningOfHierarchicalTransactionSchema,
  receiver_name_NM1_loop: ReceiverNameLoopSchema,
  submitter_name_NM1_loop: SubmitterNameLoopSchema,
  transaction_set_header_ST: z.union([TransactionSetHeaderSchema, z.null()]).optional(),
});
export type Heading = z.infer<typeof HeadingSchema>;

export const TransactionSetTrailerSchema = z.object({
  transaction_segment_count_01: z.number(),
  transaction_set_control_number_02: z.number(),
});
export type TransactionSetTrailer = z.infer<typeof TransactionSetTrailerSchema>;

export const SubscriberSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  subscriber_supplemental_identifier_02: z.string(),
});
export type SubscriberSecondaryIdentification = z.infer<
  typeof SubscriberSecondaryIdentificationSchema
>;

export const SubscriberNameSchema = z.object({
  entity_identifier_code_01: OtherSubscriberNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherSubscriberNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([OtherSubscriberNameNm1IdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  subscriber_first_name_04: z.union([z.null(), z.string()]).optional(),
  subscriber_last_name_03: z.string(),
  subscriber_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  subscriber_name_suffix_07: z.union([z.null(), z.string()]).optional(),
  subscriber_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type SubscriberName = z.infer<typeof SubscriberNameSchema>;

export const SubscriberDemographicInformationSchema = z.object({
  date_time_period_format_qualifier_01: DateTimePeriodFormatQualifier01_EnumSchema,
  subscriber_birth_date_02: z.string(),
  subscriber_gender_code_03: GenderCodeSchema,
});
export type SubscriberDemographicInformation = z.infer<
  typeof SubscriberDemographicInformationSchema
>;

export const SubscriberCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  subscriber_city_name_01: z.string(),
  subscriber_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  subscriber_state_code_02: z.union([z.null(), z.string()]).optional(),
});
export type SubscriberCityStateZipCode = z.infer<typeof SubscriberCityStateZipCodeSchema>;

export const SubscriberAddressSchema = z.object({
  subscriber_address_line_01: z.string(),
  subscriber_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type SubscriberAddress = z.infer<typeof SubscriberAddressSchema>;

export const SubscriberNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema = z.object({
  property_casualty_claim_number_02: z.string(),
  reference_identification_qualifier_01:
    PropertyAndCasualtyClaimNumberRefReferenceIdentificationQualifier01Schema,
});
export type SubscriberNameNm1LoopPropertyAndCasualtyClaimNumberRef = z.infer<
  typeof SubscriberNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema
>;

export const SubscriberNameLoopSchema = z.object({
  property_and_casualty_claim_number_REF: z
    .union([SubscriberNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema, z.null()])
    .optional(),
  subscriber_address_N3: z.union([SubscriberAddressSchema, z.null()]).optional(),
  subscriber_city_state_zip_code_N4: z
    .union([SubscriberCityStateZipCodeSchema, z.null()])
    .optional(),
  subscriber_demographic_information_DMG: z
    .union([SubscriberDemographicInformationSchema, z.null()])
    .optional(),
  subscriber_name_NM1: SubscriberNameSchema,
  subscriber_secondary_identification_REF: z
    .union([SubscriberSecondaryIdentificationSchema, z.null()])
    .optional(),
});
export type SubscriberNameLoop = z.infer<typeof SubscriberNameLoopSchema>;

export const SubscriberInformationSchema = z.object({
  claim_filing_indicator_code_09: ClaimFilingIndicatorCodeSchema,
  individual_relationship_code_02: z
    .union([SubscriberInformationSbrIndividualRelationshipCode02Schema, z.null()])
    .optional(),
  payer_responsibility_sequence_number_code_01: PayerResponsibilitySequenceNumberCodeSchema,
  subscriber_group_name_04: z.union([z.null(), z.string()]).optional(),
  subscriber_group_or_policy_number_03: z.union([z.null(), z.string()]).optional(),
});
export type SubscriberInformation = z.infer<typeof SubscriberInformationSchema>;

export const PayerSecondaryIdentificationSchema = z.object({
  payer_additional_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type PayerSecondaryIdentification = z.infer<typeof PayerSecondaryIdentificationSchema>;

export const PayerNameSchema = z.object({
  entity_identifier_code_01: OtherPayerNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: PayToPlanNameNm1IdentificationCodeQualifier08Schema,
  payer_identifier_09: z.string(),
  payer_name_03: z.string(),
});
export type PayerName = z.infer<typeof PayerNameSchema>;

export const PayerCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  payer_city_name_01: z.string(),
  payer_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  payer_state_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PayerCityStateZipCode = z.infer<typeof PayerCityStateZipCodeSchema>;

export const PayerAddressSchema = z.object({
  payer_address_line_01: z.string(),
  payer_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PayerAddress = z.infer<typeof PayerAddressSchema>;

export const BillingProviderSecondaryIdentificationSchema = z.object({
  billing_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type BillingProviderSecondaryIdentification = z.infer<
  typeof BillingProviderSecondaryIdentificationSchema
>;

export const PayerNameLoopSchema = z.object({
  billing_provider_secondary_identification_REF: z
    .union([BillingProviderSecondaryIdentificationSchema, z.null()])
    .optional(),
  payer_address_N3: z.union([PayerAddressSchema, z.null()]).optional(),
  payer_city_state_zip_code_N4: z.union([PayerCityStateZipCodeSchema, z.null()]).optional(),
  payer_name_NM1: PayerNameSchema,
  payer_secondary_identification_REF: z
    .union([z.array(PayerSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PayerNameLoop = z.infer<typeof PayerNameLoopSchema>;

export const PropertyAndCasualtyPatientIdentifierSchema = z.object({
  property_and_casualty_patient_identifier_02: z.string(),
  reference_identification_qualifier_01:
    PropertyAndCasualtyPatientIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type PropertyAndCasualtyPatientIdentifier = z.infer<
  typeof PropertyAndCasualtyPatientIdentifierSchema
>;

export const PatientNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema = z.object({
  property_casualty_claim_number_02: z.string(),
  reference_identification_qualifier_01:
    PropertyAndCasualtyClaimNumberRefReferenceIdentificationQualifier01Schema,
});
export type PatientNameNm1LoopPropertyAndCasualtyClaimNumberRef = z.infer<
  typeof PatientNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema
>;

export const PatientNameSchema = z.object({
  entity_identifier_code_01: PatientNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  patient_first_name_04: z.union([z.null(), z.string()]).optional(),
  patient_last_name_03: z.string(),
  patient_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  patient_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type PatientName = z.infer<typeof PatientNameSchema>;

export const PatientDemographicInformationSchema = z.object({
  date_time_period_format_qualifier_01: DateTimePeriodFormatQualifier01_EnumSchema,
  patient_birth_date_02: z.string(),
  patient_gender_code_03: GenderCodeSchema,
});
export type PatientDemographicInformation = z.infer<typeof PatientDemographicInformationSchema>;

export const PatientCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  patient_city_name_01: z.string(),
  patient_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  patient_state_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PatientCityStateZipCode = z.infer<typeof PatientCityStateZipCodeSchema>;

export const PatientAddressSchema = z.object({
  patient_address_line_01: z.string(),
  patient_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PatientAddress = z.infer<typeof PatientAddressSchema>;

export const PatientNameLoopSchema = z.object({
  patient_address_N3: PatientAddressSchema,
  patient_city_state_zip_code_N4: PatientCityStateZipCodeSchema,
  patient_demographic_information_DMG: PatientDemographicInformationSchema,
  patient_name_NM1: PatientNameSchema,
  property_and_casualty_claim_number_REF: z
    .union([PatientNameNm1LoopPropertyAndCasualtyClaimNumberRefSchema, z.null()])
    .optional(),
  property_and_casualty_patient_identifier_REF: z
    .union([PropertyAndCasualtyPatientIdentifierSchema, z.null()])
    .optional(),
});
export type PatientNameLoop = z.infer<typeof PatientNameLoopSchema>;

export const PatientInformationSchema = z.object({
  individual_relationship_code_01: IndividualRelationshipCode01_EnumSchema,
});
export type PatientInformation = z.infer<typeof PatientInformationSchema>;

export const HealthCareCodeInformation193Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation193 = z.infer<typeof HealthCareCodeInformation193Schema>;

export const HealthCareCodeInformation192Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation192 = z.infer<typeof HealthCareCodeInformation192Schema>;

export const HealthCareCodeInformation191Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation191 = z.infer<typeof HealthCareCodeInformation191Schema>;

export const HealthCareCodeInformation190Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation190 = z.infer<typeof HealthCareCodeInformation190Schema>;

export const HealthCareCodeInformation189Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation189 = z.infer<typeof HealthCareCodeInformation189Schema>;

export const HealthCareCodeInformation188Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation188 = z.infer<typeof HealthCareCodeInformation188Schema>;

export const HealthCareCodeInformation187Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation187 = z.infer<typeof HealthCareCodeInformation187Schema>;

export const HealthCareCodeInformation186Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation186 = z.infer<typeof HealthCareCodeInformation186Schema>;

export const HealthCareCodeInformation185Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation185 = z.infer<typeof HealthCareCodeInformation185Schema>;

export const HealthCareCodeInformation184Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation184 = z.infer<typeof HealthCareCodeInformation184Schema>;

export const HealthCareCodeInformation183Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation183 = z.infer<typeof HealthCareCodeInformation183Schema>;

export const HealthCareCodeInformation182Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation182 = z.infer<typeof HealthCareCodeInformation182Schema>;

export const FluffyValueInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation182Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation183Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation184Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation185Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation186Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation187Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation188Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation189Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation190Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation191Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation192Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation193Schema, z.null()])
    .optional(),
});
export type FluffyValueInformation = z.infer<typeof FluffyValueInformationSchema>;

export const HealthCareCodeInformation181Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation181 = z.infer<typeof HealthCareCodeInformation181Schema>;

export const HealthCareCodeInformation180Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation180 = z.infer<typeof HealthCareCodeInformation180Schema>;

export const HealthCareCodeInformation179Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation179 = z.infer<typeof HealthCareCodeInformation179Schema>;

export const HealthCareCodeInformation178Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation178 = z.infer<typeof HealthCareCodeInformation178Schema>;

export const HealthCareCodeInformation177Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation177 = z.infer<typeof HealthCareCodeInformation177Schema>;

export const HealthCareCodeInformation176Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation176 = z.infer<typeof HealthCareCodeInformation176Schema>;

export const HealthCareCodeInformation175Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation175 = z.infer<typeof HealthCareCodeInformation175Schema>;

export const HealthCareCodeInformation174Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation174 = z.infer<typeof HealthCareCodeInformation174Schema>;

export const HealthCareCodeInformation173Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation173 = z.infer<typeof HealthCareCodeInformation173Schema>;

export const HealthCareCodeInformation172Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation172 = z.infer<typeof HealthCareCodeInformation172Schema>;

export const HealthCareCodeInformation171Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation171 = z.infer<typeof HealthCareCodeInformation171Schema>;

export const HealthCareCodeInformation170Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation170 = z.infer<typeof HealthCareCodeInformation170Schema>;

export const FluffyTreatmentCodeInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation170Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation171Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation172Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation173Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation174Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation175Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation176Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation177Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation178Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation179Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation180Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation181Schema, z.null()])
    .optional(),
});
export type FluffyTreatmentCodeInformation = z.infer<typeof FluffyTreatmentCodeInformationSchema>;

export const FluffyStatementDatesSchema = z.object({
  date_time_period_format_qualifier_02: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: StatementDatesDtpDateTimeQualifier01Schema,
  statement_from_and_to_date_03: z.string(),
});
export type FluffyStatementDates = z.infer<typeof FluffyStatementDatesSchema>;

export const FluffyThirdPartyOrganizationNotesSchema = z.object({
  line_note_text_02: z.string(),
  note_reference_code_01: ThirdPartyOrganizationNotesNteNoteReferenceCode01Schema,
});
export type FluffyThirdPartyOrganizationNotes = z.infer<
  typeof FluffyThirdPartyOrganizationNotesSchema
>;

export const FluffyServiceTaxAmountSchema = z.object({
  amount_qualifier_code_01: ServiceTaxAmountAmtAmountQualifierCode01Schema,
  service_tax_amount_02: z.number(),
});
export type FluffyServiceTaxAmount = z.infer<typeof FluffyServiceTaxAmountSchema>;

export const FluffyServiceLineNumberSchema = z.object({
  assigned_number_01: z.number(),
});
export type FluffyServiceLineNumber = z.infer<typeof FluffyServiceLineNumberSchema>;

export const FluffyRepricedLineItemReferenceNumberSchema = z.object({
  reference_identification_qualifier_01:
    RepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema,
  repriced_line_item_reference_number_02: z.string(),
});
export type FluffyRepricedLineItemReferenceNumber = z.infer<
  typeof FluffyRepricedLineItemReferenceNumberSchema
>;

export const AmbitiousReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type AmbitiousReferenceIdentifier = z.infer<typeof AmbitiousReferenceIdentifierSchema>;

export const StickyRenderingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([AmbitiousReferenceIdentifierSchema, z.null()]).optional(),
  rendering_provider_secondary_identifier_02: z.string(),
});
export type StickyRenderingProviderSecondaryIdentification = z.infer<
  typeof StickyRenderingProviderSecondaryIdentificationSchema
>;

export const StickyRenderingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  rendering_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  rendering_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  rendering_provider_last_name_03: z.string(),
  rendering_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  rendering_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type StickyRenderingProviderName = z.infer<typeof StickyRenderingProviderNameSchema>;

export const StickyRenderingProviderNameLoopSchema = z.object({
  rendering_provider_name_NM1: StickyRenderingProviderNameSchema,
  rendering_provider_secondary_identification_REF: z
    .union([z.array(StickyRenderingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type StickyRenderingProviderNameLoop = z.infer<typeof StickyRenderingProviderNameLoopSchema>;

export const HilariousReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type HilariousReferenceIdentifier = z.infer<typeof HilariousReferenceIdentifierSchema>;

export const StickyReferringProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([HilariousReferenceIdentifierSchema, z.null()]).optional(),
  referring_provider_secondary_identifier_02: z.string(),
});
export type StickyReferringProviderSecondaryIdentification = z.infer<
  typeof StickyReferringProviderSecondaryIdentificationSchema
>;

export const StickyReferringProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  referring_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  referring_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  referring_provider_last_name_03: z.string(),
  referring_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  referring_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type StickyReferringProviderName = z.infer<typeof StickyReferringProviderNameSchema>;

export const StickyReferringProviderNameLoopSchema = z.object({
  referring_provider_name_NM1: StickyReferringProviderNameSchema,
  referring_provider_secondary_identification_REF: z
    .union([z.array(StickyReferringProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type StickyReferringProviderNameLoop = z.infer<typeof StickyReferringProviderNameLoopSchema>;

export const IndecentReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type IndecentReferenceIdentifier = z.infer<typeof IndecentReferenceIdentifierSchema>;

export const StickyOtherOperatingPhysicianSecondaryIdentificationSchema = z.object({
  other_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([IndecentReferenceIdentifierSchema, z.null()]).optional(),
});
export type StickyOtherOperatingPhysicianSecondaryIdentification = z.infer<
  typeof StickyOtherOperatingPhysicianSecondaryIdentificationSchema
>;

export const StickyOtherOperatingPhysicianNameSchema = z.object({
  entity_identifier_code_01: CodeQualifierSchema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  other_operating_physician_first_name_04: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_identifier_09: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_last_name_03: z.string(),
  other_operating_physician_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type StickyOtherOperatingPhysicianName = z.infer<
  typeof StickyOtherOperatingPhysicianNameSchema
>;

export const StickyOtherOperatingPhysicianNameLoopSchema = z.object({
  other_operating_physician_name_NM1: StickyOtherOperatingPhysicianNameSchema,
  other_operating_physician_secondary_identification_REF: z
    .union([z.array(StickyOtherOperatingPhysicianSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type StickyOtherOperatingPhysicianNameLoop = z.infer<
  typeof StickyOtherOperatingPhysicianNameLoopSchema
>;

export const IndigoReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type IndigoReferenceIdentifier = z.infer<typeof IndigoReferenceIdentifierSchema>;

export const StickyOperatingPhysicianSecondaryIdentificationSchema = z.object({
  operating_physician_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([IndigoReferenceIdentifierSchema, z.null()]).optional(),
});
export type StickyOperatingPhysicianSecondaryIdentification = z.infer<
  typeof StickyOperatingPhysicianSecondaryIdentificationSchema
>;

export const StickyOperatingPhysicianNameSchema = z.object({
  entity_identifier_code_01: OperatingPhysicianNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  operating_physician_first_name_04: z.union([z.null(), z.string()]).optional(),
  operating_physician_last_name_03: z.string(),
  operating_physician_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  operating_physician_name_suffix_07: z.union([z.null(), z.string()]).optional(),
  operating_physician_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type StickyOperatingPhysicianName = z.infer<typeof StickyOperatingPhysicianNameSchema>;

export const StickyOperatingPhysicianNameLoopSchema = z.object({
  operating_physician_name_NM1: StickyOperatingPhysicianNameSchema,
  operating_physician_secondary_identification_REF: z
    .union([z.array(StickyOperatingPhysicianSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type StickyOperatingPhysicianNameLoop = z.infer<
  typeof StickyOperatingPhysicianNameLoopSchema
>;

export const FluffyLineSupplementalInformationSchema = z.object({
  attachment_control_number_06: z.union([z.null(), z.string()]).optional(),
  attachment_report_type_code_01: AttachmentReportTypeCodeSchema,
  attachment_transmission_code_02: AttachmentTransmissionCodeSchema,
  identification_code_qualifier_05: z
    .union([IdentificationCodeQualifier05_EnumSchema, z.null()])
    .optional(),
});
export type FluffyLineSupplementalInformation = z.infer<
  typeof FluffyLineSupplementalInformationSchema
>;

export const FluffyLinePricingRepricingInformationSchema = z.object({
  exception_code_15: z.union([ExceptionCodeSchema, z.null()]).optional(),
  monetary_amount_02: z.number(),
  monetary_amount_03: z.union([z.number(), z.null()]).optional(),
  monetary_amount_07: z.union([z.number(), z.null()]).optional(),
  policy_compliance_code_14: z.union([PolicyComplianceCodeSchema, z.null()]).optional(),
  pricing_methodology_01: PricingMethodologySchema,
  product_or_service_id_08: z.union([z.null(), z.string()]).optional(),
  product_or_service_id_qualifier_09: z
    .union([ProductOrServiceIdQualifier01_EnumSchema, z.null()])
    .optional(),
  quantity_12: z.union([z.number(), z.null()]).optional(),
  rate_05: z.union([z.number(), z.null()]).optional(),
  reference_identification_04: z.union([z.null(), z.string()]).optional(),
  reference_identification_06: z.union([z.null(), z.string()]).optional(),
  reject_reason_code_13: z.union([RejectReasonCodeSchema, z.null()]).optional(),
  repriced_approved_hcpcs_code_10: z.union([z.null(), z.string()]).optional(),
  unit_or_basis_for_measurement_code_11: z
    .union([UnitOrBasisForMeasurementCodeSchema, z.null()])
    .optional(),
});
export type FluffyLinePricingRepricingInformation = z.infer<
  typeof FluffyLinePricingRepricingInformationSchema
>;

export const FluffyLineItemControlNumberSchema = z.object({
  line_item_control_number_02: z.string(),
  reference_identification_qualifier_01:
    LineItemControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyLineItemControlNumber = z.infer<typeof FluffyLineItemControlNumberSchema>;

export const StickyRemainingPatientLiabilitySchema = z.object({
  amount_qualifier_code_01: RemainingPatientLiabilityAmtAmountQualifierCode01Schema,
  remaining_patient_liability_02: z.number(),
});
export type StickyRemainingPatientLiability = z.infer<typeof StickyRemainingPatientLiabilitySchema>;

export const FluffyLineCheckOrRemittanceDateSchema = z.object({
  adjudication_or_payment_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier01_EnumSchema,
  date_time_qualifier_01: ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema,
});
export type FluffyLineCheckOrRemittanceDate = z.infer<typeof FluffyLineCheckOrRemittanceDateSchema>;

export const FluffyLineAdjustmentSchema = z.object({
  adjustment_amount_03: z.number(),
  adjustment_amount_06: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_09: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_12: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_15: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_18: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_04: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_07: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_10: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_13: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_16: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_19: z.union([z.number(), z.null()]).optional(),
  adjustment_reason_code_02: z.string(),
  adjustment_reason_code_05: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_08: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_11: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_14: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_17: z.union([z.null(), z.string()]).optional(),
  claim_adjustment_group_code_01: ClaimAdjustmentGroupCodeSchema,
});
export type FluffyLineAdjustment = z.infer<typeof FluffyLineAdjustmentSchema>;

export const StickyCompositeMedicalProcedureIdentifierSchema = z.object({
  procedure_code_02: z.string(),
  procedure_code_description_07: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_03: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_04: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_05: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_06: z.union([z.null(), z.string()]).optional(),
  product_or_service_id_qualifier_01: ProductOrServiceIdQualifier01_EnumSchema,
});
export type StickyCompositeMedicalProcedureIdentifier = z.infer<
  typeof StickyCompositeMedicalProcedureIdentifierSchema
>;

export const FluffyLineAdjudicationInformationSchema = z.object({
  bundled_line_number_06: z.union([z.number(), z.null()]).optional(),
  composite_medical_procedure_identifier_03: z
    .union([StickyCompositeMedicalProcedureIdentifierSchema, z.null()])
    .optional(),
  other_payer_primary_identifier_01: z.string(),
  paid_service_unit_count_05: z.number(),
  service_line_paid_amount_02: z.number(),
  service_line_revenue_code_04: z.string(),
});
export type FluffyLineAdjudicationInformation = z.infer<
  typeof FluffyLineAdjudicationInformationSchema
>;

export const FluffyLineAdjudicationInformationLoopSchema = z.object({
  line_adjudication_information_SVD: FluffyLineAdjudicationInformationSchema,
  line_adjustment_CAS: z.union([z.array(FluffyLineAdjustmentSchema), z.null()]).optional(),
  line_check_or_remittance_date_DTP: FluffyLineCheckOrRemittanceDateSchema,
  remaining_patient_liability_AMT: z
    .union([StickyRemainingPatientLiabilitySchema, z.null()])
    .optional(),
});
export type FluffyLineAdjudicationInformationLoop = z.infer<
  typeof FluffyLineAdjudicationInformationLoopSchema
>;

export const TentacledCompositeMedicalProcedureIdentifierSchema = z.object({
  description_07: z.union([z.null(), z.string()]).optional(),
  procedure_code_02: z.string(),
  procedure_modifier_03: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_04: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_05: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_06: z.union([z.null(), z.string()]).optional(),
  product_or_service_id_qualifier_01: ProductOrServiceIdQualifier01_EnumSchema,
});
export type TentacledCompositeMedicalProcedureIdentifier = z.infer<
  typeof TentacledCompositeMedicalProcedureIdentifierSchema
>;

export const FluffyInstitutionalServiceLineSchema = z.object({
  composite_medical_procedure_identifier_02: z
    .union([TentacledCompositeMedicalProcedureIdentifierSchema, z.null()])
    .optional(),
  line_item_charge_amount_03: z.number(),
  line_item_denied_charge_or_non_covered_charge_amount_07: z
    .union([z.number(), z.null()])
    .optional(),
  service_line_revenue_code_01: z.string(),
  service_unit_count_05: z.number(),
  unit_or_basis_for_measurement_code_04: UnitOrBasisForMeasurementCodeSchema,
});
export type FluffyInstitutionalServiceLine = z.infer<typeof FluffyInstitutionalServiceLineSchema>;

export const FluffyFacilityTaxAmountSchema = z.object({
  amount_qualifier_code_01: FacilityTaxAmountAmtAmountQualifierCode01Schema,
  facility_tax_amount_02: z.number(),
});
export type FluffyFacilityTaxAmount = z.infer<typeof FluffyFacilityTaxAmountSchema>;

export const FluffyPrescriptionOrCompoundDrugAssociationNumberSchema = z.object({
  prescription_number_02: z.string(),
  reference_identification_qualifier_01:
    PrescriptionOrCompoundDrugAssociationNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyPrescriptionOrCompoundDrugAssociationNumber = z.infer<
  typeof FluffyPrescriptionOrCompoundDrugAssociationNumberSchema
>;

export const FluffyCompositeUnitOfMeasureSchema = z.object({
  code_qualifier_01: CompositeUnitOfMeasure05_CodeQualifier01Schema,
});
export type FluffyCompositeUnitOfMeasure = z.infer<typeof FluffyCompositeUnitOfMeasureSchema>;

export const FluffyDrugQuantitySchema = z.object({
  composite_unit_of_measure_05: FluffyCompositeUnitOfMeasureSchema,
  national_drug_unit_count_04: z.number(),
});
export type FluffyDrugQuantity = z.infer<typeof FluffyDrugQuantitySchema>;

export const FluffyDrugIdentificationSchema = z.object({
  national_drug_code_03: z.string(),
  product_or_service_id_qualifier_02: ProductOrServiceIdQualifier02_EnumSchema,
});
export type FluffyDrugIdentification = z.infer<typeof FluffyDrugIdentificationSchema>;

export const FluffyDrugIdentificationLoopSchema = z.object({
  drug_identification_LIN: FluffyDrugIdentificationSchema,
  drug_quantity_CTP: FluffyDrugQuantitySchema,
  prescription_or_compound_drug_association_number_REF: z
    .union([FluffyPrescriptionOrCompoundDrugAssociationNumberSchema, z.null()])
    .optional(),
});
export type FluffyDrugIdentificationLoop = z.infer<typeof FluffyDrugIdentificationLoopSchema>;

export const FluffyDateServiceDateSchema = z.object({
  date_time_period_format_qualifier_02: DateServiceDateDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: DateServiceDateDtpDateTimeQualifier01Schema,
  service_date_03: z.string(),
});
export type FluffyDateServiceDate = z.infer<typeof FluffyDateServiceDateSchema>;

export const FluffyAdjustedRepricedLineItemReferenceNumberSchema = z.object({
  adjusted_repriced_line_item_reference_number_02: z.string(),
  reference_identification_qualifier_01:
    AdjustedRepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyAdjustedRepricedLineItemReferenceNumber = z.infer<
  typeof FluffyAdjustedRepricedLineItemReferenceNumberSchema
>;

export const FluffyServiceLineNumberLoopSchema = z.object({
  adjusted_repriced_line_item_reference_number_REF: z
    .union([FluffyAdjustedRepricedLineItemReferenceNumberSchema, z.null()])
    .optional(),
  date_service_date_DTP: z.union([FluffyDateServiceDateSchema, z.null()]).optional(),
  drug_identification_LIN_loop: z.union([FluffyDrugIdentificationLoopSchema, z.null()]).optional(),
  facility_tax_amount_AMT: z.union([FluffyFacilityTaxAmountSchema, z.null()]).optional(),
  institutional_service_line_SV2: FluffyInstitutionalServiceLineSchema,
  line_adjudication_information_SVD_loop: z
    .union([z.array(FluffyLineAdjudicationInformationLoopSchema), z.null()])
    .optional(),
  line_item_control_number_REF: z.union([FluffyLineItemControlNumberSchema, z.null()]).optional(),
  line_pricing_repricing_information_HCP: z
    .union([FluffyLinePricingRepricingInformationSchema, z.null()])
    .optional(),
  line_supplemental_information_PWK: z
    .union([z.array(FluffyLineSupplementalInformationSchema), z.null()])
    .optional(),
  operating_physician_name_NM1_loop: z
    .union([StickyOperatingPhysicianNameLoopSchema, z.null()])
    .optional(),
  other_operating_physician_name_NM1_loop: z
    .union([StickyOtherOperatingPhysicianNameLoopSchema, z.null()])
    .optional(),
  referring_provider_name_NM1_loop: z
    .union([StickyReferringProviderNameLoopSchema, z.null()])
    .optional(),
  rendering_provider_name_NM1_loop: z
    .union([StickyRenderingProviderNameLoopSchema, z.null()])
    .optional(),
  repriced_line_item_reference_number_REF: z
    .union([FluffyRepricedLineItemReferenceNumberSchema, z.null()])
    .optional(),
  service_line_number_LX: FluffyServiceLineNumberSchema,
  service_tax_amount_AMT: z.union([FluffyServiceTaxAmountSchema, z.null()]).optional(),
  third_party_organization_notes_NTE: z
    .union([FluffyThirdPartyOrganizationNotesSchema, z.null()])
    .optional(),
});
export type FluffyServiceLineNumberLoop = z.infer<typeof FluffyServiceLineNumberLoopSchema>;

export const FluffyServiceFacilityLocationSecondaryIdentificationSchema = z.object({
  laboratory_or_facility_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyServiceFacilityLocationSecondaryIdentification = z.infer<
  typeof FluffyServiceFacilityLocationSecondaryIdentificationSchema
>;

export const FluffyServiceFacilityLocationNameSchema = z.object({
  entity_identifier_code_01: OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  laboratory_or_facility_name_03: z.string(),
  laboratory_or_facility_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type FluffyServiceFacilityLocationName = z.infer<
  typeof FluffyServiceFacilityLocationNameSchema
>;

export const FluffyServiceFacilityLocationCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_city_name_01: z.string(),
  laboratory_or_facility_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyServiceFacilityLocationCityStateZipCode = z.infer<
  typeof FluffyServiceFacilityLocationCityStateZipCodeSchema
>;

export const FluffyServiceFacilityLocationAddressSchema = z.object({
  laboratory_or_facility_address_line_01: z.string(),
  laboratory_or_facility_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyServiceFacilityLocationAddress = z.infer<
  typeof FluffyServiceFacilityLocationAddressSchema
>;

export const FluffyServiceFacilityLocationNameLoopSchema = z.object({
  service_facility_location_address_N3: FluffyServiceFacilityLocationAddressSchema,
  service_facility_location_city_state_zip_code_N4:
    FluffyServiceFacilityLocationCityStateZipCodeSchema,
  service_facility_location_name_NM1: FluffyServiceFacilityLocationNameSchema,
  service_facility_location_secondary_identification_REF: z
    .union([z.array(FluffyServiceFacilityLocationSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type FluffyServiceFacilityLocationNameLoop = z.infer<
  typeof FluffyServiceFacilityLocationNameLoopSchema
>;

export const FluffyServiceAuthorizationExceptionCodeSchema = z.object({
  reference_identification_qualifier_01:
    ServiceAuthorizationExceptionCodeRefReferenceIdentificationQualifier01Schema,
  service_authorization_exception_code_02: z.string(),
});
export type FluffyServiceAuthorizationExceptionCode = z.infer<
  typeof FluffyServiceAuthorizationExceptionCodeSchema
>;

export const FluffyRepricedClaimNumberSchema = z.object({
  reference_identification_qualifier_01:
    RepricedClaimNumberRefReferenceIdentificationQualifier01Schema,
  repriced_claim_reference_number_02: z.string(),
});
export type FluffyRepricedClaimNumber = z.infer<typeof FluffyRepricedClaimNumberSchema>;

export const TentacledRenderingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  rendering_provider_secondary_identifier_02: z.string(),
});
export type TentacledRenderingProviderSecondaryIdentification = z.infer<
  typeof TentacledRenderingProviderSecondaryIdentificationSchema
>;

export const TentacledRenderingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  rendering_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  rendering_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  rendering_provider_last_name_03: z.string(),
  rendering_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  rendering_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type TentacledRenderingProviderName = z.infer<typeof TentacledRenderingProviderNameSchema>;

export const TentacledRenderingProviderNameLoopSchema = z.object({
  rendering_provider_name_NM1: TentacledRenderingProviderNameSchema,
  rendering_provider_secondary_identification_REF: z
    .union([z.array(TentacledRenderingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type TentacledRenderingProviderNameLoop = z.infer<
  typeof TentacledRenderingProviderNameLoopSchema
>;

export const TentacledReferringProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  referring_provider_secondary_identifier_02: z.string(),
});
export type TentacledReferringProviderSecondaryIdentification = z.infer<
  typeof TentacledReferringProviderSecondaryIdentificationSchema
>;

export const TentacledReferringProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  referring_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  referring_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  referring_provider_last_name_03: z.string(),
  referring_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  referring_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type TentacledReferringProviderName = z.infer<typeof TentacledReferringProviderNameSchema>;

export const TentacledReferringProviderNameLoopSchema = z.object({
  referring_provider_name_NM1: TentacledReferringProviderNameSchema,
  referring_provider_secondary_identification_REF: z
    .union([z.array(TentacledReferringProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type TentacledReferringProviderNameLoop = z.infer<
  typeof TentacledReferringProviderNameLoopSchema
>;

export const FluffyReferralNumberSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema,
  referral_number_02: z.string(),
});
export type FluffyReferralNumber = z.infer<typeof FluffyReferralNumberSchema>;

export const FluffyPriorAuthorizationSchema = z.object({
  prior_authorization_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyPriorAuthorization = z.infer<typeof FluffyPriorAuthorizationSchema>;

export const HealthCareCodeInformation169Schema = z.object({
  code_list_qualifier_code_01: MagentaCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  principal_procedure_code_02: z.string(),
  principal_procedure_date_04: z.string(),
});
export type HealthCareCodeInformation169 = z.infer<typeof HealthCareCodeInformation169Schema>;

export const FluffyPrincipalProcedureInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation169Schema,
});
export type FluffyPrincipalProcedureInformation = z.infer<
  typeof FluffyPrincipalProcedureInformationSchema
>;

export const HealthCareCodeInformation168Schema = z.object({
  code_list_qualifier_code_01: CunningCodeListQualifierCodeSchema,
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
  principal_diagnosis_code_02: z.string(),
});
export type HealthCareCodeInformation168 = z.infer<typeof HealthCareCodeInformation168Schema>;

export const FluffyPrincipalDiagnosisSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation168Schema,
});
export type FluffyPrincipalDiagnosis = z.infer<typeof FluffyPrincipalDiagnosisSchema>;

export const FluffyPeerReviewOrganizationProApprovalNumberSchema = z.object({
  peer_review_authorization_number_02: z.string(),
  reference_identification_qualifier_01:
    PeerReviewOrganizationProApprovalNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyPeerReviewOrganizationProApprovalNumber = z.infer<
  typeof FluffyPeerReviewOrganizationProApprovalNumberSchema
>;

export const FluffyPayerClaimControlNumberSchema = z.object({
  payer_claim_control_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyPayerClaimControlNumber = z.infer<typeof FluffyPayerClaimControlNumberSchema>;

export const HealthCareCodeInformation167Schema = z.object({
  code_list_qualifier_code_01: AmbitiousCodeListQualifierCodeSchema,
  patient_reason_for_visit_02: z.string(),
});
export type HealthCareCodeInformation167 = z.infer<typeof HealthCareCodeInformation167Schema>;

export const HealthCareCodeInformation166Schema = z.object({
  code_list_qualifier_code_01: AmbitiousCodeListQualifierCodeSchema,
  patient_reason_for_visit_02: z.string(),
});
export type HealthCareCodeInformation166 = z.infer<typeof HealthCareCodeInformation166Schema>;

export const HealthCareCodeInformation165Schema = z.object({
  code_list_qualifier_code_01: AmbitiousCodeListQualifierCodeSchema,
  patient_reason_for_visit_02: z.string(),
});
export type HealthCareCodeInformation165 = z.infer<typeof HealthCareCodeInformation165Schema>;

export const FluffyPatientSReasonForVisitSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation165Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation166Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation167Schema, z.null()])
    .optional(),
});
export type FluffyPatientSReasonForVisit = z.infer<typeof FluffyPatientSReasonForVisitSchema>;

export const FluffyPatientEstimatedAmountDueSchema = z.object({
  amount_qualifier_code_01: PatientEstimatedAmountDueAmtAmountQualifierCode01Schema,
  patient_responsibility_amount_02: z.number(),
});
export type FluffyPatientEstimatedAmountDue = z.infer<typeof FluffyPatientEstimatedAmountDueSchema>;

export const TentacledRemainingPatientLiabilitySchema = z.object({
  amount_qualifier_code_01: RemainingPatientLiabilityAmtAmountQualifierCode01Schema,
  remaining_patient_liability_02: z.number(),
});
export type TentacledRemainingPatientLiability = z.infer<
  typeof TentacledRemainingPatientLiabilitySchema
>;

export const FluffyOutpatientAdjudicationInformationSchema = z.object({
  claim_payment_remark_code_03: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_04: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_05: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_06: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_07: z.union([z.null(), z.string()]).optional(),
  end_stage_renal_disease_payment_amount_08: z.union([z.number(), z.null()]).optional(),
  hcpcs_payable_amount_02: z.union([z.number(), z.null()]).optional(),
  non_payable_professional_component_billed_amount_09: z.union([z.number(), z.null()]).optional(),
  reimbursement_rate_01: z.union([z.number(), z.null()]).optional(),
});
export type FluffyOutpatientAdjudicationInformation = z.infer<
  typeof FluffyOutpatientAdjudicationInformationSchema
>;

export const FluffyOtherSubscriberSecondaryIdentificationSchema = z.object({
  other_insured_additional_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherSubscriberSecondaryIdentification = z.infer<
  typeof FluffyOtherSubscriberSecondaryIdentificationSchema
>;

export const FluffyOtherSubscriberNameSchema = z.object({
  entity_identifier_code_01: OtherSubscriberNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherSubscriberNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: OtherSubscriberNameNm1IdentificationCodeQualifier08Schema,
  other_insured_first_name_04: z.union([z.null(), z.string()]).optional(),
  other_insured_identifier_09: z.string(),
  other_insured_last_name_03: z.string(),
  other_insured_middle_name_05: z.union([z.null(), z.string()]).optional(),
  other_insured_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOtherSubscriberName = z.infer<typeof FluffyOtherSubscriberNameSchema>;

export const FluffyOtherSubscriberCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  other_insured_city_name_01: z.string(),
  other_insured_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  other_insured_state_code_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOtherSubscriberCityStateZipCode = z.infer<
  typeof FluffyOtherSubscriberCityStateZipCodeSchema
>;

export const FluffyOtherSubscriberAddressSchema = z.object({
  other_insured_address_line_01: z.string(),
  other_insured_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOtherSubscriberAddress = z.infer<typeof FluffyOtherSubscriberAddressSchema>;

export const FluffyOtherSubscriberNameLoopSchema = z.object({
  other_subscriber_address_N3: z.union([FluffyOtherSubscriberAddressSchema, z.null()]).optional(),
  other_subscriber_city_state_zip_code_N4: z
    .union([FluffyOtherSubscriberCityStateZipCodeSchema, z.null()])
    .optional(),
  other_subscriber_name_NM1: FluffyOtherSubscriberNameSchema,
  other_subscriber_secondary_identification_REF: z
    .union([z.array(FluffyOtherSubscriberSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type FluffyOtherSubscriberNameLoop = z.infer<typeof FluffyOtherSubscriberNameLoopSchema>;

export const FluffyOtherSubscriberInformationSchema = z.object({
  claim_filing_indicator_code_09: ClaimFilingIndicatorCodeSchema,
  individual_relationship_code_02: OtherSubscriberInformationSbrIndividualRelationshipCode02Schema,
  insured_group_or_policy_number_03: z.union([z.null(), z.string()]).optional(),
  other_insured_group_name_04: z.union([z.null(), z.string()]).optional(),
  payer_responsibility_sequence_number_code_01: PayerResponsibilitySequenceNumberCodeSchema,
});
export type FluffyOtherSubscriberInformation = z.infer<
  typeof FluffyOtherSubscriberInformationSchema
>;

export const FluffyOtherPayerServiceFacilityLocationSecondaryIdentificationSchema = z.object({
  other_payer_service_facility_location_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerServiceFacilityLocationSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerServiceFacilityLocationSecondaryIdentificationSchema
>;

export const FluffyOtherPayerServiceFacilityLocationSchema = z.object({
  entity_identifier_code_01: OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
});
export type FluffyOtherPayerServiceFacilityLocation = z.infer<
  typeof FluffyOtherPayerServiceFacilityLocationSchema
>;

export const FluffyOtherPayerServiceFacilityLocationLoopSchema = z.object({
  other_payer_service_facility_location_NM1: FluffyOtherPayerServiceFacilityLocationSchema,
  other_payer_service_facility_location_secondary_identification_REF: z.array(
    FluffyOtherPayerServiceFacilityLocationSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerServiceFacilityLocationLoop = z.infer<
  typeof FluffyOtherPayerServiceFacilityLocationLoopSchema
>;

export const FluffyOtherPayerRenderingProviderSecondaryIdentificationSchema = z.object({
  other_payer_rendering_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerRenderingProviderSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerRenderingProviderSecondaryIdentificationSchema
>;

export const FluffyOtherPayerRenderingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
});
export type FluffyOtherPayerRenderingProviderName = z.infer<
  typeof FluffyOtherPayerRenderingProviderNameSchema
>;

export const FluffyOtherPayerRenderingProviderNameLoopSchema = z.object({
  other_payer_rendering_provider_name_NM1: FluffyOtherPayerRenderingProviderNameSchema,
  other_payer_rendering_provider_secondary_identification_REF: z.array(
    FluffyOtherPayerRenderingProviderSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerRenderingProviderNameLoop = z.infer<
  typeof FluffyOtherPayerRenderingProviderNameLoopSchema
>;

export const FluffyOtherPayerReferringProviderSecondaryIdentificationSchema = z.object({
  other_payer_referring_provider_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerReferringProviderSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerReferringProviderSecondaryIdentificationSchema
>;

export const FluffyOtherPayerReferringProviderSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
});
export type FluffyOtherPayerReferringProvider = z.infer<
  typeof FluffyOtherPayerReferringProviderSchema
>;

export const FluffyOtherPayerReferringProviderLoopSchema = z.object({
  other_payer_referring_provider_NM1: FluffyOtherPayerReferringProviderSchema,
  other_payer_referring_provider_secondary_identification_REF: z.array(
    FluffyOtherPayerReferringProviderSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerReferringProviderLoop = z.infer<
  typeof FluffyOtherPayerReferringProviderLoopSchema
>;

export const FluffyOtherPayerOtherOperatingPhysicianSecondaryIdentificationSchema = z.object({
  other_payer_other_operating_physician_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerOtherOperatingPhysicianSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerOtherOperatingPhysicianSecondaryIdentificationSchema
>;

export const FluffyOtherPayerOtherOperatingPhysicianSchema = z.object({
  entity_identifier_code_01: CodeQualifierSchema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
});
export type FluffyOtherPayerOtherOperatingPhysician = z.infer<
  typeof FluffyOtherPayerOtherOperatingPhysicianSchema
>;

export const FluffyOtherPayerOtherOperatingPhysicianLoopSchema = z.object({
  other_payer_other_operating_physician_NM1: FluffyOtherPayerOtherOperatingPhysicianSchema,
  other_payer_other_operating_physician_secondary_identification_REF: z.array(
    FluffyOtherPayerOtherOperatingPhysicianSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerOtherOperatingPhysicianLoop = z.infer<
  typeof FluffyOtherPayerOtherOperatingPhysicianLoopSchema
>;

export const FluffyOtherPayerOperatingPhysicianSecondaryIdentificationSchema = z.object({
  other_payer_operating_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerOperatingPhysicianSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerOperatingPhysicianSecondaryIdentificationSchema
>;

export const FluffyOtherPayerOperatingPhysicianSchema = z.object({
  entity_identifier_code_01: OperatingPhysicianNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
});
export type FluffyOtherPayerOperatingPhysician = z.infer<
  typeof FluffyOtherPayerOperatingPhysicianSchema
>;

export const FluffyOtherPayerOperatingPhysicianLoopSchema = z.object({
  other_payer_operating_physician_NM1: FluffyOtherPayerOperatingPhysicianSchema,
  other_payer_operating_physician_secondary_identification_REF: z.array(
    FluffyOtherPayerOperatingPhysicianSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerOperatingPhysicianLoop = z.infer<
  typeof FluffyOtherPayerOperatingPhysicianLoopSchema
>;

export const FluffyOtherPayerSecondaryIdentifierSchema = z.object({
  other_payer_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerSecondaryIdentifier = z.infer<
  typeof FluffyOtherPayerSecondaryIdentifierSchema
>;

export const FluffyOtherPayerReferralNumberSchema = z.object({
  other_payer_prior_authorization_or_referral_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerReferralNumber = z.infer<typeof FluffyOtherPayerReferralNumberSchema>;

export const FluffyOtherPayerPriorAuthorizationNumberSchema = z.object({
  other_payer_prior_authorization_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerPriorAuthorizationNumber = z.infer<
  typeof FluffyOtherPayerPriorAuthorizationNumberSchema
>;

export const FluffyOtherPayerNameSchema = z.object({
  entity_identifier_code_01: OtherPayerNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: PayToPlanNameNm1IdentificationCodeQualifier08Schema,
  other_payer_last_or_organization_name_03: z.string(),
  other_payer_primary_identifier_09: z.string(),
});
export type FluffyOtherPayerName = z.infer<typeof FluffyOtherPayerNameSchema>;

export const FluffyOtherPayerClaimControlNumberSchema = z.object({
  other_payers_claim_control_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerClaimControlNumber = z.infer<
  typeof FluffyOtherPayerClaimControlNumberSchema
>;

export const FluffyOtherPayerClaimAdjustmentIndicatorSchema = z.object({
  other_payer_claim_adjustment_indicator_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimAdjustmentIndicatorRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerClaimAdjustmentIndicator = z.infer<
  typeof FluffyOtherPayerClaimAdjustmentIndicatorSchema
>;

export const FluffyOtherPayerCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  other_payer_city_name_01: z.string(),
  other_payer_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  other_payer_state_code_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOtherPayerCityStateZipCode = z.infer<
  typeof FluffyOtherPayerCityStateZipCodeSchema
>;

export const FluffyOtherPayerAddressSchema = z.object({
  other_payer_address_line_01: z.string(),
  other_payer_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOtherPayerAddress = z.infer<typeof FluffyOtherPayerAddressSchema>;

export const FluffyClaimCheckOrRemittanceDateSchema = z.object({
  adjudication_or_payment_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier01_EnumSchema,
  date_time_qualifier_01: ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema,
});
export type FluffyClaimCheckOrRemittanceDate = z.infer<
  typeof FluffyClaimCheckOrRemittanceDateSchema
>;

export const FluffyOtherPayerNameLoopSchema = z.object({
  claim_check_or_remittance_date_DTP: z
    .union([FluffyClaimCheckOrRemittanceDateSchema, z.null()])
    .optional(),
  other_payer_address_N3: z.union([FluffyOtherPayerAddressSchema, z.null()]).optional(),
  other_payer_city_state_zip_code_N4: z
    .union([FluffyOtherPayerCityStateZipCodeSchema, z.null()])
    .optional(),
  other_payer_claim_adjustment_indicator_REF: z
    .union([FluffyOtherPayerClaimAdjustmentIndicatorSchema, z.null()])
    .optional(),
  other_payer_claim_control_number_REF: z
    .union([FluffyOtherPayerClaimControlNumberSchema, z.null()])
    .optional(),
  other_payer_name_NM1: FluffyOtherPayerNameSchema,
  other_payer_prior_authorization_number_REF: z
    .union([FluffyOtherPayerPriorAuthorizationNumberSchema, z.null()])
    .optional(),
  other_payer_referral_number_REF: z
    .union([FluffyOtherPayerReferralNumberSchema, z.null()])
    .optional(),
  other_payer_secondary_identifier_REF: z
    .union([z.array(FluffyOtherPayerSecondaryIdentifierSchema), z.null()])
    .optional(),
});
export type FluffyOtherPayerNameLoop = z.infer<typeof FluffyOtherPayerNameLoopSchema>;

export const FluffyOtherPayerBillingProviderSecondaryIdentificationSchema = z.object({
  other_payer_billing_provider_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerBillingProviderSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerBillingProviderSecondaryIdentificationSchema
>;

export const FluffyOtherPayerBillingProviderSchema = z.object({
  entity_identifier_code_01: BillingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
});
export type FluffyOtherPayerBillingProvider = z.infer<typeof FluffyOtherPayerBillingProviderSchema>;

export const FluffyOtherPayerBillingProviderLoopSchema = z.object({
  other_payer_billing_provider_NM1: FluffyOtherPayerBillingProviderSchema,
  other_payer_billing_provider_secondary_identification_REF: z.array(
    FluffyOtherPayerBillingProviderSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerBillingProviderLoop = z.infer<
  typeof FluffyOtherPayerBillingProviderLoopSchema
>;

export const FluffyOtherPayerAttendingProviderSecondaryIdentificationSchema = z.object({
  other_payer_attending_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyOtherPayerAttendingProviderSecondaryIdentification = z.infer<
  typeof FluffyOtherPayerAttendingProviderSecondaryIdentificationSchema
>;

export const FluffyOtherPayerAttendingProviderSchema = z.object({
  entity_identifier_code_01: AttendingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
});
export type FluffyOtherPayerAttendingProvider = z.infer<
  typeof FluffyOtherPayerAttendingProviderSchema
>;

export const FluffyOtherPayerAttendingProviderLoopSchema = z.object({
  other_payer_attending_provider_NM1: FluffyOtherPayerAttendingProviderSchema,
  other_payer_attending_provider_secondary_identification_REF: z.array(
    FluffyOtherPayerAttendingProviderSecondaryIdentificationSchema
  ),
});
export type FluffyOtherPayerAttendingProviderLoop = z.infer<
  typeof FluffyOtherPayerAttendingProviderLoopSchema
>;

export const FluffyOtherInsuranceCoverageInformationSchema = z.object({
  benefits_assignment_certification_indicator_03: BenefitsAssignmentCertificationIndicatorSchema,
  release_of_information_code_06: ReleaseOfInformationCodeSchema,
});
export type FluffyOtherInsuranceCoverageInformation = z.infer<
  typeof FluffyOtherInsuranceCoverageInformationSchema
>;

export const FluffyInpatientAdjudicationInformationSchema = z.object({
  claim_disproportionate_share_amount_06: z.union([z.number(), z.null()]).optional(),
  claim_drg_amount_04: z.union([z.number(), z.null()]).optional(),
  claim_indirect_teaching_amount_18: z.union([z.number(), z.null()]).optional(),
  claim_msp_pass_through_amount_07: z.union([z.number(), z.null()]).optional(),
  claim_payment_remark_code_05: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_20: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_21: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_22: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_23: z.union([z.null(), z.string()]).optional(),
  claim_pps_capital_amount_08: z.union([z.number(), z.null()]).optional(),
  claim_pps_capital_outlier_amount_17: z.union([z.number(), z.null()]).optional(),
  cost_report_day_count_15: z.union([z.number(), z.null()]).optional(),
  covered_days_or_visits_count_01: z.number(),
  lifetime_psychiatric_days_count_03: z.union([z.number(), z.null()]).optional(),
  non_payable_professional_component_billed_amount_19: z.union([z.number(), z.null()]).optional(),
  old_capital_amount_12: z.union([z.number(), z.null()]).optional(),
  pps_capital_dsh_drg_amount_11: z.union([z.number(), z.null()]).optional(),
  pps_capital_exception_amount_24: z.union([z.number(), z.null()]).optional(),
  pps_capital_fsp_drg_amount_09: z.union([z.number(), z.null()]).optional(),
  pps_capital_hsp_drg_amount_10: z.union([z.number(), z.null()]).optional(),
  pps_capital_ime_amount_13: z.union([z.number(), z.null()]).optional(),
  pps_operating_federal_specific_drg_amount_16: z.union([z.number(), z.null()]).optional(),
  pps_operating_hospital_specific_drg_amount_14: z.union([z.number(), z.null()]).optional(),
});
export type FluffyInpatientAdjudicationInformation = z.infer<
  typeof FluffyInpatientAdjudicationInformationSchema
>;

export const FluffyCoordinationOfBenefitsCobTotalNonCoveredAmountSchema = z.object({
  amount_qualifier_code_01:
    CoordinationOfBenefitsCobTotalNonCoveredAmountAmtAmountQualifierCode01Schema,
  non_covered_charge_amount_02: z.number(),
});
export type FluffyCoordinationOfBenefitsCobTotalNonCoveredAmount = z.infer<
  typeof FluffyCoordinationOfBenefitsCobTotalNonCoveredAmountSchema
>;

export const FluffyCoordinationOfBenefitsCobPayerPaidAmountSchema = z.object({
  amount_qualifier_code_01: CoordinationOfBenefitsCobPayerPaidAmountAmtAmountQualifierCode01Schema,
  payer_paid_amount_02: z.number(),
});
export type FluffyCoordinationOfBenefitsCobPayerPaidAmount = z.infer<
  typeof FluffyCoordinationOfBenefitsCobPayerPaidAmountSchema
>;

export const FluffyClaimLevelAdjustmentsSchema = z.object({
  adjustment_amount_03: z.number(),
  adjustment_amount_06: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_09: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_12: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_15: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_18: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_04: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_07: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_10: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_13: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_16: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_19: z.union([z.number(), z.null()]).optional(),
  adjustment_reason_code_02: z.string(),
  adjustment_reason_code_05: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_08: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_11: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_14: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_17: z.union([z.null(), z.string()]).optional(),
  claim_adjustment_group_code_01: ClaimAdjustmentGroupCodeSchema,
});
export type FluffyClaimLevelAdjustments = z.infer<typeof FluffyClaimLevelAdjustmentsSchema>;

export const FluffyOtherSubscriberInformationLoopSchema = z.object({
  claim_level_adjustments_CAS: z
    .union([z.array(FluffyClaimLevelAdjustmentsSchema), z.null()])
    .optional(),
  coordination_of_benefits_cob_payer_paid_amount_AMT: z
    .union([FluffyCoordinationOfBenefitsCobPayerPaidAmountSchema, z.null()])
    .optional(),
  coordination_of_benefits_cob_total_non_covered_amount_AMT: z
    .union([FluffyCoordinationOfBenefitsCobTotalNonCoveredAmountSchema, z.null()])
    .optional(),
  inpatient_adjudication_information_MIA: z
    .union([FluffyInpatientAdjudicationInformationSchema, z.null()])
    .optional(),
  other_insurance_coverage_information_OI: FluffyOtherInsuranceCoverageInformationSchema,
  other_payer_attending_provider_NM1_loop: z
    .union([FluffyOtherPayerAttendingProviderLoopSchema, z.null()])
    .optional(),
  other_payer_billing_provider_NM1_loop: z
    .union([FluffyOtherPayerBillingProviderLoopSchema, z.null()])
    .optional(),
  other_payer_name_NM1_loop: FluffyOtherPayerNameLoopSchema,
  other_payer_operating_physician_NM1_loop: z
    .union([FluffyOtherPayerOperatingPhysicianLoopSchema, z.null()])
    .optional(),
  other_payer_other_operating_physician_NM1_loop: z
    .union([FluffyOtherPayerOtherOperatingPhysicianLoopSchema, z.null()])
    .optional(),
  other_payer_referring_provider_NM1_loop: z
    .union([FluffyOtherPayerReferringProviderLoopSchema, z.null()])
    .optional(),
  other_payer_rendering_provider_name_NM1_loop: z
    .union([FluffyOtherPayerRenderingProviderNameLoopSchema, z.null()])
    .optional(),
  other_payer_service_facility_location_NM1_loop: z
    .union([FluffyOtherPayerServiceFacilityLocationLoopSchema, z.null()])
    .optional(),
  other_subscriber_information_SBR: FluffyOtherSubscriberInformationSchema,
  other_subscriber_name_NM1_loop: FluffyOtherSubscriberNameLoopSchema,
  outpatient_adjudication_information_MOA: z
    .union([FluffyOutpatientAdjudicationInformationSchema, z.null()])
    .optional(),
  remaining_patient_liability_AMT: z
    .union([TentacledRemainingPatientLiabilitySchema, z.null()])
    .optional(),
});
export type FluffyOtherSubscriberInformationLoop = z.infer<
  typeof FluffyOtherSubscriberInformationLoopSchema
>;

export const HealthCareCodeInformation164Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation164 = z.infer<typeof HealthCareCodeInformation164Schema>;

export const HealthCareCodeInformation163Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation163 = z.infer<typeof HealthCareCodeInformation163Schema>;

export const HealthCareCodeInformation162Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation162 = z.infer<typeof HealthCareCodeInformation162Schema>;

export const HealthCareCodeInformation161Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation161 = z.infer<typeof HealthCareCodeInformation161Schema>;

export const HealthCareCodeInformation160Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation160 = z.infer<typeof HealthCareCodeInformation160Schema>;

export const HealthCareCodeInformation159Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation159 = z.infer<typeof HealthCareCodeInformation159Schema>;

export const HealthCareCodeInformation158Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation158 = z.infer<typeof HealthCareCodeInformation158Schema>;

export const HealthCareCodeInformation157Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation157 = z.infer<typeof HealthCareCodeInformation157Schema>;

export const HealthCareCodeInformation156Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation156 = z.infer<typeof HealthCareCodeInformation156Schema>;

export const HealthCareCodeInformation155Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation155 = z.infer<typeof HealthCareCodeInformation155Schema>;

export const HealthCareCodeInformation154Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation154 = z.infer<typeof HealthCareCodeInformation154Schema>;

export const HealthCareCodeInformation153Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation153 = z.infer<typeof HealthCareCodeInformation153Schema>;

export const FluffyOtherProcedureInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation153Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation154Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation155Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation156Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation157Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation158Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation159Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation160Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation161Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation162Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation163Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation164Schema, z.null()])
    .optional(),
});
export type FluffyOtherProcedureInformation = z.infer<typeof FluffyOtherProcedureInformationSchema>;

export const TentacledOtherOperatingPhysicianSecondaryIdentificationSchema = z.object({
  other_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type TentacledOtherOperatingPhysicianSecondaryIdentification = z.infer<
  typeof TentacledOtherOperatingPhysicianSecondaryIdentificationSchema
>;

export const TentacledOtherOperatingPhysicianNameSchema = z.object({
  entity_identifier_code_01: CodeQualifierSchema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  other_operating_physician_first_name_04: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_identifier_09: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_last_name_03: z.string(),
  other_operating_physician_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type TentacledOtherOperatingPhysicianName = z.infer<
  typeof TentacledOtherOperatingPhysicianNameSchema
>;

export const TentacledOtherOperatingPhysicianNameLoopSchema = z.object({
  other_operating_physician_name_NM1: TentacledOtherOperatingPhysicianNameSchema,
  other_operating_physician_secondary_identification_REF: z
    .union([z.array(TentacledOtherOperatingPhysicianSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type TentacledOtherOperatingPhysicianNameLoop = z.infer<
  typeof TentacledOtherOperatingPhysicianNameLoopSchema
>;

export const HealthCareCodeInformation152Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation152 = z.infer<typeof HealthCareCodeInformation152Schema>;

export const HealthCareCodeInformation151Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation151 = z.infer<typeof HealthCareCodeInformation151Schema>;

export const HealthCareCodeInformation150Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation150 = z.infer<typeof HealthCareCodeInformation150Schema>;

export const HealthCareCodeInformation149Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation149 = z.infer<typeof HealthCareCodeInformation149Schema>;

export const HealthCareCodeInformation148Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation148 = z.infer<typeof HealthCareCodeInformation148Schema>;

export const HealthCareCodeInformation147Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation147 = z.infer<typeof HealthCareCodeInformation147Schema>;

export const HealthCareCodeInformation146Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation146 = z.infer<typeof HealthCareCodeInformation146Schema>;

export const HealthCareCodeInformation145Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation145 = z.infer<typeof HealthCareCodeInformation145Schema>;

export const HealthCareCodeInformation144Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation144 = z.infer<typeof HealthCareCodeInformation144Schema>;

export const HealthCareCodeInformation143Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation143 = z.infer<typeof HealthCareCodeInformation143Schema>;

export const HealthCareCodeInformation142Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation142 = z.infer<typeof HealthCareCodeInformation142Schema>;

export const HealthCareCodeInformation141Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation141 = z.infer<typeof HealthCareCodeInformation141Schema>;

export const FluffyOtherDiagnosisInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation141Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation142Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation143Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation144Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation145Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation146Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation147Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation148Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation149Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation150Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation151Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation152Schema, z.null()])
    .optional(),
});
export type FluffyOtherDiagnosisInformation = z.infer<typeof FluffyOtherDiagnosisInformationSchema>;

export const TentacledOperatingPhysicianSecondaryIdentificationSchema = z.object({
  operating_physician_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type TentacledOperatingPhysicianSecondaryIdentification = z.infer<
  typeof TentacledOperatingPhysicianSecondaryIdentificationSchema
>;

export const TentacledOperatingPhysicianNameSchema = z.object({
  entity_identifier_code_01: OperatingPhysicianNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  operating_physician_first_name_04: z.union([z.null(), z.string()]).optional(),
  operating_physician_last_name_03: z.string(),
  operating_physician_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  operating_physician_name_suffix_07: z.union([z.null(), z.string()]).optional(),
  operating_physician_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type TentacledOperatingPhysicianName = z.infer<typeof TentacledOperatingPhysicianNameSchema>;

export const TentacledOperatingPhysicianNameLoopSchema = z.object({
  operating_physician_name_NM1: TentacledOperatingPhysicianNameSchema,
  operating_physician_secondary_identification_REF: z
    .union([z.array(TentacledOperatingPhysicianSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type TentacledOperatingPhysicianNameLoop = z.infer<
  typeof TentacledOperatingPhysicianNameLoopSchema
>;

export const HealthCareCodeInformation140Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation140 = z.infer<typeof HealthCareCodeInformation140Schema>;

export const HealthCareCodeInformation139Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation139 = z.infer<typeof HealthCareCodeInformation139Schema>;

export const HealthCareCodeInformation138Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation138 = z.infer<typeof HealthCareCodeInformation138Schema>;

export const HealthCareCodeInformation137Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation137 = z.infer<typeof HealthCareCodeInformation137Schema>;

export const HealthCareCodeInformation136Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation136 = z.infer<typeof HealthCareCodeInformation136Schema>;

export const HealthCareCodeInformation135Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation135 = z.infer<typeof HealthCareCodeInformation135Schema>;

export const HealthCareCodeInformation134Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation134 = z.infer<typeof HealthCareCodeInformation134Schema>;

export const HealthCareCodeInformation133Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation133 = z.infer<typeof HealthCareCodeInformation133Schema>;

export const HealthCareCodeInformation132Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation132 = z.infer<typeof HealthCareCodeInformation132Schema>;

export const HealthCareCodeInformation131Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation131 = z.infer<typeof HealthCareCodeInformation131Schema>;

export const HealthCareCodeInformation130Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation130 = z.infer<typeof HealthCareCodeInformation130Schema>;

export const HealthCareCodeInformation129Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation129 = z.infer<typeof HealthCareCodeInformation129Schema>;

export const FluffyOccurrenceSpanInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation129Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation130Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation131Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation132Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation133Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation134Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation135Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation136Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation137Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation138Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation139Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation140Schema, z.null()])
    .optional(),
});
export type FluffyOccurrenceSpanInformation = z.infer<typeof FluffyOccurrenceSpanInformationSchema>;

export const HealthCareCodeInformation128Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation128 = z.infer<typeof HealthCareCodeInformation128Schema>;

export const HealthCareCodeInformation127Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation127 = z.infer<typeof HealthCareCodeInformation127Schema>;

export const HealthCareCodeInformation126Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation126 = z.infer<typeof HealthCareCodeInformation126Schema>;

export const HealthCareCodeInformation125Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation125 = z.infer<typeof HealthCareCodeInformation125Schema>;

export const HealthCareCodeInformation124Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation124 = z.infer<typeof HealthCareCodeInformation124Schema>;

export const HealthCareCodeInformation123Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation123 = z.infer<typeof HealthCareCodeInformation123Schema>;

export const HealthCareCodeInformation122Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation122 = z.infer<typeof HealthCareCodeInformation122Schema>;

export const HealthCareCodeInformation121Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation121 = z.infer<typeof HealthCareCodeInformation121Schema>;

export const HealthCareCodeInformation120Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation120 = z.infer<typeof HealthCareCodeInformation120Schema>;

export const HealthCareCodeInformation119Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation119 = z.infer<typeof HealthCareCodeInformation119Schema>;

export const HealthCareCodeInformation118Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation118 = z.infer<typeof HealthCareCodeInformation118Schema>;

export const HealthCareCodeInformation117Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation117 = z.infer<typeof HealthCareCodeInformation117Schema>;

export const FluffyOccurrenceInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation117Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation118Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation119Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation120Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation121Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation122Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation123Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation124Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation125Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation126Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation127Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation128Schema, z.null()])
    .optional(),
});
export type FluffyOccurrenceInformation = z.infer<typeof FluffyOccurrenceInformationSchema>;

export const FluffyMedicalRecordNumberSchema = z.object({
  medical_record_number_02: z.string(),
  reference_identification_qualifier_01:
    MedicalRecordNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyMedicalRecordNumber = z.infer<typeof FluffyMedicalRecordNumberSchema>;

export const FluffyInvestigationalDeviceExemptionNumberSchema = z.object({
  investigational_device_exemption_identifier_02: z.string(),
  reference_identification_qualifier_01:
    InvestigationalDeviceExemptionNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyInvestigationalDeviceExemptionNumber = z.infer<
  typeof FluffyInvestigationalDeviceExemptionNumberSchema
>;

export const FluffyInstitutionalClaimCodeSchema = z.object({
  admission_source_code_02: z.union([z.null(), z.string()]).optional(),
  admission_type_code_01: z.string(),
  patient_status_code_03: z.string(),
});
export type FluffyInstitutionalClaimCode = z.infer<typeof FluffyInstitutionalClaimCodeSchema>;

export const FluffyFileInformationSchema = z.object({
  fixed_format_information_01: z.string(),
});
export type FluffyFileInformation = z.infer<typeof FluffyFileInformationSchema>;

export const HealthCareCodeInformation116Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation116 = z.infer<typeof HealthCareCodeInformation116Schema>;

export const HealthCareCodeInformation115Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation115 = z.infer<typeof HealthCareCodeInformation115Schema>;

export const HealthCareCodeInformation114Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation114 = z.infer<typeof HealthCareCodeInformation114Schema>;

export const HealthCareCodeInformation113Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation113 = z.infer<typeof HealthCareCodeInformation113Schema>;

export const HealthCareCodeInformation112Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation112 = z.infer<typeof HealthCareCodeInformation112Schema>;

export const HealthCareCodeInformation111Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation111 = z.infer<typeof HealthCareCodeInformation111Schema>;

export const HealthCareCodeInformation110Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation110 = z.infer<typeof HealthCareCodeInformation110Schema>;

export const HealthCareCodeInformation109Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation109 = z.infer<typeof HealthCareCodeInformation109Schema>;

export const HealthCareCodeInformation108Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation108 = z.infer<typeof HealthCareCodeInformation108Schema>;

export const HealthCareCodeInformation107Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation107 = z.infer<typeof HealthCareCodeInformation107Schema>;

export const HealthCareCodeInformation106Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation106 = z.infer<typeof HealthCareCodeInformation106Schema>;

export const HealthCareCodeInformation105Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation105 = z.infer<typeof HealthCareCodeInformation105Schema>;

export const FluffyExternalCauseOfInjurySchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation105Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation106Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation107Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation108Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation109Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation110Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation111Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation112Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation113Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation114Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation115Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation116Schema, z.null()])
    .optional(),
});
export type FluffyExternalCauseOfInjury = z.infer<typeof FluffyExternalCauseOfInjurySchema>;

export const FluffyEpsdtReferralSchema = z.object({
  certification_condition_code_applies_indicator_02:
    CertificationConditionCodeAppliesIndicatorSchema,
  code_qualifier_01: CodeQualifierSchema,
  condition_indicator_03: ConditionIndicatorSchema,
  condition_indicator_04: z.union([z.null(), z.string()]).optional(),
  condition_indicator_05: z.union([z.null(), z.string()]).optional(),
});
export type FluffyEpsdtReferral = z.infer<typeof FluffyEpsdtReferralSchema>;

export const FluffyDischargeHourSchema = z.object({
  date_time_period_format_qualifier_02: DischargeHourDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: DischargeHourDtpDateTimeQualifier01Schema,
  discharge_time_03: z.string(),
});
export type FluffyDischargeHour = z.infer<typeof FluffyDischargeHourSchema>;

export const HealthCareCodeInformation104Schema = z.object({
  code_list_qualifier_code_01: TentacledCodeListQualifierCodeSchema,
  diagnosis_related_group_drg_code_02: z.string(),
});
export type HealthCareCodeInformation104 = z.infer<typeof HealthCareCodeInformation104Schema>;

export const FluffyDiagnosisRelatedGroupDrgInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation104Schema,
});
export type FluffyDiagnosisRelatedGroupDrgInformation = z.infer<
  typeof FluffyDiagnosisRelatedGroupDrgInformationSchema
>;

export const FluffyDemonstrationProjectIdentifierSchema = z.object({
  demonstration_project_identifier_02: z.string(),
  reference_identification_qualifier_01:
    DemonstrationProjectIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type FluffyDemonstrationProjectIdentifier = z.infer<
  typeof FluffyDemonstrationProjectIdentifierSchema
>;

export const FluffyDateRepricerReceivedDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier01_EnumSchema,
  date_time_qualifier_01: DateRepricerReceivedDateDtpDateTimeQualifier01Schema,
  repricer_received_date_03: z.string(),
});
export type FluffyDateRepricerReceivedDate = z.infer<typeof FluffyDateRepricerReceivedDateSchema>;

export const FluffyContractInformationSchema = z.object({
  contract_amount_02: z.union([z.number(), z.null()]).optional(),
  contract_code_04: z.union([z.null(), z.string()]).optional(),
  contract_percentage_03: z.union([z.number(), z.null()]).optional(),
  contract_type_code_01: ContractTypeCodeSchema,
  contract_version_identifier_06: z.union([z.null(), z.string()]).optional(),
  terms_discount_percentage_05: z.union([z.number(), z.null()]).optional(),
});
export type FluffyContractInformation = z.infer<typeof FluffyContractInformationSchema>;

export const HealthCareCodeInformation103Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation103 = z.infer<typeof HealthCareCodeInformation103Schema>;

export const HealthCareCodeInformation102Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation102 = z.infer<typeof HealthCareCodeInformation102Schema>;

export const HealthCareCodeInformation101Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation101 = z.infer<typeof HealthCareCodeInformation101Schema>;

export const HealthCareCodeInformation100Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation100 = z.infer<typeof HealthCareCodeInformation100Schema>;

export const HealthCareCodeInformation99Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation99 = z.infer<typeof HealthCareCodeInformation99Schema>;

export const HealthCareCodeInformation98Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation98 = z.infer<typeof HealthCareCodeInformation98Schema>;

export const HealthCareCodeInformation97Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation97 = z.infer<typeof HealthCareCodeInformation97Schema>;

export const HealthCareCodeInformation96Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation96 = z.infer<typeof HealthCareCodeInformation96Schema>;

export const HealthCareCodeInformation95Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation95 = z.infer<typeof HealthCareCodeInformation95Schema>;

export const HealthCareCodeInformation94Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation94 = z.infer<typeof HealthCareCodeInformation94Schema>;

export const HealthCareCodeInformation93Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation93 = z.infer<typeof HealthCareCodeInformation93Schema>;

export const HealthCareCodeInformation92Schema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HealthCareCodeInformation92 = z.infer<typeof HealthCareCodeInformation92Schema>;

export const FluffyConditionInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation92Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation93Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation94Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation95Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation96Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation97Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation98Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation99Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation100Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation101Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation102Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation103Schema, z.null()])
    .optional(),
});
export type FluffyConditionInformation = z.infer<typeof FluffyConditionInformationSchema>;

export const FluffyClaimSupplementalInformationSchema = z.object({
  attachment_control_number_06: z.union([z.null(), z.string()]).optional(),
  attachment_report_type_code_01: AttachmentReportTypeCodeSchema,
  attachment_transmission_code_02: AttachmentTransmissionCodeSchema,
  identification_code_qualifier_05: z
    .union([IdentificationCodeQualifier05_EnumSchema, z.null()])
    .optional(),
});
export type FluffyClaimSupplementalInformation = z.infer<
  typeof FluffyClaimSupplementalInformationSchema
>;

export const FluffyClaimPricingRepricingInformationSchema = z.object({
  exception_code_15: z.union([ExceptionCodeSchema, z.null()]).optional(),
  policy_compliance_code_14: z.union([PolicyComplianceCodeSchema, z.null()]).optional(),
  pricing_methodology_01: PricingMethodologySchema,
  reject_reason_code_13: z.union([RejectReasonCodeSchema, z.null()]).optional(),
  repriced_allowed_amount_02: z.number(),
  repriced_approved_amount_07: z.union([z.number(), z.null()]).optional(),
  repriced_approved_drg_code_06: z.union([z.null(), z.string()]).optional(),
  repriced_approved_revenue_code_08: z.union([z.null(), z.string()]).optional(),
  repriced_approved_service_unit_count_12: z.union([z.number(), z.null()]).optional(),
  repriced_saving_amount_03: z.union([z.number(), z.null()]).optional(),
  repricing_organization_identifier_04: z.union([z.null(), z.string()]).optional(),
  repricing_per_diem_or_flat_rate_amount_05: z.union([z.number(), z.null()]).optional(),
  unit_or_basis_for_measurement_code_11: z
    .union([UnitOrBasisForMeasurementCodeSchema, z.null()])
    .optional(),
});
export type FluffyClaimPricingRepricingInformation = z.infer<
  typeof FluffyClaimPricingRepricingInformationSchema
>;

export const FluffyClaimNoteSchema = z.object({
  claim_note_text_02: z.string(),
  note_reference_code_01: ClaimNoteNteNoteReferenceCode01Schema,
});
export type FluffyClaimNote = z.infer<typeof FluffyClaimNoteSchema>;

export const FluffyHealthCareServiceLocationInformationSchema = z.object({
  claim_frequency_code_03: z.string(),
  facility_code_qualifier_02: FacilityCodeQualifierSchema,
  facility_type_code_01: z.string(),
});
export type FluffyHealthCareServiceLocationInformation = z.infer<
  typeof FluffyHealthCareServiceLocationInformationSchema
>;

export const FluffyClaimInformationSchema = z.object({
  assignment_or_plan_participation_code_07: AssignmentOrPlanParticipationCodeSchema,
  benefits_assignment_certification_indicator_08: BenefitsAssignmentCertificationIndicatorSchema,
  delay_reason_code_20: z.union([DelayReasonCodeSchema, z.null()]).optional(),
  health_care_service_location_information_05: FluffyHealthCareServiceLocationInformationSchema,
  patient_control_number_01: z.string(),
  release_of_information_code_09: ReleaseOfInformationCodeSchema,
  total_claim_charge_amount_02: z.number(),
});
export type FluffyClaimInformation = z.infer<typeof FluffyClaimInformationSchema>;

export const FluffyClaimIdentifierForTransmissionIntermediariesSchema = z.object({
  reference_identification_qualifier_01:
    ClaimIdentifierForTransmissionIntermediariesRefReferenceIdentificationQualifier01Schema,
  value_added_network_trace_number_02: z.string(),
});
export type FluffyClaimIdentifierForTransmissionIntermediaries = z.infer<
  typeof FluffyClaimIdentifierForTransmissionIntermediariesSchema
>;

export const FluffyBillingNoteSchema = z.object({
  billing_note_text_02: z.string(),
  note_reference_code_01: BillingNoteNteNoteReferenceCode01Schema,
});
export type FluffyBillingNote = z.infer<typeof FluffyBillingNoteSchema>;

export const FluffyAutoAccidentStateSchema = z.object({
  auto_accident_state_or_province_code_02: z.string(),
  reference_identification_qualifier_01:
    AutoAccidentStateRefReferenceIdentificationQualifier01Schema,
});
export type FluffyAutoAccidentState = z.infer<typeof FluffyAutoAccidentStateSchema>;

export const FluffyAttendingProviderSpecialtyInformationSchema = z.object({
  provider_code_01: ProviderCodeSchema,
  provider_taxonomy_code_03: z.string(),
  reference_identification_qualifier_02: ReferenceIdentificationQualifier02_EnumSchema,
});
export type FluffyAttendingProviderSpecialtyInformation = z.infer<
  typeof FluffyAttendingProviderSpecialtyInformationSchema
>;

export const FluffyAttendingProviderSecondaryIdentificationSchema = z.object({
  attending_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type FluffyAttendingProviderSecondaryIdentification = z.infer<
  typeof FluffyAttendingProviderSecondaryIdentificationSchema
>;

export const FluffyAttendingProviderNameSchema = z.object({
  attending_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  attending_provider_last_name_03: z.string(),
  attending_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  attending_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
  attending_provider_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
  entity_identifier_code_01: AttendingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
});
export type FluffyAttendingProviderName = z.infer<typeof FluffyAttendingProviderNameSchema>;

export const FluffyAttendingProviderNameLoopSchema = z.object({
  attending_provider_name_NM1: FluffyAttendingProviderNameSchema,
  attending_provider_secondary_identification_REF: z
    .union([z.array(FluffyAttendingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
  attending_provider_specialty_information_PRV: z
    .union([FluffyAttendingProviderSpecialtyInformationSchema, z.null()])
    .optional(),
});
export type FluffyAttendingProviderNameLoop = z.infer<typeof FluffyAttendingProviderNameLoopSchema>;

export const HealthCareCodeInformation91Schema = z.object({
  admitting_diagnosis_code_02: z.string(),
  code_list_qualifier_code_01: PurpleCodeListQualifierCodeSchema,
});
export type HealthCareCodeInformation91 = z.infer<typeof HealthCareCodeInformation91Schema>;

export const FluffyAdmittingDiagnosisSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation91Schema,
});
export type FluffyAdmittingDiagnosis = z.infer<typeof FluffyAdmittingDiagnosisSchema>;

export const FluffyAdmissionDateHourSchema = z.object({
  admission_date_and_hour_03: z.string(),
  date_time_period_format_qualifier_02: AdmissionDateHourDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: AdmissionDateHourDtpDateTimeQualifier01Schema,
});
export type FluffyAdmissionDateHour = z.infer<typeof FluffyAdmissionDateHourSchema>;

export const FluffyAdjustedRepricedClaimNumberSchema = z.object({
  adjusted_repriced_claim_reference_number_02: z.string(),
  reference_identification_qualifier_01:
    AdjustedRepricedClaimNumberRefReferenceIdentificationQualifier01Schema,
});
export type FluffyAdjustedRepricedClaimNumber = z.infer<
  typeof FluffyAdjustedRepricedClaimNumberSchema
>;

export const PatientHierarchicalLevelHlLoopClaimInformationClmLoopSchema = z.object({
  adjusted_repriced_claim_number_REF: z
    .union([FluffyAdjustedRepricedClaimNumberSchema, z.null()])
    .optional(),
  admission_date_hour_DTP: z.union([FluffyAdmissionDateHourSchema, z.null()]).optional(),
  admitting_diagnosis_HI: z.union([FluffyAdmittingDiagnosisSchema, z.null()]).optional(),
  attending_provider_name_NM1_loop: z
    .union([FluffyAttendingProviderNameLoopSchema, z.null()])
    .optional(),
  auto_accident_state_REF: z.union([FluffyAutoAccidentStateSchema, z.null()]).optional(),
  billing_note_NTE: z.union([FluffyBillingNoteSchema, z.null()]).optional(),
  claim_identifier_for_transmission_intermediaries_REF: z
    .union([FluffyClaimIdentifierForTransmissionIntermediariesSchema, z.null()])
    .optional(),
  claim_information_CLM: FluffyClaimInformationSchema,
  claim_note_NTE: z.union([z.array(FluffyClaimNoteSchema), z.null()]).optional(),
  claim_pricing_repricing_information_HCP: z
    .union([FluffyClaimPricingRepricingInformationSchema, z.null()])
    .optional(),
  claim_supplemental_information_PWK: z
    .union([z.array(FluffyClaimSupplementalInformationSchema), z.null()])
    .optional(),
  condition_information_HI: z
    .union([z.array(FluffyConditionInformationSchema), z.null()])
    .optional(),
  contract_information_CN1: z.union([FluffyContractInformationSchema, z.null()]).optional(),
  date_repricer_received_date_DTP: z
    .union([FluffyDateRepricerReceivedDateSchema, z.null()])
    .optional(),
  demonstration_project_identifier_REF: z
    .union([FluffyDemonstrationProjectIdentifierSchema, z.null()])
    .optional(),
  diagnosis_related_group_drg_information_HI: z
    .union([FluffyDiagnosisRelatedGroupDrgInformationSchema, z.null()])
    .optional(),
  discharge_hour_DTP: z.union([FluffyDischargeHourSchema, z.null()]).optional(),
  epsdt_referral_CRC: z.union([FluffyEpsdtReferralSchema, z.null()]).optional(),
  external_cause_of_injury_HI: z.union([FluffyExternalCauseOfInjurySchema, z.null()]).optional(),
  file_information_K3: z.union([z.array(FluffyFileInformationSchema), z.null()]).optional(),
  institutional_claim_code_CL1: FluffyInstitutionalClaimCodeSchema,
  investigational_device_exemption_number_REF: z
    .union([z.array(FluffyInvestigationalDeviceExemptionNumberSchema), z.null()])
    .optional(),
  medical_record_number_REF: z.union([FluffyMedicalRecordNumberSchema, z.null()]).optional(),
  occurrence_information_HI: z
    .union([z.array(FluffyOccurrenceInformationSchema), z.null()])
    .optional(),
  occurrence_span_information_HI: z
    .union([z.array(FluffyOccurrenceSpanInformationSchema), z.null()])
    .optional(),
  operating_physician_name_NM1_loop: z
    .union([TentacledOperatingPhysicianNameLoopSchema, z.null()])
    .optional(),
  other_diagnosis_information_HI: z
    .union([z.array(FluffyOtherDiagnosisInformationSchema), z.null()])
    .optional(),
  other_operating_physician_name_NM1_loop: z
    .union([TentacledOtherOperatingPhysicianNameLoopSchema, z.null()])
    .optional(),
  other_procedure_information_HI: z
    .union([z.array(FluffyOtherProcedureInformationSchema), z.null()])
    .optional(),
  other_subscriber_information_SBR_loop: z
    .union([z.array(FluffyOtherSubscriberInformationLoopSchema), z.null()])
    .optional(),
  patient_estimated_amount_due_AMT: z
    .union([FluffyPatientEstimatedAmountDueSchema, z.null()])
    .optional(),
  patients_reason_for_visit_HI: z.union([FluffyPatientSReasonForVisitSchema, z.null()]).optional(),
  payer_claim_control_number_REF: z
    .union([FluffyPayerClaimControlNumberSchema, z.null()])
    .optional(),
  peer_review_organization_pro_approval_number_REF: z
    .union([FluffyPeerReviewOrganizationProApprovalNumberSchema, z.null()])
    .optional(),
  principal_diagnosis_HI: FluffyPrincipalDiagnosisSchema,
  principal_procedure_information_HI: z
    .union([FluffyPrincipalProcedureInformationSchema, z.null()])
    .optional(),
  prior_authorization_REF: z.union([FluffyPriorAuthorizationSchema, z.null()]).optional(),
  referral_number_REF: z.union([FluffyReferralNumberSchema, z.null()]).optional(),
  referring_provider_name_NM1_loop: z
    .union([TentacledReferringProviderNameLoopSchema, z.null()])
    .optional(),
  rendering_provider_name_NM1_loop: z
    .union([TentacledRenderingProviderNameLoopSchema, z.null()])
    .optional(),
  repriced_claim_number_REF: z.union([FluffyRepricedClaimNumberSchema, z.null()]).optional(),
  service_authorization_exception_code_REF: z
    .union([FluffyServiceAuthorizationExceptionCodeSchema, z.null()])
    .optional(),
  service_facility_location_name_NM1_loop: z
    .union([FluffyServiceFacilityLocationNameLoopSchema, z.null()])
    .optional(),
  service_line_number_LX_loop: z.array(FluffyServiceLineNumberLoopSchema),
  statement_dates_DTP: FluffyStatementDatesSchema,
  treatment_code_information_HI: z
    .union([z.array(FluffyTreatmentCodeInformationSchema), z.null()])
    .optional(),
  value_information_HI: z.union([z.array(FluffyValueInformationSchema), z.null()]).optional(),
});
export type PatientHierarchicalLevelHlLoopClaimInformationClmLoop = z.infer<
  typeof PatientHierarchicalLevelHlLoopClaimInformationClmLoopSchema
>;

export const PatientHierarchicalLevelLoopSchema = z.object({
  claim_information_CLM_loop: z.array(PatientHierarchicalLevelHlLoopClaimInformationClmLoopSchema),
  patient_information_PAT: PatientInformationSchema,
  patient_name_NM1_loop: PatientNameLoopSchema,
});
export type PatientHierarchicalLevelLoop = z.infer<typeof PatientHierarchicalLevelLoopSchema>;

export const HealthCareCodeInformation90Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation90 = z.infer<typeof HealthCareCodeInformation90Schema>;

export const HealthCareCodeInformation89Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation89 = z.infer<typeof HealthCareCodeInformation89Schema>;

export const HealthCareCodeInformation88Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation88 = z.infer<typeof HealthCareCodeInformation88Schema>;

export const HealthCareCodeInformation87Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation87 = z.infer<typeof HealthCareCodeInformation87Schema>;

export const HealthCareCodeInformation86Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation86 = z.infer<typeof HealthCareCodeInformation86Schema>;

export const HealthCareCodeInformation85Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation85 = z.infer<typeof HealthCareCodeInformation85Schema>;

export const HealthCareCodeInformation84Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation84 = z.infer<typeof HealthCareCodeInformation84Schema>;

export const HealthCareCodeInformation83Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation83 = z.infer<typeof HealthCareCodeInformation83Schema>;

export const HealthCareCodeInformation82Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation82 = z.infer<typeof HealthCareCodeInformation82Schema>;

export const HealthCareCodeInformation81Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation81 = z.infer<typeof HealthCareCodeInformation81Schema>;

export const HealthCareCodeInformation80Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation80 = z.infer<typeof HealthCareCodeInformation80Schema>;

export const HealthCareCodeInformation79Schema = z.object({
  code_list_qualifier_code_01: MischievousCodeListQualifierCodeSchema,
  value_code_02: z.string(),
  value_code_amount_05: z.number(),
});
export type HealthCareCodeInformation79 = z.infer<typeof HealthCareCodeInformation79Schema>;

export const PurpleValueInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation79Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation80Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation81Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation82Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation83Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation84Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation85Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation86Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation87Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation88Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation89Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation90Schema, z.null()])
    .optional(),
});
export type PurpleValueInformation = z.infer<typeof PurpleValueInformationSchema>;

export const HealthCareCodeInformation78Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation78 = z.infer<typeof HealthCareCodeInformation78Schema>;

export const HealthCareCodeInformation77Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation77 = z.infer<typeof HealthCareCodeInformation77Schema>;

export const HealthCareCodeInformation76Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation76 = z.infer<typeof HealthCareCodeInformation76Schema>;

export const HealthCareCodeInformation75Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation75 = z.infer<typeof HealthCareCodeInformation75Schema>;

export const HealthCareCodeInformation74Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation74 = z.infer<typeof HealthCareCodeInformation74Schema>;

export const HealthCareCodeInformation73Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation73 = z.infer<typeof HealthCareCodeInformation73Schema>;

export const HealthCareCodeInformation72Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation72 = z.infer<typeof HealthCareCodeInformation72Schema>;

export const HealthCareCodeInformation71Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation71 = z.infer<typeof HealthCareCodeInformation71Schema>;

export const HealthCareCodeInformation70Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation70 = z.infer<typeof HealthCareCodeInformation70Schema>;

export const HealthCareCodeInformation69Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation69 = z.infer<typeof HealthCareCodeInformation69Schema>;

export const HealthCareCodeInformation68Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation68 = z.infer<typeof HealthCareCodeInformation68Schema>;

export const HealthCareCodeInformation67Schema = z.object({
  code_list_qualifier_code_01: FriskyCodeListQualifierCodeSchema,
  treatment_code_02: z.string(),
});
export type HealthCareCodeInformation67 = z.infer<typeof HealthCareCodeInformation67Schema>;

export const PurpleTreatmentCodeInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation67Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation68Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation69Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation70Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation71Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation72Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation73Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation74Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation75Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation76Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation77Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation78Schema, z.null()])
    .optional(),
});
export type PurpleTreatmentCodeInformation = z.infer<typeof PurpleTreatmentCodeInformationSchema>;

export const PurpleStatementDatesSchema = z.object({
  date_time_period_format_qualifier_02: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: StatementDatesDtpDateTimeQualifier01Schema,
  statement_from_and_to_date_03: z.string(),
});
export type PurpleStatementDates = z.infer<typeof PurpleStatementDatesSchema>;

export const PurpleThirdPartyOrganizationNotesSchema = z.object({
  line_note_text_02: z.string(),
  note_reference_code_01: ThirdPartyOrganizationNotesNteNoteReferenceCode01Schema,
});
export type PurpleThirdPartyOrganizationNotes = z.infer<
  typeof PurpleThirdPartyOrganizationNotesSchema
>;

export const PurpleServiceTaxAmountSchema = z.object({
  amount_qualifier_code_01: ServiceTaxAmountAmtAmountQualifierCode01Schema,
  service_tax_amount_02: z.number(),
});
export type PurpleServiceTaxAmount = z.infer<typeof PurpleServiceTaxAmountSchema>;

export const PurpleServiceLineNumberSchema = z.object({
  assigned_number_01: z.number(),
});
export type PurpleServiceLineNumber = z.infer<typeof PurpleServiceLineNumberSchema>;

export const PurpleRepricedLineItemReferenceNumberSchema = z.object({
  reference_identification_qualifier_01:
    RepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema,
  repriced_line_item_reference_number_02: z.string(),
});
export type PurpleRepricedLineItemReferenceNumber = z.infer<
  typeof PurpleRepricedLineItemReferenceNumberSchema
>;

export const StickyReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type StickyReferenceIdentifier = z.infer<typeof StickyReferenceIdentifierSchema>;

export const FluffyRenderingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([StickyReferenceIdentifierSchema, z.null()]).optional(),
  rendering_provider_secondary_identifier_02: z.string(),
});
export type FluffyRenderingProviderSecondaryIdentification = z.infer<
  typeof FluffyRenderingProviderSecondaryIdentificationSchema
>;

export const FluffyRenderingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  rendering_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  rendering_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  rendering_provider_last_name_03: z.string(),
  rendering_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  rendering_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyRenderingProviderName = z.infer<typeof FluffyRenderingProviderNameSchema>;

export const FluffyRenderingProviderNameLoopSchema = z.object({
  rendering_provider_name_NM1: FluffyRenderingProviderNameSchema,
  rendering_provider_secondary_identification_REF: z
    .union([z.array(FluffyRenderingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type FluffyRenderingProviderNameLoop = z.infer<typeof FluffyRenderingProviderNameLoopSchema>;

export const TentacledReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type TentacledReferenceIdentifier = z.infer<typeof TentacledReferenceIdentifierSchema>;

export const FluffyReferringProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([TentacledReferenceIdentifierSchema, z.null()]).optional(),
  referring_provider_secondary_identifier_02: z.string(),
});
export type FluffyReferringProviderSecondaryIdentification = z.infer<
  typeof FluffyReferringProviderSecondaryIdentificationSchema
>;

export const FluffyReferringProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  referring_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  referring_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  referring_provider_last_name_03: z.string(),
  referring_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  referring_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyReferringProviderName = z.infer<typeof FluffyReferringProviderNameSchema>;

export const FluffyReferringProviderNameLoopSchema = z.object({
  referring_provider_name_NM1: FluffyReferringProviderNameSchema,
  referring_provider_secondary_identification_REF: z
    .union([z.array(FluffyReferringProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type FluffyReferringProviderNameLoop = z.infer<typeof FluffyReferringProviderNameLoopSchema>;

export const FluffyReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type FluffyReferenceIdentifier = z.infer<typeof FluffyReferenceIdentifierSchema>;

export const FluffyOtherOperatingPhysicianSecondaryIdentificationSchema = z.object({
  other_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([FluffyReferenceIdentifierSchema, z.null()]).optional(),
});
export type FluffyOtherOperatingPhysicianSecondaryIdentification = z.infer<
  typeof FluffyOtherOperatingPhysicianSecondaryIdentificationSchema
>;

export const FluffyOtherOperatingPhysicianNameSchema = z.object({
  entity_identifier_code_01: CodeQualifierSchema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  other_operating_physician_first_name_04: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_identifier_09: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_last_name_03: z.string(),
  other_operating_physician_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOtherOperatingPhysicianName = z.infer<
  typeof FluffyOtherOperatingPhysicianNameSchema
>;

export const FluffyOtherOperatingPhysicianNameLoopSchema = z.object({
  other_operating_physician_name_NM1: FluffyOtherOperatingPhysicianNameSchema,
  other_operating_physician_secondary_identification_REF: z
    .union([z.array(FluffyOtherOperatingPhysicianSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type FluffyOtherOperatingPhysicianNameLoop = z.infer<
  typeof FluffyOtherOperatingPhysicianNameLoopSchema
>;

export const PurpleReferenceIdentifierSchema = z.object({
  other_payer_primary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    ReferenceIdentifier04_ReferenceIdentificationQualifier01Schema,
});
export type PurpleReferenceIdentifier = z.infer<typeof PurpleReferenceIdentifierSchema>;

export const FluffyOperatingPhysicianSecondaryIdentificationSchema = z.object({
  operating_physician_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  reference_identifier_04: z.union([PurpleReferenceIdentifierSchema, z.null()]).optional(),
});
export type FluffyOperatingPhysicianSecondaryIdentification = z.infer<
  typeof FluffyOperatingPhysicianSecondaryIdentificationSchema
>;

export const FluffyOperatingPhysicianNameSchema = z.object({
  entity_identifier_code_01: OperatingPhysicianNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  operating_physician_first_name_04: z.union([z.null(), z.string()]).optional(),
  operating_physician_last_name_03: z.string(),
  operating_physician_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  operating_physician_name_suffix_07: z.union([z.null(), z.string()]).optional(),
  operating_physician_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type FluffyOperatingPhysicianName = z.infer<typeof FluffyOperatingPhysicianNameSchema>;

export const FluffyOperatingPhysicianNameLoopSchema = z.object({
  operating_physician_name_NM1: FluffyOperatingPhysicianNameSchema,
  operating_physician_secondary_identification_REF: z
    .union([z.array(FluffyOperatingPhysicianSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type FluffyOperatingPhysicianNameLoop = z.infer<
  typeof FluffyOperatingPhysicianNameLoopSchema
>;

export const PurpleLineSupplementalInformationSchema = z.object({
  attachment_control_number_06: z.union([z.null(), z.string()]).optional(),
  attachment_report_type_code_01: AttachmentReportTypeCodeSchema,
  attachment_transmission_code_02: AttachmentTransmissionCodeSchema,
  identification_code_qualifier_05: z
    .union([IdentificationCodeQualifier05_EnumSchema, z.null()])
    .optional(),
});
export type PurpleLineSupplementalInformation = z.infer<
  typeof PurpleLineSupplementalInformationSchema
>;

export const PurpleLinePricingRepricingInformationSchema = z.object({
  exception_code_15: z.union([ExceptionCodeSchema, z.null()]).optional(),
  monetary_amount_02: z.number(),
  monetary_amount_03: z.union([z.number(), z.null()]).optional(),
  monetary_amount_07: z.union([z.number(), z.null()]).optional(),
  policy_compliance_code_14: z.union([PolicyComplianceCodeSchema, z.null()]).optional(),
  pricing_methodology_01: PricingMethodologySchema,
  product_or_service_id_08: z.union([z.null(), z.string()]).optional(),
  product_or_service_id_qualifier_09: z
    .union([ProductOrServiceIdQualifier01_EnumSchema, z.null()])
    .optional(),
  quantity_12: z.union([z.number(), z.null()]).optional(),
  rate_05: z.union([z.number(), z.null()]).optional(),
  reference_identification_04: z.union([z.null(), z.string()]).optional(),
  reference_identification_06: z.union([z.null(), z.string()]).optional(),
  reject_reason_code_13: z.union([RejectReasonCodeSchema, z.null()]).optional(),
  repriced_approved_hcpcs_code_10: z.union([z.null(), z.string()]).optional(),
  unit_or_basis_for_measurement_code_11: z
    .union([UnitOrBasisForMeasurementCodeSchema, z.null()])
    .optional(),
});
export type PurpleLinePricingRepricingInformation = z.infer<
  typeof PurpleLinePricingRepricingInformationSchema
>;

export const PurpleLineItemControlNumberSchema = z.object({
  line_item_control_number_02: z.string(),
  reference_identification_qualifier_01:
    LineItemControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleLineItemControlNumber = z.infer<typeof PurpleLineItemControlNumberSchema>;

export const FluffyRemainingPatientLiabilitySchema = z.object({
  amount_qualifier_code_01: RemainingPatientLiabilityAmtAmountQualifierCode01Schema,
  remaining_patient_liability_02: z.number(),
});
export type FluffyRemainingPatientLiability = z.infer<typeof FluffyRemainingPatientLiabilitySchema>;

export const PurpleLineCheckOrRemittanceDateSchema = z.object({
  adjudication_or_payment_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier01_EnumSchema,
  date_time_qualifier_01: ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema,
});
export type PurpleLineCheckOrRemittanceDate = z.infer<typeof PurpleLineCheckOrRemittanceDateSchema>;

export const PurpleLineAdjustmentSchema = z.object({
  adjustment_amount_03: z.number(),
  adjustment_amount_06: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_09: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_12: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_15: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_18: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_04: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_07: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_10: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_13: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_16: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_19: z.union([z.number(), z.null()]).optional(),
  adjustment_reason_code_02: z.string(),
  adjustment_reason_code_05: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_08: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_11: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_14: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_17: z.union([z.null(), z.string()]).optional(),
  claim_adjustment_group_code_01: ClaimAdjustmentGroupCodeSchema,
});
export type PurpleLineAdjustment = z.infer<typeof PurpleLineAdjustmentSchema>;

export const FluffyCompositeMedicalProcedureIdentifierSchema = z.object({
  procedure_code_02: z.string(),
  procedure_code_description_07: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_03: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_04: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_05: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_06: z.union([z.null(), z.string()]).optional(),
  product_or_service_id_qualifier_01: ProductOrServiceIdQualifier01_EnumSchema,
});
export type FluffyCompositeMedicalProcedureIdentifier = z.infer<
  typeof FluffyCompositeMedicalProcedureIdentifierSchema
>;

export const PurpleLineAdjudicationInformationSchema = z.object({
  bundled_line_number_06: z.union([z.number(), z.null()]).optional(),
  composite_medical_procedure_identifier_03: z
    .union([FluffyCompositeMedicalProcedureIdentifierSchema, z.null()])
    .optional(),
  other_payer_primary_identifier_01: z.string(),
  paid_service_unit_count_05: z.number(),
  service_line_paid_amount_02: z.number(),
  service_line_revenue_code_04: z.string(),
});
export type PurpleLineAdjudicationInformation = z.infer<
  typeof PurpleLineAdjudicationInformationSchema
>;

export const PurpleLineAdjudicationInformationLoopSchema = z.object({
  line_adjudication_information_SVD: PurpleLineAdjudicationInformationSchema,
  line_adjustment_CAS: z.union([z.array(PurpleLineAdjustmentSchema), z.null()]).optional(),
  line_check_or_remittance_date_DTP: PurpleLineCheckOrRemittanceDateSchema,
  remaining_patient_liability_AMT: z
    .union([FluffyRemainingPatientLiabilitySchema, z.null()])
    .optional(),
});
export type PurpleLineAdjudicationInformationLoop = z.infer<
  typeof PurpleLineAdjudicationInformationLoopSchema
>;

export const PurpleCompositeMedicalProcedureIdentifierSchema = z.object({
  description_07: z.union([z.null(), z.string()]).optional(),
  procedure_code_02: z.string(),
  procedure_modifier_03: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_04: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_05: z.union([z.null(), z.string()]).optional(),
  procedure_modifier_06: z.union([z.null(), z.string()]).optional(),
  product_or_service_id_qualifier_01: ProductOrServiceIdQualifier01_EnumSchema,
});
export type PurpleCompositeMedicalProcedureIdentifier = z.infer<
  typeof PurpleCompositeMedicalProcedureIdentifierSchema
>;

export const PurpleInstitutionalServiceLineSchema = z.object({
  composite_medical_procedure_identifier_02: z
    .union([PurpleCompositeMedicalProcedureIdentifierSchema, z.null()])
    .optional(),
  line_item_charge_amount_03: z.number(),
  line_item_denied_charge_or_non_covered_charge_amount_07: z
    .union([z.number(), z.null()])
    .optional(),
  service_line_revenue_code_01: z.string(),
  service_unit_count_05: z.number(),
  unit_or_basis_for_measurement_code_04: UnitOrBasisForMeasurementCodeSchema,
});
export type PurpleInstitutionalServiceLine = z.infer<typeof PurpleInstitutionalServiceLineSchema>;

export const PurpleFacilityTaxAmountSchema = z.object({
  amount_qualifier_code_01: FacilityTaxAmountAmtAmountQualifierCode01Schema,
  facility_tax_amount_02: z.number(),
});
export type PurpleFacilityTaxAmount = z.infer<typeof PurpleFacilityTaxAmountSchema>;

export const PurplePrescriptionOrCompoundDrugAssociationNumberSchema = z.object({
  prescription_number_02: z.string(),
  reference_identification_qualifier_01:
    PrescriptionOrCompoundDrugAssociationNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurplePrescriptionOrCompoundDrugAssociationNumber = z.infer<
  typeof PurplePrescriptionOrCompoundDrugAssociationNumberSchema
>;

export const PurpleCompositeUnitOfMeasureSchema = z.object({
  code_qualifier_01: CompositeUnitOfMeasure05_CodeQualifier01Schema,
});
export type PurpleCompositeUnitOfMeasure = z.infer<typeof PurpleCompositeUnitOfMeasureSchema>;

export const PurpleDrugQuantitySchema = z.object({
  composite_unit_of_measure_05: PurpleCompositeUnitOfMeasureSchema,
  national_drug_unit_count_04: z.number(),
});
export type PurpleDrugQuantity = z.infer<typeof PurpleDrugQuantitySchema>;

export const PurpleDrugIdentificationSchema = z.object({
  national_drug_code_03: z.string(),
  product_or_service_id_qualifier_02: ProductOrServiceIdQualifier02_EnumSchema,
});
export type PurpleDrugIdentification = z.infer<typeof PurpleDrugIdentificationSchema>;

export const PurpleDrugIdentificationLoopSchema = z.object({
  drug_identification_LIN: PurpleDrugIdentificationSchema,
  drug_quantity_CTP: PurpleDrugQuantitySchema,
  prescription_or_compound_drug_association_number_REF: z
    .union([PurplePrescriptionOrCompoundDrugAssociationNumberSchema, z.null()])
    .optional(),
});
export type PurpleDrugIdentificationLoop = z.infer<typeof PurpleDrugIdentificationLoopSchema>;

export const PurpleDateServiceDateSchema = z.object({
  date_time_period_format_qualifier_02: DateServiceDateDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: DateServiceDateDtpDateTimeQualifier01Schema,
  service_date_03: z.string(),
});
export type PurpleDateServiceDate = z.infer<typeof PurpleDateServiceDateSchema>;

export const PurpleAdjustedRepricedLineItemReferenceNumberSchema = z.object({
  adjusted_repriced_line_item_reference_number_02: z.string(),
  reference_identification_qualifier_01:
    AdjustedRepricedLineItemReferenceNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleAdjustedRepricedLineItemReferenceNumber = z.infer<
  typeof PurpleAdjustedRepricedLineItemReferenceNumberSchema
>;

export const PurpleServiceLineNumberLoopSchema = z.object({
  adjusted_repriced_line_item_reference_number_REF: z
    .union([PurpleAdjustedRepricedLineItemReferenceNumberSchema, z.null()])
    .optional(),
  date_service_date_DTP: z.union([PurpleDateServiceDateSchema, z.null()]).optional(),
  drug_identification_LIN_loop: z.union([PurpleDrugIdentificationLoopSchema, z.null()]).optional(),
  facility_tax_amount_AMT: z.union([PurpleFacilityTaxAmountSchema, z.null()]).optional(),
  institutional_service_line_SV2: PurpleInstitutionalServiceLineSchema,
  line_adjudication_information_SVD_loop: z
    .union([z.array(PurpleLineAdjudicationInformationLoopSchema), z.null()])
    .optional(),
  line_item_control_number_REF: z.union([PurpleLineItemControlNumberSchema, z.null()]).optional(),
  line_pricing_repricing_information_HCP: z
    .union([PurpleLinePricingRepricingInformationSchema, z.null()])
    .optional(),
  line_supplemental_information_PWK: z
    .union([z.array(PurpleLineSupplementalInformationSchema), z.null()])
    .optional(),
  operating_physician_name_NM1_loop: z
    .union([FluffyOperatingPhysicianNameLoopSchema, z.null()])
    .optional(),
  other_operating_physician_name_NM1_loop: z
    .union([FluffyOtherOperatingPhysicianNameLoopSchema, z.null()])
    .optional(),
  referring_provider_name_NM1_loop: z
    .union([FluffyReferringProviderNameLoopSchema, z.null()])
    .optional(),
  rendering_provider_name_NM1_loop: z
    .union([FluffyRenderingProviderNameLoopSchema, z.null()])
    .optional(),
  repriced_line_item_reference_number_REF: z
    .union([PurpleRepricedLineItemReferenceNumberSchema, z.null()])
    .optional(),
  service_line_number_LX: PurpleServiceLineNumberSchema,
  service_tax_amount_AMT: z.union([PurpleServiceTaxAmountSchema, z.null()]).optional(),
  third_party_organization_notes_NTE: z
    .union([PurpleThirdPartyOrganizationNotesSchema, z.null()])
    .optional(),
});
export type PurpleServiceLineNumberLoop = z.infer<typeof PurpleServiceLineNumberLoopSchema>;

export const PurpleServiceFacilityLocationSecondaryIdentificationSchema = z.object({
  laboratory_or_facility_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleServiceFacilityLocationSecondaryIdentification = z.infer<
  typeof PurpleServiceFacilityLocationSecondaryIdentificationSchema
>;

export const PurpleServiceFacilityLocationNameSchema = z.object({
  entity_identifier_code_01: OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  laboratory_or_facility_name_03: z.string(),
  laboratory_or_facility_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type PurpleServiceFacilityLocationName = z.infer<
  typeof PurpleServiceFacilityLocationNameSchema
>;

export const PurpleServiceFacilityLocationCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_city_name_01: z.string(),
  laboratory_or_facility_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  laboratory_or_facility_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleServiceFacilityLocationCityStateZipCode = z.infer<
  typeof PurpleServiceFacilityLocationCityStateZipCodeSchema
>;

export const PurpleServiceFacilityLocationAddressSchema = z.object({
  laboratory_or_facility_address_line_01: z.string(),
  laboratory_or_facility_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleServiceFacilityLocationAddress = z.infer<
  typeof PurpleServiceFacilityLocationAddressSchema
>;

export const PurpleServiceFacilityLocationNameLoopSchema = z.object({
  service_facility_location_address_N3: PurpleServiceFacilityLocationAddressSchema,
  service_facility_location_city_state_zip_code_N4:
    PurpleServiceFacilityLocationCityStateZipCodeSchema,
  service_facility_location_name_NM1: PurpleServiceFacilityLocationNameSchema,
  service_facility_location_secondary_identification_REF: z
    .union([z.array(PurpleServiceFacilityLocationSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PurpleServiceFacilityLocationNameLoop = z.infer<
  typeof PurpleServiceFacilityLocationNameLoopSchema
>;

export const PurpleServiceAuthorizationExceptionCodeSchema = z.object({
  reference_identification_qualifier_01:
    ServiceAuthorizationExceptionCodeRefReferenceIdentificationQualifier01Schema,
  service_authorization_exception_code_02: z.string(),
});
export type PurpleServiceAuthorizationExceptionCode = z.infer<
  typeof PurpleServiceAuthorizationExceptionCodeSchema
>;

export const PurpleRepricedClaimNumberSchema = z.object({
  reference_identification_qualifier_01:
    RepricedClaimNumberRefReferenceIdentificationQualifier01Schema,
  repriced_claim_reference_number_02: z.string(),
});
export type PurpleRepricedClaimNumber = z.infer<typeof PurpleRepricedClaimNumberSchema>;

export const PurpleRenderingProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  rendering_provider_secondary_identifier_02: z.string(),
});
export type PurpleRenderingProviderSecondaryIdentification = z.infer<
  typeof PurpleRenderingProviderSecondaryIdentificationSchema
>;

export const PurpleRenderingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  rendering_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  rendering_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  rendering_provider_last_name_03: z.string(),
  rendering_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  rendering_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleRenderingProviderName = z.infer<typeof PurpleRenderingProviderNameSchema>;

export const PurpleRenderingProviderNameLoopSchema = z.object({
  rendering_provider_name_NM1: PurpleRenderingProviderNameSchema,
  rendering_provider_secondary_identification_REF: z
    .union([z.array(PurpleRenderingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PurpleRenderingProviderNameLoop = z.infer<typeof PurpleRenderingProviderNameLoopSchema>;

export const PurpleReferringProviderSecondaryIdentificationSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
  referring_provider_secondary_identifier_02: z.string(),
});
export type PurpleReferringProviderSecondaryIdentification = z.infer<
  typeof PurpleReferringProviderSecondaryIdentificationSchema
>;

export const PurpleReferringProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  referring_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  referring_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  referring_provider_last_name_03: z.string(),
  referring_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  referring_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleReferringProviderName = z.infer<typeof PurpleReferringProviderNameSchema>;

export const PurpleReferringProviderNameLoopSchema = z.object({
  referring_provider_name_NM1: PurpleReferringProviderNameSchema,
  referring_provider_secondary_identification_REF: z
    .union([z.array(PurpleReferringProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PurpleReferringProviderNameLoop = z.infer<typeof PurpleReferringProviderNameLoopSchema>;

export const PurpleReferralNumberSchema = z.object({
  reference_identification_qualifier_01:
    OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema,
  referral_number_02: z.string(),
});
export type PurpleReferralNumber = z.infer<typeof PurpleReferralNumberSchema>;

export const PurplePriorAuthorizationSchema = z.object({
  prior_authorization_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurplePriorAuthorization = z.infer<typeof PurplePriorAuthorizationSchema>;

export const HealthCareCodeInformation66Schema = z.object({
  code_list_qualifier_code_01: MagentaCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  principal_procedure_code_02: z.string(),
  principal_procedure_date_04: z.string(),
});
export type HealthCareCodeInformation66 = z.infer<typeof HealthCareCodeInformation66Schema>;

export const PurplePrincipalProcedureInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation66Schema,
});
export type PurplePrincipalProcedureInformation = z.infer<
  typeof PurplePrincipalProcedureInformationSchema
>;

export const HealthCareCodeInformation65Schema = z.object({
  code_list_qualifier_code_01: CunningCodeListQualifierCodeSchema,
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
  principal_diagnosis_code_02: z.string(),
});
export type HealthCareCodeInformation65 = z.infer<typeof HealthCareCodeInformation65Schema>;

export const PurplePrincipalDiagnosisSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation65Schema,
});
export type PurplePrincipalDiagnosis = z.infer<typeof PurplePrincipalDiagnosisSchema>;

export const PurplePeerReviewOrganizationProApprovalNumberSchema = z.object({
  peer_review_authorization_number_02: z.string(),
  reference_identification_qualifier_01:
    PeerReviewOrganizationProApprovalNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurplePeerReviewOrganizationProApprovalNumber = z.infer<
  typeof PurplePeerReviewOrganizationProApprovalNumberSchema
>;

export const PurplePayerClaimControlNumberSchema = z.object({
  payer_claim_control_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurplePayerClaimControlNumber = z.infer<typeof PurplePayerClaimControlNumberSchema>;

export const HealthCareCodeInformation64Schema = z.object({
  code_list_qualifier_code_01: AmbitiousCodeListQualifierCodeSchema,
  patient_reason_for_visit_02: z.string(),
});
export type HealthCareCodeInformation64 = z.infer<typeof HealthCareCodeInformation64Schema>;

export const HealthCareCodeInformation63Schema = z.object({
  code_list_qualifier_code_01: AmbitiousCodeListQualifierCodeSchema,
  patient_reason_for_visit_02: z.string(),
});
export type HealthCareCodeInformation63 = z.infer<typeof HealthCareCodeInformation63Schema>;

export const HealthCareCodeInformation62Schema = z.object({
  code_list_qualifier_code_01: AmbitiousCodeListQualifierCodeSchema,
  patient_reason_for_visit_02: z.string(),
});
export type HealthCareCodeInformation62 = z.infer<typeof HealthCareCodeInformation62Schema>;

export const PurplePatientSReasonForVisitSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation62Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation63Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation64Schema, z.null()])
    .optional(),
});
export type PurplePatientSReasonForVisit = z.infer<typeof PurplePatientSReasonForVisitSchema>;

export const PurplePatientEstimatedAmountDueSchema = z.object({
  amount_qualifier_code_01: PatientEstimatedAmountDueAmtAmountQualifierCode01Schema,
  patient_responsibility_amount_02: z.number(),
});
export type PurplePatientEstimatedAmountDue = z.infer<typeof PurplePatientEstimatedAmountDueSchema>;

export const PurpleRemainingPatientLiabilitySchema = z.object({
  amount_qualifier_code_01: RemainingPatientLiabilityAmtAmountQualifierCode01Schema,
  remaining_patient_liability_02: z.number(),
});
export type PurpleRemainingPatientLiability = z.infer<typeof PurpleRemainingPatientLiabilitySchema>;

export const PurpleOutpatientAdjudicationInformationSchema = z.object({
  claim_payment_remark_code_03: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_04: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_05: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_06: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_07: z.union([z.null(), z.string()]).optional(),
  end_stage_renal_disease_payment_amount_08: z.union([z.number(), z.null()]).optional(),
  hcpcs_payable_amount_02: z.union([z.number(), z.null()]).optional(),
  non_payable_professional_component_billed_amount_09: z.union([z.number(), z.null()]).optional(),
  reimbursement_rate_01: z.union([z.number(), z.null()]).optional(),
});
export type PurpleOutpatientAdjudicationInformation = z.infer<
  typeof PurpleOutpatientAdjudicationInformationSchema
>;

export const PurpleOtherSubscriberSecondaryIdentificationSchema = z.object({
  other_insured_additional_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherSubscriberSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherSubscriberSecondaryIdentification = z.infer<
  typeof PurpleOtherSubscriberSecondaryIdentificationSchema
>;

export const PurpleOtherSubscriberNameSchema = z.object({
  entity_identifier_code_01: OtherSubscriberNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: OtherSubscriberNameNm1EntityTypeQualifier02Schema,
  identification_code_qualifier_08: OtherSubscriberNameNm1IdentificationCodeQualifier08Schema,
  other_insured_first_name_04: z.union([z.null(), z.string()]).optional(),
  other_insured_identifier_09: z.string(),
  other_insured_last_name_03: z.string(),
  other_insured_middle_name_05: z.union([z.null(), z.string()]).optional(),
  other_insured_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOtherSubscriberName = z.infer<typeof PurpleOtherSubscriberNameSchema>;

export const PurpleOtherSubscriberCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  other_insured_city_name_01: z.string(),
  other_insured_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  other_insured_state_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOtherSubscriberCityStateZipCode = z.infer<
  typeof PurpleOtherSubscriberCityStateZipCodeSchema
>;

export const PurpleOtherSubscriberAddressSchema = z.object({
  other_insured_address_line_01: z.string(),
  other_insured_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOtherSubscriberAddress = z.infer<typeof PurpleOtherSubscriberAddressSchema>;

export const PurpleOtherSubscriberNameLoopSchema = z.object({
  other_subscriber_address_N3: z.union([PurpleOtherSubscriberAddressSchema, z.null()]).optional(),
  other_subscriber_city_state_zip_code_N4: z
    .union([PurpleOtherSubscriberCityStateZipCodeSchema, z.null()])
    .optional(),
  other_subscriber_name_NM1: PurpleOtherSubscriberNameSchema,
  other_subscriber_secondary_identification_REF: z
    .union([z.array(PurpleOtherSubscriberSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PurpleOtherSubscriberNameLoop = z.infer<typeof PurpleOtherSubscriberNameLoopSchema>;

export const PurpleOtherSubscriberInformationSchema = z.object({
  claim_filing_indicator_code_09: ClaimFilingIndicatorCodeSchema,
  individual_relationship_code_02: OtherSubscriberInformationSbrIndividualRelationshipCode02Schema,
  insured_group_or_policy_number_03: z.union([z.null(), z.string()]).optional(),
  other_insured_group_name_04: z.union([z.null(), z.string()]).optional(),
  payer_responsibility_sequence_number_code_01: PayerResponsibilitySequenceNumberCodeSchema,
});
export type PurpleOtherSubscriberInformation = z.infer<
  typeof PurpleOtherSubscriberInformationSchema
>;

export const PurpleOtherPayerServiceFacilityLocationSecondaryIdentificationSchema = z.object({
  other_payer_service_facility_location_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerServiceFacilityLocationSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerServiceFacilityLocationSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerServiceFacilityLocationSecondaryIdentificationSchema
>;

export const PurpleOtherPayerServiceFacilityLocationSchema = z.object({
  entity_identifier_code_01: OtherPayerServiceFacilityLocationNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
});
export type PurpleOtherPayerServiceFacilityLocation = z.infer<
  typeof PurpleOtherPayerServiceFacilityLocationSchema
>;

export const PurpleOtherPayerServiceFacilityLocationLoopSchema = z.object({
  other_payer_service_facility_location_NM1: PurpleOtherPayerServiceFacilityLocationSchema,
  other_payer_service_facility_location_secondary_identification_REF: z.array(
    PurpleOtherPayerServiceFacilityLocationSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerServiceFacilityLocationLoop = z.infer<
  typeof PurpleOtherPayerServiceFacilityLocationLoopSchema
>;

export const PurpleOtherPayerRenderingProviderSecondaryIdentificationSchema = z.object({
  other_payer_rendering_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerRenderingProviderSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerRenderingProviderSecondaryIdentificationSchema
>;

export const PurpleOtherPayerRenderingProviderNameSchema = z.object({
  entity_identifier_code_01: OtherPayerRenderingProviderNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
});
export type PurpleOtherPayerRenderingProviderName = z.infer<
  typeof PurpleOtherPayerRenderingProviderNameSchema
>;

export const PurpleOtherPayerRenderingProviderNameLoopSchema = z.object({
  other_payer_rendering_provider_name_NM1: PurpleOtherPayerRenderingProviderNameSchema,
  other_payer_rendering_provider_secondary_identification_REF: z.array(
    PurpleOtherPayerRenderingProviderSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerRenderingProviderNameLoop = z.infer<
  typeof PurpleOtherPayerRenderingProviderNameLoopSchema
>;

export const PurpleOtherPayerReferringProviderSecondaryIdentificationSchema = z.object({
  other_payer_referring_provider_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferringProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerReferringProviderSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerReferringProviderSecondaryIdentificationSchema
>;

export const PurpleOtherPayerReferringProviderSchema = z.object({
  entity_identifier_code_01: OtherPayerReferringProviderNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
});
export type PurpleOtherPayerReferringProvider = z.infer<
  typeof PurpleOtherPayerReferringProviderSchema
>;

export const PurpleOtherPayerReferringProviderLoopSchema = z.object({
  other_payer_referring_provider_NM1: PurpleOtherPayerReferringProviderSchema,
  other_payer_referring_provider_secondary_identification_REF: z.array(
    PurpleOtherPayerReferringProviderSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerReferringProviderLoop = z.infer<
  typeof PurpleOtherPayerReferringProviderLoopSchema
>;

export const PurpleOtherPayerOtherOperatingPhysicianSecondaryIdentificationSchema = z.object({
  other_payer_other_operating_physician_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerOtherOperatingPhysicianSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerOtherOperatingPhysicianSecondaryIdentificationSchema
>;

export const PurpleOtherPayerOtherOperatingPhysicianSchema = z.object({
  entity_identifier_code_01: CodeQualifierSchema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
});
export type PurpleOtherPayerOtherOperatingPhysician = z.infer<
  typeof PurpleOtherPayerOtherOperatingPhysicianSchema
>;

export const PurpleOtherPayerOtherOperatingPhysicianLoopSchema = z.object({
  other_payer_other_operating_physician_NM1: PurpleOtherPayerOtherOperatingPhysicianSchema,
  other_payer_other_operating_physician_secondary_identification_REF: z.array(
    PurpleOtherPayerOtherOperatingPhysicianSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerOtherOperatingPhysicianLoop = z.infer<
  typeof PurpleOtherPayerOtherOperatingPhysicianLoopSchema
>;

export const PurpleOtherPayerOperatingPhysicianSecondaryIdentificationSchema = z.object({
  other_payer_operating_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerOperatingPhysicianSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerOperatingPhysicianSecondaryIdentificationSchema
>;

export const PurpleOtherPayerOperatingPhysicianSchema = z.object({
  entity_identifier_code_01: OperatingPhysicianNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
});
export type PurpleOtherPayerOperatingPhysician = z.infer<
  typeof PurpleOtherPayerOperatingPhysicianSchema
>;

export const PurpleOtherPayerOperatingPhysicianLoopSchema = z.object({
  other_payer_operating_physician_NM1: PurpleOtherPayerOperatingPhysicianSchema,
  other_payer_operating_physician_secondary_identification_REF: z.array(
    PurpleOtherPayerOperatingPhysicianSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerOperatingPhysicianLoop = z.infer<
  typeof PurpleOtherPayerOperatingPhysicianLoopSchema
>;

export const PurpleOtherPayerSecondaryIdentifierSchema = z.object({
  other_payer_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerSecondaryIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerSecondaryIdentifier = z.infer<
  typeof PurpleOtherPayerSecondaryIdentifierSchema
>;

export const PurpleOtherPayerReferralNumberSchema = z.object({
  other_payer_prior_authorization_or_referral_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerReferralNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerReferralNumber = z.infer<typeof PurpleOtherPayerReferralNumberSchema>;

export const PurpleOtherPayerPriorAuthorizationNumberSchema = z.object({
  other_payer_prior_authorization_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerPriorAuthorizationNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerPriorAuthorizationNumber = z.infer<
  typeof PurpleOtherPayerPriorAuthorizationNumberSchema
>;

export const PurpleOtherPayerNameSchema = z.object({
  entity_identifier_code_01: OtherPayerNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: PayToPlanNameNm1IdentificationCodeQualifier08Schema,
  other_payer_last_or_organization_name_03: z.string(),
  other_payer_primary_identifier_09: z.string(),
});
export type PurpleOtherPayerName = z.infer<typeof PurpleOtherPayerNameSchema>;

export const PurpleOtherPayerClaimControlNumberSchema = z.object({
  other_payers_claim_control_number_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimControlNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerClaimControlNumber = z.infer<
  typeof PurpleOtherPayerClaimControlNumberSchema
>;

export const PurpleOtherPayerClaimAdjustmentIndicatorSchema = z.object({
  other_payer_claim_adjustment_indicator_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerClaimAdjustmentIndicatorRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerClaimAdjustmentIndicator = z.infer<
  typeof PurpleOtherPayerClaimAdjustmentIndicatorSchema
>;

export const PurpleOtherPayerCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  other_payer_city_name_01: z.string(),
  other_payer_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  other_payer_state_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOtherPayerCityStateZipCode = z.infer<
  typeof PurpleOtherPayerCityStateZipCodeSchema
>;

export const PurpleOtherPayerAddressSchema = z.object({
  other_payer_address_line_01: z.string(),
  other_payer_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOtherPayerAddress = z.infer<typeof PurpleOtherPayerAddressSchema>;

export const PurpleClaimCheckOrRemittanceDateSchema = z.object({
  adjudication_or_payment_date_03: z.string(),
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier01_EnumSchema,
  date_time_qualifier_01: ClaimCheckOrRemittanceDateDtpDateTimeQualifier01Schema,
});
export type PurpleClaimCheckOrRemittanceDate = z.infer<
  typeof PurpleClaimCheckOrRemittanceDateSchema
>;

export const PurpleOtherPayerNameLoopSchema = z.object({
  claim_check_or_remittance_date_DTP: z
    .union([PurpleClaimCheckOrRemittanceDateSchema, z.null()])
    .optional(),
  other_payer_address_N3: z.union([PurpleOtherPayerAddressSchema, z.null()]).optional(),
  other_payer_city_state_zip_code_N4: z
    .union([PurpleOtherPayerCityStateZipCodeSchema, z.null()])
    .optional(),
  other_payer_claim_adjustment_indicator_REF: z
    .union([PurpleOtherPayerClaimAdjustmentIndicatorSchema, z.null()])
    .optional(),
  other_payer_claim_control_number_REF: z
    .union([PurpleOtherPayerClaimControlNumberSchema, z.null()])
    .optional(),
  other_payer_name_NM1: PurpleOtherPayerNameSchema,
  other_payer_prior_authorization_number_REF: z
    .union([PurpleOtherPayerPriorAuthorizationNumberSchema, z.null()])
    .optional(),
  other_payer_referral_number_REF: z
    .union([PurpleOtherPayerReferralNumberSchema, z.null()])
    .optional(),
  other_payer_secondary_identifier_REF: z
    .union([z.array(PurpleOtherPayerSecondaryIdentifierSchema), z.null()])
    .optional(),
});
export type PurpleOtherPayerNameLoop = z.infer<typeof PurpleOtherPayerNameLoopSchema>;

export const PurpleOtherPayerBillingProviderSecondaryIdentificationSchema = z.object({
  other_payer_billing_provider_identifier_02: z.string(),
  reference_identification_qualifier_01:
    OtherPayerBillingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerBillingProviderSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerBillingProviderSecondaryIdentificationSchema
>;

export const PurpleOtherPayerBillingProviderSchema = z.object({
  entity_identifier_code_01: BillingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
});
export type PurpleOtherPayerBillingProvider = z.infer<typeof PurpleOtherPayerBillingProviderSchema>;

export const PurpleOtherPayerBillingProviderLoopSchema = z.object({
  other_payer_billing_provider_NM1: PurpleOtherPayerBillingProviderSchema,
  other_payer_billing_provider_secondary_identification_REF: z.array(
    PurpleOtherPayerBillingProviderSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerBillingProviderLoop = z.infer<
  typeof PurpleOtherPayerBillingProviderLoopSchema
>;

export const PurpleOtherPayerAttendingProviderSecondaryIdentificationSchema = z.object({
  other_payer_attending_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherPayerAttendingProviderSecondaryIdentification = z.infer<
  typeof PurpleOtherPayerAttendingProviderSecondaryIdentificationSchema
>;

export const PurpleOtherPayerAttendingProviderSchema = z.object({
  entity_identifier_code_01: AttendingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
});
export type PurpleOtherPayerAttendingProvider = z.infer<
  typeof PurpleOtherPayerAttendingProviderSchema
>;

export const PurpleOtherPayerAttendingProviderLoopSchema = z.object({
  other_payer_attending_provider_NM1: PurpleOtherPayerAttendingProviderSchema,
  other_payer_attending_provider_secondary_identification_REF: z.array(
    PurpleOtherPayerAttendingProviderSecondaryIdentificationSchema
  ),
});
export type PurpleOtherPayerAttendingProviderLoop = z.infer<
  typeof PurpleOtherPayerAttendingProviderLoopSchema
>;

export const PurpleOtherInsuranceCoverageInformationSchema = z.object({
  benefits_assignment_certification_indicator_03: BenefitsAssignmentCertificationIndicatorSchema,
  release_of_information_code_06: ReleaseOfInformationCodeSchema,
});
export type PurpleOtherInsuranceCoverageInformation = z.infer<
  typeof PurpleOtherInsuranceCoverageInformationSchema
>;

export const PurpleInpatientAdjudicationInformationSchema = z.object({
  claim_disproportionate_share_amount_06: z.union([z.number(), z.null()]).optional(),
  claim_drg_amount_04: z.union([z.number(), z.null()]).optional(),
  claim_indirect_teaching_amount_18: z.union([z.number(), z.null()]).optional(),
  claim_msp_pass_through_amount_07: z.union([z.number(), z.null()]).optional(),
  claim_payment_remark_code_05: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_20: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_21: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_22: z.union([z.null(), z.string()]).optional(),
  claim_payment_remark_code_23: z.union([z.null(), z.string()]).optional(),
  claim_pps_capital_amount_08: z.union([z.number(), z.null()]).optional(),
  claim_pps_capital_outlier_amount_17: z.union([z.number(), z.null()]).optional(),
  cost_report_day_count_15: z.union([z.number(), z.null()]).optional(),
  covered_days_or_visits_count_01: z.number(),
  lifetime_psychiatric_days_count_03: z.union([z.number(), z.null()]).optional(),
  non_payable_professional_component_billed_amount_19: z.union([z.number(), z.null()]).optional(),
  old_capital_amount_12: z.union([z.number(), z.null()]).optional(),
  pps_capital_dsh_drg_amount_11: z.union([z.number(), z.null()]).optional(),
  pps_capital_exception_amount_24: z.union([z.number(), z.null()]).optional(),
  pps_capital_fsp_drg_amount_09: z.union([z.number(), z.null()]).optional(),
  pps_capital_hsp_drg_amount_10: z.union([z.number(), z.null()]).optional(),
  pps_capital_ime_amount_13: z.union([z.number(), z.null()]).optional(),
  pps_operating_federal_specific_drg_amount_16: z.union([z.number(), z.null()]).optional(),
  pps_operating_hospital_specific_drg_amount_14: z.union([z.number(), z.null()]).optional(),
});
export type PurpleInpatientAdjudicationInformation = z.infer<
  typeof PurpleInpatientAdjudicationInformationSchema
>;

export const PurpleCoordinationOfBenefitsCobTotalNonCoveredAmountSchema = z.object({
  amount_qualifier_code_01:
    CoordinationOfBenefitsCobTotalNonCoveredAmountAmtAmountQualifierCode01Schema,
  non_covered_charge_amount_02: z.number(),
});
export type PurpleCoordinationOfBenefitsCobTotalNonCoveredAmount = z.infer<
  typeof PurpleCoordinationOfBenefitsCobTotalNonCoveredAmountSchema
>;

export const PurpleCoordinationOfBenefitsCobPayerPaidAmountSchema = z.object({
  amount_qualifier_code_01: CoordinationOfBenefitsCobPayerPaidAmountAmtAmountQualifierCode01Schema,
  payer_paid_amount_02: z.number(),
});
export type PurpleCoordinationOfBenefitsCobPayerPaidAmount = z.infer<
  typeof PurpleCoordinationOfBenefitsCobPayerPaidAmountSchema
>;

export const PurpleClaimLevelAdjustmentsSchema = z.object({
  adjustment_amount_03: z.number(),
  adjustment_amount_06: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_09: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_12: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_15: z.union([z.number(), z.null()]).optional(),
  adjustment_amount_18: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_04: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_07: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_10: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_13: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_16: z.union([z.number(), z.null()]).optional(),
  adjustment_quantity_19: z.union([z.number(), z.null()]).optional(),
  adjustment_reason_code_02: z.string(),
  adjustment_reason_code_05: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_08: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_11: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_14: z.union([z.null(), z.string()]).optional(),
  adjustment_reason_code_17: z.union([z.null(), z.string()]).optional(),
  claim_adjustment_group_code_01: ClaimAdjustmentGroupCodeSchema,
});
export type PurpleClaimLevelAdjustments = z.infer<typeof PurpleClaimLevelAdjustmentsSchema>;

export const PurpleOtherSubscriberInformationLoopSchema = z.object({
  claim_level_adjustments_CAS: z
    .union([z.array(PurpleClaimLevelAdjustmentsSchema), z.null()])
    .optional(),
  coordination_of_benefits_cob_payer_paid_amount_AMT: z
    .union([PurpleCoordinationOfBenefitsCobPayerPaidAmountSchema, z.null()])
    .optional(),
  coordination_of_benefits_cob_total_non_covered_amount_AMT: z
    .union([PurpleCoordinationOfBenefitsCobTotalNonCoveredAmountSchema, z.null()])
    .optional(),
  inpatient_adjudication_information_MIA: z
    .union([PurpleInpatientAdjudicationInformationSchema, z.null()])
    .optional(),
  other_insurance_coverage_information_OI: PurpleOtherInsuranceCoverageInformationSchema,
  other_payer_attending_provider_NM1_loop: z
    .union([PurpleOtherPayerAttendingProviderLoopSchema, z.null()])
    .optional(),
  other_payer_billing_provider_NM1_loop: z
    .union([PurpleOtherPayerBillingProviderLoopSchema, z.null()])
    .optional(),
  other_payer_name_NM1_loop: PurpleOtherPayerNameLoopSchema,
  other_payer_operating_physician_NM1_loop: z
    .union([PurpleOtherPayerOperatingPhysicianLoopSchema, z.null()])
    .optional(),
  other_payer_other_operating_physician_NM1_loop: z
    .union([PurpleOtherPayerOtherOperatingPhysicianLoopSchema, z.null()])
    .optional(),
  other_payer_referring_provider_NM1_loop: z
    .union([PurpleOtherPayerReferringProviderLoopSchema, z.null()])
    .optional(),
  other_payer_rendering_provider_name_NM1_loop: z
    .union([PurpleOtherPayerRenderingProviderNameLoopSchema, z.null()])
    .optional(),
  other_payer_service_facility_location_NM1_loop: z
    .union([PurpleOtherPayerServiceFacilityLocationLoopSchema, z.null()])
    .optional(),
  other_subscriber_information_SBR: PurpleOtherSubscriberInformationSchema,
  other_subscriber_name_NM1_loop: PurpleOtherSubscriberNameLoopSchema,
  outpatient_adjudication_information_MOA: z
    .union([PurpleOutpatientAdjudicationInformationSchema, z.null()])
    .optional(),
  remaining_patient_liability_AMT: z
    .union([PurpleRemainingPatientLiabilitySchema, z.null()])
    .optional(),
});
export type PurpleOtherSubscriberInformationLoop = z.infer<
  typeof PurpleOtherSubscriberInformationLoopSchema
>;

export const HealthCareCodeInformation61Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation61 = z.infer<typeof HealthCareCodeInformation61Schema>;

export const HealthCareCodeInformation60Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation60 = z.infer<typeof HealthCareCodeInformation60Schema>;

export const HealthCareCodeInformation59Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation59 = z.infer<typeof HealthCareCodeInformation59Schema>;

export const HealthCareCodeInformation58Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation58 = z.infer<typeof HealthCareCodeInformation58Schema>;

export const HealthCareCodeInformation57Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation57 = z.infer<typeof HealthCareCodeInformation57Schema>;

export const HealthCareCodeInformation56Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation56 = z.infer<typeof HealthCareCodeInformation56Schema>;

export const HealthCareCodeInformation55Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation55 = z.infer<typeof HealthCareCodeInformation55Schema>;

export const HealthCareCodeInformation54Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation54 = z.infer<typeof HealthCareCodeInformation54Schema>;

export const HealthCareCodeInformation53Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation53 = z.infer<typeof HealthCareCodeInformation53Schema>;

export const HealthCareCodeInformation52Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation52 = z.infer<typeof HealthCareCodeInformation52Schema>;

export const HealthCareCodeInformation51Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation51 = z.infer<typeof HealthCareCodeInformation51Schema>;

export const HealthCareCodeInformation50Schema = z.object({
  code_list_qualifier_code_01: HilariousCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  procedure_code_02: z.string(),
  procedure_date_04: z.string(),
});
export type HealthCareCodeInformation50 = z.infer<typeof HealthCareCodeInformation50Schema>;

export const PurpleOtherProcedureInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation50Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation51Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation52Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation53Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation54Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation55Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation56Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation57Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation58Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation59Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation60Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation61Schema, z.null()])
    .optional(),
});
export type PurpleOtherProcedureInformation = z.infer<typeof PurpleOtherProcedureInformationSchema>;

export const PurpleOtherOperatingPhysicianSecondaryIdentificationSchema = z.object({
  other_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOtherOperatingPhysicianSecondaryIdentification = z.infer<
  typeof PurpleOtherOperatingPhysicianSecondaryIdentificationSchema
>;

export const PurpleOtherOperatingPhysicianNameSchema = z.object({
  entity_identifier_code_01: CodeQualifierSchema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  other_operating_physician_first_name_04: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_identifier_09: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_last_name_03: z.string(),
  other_operating_physician_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  other_operating_physician_name_suffix_07: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOtherOperatingPhysicianName = z.infer<
  typeof PurpleOtherOperatingPhysicianNameSchema
>;

export const PurpleOtherOperatingPhysicianNameLoopSchema = z.object({
  other_operating_physician_name_NM1: PurpleOtherOperatingPhysicianNameSchema,
  other_operating_physician_secondary_identification_REF: z
    .union([z.array(PurpleOtherOperatingPhysicianSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PurpleOtherOperatingPhysicianNameLoop = z.infer<
  typeof PurpleOtherOperatingPhysicianNameLoopSchema
>;

export const HealthCareCodeInformation49Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation49 = z.infer<typeof HealthCareCodeInformation49Schema>;

export const HealthCareCodeInformation48Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation48 = z.infer<typeof HealthCareCodeInformation48Schema>;

export const HealthCareCodeInformation47Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation47 = z.infer<typeof HealthCareCodeInformation47Schema>;

export const HealthCareCodeInformation46Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation46 = z.infer<typeof HealthCareCodeInformation46Schema>;

export const HealthCareCodeInformation45Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation45 = z.infer<typeof HealthCareCodeInformation45Schema>;

export const HealthCareCodeInformation44Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation44 = z.infer<typeof HealthCareCodeInformation44Schema>;

export const HealthCareCodeInformation43Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation43 = z.infer<typeof HealthCareCodeInformation43Schema>;

export const HealthCareCodeInformation42Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation42 = z.infer<typeof HealthCareCodeInformation42Schema>;

export const HealthCareCodeInformation41Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation41 = z.infer<typeof HealthCareCodeInformation41Schema>;

export const HealthCareCodeInformation40Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation40 = z.infer<typeof HealthCareCodeInformation40Schema>;

export const HealthCareCodeInformation39Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation39 = z.infer<typeof HealthCareCodeInformation39Schema>;

export const HealthCareCodeInformation38Schema = z.object({
  code_list_qualifier_code_01: IndecentCodeListQualifierCodeSchema,
  other_diagnosis_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation38 = z.infer<typeof HealthCareCodeInformation38Schema>;

export const PurpleOtherDiagnosisInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation38Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation39Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation40Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation41Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation42Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation43Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation44Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation45Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation46Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation47Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation48Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation49Schema, z.null()])
    .optional(),
});
export type PurpleOtherDiagnosisInformation = z.infer<typeof PurpleOtherDiagnosisInformationSchema>;

export const PurpleOperatingPhysicianSecondaryIdentificationSchema = z.object({
  operating_physician_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleOperatingPhysicianSecondaryIdentification = z.infer<
  typeof PurpleOperatingPhysicianSecondaryIdentificationSchema
>;

export const PurpleOperatingPhysicianNameSchema = z.object({
  entity_identifier_code_01: OperatingPhysicianNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
  operating_physician_first_name_04: z.union([z.null(), z.string()]).optional(),
  operating_physician_last_name_03: z.string(),
  operating_physician_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  operating_physician_name_suffix_07: z.union([z.null(), z.string()]).optional(),
  operating_physician_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
});
export type PurpleOperatingPhysicianName = z.infer<typeof PurpleOperatingPhysicianNameSchema>;

export const PurpleOperatingPhysicianNameLoopSchema = z.object({
  operating_physician_name_NM1: PurpleOperatingPhysicianNameSchema,
  operating_physician_secondary_identification_REF: z
    .union([z.array(PurpleOperatingPhysicianSecondaryIdentificationSchema), z.null()])
    .optional(),
});
export type PurpleOperatingPhysicianNameLoop = z.infer<
  typeof PurpleOperatingPhysicianNameLoopSchema
>;

export const HealthCareCodeInformation37Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation37 = z.infer<typeof HealthCareCodeInformation37Schema>;

export const HealthCareCodeInformation36Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation36 = z.infer<typeof HealthCareCodeInformation36Schema>;

export const HealthCareCodeInformation35Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation35 = z.infer<typeof HealthCareCodeInformation35Schema>;

export const HealthCareCodeInformation34Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation34 = z.infer<typeof HealthCareCodeInformation34Schema>;

export const HealthCareCodeInformation33Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation33 = z.infer<typeof HealthCareCodeInformation33Schema>;

export const HealthCareCodeInformation32Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation32 = z.infer<typeof HealthCareCodeInformation32Schema>;

export const HealthCareCodeInformation31Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation31 = z.infer<typeof HealthCareCodeInformation31Schema>;

export const HealthCareCodeInformation30Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation30 = z.infer<typeof HealthCareCodeInformation30Schema>;

export const HealthCareCodeInformation29Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation29 = z.infer<typeof HealthCareCodeInformation29Schema>;

export const HealthCareCodeInformation28Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation28 = z.infer<typeof HealthCareCodeInformation28Schema>;

export const HealthCareCodeInformation27Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation27 = z.infer<typeof HealthCareCodeInformation27Schema>;

export const HealthCareCodeInformation26Schema = z.object({
  code_list_qualifier_code_01: ErCodeSchema,
  date_time_period_format_qualifier_03: StatementDatesDtpDateTimePeriodFormatQualifier02Schema,
  occurrence_span_code_02: z.string(),
  occurrence_span_code_date_04: z.string(),
});
export type HealthCareCodeInformation26 = z.infer<typeof HealthCareCodeInformation26Schema>;

export const PurpleOccurrenceSpanInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation26Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation27Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation28Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation29Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation30Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation31Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation32Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation33Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation34Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation35Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation36Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation37Schema, z.null()])
    .optional(),
});
export type PurpleOccurrenceSpanInformation = z.infer<typeof PurpleOccurrenceSpanInformationSchema>;

export const HealthCareCodeInformation25Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation25 = z.infer<typeof HealthCareCodeInformation25Schema>;

export const HealthCareCodeInformation24Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation24 = z.infer<typeof HealthCareCodeInformation24Schema>;

export const HealthCareCodeInformation23Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation23 = z.infer<typeof HealthCareCodeInformation23Schema>;

export const HealthCareCodeInformation22Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation22 = z.infer<typeof HealthCareCodeInformation22Schema>;

export const HealthCareCodeInformation21Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation21 = z.infer<typeof HealthCareCodeInformation21Schema>;

export const HealthCareCodeInformation20Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation20 = z.infer<typeof HealthCareCodeInformation20Schema>;

export const HealthCareCodeInformation19Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation19 = z.infer<typeof HealthCareCodeInformation19Schema>;

export const HealthCareCodeInformation18Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation18 = z.infer<typeof HealthCareCodeInformation18Schema>;

export const HealthCareCodeInformation17Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation17 = z.infer<typeof HealthCareCodeInformation17Schema>;

export const HealthCareCodeInformation16Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation16 = z.infer<typeof HealthCareCodeInformation16Schema>;

export const HealthCareCodeInformation15Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation15 = z.infer<typeof HealthCareCodeInformation15Schema>;

export const HealthCareCodeInformation14Schema = z.object({
  code_list_qualifier_code_01: IndigoCodeListQualifierCodeSchema,
  date_time_period_format_qualifier_03: DateTimePeriodFormatQualifier01_EnumSchema,
  occurrence_code_02: z.string(),
  occurrence_code_date_04: z.string(),
});
export type HealthCareCodeInformation14 = z.infer<typeof HealthCareCodeInformation14Schema>;

export const PurpleOccurrenceInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation14Schema,
  health_care_code_information_02: z
    .union([HealthCareCodeInformation15Schema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([HealthCareCodeInformation16Schema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([HealthCareCodeInformation17Schema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([HealthCareCodeInformation18Schema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HealthCareCodeInformation19Schema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([HealthCareCodeInformation20Schema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([HealthCareCodeInformation21Schema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation22Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation23Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation24Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation25Schema, z.null()])
    .optional(),
});
export type PurpleOccurrenceInformation = z.infer<typeof PurpleOccurrenceInformationSchema>;

export const PurpleMedicalRecordNumberSchema = z.object({
  medical_record_number_02: z.string(),
  reference_identification_qualifier_01:
    MedicalRecordNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleMedicalRecordNumber = z.infer<typeof PurpleMedicalRecordNumberSchema>;

export const PurpleInvestigationalDeviceExemptionNumberSchema = z.object({
  investigational_device_exemption_identifier_02: z.string(),
  reference_identification_qualifier_01:
    InvestigationalDeviceExemptionNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleInvestigationalDeviceExemptionNumber = z.infer<
  typeof PurpleInvestigationalDeviceExemptionNumberSchema
>;

export const PurpleInstitutionalClaimCodeSchema = z.object({
  admission_source_code_02: z.union([z.null(), z.string()]).optional(),
  admission_type_code_01: z.string(),
  patient_status_code_03: z.string(),
});
export type PurpleInstitutionalClaimCode = z.infer<typeof PurpleInstitutionalClaimCodeSchema>;

export const PurpleFileInformationSchema = z.object({
  fixed_format_information_01: z.string(),
});
export type PurpleFileInformation = z.infer<typeof PurpleFileInformationSchema>;

export const HealthCareCodeInformation13Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation13 = z.infer<typeof HealthCareCodeInformation13Schema>;

export const HealthCareCodeInformation12Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation12 = z.infer<typeof HealthCareCodeInformation12Schema>;

export const HealthCareCodeInformation11Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation11 = z.infer<typeof HealthCareCodeInformation11Schema>;

export const HealthCareCodeInformation10Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation10 = z.infer<typeof HealthCareCodeInformation10Schema>;

export const HealthCareCodeInformation9Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation9 = z.infer<typeof HealthCareCodeInformation9Schema>;

export const HealthCareCodeInformation8Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation8 = z.infer<typeof HealthCareCodeInformation8Schema>;

export const HealthCareCodeInformation7Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation7 = z.infer<typeof HealthCareCodeInformation7Schema>;

export const HealthCareCodeInformation6Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation6 = z.infer<typeof HealthCareCodeInformation6Schema>;

export const HealthCareCodeInformation5Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation5 = z.infer<typeof HealthCareCodeInformation5Schema>;

export const HealthCareCodeInformation4Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation4 = z.infer<typeof HealthCareCodeInformation4Schema>;

export const HealthCareCodeInformation3Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation3 = z.infer<typeof HealthCareCodeInformation3Schema>;

export const HealthCareCodeInformation2Schema = z.object({
  code_list_qualifier_code_01: StickyCodeListQualifierCodeSchema,
  external_cause_of_injury_code_02: z.string(),
  present_on_admission_indicator_09: z
    .union([PresentOnAdmissionIndicatorSchema, z.null()])
    .optional(),
});
export type HealthCareCodeInformation2 = z.infer<typeof HealthCareCodeInformation2Schema>;

export const PurpleExternalCauseOfInjurySchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation2Schema,
  health_care_code_information_02: z.union([HealthCareCodeInformation3Schema, z.null()]).optional(),
  health_care_code_information_03: z.union([HealthCareCodeInformation4Schema, z.null()]).optional(),
  health_care_code_information_04: z.union([HealthCareCodeInformation5Schema, z.null()]).optional(),
  health_care_code_information_05: z.union([HealthCareCodeInformation6Schema, z.null()]).optional(),
  health_care_code_information_06: z.union([HealthCareCodeInformation7Schema, z.null()]).optional(),
  health_care_code_information_07: z.union([HealthCareCodeInformation8Schema, z.null()]).optional(),
  health_care_code_information_08: z.union([HealthCareCodeInformation9Schema, z.null()]).optional(),
  health_care_code_information_09: z
    .union([HealthCareCodeInformation10Schema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([HealthCareCodeInformation11Schema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([HealthCareCodeInformation12Schema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([HealthCareCodeInformation13Schema, z.null()])
    .optional(),
});
export type PurpleExternalCauseOfInjury = z.infer<typeof PurpleExternalCauseOfInjurySchema>;

export const PurpleEpsdtReferralSchema = z.object({
  certification_condition_code_applies_indicator_02:
    CertificationConditionCodeAppliesIndicatorSchema,
  code_qualifier_01: CodeQualifierSchema,
  condition_indicator_03: ConditionIndicatorSchema,
  condition_indicator_04: z.union([z.null(), z.string()]).optional(),
  condition_indicator_05: z.union([z.null(), z.string()]).optional(),
});
export type PurpleEpsdtReferral = z.infer<typeof PurpleEpsdtReferralSchema>;

export const PurpleDischargeHourSchema = z.object({
  date_time_period_format_qualifier_02: DischargeHourDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: DischargeHourDtpDateTimeQualifier01Schema,
  discharge_time_03: z.string(),
});
export type PurpleDischargeHour = z.infer<typeof PurpleDischargeHourSchema>;

export const HealthCareCodeInformation1Schema = z.object({
  code_list_qualifier_code_01: TentacledCodeListQualifierCodeSchema,
  diagnosis_related_group_drg_code_02: z.string(),
});
export type HealthCareCodeInformation1 = z.infer<typeof HealthCareCodeInformation1Schema>;

export const PurpleDiagnosisRelatedGroupDrgInformationSchema = z.object({
  health_care_code_information_01: HealthCareCodeInformation1Schema,
});
export type PurpleDiagnosisRelatedGroupDrgInformation = z.infer<
  typeof PurpleDiagnosisRelatedGroupDrgInformationSchema
>;

export const PurpleDemonstrationProjectIdentifierSchema = z.object({
  demonstration_project_identifier_02: z.string(),
  reference_identification_qualifier_01:
    DemonstrationProjectIdentifierRefReferenceIdentificationQualifier01Schema,
});
export type PurpleDemonstrationProjectIdentifier = z.infer<
  typeof PurpleDemonstrationProjectIdentifierSchema
>;

export const PurpleDateRepricerReceivedDateSchema = z.object({
  date_time_period_format_qualifier_02: DateTimePeriodFormatQualifier01_EnumSchema,
  date_time_qualifier_01: DateRepricerReceivedDateDtpDateTimeQualifier01Schema,
  repricer_received_date_03: z.string(),
});
export type PurpleDateRepricerReceivedDate = z.infer<typeof PurpleDateRepricerReceivedDateSchema>;

export const PurpleContractInformationSchema = z.object({
  contract_amount_02: z.union([z.number(), z.null()]).optional(),
  contract_code_04: z.union([z.null(), z.string()]).optional(),
  contract_percentage_03: z.union([z.number(), z.null()]).optional(),
  contract_type_code_01: ContractTypeCodeSchema,
  contract_version_identifier_06: z.union([z.null(), z.string()]).optional(),
  terms_discount_percentage_05: z.union([z.number(), z.null()]).optional(),
});
export type PurpleContractInformation = z.infer<typeof PurpleContractInformationSchema>;

export const BraggadociousHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type BraggadociousHealthCareCodeInformation = z.infer<
  typeof BraggadociousHealthCareCodeInformationSchema
>;

export const MischievousHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type MischievousHealthCareCodeInformation = z.infer<
  typeof MischievousHealthCareCodeInformationSchema
>;

export const FriskyHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type FriskyHealthCareCodeInformation = z.infer<typeof FriskyHealthCareCodeInformationSchema>;

export const MagentaHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type MagentaHealthCareCodeInformation = z.infer<
  typeof MagentaHealthCareCodeInformationSchema
>;

export const CunningHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type CunningHealthCareCodeInformation = z.infer<
  typeof CunningHealthCareCodeInformationSchema
>;

export const AmbitiousHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type AmbitiousHealthCareCodeInformation = z.infer<
  typeof AmbitiousHealthCareCodeInformationSchema
>;

export const HilariousHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type HilariousHealthCareCodeInformation = z.infer<
  typeof HilariousHealthCareCodeInformationSchema
>;

export const IndecentHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type IndecentHealthCareCodeInformation = z.infer<
  typeof IndecentHealthCareCodeInformationSchema
>;

export const IndigoHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type IndigoHealthCareCodeInformation = z.infer<typeof IndigoHealthCareCodeInformationSchema>;

export const StickyHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type StickyHealthCareCodeInformation = z.infer<typeof StickyHealthCareCodeInformationSchema>;

export const TentacledHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type TentacledHealthCareCodeInformation = z.infer<
  typeof TentacledHealthCareCodeInformationSchema
>;

export const FluffyHealthCareCodeInformationSchema = z.object({
  code_list_qualifier_code_01: FluffyCodeListQualifierCodeSchema,
  condition_code_02: z.string(),
});
export type FluffyHealthCareCodeInformation = z.infer<typeof FluffyHealthCareCodeInformationSchema>;

export const PurpleConditionInformationSchema = z.object({
  health_care_code_information_01: FluffyHealthCareCodeInformationSchema,
  health_care_code_information_02: z
    .union([TentacledHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_03: z
    .union([StickyHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_04: z
    .union([IndigoHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_05: z
    .union([IndecentHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_06: z
    .union([HilariousHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_07: z
    .union([AmbitiousHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_08: z
    .union([CunningHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_09: z
    .union([MagentaHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_10: z
    .union([FriskyHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_11: z
    .union([MischievousHealthCareCodeInformationSchema, z.null()])
    .optional(),
  health_care_code_information_12: z
    .union([BraggadociousHealthCareCodeInformationSchema, z.null()])
    .optional(),
});
export type PurpleConditionInformation = z.infer<typeof PurpleConditionInformationSchema>;

export const PurpleClaimSupplementalInformationSchema = z.object({
  attachment_control_number_06: z.union([z.null(), z.string()]).optional(),
  attachment_report_type_code_01: AttachmentReportTypeCodeSchema,
  attachment_transmission_code_02: AttachmentTransmissionCodeSchema,
  identification_code_qualifier_05: z
    .union([IdentificationCodeQualifier05_EnumSchema, z.null()])
    .optional(),
});
export type PurpleClaimSupplementalInformation = z.infer<
  typeof PurpleClaimSupplementalInformationSchema
>;

export const PurpleClaimPricingRepricingInformationSchema = z.object({
  exception_code_15: z.union([ExceptionCodeSchema, z.null()]).optional(),
  policy_compliance_code_14: z.union([PolicyComplianceCodeSchema, z.null()]).optional(),
  pricing_methodology_01: PricingMethodologySchema,
  reject_reason_code_13: z.union([RejectReasonCodeSchema, z.null()]).optional(),
  repriced_allowed_amount_02: z.number(),
  repriced_approved_amount_07: z.union([z.number(), z.null()]).optional(),
  repriced_approved_drg_code_06: z.union([z.null(), z.string()]).optional(),
  repriced_approved_revenue_code_08: z.union([z.null(), z.string()]).optional(),
  repriced_approved_service_unit_count_12: z.union([z.number(), z.null()]).optional(),
  repriced_saving_amount_03: z.union([z.number(), z.null()]).optional(),
  repricing_organization_identifier_04: z.union([z.null(), z.string()]).optional(),
  repricing_per_diem_or_flat_rate_amount_05: z.union([z.number(), z.null()]).optional(),
  unit_or_basis_for_measurement_code_11: z
    .union([UnitOrBasisForMeasurementCodeSchema, z.null()])
    .optional(),
});
export type PurpleClaimPricingRepricingInformation = z.infer<
  typeof PurpleClaimPricingRepricingInformationSchema
>;

export const PurpleClaimNoteSchema = z.object({
  claim_note_text_02: z.string(),
  note_reference_code_01: ClaimNoteNteNoteReferenceCode01Schema,
});
export type PurpleClaimNote = z.infer<typeof PurpleClaimNoteSchema>;

export const PurpleHealthCareServiceLocationInformationSchema = z.object({
  claim_frequency_code_03: z.string(),
  facility_code_qualifier_02: FacilityCodeQualifierSchema,
  facility_type_code_01: z.string(),
});
export type PurpleHealthCareServiceLocationInformation = z.infer<
  typeof PurpleHealthCareServiceLocationInformationSchema
>;

export const PurpleClaimInformationSchema = z.object({
  assignment_or_plan_participation_code_07: AssignmentOrPlanParticipationCodeSchema,
  benefits_assignment_certification_indicator_08: BenefitsAssignmentCertificationIndicatorSchema,
  delay_reason_code_20: z.union([DelayReasonCodeSchema, z.null()]).optional(),
  health_care_service_location_information_05: PurpleHealthCareServiceLocationInformationSchema,
  patient_control_number_01: z.string(),
  release_of_information_code_09: ReleaseOfInformationCodeSchema,
  total_claim_charge_amount_02: z.number(),
});
export type PurpleClaimInformation = z.infer<typeof PurpleClaimInformationSchema>;

export const PurpleClaimIdentifierForTransmissionIntermediariesSchema = z.object({
  reference_identification_qualifier_01:
    ClaimIdentifierForTransmissionIntermediariesRefReferenceIdentificationQualifier01Schema,
  value_added_network_trace_number_02: z.string(),
});
export type PurpleClaimIdentifierForTransmissionIntermediaries = z.infer<
  typeof PurpleClaimIdentifierForTransmissionIntermediariesSchema
>;

export const PurpleBillingNoteSchema = z.object({
  billing_note_text_02: z.string(),
  note_reference_code_01: BillingNoteNteNoteReferenceCode01Schema,
});
export type PurpleBillingNote = z.infer<typeof PurpleBillingNoteSchema>;

export const PurpleAutoAccidentStateSchema = z.object({
  auto_accident_state_or_province_code_02: z.string(),
  reference_identification_qualifier_01:
    AutoAccidentStateRefReferenceIdentificationQualifier01Schema,
});
export type PurpleAutoAccidentState = z.infer<typeof PurpleAutoAccidentStateSchema>;

export const PurpleAttendingProviderSpecialtyInformationSchema = z.object({
  provider_code_01: ProviderCodeSchema,
  provider_taxonomy_code_03: z.string(),
  reference_identification_qualifier_02: ReferenceIdentificationQualifier02_EnumSchema,
});
export type PurpleAttendingProviderSpecialtyInformation = z.infer<
  typeof PurpleAttendingProviderSpecialtyInformationSchema
>;

export const PurpleAttendingProviderSecondaryIdentificationSchema = z.object({
  attending_provider_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    AttendingProviderSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PurpleAttendingProviderSecondaryIdentification = z.infer<
  typeof PurpleAttendingProviderSecondaryIdentificationSchema
>;

export const PurpleAttendingProviderNameSchema = z.object({
  attending_provider_first_name_04: z.union([z.null(), z.string()]).optional(),
  attending_provider_last_name_03: z.string(),
  attending_provider_middle_name_or_initial_05: z.union([z.null(), z.string()]).optional(),
  attending_provider_name_suffix_07: z.union([z.null(), z.string()]).optional(),
  attending_provider_primary_identifier_09: z.union([z.null(), z.string()]).optional(),
  entity_identifier_code_01: AttendingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: AttendingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
});
export type PurpleAttendingProviderName = z.infer<typeof PurpleAttendingProviderNameSchema>;

export const PurpleAttendingProviderNameLoopSchema = z.object({
  attending_provider_name_NM1: PurpleAttendingProviderNameSchema,
  attending_provider_secondary_identification_REF: z
    .union([z.array(PurpleAttendingProviderSecondaryIdentificationSchema), z.null()])
    .optional(),
  attending_provider_specialty_information_PRV: z
    .union([PurpleAttendingProviderSpecialtyInformationSchema, z.null()])
    .optional(),
});
export type PurpleAttendingProviderNameLoop = z.infer<typeof PurpleAttendingProviderNameLoopSchema>;

export const PurpleHealthCareCodeInformationSchema = z.object({
  admitting_diagnosis_code_02: z.string(),
  code_list_qualifier_code_01: PurpleCodeListQualifierCodeSchema,
});
export type PurpleHealthCareCodeInformation = z.infer<typeof PurpleHealthCareCodeInformationSchema>;

export const PurpleAdmittingDiagnosisSchema = z.object({
  health_care_code_information_01: PurpleHealthCareCodeInformationSchema,
});
export type PurpleAdmittingDiagnosis = z.infer<typeof PurpleAdmittingDiagnosisSchema>;

export const PurpleAdmissionDateHourSchema = z.object({
  admission_date_and_hour_03: z.string(),
  date_time_period_format_qualifier_02: AdmissionDateHourDtpDateTimePeriodFormatQualifier02Schema,
  date_time_qualifier_01: AdmissionDateHourDtpDateTimeQualifier01Schema,
});
export type PurpleAdmissionDateHour = z.infer<typeof PurpleAdmissionDateHourSchema>;

export const PurpleAdjustedRepricedClaimNumberSchema = z.object({
  adjusted_repriced_claim_reference_number_02: z.string(),
  reference_identification_qualifier_01:
    AdjustedRepricedClaimNumberRefReferenceIdentificationQualifier01Schema,
});
export type PurpleAdjustedRepricedClaimNumber = z.infer<
  typeof PurpleAdjustedRepricedClaimNumberSchema
>;

export const SubscriberHierarchicalLevelHlLoopClaimInformationClmLoopSchema = z.object({
  adjusted_repriced_claim_number_REF: z
    .union([PurpleAdjustedRepricedClaimNumberSchema, z.null()])
    .optional(),
  admission_date_hour_DTP: z.union([PurpleAdmissionDateHourSchema, z.null()]).optional(),
  admitting_diagnosis_HI: z.union([PurpleAdmittingDiagnosisSchema, z.null()]).optional(),
  attending_provider_name_NM1_loop: z
    .union([PurpleAttendingProviderNameLoopSchema, z.null()])
    .optional(),
  auto_accident_state_REF: z.union([PurpleAutoAccidentStateSchema, z.null()]).optional(),
  billing_note_NTE: z.union([PurpleBillingNoteSchema, z.null()]).optional(),
  claim_identifier_for_transmission_intermediaries_REF: z
    .union([PurpleClaimIdentifierForTransmissionIntermediariesSchema, z.null()])
    .optional(),
  claim_information_CLM: PurpleClaimInformationSchema,
  claim_note_NTE: z.union([z.array(PurpleClaimNoteSchema), z.null()]).optional(),
  claim_pricing_repricing_information_HCP: z
    .union([PurpleClaimPricingRepricingInformationSchema, z.null()])
    .optional(),
  claim_supplemental_information_PWK: z
    .union([z.array(PurpleClaimSupplementalInformationSchema), z.null()])
    .optional(),
  condition_information_HI: z
    .union([z.array(PurpleConditionInformationSchema), z.null()])
    .optional(),
  contract_information_CN1: z.union([PurpleContractInformationSchema, z.null()]).optional(),
  date_repricer_received_date_DTP: z
    .union([PurpleDateRepricerReceivedDateSchema, z.null()])
    .optional(),
  demonstration_project_identifier_REF: z
    .union([PurpleDemonstrationProjectIdentifierSchema, z.null()])
    .optional(),
  diagnosis_related_group_drg_information_HI: z
    .union([PurpleDiagnosisRelatedGroupDrgInformationSchema, z.null()])
    .optional(),
  discharge_hour_DTP: z.union([PurpleDischargeHourSchema, z.null()]).optional(),
  epsdt_referral_CRC: z.union([PurpleEpsdtReferralSchema, z.null()]).optional(),
  external_cause_of_injury_HI: z.union([PurpleExternalCauseOfInjurySchema, z.null()]).optional(),
  file_information_K3: z.union([z.array(PurpleFileInformationSchema), z.null()]).optional(),
  institutional_claim_code_CL1: PurpleInstitutionalClaimCodeSchema,
  investigational_device_exemption_number_REF: z
    .union([z.array(PurpleInvestigationalDeviceExemptionNumberSchema), z.null()])
    .optional(),
  medical_record_number_REF: z.union([PurpleMedicalRecordNumberSchema, z.null()]).optional(),
  occurrence_information_HI: z
    .union([z.array(PurpleOccurrenceInformationSchema), z.null()])
    .optional(),
  occurrence_span_information_HI: z
    .union([z.array(PurpleOccurrenceSpanInformationSchema), z.null()])
    .optional(),
  operating_physician_name_NM1_loop: z
    .union([PurpleOperatingPhysicianNameLoopSchema, z.null()])
    .optional(),
  other_diagnosis_information_HI: z
    .union([z.array(PurpleOtherDiagnosisInformationSchema), z.null()])
    .optional(),
  other_operating_physician_name_NM1_loop: z
    .union([PurpleOtherOperatingPhysicianNameLoopSchema, z.null()])
    .optional(),
  other_procedure_information_HI: z
    .union([z.array(PurpleOtherProcedureInformationSchema), z.null()])
    .optional(),
  other_subscriber_information_SBR_loop: z
    .union([z.array(PurpleOtherSubscriberInformationLoopSchema), z.null()])
    .optional(),
  patient_estimated_amount_due_AMT: z
    .union([PurplePatientEstimatedAmountDueSchema, z.null()])
    .optional(),
  patients_reason_for_visit_HI: z.union([PurplePatientSReasonForVisitSchema, z.null()]).optional(),
  payer_claim_control_number_REF: z
    .union([PurplePayerClaimControlNumberSchema, z.null()])
    .optional(),
  peer_review_organization_pro_approval_number_REF: z
    .union([PurplePeerReviewOrganizationProApprovalNumberSchema, z.null()])
    .optional(),
  principal_diagnosis_HI: PurplePrincipalDiagnosisSchema,
  principal_procedure_information_HI: z
    .union([PurplePrincipalProcedureInformationSchema, z.null()])
    .optional(),
  prior_authorization_REF: z.union([PurplePriorAuthorizationSchema, z.null()]).optional(),
  referral_number_REF: z.union([PurpleReferralNumberSchema, z.null()]).optional(),
  referring_provider_name_NM1_loop: z
    .union([PurpleReferringProviderNameLoopSchema, z.null()])
    .optional(),
  rendering_provider_name_NM1_loop: z
    .union([PurpleRenderingProviderNameLoopSchema, z.null()])
    .optional(),
  repriced_claim_number_REF: z.union([PurpleRepricedClaimNumberSchema, z.null()]).optional(),
  service_authorization_exception_code_REF: z
    .union([PurpleServiceAuthorizationExceptionCodeSchema, z.null()])
    .optional(),
  service_facility_location_name_NM1_loop: z
    .union([PurpleServiceFacilityLocationNameLoopSchema, z.null()])
    .optional(),
  service_line_number_LX_loop: z.array(PurpleServiceLineNumberLoopSchema),
  statement_dates_DTP: PurpleStatementDatesSchema,
  treatment_code_information_HI: z
    .union([z.array(PurpleTreatmentCodeInformationSchema), z.null()])
    .optional(),
  value_information_HI: z.union([z.array(PurpleValueInformationSchema), z.null()]).optional(),
});
export type SubscriberHierarchicalLevelHlLoopClaimInformationClmLoop = z.infer<
  typeof SubscriberHierarchicalLevelHlLoopClaimInformationClmLoopSchema
>;

export const SubscriberHierarchicalLevelLoopSchema = z.object({
  claim_information_CLM_loop: z
    .union([z.array(SubscriberHierarchicalLevelHlLoopClaimInformationClmLoopSchema), z.null()])
    .optional(),
  patient_hierarchical_level_HL_loop: z
    .union([z.array(PatientHierarchicalLevelLoopSchema), z.null()])
    .optional(),
  payer_name_NM1_loop: PayerNameLoopSchema,
  subscriber_information_SBR: SubscriberInformationSchema,
  subscriber_name_NM1_loop: SubscriberNameLoopSchema,
});
export type SubscriberHierarchicalLevelLoop = z.infer<typeof SubscriberHierarchicalLevelLoopSchema>;

export const PayToPlanTaxIdentificationNumberSchema = z.object({
  pay_to_plan_tax_identification_number_02: z.string(),
  reference_identification_qualifier_01:
    BillingProviderTaxIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PayToPlanTaxIdentificationNumber = z.infer<
  typeof PayToPlanTaxIdentificationNumberSchema
>;

export const PayToPlanSecondaryIdentificationSchema = z.object({
  pay_to_plan_secondary_identifier_02: z.string(),
  reference_identification_qualifier_01:
    PayToPlanSecondaryIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type PayToPlanSecondaryIdentification = z.infer<
  typeof PayToPlanSecondaryIdentificationSchema
>;

export const PayToPlanNameSchema = z.object({
  entity_identifier_code_01: PayToPlanNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: PayToPlanNameNm1IdentificationCodeQualifier08Schema,
  pay_to_plan_organizational_name_03: z.string(),
  pay_to_plan_primary_identifier_09: z.string(),
});
export type PayToPlanName = z.infer<typeof PayToPlanNameSchema>;

export const PayToPlanCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  pay_to_plan_city_name_01: z.string(),
  pay_to_plan_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  pay_to_plan_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PayToPlanCityStateZipCode = z.infer<typeof PayToPlanCityStateZipCodeSchema>;

export const PayToPlanAddressSchema = z.object({
  pay_to_plan_address_line_01: z.string(),
  pay_to_plan_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PayToPlanAddress = z.infer<typeof PayToPlanAddressSchema>;

export const PayToPlanNameLoopSchema = z.object({
  pay_to_plan_address_N3: PayToPlanAddressSchema,
  pay_to_plan_city_state_zip_code_N4: PayToPlanCityStateZipCodeSchema,
  pay_to_plan_name_NM1: PayToPlanNameSchema,
  pay_to_plan_secondary_identification_REF: z
    .union([PayToPlanSecondaryIdentificationSchema, z.null()])
    .optional(),
  pay_to_plan_tax_identification_number_REF: PayToPlanTaxIdentificationNumberSchema,
});
export type PayToPlanNameLoop = z.infer<typeof PayToPlanNameLoopSchema>;

export const PayToAddressNameSchema = z.object({
  entity_identifier_code_01: PayToAddressNameNm1EntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
});
export type PayToAddressName = z.infer<typeof PayToAddressNameSchema>;

export const PayToAddressCityStateZipCodeSchema = z.object({
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
  pay_to_address_city_name_01: z.string(),
  pay_to_address_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  pay_to_address_state_code_02: z.union([z.null(), z.string()]).optional(),
});
export type PayToAddressCityStateZipCode = z.infer<typeof PayToAddressCityStateZipCodeSchema>;

export const PayToAddressAddressSchema = z.object({
  pay_to_address_line_01: z.string(),
  pay_to_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type PayToAddressAddress = z.infer<typeof PayToAddressAddressSchema>;

export const PayToAddressNameLoopSchema = z.object({
  pay_to_address_address_N3: PayToAddressAddressSchema,
  pay_to_address_city_state_zip_code_N4: PayToAddressCityStateZipCodeSchema,
  pay_to_address_name_NM1: PayToAddressNameSchema,
});
export type PayToAddressNameLoop = z.infer<typeof PayToAddressNameLoopSchema>;

export const ForeignCurrencyInformationSchema = z.object({
  currency_code_02: z.string(),
  entity_identifier_code_01: BillingProviderNameEntityIdentifierCode01Schema,
});
export type ForeignCurrencyInformation = z.infer<typeof ForeignCurrencyInformationSchema>;

export const BillingProviderSpecialtyInformationSchema = z.object({
  provider_code_01: ErCodeSchema,
  provider_taxonomy_code_03: z.string(),
  reference_identification_qualifier_02: ReferenceIdentificationQualifier02_EnumSchema,
});
export type BillingProviderSpecialtyInformation = z.infer<
  typeof BillingProviderSpecialtyInformationSchema
>;

export const BillingProviderTaxIdentificationSchema = z.object({
  billing_provider_tax_identification_number_02: z.string(),
  reference_identification_qualifier_01:
    BillingProviderTaxIdentificationRefReferenceIdentificationQualifier01Schema,
});
export type BillingProviderTaxIdentification = z.infer<
  typeof BillingProviderTaxIdentificationSchema
>;

export const BillingProviderNameSchema = z.object({
  billing_provider_identifier_09: z.union([z.null(), z.string()]).optional(),
  billing_provider_organizational_name_03: z.string(),
  entity_identifier_code_01: BillingProviderNameEntityIdentifierCode01Schema,
  entity_type_qualifier_02: BillingProviderNameEntityTypeQualifier02Schema,
  identification_code_qualifier_08: z
    .union([BillingProviderNameIdentificationCodeQualifier08Schema, z.null()])
    .optional(),
});
export type BillingProviderName = z.infer<typeof BillingProviderNameSchema>;

export const BillingProviderContactInformationSchema = z.object({
  billing_provider_contact_name_02: z.union([z.null(), z.string()]).optional(),
  communication_number_04: z.string(),
  communication_number_06: z.union([z.null(), z.string()]).optional(),
  communication_number_08: z.union([z.null(), z.string()]).optional(),
  communication_number_qualifier_03: CommunicationNumberQualifier03_EnumSchema,
  communication_number_qualifier_05: z
    .union([CommunicationNumberQualifier05_EnumSchema, z.null()])
    .optional(),
  communication_number_qualifier_07: z
    .union([CommunicationNumberQualifier05_EnumSchema, z.null()])
    .optional(),
  contact_function_code_01: ContactFunctionCodeSchema,
});
export type BillingProviderContactInformation = z.infer<
  typeof BillingProviderContactInformationSchema
>;

export const BillingProviderCityStateZipCodeSchema = z.object({
  billing_provider_city_name_01: z.string(),
  billing_provider_postal_zone_or_zip_code_03: z.union([z.null(), z.string()]).optional(),
  billing_provider_state_or_province_code_02: z.union([z.null(), z.string()]).optional(),
  country_code_04: z.union([z.null(), z.string()]).optional(),
  country_subdivision_code_07: z.union([z.null(), z.string()]).optional(),
});
export type BillingProviderCityStateZipCode = z.infer<typeof BillingProviderCityStateZipCodeSchema>;

export const BillingProviderAddressSchema = z.object({
  billing_provider_address_line_01: z.string(),
  billing_provider_address_line_02: z.union([z.null(), z.string()]).optional(),
});
export type BillingProviderAddress = z.infer<typeof BillingProviderAddressSchema>;

export const BillingProviderNameLoopSchema = z.object({
  billing_provider_address_N3: BillingProviderAddressSchema,
  billing_provider_city_state_zip_code_N4: BillingProviderCityStateZipCodeSchema,
  billing_provider_contact_information_PER: z
    .union([z.array(BillingProviderContactInformationSchema), z.null()])
    .optional(),
  billing_provider_name_NM1: BillingProviderNameSchema,
  billing_provider_tax_identification_REF: BillingProviderTaxIdentificationSchema,
});
export type BillingProviderNameLoop = z.infer<typeof BillingProviderNameLoopSchema>;

export const BillingProviderHierarchicalLevelLoopSchema = z.object({
  billing_provider_name_NM1_loop: BillingProviderNameLoopSchema,
  billing_provider_specialty_information_PRV: z
    .union([BillingProviderSpecialtyInformationSchema, z.null()])
    .optional(),
  foreign_currency_information_CUR: z
    .union([ForeignCurrencyInformationSchema, z.null()])
    .optional(),
  pay_to_address_name_NM1_loop: z.union([PayToAddressNameLoopSchema, z.null()]).optional(),
  pay_to_plan_name_NM1_loop: z.union([PayToPlanNameLoopSchema, z.null()]).optional(),
  subscriber_hierarchical_level_HL_loop: z.array(SubscriberHierarchicalLevelLoopSchema),
});
export type BillingProviderHierarchicalLevelLoop = z.infer<
  typeof BillingProviderHierarchicalLevelLoopSchema
>;

export const DetailSchema = z.object({
  billing_provider_hierarchical_level_HL_loop: z.array(BillingProviderHierarchicalLevelLoopSchema),
  transaction_set_trailer_SE: z.union([TransactionSetTrailerSchema, z.null()]).optional(),
});
export type Detail = z.infer<typeof DetailSchema>;

export const X12837ISchema = z.object({
  detail: DetailSchema,
  heading: HeadingSchema,
});
export type X12837I = z.infer<typeof X12837ISchema>;
