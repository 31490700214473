export function index<TRecord, TBy extends keyof TRecord>(items: TRecord[], { by }: { by: TBy }) {
  const out: Record<string, TRecord> = {};
  for (const item of items) {
    const key = item[by];
    if (typeof key === "string") {
      out[key] = item;
    } else {
      throw new Error(`Can't index by non-string values`);
    }
  }
  return out;
}
