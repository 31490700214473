import { z } from 'zod';
import { ListRuleOperationSchema } from '../inputTypeSchemas/ListRuleOperationSchema'
import { ListRuleConditionSchema } from '../inputTypeSchemas/ListRuleConditionSchema'

/////////////////////////////////////////
// CONTRACT LIST RULE SCHEMA
/////////////////////////////////////////

export const ContractListRuleSchema = z.object({
  operation: ListRuleOperationSchema,
  condition: ListRuleConditionSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  listId: z.string(),
  manipulator: z.number(),
  comparison: z.number(),
})

export type ContractListRule = z.infer<typeof ContractListRuleSchema>

export default ContractListRuleSchema;
