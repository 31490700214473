import { z } from 'zod';

/////////////////////////////////////////
// ADDRESS SCHEMA
/////////////////////////////////////////

export const AddressSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  address1: z.string(),
  address2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Address = z.infer<typeof AddressSchema>

export default AddressSchema;
