import { z } from 'zod';

/////////////////////////////////////////
// QUESTIONNAIRE SCHEMA
/////////////////////////////////////////

export const QuestionnaireSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  name: z.string(),
  latestVersion: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Questionnaire = z.infer<typeof QuestionnaireSchema>

export default QuestionnaireSchema;
