import { z } from 'zod';
import { AuditOperationSchema } from '../inputTypeSchemas/AuditOperationSchema'
import { OrganizationTypeSchema } from '../inputTypeSchemas/OrganizationTypeSchema'
import { CaseStatusSchema } from '../inputTypeSchemas/CaseStatusSchema'
import { SexSchema } from '../inputTypeSchemas/SexSchema'
import { AsaClassificationSchema } from '../inputTypeSchemas/AsaClassificationSchema'
import { HealthReviewStatusSchema } from '../inputTypeSchemas/HealthReviewStatusSchema'

/////////////////////////////////////////
// CASE VERSION SCHEMA
/////////////////////////////////////////

export const CaseVersionSchema = z.object({
  versionOperation: AuditOperationSchema,
  createdBy: OrganizationTypeSchema.nullish(),
  status: CaseStatusSchema.nullish(),
  patientSex: SexSchema.nullish(),
  asaClassification: AsaClassificationSchema.nullish(),
  healthReviewStatus: HealthReviewStatusSchema.nullish(),
  versionId: z.number().int(),
  versionSourceId: z.string().nullish(),
  versionUserId: z.string().nullish(),
  versionTimestamp: z.coerce.date(),
  id: z.string().nullish(),
  financialReference: z.number().int().nullish(),
  requestedById: z.string().nullish(),
  cancellationReason: z.string().nullish(),
  surgeryDate: z.coerce.date().nullish(),
  roomId: z.string().nullish(),
  facilityId: z.string().nullish(),
  practiceId: z.string().nullish(),
  name: z.string().nullish(),
  specialInstructions: z.string().nullish(),
  patientFirstName: z.string().nullish(),
  patientLastName: z.string().nullish(),
  patientMiddleName: z.string().nullish(),
  patientNameSuffix: z.string().nullish(),
  patientAddress1: z.string().nullish(),
  patientAddress2: z.string().nullish(),
  patientCity: z.string().nullish(),
  patientState: z.string().nullish(),
  patientZip: z.string().nullish(),
  patientMobilePhone: z.string().nullish(),
  patientHomePhone: z.string().nullish(),
  patientEmail: z.string().nullish(),
  patientDateOfBirth: z.coerce.date().nullish(),
  patientSsn: z.string().nullish(),
  heightInInches: z.number().nullish(),
  weightInPounds: z.number().nullish(),
  bmi: z.number().nullish(),
  surgeonId: z.string().nullish(),
  additionalStaffId: z.string().nullish(),
  guardianName: z.string().nullish(),
  guardianPhone: z.string().nullish(),
  guardianRelationship: z.string().nullish(),
  expectedCaseLength: z.number().int().nullish(),
  roomTurnOverTime: z.number().int().nullish(),
  createdAt: z.coerce.date().nullish(),
  updatedAt: z.coerce.date().nullish(),
  acknowledgedAt: z.coerce.date().nullish(),
  anesthesiaType: z.string().nullish(),
  arriveMinutesBeforeAppointment: z.number().int().nullish(),
  referringPhysicianName: z.string().nullish(),
  referringPhysicianPhone: z.string().nullish(),
  patientId: z.string().nullish(),
  overnightStay: z.boolean().nullish(),
  messageThreadId: z.string().nullish(),
  facilityMessageThreadId: z.string().nullish(),
  prePaymentType: z.string().nullish(),
  prePaymentAmount: z.number().int().nullish(),
  prePaymentNotes: z.string().nullish(),
  prePaymentTransferredAt: z.coerce.date().nullish(),
  createdFromSurgeryProfileId: z.string().nullish(),
})

export type CaseVersion = z.infer<typeof CaseVersionSchema>

export default CaseVersionSchema;
