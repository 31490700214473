import { z } from 'zod';
import { JsonValueSchema } from '../inputTypeSchemas/JsonValueSchema'
import { EmrSectionTypeSchema } from '../inputTypeSchemas/EmrSectionTypeSchema'

/////////////////////////////////////////
// EMR TEMPLATE SECTION SCHEMA
/////////////////////////////////////////

export const EmrTemplateSectionSchema = z.object({
  type: EmrSectionTypeSchema,
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  emrTemplatePageId: z.string(),
  heading: z.string(),
  sequenceNumber: z.number().int(),
  config: JsonValueSchema.nullable(),
})

export type EmrTemplateSection = z.infer<typeof EmrTemplateSectionSchema>

export default EmrTemplateSectionSchema;
