import { z } from 'zod';
import { OrganizationTypeSchema } from '../inputTypeSchemas/OrganizationTypeSchema'
import { CaseStatusSchema } from '../inputTypeSchemas/CaseStatusSchema'
import { SexSchema } from '../inputTypeSchemas/SexSchema'
import { AsaClassificationSchema } from '../inputTypeSchemas/AsaClassificationSchema'
import { HealthReviewStatusSchema } from '../inputTypeSchemas/HealthReviewStatusSchema'
import { AnesthesiaTypeSchema } from '../inputTypeSchemas/AnesthesiaTypeSchema'
import { PaymentTypeSchema } from '../inputTypeSchemas/PaymentTypeSchema'

/////////////////////////////////////////
// CASE SCHEMA
/////////////////////////////////////////

export const CaseSchema = z.object({
  createdBy: OrganizationTypeSchema,
  status: CaseStatusSchema,
  patientSex: SexSchema.nullish(),
  asaClassification: AsaClassificationSchema.nullish(),
  healthReviewStatus: HealthReviewStatusSchema,
  anesthesiaType: AnesthesiaTypeSchema.nullish(),
  prePaymentType: PaymentTypeSchema.nullish(),
  id: z.string(),
  /**
   * A second identifier for the case. This is used for billing purposes. Keep financial data from comingling with medical data.
   */
  financialReference: z.number().int(),
  requestedById: z.string(),
  cancellationReason: z.string().nullish(),
  surgeryDate: z.coerce.date(),
  roomId: z.string().nullish(),
  facilityId: z.string(),
  practiceId: z.string(),
  /**
   * PHI
   */
  name: z.string(),
  specialInstructions: z.string(),
  /**
   * PHI
   */
  patientFirstName: z.string().nullish(),
  /**
   * PHI
   */
  patientLastName: z.string().nullish(),
  /**
   * PHI
   */
  patientMiddleName: z.string().nullish(),
  patientNameSuffix: z.string().nullish(),
  /**
   * PHI
   */
  patientAddress1: z.string().nullish(),
  patientAddress2: z.string().nullish(),
  patientCity: z.string().nullish(),
  patientState: z.string().nullish(),
  patientZip: z.string().nullish(),
  /**
   * PHI
   */
  patientMobilePhone: z.string().nullish(),
  /**
   * PHI
   */
  patientHomePhone: z.string().nullish(),
  /**
   * PHI
   */
  patientEmail: z.string().nullish(),
  /**
   * PHI
   */
  patientDateOfBirth: z.coerce.date().nullish(),
  /**
   * PHI
   */
  patientSsn: z.string().nullish(),
  heightInInches: z.number().nullish(),
  weightInPounds: z.number().nullish(),
  bmi: z.number().nullish(),
  surgeonId: z.string(),
  additionalStaffId: z.string().nullish(),
  guardianName: z.string(),
  guardianPhone: z.string(),
  guardianRelationship: z.string(),
  expectedCaseLength: z.number().int().nullish(),
  roomTurnOverTime: z.number().int().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  acknowledgedAt: z.coerce.date().nullish(),
  arriveMinutesBeforeAppointment: z.number().int().nullish(),
  referringPhysicianName: z.string(),
  referringPhysicianPhone: z.string(),
  patientId: z.string().nullish(),
  overnightStay: z.boolean(),
  messageThreadId: z.string().nullish(),
  facilityMessageThreadId: z.string().nullish(),
  prePaymentAmount: z.number().int().nullish(),
  prePaymentNotes: z.string().nullish(),
  prePaymentTransferredAt: z.coerce.date().nullish(),
  createdFromSurgeryProfileId: z.string().nullish(),
})

export type Case = z.infer<typeof CaseSchema>

export default CaseSchema;
