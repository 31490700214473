import { z } from 'zod';

/////////////////////////////////////////
// STANDING MEDICATION ORDER SCHEMA
/////////////////////////////////////////

export const StandingMedicationOrderSchema = z.object({
  id: z.string(),
  standingOrderId: z.string(),
  medId: z.string(),
  registrarId: z.string(),
  doseageNumber: z.number().nullish(),
  doseageUomId: z.string().nullish(),
  frequency: z.string().nullish(),
  route: z.string().nullish(),
  site: z.string().nullish(),
})

export type StandingMedicationOrder = z.infer<typeof StandingMedicationOrderSchema>

export default StandingMedicationOrderSchema;
