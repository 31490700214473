import { z } from 'zod';
import { PatientMedicationReconciliationSchema } from '../inputTypeSchemas/PatientMedicationReconciliationSchema'

/////////////////////////////////////////
// PATIENT MEDICATION CHANGE SCHEMA
/////////////////////////////////////////

export const PatientMedicationChangeSchema = z.object({
  action: PatientMedicationReconciliationSchema,
  id: z.string(),
  medicationId: z.string(),
  encounterId: z.string(),
  dose: z.string().nullish(),
  doseUnitsId: z.string().nullish(),
  frequency: z.string().nullish(),
  notes: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type PatientMedicationChange = z.infer<typeof PatientMedicationChangeSchema>

export default PatientMedicationChangeSchema;
