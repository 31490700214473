import { type Prisma, type PrismaClient } from "@procision-software/database";
import type { PaginationInput } from "../../util/pagination";
import type { ProviderEncounterDocumentFormData, ProviderEncounterDocumentRecord } from "./types";

type ProviderEncounterDocumentFindFilter = {
  staffId?: string;
  encounterId?: string;
};
const RELATED_MODELS = {
  encounterDocument: {
    include: {
      type: true,
      documentTemplate: {
        include: {
          organization: true,
          type: true,
        },
      },
      signatures: {
        include: {
          appliedJobRole: true,
          appliedPatientSignature: {
            include: {
              patient: true,
            },
          },
          appliedStaffSignature: {
            include: {
              staff: true,
            },
          },
        },
      },
    } satisfies Prisma.EncounterDocumentInclude,
  },
  staff: true,
} as const;

function providerEncounterDocumentWhereClause(
  filters: ProviderEncounterDocumentFindFilter
): Prisma.ProviderEncounterDocumentWhereInput {
  const where: Prisma.ProviderEncounterDocumentWhereInput = {};
  if (filters.staffId) {
    where.staffId = filters.staffId;
  }
  if (filters.encounterId) {
    where.encounterDocument = { encounterId: filters.encounterId };
  }
  return where;
}

export async function providerEncounterDocumentFindMany(
  prisma: PrismaClient,
  filters: ProviderEncounterDocumentFindFilter,
  pagination: PaginationInput
): Promise<ProviderEncounterDocumentRecord[]> {
  const where = providerEncounterDocumentWhereClause(filters);
  const records = await prisma.providerEncounterDocument.findMany({
    where,
    skip: (pagination.page - 1) * pagination.perPage,
    take: pagination.perPage,
    include: RELATED_MODELS,
  });
  return records as ProviderEncounterDocumentRecord[];
}

export function providerEncounterDocumentCount(
  prisma: PrismaClient,
  filters: ProviderEncounterDocumentFindFilter
): Promise<number> {
  const where = providerEncounterDocumentWhereClause(filters);
  return prisma.providerEncounterDocument.count({ where });
}

export async function providerEncounterDocumentFindById(
  prisma: PrismaClient,
  id: string
): Promise<ProviderEncounterDocumentRecord> {
  return (await prisma.providerEncounterDocument.findFirstOrThrow({
    where: { id },
    include: RELATED_MODELS,
  })) as ProviderEncounterDocumentRecord;
}

export async function providerEncounterDocumentCreate(
  prisma: PrismaClient,
  { encounterDocumentId, staffId }: ProviderEncounterDocumentFormData
): Promise<ProviderEncounterDocumentRecord> {
  await prisma.encounterDocument.findFirstOrThrow({
    where: { id: encounterDocumentId },
  });
  await prisma.staff.findFirstOrThrow({
    where: { id: staffId },
  });
  const ped = await prisma.providerEncounterDocument.create({
    data: {
      encounterDocument: { connect: { id: encounterDocumentId } },
      staff: { connect: { id: staffId } },
    },
    include: RELATED_MODELS,
  });
  return await providerEncounterDocumentFindById(prisma, ped.id);
}

export function providerEncounterDocumentUpdate(
  _prisma: PrismaClient,
  _id: string,
  _data: Partial<ProviderEncounterDocumentFormData>
): Promise<ProviderEncounterDocumentRecord> {
  throw new Error("Not supported");
}

export async function providerEncounterDocumentDelete(
  prisma: PrismaClient,
  id: string
): Promise<boolean> {
  const record = await prisma.providerEncounterDocument.findFirstOrThrow({
    where: { id },
  });
  await prisma.providerEncounterDocument.delete({
    where: { id },
  });
  await prisma.encounterDocument.delete({
    where: { id: record.encounterDocumentId },
  });

  return true;
}
