import { z } from 'zod';

/////////////////////////////////////////
// HEALTH REVIEW REQUIREMENT ATTACHMENT SCHEMA
/////////////////////////////////////////

export const HealthReviewRequirementAttachmentSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  healthReviewRequirementId: z.string(),
  storageBlobId: z.string(),
  name: z.string(),
})

export type HealthReviewRequirementAttachment = z.infer<typeof HealthReviewRequirementAttachmentSchema>

export default HealthReviewRequirementAttachmentSchema;
