import { cn } from "@procision-software/ui";
import React, { forwardRef } from "react";

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  error?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

/**
 * @deprecated Use UI package components instead
 */
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, error, icon: Icon, ...props }, ref) => (
    <div className="group relative w-full">
      <textarea
        {...props}
        rows={props.rows ?? 4}
        ref={ref}
        className={cn(
          "input relative w-full",
          props.disabled && "cursor-not-allowed opacity-50",
          error && "input-error",
          Icon && "pl-8",
          className
        )}
      />
      {Icon && (
        <span
          className={cn(
            "pointer-events-none absolute inset-y-0 left-0 top-0 pl-2 pt-2.5",
            props.disabled && "cursor-not-allowed opacity-50"
          )}
        >
          <Icon className="h-5 w-5" />
        </span>
      )}
    </div>
  )
);
Textarea.displayName = "Textarea";
