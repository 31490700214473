import { z } from "zod";

export const PaginationStateSchema = z.object({
  all: z.number(),
  page: z.number(),
  perPage: z.number(),
});

export type PaginationState = z.infer<typeof PaginationStateSchema>;

export const PaginationInputSchema = z.object({
  page: z.number().default(1),
  perPage: z.number().default(10),
});
export type PaginationInput = z.infer<typeof PaginationInputSchema>;

export const PAGINATION_INFINITISH: PaginationInput = {
  page: 1,
  perPage: 1000,
};
export function paginatedResponse<T>(itemSchema: z.ZodType<T>) {
  return z.object({
    rows: z.array(itemSchema),
    pagination: PaginationStateSchema,
  });
}
