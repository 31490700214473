import { z } from 'zod';

/////////////////////////////////////////
// PATIENT SIGNATURE SCHEMA
/////////////////////////////////////////

export const PatientSignatureSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  patientId: z.string(),
  blob: z.string(),
  representativeName: z.string().nullish(),
})

export type PatientSignature = z.infer<typeof PatientSignatureSchema>

export default PatientSignatureSchema;
