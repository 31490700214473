import type { CodeableConcept, PrismaClient } from "@prisma/client";
import type { CodeableConceptId, CodeableConceptPath } from "@procision-software/mason";
export {
  codeableConceptIdForPath,
  pathForNode,
  codeableConceptById,
  CodeableConceptPathSchema,
} from "@procision-software/mason";
export { type CodeableConceptPath, CodeableConceptId };

export type CodeableConceptCode = string;
type CodeableConceptDefault = [string, string, CodeableConceptCode, CodeableConceptDefault[]?];

export async function upsertDefaultCodesets(prisma: PrismaClient): Promise<void> {
  async function createCC(
    [label, description, code, children]: CodeableConceptDefault,
    parentId: string | null
  ) {
    if (parentId) {
      const r = await prisma.codeableConcept.upsert({
        where: {
          code_codeableConceptId: {
            code,
            codeableConceptId: parentId,
          },
        },
        create: {
          activeFrom: new Date(0),
          activeTo: null,
          codeableConceptId: parentId,
          display: label,
          description,
          key: children !== undefined,
          code,
        },
        update: {
          key: children !== undefined,
          display: label,
          description,
        },
      });
      for (const child of children ?? []) {
        await createCC(child, r.id);
      }
    } else {
      // prisma won't allow you to do an upsert on a composite key when one member of the key is null
      const existing = await prisma.codeableConcept.findFirst({
        where: {
          code,
          codeableConceptId: null,
        },
      });
      let n: CodeableConcept | null = null;
      if (!existing) {
        n = await prisma.codeableConcept.create({
          data: {
            activeFrom: new Date(0),
            activeTo: null,
            display: label,
            code,
            description,
            key: children !== undefined,
          },
        });
      } else {
        n = existing;
      }
      if (n)
        for (const child of children ?? []) {
          await createCC(child, n.id);
        }
    }
  }
  // iterate over the default codesets and create them if they don't exist
  for (const codeset of convertRegistryToDefaults(Registry)) {
    await createCC(codeset, null);
  }
}

export async function allDescendentConcepts(
  prisma: PrismaClient,
  node: null | Pick<CodeableConcept, "id">
): Promise<CodeableConcept[]> {
  const descendents = await prisma.codeableConcept.findMany({
    where: {
      codeableConceptId: node?.id ?? null,
    },
  });
  const descendentPaths = await Promise.all(
    descendents.map((descendent) => allDescendentConcepts(prisma, descendent))
  );
  return descendents.concat(descendentPaths.flat());
}

export function toPath(dottedString: string | { $path: string }): CodeableConceptPath {
  if (typeof dottedString === "object") {
    return dottedString.$path.split(".");
  } else {
    return dottedString.split(".");
  }
}

export function isPath(dottedString: string): boolean {
  return dottedString.includes(".");
}

type CCNode = {
  $label: string;
  $path: string;
  $children?: Record<CodeableConceptCode, CCNode>;
  $description?: string;
};

type CCRoot = Record<CodeableConceptCode, CCNode>;

export const Registry: CCRoot = {
  MED: {
    $path: "MED",
    $label: "Medication",
    $children: {
      REGISTRY: {
        $path: "MED.REGISTRY",
        $label: "Medication registry",
        $children: {
          NDC: {
            $path: "MED.REGISTRY.NDC",
            $label: "National Drug Code",
          },
          RXCUI: {
            $path: "MED.REGISTRY.RXCUI",
            $label: "RxNorm Concept Unique Identifier",
          },
          PROCISIONTT: {
            $path: "MED.REGISTRY.PROCISIONTT",
            $label: "Procision Text Templates",
          },
        },
      },
    },
  },
  CLIN: {
    $path: "CLIN",
    $label: "Clinical Data",
    $children: {
      OR: {
        $path: "CLIN.OR",
        $label: "Orders",
        $children: {
          TYPE: {
            $path: "CLIN.OR.TYPE",
            $label: "Order Type",
            $children: {
              WRITTEN: {
                $path: "CLIN.OR.TYPE.WRITTEN",
                $label: "Written",
              },
              MED: {
                $path: "CLIN.OR.TYPE.MED",
                $label: "Administer Medication",
              },
            },
          },
        },
      },
      EV: {
        $path: "CLIN.EV",
        $label: "Events",
        $children: {
          MAR: {
            $path: "CLIN.EV.MAR",
            $label: "Medication Administration",
          },
          TIMEOUT: {
            $path: "CLIN.EV.TIMEOUT",
            $label: "Time Out",
          },
          LAB: {
            $path: "CLIN.EV.LAB",
            $label: "Laboratory",
            $children: {
              TEMPSET: {
                $path: "CLIN.EV.LAB.TEMPSET",
                $label: "Temperature Set",
                $children: {
                  ORAL: {
                    $path: "CLIN.EV.LAB.TEMPSET.ORAL",
                    $label: "Oral",
                  },
                  RECTAL: {
                    $path: "CLIN.EV.LAB.TEMPSET.RECTAL",
                    $label: "Rectal",
                  },
                  AXIL: {
                    $path: "CLIN.EV.LAB.TEMPSET.AXIL",
                    $label: "Axillary",
                  },
                },
              },
              GLUCOSE: {
                $path: "CLIN.EV.LAB.GLUCOSE",
                $label: "Glucose",
              },
              HCG: {
                $path: "CLIN.EV.LAB.HCG",
                $label: "HCG",
                $description: "Human Chorionic Gonadotropin",
              },
              COCAINE: {
                $path: "CLIN.EV.LAB.COCAINE",
                $label: "Cocaine",
                $description: "Presence of cocaine in the blood",
              },
              OTHERDRUG: {
                $label: "Other Drug",
                $path: "CLIN.EV.LAB.OTHERDRUG",
                $description: "Presence of other drugs in the blood",
              },
              PT: {
                $label: "Prothrombin Time",
                $path: "CLIN.EV.LAB.PT",
                $description: "Measure of time it takes for blood to clot",
              },
              PTT: {
                $label: "Partial Thromboplastin Time",
                $path: "CLIN.EV.LAB.PTT",
                $description: "Measure of time it takes for blood to partially clot",
              },
              INR: {
                $label: "International Normalized Ratio",
                $path: "CLIN.EV.LAB.INR",
                // chat gpt says it's a ratio of prothrombin time to a control sample
              },
            },
          },
          ALDRETE: {
            $path: "CLIN.EV.ALDRETE",
            $label: "Recovery",
            $children: {
              MOTOR: {
                $label: "Moto",
                $path: "CLIN.EV.ALDRETE.MOTOR",
              },
              RESPIRATORY: {
                $label: "Respiratory",
                $path: "CLIN.EV.ALDRETE.RESPIRATORY",
              },
              COLOR: {
                $label: "Color",
                $path: "CLIN.EV.ALDRETE.COLOR",
              },
              CONSCIOUSNESS: {
                $label: "Consciousness",
                $path: "CLIN.EV.ALDRETE.CONSCIOUSNESS",
              },
              BP: {
                $label: "Blood Pressure",
                $path: "CLIN.EV.ALDRETE.BP",
              },
            },
          },
          OBSV: {
            $path: "CLIN.EV.OBSV",
            $label: "Observation",
            $children: {
              HEIGHT: {
                $path: "CLIN.EV.OBSV.HEIGHT",
                $label: "Patient Height",
              },
              WEIGHT: {
                $label: "Patient Weight",
                $path: "CLIN.EV.OBSV.WEIGHT",
              },
              BMI: {
                $label: "Body Mass Index",
                $path: "CLIN.EV.OBSV.BMI",
              },
              SPO2: {
                $path: "CLIN.EV.OBSV.SPO2",
                $label: "Oxygen Saturation",
              },
              SUPP_O2: {
                $label: "Supplemental Oxygen",
                $path: "CLIN.EV.OBSV.SUPP_O2",
              },
              TEMP: {
                $path: "CLIN.EV.OBSV.TEMP",
                $label: "Temperature",
              },
              TEMPMETHOD: {
                $path: "CLIN.EV.OBSV.TEMPMETHOD",
                $label: "Temperature Method of Measurement",
              },
              RESPRATE: {
                $path: "CLIN.EV.OBSV.RESPRATE",
                $label: "Respiration Rate",
              },
              BP: {
                $path: "CLIN.EV.OBSV.BP",
                $label: "Blood Pressure",
                $children: {
                  SYS: {
                    $path: "CLIN.EV.OBSV.BP.SYS",
                    $label: "Systolic",
                  },
                  DIA: {
                    $path: "CLIN.EV.OBSV.BP.DIA",
                    $label: "Diastolic",
                  },
                },
              },
              PULSE: {
                $path: "CLIN.EV.OBSV.PULSE",
                $label: "Pulse Rate",
              },
              PAIN: {
                $path: "CLIN.EV.OBSV.PAIN",
                $label: "Pain Level",
              },
              PAINLOC: {
                $path: "CLIN.EV.OBSV.PAINLOC",
                $label: "Pain Location",
              },
              LOC: {
                $path: "CLIN.EV.OBSV.LOC",
                $label: "Level of Consciousness",
                $children: {
                  UNCONSCIOUS: {
                    $path: "CLIN.EV.OBSV.LOC.UNCONSCIOUS",
                    $label: "Unconscious",
                  },
                  ASLEEP: {
                    $label: "Asleep",
                    $path: "CLIN.EV.OBSV.LOC.ASLEEP",
                  },
                  AWAKE: {
                    $label: "Awake",
                    $path: "CLIN.EV.OBSV.LOC.AWAKE",
                  },
                  CONSCIOUS: {
                    $label: "Conscious",
                    $path: "CLIN.EV.OBSV.LOC.CONSCIOUS",
                  },
                },
              },
              FALLRISK: {
                $path: "CLIN.EV.OBSV.FALLRISK",
                $label: "Fall Risk",
                $children: {
                  HISTORY: {
                    $path: "CLIN.EV.OBSV.FALLRISK.HISTORY",
                    $label: "History of falling",
                    $children: {
                      NO: {
                        $path: "CLIN.EV.OBSV.FALLRISK.HISTORY.NO",
                        $label: "No",
                      },
                      YES: {
                        $path: "CLIN.EV.OBSV.FALLRISK.HISTORY.YES",
                        $label: "Yes",
                      },
                    },
                  },
                  SECONDARYDX: {
                    $path: "CLIN.EV.OBSV.FALLRISK.SECONDARYDX",
                    $label: "Secondary Diagnosis",
                    $children: {
                      NO: {
                        $path: "CLIN.EV.OBSV.FALLRISK.SECONDARYDX.NO",
                        $label: "No",
                      },
                      YES: {
                        $path: "CLIN.EV.OBSV.FALLRISK.SECONDARYDX.YES",
                        $label: "Yes",
                      },
                    },
                  },
                  AMBULATORYAID: {
                    $path: "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID",
                    $label: "Ambulatory Aid",
                    $children: {
                      NONE: {
                        $path: "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID.NO",
                        $label: "None/bedrest/nurse assist",
                      },
                      SOME: {
                        $path: "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID.SOME",
                        $label: "Crutches/cane/walker",
                      },
                      FURNITURE: {
                        $path: "CLIN.EV.OBSV.FALLRISK.AMBULATORYAID.FURNITURE",
                        $label: "Furniture",
                      },
                    },
                  },
                  IVHEPLOCK: {
                    $path: "CLIN.EV.OBSV.FALLRISK.IVHEPLOCK",
                    $label: "IV/Heparin Lock",
                    $children: {
                      NO: {
                        $path: "CLIN.EV.OBSV.FALLRISK.IVHEPLOCK.NO",
                        $label: "No",
                      },
                      YES: {
                        $path: "CLIN.EV.OBSV.FALLRISK.IVHEPLOCK.YES",
                        $label: "Yes",
                      },
                    },
                  },
                  GAIT: {
                    $path: "CLIN.EV.OBSV.FALLRISK.GAIT",
                    $label: "Gait",
                    $children: {
                      MILD: {
                        $path: "CLIN.EV.OBSV.FALLRISK.GAIT.MILD",
                        $label: "Normal/bedrest/wheelchair",
                      },
                      WEAK: {
                        $path: "CLIN.EV.OBSV.FALLRISK.GAIT.WEAK",
                        $label: "Weak",
                      },
                      IMPAIRED: {
                        $path: "CLIN.EV.OBSV.FALLRISK.GAIT.IMPAIRED",
                        $label: "Impaired",
                      },
                    },
                  },
                  MENTALSTATUS: {
                    $path: "CLIN.EV.OBSV.FALLRISK.MENTALSTATUS",
                    $label: "Mental Status",
                    $children: {
                      ORIENTED: {
                        $path: "CLIN.EV.OBSV.FALLRISK.MENTALSTATUS.ORIENTED",
                        $label: "Oriented to own ability",
                      },
                      IMPAIRED: {
                        $path: "CLIN.EV.OBSV.FALLRISK.MENTALSTATUS.IMPAIRED",
                        $label: "Forgets limitations",
                      },
                    },
                  },
                },
              },
              PONVRISK: {
                $label: "Post Operative Nausea and Vomiting",
                $path: "CLIN.EV.OBSV.PONVRISK",
                $children: {
                  SEX: {
                    $path: "CLIN.EV.OBSV.PONVRISK.SEX",
                    $label: "Sex",
                    $children: {
                      MALE: {
                        $path: "CLIN.EV.OBSV.PONVRISK.SEX.MALE",
                        $label: "Male",
                      },
                      FEMALE: {
                        $path: "CLIN.EV.OBSV.PONVRISK.SEX.FEMALE",
                        $label: "Female",
                      },
                    },
                  },
                  SMOKING: {
                    $label: "Smoking status",
                    $path: "CLIN.EV.OBSV.PONVRISK.SMOKING",
                    $children: {
                      CURRENTSMOKER: {
                        $path: "CLIN.EV.OBSV.PONVRISK.SMOKING.CURRENT",
                        $label: "Current Smoker",
                      },
                      NONSMOKER: {
                        $path: "CLIN.EV.OBSV.PONVRISK.SMOKING.NO",
                        $label: "Nonsmoker",
                      },
                    },
                  },
                  HISTORY: {
                    $label: "Medical history",
                    $path: "CLIN.EV.OBSV.PONVRISK.HISTORY",
                    $children: {
                      MOTIONSICKNESS: {
                        $label: "History of PONV or motion sickness",
                        $path: "CLIN.EV.OBSV.PONVRISK.HISTORY.MOTIONSICKNESS",
                      },
                    },
                  },
                  POSTOPTX: {
                    $label: "Postoperative treatment",
                    $path: "CLIN.EV.OBSV.PONVRISK.POSTOPTX",
                    $children: {
                      OPIOIDS: {
                        $label: "Use of post-operative opioids",
                        $path: "CLIN.EV.OBSV.PONVRISK.POSTOPTX.OPIOIDS",
                      },
                    },
                  },
                },
              },
              STOPBANGRISK: {
                $label: "STOP-BANG Risk",
                $path: "CLIN.EV.OBSV.STOPBANGRISK",
                $children: {
                  OBSERVATIONS: {
                    $label: "STOP BANG factors",
                    $path: "CLIN.EV.OBSV.STOPBANGRISK.FACTORS",
                    $children: {
                      SNORING: {
                        $label: "Loud Snoring",
                        $path: "CLIN.EV.OBSV.STOPBANGRISK.FACTORS.SNORING",
                      },
                      TIREDNESS: {
                        $label: "Feeling tired during the day",
                        $path: "CLIN.EV.OBSV.STOPBANGRISK.FACTORS.TIREDNESS",
                      },
                      APNEA: {
                        $label: "Observed stopping breathing during sleep",
                        $path: "CLIN.EV.OBSV.STOPBANGRISK.FACTORS.APNEA",
                      },
                      BP: {
                        $label: "History of high blood pressure",
                        $path: "CLIN.EV.OBSV.STOPBANGRISK.FACTORS.BP",
                      },
                      BMI: {
                        $label: "BMI > 35 kg/m2",
                        $path: "CLIN.EV.OBSV.STOPBANGRISK.FACTORS.BMI",
                      },
                      AGE: {
                        $label: "> 50 years of age",
                        $path: "CLIN.EV.OBSV.STOPBANGRISK.FACTORS.AGE",
                      },
                      NECKCIRCUMFERENCE: {
                        $label: "> 40 cm",
                        $path: "CLIN.EV.OBSV.STOPBANGRISK.FACTORS.NECKCIRCUMFERENCE",
                      },
                      MALE: {
                        $label: "Male Gender",
                        $path: "CLIN.EV.OBSV.STOPBANGRISK.FACTORS.MALE",
                      },
                    },
                  },
                },
              },
              DVTRISK: {
                $path: "CLIN.EV.OBSV.DVTRISK",
                $label: "DVT Risk",
                $children: {
                  AGE: {
                    $label: "Age",
                    $path: "CLIN.EV.OBSV.DVTRISK.AGE",
                    $children: {
                      "HIGH-RISK": {
                        $label: ">= 75 years",
                        $path: "CLIN.EV.OBSV.DVTRISK.AGE.HIGH-RISK",
                      },
                      "MID-RISK": {
                        $label: "61-74 years",
                        $path: "CLIN.EV.OBSV.DVTRISK.AGE.MID-RISK",
                      },
                      "LOW-RISK": {
                        $label: "41-60 years",
                        $path: "CLIN.EV.OBSV.DVTRISK.AGE.LOW-RISK",
                      },
                    },
                  },
                  SURGERY: {
                    $label: "Surgery",
                    $path: "CLIN.EV.OBSV.DVTRISK.SURGERY",
                    $children: {
                      MAJOR: {
                        $label: "Major",
                        $path: "CLIN.EV.OBSV.DVTRISK.SURGERY.MAJOR",
                      },
                      MINOR: {
                        $label: "Minor",
                        $path: "CLIN.EV.OBSV.DVTRISK.SURGERY.MINOR",
                      },
                    },
                  },
                  COMORBIDITIES: {
                    $label: "Comorbidities",
                    $path: "CLIN.EV.OBSV.DVTRISK.COMORBIDITIES",
                    $children: {
                      "HISTORY-OF-DVT": {
                        $label: "History of DVT",
                        $path: "CLIN.EV.OBSV.DVTRISK.COMORBIDITIES.HISTORY-OF-DVT",
                      },
                      "RECENT-MI": {
                        $label: "Recent myocardial infarction",
                        $path: "CLIN.EV.OBSV.DVTRISK.COMORBIDITIES.RECENT-MI",
                      },
                    },
                  },
                  MOBILITY: {
                    $label: "Mobility",
                    $path: "CLIN.EV.OBSV.DVTRISK.MOBILITY",
                    $children: {
                      BEDRIDDEN: {
                        $label: "Bedridden",
                        $path: "CLIN.EV.OBSV.DVTRISK.MOBILITY.BEDRIDDEN",
                      },
                    },
                  },
                  OTHERS: {
                    $label: "Others",
                    $path: "CLIN.EV.OBSV.DVTRISK.OTHERS",
                    $children: {
                      HRT: {
                        $label: "Hormone Replacement Therapy",
                        $path: "CLIN.EV.OBSV.DVTRISK.OTHERS.HRT",
                      },
                      OBESITY: {
                        $label: "Obesity",
                        $path: "CLIN.EV.OBSV.DVTRISK.OTHERS.OBESITY",
                      },
                    },
                  },
                },
              },
            },
          },
          CHARGE: {
            $path: "CLIN.EV.CHARGE",
            $label: "Charges",
            $children: {
              CPT: {
                $path: "CLIN.EV.CHARGE.CPT",
                $label: "CPT",
              },
              HCPCS: {
                $path: "CLIN.EV.CHARGE.HCPCS",
                $label: "HCPCS",
              },
            },
          },
          LINE: {
            $path: "CLIN.EV.LINE",
            $label: "Line",
            $children: {
              TYPE: {
                $path: "CLIN.EV.LINE.TYPE",
                $label: "types",
                $children: {
                  IV: {
                    $path: "CLIN.EV.LINE.TYPE.IV",
                    $label: "IV",
                  },
                },
              },
              START: {
                $path: "CLIN.EV.LINE.START",
                $label: "Line Started",
              },
              DISCONTINUE: {
                $path: "CLIN.EV.LINE.DISCONTINUE",
                $label: "Line Discontinued",
              },
            },
          },
        },
      },
      ENCNTR: {
        $path: "CLIN.EV.ENCNTR",
        $label: "Encounter",
        $children: {
          PRI: {
            $path: "CLIN.EV.ENCNTR.PRI",
            $label: "Priorities",
            $children: {
              ROUT: {
                $path: "CLIN.EV.ENCNTR.PRI.ROUT",
                $label: "Routine",
              },
              URG: {
                $path: "CLIN.EV.ENCNTR.PRI.URG",
                $label: "Urgent",
              },
              EMERG: {
                $path: "CLIN.EV.ENCNTR.PRI.EMERG",
                $label: "Emergency",
              },
            },
          },
        },
      },
      DX: {
        $path: "CLIN.EV.DX",
        $label: "Diagnoses",
        $children: {
          ICD10: {
            $path: "CLIN.EV.DX.ICD10",
            $label: "ICD-10",
            $children: {
              A00_B99: {
                $path: "CLIN.EV.DX.ICD10.A00-B99",
                $label: "A00-B99",
              },
              C00_D49: {
                $path: "CLIN.EV.DX.ICD10.C00-D49",
                $label: "C00-D49",
              },
              D50_D89: {
                $path: "CLIN.EV.DX.ICD10.D50-D89",
                $label: "D50-D89",
              },
            },
          },
        },
      },
      DIM: {
        $path: "CLIN.DIM",
        $label: "Dimensions",
        $children: {
          BULK: {
            $path: "CLIN.DIM.BULK",
            $label: "Bundle",
          },
          SET: {
            $path: "CLIN.DIM.SET",
            $label: "Set",
          },
        },
      },
    },
  },
  WF: {
    $label: "Workflow",
    $path: "WF",
    $children: {
      DOC: {
        $label: "Documents",
        $path: "WF.DOC",
        $children: {
          // 👋 Hi archealogist!
          // WF.DOC.TYPE used to be at WF.DOC.TEMPLATE.TYPE
          // I've left the old template path here, but
          // there's SQL at 20240820000000 to relocate existing records and match this structure
          TEMPLATE: {
            $label: "Template",
            $path: "WF.DOC.TEMPLATE",
            $children: {},
          },
          TYPE: {
            $label: "Type",
            $path: "WF.DOC.TYPE",
            $children: {
              OPREPORT: {
                $label: "Op Report",
                $path: "WF.DOC.TYPE.OPREPORT",
              },
              IMMEDIATEPOSTOPNOTE: {
                $label: "Immediate Post Op Note",
                $path: "WF.DOC.TYPE.IMMEDIATEPOSTOPNOTE",
              },
              CONSENT: {
                $label: "Consent",
                $path: "WF.DOC.TYPE.CONSENT",
              },
              DI: {
                $label: "Discharge Instructions",
                $path: "WF.DOC.TYPE.DI",
              },
              HISTORYANDPHYSICAL: {
                $label: "History and Physical",
                $path: "WF.DOC.TYPE.HISTORYANDPHYSICAL",
              },
            },
          },
        },
      },
    },
  },
  UOM: {
    $path: "UOM",
    $label: "Units of Measure",
    $children: {
      DIAM: {
        $path: "UOM.DIAM",
        $label: "Diameter",
        $children: {
          GA: {
            $path: "UOM.DIAM.GA",
            $label: "Gauge",
          },
        },
      },
      DOSAGE: {
        // Hopefully this is temporary and can be fed with data from rxnorm
        $path: "UOM.DOSAGE",
        $label: "Dosage",
        $children: {
          MG: {
            $path: "UOM.DOSAGE.MG",
            $label: "Milligram",
          },
          GM: {
            $path: "UOM.DOSAGE.GM",
            $label: "Gram",
          },
          PILL: {
            $path: "UOM.DOSAGE.PILL",
            $label: "Pill",
          },
          ML: {
            $path: "UOM.DOSAGE.ML",
            $label: "Milliliter",
          },
          TABLET: {
            $path: "UOM.DOSAGE.TABLET",
            $label: "Tablet",
          },
          CAPSULE: {
            $path: "UOM.DOSAGE.CAPSULE",
            $label: "Capsule",
          },
          CC: {
            $path: "UOM.DOSAGE.CC",
            $label: "Cubic Centimeter",
          },
          UNITS: {
            $path: "UOM.DOSAGE.UNITS",
            $label: "Units",
          },
        },
      },
      NPRS: {
        $path: "UOM.NPRS",
        $label: "Numeric Pain Rating Scale",
      },
      TEMP: {
        $path: "UOM.TEMP",
        $label: "Temperature",
        $children: {
          C: {
            $path: "UOM.TEMP.C",
            $label: "Celsius",
          },
          F: {
            $path: "UOM.TEMP.F",
            $label: "Fahrenheit",
          },
        },
      },
      WT: {
        $path: "UOM.WT",
        $label: "Weight",
        $children: {
          KG: {
            $path: "UOM.WT.KG",
            $label: "Kilogram",
          },

          LB: {
            $path: "UOM.WT.LB",
            $label: "Pound",
          },
        },
      },
      LEN: {
        $path: "UOM.LEN",
        $label: "Length",
        $children: {
          CM: {
            $path: "UOM.LEN.CM",
            $label: "Centimeter",
          },
          IN: {
            $path: "UOM.LEN.IN",
            $label: "Inch",
          },
          FT: {
            $path: "UOM.LEN.FT",
            $label: "Foot",
          },
        },
      },
      VOL: {
        $path: "UOM.VOL",
        $label: "Volume",
        $children: {
          ML: {
            $path: "UOM.VOL.ML",
            $label: "Milliliter",
          },
          L: {
            $path: "UOM.VOL.L",
            $label: "Liter",
          },
          MGDL: {
            // US Blood glucose measure
            $path: "UOM.VOL.MGDL",
            $label: "Milligrams per Deciliter",
          },
        },
      },
      PERCENTAGE: {
        $path: "UOM.PERCENTAGE",
        $label: "Percentage",
      },
      PRESSURE: {
        $path: "UOM.PRESSURE",
        $label: "Pressure",
        $children: {
          MMHG: {
            $path: "UOM.PRESSURE.MMHG",
            $label: "Millimeters of Mercury",
          },
        },
      },
      RATE: {
        $path: "UOM.RATE",
        $label: "Rate",
        $children: {
          BEPM: {
            $path: "UOM.RATE.BEPM",
            $label: "Beats per Minute",
          },
          BRPM: {
            $path: "UOM.RATE.BRPM",
            $label: "Breaths per Minute",
          },
          LPM: {
            $path: "UOM.RATE.LPM",
            $label: "Liters per Minute",
          },
        },
      },
      COND: {
        $path: "UOM.COND",
        $label: "Condition",
        $children: {
          ALDRETE: {
            $path: "UOM.COND.ALDRETE",
            $label: "Aldrete",
          },
          SCORE: {
            $path: "UOM.COND.SCORE",
            $label: "Score",
          },
        },
      },
      TIME: {
        $path: "UOM.TIME",
        $label: "Time",
        $children: {
          SEC: {
            $path: "UOM.TIME.SEC",
            $label: "Second",
          },
          MIN: {
            $path: "UOM.TIME.MIN",
            $label: "Minute",
          },
          HR: {
            $path: "UOM.TIME.HR",
            $label: "Hour",
          },
        },
      },
    },
  },
};

function convertRegistryToDefaults(root: CCRoot): CodeableConceptDefault[] {
  const convertNode = (node: CCNode, code: CodeableConceptCode): CodeableConceptDefault => {
    // Children conversion if they exist
    const childrenDefaults = node.$children
      ? Object.entries(node.$children).map(([childCode, childNode]) =>
          convertNode(childNode, childCode)
        )
      : undefined;

    // Return the tuple format excluding $path
    return [node.$label, node.$description ?? node.$label, code, childrenDefaults];
  };

  // Convert each root entry
  return Object.entries(root).map(([code, node]) => convertNode(node, code));
}
