import { type PrismaClient } from "@prisma/client";
import type { PaginationInput } from "../util/pagination";
import { formatDOS } from "../util/dates";
import type { AppAbility } from "@procision-software/auth";

type SearchResult = {
  title: string; // What the user will see.
  description: string; // A description of the entity.
  id: string; // The ID of the entity.
  model: "Case" | "Staff" | "Transaction"; // The type of entity.
};

export async function searchFreeText(
  prisma: PrismaClient,
  ability: AppAbility,
  {
    filters: { query },
    pagination,
  }: { filters: { query: string; organizationId: string }; pagination: PaginationInput }
): Promise<SearchResult[]> {
  const terms = query.split(" ").filter(Boolean);
  const cases = await prisma.case.findMany({
    where: {
      AND: terms.map((term) => ({
        OR: [
          { patient: { firstName: { contains: term, mode: "insensitive" } } },
          { patient: { lastName: { contains: term, mode: "insensitive" } } },
          { patient: { preferredName: { contains: term, mode: "insensitive" } } },
          { patient: { mrn: { contains: term, mode: "insensitive" } } },
          ...(term === parseInt(term, 10).toString()
            ? [{ financialReference: parseInt(term, 10) }]
            : []),
          { name: { contains: term, mode: "insensitive" } },
        ],
      })),
    },
    select: {
      id: true,
      facilityId: true,
      patient: {
        select: {
          firstName: true,
          preferredName: true,
          lastName: true,
          mrn: true,
        },
      },
      surgeryDate: true,
      financialReference: true,
    },
    take: pagination.perPage,
    orderBy: [{ patient: { updatedAt: "desc" } }, { surgeryDate: "desc" }],
  });
  const staff = await prisma.staff.findMany({
    where: {
      active: true,
      AND: terms.map((term) => ({
        OR: [
          { firstName: { contains: term, mode: "insensitive" } },
          { lastName: { contains: term, mode: "insensitive" } },
        ],
      })),
    },
    select: {
      id: true,
      firstName: true,
      lastName: true,
      qualification: true,
      jobRoles: {
        select: {
          name: true,
        },
      },
    },
  });
  const transactions = ability.can("read", "BillingTransaction")
    ? await prisma.billingTransaction.findMany({
        where: {
          AND: terms.map((term) => ({
            OR: [
              {
                referenceNumber: { contains: term, mode: "insensitive" },
              },
            ],
          })),
        },
        select: {
          id: true,
          referenceNumber: true,
        },
      })
    : [];
  const formatDate = await formatDOS(prisma, cases[0]?.facilityId);
  return [
    ...cases
      .filter((kase) => kase.patient)
      .map((kase) => ({
        title: `${kase.patient?.lastName}, ${kase.patient?.preferredName ?? kase.patient?.firstName}`,
        description: `MRN ${kase.patient?.mrn} on ${formatDate(kase.surgeryDate)}`,
        id: kase.id,
        model: "Case" as const,
      })),
    ...staff.map((staff) => ({
      title: `${staff.firstName} ${staff.lastName}`,
      description: staff.jobRoles.map((role) => role.name).join(", "),
      id: staff.id,
      model: "Staff" as const,
    })),
    ...transactions.map((transaction) => ({
      title: `Transaction ${transaction.referenceNumber}`,
      description: "",
      id: transaction.id,
      model: "Transaction" as const,
    })),
  ];
}

export function convertResourceToSearchResult(
  resource: SearchResult
): { label: string } & (
  | { context: "appointment"; caseId: string }
  | { context: "staff"; staffId: string }
  | { context: "transaction"; transactionId: string }
) {
  switch (resource.model) {
    case "Case":
      return {
        label: `${resource.title}: ${resource.description}`,
        context: "appointment",
        caseId: resource.id,
      };
    case "Staff":
      return {
        label: `${resource.title}: ${resource.description}`,
        context: "staff",
        staffId: resource.id,
      };
    case "Transaction":
      return {
        label: resource.title, // description is empty
        context: "transaction",
        transactionId: resource.id,
      };
  }
}
