import { forwardRef, type HTMLAttributes } from "react";
import { cn } from "../utils";

type SectionVariant = "default" | "card" | undefined | null;

export type SectionProps = {
  variant?: SectionVariant;
} & Omit<HTMLAttributes<HTMLDivElement>, "onSubmit" | "onChange">;
const SectionRoot = forwardRef<HTMLDivElement, SectionProps>(
  ({ variant, className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          "flex flex-col gap-3 empty:hidden",
          variant === "card" && "rounded-lg border border-secondary p-3",
          className
        )}
      >
        {children}
      </div>
    );
  }
);
SectionRoot.displayName = "Section";

export type SectionHeaderProps = {
  right?: boolean;
} & HTMLAttributes<HTMLDivElement>;
const SectionHeader = forwardRef<HTMLDivElement, SectionHeaderProps>(
  ({ right, className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        data-section="header"
        className={cn(
          "flex basis-full flex-row flex-wrap items-center gap-2 empty:hidden",
          right && "ml-auto w-fit",
          className
        )}
      >
        {children}
      </div>
    );
  }
);
SectionHeader.displayName = "Section.Header";

export type SectionTitleProps = HTMLAttributes<HTMLHeadingElement>;
const SectionTitle = forwardRef<HTMLHeadingElement, SectionTitleProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <h2
        ref={ref}
        {...props}
        data-section="title"
        className={cn("flex flex-1 items-baseline empty:hidden", className)}
      >
        {children}
      </h2>
    );
  }
);
SectionTitle.displayName = "Section.Title";

export type SectionContentProps = HTMLAttributes<HTMLDivElement>;
const SectionContent = forwardRef<HTMLDivElement, SectionContentProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        data-section="content"
        className={cn("relative basis-full space-y-3 empty:hidden", className)}
      >
        {children}
      </div>
    );
  }
);
SectionContent.displayName = "Section.Content";

export type SectionFooterProps = {
  right?: boolean;
} & HTMLAttributes<HTMLDivElement>;
const SectionFooter = forwardRef<HTMLDivElement, SectionFooterProps>(
  ({ right, className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        data-section="footer"
        className={cn(
          "flex basis-full flex-row flex-wrap items-center gap-2 empty:hidden",
          right && "ml-auto w-fit",
          className
        )}
      >
        {children}
      </div>
    );
  }
);
SectionFooter.displayName = "Section.Footer";

export const Section = Object.assign(SectionRoot, {
  Header: SectionHeader,
  Title: SectionTitle,
  Content: SectionContent,
  Footer: SectionFooter,
});
