import { z } from 'zod';

/////////////////////////////////////////
// PAYMENT INSURANCE ATTACHMENT TYPE SCHEMA
/////////////////////////////////////////

export const PaymentInsuranceAttachmentTypeSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  name: z.string(),
  label: z.string(),
})

export type PaymentInsuranceAttachmentType = z.infer<typeof PaymentInsuranceAttachmentTypeSchema>

export default PaymentInsuranceAttachmentTypeSchema;
