import { z } from 'zod';
import { AuditOperationSchema } from '../inputTypeSchemas/AuditOperationSchema'
import { EquipmentTypeSchema } from '../inputTypeSchemas/EquipmentTypeSchema'

/////////////////////////////////////////
// EQUIPMENT VERSION SCHEMA
/////////////////////////////////////////

export const EquipmentVersionSchema = z.object({
  versionOperation: AuditOperationSchema,
  type: EquipmentTypeSchema.nullish(),
  versionId: z.number().int(),
  versionSourceId: z.string().nullish(),
  versionUserId: z.string().nullish(),
  versionTimestamp: z.coerce.date(),
  id: z.string().nullish(),
  name: z.string().nullish(),
  active: z.boolean().nullish(),
  inventoryNumber: z.string().nullish(),
  supplierNumber: z.string().nullish(),
  supplierId: z.string().nullish(),
  supplierRepId: z.string().nullish(),
  organizationId: z.string().nullish(),
  createdAt: z.coerce.date().nullish(),
  updatedAt: z.coerce.date().nullish(),
})

export type EquipmentVersion = z.infer<typeof EquipmentVersionSchema>

export default EquipmentVersionSchema;
