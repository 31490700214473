import type { Prisma, PrismaClient as PrismaClientBase } from "@prisma/client";
import type { PaginationInput } from "../../util/pagination";
import type { SignatureFindFilter, SignatureFormData, SignatureRecord } from "./types";

type PrismaClient = Pick<PrismaClientBase, "signature">;

const include = {
  appliedJobRole: true,
  appliedPatientSignature: {
    include: {
      patient: true,
    } as Prisma.PatientSignatureInclude,
  },
  appliedStaffSignature: {
    include: {
      staff: true,
    } as Prisma.StaffSignatureInclude,
  },
  clinicalEvents: true,
  encounterDocuments: true,
  encounterWorkflows: true,
  orders: true,
  encounterClosures: true,
};

function signatureWhereClause({
  id,
  clinicalEvents,
  encounterDocuments,
  encounterWorkflows,
  orders,
  requiredJobRoleType,
  encounterClosures,
}: SignatureFindFilter): Prisma.SignatureWhereInput {
  const where: Prisma.SignatureWhereInput = {};

  if (id) {
    where.id = id;
  }

  if (clinicalEvents) {
    where.clinicalEvents = {
      every: {
        id: {
          in: clinicalEvents,
        },
      },
    };
  }

  if (encounterDocuments) {
    where.encounterDocuments = {
      every: {
        id: {
          in: encounterDocuments,
        },
      },
    };
  }

  if (encounterWorkflows) {
    where.encounterWorkflows = {
      every: {
        id: {
          in: encounterWorkflows,
        },
      },
    };
  }

  if (orders) {
    where.orders = {
      every: {
        id: {
          in: orders,
        },
      },
    };
  }

  if (requiredJobRoleType) {
    where.requiredJobRoleType = requiredJobRoleType;
  }

  if (encounterClosures) {
    where.encounterClosures = {
      every: {
        id: {
          in: encounterClosures,
        },
      },
    };
  }

  return where;
}

export async function signatureCount(
  prisma: PrismaClient,
  filters: SignatureFindFilter
): Promise<number> {
  const where = signatureWhereClause(filters);

  return await prisma.signature.count({
    where,
  });
}

export async function signatureCreate(
  prisma: PrismaClient,
  {
    appliedJobRoleId,
    appliedPatientSignatureId,
    appliedStaffSignatureId,
    clinicalEvents,
    encounterDocuments,
    encounterWorkflows,
    orders,
    requiredJobRoleType,
    encounterClosures,
  }: SignatureFormData
): Promise<SignatureRecord> {
  return await prisma.signature.create({
    data: {
      appliedAt:
        Boolean(appliedJobRoleId) ||
        Boolean(appliedPatientSignatureId) ||
        Boolean(appliedStaffSignatureId)
          ? new Date()
          : undefined,
      appliedJobRole: appliedJobRoleId
        ? {
            connect: {
              id: appliedJobRoleId,
            },
          }
        : undefined,
      appliedPatientSignature: appliedPatientSignatureId
        ? {
            connect: {
              id: appliedPatientSignatureId,
            },
          }
        : undefined,
      appliedStaffSignature: appliedStaffSignatureId
        ? {
            connect: {
              id: appliedStaffSignatureId,
            },
          }
        : undefined,
      clinicalEvents: clinicalEvents
        ? {
            connect: clinicalEvents.map((id) => ({ id })),
          }
        : undefined,
      encounterDocuments: encounterDocuments
        ? {
            connect: encounterDocuments.map((id) => ({ id })),
          }
        : undefined,
      encounterWorkflows: encounterWorkflows
        ? {
            connect: encounterWorkflows.map((id) => ({ id })),
          }
        : undefined,
      orders: orders
        ? {
            connect: orders.map((id) => ({ id })),
          }
        : undefined,
      requiredJobRoleType: requiredJobRoleType,
      encounterClosures: encounterClosures
        ? {
            connect: encounterClosures.map((id) => ({ id })),
          }
        : undefined,
    },
    include,
  });
}

export async function signatureDelete(prisma: PrismaClient, id: string): Promise<SignatureRecord> {
  return await prisma.signature.delete({
    include,
    where: {
      id,
    },
  });
}

export async function signatureFindById(
  prisma: PrismaClient,
  filters: SignatureFindFilter
): Promise<SignatureRecord | null> {
  const where = signatureWhereClause(filters);

  return await prisma.signature.findFirst({
    include,
    where,
  });
}

export async function signatureFindMany(
  prisma: PrismaClient,
  filters: SignatureFindFilter,
  { page, perPage }: PaginationInput
): Promise<SignatureRecord[]> {
  const where = signatureWhereClause(filters);

  return await prisma.signature.findMany({
    include,
    skip: (page - 1) * perPage,
    take: perPage,
    where,
  });
}

export async function signatureUpdate(
  prisma: PrismaClient,
  id: string,
  {
    appliedJobRoleId,
    appliedPatientSignatureId,
    appliedStaffSignatureId,
  }: Partial<SignatureFormData>
): Promise<SignatureRecord> {
  return await prisma.signature.update({
    data: {
      appliedAt:
        Boolean(appliedJobRoleId) ||
        Boolean(appliedPatientSignatureId) ||
        Boolean(appliedStaffSignatureId)
          ? new Date()
          : undefined,
      appliedJobRole: appliedJobRoleId
        ? {
            connect: {
              id: appliedJobRoleId,
            },
          }
        : undefined,
      appliedPatientSignature: appliedPatientSignatureId
        ? {
            connect: {
              id: appliedPatientSignatureId,
            },
          }
        : undefined,
      appliedStaffSignature: appliedStaffSignatureId
        ? {
            connect: {
              id: appliedStaffSignatureId,
            },
          }
        : undefined,
    },
    include,
    where: {
      id,
    },
  });
}
