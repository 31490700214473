import { z } from 'zod';

/////////////////////////////////////////
// PAYER REGION SCHEMA
/////////////////////////////////////////

export const PayerRegionSchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  payerId: z.string(),
})

export type PayerRegion = z.infer<typeof PayerRegionSchema>

export default PayerRegionSchema;
