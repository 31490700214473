import { z } from 'zod';
import { AuditOperationSchema } from '../inputTypeSchemas/AuditOperationSchema'

/////////////////////////////////////////
// PAYMENT WORKERS COMP VERSION SCHEMA
/////////////////////////////////////////

export const PaymentWorkersCompVersionSchema = z.object({
  versionOperation: AuditOperationSchema,
  versionId: z.number().int(),
  versionSourceId: z.string().nullish(),
  versionUserId: z.string().nullish(),
  versionTimestamp: z.coerce.date(),
  id: z.string().nullish(),
  caseId: z.string().nullish(),
  carrier: z.string().nullish(),
  claimNumber: z.string().nullish(),
  address: z.string().nullish(),
  authorizationNumber: z.string().nullish(),
  adjustor: z.string().nullish(),
  adjustorPhone: z.string().nullish(),
  dateOfInjury: z.coerce.date().nullish(),
  notes: z.string().nullish(),
  createdAt: z.coerce.date().nullish(),
  updatedAt: z.coerce.date().nullish(),
  providerId: z.string().nullish(),
})

export type PaymentWorkersCompVersion = z.infer<typeof PaymentWorkersCompVersionSchema>

export default PaymentWorkersCompVersionSchema;
