import { Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { cn } from "@procision-software/ui";
import humanizeString from "humanize-string";
import React, { forwardRef, useMemo, useState, type Ref, useCallback } from "react";
import { mergeRefs } from "react-merge-refs";
import { usePopper } from "react-popper";
import Spinner from "../Spinner";

/**
 * @deprecated Use UI package components instead
 */
export type SelectProps<TKey extends string | number | symbol = string> = {
  blankOption?: boolean;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  id?: string;
  loading?: boolean;
  name?: string;
  onChange?: (id: TKey | null) => void;
  options: { id: TKey; label: string }[] | Record<TKey, string>;
  placeholder?: string;
  value?: TKey | null;
  alphabetize?: boolean;
};

const SelectInner = <TKey extends string | number | symbol>(
  {
    blankOption,
    className,
    disabled,
    readOnly,
    error,
    icon: Icon,
    loading,
    onChange,
    options,
    placeholder,
    value,
    alphabetize = true,
    ...props
  }: SelectProps<TKey>,
  ref?: Ref<HTMLButtonElement>
) => {
  const managedOptions = useMemo(() => {
    const result = Array.isArray(options)
      ? options
      : Object.entries(options).map(([id, label]) => ({
          id,
          label: humanizeString(label as string),
        }));
    return !blankOption ? result : [{ id: null, label: null }, ...result];
  }, [blankOption, options]);

  const selection = useMemo(
    () => managedOptions.find((opt) => !!opt.id && opt.id === value),
    [managedOptions, value]
  );

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
  });
  const sortOptions = useCallback(
    (a: (typeof managedOptions)[number], b: (typeof managedOptions)[number]) => {
      if (a.label === null && b.label === null) return 0;
      if (a.label === null) return 1;
      if (b.label === null) return -1;
      return a.label.localeCompare(b.label);
    },
    []
  );

  return (
    <Listbox
      as="div"
      className="group relative w-full"
      value={value ?? null}
      onChange={onChange}
      disabled={disabled || readOnly}
    >
      <Listbox.Button
        {...props}
        ref={ref ? mergeRefs([ref, setReferenceElement]) : setReferenceElement}
        className={cn(
          `input relative w-full cursor-default pr-8 group-focus-within:ring-1
          group-focus-within:ring-blue-600`,
          disabled && "cursor-not-allowed opacity-50",
          error && "input-error",
          Icon && "pl-8",
          className
        )}
      >
        {Icon && (
          <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
            <Icon className="h-5 w-5" />
          </span>
        )}
        <span className={cn("block truncate", !error && !selection?.label && "text-gray-400")}>
          {selection?.label ? String(selection.label) : (placeholder ?? "-")}
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1.5">
          {loading && <Spinner className="absolute left-0" />}
          <ChevronDownIcon
            className="h-6 w-6 pt-0.5 text-gray-400 dark:text-white"
            aria-hidden="true"
          />
        </span>
      </Listbox.Button>
      <Listbox.Options
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="list-popup relative"
      >
        {managedOptions.length === 0 ? (
          <Listbox.Option
            value={null}
            disabled={true}
            className={cn(
              "relative cursor-not-allowed p-2.5 pr-4 text-sm italic text-gray-500",
              Icon && "pl-8"
            )}
          >
            None available
          </Listbox.Option>
        ) : (
          (alphabetize ? managedOptions.sort(sortOptions) : managedOptions).map((item, i) => (
            <Listbox.Option
              key={`${String(item.id ?? i)}`}
              value={item.id}
              className={({ active, selected }) =>
                cn(
                  "relative p-2.5 pr-4 text-sm text-gray-700",
                  active && "bg-blue-600 text-white",
                  selected && "bg-blue-500 text-white",
                  Icon && "pl-8"
                )
              }
            >
              {item.label ? String(item.label) : "-"}
            </Listbox.Option>
          ))
        )}
      </Listbox.Options>
    </Listbox>
  );
};

/**
 * @deprecated Use UI package components instead
 */
export const Select = forwardRef(SelectInner) as <TKey extends string | number | symbol>(
  props: SelectProps<TKey> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof SelectInner>;
