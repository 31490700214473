import { z } from 'zod';
import { HealthReviewRequirementsStatusSchema } from '../inputTypeSchemas/HealthReviewRequirementsStatusSchema'
import { HealthReviewStatusSchema } from '../inputTypeSchemas/HealthReviewStatusSchema'
import { PreAuthStatusSchema } from '../inputTypeSchemas/PreAuthStatusSchema'

/////////////////////////////////////////
// CASE STATUS TRACKER SCHEMA
/////////////////////////////////////////

export const CaseStatusTrackerSchema = z.object({
  healthReviewRequirementsStatus: HealthReviewRequirementsStatusSchema.nullish(),
  healthReviewStatus: HealthReviewStatusSchema.nullish(),
  insurancePreAuthorizationStatus: PreAuthStatusSchema.nullish(),
  id: z.string(),
  caseId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  hasEquipment: z.boolean(),
  hasHealthReview: z.boolean(),
  hasInsurance: z.boolean(),
  hasPreAdmitQuestionnaire: z.boolean(),
  hasSchedulingConflict: z.boolean(),
  healthReviewMissingApprovals: z.number().int().nullish(),
  healthReviewMissingRequirements: z.number().int().nullish(),
  insuranceVerificationFlag: z.boolean().nullish(),
  overviewClearanceFlag: z.boolean().nullish(),
  overviewEquipmentFlag: z.boolean().nullish(),
  overviewHealthReviewFlag: z.boolean().nullish(),
  overviewHistoryPhysicalFlag: z.boolean().nullish(),
  overviewInsuranceFlag: z.boolean().nullish(),
  overviewPatFlag: z.boolean().nullish(),
  overviewTestingFlag: z.boolean().nullish(),
  isMissingEquipment: z.boolean(),
  isMissingMedical: z.boolean(),
  isMissingMultiple: z.boolean(),
  isMissingPayment: z.boolean(),
})

export type CaseStatusTracker = z.infer<typeof CaseStatusTrackerSchema>

export default CaseStatusTrackerSchema;
