import Cookies from "js-cookie"; // https://www.npmjs.com/package/js-cookie

export function escapeValve() {
  const isServer = typeof window === "undefined";
  if (isServer) return;
  const set = (name: string, value: string) => Cookies.set(name, value);
  const get = (name: string) => Cookies.get(name);
  const remove = (name: string) => Cookies.remove(name);
  // Start by retrieving the existing cookies
  const lastLoadStart = get("lastLoadStart");
  const loadCounter = get("loadCounter");

  // Get the current time
  const currentTime = new Date().getTime();

  if (lastLoadStart && loadCounter) {
    // Cookies exist, check the time difference
    const timeDiff = currentTime - parseInt(lastLoadStart, 10);

    if (timeDiff <= 50000) {
      // If load is within 5 seconds
      if (parseInt(loadCounter, 10) >= 4) {
        // If this is the 4th load within 5 seconds, clear all state
        localStorage.clear(); // https://developer.mozilla.org/en-US/docs/Web/API/Storage/clear
        sessionStorage.clear();

        // Clear all cookies
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });

        window.location.href = "/sign-in"; // send user to sign in page w/ a fresh load
      } else {
        // If less than 4 loads, increment loadCounter
        set("loadCounter", (parseInt(loadCounter, 10) + 1).toString());
      }
    } else {
      // If more than 5 seconds has passed since the last load, reset both cookies
      remove("lastLoadStart");
      remove("loadCounter");
    }
  } else {
    // If the cookies do not exist, this is a first load. Set them.
    set("lastLoadStart", currentTime.toString());
    set("loadCounter", "1");
  }

  // Start a timer to clear the cookies after 5 seconds
  setTimeout(() => {
    remove("lastLoadStart");
    remove("loadCounter");
  }, 5000);
}
