import type {
  StaffSignatureAuthFormData,
  StaffSignatureFindFilter,
  StaffSignatureFormData,
  StaffSignatureUpdateFormData,
} from "@procision-software/mason";
import { useCallback } from "react";
import { client, trpc, type ReactQueryOptions } from "~/utils/trpc";

export function useInvalidateStaffSignature() {
  const utils = trpc.useUtils();

  const invalidate = useCallback(
    async (input?: StaffSignatureFindFilter): Promise<void> => {
      if (input === undefined) {
        await utils.core.staffSignature.get.invalidate();
        await utils.core.staffSignature.list.invalidate();
        return;
      }

      await utils.core.staffSignature.get.invalidate(input);
      await utils.core.staffSignature.list.invalidate({ filters: input });
    },
    [utils]
  );

  return [invalidate] as const;
}

export function useStaffSignatureService() {
  const [invalidateStaffSignature] = useInvalidateStaffSignature();

  async function auth(data: StaffSignatureAuthFormData) {
    return await client.core.staffSignature.auth.query(data);
  }

  const create = useCallback(
    async (data: StaffSignatureFormData) => {
      const value = await client.core.staffSignature.create.mutate(data);

      await invalidateStaffSignature({ id: value.id });
      await invalidateStaffSignature({ staffId: value.staffId });

      return value;
    },
    [invalidateStaffSignature]
  );

  const update = useCallback(
    async (data: StaffSignatureUpdateFormData) => {
      const value = await client.core.staffSignature.update.mutate(data);

      await invalidateStaffSignature({ id: value.id });
      await invalidateStaffSignature({ staffId: value.staffId });

      return value;
    },
    [invalidateStaffSignature]
  );

  return {
    auth,
    create,
    update,
  } as const;
}

export function useStaffSignature(
  input: StaffSignatureFindFilter,
  options?: ReactQueryOptions["core"]["staffSignature"]["get"]
) {
  return trpc.core.staffSignature.get.useQuery(input, options);
}
