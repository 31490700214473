import { z } from 'zod';

/////////////////////////////////////////
// HEALTH REVIEW CONDITION SCHEMA
/////////////////////////////////////////

export const HealthReviewConditionSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  healthReviewTypeId: z.string(),
  organizationId: z.string(),
  isRequired: z.boolean(),
  isRequiredOver50: z.boolean(),
})

export type HealthReviewCondition = z.infer<typeof HealthReviewConditionSchema>

export default HealthReviewConditionSchema;
