import { z } from 'zod';

/////////////////////////////////////////
// ASSET TYPE SCHEMA
/////////////////////////////////////////

export const AssetTypeSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  name: z.string(),
  label: z.string(),
})

export type AssetType = z.infer<typeof AssetTypeSchema>

export default AssetTypeSchema;
