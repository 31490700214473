import { z } from 'zod';

/////////////////////////////////////////
// PATIENT NOTE SCHEMA
/////////////////////////////////////////

export const PatientNoteSchema = z.object({
  id: z.string(),
  encounterId: z.string(),
  authorId: z.string(),
  /**
   * PHI
   */
  notes: z.string(),
  key: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type PatientNote = z.infer<typeof PatientNoteSchema>

export default PatientNoteSchema;
