import { z } from 'zod';
import { AdjustmentReportTypeSchema } from '../inputTypeSchemas/AdjustmentReportTypeSchema'

/////////////////////////////////////////
// BILLING ADJUSTMENT SCHEMA
/////////////////////////////////////////

export const BillingAdjustmentSchema = z.object({
  reportAs: AdjustmentReportTypeSchema,
  id: z.string(),
  code: z.string(),
  name: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type BillingAdjustment = z.infer<typeof BillingAdjustmentSchema>

export default BillingAdjustmentSchema;
