import { cn } from "@procision-software/ui";
import React, { forwardRef } from "react";

type DisplayProps = {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  value?: string | number | readonly string[];
  unbound?: boolean; // used to prevent Field/ControlField from binding props to this component
  children?: React.ReactNode;
};

/**
 * @deprecated Use UI package components instead
 */
export const Display = forwardRef<HTMLSpanElement, DisplayProps>(
  ({ className, error, icon: Icon, value, unbound: _, children, ...props }, ref) => (
    <div className="relative w-fit">
      <span
        {...props}
        ref={ref}
        className={cn(
          "input block w-fit cursor-default border-transparent bg-transparent px-0",
          props.disabled && "cursor-not-allowed opacity-50",
          error && "input-error",
          Icon && "pl-8",
          className
        )}
      >
        {value}
        {children}
      </span>
      {Icon && (
        <span
          className={cn(
            "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2",
            props.disabled && "cursor-not-allowed opacity-50"
          )}
        >
          <Icon className="h-5 w-5" />
        </span>
      )}
    </div>
  )
);
Display.displayName = "Display";
