import { z } from 'zod';
import { OrderResponseStatusSchema } from '../inputTypeSchemas/OrderResponseStatusSchema'

/////////////////////////////////////////
// ORDER RESPONSE SCHEMA
/////////////////////////////////////////

export const OrderResponseSchema = z.object({
  status: OrderResponseStatusSchema,
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  orderId: z.string(),
})

export type OrderResponse = z.infer<typeof OrderResponseSchema>

export default OrderResponseSchema;
