import { type Prisma, type PrismaClient } from "@procision-software/database";
import type { EncounterParticipantFilter, EncounterParticipantRecord } from "./types";

const RELATED_MODELS = {
  staff: true,
  jobRole: true,
  room: true,
} satisfies Prisma.EncounterParticipantInclude;

function encounterParticipantWhereClause(
  prisma: PrismaClient,
  filters: EncounterParticipantFilter
): Prisma.EncounterParticipantWhereInput {
  const where: Prisma.EncounterParticipantWhereInput = {
    encounterId: filters.encounterId,
  };

  if (filters.actors?.length) {
    where.actor = { in: filters.actors };
  }
  return where;
}

export async function encounterParticipantFindMany(
  prisma: PrismaClient,
  filters: EncounterParticipantFilter
): Promise<EncounterParticipantRecord[]> {
  const where = encounterParticipantWhereClause(prisma, filters);
  return await prisma.encounterParticipant.findMany({
    where,
    include: RELATED_MODELS,
  });
}

export async function encounterParticipantById(
  prisma: PrismaClient,
  id: string
): Promise<EncounterParticipantRecord | null> {
  return await prisma.encounterParticipant.findUnique({
    where: { id },
    include: RELATED_MODELS,
  });
}
