import { z } from 'zod';

/////////////////////////////////////////
// PAYMENT WORKERS COMP ATTACHMENT SCHEMA
/////////////////////////////////////////

export const PaymentWorkersCompAttachmentSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  paymentWorkersCompAttachmentTypeId: z.string(),
  paymentWorkersCompId: z.string(),
  storageBlobId: z.string(),
  name: z.string(),
})

export type PaymentWorkersCompAttachment = z.infer<typeof PaymentWorkersCompAttachmentSchema>

export default PaymentWorkersCompAttachmentSchema;
