import { z } from 'zod';
import { JobRoleTypeSchema } from '../inputTypeSchemas/JobRoleTypeSchema'

/////////////////////////////////////////
// JOB ROLE SCHEMA
/////////////////////////////////////////

export const JobRoleSchema = z.object({
  type: JobRoleTypeSchema.nullish(),
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  name: z.string(),
})

export type JobRole = z.infer<typeof JobRoleSchema>

export default JobRoleSchema;
