import { z } from 'zod';

/////////////////////////////////////////
// ATTACHMENT SCHEMA
/////////////////////////////////////////

export const AttachmentSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  attachmentTypeId: z.string(),
  caseId: z.string(),
  storageBlobId: z.string(),
  name: z.string(),
})

export type Attachment = z.infer<typeof AttachmentSchema>

export default AttachmentSchema;
