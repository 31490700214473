import { z } from 'zod';
import { BillingClaimStatusSchema } from '../inputTypeSchemas/BillingClaimStatusSchema'

/////////////////////////////////////////
// BILLING CLAIM SCHEMA
/////////////////////////////////////////

export const BillingClaimSchema = z.object({
  status: BillingClaimStatusSchema,
  id: z.string(),
  billingPayerId: z.string(),
  frequencyCode: z.string(),
  billingCaseId: z.string(),
  referenceNumber: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  lastSubmittedAt: z.coerce.date().nullish(),
  agingThreadId: z.string().nullish(),
  generalThreadId: z.string().nullish(),
})

export type BillingClaim = z.infer<typeof BillingClaimSchema>

export default BillingClaimSchema;
