import { z } from 'zod';
import { JsonValueSchema } from '../inputTypeSchemas/JsonValueSchema'
import { JobRoleTypeSchema } from '../inputTypeSchemas/JobRoleTypeSchema'

/////////////////////////////////////////
// DOCUMENT TEMPLATE SCHEMA
/////////////////////////////////////////

export const DocumentTemplateSchema = z.object({
  jobRoleTypes: JobRoleTypeSchema.array(),
  id: z.string().cuid(),
  organizationId: z.string(),
  title: z.string(),
  typeId: z.string(),
  body: JsonValueSchema.nullable(),
  preview: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullish(),
})

export type DocumentTemplate = z.infer<typeof DocumentTemplateSchema>

export default DocumentTemplateSchema;
