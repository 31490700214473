import { zodResolver } from "@hookform/resolvers/zod";
import type { JobRoleType } from "@procision-software/database-zod";
import {
  StaffSignatureFormDataSchema,
  type StaffSignatureFormData,
} from "@procision-software/mason";
import {
  BoundField,
  Button,
  Field,
  Fieldset,
  Form,
  InputField,
  Section,
} from "@procision-software/ui";
import Signature, { type SignatureRef } from "@uiw/react-signature";
import { useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { StaffSelect } from "~/components/fields/StaffSelect";
import { trpc } from "~/utils/trpc";

type SignatureInputFieldProps = {
  onChange: (value: string) => void;
};

type StaffSignatureCreateFormProps = {
  defaultValues: Partial<StaffSignatureFormData>;
  hideStaffSelect?: boolean;
  jobRoleType?: JobRoleType;
  onCancel: () => unknown;
  onChangeStaff: (id: string | null) => void;
  onSubmit: (data: StaffSignatureFormData) => unknown;
  userId?: string;
};

export function StaffSignatureCreateForm({
  defaultValues,
  jobRoleType,
  hideStaffSelect,
  onCancel,
  onChangeStaff,
  onSubmit,
  userId,
}: StaffSignatureCreateFormProps) {
  const isStaffSelectHidden = hideStaffSelect ?? false;

  const form = useForm<StaffSignatureFormData>({
    resolver: zodResolver(StaffSignatureFormDataSchema),
    defaultValues,
  });

  const staffId = form.watch("staffId");
  const staff = trpc.staff.get.useQuery({ id: staffId }, { enabled: !!staffId });
  const isStaff = !!staffId;
  const isUserStaffMatch = userId && staff.data?.userId === userId;

  const {
    formState: { isSubmitting },
  } = form;

  const onChangeSignature = useCallback(
    (value: string) => {
      form.setValue("blob", value);
    },
    [form]
  );

  const onChangeStaffHandler = useCallback(
    (value: string | null) => {
      if (value) {
        form.setValue("staffId", value);
        onChangeStaff(value);
      }
    },
    [form, onChangeStaff]
  );

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      {!isStaffSelectHidden && (
        <Fieldset>
          <BoundField
            control={form.control}
            name="staffId"
            label="Staff"
            render={({ field }) => (
              <StaffSelect
                {...field}
                blankOption={true}
                context={{ activeOnly: true }}
                roleType={jobRoleType}
                onChange={onChangeStaffHandler}
              />
            )}
          />
        </Fieldset>
      )}
      {isUserStaffMatch ? (
        <>
          <Fieldset>
            <Field label="Signature">
              <SignatureInput onChange={onChangeSignature} />
            </Field>
          </Fieldset>
          <Fieldset>
            <InputField {...form} label="Pin" name="pin" />
          </Fieldset>
          <Fieldset>
            <InputField {...form} label="Pin Confirmation" name="pinConfirmation" />
          </Fieldset>
          <Fieldset variant="actions">
            <Button onClick={onCancel}>Cancel</Button>
            <Button variant="primary" type="submit" loading={isSubmitting}>
              Create Signature
            </Button>
          </Fieldset>
        </>
      ) : isStaff ? (
        <Section>
          <Section.Header>
            <Section.Title>Pin &amp; Signature Required for Staff</Section.Title>
          </Section.Header>
          <Section.Content className="max-w-96">
            The selected staff must first setup their pin &amp; signature in user settings before
            they can sign.
          </Section.Content>
        </Section>
      ) : (
        <></>
      )}
    </Form>
  );
}

function SignatureInput({ onChange }: SignatureInputFieldProps) {
  const $svg = useRef<SignatureRef | null>(null);
  const onPointer = useCallback(() => {
    const svgelm = $svg.current?.svg?.cloneNode(true) as SVGSVGElement;
    const clientWidth = $svg.current?.svg?.clientWidth ?? 4.3 * 410;
    const clientHeight = $svg.current?.svg?.clientHeight ?? 1.4 * 410;

    svgelm.removeAttribute("style");
    svgelm.setAttribute("width", `${clientWidth}px`);
    svgelm.setAttribute("height", `${clientHeight}px`);
    svgelm.setAttribute("viewbox", `${clientWidth} ${clientHeight}`);

    const data = new XMLSerializer().serializeToString(svgelm);

    onChange(`data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(data)))}`);
  }, [onChange]);

  return (
    <Signature
      className="aspect-signature-pad relative box-border cursor-crosshair rounded-lg border
        border-primary bg-primary text-primary focus-within:outline-none focus-within:ring-1
        focus-within:ring-brand focus-within:ring-offset-primary disabled:opacity-70"
      onPointer={onPointer}
      ref={$svg}
    />
  );
}
