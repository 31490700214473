import { z } from 'zod';
import { EmrTemplateWorkflowTypeSchema } from '../inputTypeSchemas/EmrTemplateWorkflowTypeSchema'

/////////////////////////////////////////
// STANDING ORDER SCHEMA
/////////////////////////////////////////

export const StandingOrderSchema = z.object({
  phase: EmrTemplateWorkflowTypeSchema,
  id: z.string(),
  providerId: z.string(),
  emrTemplateId: z.string(),
  orderText: z.string(),
  typeId: z.string(),
  validFrom: z.coerce.date(),
  validUntil: z.coerce.date(),
  orderGroupId: z.string(),
  sequenceNumber: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  appliesByDefault: z.boolean(),
})

export type StandingOrder = z.infer<typeof StandingOrderSchema>

export default StandingOrderSchema;
