import { z } from 'zod';

/////////////////////////////////////////
// CASE PREFERENCE CARD SCHEMA
/////////////////////////////////////////

export const CasePreferenceCardSchema = z.object({
  id: z.string(),
  caseId: z.string(),
  preferenceCardId: z.string(),
  quantity: z.number().int().nullish(),
  version: z.number().int().nullish(),
  positioning: z.string(),
  prep: z.string(),
  drapes: z.string(),
  notes: z.string(),
  gloves: z.string(),
  copiedAt: z.coerce.date(),
})

export type CasePreferenceCard = z.infer<typeof CasePreferenceCardSchema>

export default CasePreferenceCardSchema;
