import { z } from 'zod';
import { EncounterStatusSchema } from '../inputTypeSchemas/EncounterStatusSchema'

/////////////////////////////////////////
// ENCOUNTER SCHEMA
/////////////////////////////////////////

export const EncounterSchema = z.object({
  status: EncounterStatusSchema,
  id: z.string(),
  appointmentId: z.string().nullish(),
  /**
   * the financialReference from the appointment
   */
  identifier: z.string(),
  /**
   * Quantity of time the encounter lasted. This excludes the time during leaves of absence.
   */
  length: z.number().int().nullish(),
  /**
   * time units
   */
  lengthId: z.string().nullish(),
  patientId: z.string(),
  /**
   * The start and end time of the encounter.
   */
  periodStart: z.coerce.date().nullish(),
  periodEnd: z.coerce.date().nullish(),
  /**
   * Indicates the urgency of the encounter.
   */
  priorityId: z.string(),
  serviceProviderId: z.string(),
  open: z.boolean(),
  /**
   * The type of service that is delivered during this encounter.
   */
  emrTemplateId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  matchedSubmissionId: z.string().nullish(),
  /**
   * This is a phantom encounter. It is not a real encounter. If a phantom is not matched within 30 days it should be deleted.
   */
  phantom: z.boolean(),
})

export type Encounter = z.infer<typeof EncounterSchema>

export default EncounterSchema;
