import { z } from 'zod';

/////////////////////////////////////////
// MEDICAL CONDITION SCHEMA
/////////////////////////////////////////

export const MedicalConditionSchema = z.object({
  id: z.string(),
  name: z.string(),
  abbreviation: z.string(),
  color: z.string(),
})

export type MedicalCondition = z.infer<typeof MedicalConditionSchema>

export default MedicalConditionSchema;
