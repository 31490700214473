import { z } from 'zod';

/////////////////////////////////////////
// PAYMENT METHOD TYPE SCHEMA
/////////////////////////////////////////

export const PaymentMethodTypeSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  name: z.string(),
  label: z.string(),
})

export type PaymentMethodType = z.infer<typeof PaymentMethodTypeSchema>

export default PaymentMethodTypeSchema;
