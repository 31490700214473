import { z } from "zod";

/**
 * Specify your server-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars.
 */
const server = z.object({
  S3_AWS_ACCESS_KEY_ID: z.string(),
  S3_AWS_SECRET_ACCESS_KEY: z.string(),
  CLERK_SECRET_KEY: z.string(),
  DATABASE_URL: z.string().url(),
  REDIS_URL: z.string().url(),
  LAUNCH_DARKLY_SDK_SERVER_SIDE_ID: z.string(),
  REDIS_DISABLE_TLS: z.string().optional(),
  BULLMQ_QUEUE_NAME: z.string().default("bullmq"),
  PRISMA_CONNECTION_LIMIT: z.string().optional(),
  NODE_ENV: z.enum(["development", "test", "production"]),
  INFRA_ENV: z.enum(["dev", "test", "demo", "sandbox", "prod"]),
  ONE_SCHEMA_CLIENT_ID: z.string(),
  ONE_SCHEMA_CLIENT_SECRET: z.string(),
  INTERCOM_SECRET_KEY: z.string(),
});

/**
 * Specify your client-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars. To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const client = z.object({
  NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string(),
  NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID: z.string(),
  NEXT_PUBLIC_DD_RUM_APPLICATION_ID: z.string(),
  NEXT_PUBLIC_DD_RUM_CLIENT_ID: z.string(),
  NEXT_PUBLIC_INFRA_ENV: z.string(),
  NEXT_PUBLIC_INTERCOM_APP_ID: z.string(),
});

/**
 * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
 * middlewares) or client-side so we need to destruct manually.
 *
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
  S3_AWS_ACCESS_KEY_ID: process.env.S3_AWS_ACCESS_KEY_ID,
  S3_AWS_SECRET_ACCESS_KEY: process.env.S3_AWS_SECRET_ACCESS_KEY,
  CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
  DATABASE_URL:
    process.env.DATABASE_URL +
    (process.env.PRISMA_CONNECTION_LIMIT
      ? "?connection_limit=" + process.env.PRISMA_CONNECTION_LIMIT
      : ""),
  NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
  NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID:
    process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
  NODE_ENV: process.env.NODE_ENV,
  PRISMA_CONNECTION_LIMIT: process.env.PRISMA_CONNECTION_LIMIT,
  INFRA_ENV: process.env.INFRA_ENV,
  LAUNCH_DARKLY_SDK_SERVER_SIDE_ID: process.env.LAUNCH_DARKLY_SDK_SERVER_SIDE_ID,
  NEXT_PUBLIC_DD_RUM_APPLICATION_ID: process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID,
  NEXT_PUBLIC_DD_RUM_CLIENT_ID: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_ID,
  NEXT_PUBLIC_INFRA_ENV: process.env.NEXT_PUBLIC_INFRA_ENV,
  ONE_SCHEMA_CLIENT_ID: process.env.ONE_SCHEMA_CLIENT_ID,
  ONE_SCHEMA_CLIENT_SECRET: process.env.ONE_SCHEMA_CLIENT_SECRET,
  REDIS_URL: process.env.REDIS_URL,
  REDIS_DISABLE_TLS: process.env.REDIS_DISABLE_TLS,
  BULLMQ_QUEUE_NAME: process.env.BULLMQ_QUEUE_NAME,
  INTERCOM_SECRET_KEY: process.env.INTERCOM_SECRET_KEY,
  NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
};

// Don't touch the part below
// --------------------------

const merged = server.merge(client);

/** @typedef {z.input<typeof merged>} MergedInput */
/** @typedef {z.infer<typeof merged>} MergedOutput */
/** @typedef {z.SafeParseReturnType<MergedInput, MergedOutput>} MergedSafeParseReturn */

let env = /** @type {MergedOutput} */ (process.env);

if (!!process.env.SKIP_ENV_VALIDATION == false) {
  const isServer = typeof window === "undefined";

  const parsed = /** @type {MergedSafeParseReturn} */ (
    isServer
      ? merged.safeParse(processEnv) // on server we can validate all env vars
      : client.safeParse(processEnv) // on client we can only validate the ones that are exposed
  );

  if (parsed.success === false) {
    console.error("❌ Invalid environment variables:", parsed.error.flatten().fieldErrors);
    throw new Error("Invalid environment variables");
  }

  env = new Proxy(parsed.data, {
    get(target, prop) {
      if (typeof prop !== "string") return undefined;
      // Throw a descriptive error if a server-side env var is accessed on the client
      // Otherwise it would just be returning `undefined` and be annoying to debug
      if (!isServer && !prop.startsWith("NEXT_PUBLIC_"))
        throw new Error(
          process.env.NODE_ENV === "production"
            ? "❌ Attempted to access a server-side environment variable on the client"
            : `❌ Attempted to access server-side environment variable '${prop}' on the client`
        );
      return target[/** @type {keyof typeof target} */ (prop)];
    },
  });
}

export { env };
