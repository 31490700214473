import { cva } from "class-variance-authority";
import { type ClassValue } from "clsx";
import { type LucideIcon, type LucideProps } from "lucide-react";
import { cn } from "../../utils";
import { stateVariant, type StateVariant } from "../../variants";
import { Logo } from "./Logo";

export type IconSize = "badge" | "table" | "sm" | "md" | "xl" | "2xl";

export const iconVariants = cva("", {
  variants: {
    size: {
      badge: "h-4 w-4",
      table: "h-4 w-4",
      sm: "h-4 w-4",
      md: "h-4 w-4 sm:h-4 sm:w-4 md:h-5 md:w-5",
      xl: "h-10 w-10 sm:h-6 sm:w-6 md:h-10 md:w-10",
      "2xl": "h-14 w-14 sm:h-10 sm:w-10 md:h-12 md:w-12",
    } satisfies Record<IconSize, string>,
  },
  defaultVariants: {
    size: "md",
  },
});

export type IconVariantProps = {
  size?: IconSize | null;
  state?: StateVariant;
};

export type IconProps = Omit<LucideProps, "size"> &
  IconVariantProps & {
    icon?: LucideIcon | null;
    loading?: boolean;
    className?: ClassValue;
  };

export const Icon = ({
  size,
  icon: InternalIcon,
  state,
  loading,
  className,
  ...props
}: IconProps) => {
  if (loading) {
    return (
      <Logo className={cn(iconVariants({ size }), "animate-spin-slow opacity-50", className)} />
    );
  }

  if (!InternalIcon) return null;

  return (
    <InternalIcon
      {...props}
      className={cn(iconVariants({ size }), stateVariant(state, ["text"]), className)}
    />
  );
};
