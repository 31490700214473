import { z } from 'zod';
import { LateralitySchema } from '../inputTypeSchemas/LateralitySchema'

/////////////////////////////////////////
// SURGERY PROFILE CHARGE SCHEMA
/////////////////////////////////////////

export const SurgeryProfileChargeSchema = z.object({
  laterality: LateralitySchema.nullish(),
  id: z.string(),
  surgeryProfileId: z.string(),
  cptCode: z.string().nullish(),
  hcpcsCode: z.string().nullish(),
  sequenceNumber: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type SurgeryProfileCharge = z.infer<typeof SurgeryProfileChargeSchema>

export default SurgeryProfileChargeSchema;
