import { z } from 'zod';
import { DaysOfWeekSchema } from '../inputTypeSchemas/DaysOfWeekSchema'

/////////////////////////////////////////
// FACILITY HOURS SCHEMA
/////////////////////////////////////////

export const FacilityHoursSchema = z.object({
  day: DaysOfWeekSchema,
  id: z.string(),
  facilityId: z.string(),
  open: z.boolean(),
  openTime: z.string().nullish(),
  closeTime: z.string().nullish(),
})

export type FacilityHours = z.infer<typeof FacilityHoursSchema>

export default FacilityHoursSchema;
