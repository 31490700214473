import { type PrismaClient } from "@procision-software/database";
import {
  OrderGroupSchema,
  StandingMedicationOrderSchema,
  StandingOrderSchema,
} from "@procision-software/database-zod";
import { z } from "zod";
import {
  CodeableConceptPathAsArray,
  codeableConceptIdForPath,
  codeableConceptPathForId,
} from "../codeable-concept";
import { endOfTimes } from "../../util/dates";
import { index } from "../../util/indexing";

export const PortableStandingOrder = StandingOrderSchema.pick({
  appliesByDefault: true,
  orderText: true,
  phase: true,
}).extend({
  orderGroup: OrderGroupSchema.pick({
    name: true,
  }),
  type: CodeableConceptPathAsArray,
  medications: z.array(
    StandingMedicationOrderSchema.pick({
      doseageNumber: true,
      frequency: true,
      medId: true,
      route: true,
      site: true,
    }).extend({
      registrar: CodeableConceptPathAsArray,
      uom: CodeableConceptPathAsArray,
    })
  ),
});

type PortableStandingOrder = z.infer<typeof PortableStandingOrder>;

export async function exportStandingOrders(
  prisma: PrismaClient,
  providerId: string,
  templateId: string,
  archived: boolean
): Promise<PortableStandingOrder[]> {
  const existing = await prisma.standingOrder.findMany({
    where: {
      providerId,
      emrTemplateId: templateId,
      validUntil: archived ? undefined : { gt: new Date() },
    },
    include: {
      orderGroup: {
        select: {
          name: true,
        },
      },
      medications: true,
    },
    orderBy: [{ phase: "asc" }, { sequenceNumber: "asc" }],
  });
  const out: PortableStandingOrder[] = [];
  for (const { id: _id, typeId, medications, ...standingOrder } of existing) {
    out.push({
      ...standingOrder,
      type: await codeableConceptPathForId(prisma, typeId),
      medications: await Promise.all(
        medications.map(async ({ id: _id, registrarId, doseageUomId, ...medication }) => ({
          ...medication,
          registrar: await codeableConceptPathForId(prisma, registrarId),
          uom: doseageUomId ? await codeableConceptPathForId(prisma, doseageUomId) : [],
        }))
      ),
    });
  }
  return out;
}

/**
 * Import a standing order JSON blob into the database for a new provider
 * @param prisma
 * @param standingOrders PortableStandingOrder[] output from exportStandingOrders
 * @param context: providerId, organizationId and emrTemplateId
 * @returns
 */
export async function importStandingOrders(
  prisma: PrismaClient,
  standingOrders: PortableStandingOrder[],
  {
    providerId,
    organizationId,
    emrTemplateId,
  }: { providerId: string; organizationId: string; emrTemplateId: string }
) {
  // confirm all order groups exist
  const orderGroups = index(
    await prisma.orderGroup.findMany({
      where: {
        organizationId,
      },
    }),
    { by: "name" }
  );
  const missingOrderGroups = standingOrders
    .filter(({ orderGroup: { name } }) => !(name in orderGroups))
    .map(({ orderGroup: { name } }) => name);
  if (!standingOrders.every(({ orderGroup: { name } }) => name in orderGroups)) {
    throw new Error(`Some order groups not found: ${missingOrderGroups.join(", ")}`);
  }
  // confirm the given provider is accessible to me
  const provider = await prisma.staff.findUnique({
    where: {
      id: providerId,
    },
  });

  // confirm the emr template is accessible to me
  const emrTemplate = await prisma.emrTemplate.findUnique({
    where: {
      id: emrTemplateId,
    },
  });

  if (!provider || !emrTemplate) {
    throw new Error(`Provider or emr template not found`);
  }

  let counter = 0;
  const out: string[] = [];
  for (const {
    type,
    orderGroup: { name: orderGroupName },
    medications,
    ...standingOrder
  } of standingOrders) {
    const typeId = await codeableConceptIdForPath(prisma, type);
    const orderGroup = orderGroups[orderGroupName];
    if (!orderGroup) {
      throw new Error(`Order group not found: ${orderGroupName}`);
    }
    const so = await prisma.standingOrder.create({
      data: {
        ...standingOrder,
        type: {
          connect: {
            id: typeId,
          },
        },
        orderGroup: {
          connect: {
            id: orderGroup.id,
          },
        },
        validFrom: new Date(),
        validUntil: endOfTimes(),
        provider: {
          connect: {
            id: providerId,
          },
        },
        emrTemplate: {
          connect: {
            id: emrTemplateId,
          },
        },
        sequenceNumber: counter++,
      },
    });
    for (const { registrar, uom, ...m } of medications) {
      const registrarId = await codeableConceptIdForPath(prisma, registrar);
      const doseageUomId = uom.length ? await codeableConceptIdForPath(prisma, uom) : null;
      await prisma.standingMedicationOrder.create({
        data: {
          ...m,
          registrar: {
            connect: {
              id: registrarId,
            },
          },
          ...(doseageUomId && {
            doseageUom: {
              connect: {
                id: doseageUomId,
              },
            },
          }),
          standingOrder: {
            connect: {
              id: so.id,
            },
          },
        },
      });
    }
    out.push(so.id);
  }
  return out;
}
