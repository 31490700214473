import { z } from 'zod';

/////////////////////////////////////////
// MESSAGE THREAD SCHEMA
/////////////////////////////////////////

export const MessageThreadSchema = z.object({
  id: z.string(),
  facilityId: z.string().nullish(),
  practiceId: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type MessageThread = z.infer<typeof MessageThreadSchema>

export default MessageThreadSchema;
