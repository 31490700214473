import { z } from 'zod';

/////////////////////////////////////////
// CONTRACT MODIFIER WEIGHT SCHEMA
/////////////////////////////////////////

export const ContractModifierWeightSchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  contractId: z.string(),
  modifierId: z.string(),
  multiplier: z.number(),
})

export type ContractModifierWeight = z.infer<typeof ContractModifierWeightSchema>

export default ContractModifierWeightSchema;
