import { z } from 'zod';

/////////////////////////////////////////
// CASE ROLE SCHEMA
/////////////////////////////////////////

/**
 * Who is involved in a case?
 */
export const CaseRoleSchema = z.object({
  id: z.string(),
  caseId: z.string(),
  staffId: z.string(),
  jobRoleId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  roleId: z.string().nullish(),
})

export type CaseRole = z.infer<typeof CaseRoleSchema>

export default CaseRoleSchema;
