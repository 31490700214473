import { z } from 'zod';
import { JsonValueSchema } from '../inputTypeSchemas/JsonValueSchema'

/////////////////////////////////////////
// ENCOUNTER DOCUMENT SCHEMA
/////////////////////////////////////////

export const EncounterDocumentSchema = z.object({
  id: z.string().cuid(),
  documentTemplateId: z.string().nullish(),
  encounterId: z.string(),
  title: z.string(),
  body: JsonValueSchema.nullable(),
  preview: z.string(),
  typeId: z.string(),
})

export type EncounterDocument = z.infer<typeof EncounterDocumentSchema>

export default EncounterDocumentSchema;
