import type {
  PatientSignatureRecord,
  SignatureFindFilter,
  SignatureRecord,
  StaffSignatureRecord,
} from "@procision-software/mason";
import { Dialog, createFastContext, useAppContext } from "@procision-software/ui";
import { useCallback, useMemo, type PropsWithChildren } from "react";
import { PatientSignatureForm } from "../patient-signature/PatientSignatureForm";
import { StaffSignatureForm } from "../staff-signature/StaffSignatureForm";
import { useSignatureService } from "./useSignature";

type OnAuthPatientSignature =
  | ((value: PatientSignatureRecord) => Promise<SignatureFindFilter>)
  | ((value: PatientSignatureRecord) => SignatureFindFilter);

type OnAuthStaffSignature =
  | ((value: StaffSignatureRecord) => Promise<SignatureFindFilter>)
  | ((value: StaffSignatureRecord) => SignatureFindFilter);

type OnAuth = OnAuthPatientSignature | OnAuthStaffSignature;

type OnAuthReject = (reason?: unknown) => void;

type OnAuthResolveValue =
  | { success: true; data: SignatureRecord }
  | { success: false; error: string };

type OnAuthResolve =
  | ((value: OnAuthResolveValue) => void)
  | ((value: OnAuthResolveValue) => Promise<void>);

type BasicSignatureRecord = Pick<SignatureRecord, "id" | "requiredJobRoleType">;

type SignatureContext = {
  isPatient: boolean;
  isStaff: boolean;
  onAuth?: OnAuth;
  onAuthReject?: OnAuthReject;
  onAuthResolve?: OnAuthResolve;
  patientId?: string;
  signature?: BasicSignatureRecord;
};

const ERROR_DIALOG_CLOSED = "Dialog closed.";
const ERROR_SIGNATURE_NOT_FOUND = "Signature not found.";

const { Provider, useStore } = createFastContext<SignatureContext>({
  isPatient: false,
  isStaff: false,
});

export function SignatureProvider({ children }: PropsWithChildren) {
  return (
    <Provider>
      {children}
      <SignatureDialog />
    </Provider>
  );
}

/**
 * This is being used to track if the onAuthResolve has already been called.
 */
const ON_AUTH_RESOLVER_WEAK_MAP = new WeakMap<OnAuthResolve, boolean>();

export function SignatureDialog() {
  const [
    { isPatient, isStaff, onAuth, onAuthReject, onAuthResolve, patientId, signature },
    setStore,
  ] = useStore((store) => store);
  const { staff, user } = useAppContext();
  const signatureService = useSignatureService();
  const staffId = staff?.id;
  const userId = user?.id;

  if (onAuthResolve !== undefined) {
    ON_AUTH_RESOLVER_WEAK_MAP.set(onAuthResolve, false);
  }

  const tryOnAuthResolve = useCallback(
    async (value: OnAuthResolveValue) => {
      if (onAuthResolve === undefined) {
        return;
      }

      if (ON_AUTH_RESOLVER_WEAK_MAP.get(onAuthResolve) === false) {
        await onAuthResolve(value);
        ON_AUTH_RESOLVER_WEAK_MAP.set(onAuthResolve, true);
      }
    },
    [onAuthResolve]
  );

  const onClose = useCallback(() => {
    void tryOnAuthResolve({ success: false, error: ERROR_DIALOG_CLOSED });
    setStore({
      isPatient: false,
      isStaff: false,
      onAuth: undefined,
      onAuthReject: undefined,
      onAuthResolve: undefined,
      patientId: undefined,
      signature: undefined,
    });
  }, [setStore, tryOnAuthResolve]);

  const onPatientSignatureSubmit = useCallback(
    async (data: PatientSignatureRecord) => {
      try {
        const signature = await (onAuth as OnAuthPatientSignature | undefined)?.(data);

        if (signature === undefined) {
          return tryOnAuthResolve({ success: false, error: ERROR_SIGNATURE_NOT_FOUND });
        }

        await tryOnAuthResolve({
          success: true,
          data:
            signature.id === undefined
              ? await signatureService.create({ ...signature, appliedPatientSignatureId: data.id })
              : await signatureService.update({ ...signature, appliedPatientSignatureId: data.id }),
        });
      } catch (error) {
        onAuthReject?.(error);
      }
    },
    [onAuth, onAuthReject, tryOnAuthResolve, signatureService]
  );

  const onStaffSignatureSubmit = useCallback(
    async (data: StaffSignatureRecord) => {
      try {
        const signature = await (onAuth as OnAuthStaffSignature | undefined)?.(data);

        if (signature === undefined) {
          return tryOnAuthResolve({ success: false, error: ERROR_SIGNATURE_NOT_FOUND });
        }

        await tryOnAuthResolve({
          success: true,
          data:
            signature.id === undefined
              ? await signatureService.create({ ...signature, appliedStaffSignatureId: data.id })
              : await signatureService.update({ ...signature, appliedStaffSignatureId: data.id }),
        });
      } catch (error) {
        onAuthReject?.(error);
      }
    },
    [onAuth, onAuthReject, tryOnAuthResolve, signatureService]
  );

  return (
    <Dialog show={isPatient || isStaff} title="Apply Signature" onClose={onClose}>
      {({ close }) => (
        <>
          {isPatient && patientId && (
            <PatientSignatureForm
              onSubmit={async (data) => {
                await onPatientSignatureSubmit(data);
                close();
              }}
              onCancel={() => {
                void tryOnAuthResolve({ success: false, error: ERROR_DIALOG_CLOSED });
                close();
              }}
              patientId={patientId}
            />
          )}
          {isStaff && (
            <StaffSignatureForm
              jobRoleType={signature?.requiredJobRoleType ?? undefined}
              onSubmit={async (data) => {
                await onStaffSignatureSubmit(data);
                close();
              }}
              onCancel={() => {
                void tryOnAuthResolve({ success: false, error: ERROR_DIALOG_CLOSED });
                close();
              }}
              staffId={staffId}
              userId={userId}
            />
          )}
        </>
      )}
    </Dialog>
  );
}

export function useSignatureProvider() {
  const [_, setStore] = useStore();

  return useMemo(
    () => ({
      openPatientSignature: (
        patientId: string,
        onAuth: OnAuthPatientSignature
      ): Promise<OnAuthResolveValue> =>
        new Promise((onAuthResolve, onAuthReject) =>
          setStore({ isPatient: true, onAuth, onAuthReject, onAuthResolve, patientId })
        ),
      openStaffSignature: (onAuth: OnAuthStaffSignature): Promise<OnAuthResolveValue> =>
        new Promise((onAuthResolve, onAuthReject) =>
          setStore({ isStaff: true, onAuth, onAuthReject, onAuthResolve })
        ),
      signPatientSignature: (
        patientId: string,
        signature: BasicSignatureRecord
      ): Promise<OnAuthResolveValue> =>
        new Promise((onAuthResolve, onAuthReject) =>
          setStore({
            isPatient: true,
            onAuth: (() => Promise.resolve(signature)) as OnAuthPatientSignature,
            onAuthReject,
            onAuthResolve,
            patientId,
            signature,
          })
        ),
      signStaffSignature: (signature: BasicSignatureRecord): Promise<OnAuthResolveValue> =>
        new Promise((onAuthResolve, onAuthReject) =>
          setStore({
            isStaff: true,
            onAuth: (() => Promise.resolve(signature)) as OnAuthStaffSignature,
            onAuthReject,
            onAuthResolve,
            signature,
          })
        ),
    }),
    [setStore]
  );
}
