import { z } from 'zod';
import { QuestionValueTypeSchema } from '../inputTypeSchemas/QuestionValueTypeSchema'

/////////////////////////////////////////
// QUESTIONNAIRE RESPONSE ANSWER SCHEMA
/////////////////////////////////////////

export const QuestionnaireResponseAnswerSchema = z.object({
  valueType: QuestionValueTypeSchema,
  id: z.string(),
  questionnaireResponseId: z.string(),
  questionnaireQuestionId: z.string(),
  groupRow: z.number().int(),
  valueOrdinal: z.number().int(),
  floatValue: z.number().nullish(),
  /**
   * PHI
   */
  stringValue: z.string().nullish(),
  booleanValue: z.boolean().nullish(),
  /**
   * PHI
   */
  dateValue: z.coerce.date().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type QuestionnaireResponseAnswer = z.infer<typeof QuestionnaireResponseAnswerSchema>

export default QuestionnaireResponseAnswerSchema;
