import { z } from 'zod';
import { AuditOperationSchema } from '../inputTypeSchemas/AuditOperationSchema'

/////////////////////////////////////////
// PAYMENT SELF PAY VERSION SCHEMA
/////////////////////////////////////////

export const PaymentSelfPayVersionSchema = z.object({
  versionOperation: AuditOperationSchema,
  versionId: z.number().int(),
  versionSourceId: z.string().nullish(),
  versionUserId: z.string().nullish(),
  versionTimestamp: z.coerce.date(),
  id: z.string().nullish(),
  createdAt: z.coerce.date().nullish(),
  updatedAt: z.coerce.date().nullish(),
  caseId: z.string().nullish(),
  paymentMethodTypeId: z.string().nullish(),
  practiceCollectsAllPayment: z.boolean().nullish(),
  practiceCollectsProviderPayment: z.boolean().nullish(),
  notes: z.string().nullish(),
})

export type PaymentSelfPayVersion = z.infer<typeof PaymentSelfPayVersionSchema>

export default PaymentSelfPayVersionSchema;
