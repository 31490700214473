import { index } from "../../util/indexing";
import { type ExpandedQuestionnaireQuestion, type QConditionComparatorType } from "./types";

export const qComparatorHandlers: Record<
  QConditionComparatorType,
  (referenceValue: unknown, targetValue: unknown) => boolean
> = {
  equals(referenceValue: unknown, targetValue: unknown): boolean {
    if (referenceValue && typeof referenceValue === "string" && typeof targetValue === "string") {
      // for better UX, allow for case-insensitive checking if string
      return referenceValue.toLowerCase() === targetValue.toLowerCase();
    }
    // lazy compare allowed on purpose. No need for specific i.e "==="
    return referenceValue == targetValue;
  },
  notEquals(referenceValue: unknown, targetValue: unknown): boolean {
    if (referenceValue && typeof referenceValue === "string" && typeof targetValue === "string") {
      // for better UX, allow for case-insensitive checking if string
      return referenceValue.toLowerCase() != targetValue.toLowerCase();
    }
    return referenceValue != targetValue;
  },
  exists(referenceValue: unknown): boolean {
    return !!referenceValue;
  },
  notExists(referenceValue: unknown): boolean {
    return !referenceValue;
  },
  greaterThan(referenceValue: unknown, targetValue: unknown): boolean {
    // @ts-expect-error any possible comparator errors due to data type are unlikely and if so, are caught up stack
    return referenceValue > targetValue;
  },
  greaterThanEqual(referenceValue: unknown, targetValue: unknown): boolean {
    // @ts-expect-error any possible comparator errors due to data type are unlikely and if so, are caught up stack
    return referenceValue >= targetValue;
  },
  lessThan(referenceValue: unknown, targetValue: unknown): boolean {
    // @ts-expect-error any possible comparator errors due to data type are unlikely and if so, are caught up stack
    return referenceValue < targetValue;
  },
  lessThanEqual(referenceValue: unknown, targetValue: unknown): boolean {
    // @ts-expect-error any possible comparator errors due to data type are unlikely and if so, are caught up stack
    return referenceValue <= targetValue;
  },
};

export function deriveErrorKey(questionnaireId: string, questionId: string, groupRow?: number) {
  return `${questionnaireId}.${questionId}.${groupRow ?? 0}`;
}

export function createQuestionTree(
  questions: ExpandedQuestionnaireQuestion[]
): ExpandedQuestionnaireQuestion[] {
  const res: ExpandedQuestionnaireQuestion[] = [];
  const questionMap = index(questions, { by: "id" });

  // tree
  questions.forEach((it) => {
    if (it.parentQuestionId) {
      if (!questionMap[it.parentQuestionId]) {
        throw new Error(
          `createQuestionTree: ${it.id}: Parent (${it.parentQuestionId}) was not found`
        );
      }

      questionMap[it.parentQuestionId]!.children = questionMap[it.parentQuestionId]?.children ?? [];

      // ensure uniqueness prior to pushing
      if (!questionMap[it.parentQuestionId]!.children?.includes(it)) {
        questionMap[it.parentQuestionId]!.children?.push(it);
      }
    } else {
      res.push(it);
    }
  });

  return res;
}
