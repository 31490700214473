import { z } from 'zod';

/////////////////////////////////////////
// CASE PREFERENCE CARD EQUIPMENT SCHEMA
/////////////////////////////////////////

export const CasePreferenceCardEquipmentSchema = z.object({
  id: z.string(),
  casePreferenceCardId: z.string(),
  preferenceCardEquipmentId: z.string(),
  used: z.number().int(),
  open: z.number().int(),
  hold: z.number().int(),
  comments: z.string(),
  equipmentId: z.string(),
  equipmentVersion: z.number().int(),
})

export type CasePreferenceCardEquipment = z.infer<typeof CasePreferenceCardEquipmentSchema>

export default CasePreferenceCardEquipmentSchema;
