import { forwardRef, useEffect, useState, type ComponentProps } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Input, type InputProps } from "./Input";

type InputValue = ComponentProps<typeof Input>["value"];

type InputBufferedProps<TValue extends InputValue> = Omit<InputProps, "value" | "onChange"> & {
  value?: TValue;
  onChange?: (value: TValue) => void;
};

const InputBufferedInner = <TValue extends InputValue>(
  { value, onChange, ...props }: InputBufferedProps<TValue>,
  ref?: React.ForwardedRef<HTMLInputElement>
) => {
  const [internalValue, setInternalValue] = useState<TValue>(value as TValue);
  useEffect(() => setInternalValue(value as TValue), [value]);

  const update = useDebouncedCallback((data: TValue) => {
    if (data !== value) onChange?.(data);
  }, 3000);

  useEffect(() => {
    update(internalValue);
  }, [update, internalValue]);

  useEffect(() => {
    return () => update.flush();
  }, [update]);

  return (
    <Input
      {...props}
      ref={ref}
      value={internalValue}
      onChange={(e) => setInternalValue(e.target.value as TValue)}
      onBlur={update.flush}
    />
  );
};

/**
 * @deprecated Use UI package components instead
 */
export const InputBuffered = forwardRef(InputBufferedInner) as <TValue extends InputValue>(
  props: InputBufferedProps<TValue> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof InputBufferedInner>;
