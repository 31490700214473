import { z } from 'zod';
import { BillingPayerTypeSchema } from '../inputTypeSchemas/BillingPayerTypeSchema'

/////////////////////////////////////////
// BILLING CHARGE SCHEMA
/////////////////////////////////////////

export const BillingChargeSchema = z.object({
  payerTypes: BillingPayerTypeSchema.array(),
  id: z.string(),
  revenueCode: z.string(),
  units: z.number(),
  sequenceNumber: z.number().int(),
  billingChargeMasterId: z.string(),
  /**
   * in cents
   */
  unitCost: z.number().int(),
  billingCaseId: z.string(),
  /**
   * in cents
   */
  billedAmount: z.number().int(),
  currency: z.string(),
  collectFromOffice: z.boolean(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type BillingCharge = z.infer<typeof BillingChargeSchema>

export default BillingChargeSchema;
