import type { FormHTMLAttributes, ForwardedRef, HTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";
import { cn } from "../../utils";

export type FormProps = FormHTMLAttributes<HTMLFormElement>;
const FormBase = forwardRef<HTMLFormElement, FormProps>(
  ({ className, children, ...props }: FormProps, ref?: ForwardedRef<HTMLFormElement>) => {
    return (
      <form
        {...props}
        ref={ref}
        className={cn(
          "[&>fieldset]:py-1.5 first:[&>fieldset]:pt-0 last:[&>fieldset]:pb-0",
          className
        )}
      >
        {children}
      </form>
    );
  }
);
FormBase.displayName = "Form";

type GroupProps = Omit<HTMLAttributes<HTMLDivElement>, "title"> & {
  title?: string | ReactNode;
};
const FormFieldGroup = forwardRef<HTMLDivElement, GroupProps>(
  ({ title, className, children, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={cn(
          `relative mb-4 flex flex-col gap-4 border-b border-primary pb-4 last-of-type:mb-0
          last-of-type:border-b-0 last-of-type:pb-0`,
          title && "ml-4",
          className
        )}
      >
        {title && (
          <div className="-ml-4">{typeof title === "string" ? <h3>{title}</h3> : title}</div>
        )}
        {children}
      </div>
    );
  }
);
FormFieldGroup.displayName = "Form.Group";

type RowProps = HTMLAttributes<HTMLDivElement>;
const FormFieldRow = forwardRef<HTMLDivElement, RowProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={cn(
          "relative flex flex-row justify-stretch gap-4",
          "last:[&>.field]:flex-auto", // we always want the last field to take up remaining space
          className
        )}
      >
        {children}
      </div>
    );
  }
);
FormFieldRow.displayName = "Form.Row";

type ActionsProps = HTMLAttributes<HTMLDivElement>;
const FormActions = forwardRef<HTMLDivElement, ActionsProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={cn("relative ml-auto grid auto-cols-fr grid-flow-col gap-4", className)}
      >
        {children}
      </div>
    );
  }
);
FormActions.displayName = "Form.Actions";

export const Form = Object.assign(FormBase, {
  /**
   * @deprecated Use `Fieldset` instead
   */
  Group: FormFieldGroup,
  /**
   * @deprecated Use `Fieldset` instead
   */
  Row: FormFieldRow,
  /**
   * @deprecated Use `<Fieldset variant="actions">` instead
   */
  Actions: FormActions,
});
