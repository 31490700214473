import { zodResolver } from "@hookform/resolvers/zod";
import type { JobRoleType } from "@procision-software/database-zod";
import {
  StaffSignatureUpdateFormDataSchema,
  type StaffSignatureUpdateFormData,
} from "@procision-software/mason";
import {
  BoundField,
  Button,
  Field,
  Fieldset,
  Form,
  InputField,
  Section,
} from "@procision-software/ui";
import Signature, { type SignatureRef } from "@uiw/react-signature";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { StaffSelect } from "~/components/fields/StaffSelect";

type SignatureInputFieldProps = {
  blob?: string;
  onChange: (value: string) => void;
};

type StaffSignatureUpdateFormProps = {
  defaultValues: Partial<StaffSignatureUpdateFormData>;
  hideStaffSelect?: boolean;
  jobRoleType?: JobRoleType;
  onCancel: () => unknown;
  onChangeStaff: (id: string | null) => void;
  onSubmit: (data: StaffSignatureUpdateFormData) => unknown;
};

export function StaffSignatureUpdateForm({
  defaultValues,
  jobRoleType,
  hideStaffSelect,
  onCancel,
  onChangeStaff,
  onSubmit,
}: StaffSignatureUpdateFormProps) {
  const isStaffSelectHidden = hideStaffSelect ?? false;

  const form = useForm<StaffSignatureUpdateFormData>({
    resolver: zodResolver(StaffSignatureUpdateFormDataSchema),
    defaultValues,
  });

  const {
    formState: { isSubmitting },
  } = form;

  const onChangeSignature = useCallback(
    (value: string) => {
      form.setValue("blob", value);
    },
    [form]
  );

  const onChangeStaffHandler = useCallback(
    (value: string | null) => {
      if (value) {
        form.setValue("staffId", value);
        onChangeStaff(value);
      }
    },
    [form, onChangeStaff]
  );

  const onCancelHandler = useCallback(() => {
    form.reset(defaultValues);
    onCancel();
  }, [defaultValues, form, onCancel]);

  const blob = form.watch("blob");

  return (
    <Section>
      <Section.Header>
        <p>
          You can use the following form to update a your signature and pin. If you wish to update
          just your signature, you can leave the pin fields blank, or if you wish to update just
          your pin, you can leave the signature field as is.
        </p>
      </Section.Header>
      <Section.Content>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          {!isStaffSelectHidden && (
            <Fieldset>
              <BoundField
                control={form.control}
                name="staffId"
                label="Staff"
                render={({ field }) => (
                  <StaffSelect
                    {...field}
                    blankOption={true}
                    context={{ activeOnly: true }}
                    roleType={jobRoleType}
                    onChange={onChangeStaffHandler}
                  />
                )}
              />
            </Fieldset>
          )}
          <Fieldset>
            <Field label="Signature">
              <SignatureInput blob={blob} onChange={onChangeSignature} />
            </Field>
          </Fieldset>
          <Fieldset>
            <InputField {...form} label="Pin" name="pin" />
          </Fieldset>
          <Fieldset>
            <InputField {...form} label="Pin Confirmation" name="pinConfirmation" />
          </Fieldset>
          <Fieldset variant="actions">
            <Button onClick={onCancelHandler} type="reset">
              Cancel
            </Button>
            <Button variant="primary" type="submit" loading={isSubmitting}>
              Update
            </Button>
          </Fieldset>
        </Form>
      </Section.Content>
    </Section>
  );
}

function SignatureInput({ blob, onChange }: SignatureInputFieldProps) {
  const $svg = useRef<SignatureRef | null>(null);
  const [isSignatureFocused, setSignatureFocused] = useState(false);
  const isBlobVisible = blob !== undefined && !isSignatureFocused;
  const onClickHandler = useCallback(() => setSignatureFocused(true), []);

  const onPointer = useCallback(() => {
    const svgelm = $svg.current?.svg?.cloneNode(true) as SVGSVGElement;
    const clientWidth = $svg.current?.svg?.clientWidth ?? 4.3 * 410;
    const clientHeight = $svg.current?.svg?.clientHeight ?? 1.4 * 410;

    svgelm.removeAttribute("style");
    svgelm.setAttribute("width", `${clientWidth}px`);
    svgelm.setAttribute("height", `${clientHeight}px`);
    svgelm.setAttribute("viewbox", `${clientWidth} ${clientHeight}`);

    const data = new XMLSerializer().serializeToString(svgelm);

    onChange(`data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(data)))}`);
  }, [onChange]);

  if (isBlobVisible) {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        className="aspect-signature-pad relative box-border cursor-crosshair rounded-lg border
          border-primary bg-primary text-primary focus-within:outline-none focus-within:ring-1
          focus-within:ring-brand focus-within:ring-offset-primary disabled:opacity-70"
        src={blob}
        onClick={onClickHandler}
      />
    );
  }

  return (
    <Signature
      className="aspect-signature-pad relative box-border cursor-crosshair rounded-lg border
        border-primary bg-primary text-primary focus-within:outline-none focus-within:ring-1
        focus-within:ring-brand focus-within:ring-offset-primary disabled:opacity-70"
      onPointer={onPointer}
      ref={$svg}
    />
  );
}
