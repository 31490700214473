import { z } from "zod";
import {
  AnesthesiaTypeSchema,
  AsaClassificationSchema,
  AuditOperationSchema,
  BulkCreateDestinationSchema,
  BulkCreateSourceSchema,
  CaseActorSchema,
  CaseStatusSchema,
  ConditionStateSchema,
  DaysOfWeekSchema,
  DiagnosisReporterSchema,
  DirectionSchema,
  EquipmentRequirementRepresentativeStatusSchema,
  EquipmentRequirementStatusSchema,
  EquipmentTypeSchema,
  HealthReviewRequirementsStatusSchema,
  InsuredRelationshipSchema,
  IntervalUnitSchema,
  JobRoleTypeSchema,
  LateralitySchema,
  OrganizationTypeSchema,
  PaymentTypeSchema,
  PreAuthStatusSchema,
  SexSchema,
  TagTypeSchema,
  TimePeriodTypeSchema,
  X12ClaimAdjustmentCodeGroupSchema,
} from "./generated";

import type { CaseKeyPointType } from "./generated/inputTypeSchemas/CaseKeyPointSchema";

export * from "./attachment";
export * from "./billing-organization";
export * from "./bucket";
export * from "./generated";
export * from "./health-review-requirement";
export * from "./health-review-requirement-attachment";
export * from "./health-review-type";
export * from "./pagination";
export * from "./payment-insurance-attachment";
export * from "./payment-letter-protection";
export * from "./payment-workers-comp-attachment";
export * from "./storage-blob";
export * from "./zod-helpers";

export const AnesthesiaType = AnesthesiaTypeSchema.enum;
export type AnesthesiaType = `${z.infer<typeof AnesthesiaTypeSchema>}`;
export const ArrivalAndLaterStatusesSchema = z.enum([
  "Late",
  "Arrived",
  "Checked_In",
  "In_PreOp",
  "Ready_for_OR",
  "In_OR",
  "Recovery",
  "Discharged",
  "Partially_Completed",
  "Completed",
]);
export const ArrivalAndLaterStatuses = ArrivalAndLaterStatusesSchema.enum;
export type ArrivalAndLaterStatuses = `${z.infer<typeof ArrivalAndLaterStatusesSchema>}`;

export const AsaClassification = AsaClassificationSchema.enum;
export type AsaClassification = `${z.infer<typeof AsaClassificationSchema>}`;
export const AuditOperation = AuditOperationSchema.enum;
export type AuditOperation = `${z.infer<typeof AuditOperationSchema>}`;
export const CaseActor = CaseActorSchema.enum;
export type CaseActor = `${z.infer<typeof CaseActorSchema>}`;
export type CaseKeyPoint = CaseKeyPointType;
export const CaseStatus = CaseStatusSchema.enum;
export type CaseStatus = `${z.infer<typeof CaseStatusSchema>}`;
export const ConditionState = ConditionStateSchema.enum;
export type ConditionState = `${z.infer<typeof ConditionStateSchema>}`;
export const DaysOfWeek = DaysOfWeekSchema.enum;
export type DaysOfWeek = `${z.infer<typeof DaysOfWeekSchema>}`;
export const DiagnosisReporter = DiagnosisReporterSchema.enum;
export type DiagnosisReporter = `${z.infer<typeof DiagnosisReporterSchema>}`;
export const Direction = DirectionSchema.enum;
export type Direction = `${z.infer<typeof DirectionSchema>}`;
export const EquipmentRequirementStatus = EquipmentRequirementStatusSchema.enum;
export type EquipmentRequirementStatus = `${z.infer<typeof EquipmentRequirementStatusSchema>}`;
export const EquipmentRequirementRepresentativeStatus =
  EquipmentRequirementRepresentativeStatusSchema.enum;
export type EquipmentRequirementRepresentativeStatus = `${z.infer<
  typeof EquipmentRequirementRepresentativeStatusSchema
>}`;
export const EquipmentType = EquipmentTypeSchema.enum;
export type EquipmentType = `${z.infer<typeof EquipmentTypeSchema>}`;
export const HealthReviewRequirementsStatus = HealthReviewRequirementsStatusSchema.enum;
export type HealthReviewRequirementsStatus = `${z.infer<
  typeof HealthReviewRequirementsStatusSchema
>}`;
export const InsuredRelationship = InsuredRelationshipSchema.enum;
export type InsuredRelationship = `${z.infer<typeof InsuredRelationshipSchema>}`;
export const IntervalUnit = IntervalUnitSchema.enum;
export type IntervalUnit = `${z.infer<typeof IntervalUnitSchema>}`;
export type JobRoleType = `${z.infer<typeof JobRoleTypeSchema>}`;
export const JobRoleType = JobRoleTypeSchema.enum;
export const Laterality = LateralitySchema.enum;
export type Laterality = `${z.infer<typeof LateralitySchema>}`;
export const OrganizationType = OrganizationTypeSchema.enum;
export type OrganizationType = `${z.infer<typeof OrganizationTypeSchema>}`;
export const PaymentType = PaymentTypeSchema.enum;
export type PaymentType = `${z.infer<typeof PaymentTypeSchema>}`;
export const PreAuthStatus = PreAuthStatusSchema.enum;
export type PreAuthStatus = `${z.infer<typeof PreAuthStatusSchema>}`;
export const Sex = SexSchema.enum;
export type Sex = `${z.infer<typeof SexSchema>}`;
export const TagType = TagTypeSchema.enum;
export type TagType = `${z.infer<typeof TagTypeSchema>}`;
export const TimePeriodType = TimePeriodTypeSchema.enum;
export type TimePeriodType = `${z.infer<typeof TimePeriodTypeSchema>}`;

export type Perspective = "asc" | "practice";

// 0278 = Other Implant
// 0490 = Ambulatory Surgical Care (General)
// 0499 = Ambulatory Surgical Care (Other)

// when rendered, the first three are most used others are in numeric sorted order
// this list comes from https://linear.app/procision/issue/ENG-240/update-revenue-codes
// the revenue code descriptions (in comments) are generated from https://chatgpt.com/share/c957d12c-35e5-4b03-82d0-24d397330e3a
export const BillingRevenueCodes = [
  "0110", // Room & Board - Private
  "0120", // Room & Board - Semi-Private 2 Beds
  "0130", // Room & Board - Semi-Private
  "0150", // Room & Board - Ward
  "0160", // Room & Board - Other
  "0250", // Pharmacy
  "0270", // Medical/Surgical Supplies
  "0272", // Sterile Supply
  "0274", // Prosthetic/Orthotic Devices
  "0276", // Intraocular Lens
  "0278", // Other Implants
  "0279", // Other Supplies/Devices
  "0290", // Durable Medical Equipment (DME)
  "0300", // Laboratory
  "0320", // Radiology - Diagnostic
  "0333", // Radiology - Therapeutic
  "0360", // Operating Room Services
  "0370", // Anesthesia
  "0402", // Other Imaging Services
  "0490", // Ambulatory Surgical Center (ASC)
  "0499", // Ambulatory Surgical Care - Other
  "0636", // Drugs Requiring Detailed Coding
  "0710", // Recovery Room
  "0762", // Observation Room
  "0922", // Other Diagnostic Services
  "0940", // Other Therapeutic Services
] as const;
export const BillingRevenueCodeSchema = z.enum(BillingRevenueCodes);
export type BillingRevenueCodeType = `${z.infer<typeof BillingRevenueCodeSchema>}`;

export enum CurrencyType {
  USD = "USD",
}

export const BulkCreateSource = BulkCreateSourceSchema.enum;
export type BulkCreateSource = `${z.infer<typeof BulkCreateSourceSchema>}`;
export const BulkCreateDestination = BulkCreateDestinationSchema.enum;
export type BulkCreateDestination = `${z.infer<typeof BulkCreateDestinationSchema>}`;
export const X12ClaimAdjustmentCodeGroup = X12ClaimAdjustmentCodeGroupSchema.enum;
export type X12ClaimAdjustmentCodeGroup = `${z.infer<typeof X12ClaimAdjustmentCodeGroupSchema>}`;
