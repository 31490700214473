import { zodResolver } from "@hookform/resolvers/zod";
import {
  PatientSignatureFormDataSchema,
  type PatientSignatureFormData,
} from "@procision-software/mason";
import { Button, Field, Fieldset, Form, InputField, RadioGroupField } from "@procision-software/ui";
import Signature, { type SignatureRef } from "@uiw/react-signature";
import { useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import PatientData from "~/components/patient/PatientData";
import { patientName } from "~/models/Patient";

type SignatureInputFieldProps = {
  onChange: (value: string) => void;
};

type ExtendedPatientSignatureFormData = PatientSignatureFormData & {
  signatureType: "patient" | "representative";
};

type PatientSignatureCreateFormProps = {
  defaultValues: Partial<ExtendedPatientSignatureFormData>;
  patientId: string;
  onCancel: () => unknown;
  onSubmit: (data: PatientSignatureFormData) => unknown;
};

export function PatientSignatureCreateForm({
  defaultValues,
  patientId,
  onCancel,
  onSubmit,
}: PatientSignatureCreateFormProps) {
  const form = useForm<ExtendedPatientSignatureFormData>({
    resolver: zodResolver(
      PatientSignatureFormDataSchema.extend({
        signatureType: z.union([z.literal("patient"), z.literal("representative")]),
      })
    ),
    defaultValues: {
      ...defaultValues,
      signatureType: "patient",
    },
  });

  const {
    formState: { isSubmitting },
    watch,
  } = form;

  const onChangeSignature = useCallback(
    (value: string) => {
      form.setValue("blob", value);
    },
    [form]
  );

  const onChangeSignatureType = useCallback(
    (value: ExtendedPatientSignatureFormData["signatureType"]) => {
      form.setValue("signatureType", value);

      if (value === "patient") {
        form.setValue("representativeName", undefined);
      }
    },
    [form]
  );

  const isPatientSignature = watch("signatureType") === "patient";

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <Fieldset>
        <Field>
          <SignatureInput onChange={onChangeSignature} />
        </Field>
      </Fieldset>
      <Fieldset>
        <RadioGroupField
          {...form}
          name="signatureType"
          onChange={onChangeSignatureType}
          options={[
            { label: "Patient", value: "patient" },
            { label: "Representative", value: "representative" },
          ]}
          orientation="horizontal"
        />
      </Fieldset>
      <Fieldset>
        {isPatientSignature ? (
          <PatientData id={patientId}>{(patient) => patientName(patient)}</PatientData>
        ) : (
          <InputField {...form} name="representativeName" />
        )}
      </Fieldset>
      <Fieldset variant="actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button variant="primary" type="submit" loading={isSubmitting}>
          Create Signature
        </Button>
      </Fieldset>
    </Form>
  );
}

function SignatureInput({ onChange }: SignatureInputFieldProps) {
  const $svg = useRef<SignatureRef | null>(null);
  const onPointer = useCallback(() => {
    const svgelm = $svg.current?.svg?.cloneNode(true) as SVGSVGElement;
    const clientWidth = $svg.current?.svg?.clientWidth ?? 4.3 * 410;
    const clientHeight = $svg.current?.svg?.clientHeight ?? 1.4 * 410;

    svgelm.removeAttribute("style");
    svgelm.setAttribute("width", `${clientWidth}px`);
    svgelm.setAttribute("height", `${clientHeight}px`);
    svgelm.setAttribute("viewbox", `${clientWidth} ${clientHeight}`);

    const data = new XMLSerializer().serializeToString(svgelm);

    onChange(`data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(data)))}`);
  }, [onChange]);

  return (
    <Signature
      className="aspect-signature-pad relative box-border cursor-crosshair rounded-lg border border-primary bg-primary text-primary focus-within:outline-none focus-within:ring-1 focus-within:ring-brand focus-within:ring-offset-primary disabled:opacity-70"
      onPointer={onPointer}
      ref={$svg}
    />
  );
}
