import { z } from "zod";
import {
  HealthReviewRequirementSchema,
  HealthReviewStatusSchema,
  UserSchema,
} from "./generated";

export const HealthReviewRequirementInputSchema = HealthReviewRequirementSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  userId: true,
}).extend({
  id: z.string().optional(),
});

export const HealthReviewStatus = HealthReviewStatusSchema.enum;
export type HealthReviewStatus = `${z.infer<typeof HealthReviewStatusSchema>}`;

export type HealthReviewRequirementFilter = {
  status?: HealthReviewStatus[];
};

export type HealthReviewRequirementInput = z.infer<typeof HealthReviewRequirementInputSchema>;

export const HealthReviewRequirementUserSchema = HealthReviewRequirementSchema.extend({
  user: UserSchema,
}).partial({ user: true });

export type HealthReviewRequirementUser = z.infer<typeof HealthReviewRequirementUserSchema>;
