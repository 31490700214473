import {
  CaseActorSchema,
  EncounterParticipantSchema,
  JobRoleSchema,
  RoomSchema,
  StaffSchema,
} from "@procision-software/database-zod";
import { z } from "zod";

export const EncounterParticipantFilterSchema = z.object({
  encounterId: z.string(),
  actors: z.array(CaseActorSchema).optional(),
});
export type EncounterParticipantFilter = z.infer<typeof EncounterParticipantFilterSchema>;

export const EncounterParticipantRecordSchema = EncounterParticipantSchema.extend({
  staff: StaffSchema.nullable(),
  jobRole: JobRoleSchema.nullable(),
  room: RoomSchema.nullable(),
});
export type EncounterParticipantRecord = z.infer<typeof EncounterParticipantRecordSchema>;

/**
 * The below StaffEncounterParticipant types are used to represent the subest of EncounterParticipants
 * where the actor value is STAFF. These entries should include staff and jobRole related models.
 */
export const StaffEncounterParticipantRecordSchema = EncounterParticipantSchema.extend({
  staffId: z.string().trim().min(1, "Required"),
  staff: StaffSchema,
  jobRole: JobRoleSchema.nullable(),
  room: RoomSchema.nullable(),
});
export type StaffEncounterParticipantRecord = z.infer<typeof StaffEncounterParticipantRecordSchema>;

export const StaffEncounterParticipantFormDataSchema = EncounterParticipantSchema.pick({
  encounterId: true,
  staffId: true,
  jobRoleId: true,
  eventTime: true,
  direction: true,
  roomId: true,
}).extend({
  staffId: z.string().trim().min(1, "Required"),
  eventTime: z.string().trim().min(1, "Required"),
});
export type StaffEncounterParticipantFormData = z.infer<
  typeof StaffEncounterParticipantFormDataSchema
>;
