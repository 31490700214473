import { PatientSchema, PatientSignatureSchema } from "@procision-software/database-zod";
import { z } from "zod";

export type PatientSignatureRecord = z.infer<typeof PatientSignatureRecordSchema>;
export const PatientSignatureRecordSchema = PatientSignatureSchema.extend({
  patient: PatientSchema,
});

export type PatientSignatureFindFilter = z.infer<typeof PatientSignatureFindFilterSchema>;
export const PatientSignatureFindFilterSchema = z.object({
  patientId: z.string().optional(),
});

export type PatientSignatureFormData = z.infer<typeof PatientSignatureFormDataSchema>;
export const PatientSignatureFormDataSchema = PatientSignatureRecordSchema.pick({
  patientId: true,
  blob: true,
  representativeName: true,
});
