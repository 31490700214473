import { z } from "zod";
import { HealthReviewTypeSchema } from "./generated";

export const HealthReviewTypeInputSchema = HealthReviewTypeSchema.partial({
  createdAt: true,
  updatedAt: true,
}).extend({
  id: z.string().optional(),
});

export type HealthReviewTypeInput = z.infer<typeof HealthReviewTypeInputSchema>;
