import { z } from 'zod';
import { X12ClaimAdjustmentCodeGroupSchema } from '../inputTypeSchemas/X12ClaimAdjustmentCodeGroupSchema'
import { BillingTransactionAllocationStatusSchema } from '../inputTypeSchemas/BillingTransactionAllocationStatusSchema'

/////////////////////////////////////////
// BILLING TRANSACTION ALLOCATION SCHEMA
/////////////////////////////////////////

export const BillingTransactionAllocationSchema = z.object({
  adjustmentGroup: X12ClaimAdjustmentCodeGroupSchema.nullish(),
  status: BillingTransactionAllocationStatusSchema,
  id: z.string(),
  billingTransactionId: z.string(),
  billingChargeId: z.string(),
  billingClaimId: z.string(),
  amount: z.number().int(),
  currency: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  /**
   * null is the traditional payment
   */
  billingAdjustmentId: z.string().nullish(),
})

export type BillingTransactionAllocation = z.infer<typeof BillingTransactionAllocationSchema>

export default BillingTransactionAllocationSchema;
