import { type CaseStatus, EncounterStatus, type PrismaClient } from "@prisma/client";
import { DateTime } from "luxon";
import { caseIdToString, type CaseId } from "~/models/case";
import { codeableConceptIdForPath } from "~/procision-core/models/codeable-concepts";
import { type EmrTemplateId } from "~/procision-core/models/emr-templates";

declare const EncounterId: unique symbol;
export type EncounterId = string & { readonly tag: typeof EncounterId };
export function encounterIdToString(id: EncounterId) {
  return id as string;
}
export function toEncounterId(id: string) {
  return id as EncounterId;
}

const appointmentStatusToEncounterStatusCrosswalk: Partial<Record<CaseStatus, EncounterStatus>> = {
  Abandoned: EncounterStatus.Canceled,
  Accepted: EncounterStatus.Planned,
  Arrived: EncounterStatus.Arrived,
  Canceled: EncounterStatus.Canceled,
  Checked_In: EncounterStatus.CheckedIn,
  Denied: EncounterStatus.Canceled,
  Completed: EncounterStatus.Finished,
  Discharged: EncounterStatus.Finished,
  In_OR: EncounterStatus.InProgress,
  In_PreOp: EncounterStatus.InProgress,
  Late: EncounterStatus.Planned,
  Ready_for_OR: EncounterStatus.Arrived,
  // Requested: null,
  Partially_Completed: EncounterStatus.Finished,
  Recovery: EncounterStatus.InProgress,
  // Draft: null
};

enum EncounterCreationStatus {
  Exists,
  Created,
  TooSoon,
  ErrorCreating,
}

export async function ensureEncounterExistsForAppointment(
  prisma: PrismaClient,
  appointmentId: CaseId,
  emrTemplateId: EmrTemplateId
): Promise<EncounterCreationStatus> {
  try {
    const appointment = await prisma.case.findFirstOrThrow({
      where: {
        id: appointmentId,
      },
      include: {
        facility: true,
      },
    });
    const status = appointmentStatusToEncounterStatusCrosswalk[appointment.status];
    if (!status) return EncounterCreationStatus.TooSoon;
    const existingEncounter = await prisma.encounter.count({
      where: {
        appointmentId,
      },
    });

    if (existingEncounter) {
      return EncounterCreationStatus.Exists;
    }

    if (!appointment.patientId) throw new Error("Appointment is missing patientId");

    const periodEnd = DateTime.fromJSDate(appointment.surgeryDate, {
      zone: appointment.facility.timezone,
    })
      .plus({ minutes: appointment.expectedCaseLength ?? 0 })
      .toJSDate();

    await prisma.encounter.create({
      data: {
        patient: {
          connect: {
            id: appointment.patientId,
          },
        },
        status,
        emrTemplate: {
          connect: {
            id: emrTemplateId,
          },
        },
        identifier: appointment.financialReference.toString(),
        priorityCode: {
          connect: {
            id: await codeableConceptIdForPath(prisma, "CLIN.ENCNTR.PRI.ROUT"),
          },
        },
        appointment: {
          connect: {
            id: caseIdToString(appointmentId),
          },
        },
        serviceProvider: {
          connect: {
            id: appointment.facility.organizationId,
          },
        },
        length: appointment.expectedCaseLength,
        lengthCode: {
          connect: {
            id: await codeableConceptIdForPath(prisma, "UOM.TIME.MIN"),
          },
        },
        periodStart: appointment.surgeryDate,
        periodEnd,
      },
    });
    return EncounterCreationStatus.Created;
  } catch (e) {
    console.error(JSON.stringify(e));
    return EncounterCreationStatus.ErrorCreating;
  }
}

export async function syncAppointmentAndEncounter(
  prisma: PrismaClient,
  appointmentId: CaseId,
  encounterId: EncounterId
) {
  const appointment = await prisma.case.findFirstOrThrow({
    where: {
      id: appointmentId,
    },
    include: {
      facility: true,
    },
  });

  const status = appointmentStatusToEncounterStatusCrosswalk[appointment.status];
  if (!status) return EncounterCreationStatus.TooSoon;

  const periodEnd = DateTime.fromJSDate(appointment.surgeryDate, {
    zone: appointment.facility.timezone,
  })
    .plus({ minutes: appointment.expectedCaseLength ?? 0 })
    .toJSDate();

  await prisma.encounter.update({
    where: {
      id: encounterId,
    },
    data: {
      status,
      identifier: appointment.financialReference.toString(),
      ...(["Accepted", "Requested"].includes(appointment.status) && {
        // once an appointment has really started, we want reality not schedule to drive times
        length: appointment.expectedCaseLength,
        lengthCode: {
          connect: {
            id: await codeableConceptIdForPath(prisma, "UOM.TIME.MIN"),
          },
        },
        periodStart: appointment.surgeryDate,
        periodEnd,
      }),
    },
  });
}
