import { z } from 'zod';

/////////////////////////////////////////
// PATIENT CONDITION SCHEMA
/////////////////////////////////////////

export const PatientConditionSchema = z.object({
  id: z.string(),
  patientId: z.string(),
  name: z.string(),
  present: z.boolean(),
  effective: z.coerce.date(),
  notes: z.string(),
  invalidated: z.coerce.date().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type PatientCondition = z.infer<typeof PatientConditionSchema>

export default PatientConditionSchema;
