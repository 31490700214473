import { z } from 'zod';
import { AuditOperationSchema } from '../inputTypeSchemas/AuditOperationSchema'

/////////////////////////////////////////
// ROOM VERSION SCHEMA
/////////////////////////////////////////

export const RoomVersionSchema = z.object({
  versionOperation: AuditOperationSchema,
  versionId: z.number().int(),
  versionSourceId: z.string().nullish(),
  versionUserId: z.string().nullish(),
  versionTimestamp: z.coerce.date(),
  id: z.string().nullish(),
  name: z.string().nullish(),
  active: z.boolean().nullish(),
  facilityId: z.string().nullish(),
  createdAt: z.coerce.date().nullish(),
  updatedAt: z.coerce.date().nullish(),
})

export type RoomVersion = z.infer<typeof RoomVersionSchema>

export default RoomVersionSchema;
