import { type CurrencyType } from "@procision-software/database-zod";
import { DollarSignIcon } from "lucide-react";
import type { ChangeEvent, ForwardedRef } from "react";
import { forwardRef, useCallback } from "react";
import type { FieldValues, Path } from "react-hook-form";
import { mergeRefs } from "react-merge-refs";
import { cn, currencyFormat } from "../../utils";
import { BoundField, useFilteredFieldProps, type BoundFieldWithLegacyProps } from "./BoundField";
import type { InputProps } from "./Input";
import { Input } from "./Input";

type Ref = HTMLInputElement;

type CurrencyInputProps = Omit<
  InputProps,
  "type" | "step" | "defaultValue" | "value" | "onChange" | "leftIcon"
> & {
  currencyType: CurrencyType | string;
  value?: number | null;
  onChange?: (value: number | null) => void;
};

export const CurrencyInput = forwardRef<Ref, CurrencyInputProps>(
  ({ currencyType, value, onChange, className, ...props }, ref) => {
    // The value should be an integer of the smallest currency type unit that we
    // will format via the formatter. For instance, if the currency type is USD,
    // the smallest unit is 0.01, so a value of 1000 would be $10.00. If the
    // currency type is JPY, the smallest unit is 1, so a value of 1000 would be
    // ¥1000.

    const { formatter, unitStep } = currencyFormat(currencyType, "decimal");
    const formatValue = useCallback(
      (value: number | null | undefined) => {
        if (value === undefined) return "";
        if (value == null) return "";
        return formatter.format(Number(value) * unitStep);
      },
      [unitStep, formatter]
    );

    const handleChange = useCallback(
      (e: ChangeEvent<Ref>) => {
        const newValue = parseFloat(e.target.value.replace(/[^\d.-]/, ""));
        if (Number.isNaN(newValue)) return onChange?.(null);
        onChange?.(Math.round(newValue / unitStep));
      },
      [onChange, unitStep]
    );

    return (
      <Input
        {...props}
        ref={ref}
        type="text"
        leftIcon={DollarSignIcon}
        className={cn("[&>input]:text-right", className)}
        value={formatValue(value)}
        onChange={handleChange}
        buffered
        selectOnFocus
      />
    );
  }
);
CurrencyInput.displayName = "CurrencyInput";

type CurrencyInputFieldProps<
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
> = Omit<CurrencyInputProps, "name"> & BoundFieldWithLegacyProps<TFieldValues, TFieldPath>;

const CurrencyInputFieldInner = <
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
>(
  props: CurrencyInputFieldProps<TFieldValues, TFieldPath>,
  ref?: ForwardedRef<Ref>
) => {
  const { fieldProps, inputProps } = useFilteredFieldProps<
    TFieldValues,
    TFieldPath,
    CurrencyInputFieldProps<TFieldValues, TFieldPath>
  >(props);

  return (
    <BoundField
      {...fieldProps}
      labelProps={{ ...fieldProps.labelProps, size: inputProps.size }}
      className={cn("!flex-none", fieldProps.className)}
      render={({ field }) => (
        <CurrencyInput {...inputProps} {...field} ref={mergeRefs([ref, field.ref])} />
      )}
    />
  );
};

export const CurrencyInputField = forwardRef(CurrencyInputFieldInner) as <
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
>(
  props: CurrencyInputFieldProps<TFieldValues, TFieldPath> & {
    ref?: ForwardedRef<Ref>;
  }
) => ReturnType<typeof CurrencyInputFieldInner>;
