import { z } from 'zod';
import { MrnStrategySchema } from '../inputTypeSchemas/MrnStrategySchema'

/////////////////////////////////////////
// FACILITY SCHEMA
/////////////////////////////////////////

export const FacilitySchema = z.object({
  mrnStrategy: MrnStrategySchema,
  id: z.string(),
  name: z.string(),
  organizationId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  defaultRoomTurnOverTimeInMinutes: z.number().int(),
  timezone: z.string(),
  address1: z.string(),
  address2: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  phone: z.string(),
  fax: z.string(),
  email: z.string(),
  earliestTime: z.string(),
  latestTime: z.string(),
  highBmiFlagLevel: z.number(),
  allowSchedulingInOpenTime: z.boolean(),
  mrnPrefix: z.string(),
  sequentialMrnSequence: z.number().int(),
  labelTemplateId: z.string().nullish(),
  patientInvoiceMessage: z.string().nullish(),
  ein: z.string().nullish(),
  npi: z.string().nullish(),
})

export type Facility = z.infer<typeof FacilitySchema>

export default FacilitySchema;
