import type { Prisma, PrismaClient as PrismaClientBase } from "@prisma/client";
import type { PaginationInput } from "../../util/pagination";
import type {
  StaffSignatureAuthFormData,
  StaffSignatureFindFilter,
  StaffSignatureFormData,
  StaffSignatureRecord,
} from "./types";

type PrismaClient = Pick<PrismaClientBase, "staffSignature">;

const include = {
  staff: true,
};

async function compare(value: string, hashedValue: string) {
  return (await hash(value)) === hashedValue;
}

async function hash(value: string) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(value);

  // hash the value
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");

  return hashHex;
}

function staffSignatureWhereClause({
  id,
  staffId,
}: StaffSignatureFindFilter): Prisma.StaffSignatureWhereInput {
  const where: Prisma.StaffSignatureWhereInput = {};

  if (id) {
    where.id = id;
  }

  if (staffId) {
    where.staffId = staffId;
  }

  return where;
}

export async function staffSignatureAuth(
  prisma: PrismaClient,
  userId: string | null,
  { id, pin: unhashedPin }: StaffSignatureAuthFormData
): Promise<boolean> {
  const staffSignature = await prisma.staffSignature.findFirstOrThrow({
    select: {
      pin: true,
      staff: {
        select: {
          userId: true,
        },
      },
    },
    where: {
      id,
    },
  });

  if (!staffSignature) {
    return false;
  }

  const {
    pin,
    staff: { userId: staffUserId },
  } = staffSignature;

  /**
   * If the related staff user is the same as the current user, then we can skip the pin check.
   */
  if (staffUserId !== userId && !(await compare(unhashedPin ?? "", pin))) {
    return false;
  }

  return true;
}

export async function staffSignatureCount(
  prisma: PrismaClient,
  filters: StaffSignatureFindFilter
): Promise<number> {
  const where = staffSignatureWhereClause(filters);

  return await prisma.staffSignature.count({
    where,
  });
}

export async function staffSignatureCreate(
  prisma: PrismaClient,
  { staffId, blob, pin: unhashedPin }: StaffSignatureFormData
): Promise<StaffSignatureRecord> {
  const pin = await hash(unhashedPin);

  return await prisma.staffSignature.create({
    data: {
      blob,
      pin,
      staff: {
        connect: {
          id: staffId,
        },
      },
    },
    include,
  });
}

export async function staffSignatureDelete(
  prisma: PrismaClient,
  id: string
): Promise<StaffSignatureRecord> {
  return await prisma.staffSignature.delete({
    include,
    where: {
      id,
    },
  });
}

export async function staffSignatureFindById(
  prisma: PrismaClient,
  filters: StaffSignatureFindFilter
): Promise<StaffSignatureRecord | null> {
  const where = staffSignatureWhereClause(filters);

  return await prisma.staffSignature.findFirst({
    include,
    where,
  });
}

export async function staffSignatureFindMany(
  prisma: PrismaClient,
  filters: StaffSignatureFindFilter,
  { page, perPage }: PaginationInput
): Promise<StaffSignatureRecord[]> {
  const where = staffSignatureWhereClause(filters);

  return await prisma.staffSignature.findMany({
    include,
    skip: (page - 1) * perPage,
    take: perPage,
    where,
  });
}

export async function staffSignatureUpdate(
  prisma: PrismaClient,
  id: string,
  { blob, pin: unhashedPin }: Partial<StaffSignatureFormData>
): Promise<StaffSignatureRecord> {
  const pin = unhashedPin ? await hash(unhashedPin) : undefined;

  return await prisma.staffSignature.update({
    data: {
      blob,
      pin,
    },
    include,
    where: {
      id,
    },
  });
}
