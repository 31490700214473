import { cn } from "../utils";

export type StateVariant = "default" | "info" | "success" | "error" | "warning";
export const StateVariants: StateVariant[] = ["default", "info", "success", "error", "warning"];

export const stateVariant = (
  state: StateVariant | undefined,
  type: ("text" | "border" | "bg")[]
) => {
  if (!state || state === "default" || !type.length) return "";

  // Cannot use dynamic class names with tailwind or they will be tree-shaken and possibly not work
  // see: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
  return cn(
    state === "info" && type.includes("text") && "text-brand",
    state === "info" && type.includes("border") && "border-brand",
    state === "info" && type.includes("bg") && "bg-brand",

    state === "success" && type.includes("text") && "text-success",
    state === "success" && type.includes("border") && "border-success",
    state === "success" && type.includes("bg") && "bg-success",

    state === "error" && type.includes("text") && "text-error",
    state === "error" && type.includes("border") && "border-error",
    state === "error" && type.includes("bg") && "bg-error",

    state === "warning" && type.includes("text") && "text-warning",
    state === "warning" && type.includes("border") && "border-warning",
    state === "warning" && type.includes("bg") && "bg-warning"
  );
};
