import { z } from 'zod';

/////////////////////////////////////////
// WORKERS COMP PROVIDER SCHEMA
/////////////////////////////////////////

export const WorkersCompProviderSchema = z.object({
  id: z.string(),
  name: z.string(),
  organizationId: z.string(),
  active: z.boolean(),
  waystarProviderId: z.string().nullish(),
  /**
   * 837I or 837P or null
   */
  electronicPaymentFormat: z.string().nullish(),
  claimAddressId: z.string().nullish(),
  claimPhoneNumber: z.string().nullish(),
  claimFaxNumber: z.string().nullish(),
})

export type WorkersCompProvider = z.infer<typeof WorkersCompProviderSchema>

export default WorkersCompProviderSchema;
