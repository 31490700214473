import { z } from 'zod';
import { AuditOperationSchema } from '../inputTypeSchemas/AuditOperationSchema'
import { DiagnosisReporterSchema } from '../inputTypeSchemas/DiagnosisReporterSchema'
import { ConditionStateSchema } from '../inputTypeSchemas/ConditionStateSchema'

/////////////////////////////////////////
// DIAGNOSIS VERSION SCHEMA
/////////////////////////////////////////

export const DiagnosisVersionSchema = z.object({
  versionOperation: AuditOperationSchema,
  reporter: DiagnosisReporterSchema.nullish(),
  status: ConditionStateSchema.nullish(),
  versionId: z.number().int(),
  versionSourceId: z.string().nullish(),
  versionUserId: z.string().nullish(),
  versionTimestamp: z.coerce.date(),
  id: z.string().nullish(),
  conditionId: z.string().nullish(),
  caseId: z.string().nullish(),
  icd10: z.string().nullish(),
  effective: z.coerce.date().nullish(),
  invalidated: z.coerce.date().nullish(),
  createdAt: z.coerce.date().nullish(),
  updatedAt: z.coerce.date().nullish(),
})

export type DiagnosisVersion = z.infer<typeof DiagnosisVersionSchema>

export default DiagnosisVersionSchema;
