import type { Organization, User } from "@clerk/backend";
import { isKnownUserRole, type KnownUserRole } from "../ability/permissions";

export function getUserRoles(
  user?: Pick<User, "publicMetadata"> | null,
  organization?: Pick<Organization, "slug"> | null
): KnownUserRole[] {
  const tree = (user?.publicMetadata?.roles ?? {}) as Record<string, string[]>;
  const roles = tree[organization?.slug ?? ""] ?? [];

  return roles.filter(isKnownUserRole);
}

export function userRoleArray(userRoles: string[], all = false): string[] {
  return userRoles
    .map((role: string) => (all ? role : String(role.split(":")[0])))
    .filter((role: string, index: number, self: string[]) => self.indexOf(role) === index);
}

export const hasRole = (role: string) => (roles: string[]) => roles.includes(role);
export const isPractice = hasRole("practice");
export const isAsc = hasRole("asc");
