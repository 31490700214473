import { z } from 'zod';

/////////////////////////////////////////
// PROCEDURE HISTORY SCHEMA
/////////////////////////////////////////

/**
 * ProcedureHistory is Procision's model of FHIR's Procedure resource.
 */
export const ProcedureHistorySchema = z.object({
  id: z.string(),
  procedureName: z.string(),
  patientId: z.string(),
  /**
   * The ID of the Encounter that this ProcedureHistory is associated with, if done in Procision
   */
  encounterId: z.string().nullish(),
  performedAt: z.coerce.date().nullish(),
  /**
   * If the date is null, or is approximate like "2021" instead of "September 15, 2021"
   */
  performedAtApproximate: z.boolean(),
  /**
   * If the date is not a date, but a descriptive string e.g "a few days ago"
   */
  performedAtText: z.string().nullish(),
  /**
   * Did the procedure have complications?
   */
  complications: z.boolean(),
  /**
   * Motivation for the procedure
   */
  reason: z.string().nullish(),
  /**
   * where the procedure was performed
   */
  bodySiteText: z.string().nullish(),
  /**
   * what happened as a result of the procedure
   */
  outcome: z.string().nullish(),
  /**
   * PHI catchall
   */
  notes: z.string().nullish(),
  /**
   * If a device like a pacemaker or a pin was implanted, the device
   */
  device: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type ProcedureHistory = z.infer<typeof ProcedureHistorySchema>

export default ProcedureHistorySchema;
