import type { AppAbility } from "@procision-software/auth";
import { accessibleBy } from "@procision-software/auth";
import {
  BillingClaimStatus,
  BillingPayerType,
  type BillingCase,
  type BillingPayer,
  type PaymentInsurance,
  type PaymentLetterProtection,
  type PaymentSelfPay,
  type PaymentWorkersComp,
  type PrismaClient,
} from "@procision-software/database";
import { BillingPayerSchema } from "@procision-software/database-zod";
import { z } from "zod";
import { getBillingCasePayers, toBillingCaseId } from "./case";
import { claimBillingAmounts, type BillingAmounts } from "./claim";

// Define a unique symbol
declare const BillingPayerIdTag: unique symbol;

// Create a tagged type
export type BillingPayerId = string & { readonly tag: typeof BillingPayerIdTag };

// Function to tag a string
export function toBillingPayerId(id: string): BillingPayerId {
  return id as BillingPayerId;
}

export function billingPayerIdToString(id: BillingPayerId): string {
  return id.toString();
}

export const NamedPayerSchema = BillingPayerSchema.extend({
  name: z.string(),
});
export type NamedPayer = z.infer<typeof NamedPayerSchema>;

/**
 * Retrieves the name of the payer based on the given payer ID or BillingPayer object.
 *
 * @param {PrismaClient} prisma - Prisma client for database access.
 * @param {AppAbility} ability - Ability object from CASL for authorization.
 * @param {string | BillingPayer} id - Either a payer ID or a BillingPayer object.
 *
 * @see Caching - This is low hanging fruit for redis
 *
 * @returns {Promise<string>} - Name of the payer.
 *
 * @throws {PrismaClientKnownRequestError} - When billing payer is not found.
 *
 */
export async function payerName(
  prisma: PrismaClient,
  ability: AppAbility,
  id: string | BillingPayer
): Promise<string> {
  const payer = await (() => {
    if (typeof id !== "string") return id;
    return prisma.billingPayer.findFirstOrThrow({
      where: {
        id,
      },
    });
  })();

  if (!payer) {
    // if ID was null we'd be here
    return "Unknown Name";
  }

  switch (true) {
    case payer.paymentType === BillingPayerType.Insurance && !!payer.paymentInsuranceId:
      return insurerName(prisma, ability, payer.paymentInsuranceId);
    case payer.paymentType === BillingPayerType.Letter_Of_Protection &&
      !!payer.paymentLetterOfProtectionId:
      return letterOfProtectionName(prisma, ability, payer.paymentLetterOfProtectionId);
    case payer.paymentType === BillingPayerType.Self_Pay:
      return "Self Pay";
    case payer.paymentType === BillingPayerType.Workers_Comp && !!payer.paymentWorkersCompId:
      return workersCompName(prisma, ability, payer.paymentWorkersCompId);
    default:
      return "Unknown Name";
  }
}

async function insurerName(prisma: PrismaClient, ability: AppAbility, id: string): Promise<string> {
  const insurer = await prisma.paymentInsurance.findFirstOrThrow({
    where: {
      id,
    },
    include: {
      provider: true,
    },
  });
  return (
    (insurer.isInsuranceUnlisted ? insurer.otherInsurerName : insurer.provider?.name) ?? "Unknown"
  );
}

async function letterOfProtectionName(
  prisma: PrismaClient,
  ability: AppAbility,
  id: string
): Promise<string> {
  const letterOfProtection = await prisma.paymentLetterProtection.findFirstOrThrow({
    where: {
      id,
    },
  });
  return `LOP / ${letterOfProtection.attorney}`;
}

async function workersCompName(
  prisma: PrismaClient,
  ability: AppAbility,
  id: string
): Promise<string> {
  const workersComp = await prisma.paymentWorkersComp.findFirstOrThrow({
    where: {
      id,
    },
    include: {
      provider: true,
    },
  });
  return `Workers Comp ${workersComp.provider?.name ?? workersComp.carrier ?? "Unknown"}`;
}

export async function augmentBillingCaseWithPayerSummary(
  prisma: PrismaClient,
  ability: AppAbility,
  billingCase: Pick<BillingCase, "id" | "nextPayerId"> & {
    billingPayers: BillingPayer[];
  }
) {
  const fullBillingCase = await prisma.billingCase.findFirstOrThrow({
    where: { id: billingCase.id },
    include: { billingCharges: true, billingClaims: true },
  });

  const allocations = await prisma.billingTransactionAllocation.findMany({
    where: {
      billingClaim: {
        billingCaseId: billingCase.id,
      },
    },
    include: {
      billingTransaction: true,
      billingClaim: {
        include: {
          billingPayer: true,
        },
      },
    },
  });

  return await Promise.all(
    billingCase.billingPayers.map(async (payer) => {
      const claims = fullBillingCase.billingClaims.filter((c) => c.billingPayerId === payer.id);

      // use the most recent claim by submitted date, falling back to created date
      const recentClaim =
        claims.sort(
          (a, b) =>
            (b.lastSubmittedAt?.valueOf() ?? b.createdAt.valueOf()) -
            (a.lastSubmittedAt?.valueOf() ?? a.createdAt.valueOf())
        )[0] ?? null;

      // use the oldest claim's createdAt as the claim generation date
      const claimGenerationDate =
        claims.sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf())[0]?.createdAt ?? null;

      const { officeOnlyCharges, ...billingAmounts } = recentClaim
        ? claimBillingAmounts({ ...recentClaim, billingPayer: payer }, fullBillingCase, allocations)
        : ({
            officeOnlyCharges: [],
            billedAmount: 0,
            priorPayersAmount: 0,
            expectedAmount: 0,
            adjustmentAmount: 0,
            paymentAmount: 0,
            outstandingAmount: 0,
          } satisfies BillingAmounts & { officeOnlyCharges: [] });

      // most recent transaction's date
      const paymentDate =
        allocations
          .filter((a) => a.billingClaim.billingPayerId === payer.id)
          .flatMap((a) => a.billingTransaction)
          .sort((a, b) => b.transactionDate.valueOf() - a.transactionDate.valueOf())?.[0]
          ?.transactionDate ?? null;

      return {
        ...payer,
        name: await payerName(prisma, ability, payer),
        current: payer.id === billingCase.nextPayerId,
        primary: payer.sequenceNumber === 0,
        claimGenerationDate,
        // If the claim was not submitted but has been paid, use the payment date as billed date
        billedDate: recentClaim?.lastSubmittedAt ?? paymentDate ?? null,
        status: recentClaim?.status ?? BillingClaimStatus.New,
        paymentDate,
        ...billingAmounts,
        claimIds: claims.map((c) => c.id),
        officeOnlyCharges,
      };
    })
  );
}

export async function getPayer(prisma: PrismaClient, ability: AppAbility, id: BillingPayerId) {
  const payer = await prisma.billingPayer.findFirstOrThrow({
    where: {
      id: billingPayerIdToString(id),
    },
  });
  return {
    ...payer,
    name: await payerName(prisma, ability, payer),
  };
}

export async function reprioritize(
  prisma: PrismaClient,
  ability: AppAbility,
  id: string,
  desiredPriority: number
) {
  const payer = await prisma.billingPayer.findFirstOrThrow({
    where: {
      id,
    },
  });
  const kasePayers = await getBillingCasePayers(
    prisma,
    ability,
    toBillingCaseId(payer.billingCaseId)
  );
  if (kasePayers.billingPayers.length === 0) {
    throw new Error(`No payers found for case ${payer.billingCaseId}`);
  }

  const originalPriority = payer.sequenceNumber;

  const updates = kasePayers.billingPayers
    .map((p) => {
      if (p.id === id) {
        return prisma.billingPayer.updateMany({
          where: { id: p.id },
          data: { sequenceNumber: desiredPriority },
        });
      } else if (p.sequenceNumber < originalPriority && p.sequenceNumber >= desiredPriority) {
        // Only decrement sequence numbers between original and desired, excluding desired
        return prisma.billingPayer.updateMany({
          where: { id: p.id },
          data: { sequenceNumber: p.sequenceNumber + 1 },
        });
      } else if (p.sequenceNumber > originalPriority && p.sequenceNumber <= desiredPriority) {
        // Only increment sequence numbers between original and desired, excluding original
        return prisma.billingPayer.updateMany({
          where: { id: p.id },
          data: { sequenceNumber: p.sequenceNumber - 1 },
        });
      } else {
        return null;
      }
    })
    .filter(Boolean)
    .map((p) => p!);

  await prisma.$transaction(updates);

  return true;
}

export async function updatePayer(
  prisma: PrismaClient,
  ability: AppAbility,
  id: BillingPayerId,
  incoming: Partial<
    Pick<BillingPayer, "notes" | "waystarInsuranceProviderId"> & {
      paymentInsurance?: Partial<PaymentInsurance>;
      paymentLetterProtection?: Partial<PaymentLetterProtection>;
      paymentSelfPay?: Partial<PaymentSelfPay>;
      paymentWorkersComp?: Partial<PaymentWorkersComp>;
    }
  >
): Promise<boolean> {
  const { paymentInsurance, paymentLetterProtection, paymentSelfPay, paymentWorkersComp, ...data } =
    incoming;

  if (!data.waystarInsuranceProviderId) {
    let waystarInsuranceProviderId: string | null | undefined = null;

    if (paymentInsurance?.providerId) {
      const provider = await prisma.insuranceProvider.findUnique({
        where: {
          id: paymentInsurance.providerId,
        },
      });
      waystarInsuranceProviderId = provider?.waystarInsuranceProviderId;
    } else if (paymentWorkersComp?.providerId) {
      const provider = await prisma.workersCompProvider.findUnique({
        where: {
          id: paymentWorkersComp.providerId,
        },
      });
      waystarInsuranceProviderId = provider?.waystarProviderId;
    }

    data.waystarInsuranceProviderId = waystarInsuranceProviderId;
  }

  const { count } = await prisma.billingPayer.updateMany({
    where: { ...accessibleBy(ability, "update").BillingPayer, id: billingPayerIdToString(id) },
    data,
  });
  const payer = await getPayer(prisma, ability, id);
  if (
    payer.paymentType === BillingPayerType.Insurance &&
    payer.paymentInsuranceId &&
    paymentInsurance
  ) {
    await prisma.paymentInsurance.updateMany({
      where: {
        id: payer.paymentInsuranceId,
      },
      data: paymentInsurance,
    });
  } else if (
    payer.paymentType === BillingPayerType.Letter_Of_Protection &&
    payer.paymentLetterOfProtectionId &&
    paymentLetterProtection
  ) {
    await prisma.paymentLetterProtection.updateMany({
      where: {
        id: payer.paymentLetterOfProtectionId,
      },
      data: paymentLetterProtection,
    });
  } else if (
    payer.paymentType === BillingPayerType.Self_Pay &&
    payer.paymentSelfPayId &&
    paymentSelfPay
  ) {
    await prisma.paymentSelfPay.updateMany({
      where: {
        id: payer.paymentSelfPayId,
      },
      data: paymentSelfPay,
    });
  } else if (
    payer.paymentType === BillingPayerType.Workers_Comp &&
    payer.paymentWorkersCompId &&
    paymentWorkersComp
  ) {
    await prisma.paymentWorkersComp.updateMany({
      where: {
        id: payer.paymentWorkersCompId,
      },
      data: paymentWorkersComp,
    });
  }
  return count > 0;
}

type PaymentTypeMap = {
  [BillingPayerType.Insurance]: PaymentInsurance;
  [BillingPayerType.Self_Pay]: PaymentSelfPay;
  [BillingPayerType.Workers_Comp]: PaymentWorkersComp;
  [BillingPayerType.Letter_Of_Protection]: PaymentLetterProtection;
};

async function newPayerFor<T extends BillingPayerType>(
  prisma: PrismaClient,
  ability: AppAbility,
  kase: {
    caseId: string;
  },
  paymentType: T
): Promise<PaymentTypeMap[T]> {
  switch (paymentType) {
    case BillingPayerType.Insurance: {
      const insurance =
        ability.can("create", "PaymentInsurance") &&
        (await prisma.paymentInsurance.create({
          data: {
            caseId: kase.caseId,
          },
        }));
      if (!insurance) {
        throw new Error(`Permission denied`);
      }
      return insurance as PaymentTypeMap[T];
    }
    case BillingPayerType.Letter_Of_Protection: {
      const letterOfProtection =
        ability.can("create", "PaymentLetterProtection") &&
        (await prisma.paymentLetterProtection.create({
          data: {
            caseId: kase.caseId,
          },
        }));
      if (!letterOfProtection) {
        throw new Error(`Permission denied`);
      }
      return letterOfProtection as PaymentTypeMap[T];
    }
    case BillingPayerType.Self_Pay: {
      const selfPay =
        ability.can("create", "PaymentSelfPay") &&
        (await prisma.paymentSelfPay.create({
          data: {
            caseId: kase.caseId,
          },
        }));
      if (!selfPay) {
        throw new Error(`Permission denied`);
      }
      return selfPay as PaymentTypeMap[T];
    }
    case BillingPayerType.Workers_Comp: {
      const workersComp =
        ability.can("create", "PaymentWorkersComp") &&
        (await prisma.paymentWorkersComp.create({
          data: {
            caseId: kase.caseId,
          },
        }));
      if (!workersComp) {
        throw new Error(`No workers comp found for case ${kase.caseId}`);
      }
      return workersComp as PaymentTypeMap[T];
    }
    default:
      throw new Error(`Unknown payment type`);
  }
}

export async function deletePayer(prisma: PrismaClient, ability: AppAbility, id: BillingPayerId) {
  const payer = await getPayer(prisma, ability, id);
  const kase = await getBillingCasePayers(prisma, ability, toBillingCaseId(payer.billingCaseId));
  if (kase.nextPayerId === billingPayerIdToString(id)) {
    throw new Error(`Cannot delete current payer`);
  }
  // I don't want to overengineer right now but this may need more nuance yet
  // I also wonder about implications on updatePayer
  if (
    (await prisma.billingClaim.count({
      where: {
        billingPayerId: billingPayerIdToString(id),
      },
    })) === 0
  ) {
    const existing = kase.billingPayers.find((p) => p.id === billingPayerIdToString(id));
    if (existing?.paymentInsuranceId) {
      const { count } = await prisma.paymentInsurance.deleteMany({
        where: {
          id: existing.paymentInsuranceId,
        },
      });
      if (count === 0) {
        throw new Error("Unable to delete insurance payer");
      }
    } else if (existing?.paymentLetterOfProtectionId) {
      const { count } = await prisma.paymentLetterProtection.deleteMany({
        where: {
          id: existing.paymentLetterOfProtectionId,
        },
      });
      if (count === 0) {
        throw new Error("Unable to delete letter of protection payer");
      }
    } else if (existing?.paymentSelfPayId) {
      const { count } = await prisma.paymentSelfPay.deleteMany({
        where: {
          id: existing.paymentSelfPayId,
        },
      });
      if (count === 0) {
        throw new Error("Unable to delete self pay payer");
      }
    } else if (existing?.paymentWorkersCompId) {
      const { count } = await prisma.paymentWorkersComp.deleteMany({
        where: {
          id: existing.paymentWorkersCompId,
        },
      });
      if (count === 0) {
        throw new Error("Unable to delete workers comp payer");
      }
    }
    const { count } = await prisma.billingPayer.deleteMany({
      where: {
        id: billingPayerIdToString(id),
      },
    });
    return count > 0;
  } else {
    throw new Error(`Cannot delete payer with journal entries`);
  }
}

export async function createPayer(
  prisma: PrismaClient,
  ability: AppAbility,
  billingCaseId: string,
  data: Pick<BillingPayer, "paymentType"> & Partial<Pick<BillingPayer, "notes">>
) {
  const kase = await getBillingCasePayers(prisma, ability, toBillingCaseId(billingCaseId));
  const schedulingPayment = await newPayerFor(prisma, ability, kase, data.paymentType);
  const sequenceNumber =
    1 + Math.max(...kase.billingPayers.map((p) => p.sequenceNumber).concat(-1));
  const relateSchedulingPayer = () =>
    ({
      paymentInsuranceId:
        data.paymentType === BillingPayerType.Insurance ? schedulingPayment.id : null,

      paymentLetterOfProtectionId:
        data.paymentType === BillingPayerType.Letter_Of_Protection ? schedulingPayment.id : null,

      paymentSelfPayId:
        data.paymentType === BillingPayerType.Self_Pay ? schedulingPayment.id : null,

      paymentWorkersCompId:
        data.paymentType === BillingPayerType.Workers_Comp ? schedulingPayment.id : null,

      waystarInsuranceProviderId:
        "waystarInsuranceProviderId" in schedulingPayment
          ? schedulingPayment.waystarInsuranceProviderId
          : null,
    }) as BillingPayer; // just enables autocomplete on the above
  const bp = await prisma.billingPayer.create({
    data: {
      ...data,
      ...relateSchedulingPayer(),
      billingCaseId: kase.id,
      sequenceNumber,
    },
  });
  return bp;
}

export async function sortPayers(prisma: PrismaClient, ability: AppAbility, billingCaseId: string) {
  // resort billing payers's sequenceNumber so that selfpay comes last. Include newly created one in that.
  const sortBySequenceButPutSelfPayLast = (a: BillingPayer, b: BillingPayer) => {
    if (a.paymentType === BillingPayerType.Self_Pay) return 1;
    if (b.paymentType === BillingPayerType.Self_Pay) return -1;
    return a.sequenceNumber - b.sequenceNumber;
  };
  const newPayers = await getBillingCasePayers(prisma, ability, toBillingCaseId(billingCaseId));
  return await prisma.$transaction(
    newPayers.billingPayers.sort(sortBySequenceButPutSelfPayLast).map((p, index) =>
      prisma.billingPayer.updateMany({
        where: { id: p.id },
        data: { sequenceNumber: index },
      })
    )
  );
}

export async function bundlePaymentInfo(
  prisma: PrismaClient,
  ability: AppAbility,
  payer: BillingPayer
) {
  if (payer.paymentType === BillingPayerType.Insurance && payer.paymentInsuranceId) {
    return {
      paymentLetterProtection: null,
      paymentSelfPay: null,
      paymentWorkersComp: null,
      paymentInsurance: await prisma.paymentInsurance.findFirstOrThrow({
        where: {
          case: accessibleBy(ability, "read").Case,
          id: payer.paymentInsuranceId,
        },
      }),
    };
  } else if (
    payer.paymentType === BillingPayerType.Letter_Of_Protection &&
    payer.paymentLetterOfProtectionId
  ) {
    return {
      paymentInsurance: null,
      paymentSelfPay: null,
      paymentWorkersComp: null,
      paymentLetterProtection: await prisma.paymentLetterProtection.findFirstOrThrow({
        where: {
          case: accessibleBy(ability, "read").Case,
          id: payer.paymentLetterOfProtectionId,
        },
      }),
    };
  } else if (payer.paymentType === BillingPayerType.Self_Pay && payer.paymentSelfPayId) {
    return {
      paymentInsurance: null,
      paymentLetterProtection: null,
      paymentWorkersComp: null,
      paymentSelfPay: await prisma.paymentSelfPay.findFirstOrThrow({
        where: {
          case: accessibleBy(ability, "read").Case,
          id: payer.paymentSelfPayId,
        },
      }),
    };
  } else if (payer.paymentType === BillingPayerType.Workers_Comp && payer.paymentWorkersCompId) {
    return {
      paymentInsurance: null,
      paymentLetterProtection: null,
      paymentSelfPay: null,
      paymentWorkersComp: await prisma.paymentWorkersComp.findFirstOrThrow({
        where: {
          case: accessibleBy(ability, "read").Case,
          id: payer.paymentWorkersCompId,
        },
      }),
    };
  } else {
    throw new Error("undefined payment type");
  }
}

export async function waystarProviderForPayer(
  prisma: PrismaClient,
  ability: AppAbility,
  payer: {
    waystarInsuranceProviderId?: string | null;
    paymentType: BillingPayerType;
    paymentInsuranceId?: string | null;
  }
) {
  if (payer.waystarInsuranceProviderId) {
    return await prisma.waystarInsuranceProvider.findFirst({
      where: {
        id: payer.waystarInsuranceProviderId,
      },
    });
  } else if (payer.paymentType === BillingPayerType.Insurance) {
    if (!payer.paymentInsuranceId) return null;
    const insurance = await prisma.paymentInsurance.findFirst({
      where: {
        id: payer.paymentInsuranceId,
      },
    });
    if (!insurance?.providerId) return null;
    return (
      (
        await prisma.insuranceProvider.findFirst({
          where: {
            id: insurance.providerId,
          },
          include: {
            waystarInsuranceProvider: true,
          },
        })
      )?.waystarInsuranceProvider ?? null
    );
  } else {
    return null;
  }
}

export async function externalIdForPayer(
  prisma: PrismaClient,
  ability: AppAbility,
  payer: {
    waystarInsuranceProviderId?: string | null;
    paymentType: BillingPayerType;
    paymentInsuranceId?: string | null;
  }
) {
  const waystar = await waystarProviderForPayer(prisma, ability, payer);
  return waystar?.externalId ?? null;
}
