import { z } from 'zod';

/////////////////////////////////////////
// ATTACHMENT TYPE SCHEMA
/////////////////////////////////////////

export const AttachmentTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  label: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type AttachmentType = z.infer<typeof AttachmentTypeSchema>

export default AttachmentTypeSchema;
