import { z } from 'zod';
import { QuestionConditionTypeSchema } from '../inputTypeSchemas/QuestionConditionTypeSchema'
import { QuestionConditionComparatorSchema } from '../inputTypeSchemas/QuestionConditionComparatorSchema'
import { QuestionValueTypeSchema } from '../inputTypeSchemas/QuestionValueTypeSchema'

/////////////////////////////////////////
// QUESTIONNAIRE QUESTION CONDITION SCHEMA
/////////////////////////////////////////

export const QuestionnaireQuestionConditionSchema = z.object({
  type: QuestionConditionTypeSchema,
  comparator: QuestionConditionComparatorSchema,
  valueType: QuestionValueTypeSchema,
  id: z.string(),
  sourceQuestionnaireId: z.string(),
  targetQuestionnaireId: z.string(),
  sourceQuestionId: z.string(),
  targetQuestionId: z.string(),
  targetQuestionGroupRow: z.number().int(),
  useSourceGroupRow: z.boolean().nullish(),
  floatValue: z.number().nullish(),
  stringValue: z.string().nullish(),
  booleanValue: z.boolean().nullish(),
  dateValue: z.coerce.date().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type QuestionnaireQuestionCondition = z.infer<typeof QuestionnaireQuestionConditionSchema>

export default QuestionnaireQuestionConditionSchema;
